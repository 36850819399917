<template src="./activityDiscussion.html"></template>

<script>
import DiscussionRepository from "../../Repository/Discussions";
import UserRepository from "../../Repository/User";
import { v4 } from "uuid"
import inputContainer from "../../Components/inputContainer.vue";
// import { spacesUrl } from "../../NetworkManager";
import moment from "moment";
import DiscussionDownloadList from "../../Components/activityTimeline/DiscussionDownloadList.vue"

export default {
  name: "activityDiscussion",
  components: {
    inputContainer,
    DiscussionDownloadList
  },
  data() {
    return {
      isMobileView: false,
      selectedInstitute: "",
      selectedSemester: "",
      userData: null,
      testDiscussion: { title: "Test Title", nameOfPostee: "Tejas Patil", publishTime: "05:15", publishDate: "2023-02-11", publishEndTime: "2.20PM", publishEndDate: "10/02/2023", description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et…….See More" },
      discussion: null,
      responses: [],
      uuid: v4,
      moment: moment,
      showComments: [],
      commentText: [],
      userFullname: "",
      content: "",
      showAddDialog: false,
      filterByItems: ["Oldest to Newest", "Newest to Oldest"],
      ended: false
    };
  },
  created() {
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.userRepositoryInstance = new UserRepository(this);
    this.discussionRepositoryInstance = new DiscussionRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.$store.commit("liveData/set_selectedActivityName", "Discussion");
    this.userData = this.$store.getters["user/get_userData"];
    this.getFullName()
    this.fetchData();
  },
  methods: {

    async fetchData() {
      try {
        const objToPush = {
          discussionId: this.$route.query.discussionId
        };
        this.discussion = await this.discussionRepositoryInstance.getDiscussionById(objToPush)
        this.ended = new Date(this.discussion.endDate) < new Date()
        this.discussion.publishDate = moment(this.discussion.publishDate).format("DD MMMM YYYY")
        this.responses = await this.discussionRepositoryInstance.getResponseByDiscussionId(objToPush)

        const resLen = this.responses.length

        this.showComments = Array(resLen > 0 ? resLen : 1).fill(false)
        this.commentText = Array(resLen > 0 ? resLen : 1).fill("")
      } catch (err) {
      }
    },

    async getFullName() {
      try {
        const objToPush = {
          uId: this.userData.uId
        };
        this.userFullname = await this.userRepositoryInstance.getFullNameOfUser(objToPush)
      } catch (error) {
      }
    },

    hideComments(idx) {
      const tempComments = [...this.showComments]
      tempComments[idx] = !tempComments[idx]
      this.showComments = tempComments
    },
    getCommentTitle(idx) {
      return this.showComments[idx] ? "Hide Comment" : "Show Comments";
    },
    async onAddComment(index) {
      try {
        const objToPush = {
          discussionId: this.$route.query.discussionId, responseId: this.responses[index].responseId, comment: { name: this.userFullname, publishAt: new Date(), content: this.commentText[index], uId: this.userData.uId }
        }
        if (this.commentText[index] !== "") {
          await this.discussionRepositoryInstance.createComment(objToPush)
          if (Array.isArray(this.responses[index].comments)) {
            this.responses[index].comments.push(objToPush.comment)
          } else {
            this.responses[index].comments = [objToPush.comment]
          }
        }
      } catch (error) {
      } finally {
        this.commentText[index] = ""
      }
    },
    async createResponse() {
      if (this.content === "") {
        return
      }
      try {
        const objToPush = {
          discussionId: this.$route.query.discussionId,
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          postedById: this.userData.uId,
          postedByFullname: this.userFullname,
          content: this.content,
          postDate: new Date()
        };
        await this.discussionRepositoryInstance.createResponse(objToPush)
        this.fetchData()
      } catch (err) {
      } finally {
        this.showAddDialog = false
        this.content = ""
      }
    },
    onChangeComment(event, idx) {
    },
    async deleteResponse(responseId) {
      try {
        const objToPush = {
          responseId,
          discussionId: this.discussion.discussionId
        };
        await this.discussionRepositoryInstance.deleteResponse(objToPush)
        this.fetchData()
      } catch (err) {
      }
    },
    onFilterResponse(filter) {
      // ["Oldest to Newest", "Newest to Oldest", "Videos Only", "Images Only", "Description Only", "Document", "Discussion Forum"]
      switch (filter) {
        case this.filterByItems[0]:
          this.responses.sort(function (a, b) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          });
          break;
        case this.filterByItems[1]:
          this.responses.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style src="./activityDiscussion.css"></style>
