<template src="./activityValueAdded.html"></template>
<script>

import inputContainer from '../../Components/inputContainer'
import SubjectInstituteLevelRepository from '../../Repository/SubjectInstituteLevel'
import SemesterRepository from '../../Repository/Semester'
import SubjectRepository from '../../Repository/Subject'
import SemesterUserRepository from '../../Repository/SemesterUser'
import valueAddedActivityRepository from '../../Repository/ValueAddedActivity'
import uploadToDigitalOceanRepository from '../../Repository/uploadToDigitalOcean'
import userRepository from '../../Repository/User'
import showStatus from '../../NetworkManager/showStatus'
import NbaRepository from "../../Repository/NBA";

import axios from 'axios'



export default {
  name: 'activityValueAdded',
  components: {
    inputContainer,
  },
  props: ['Props_Data', 'Props_Edit'],

  data() {
    return {
      date: '',
      errorMessageByExcel: '',
      menu: false,
      showErrorOfExcel: false,
      facultyData: [],
      allErrorArray: [],
      selectedSemId: '',
      allSemData: [],
      allSubjectNames: [],
      allDeptOfSem: [],
      selectedSem: '',
      selectedDept: '',
      selectedSubject: '',
      allSemName: [],
      parseExcelFile: [],
      uploadExcelDialog: false,
      valueAddedFrameworkByInput: '',
      valueAddedIsNew: '',
      valueAddedIsForNaac: '',
      valueAddedFramework: '',
      framework: ['Professional Ethics', 'Human Values', ' Environment', 'Sustainability', 'Other'],
      createFeedbackForm: false,
      disabledForm: false,
      mappedPSOs: [],
      mappedPOs: [],
      subjectByInstituteId: [],
      coDialog: false,
      poDialog: false,
      psoDialog: false,
      bloomsDialog: false,
      totalBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organize, relate, compare, contrast, distinguish, examine, experiment, question, test)"
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorize, repeat, state)"
        }
      ],
      totalPsos: [],
      totalPos: [],
      totalCos: [],
      mappedCOs: [],
      mappedBlooms: [],
      typeOfActivitySelect: ['Other'],
      namesOfFaculty: [],
      nameOfSubject: [],
      disabledCreateValueAdded: false,
      disabledEditDialog: false,
      deletingValueAddedDialog: false,
      valueAddedActivityIdForDelete: '',
      tempValueAddedPrerequisite: [],
      valueAddedActivityId: '',
      dialogValueAddedEdit: false,
      loadingForValue: false,
      valueAddedActivityAll: [],
      searchNormal: '',
      searchValue: '',
      levelOfActivity: ['Course Level', 'Program Level'],
      valueAddedTitle: '',
      valueAddedDescription: '',
      valueAddedLevelActivity: '',
      valueAddedActivityCode: '',
      valueAddedTypeOfActivity: '',
      valueAddedLevelCourse: '',
      valueAddedAssignFaculty: [],
      valueAddedTotalCredits: null,
      valueAddedTotalDuration: null,
      valueAddedPrerequisite: [],
      valueAddedCO: [],
      valueAddedPO: [],
      valueAddedPSO: [],
      valueAddedBloomPO: [],
      valueAddedBloomCo: [],
      valueAddedGap: '',
      valueAddedFeedbackValue: '',

      dialogValueAdded: false,
      items: [{ title: 'Normal Course' }, { title: 'Value added course' }],

    }
  },
  async created() {
    this.subjectInstituteLevelInstance = new SubjectInstituteLevelRepository(this)
    this.valueAddedActivityInstance = new valueAddedActivityRepository(this)
    this.uploadToDigitalOceanInstance = new uploadToDigitalOceanRepository(this)
    this.SemesterUserInstance = new SemesterUserRepository(this)
    this.SubjectRepositoryInstance = new SubjectRepository(this)
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.SemesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new userRepository(this);
    this.user = this.$store.getters['user/get_user']
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.$store.commit('liveData/set_selectedActivityName', 'Value added course')
    this.check(this.Props_Data, this.Props_Edit)


    await this.fetchData()

  },
  methods: {
    gotoDashboard() {
      this.$router.push('/activityAddSubjectInstituteLevel')
    },

    onChangeLevelOfActivity() {
      this.selectedSubject = ''
      this.clearAllField()
    },
    onChangeOfSemDrop() {
      this.selectedDept = ''
      this.selectedSubject = ''
      this.valueAddedLevelActivity = ''
      this.allDeptOfSem = []
      if (this.selectedSem !== '') {
        this.selectedSemId = ''
        this.allSemData.map((data) => {
          if (data.semName === this.selectedSem) {
            this.selectedSemId = data.semId
            this.allDeptOfSem.push(data.department)
          }
        })


      }
    },
    async onChangeDeptDrop() {
      if (!this.Props_Edit) {

        this.valueAddedLevelActivity = ''
      }

      if (this.selectedDept !== '') {
        this.allSubjectOfDept = []
        try {
          this.allSubjectOfDept = await this.SubjectRepositoryInstance.getSubjectsOfADepartment({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemId,
            department: this.selectedDept,
          })
          this.allSubjectNames = []
          this.allSubjectNames = this.allSubjectOfDept.subjects.map((data) => {
            return data.subjectName
          })
        } catch (error) {
          console.log(error);
        }
        try {
          this.facultyData = []
          let facultyData = await this.SemesterUserInstance.getFacultiesOfADepartment({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemId,
            department: this.selectedDept,
          })
          let facultyUid = []
          facultyUid = facultyData.map((data) => {
            return data.uId
          })
          this.facultyData = await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: facultyUid
          })

          this.namesOfFaculty = []
          this.namesOfFaculty = this.facultyData.map((data) => {
            return data.fullName
          })
        } catch (error) {
          console.log(error);

        }
      }
      await this.getProgramOutcomes()
      await this.getProgramSpecificOutcomes()
    },
    async parseExcel() {
      let semId = ''
      // console.log('this.allSemData', this.allSemData);
      this.allSemData.map((data) => {
        if (data.semName === this.selectedSem) {
          semId = data.semId
        }
      })
      console.log('semId', semId);

      if (this.parseExcelFile) {
        this.disabledForm = true
        var formData = new FormData()
        formData.append('instituteId', this.selectedInstitute)
        formData.append('semId', semId)
        formData.append('department', this.selectedDept)
        formData.append('excel', this.parseExcelFile)
        formData.append('isIgnored', 'false')
        let url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/valueAddedActivity/parseValueAddedExcel'
        axios
          .post(
            process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/valueAddedActivity/parseValueAddedExcel',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(
            response => {
              console.log('response', response);
              this.disabledForm = false
              if (response.status === 200) {
                this.showErrorOfExcel = true
                this.errorMessageByExcel = response.data.errorMessage
                this.allErrorArray = response.data.errorData
                // this.$router.push({
                //   name: 'activityAddSubjectInstituteLevel',
                // });
              } else {
                this.showErrorOfExcel = true
                this.errorMessageByExcel = response.data.errorMessage

              }

            },
            error => {
              console.log('error', error)
              this.showErrorOfExcel = true
                this.errorMessageByExcel = 'Something went wrong.Please try again'

              this.disabledForm = false

            }
          )
          .then(() => {
            this.uploadExcelDialog = false


          })
      }

    },
    cancelValueAddedActivity() {
      this.$router.push({
        name: 'activityAddSubjectInstituteLevel',
      });
    },
    removeLink(index, array) {
      array.splice(index, 1)
    },
    check(data, edit) {
      if (data !== undefined) {
        this.editValueAddedActivity(data)
      }
      //   if (edit) {
      //   this.onChangeDeptDrop()
      // }

    },
    courseSelectChange() {
      console.log('this.selectedSubject', this.selectedSubject);
      this.mappedCOs = []
      let semId = '';
      let subjectId = '';
      if (this.selectedSubject !== '') {
        this.allSubjectOfDept.subjects.map((data) => {
          if (this.selectedSubject === data.subjectName) {
            semId = data.semId
            subjectId = data.subjectId
          }
        })
        this.getCourseOutcomes(semId, subjectId)
      }
    },
    removeCo(index) {
      this.mappedCOs.splice(index, 1);
    },

    removeBloom(index) {
      this.mappedBlooms.splice(index, 1);
    },
    removePOs(index) {
      this.mappedPOs.splice(index, 1);
    },
    removePSOs(index) {
      this.mappedPSOs.splice(index, 1);
    },

    async getCourseOutcomes(semID, subId) {

      try {
        const nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: semID,
          subjectId: subId
        });
        this.totalCos = []
        nbaData.data.map((d) => {
          this.totalCos.push({
            coNumber: d.CONumber,
            coName: d.COName
          });
        });

      } catch (error) {
        console.log(error);
      }
    },

    async getProgramOutcomes() {
      try {
        const nbaData = await this.nbaRepositoryInstance.getPos({
          lmsInstituteId: this.selectedInstitute,
          department: this.selectedDept
        });
        this.totalPos = []
        nbaData.data.map((d) => {
          this.totalPos.push({
            poNumber: d.poNumber,
            poName: d.poName
          });
        });
      } catch (error) {
        console.log(error);
      }

    },
    async getProgramSpecificOutcomes() {

      try {
        const nbaData = await this.nbaRepositoryInstance.getPsos({
          lmsInstituteId: this.selectedInstitute,
          department: this.selectedDept

        });

        this.totalPsos = []
        nbaData.data.map((d) => {
          this.totalPsos.push({
            psoNumber: d.psoNumber,
            psoName: d.psoName
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async feedbackForm() {
      this.createFeedbackForm = true
      await this.createValueAddedActivity()

    },
    async saveEditedValueAddedActivity() {
      let obj = {}
      let preFile = []
      if (this.valueAddedTitle === '' || this.valueAddedLevelActivity === '' || this.valueAddedIsForNaac == '' || this.date === '') {
        alert('Please fill required field')
      } else {
        this.disabledEditDialog = true
        this.disabledForm = true

        obj.valueAddedActivityId = this.valueAddedActivityId
        obj.instituteId = this.selectedInstitute
        obj.isForNaac = this.valueAddedIsForNaac
        obj.date = this.date

        if (this.valueAddedFramework !== '') {
          if (this.valueAddedFramework === 'Other') {
            obj.framework = this.valueAddedFrameworkByInput
          } else {
            obj.framework = this.valueAddedFramework
          }
        }
        if (this.valueAddedIsNew !== '') {
          obj.isNewForm = this.valueAddedIsNew === 'true' ? true : false
        }
        if (this.valueAddedPrerequisite) {
          preFile = await this.uploadFile(this.valueAddedPrerequisite)
        }

        if (this.valueAddedDescription !== '') {
          obj.description = this.valueAddedDescription
        }

        if (this.valueAddedActivityCode) {
          obj.activityCode = this.valueAddedActivityCode
        }

        if (this.valueAddedTypeOfActivity !== "Other" && this.valueAddedTypeOfActivity !== '') {
          obj.typeOfActivity = this.valueAddedTypeOfActivity
        }
        if (this.valueAddedActivityLevelInput) {
          obj.typeOfActivity = this.valueAddedActivityLevelInput
        }

        if (this.valueAddedTotalCredits !== null && this.valueAddedTotalCredits !== '') {
          obj.credits = Number(this.valueAddedTotalCredits)
        }

        if (this.valueAddedTotalDuration) {
          obj.duration = Number(this.valueAddedTotalDuration)
        }

        if (preFile === undefined) {
          preFile = []
        }
        obj.prerequisite = [...preFile, ...this.tempValueAddedPrerequisite]

        if (this.valueAddedPO) {
          obj.mapActivityPO = this.mappedPOs
        }

        if (this.valueAddedCO) {
          obj.valueAddedCO = this.mappedCOs
        }

        if (this.valueAddedPSO) {
          obj.mapActivityPSO = this.mappedPSOs
        }

        if (this.valueAddedLevelActivity === 'Course Level' && this.valueAddedBloomCo !== '') {
          obj.mapActivityBlooms = this.mappedBlooms
        }

        if (this.valueAddedLevelActivity !== 'Course Level' && this.valueAddedBloomPO !== '') {
          obj.mapActivityBlooms = this.mappedBlooms
        }

        if (this.valueAddedGap) {
          obj.gap = this.valueAddedGap
        }

        if (this.selectedSubject) {
          this.allSubjectOfDept.subjects.map((data) => {
            if (data.subjectName === this.selectedSubject)
              obj.course = data.subjectId
          })
        }

        if (this.valueAddedFeedbackValue !== '') {
          if (this.valueAddedFeedbackValue === 'true') {
            obj.createFeedbackForm = true
          } else {
            obj.createFeedbackForm = false
          }
        }

        if (this.valueAddedAssignFaculty.length > 0) {
          obj.assignedFaculty = []
          this.facultyData.map((data) => {
            if (data.fullName === this.valueAddedAssignFaculty)
              obj.assignedFaculty[0] = data.uId
          })
        }
      }
      try {
        const res = await this.valueAddedActivityInstance.updateValueAddedActivity(obj)
        showStatus('Value added course updated successfully', 3000, 'success', this)
        this.disabledEditDialog = false
        this.dialogValueAddedEdit = false
        this.disabledForm = false

        // await this.fetchData()
        this.$router.push({
          name: 'activityAddSubjectInstituteLevel',

        });

      } catch (error) {
        console.log('error', error);
        showStatus('Value added course not updated', 3000, 'error', this)
        this.disabledEditDialog = false
        this.dialogValueAddedEdit = false
        this.disabledForm = false
        this.$router.push({
          name: 'activityAddSubjectInstituteLevel',

        });

      }

    },
    editValueAddedActivity(item) {
      console.log('item', item);

      this.valueAddedPrerequisite = []
      this.valueAddedActivityId = item.valueAddedActivityId
      this.dialogValueAddedEdit = true
      this.valueAddedTitle = item.title
      this.selectedDept = item.department
      this.valueAddedLevelActivity = item.levelOfActivity
      this.valueAddedIsForNaac = item.isForNaac === true ? 'true' : 'false'
      this.date = item.date ? item.date : ''

      if (item.framework) {

        if (this.framework.includes(item.framework)) {
          this.valueAddedFramework = item.framework
        } else {
          this.valueAddedFramework = 'Other'
          this.valueAddedFrameworkByInput = item.framework
        }
      }

      if (item.isNewForm !== undefined) {
        this.valueAddedIsNew = item.isNewForm ? 'true' : 'false'
      }

      if (item.description) {
        this.valueAddedDescription = item.description
      }
      if (item.activityCode) {
        this.valueAddedActivityCode = item.activityCode
      }

      if (item.typeOfActivity) {
        if (this.typeOfActivitySelect.includes(item.typeOfActivity)) {
          this.valueAddedTypeOfActivity = item.typeOfActivity
        } else {
          this.valueAddedTypeOfActivity = 'Other'
          this.valueAddedActivityLevelInput = item.typeOfActivity
        }
      }
      if (item.credits) {

        this.valueAddedTotalCredits = item.credits
      }
      if (item.duration) {

        this.valueAddedTotalDuration = item.duration
      }
      if (item.prerequisite) {

        this.tempValueAddedPrerequisite = item.prerequisite
      }
      if (item.mapActivityPO) {

        this.mappedPOs = item.mapActivityPO
      }
      if (item.valueAddedCO) {

        this.mappedCOs = item.valueAddedCO
      }
      if (item.mapActivityPSO) {

        this.mappedPSOs = item.mapActivityPSO
      }
      if (item.mapActivityBlooms) {

        this.mappedBlooms = item.mapActivityBlooms
      }
      if (item.mapActivityBlooms) {

        this.valueAddedBloomPO = item.mapActivityBlooms
      }
      if (item.gap) {

        this.valueAddedGap = item.gap
      }
      if (item.course) {

        this.selectedSubject = item.course
      }

      if (item.createFeedbackForm !== '') {

        this.valueAddedFeedbackValue = item.createFeedbackForm ? 'true' : 'false'
      } else {
        this.valueAddedFeedbackValue = ''

      }
    },

    clearAllField() {
      this.date = ''
      this.valueAddedIsNew = ''
      this.valueAddedFramework = ''
      this.valueAddedIsForNaac = ''
      this.valueAddedTitle = ''
      this.valueAddedDescription = ''
      this.valueAddedActivityCode = ''
      this.valueAddedTypeOfActivity = ''
      this.selectedSubject = ''
      this.valueAddedAssignFaculty = []
      this.valueAddedTotalCredits = null
      this.valueAddedTotalDuration = null
      this.valueAddedPrerequisite = []
      this.valueAddedCO = []
      this.valueAddedPO = []
      this.valueAddedPSO = []
      this.valueAddedBloomPO = []
      this.valueAddedBloomCo = []
      this.valueAddedGap = ''
      this.valueAddedFeedbackValue = ''
    },
    editValueAddedCancelDialog() {
      this.date = ''
      this.valueAddedActivityLevelInput = ''
      this.valueAddedTitle = ''
      this.valueAddedDescription = ''
      this.valueAddedLevelActivity = ''
      this.valueAddedActivityCode = ''
      this.valueAddedTypeOfActivity = ''
      this.selectedSubject = ''
      this.valueAddedAssignFaculty = []
      this.valueAddedTotalCredits = null
      this.valueAddedTotalDuration = null
      this.valueAddedTotalPrerequisite = []
      this.valueAddedCO = []
      this.valueAddedPO = []
      this.valueAddedPSO = []
      this.valueAddedBloomPO = []
      this.valueAddedBloomCo = []
      this.valueAddedGap = ''
      this.valueAddedFeedbackValue = ''

      this.dialogValueAddedEdit = false

    },
    async createValueAddedActivity() {

      let obj = {}
      let preFile = []
      if (this.valueAddedTitle === '' || this.valueAddedLevelActivity === '' || this.valueAddedIsForNaac == '' || this.valueAddedIsNew === '' || this.date === '') {
        alert('Please fill required field')
      } else {
        this.disabledCreateValueAdded = true
        this.disabledForm = true
        if (this.valueAddedPrerequisite) {
          preFile = await this.uploadFile(this.valueAddedPrerequisite)
        }
        obj.instituteId = this.selectedInstitute
        obj.semId = this.selectedSemId
        obj.department = this.selectedDept
        obj.title = this.valueAddedTitle
        obj.levelOfActivity = this.valueAddedLevelActivity
        obj.isForNaac = this.valueAddedIsForNaac === 'true' ? true : false
        obj.date = this.date

        if (this.valueAddedFramework !== '') {
          if (this.valueAddedFramework === 'Other') {
            obj.framework = this.valueAddedFrameworkByInput
          } else {
            obj.framework = this.valueAddedFramework
          }
        }
        if (this.valueAddedIsNew !== '') {
          obj.isNewForm = this.valueAddedIsNew === 'true' ? true : false
        }
        if (this.valueAddedDescription !== '') {
          obj.description = this.valueAddedDescription
        }

        if (this.valueAddedActivityCode) {
          obj.activityCode = this.valueAddedActivityCode
        }

        if (this.valueAddedTypeOfActivity !== "Other" && this.valueAddedTypeOfActivity !== '') {
          obj.typeOfActivity = this.valueAddedTypeOfActivity
        }
        if (this.valueAddedActivityLevelInput) {
          obj.typeOfActivity = this.valueAddedActivityLevelInput
        }

        if (this.valueAddedTotalCredits !== null && this.valueAddedTotalCredits !== '') {
          obj.credits = Number(this.valueAddedTotalCredits)
        }

        if (this.valueAddedTotalDuration) {
          obj.duration = Number(this.valueAddedTotalDuration)
        }

        if (preFile) {
          obj.prerequisite = preFile
        }

        if (this.mappedPOs) {
          obj.mapActivityPO = this.mappedPOs
        }

        if (this.mappedCOs) {
          obj.valueAddedCO = this.mappedCOs
        }

        if (this.mappedPSOs) {
          obj.mapActivityPSO = this.mappedPSOs
        }

        if (this.mappedBlooms) {
          obj.mapActivityBlooms = this.mappedBlooms
        }



        if (this.valueAddedGap) {
          obj.gap = this.valueAddedGap
        }

        if (this.selectedSubject) {
          this.allSubjectOfDept.subjects.map((data) => {
            if (data.subjectName === this.selectedSubject)
              obj.course = data.subjectId
          })
        }

        if (this.valueAddedFeedbackValue !== '') {
          if (this.valueAddedFeedbackValue === 'true') {
            obj.createFeedbackForm = true
          } else {
            obj.createFeedbackForm = false
          }
        }

        if (this.valueAddedAssignFaculty.length > 0) {
          this.facultyData.map((data) => {
            if (data.fullName === this.valueAddedAssignFaculty)
              obj.assignedFaculty = data.uId
          })
        }
        try {
          const res = await this.valueAddedActivityInstance.createValueAddedActivity(obj)
          this.disabledCreateValueAdded = false
          this.dialogValueAdded = false
          this.disabledForm = false
          if (this.createFeedbackForm) {
            this.$router.push({
              name: 'activityCreateFBForm',
            });

          } else {

            this.$router.push({
              name: 'activityAddSubjectInstituteLevel',
            });
          }
          // await this.fetchData()
        } catch (error) {
          console.log('error', error);
          this.dialogValueAdded = false
          this.disabledCreateValueAdded = false
          this.disabledForm = false

          if (this.createFeedbackForm) {
            this.$router.push({
              name: 'activityCreateFBForm',
            });
          } else {

            this.$router.push({
              name: 'activityAddSubjectInstituteLevel',
            });
          }
        }
      }
    },
    async valueAddedDialog() {
      this.dialogValueAdded = true
      this.valueAddedActivityLevelInput = ''
      this.valueAddedTitle = ''
      this.valueAddedDescription = ''
      this.valueAddedLevelActivity = ''
      this.valueAddedActivityCode = ''
      this.valueAddedTypeOfActivity = ''
      this.selectedSubject = ''
      this.valueAddedAssignFaculty = []
      this.valueAddedTotalCredits = null
      this.valueAddedTotalDuration = null
      this.valueAddedPrerequisite = []
      this.valueAddedCO = []
      this.valueAddedPO = []
      this.valueAddedPSO = []
      this.valueAddedBloomPO = []
      this.valueAddedBloomCo = []
      this.valueAddedGap = ''
      this.valueAddedFeedbackValue = ''

    },
    async fetchData() {

      try {
        this.allSemData = await this.SemesterRepositoryInstance.getCoursebySemesters({
          instituteId: this.selectedInstitute
        })
        this.allSemName = []
        this.allSemData.map((data) => {
          this.allSemName.push(data.semName)

        })


        if (this.Props_Edit) {
          this.allDeptOfSem = []
          this.allSemData.map((data) => {
            if (data.semId === this.Props_Data.semId) {
              this.selectedSem = data.semName
              if (data.semName === this.selectedSem) {
                this.allDeptOfSem.push(data.department)
              }
              this.selectedDept = data.department
            }
          })

          if (this.Props_Data) {

            try {

              this.allSubjectOfDept = []
              this.allSubjectOfDept = await this.SubjectRepositoryInstance.getSubjectsOfADepartment({
                instituteId: this.Props_Data.instituteId,
                semId: this.Props_Data.semId,
                department: this.Props_Data.department,
              })
              this.allSubjectNames = []
              this.allSubjectNames = this.allSubjectOfDept.subjects.map((data) => {
                if (this.Props_Data.course && this.Props_Data.course === data.subjectId) {
                  this.selectedSubject = data.subjectName

                }
                return data.subjectName
              })
            } catch (error) {
              console.log(error)
            }
          }

          try {
            this.facultyData = []
            let facultyData = await this.SemesterUserInstance.getFacultiesOfADepartment({
              instituteId: this.Props_Data.instituteId,
              semId: this.Props_Data.semId,
              department: this.Props_Data.department,
            })

            let facultyUid = facultyData.map((data) => {
              return data.uId
            })
            this.facultyData = await this.userRepositoryInstance.getFullNameOfUsers({
              uIds: facultyUid
            })
            this.namesOfFaculty = []
            this.namesOfFaculty = this.facultyData.map((data) => {
              if (this.Props_Data.assignedFaculty[0] === data.uId) {
                this.valueAddedAssignFaculty = data.fullName
              }
              return data.fullName

            })


          } catch (error) {
            console.log(error);
          }

        }



      } catch (error) {
        console.log('error', error);
      }


    },

    async uploadFile(fileArr) {
      try {
        let urlLinkArr = [];
        if (fileArr.length > 0) {

          let data = await this.uploadToDigitalOceanInstance.upload(fileArr, this.userData.uId)
          return data

        }
      } catch (error) {
        console.log('error')
        let arr = []
        return arr
      }


    },

  }
}
</script>
<style scoped src="./activityValueAdded.css"></style>