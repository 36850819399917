<!-- Documentation: https://studiumtech.atlassian.net/wiki/spaces/LMS1/pages/197591043/Select -->

<template>
  <div :style="[widthStyle]">
    <div v-if="showCustomLabel" :style="labelStyle">
      {{ label }}
    </div>
    <v-autocomplete
      v-if="searchable"
      :items="items"
      :label="label"
      :value="value"
      :placeholder="placeholder"
      :multiple="multiple"
      :disabled="disabled"
      :dense="dense"
      :clearable="clearable"
      :chips="chips"
      :background-color="backgroundColor"
      :return-object="returnObject"
      :item-text="getItemText"
      :item-value="itemValue"
      :append-icon="appendIcon"
      :append-outer-icon="appendInnerIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :loading="loading"
      :width="width"
      :no-data-text="noDataText"
      :full-width="fullWidth"
      :hide-details="hideDetails"
      :item-disabled="itemDisabled"
      :solo="solo"
      :flat="flat"
      :outlined="outlined"
      :solo-label-font-size="soloLabelFontSize"
      :hint="hint"
      :readonly="readonly"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :rules="computedRules"
    ></v-autocomplete>
    <v-select
      v-else
      :items="items"
      :label="label"
      :value="value"
      :placeholder="placeholder"
      :multiple="multiple"
      :disabled="disabled"
      :dense="dense"
      :clearable="clearable"
      :chips="chips"
      :background-color="backgroundColor"
      :return-object="returnObject"
      :item-text="getItemText"
      :item-value="itemValue"
      :append-icon="appendIcon"
      :append-outer-icon="appendInnerIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :loading="loading"
      :width="width"
      :no-data-text="noDataText"
      :full-width="fullWidth"
      :hide-details="hideDetails"
      :item-disabled="itemDisabled"
      :solo="solo"
      :flat="flat"
      :outlined="outlined"
      :solo-label-font-size="soloLabelFontSize"
      :hint="hint"
      :readonly="readonly"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :rules="computedRules"
    ></v-select>
  </div>
</template>
  
  <script>
import arrayOps from "../../../Services/Utils/arrayOps";
export default {
  name: "Select",
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "#E1E2F8",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: "value",
    },
    appendIcon: {
      type: String,
      default: "mdi-chevron-down",
    },
    appendInnerIcon: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
    noDataText: {
      type: String,
      default: "No Data Available",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    itemDisabled: {
      type: [String, Array, Function],
      default: "",
    },
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    soloLabelFontSize: {
      type: [Number, String],
      default: 16, // in pixels(px)
    },
    hint: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: [String, Array, Function],
      default: "text",
    },
    sortItemsBy: {
      type: String,
      default: "",
    },
    sortOrder: {
      type: String,
      default: "asc",
    },
  },
  computed: {
    showCustomLabel() {
      let flag = false;
      if (this.solo && this.label !== "") {
        flag = true;
        this.placeholder = "";
      }
      return flag;
    },
    widthStyle() {
      return typeof this.width === "number" ? { width: `${this.width}px` } : { width: this.width };
    },
    // sortedItems() {
    //   let array = [];
    //   if (arrayOps.isArrayOfObjects(this.items)) {
    //     if (this.sortItemsBy) {
    //       array = arrayOps.sortByProperty(
    //         this.items,
    //         this.sortItemsBy,
    //         this.sortOrder
    //       );
    //     }
    //   } else {
    //     array = arrayOps.sortArray(this.items).result;
    //   }
    //   // if(this.items)
    //   return array;
    // },
    labelStyle() {
      const customFontSize =
        typeof this.soloLabelFontSize === "number"
          ? `${this.soloLabelFontSize}px`
          : `${Number(this.soloLabelFontSize)}px`;
      return {
        fontSize: customFontSize,
      };
    },
    computedRules() {
      // Return the rules array
      return this.rules;
    },
    getItemText() {
      return (item) => {
        if (typeof this.itemText === "function") {
          return this.itemText(item);
        } else if (Array.isArray(this.itemText)) {
          return this.itemText.map((key) => item[key]).join(" ");
        } else {
          return item[this.itemText];
        }
      };
    },
  },
  methods: {
    onInput(value) {
      if (this.multiple && !Array.isArray(value)) {
        value = [value];
      }
      this.$emit("input", value);
    },
  },
};
</script>
  
  <style scoped>
/* Add any custom styles here */
</style>
  