const objectOperations = {
  removeFields(obj, fieldsToRemove) {
    let errors = [];
    // Check if the first parameter is an object
    if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
      errors.push("First parameter must be a non-null object");
    }

    // Check if the second parameter is an array
    if (!Array.isArray(fieldsToRemove)) {
      errors.push("Second parameter must be an array of strings");
    }

    // Create a shallow copy of the original object to avoid mutating it
    let newObj = { ...obj };

    // Iterate through the array of fields to remove
    fieldsToRemove.forEach((field) => {
      // Check if the field is a string
      if (typeof field !== "string") {
        errors.push(`${field} must be a string.`);
      }

      // Delete the field if it exists in the object
      if (field in newObj) {
        delete newObj[field];
      }
    });

    // Return the modified object
    return { modifiedObject: newObj, errors };
  },
};

export default objectOperations;