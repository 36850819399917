import axios from "axios";
import user from "../Store/Modules/user";
import liveData from "../Store/Modules/liveData";
import instituteData from "../Store/Modules/instituteData"

const serverUrlForApi = process.env.VUE_APP_SERVER_V3_URL + "/studium/api/v3";
const token = JSON.parse(window.localStorage.getItem("token")) || { token: "" }

const getHeaders = () => {
  const token = JSON.parse(window.localStorage.getItem("token")) || { token: "" }
  return {
    userType: "admin-LMS",
    userId: user?.state?.userData?.uId || null,
    instituteId: liveData?.state?.selectedInstitute || null,
    instituteName: instituteData?.state?.instituteName || null,
    authorization: `JWT ${token?.token}`
  };
};

export default {
  serverUrl: serverUrlForApi,
  getRequest: async (apiName, data) => {
    const res = await axios.get(serverUrlForApi + apiName, {
      params: data,
      headers: getHeaders(),
    });
    return res.data;
  },

  postRequest: async (apiName, data, custoHeaders) => {
    const config = {};
    if (custoHeaders) {
      config.headers = { ...getHeaders(), ...custoHeaders };
    } else config.headers = getHeaders();
    const res = await axios.post(serverUrlForApi + apiName, data, config);
    return res.data;
  },

  putRequest: async (apiName, data) => {
    const res = await axios.put(serverUrlForApi + apiName, data, {
      headers: getHeaders(),
    });
    return res.data;
  },

  deleteRequest: async (apiName, data) => {
    const res = await axios.delete(serverUrlForApi + apiName, {
      params: data,
      headers: getHeaders(),
    });
    return res;
  },
};
