import ApiNames from "../ApiNames/ActivityLogger";
import v3 from "../NetworkManager/apiV3";

class ActivityLogger {
  constructor(context) {
    this.context = context;
  }

  async createMultipleLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.postRequest(ApiNames.createMultipleLogs, data);
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getTimetableLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.getRequest(ApiNames.getTimetableLogs, data, {
      "Content-Type": "application/json",
    });
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getLectureLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.getRequest(ApiNames.getLectureLogs, data, {
      "Content-Type": "application/json",
    });
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getFacultyAllocationLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.getRequest(
      ApiNames.getFacultyAllocationLogs,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getStudentCourseAllocationLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.getRequest(
      ApiNames.getStudentCourseAllocationLogs,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createLogForSingleUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.postRequest(ApiNames.createLogForSingleUser, data);
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createLogsForMultipleUsers(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.postRequest(
      ApiNames.createLogsForMultipleUsers,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSemesterUserLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.postRequest(ApiNames.getSemesterUserLogs, data, {
      "Content-Type": "application/json",
    });
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default ActivityLogger;
