
import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/SubjectInstituteLevel'

class SubjectInstituteLevel {
  constructor (context) {
    this.context = context
  }

  async createSubjectDocument (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSubjectDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async bulkCreateSubjectDocument (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.bulkCreateSubjectDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getSubjectDocumentOfAInstitute (data) {
    // console.log('1');
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectDocumentOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }
  async getSingleSubjectDocument (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSingleSubjectDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateSubjectDocument (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateSubjectDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async deleteSubjectDocument (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteSubjectDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }


}

export default SubjectInstituteLevel