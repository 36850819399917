const numOps = {
  stringToNumber(input) {
    const number = Number(input); // Attempt to convert the string to a number
    if (!isNaN(number)) { // Check if the result is a valid number
      return number;
    }
    return null; // Return null if conversion is not possible
  },
  isValidNumber(input) {
    try {
      // Ensure the input is a string or can be converted to one
      if (input === null || input === undefined) {
        throw new Error("Input is null or undefined.");
      }

      const inputStr = input.toString().trim(); // Convert to string and trim whitespace

      if (inputStr === "") {
        throw new Error("Input is an empty string.");
      }

      const regex = /^(\d+(\.\d+)?|\.\d+)$/;

      // Test against the regex
      const isValid = regex.test(inputStr);

      // Return the validation result
      return isValid;
    } catch (error) {
      console.error(`Validation error: ${error.message}`);
      return false; // Return false for invalid inputs
    }
  },
  roundOff(value) {
    if (!isNaN(value)) {
      return Math.round(value);
    }
  },

  roundOffUptoTwoDecimals(value) {
    if (!isNaN(value)) {
      return Math.round(value * 100) / 100;
    }
  },

  roundOffUptoOneDecimals(value) {
    if (!isNaN(value)) {
      return Math.round(value * 10) / 10;
    }
  },

  // roundOff(value, decimals = 0, fallback = 0) {
  //   if (typeof value !== "number" || isNaN(value)) {
  //     return fallback; // Return the fallback value if the input is invalid
  //   }

  //   if (
  //     typeof decimals !== "number" ||
  //     decimals < 0 ||
  //     !Number.isInteger(decimals)
  //   ) {
  //     throw new RangeError("Decimals must be a non-negative integer.");
  //   }

  //   const factor = Math.pow(10, decimals);
  //   return Math.round(value * factor) / factor;
  // },

  calculatePercentage(partialValue, totalValue) {
    return totalValue !== 0
      ? this.roundOffUptoTwoDecimals((100 * partialValue) / totalValue)
      : 0;
  },

  calculatePercentageV2(partialValue, totalValue, decimals = 2, fallback = 0) {
    if (typeof partialValue !== "number" || typeof totalValue !== "number") {
      return fallback; // Return the fallback value if inputs are invalid
    }

    if (totalValue === 0) {
      return fallback; // Avoid division by zero
    }

    if (
      typeof decimals !== "number" ||
      decimals < 0 ||
      !Number.isInteger(decimals)
    ) {
      throw new RangeError("Decimals must be a non-negative integer.");
    }

    const percentage = (100 * partialValue) / totalValue;
    const factor = Math.pow(10, decimals);
    return Math.round(percentage * factor) / factor;
  },

  calculateScoreFromPercentage(totalMarks = 100, percentage = 0) {
    if (typeof totalMarks !== "number" || typeof percentage !== "number") {
      throw new TypeError("Both totalMarks and percentage must be numbers.");
    }

    if (totalMarks < 0 || percentage < 0 || percentage > 100) {
      throw new RangeError(
        "totalMarks must be non-negative, and percentage must be between 0 and 100."
      );
    }

    return (percentage / 100) * totalMarks;
  },

  isValidNumber(input) {
    try {
      // Ensure the input is a string or can be converted to one
      if (input === null || input === undefined) {
        throw new Error("Input is null or undefined.");
      }

      const inputStr = input.toString().trim(); // Convert to string and trim whitespace

      if (inputStr === "") {
        throw new Error("Input is an empty string.");
      }

      const regex = /^(\d+(\.\d+)?|\.\d+)$/;

      // Test against the regex
      const isValid = regex.test(inputStr);

      // Return the validation result
      return isValid;
    } catch (error) {
      console.error(`Validation error: ${error.message}`);
      return false; // Return false for invalid inputs
    }
  },
  addLeadingZeros(number, totalLength) {
    // Validate the inputs
    if (typeof number !== "number" || isNaN(number)) {
      throw new Error("The 'number' parameter must be a valid number.");
    }

    if (
      typeof totalLength !== "number" ||
      totalLength <= 0 ||
      !Number.isInteger(totalLength)
    ) {
      throw new Error(
        "The 'totalLength' parameter must be a positive integer."
      );
    }

    const numberAsString = number.toString(); // Convert the number to a string
    const leadingZeros = totalLength - numberAsString.length; // Calculate how many zeros are needed

    if (leadingZeros > 0) {
      const result = "0".repeat(leadingZeros) + numberAsString; // Add the leading zeros
      return result; // Return the result as a string
    }

    return numberAsString; // Return the number as a string if no zeros are needed
  },
  numberToRoman(num) {
    // Roman numeral mapping for numbers 1 to 10
    const romanMapping = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
      8: "VIII",
      9: "IX",
      10: "X",
    };

    // Validate input
    if (typeof num !== "number" || !Number.isInteger(num)) {
      throw new Error("Input must be an integer.");
    }

    if (num < 1 || num > 10) {
      throw new Error("Input must be between 1 and 10.");
    }

    // Return the Roman numeral
    return romanMapping[num];
  }
};

export default numOps;
