<!-- Documentation: https://studiumtech.atlassian.net/wiki/spaces/LMS1/pages/197656590/Textarea -->

<template>
    <div>
      <div v-if="showCustomLabel" :style="labelStyle">
        {{ label }}
      </div>
      <v-textarea
        :type="type"
        :label="label"
        :auto-grow="autogrow"
        :placeholder="placeholder"
        :dense="dense"
        :solo="solo"
        :hide-details="hideDetails"
        :flat="flat"
        :value="value"
        @input="$emit('input', $event)"
        :background-color="backgroundColor"
        :class="customClass"
        v-bind="$attrs"
      >
        <slot></slot>
      </v-textarea>
    </div>
  </template>
    
    <script>
  export default {
    name: "VTextField",
    props: {
      value: {
        type: [String, Number],
        default: "",
      },
      type: {
        type: String,
        default: "text",
      },
      label: {
        type: String,
        default: "Text Field",
      },
      placeholder: {
        type: String,
        default: "",
      },
      dense: {
        type: Boolean,
        default: false,
      },
      solo: {
        type: Boolean,
        default: true,
      },
      autogrow: {
        type: Boolean,
        default: true,
      },
      hideDetails: {
        type: Boolean,
        default: false,
      },
      flat: {
        type: Boolean,
        default: true,
      },
      backgroundColor: {
        type: String,
        default: "#E1E2F8",
      },
      customClass: {
        type: String,
        default: "",
      },
      soloLabelFontSize: {
        type: [Number, String],
        default: 16, // in pixels(px)
      },
    },
    data() {
      return {
        showCustomLabel: false,
      };
    },
    created() {
      if (this.solo && this.label !== "") {
        this.showCustomLabel = true;
      }
    },
    computed: {
      labelStyle() {
        const customFontSize = typeof this.soloLabelFontSize === 'number' ? `${this.soloLabelFontSize}px` : `${Number(this.soloLabelFontSize)}px`;
        return {
          fontSize: customFontSize,
        };
      },
      computedRules() {
        // Return the rules array
        return this.rules;
      }
    },
  };
  </script>
  
  <style scoped>
  </style>