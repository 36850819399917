<template src='./activitySelectSubjectsForExam.html'>
</template>
<script>
import { subjectTypes } from '../../../Constants/Utils/Statics'
import SubjectRepository from '../../../Repository/Subject'
import ExamSubjectRepository from '../../../Repository/ExamSubject'
import showStatus from '../../../NetworkManager/showStatus'
import inputContainer from '../../../Components/inputContainer'
import UserRepository from '../../../Repository/User'
export default {
  name: 'activitySelectSubjectsForExam',
  components: {
    inputContainer
  },
  data () { 
    return {
      selectedInstitute: '',
      selectedSemester: {},
      selectedExam: null,
      selectedExamSubject: null,
      subjects: [],
      examSubjects: [],
      availableSubjects: [],
      selectedSubject: '',
      showSubjectAdd: false,
      showSubjectRemove: false,
      appliedSubjects: [],
      appliedSubjectsMapping: {},
      subjectTypes: subjectTypes,
      subjectGroups: {},
      loading: true,
      loading2: false,
      showApplicableUsers: false,
      applicableUsers: [],
      userMappings: {},
      subjectGroupsIdToNameMapping: {},
      headers: [
        {
          text: '',
          value: 'mark'
        },
        {
          text: 'Subject Name',
          value: 'subjectName'
        },
        {
          text: 'Subject Code',
          value: 'subjectCode'
        },
        {
          text: 'Subject Type',
          value: 'subjectType'
        },
        {
          text: 'Exam Mode',
          value: 'examMode'
        },
        {
          text: 'Applicable Students',
          value: 'applicableUsers'
        },
        {
          text: 'Total marks',
          value: 'totalObtainableMarks'
        }
      ],
      headers2: [
        // {
        //   text: 'uId',
        //   value: 'uId'
        // },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Remove',
          value: 'remove'
        }
      ],
      selectedUserToRemove: {},
      confirmRemoveUser: false,
      search: ''
    }
  },
  created () {
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.fetchSubjects()
  },
  methods: {
    async fetchSubjects () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedExam.criteria.department,
        courseYear: this.selectedExam.criteria.courseYear ? this.selectedExam.criteria.courseYear : undefined
      }
      const objToPush2 = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId
      }
      console.log(objToPush, objToPush2)
      this.subjects = []
      try {
        this.subjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush)
      } catch (err) {
        console.log(err)
      }
      this.examSubjects = []
      try {
        this.examSubjects = await this.examSubjectRepositoryInstance.getSubjectsForAnExam(objToPush2) || []
      } catch (err) {
        console.log(err)
        this.examSubjects = []
      }
      console.log('this.examSubjects', this.examSubjects)
      this.availableSubjects = []
      this.appliedSubjects = []
      this.appliedSubjectsMapping = {}
      this.examSubjects.forEach((sub) => {
        // this.appliedSubjects.push(sub.department + '_' + sub.courseYear + '_' + sub.division + '_' + sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType)
        // this.appliedSubjects.push(sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType + '_' + sub.criteria.courseYear)
        this.appliedSubjectsMapping[sub.subjectId] = sub
        this.appliedSubjectsMapping[sub.subjectId].isOnline2 = sub.isOnline ? 'Online' : 'Offline'
        this.appliedSubjects.push(sub.subjectId)
      })
      // for (let i = 0; i < this.examSubjects.length; i++) {
      //   let sub = this.examSubjects[i]
      //   console.log(sub)
      // }
      this.subjectGroups = {}
      this.subjects.forEach((sub) => {
        // const subCode = sub.subjectCode.split('-')[0]
        const subCode = sub.groupId
        if (this.subjectGroups[subCode]) {
          this.subjectGroups[subCode].push(sub)
        } else {
          this.subjectGroups[subCode] = []
          this.subjectGroups[subCode].push(sub)
        }
        this.subjectGroupsIdToNameMapping[subCode] = {
          groupName: sub.groupName,
          department: sub.department,
          courseYear: sub.courseYear
        }
      })
      console.log('this.subjects',this.subjects);
      // for (let i = 0; i < this.subjects.length; i++) {
      //   let sub = this.subjects[i]
      // let subString = sub.department + '_' + sub.courseYear + '_' + sub.division + '_' + sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType
      // let subString = sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType
      // if (this.appliedSubjects.indexOf(subString) === -1) {
      //   this.availableSubjects.push(subString)
      // }
      // }
      this.loading = false
    },
    async showApplicableUsersFun (item) {
      this.loading = true
      this.selectedExamSubject = item
      this.applicableUsers = []
      await this.getNamesOfUsers()
      this.showApplicableUsers = true
    },
    async getNamesOfUsers () {
      console.log('Hello')
      let promises = []
      this.selectedExamSubject.applicableUsers.forEach(user => {
        promises.push(
          this.userRepositoryInstance.getFullNameOfUser({ uId: user })
        )
      })
      promises = await Promise.allSettled(promises)
      console.log(promises)
      for (let i = 0; i < promises.length; i++) {
        this.applicableUsers[i] = {
          name: promises[i].value,
          uId: this.selectedExamSubject.applicableUsers[i]
        }
      }
      this.applicableUsers = this.applicableUsers.slice()
      this.loading = false
      // for (let i = 0; i < this.selectedExamSubject.applicableUsers.length; i++) {
      // const name = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.selectedExamSubject.applicableUsers[i] })
      // this.applicableUsers[i] = {
      //   uId: this.selectedExamSubject.applicableUsers[i],
      //   name: name
      // }
      // }
    },
    removeStudentStep1 (stud) {
      this.selectedUserToRemove = stud
      this.confirmRemoveUser = true
    },
    async removeStudent (stud) {
      this.loading2 = true
      this.confirmRemoveUser = false
      const tempObj = JSON.stringify(this.selectedExamSubject)
      const tempObj2 = JSON.parse(tempObj)
      tempObj2.applicableUsers = this.remove_array_element(
        tempObj2.applicableUsers,
        tempObj2.applicableUsers.indexOf(stud.uId)
      )
      // console.log(tempObj, tempObj2)
      // Call APi to update examSubject
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2)
        this.selectedExamSubject = tempObj2
        this.appliedSubjectsMapping[this.selectedExamSubject.subjectId] = tempObj2
        this.applicableUsers = this.remove_array_element(
          this.applicableUsers,
          this.applicableUsers.indexOf(stud)
        )
        this.fetchSubjects()
      } catch (err) {
        console.log(err)
        showStatus('Could not remove student. Try again.', 1000, 'error', this)
      }
      this.loading2 = false
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
    },
    remove_array_element (array, n) {
      if (n > -1) {
        array.splice(n, 1)
      }
      return array
    },
    async updateExamSubject (examSubject) {
      console.log(examSubject)
      this.loading = true
      examSubject.isOnline = (examSubject.isOnline2 === 'Online')

      const objToPush = JSON.parse(JSON.stringify(examSubject))

      delete objToPush.isOnline2
      console.log("updateExamSubject",objToPush)
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(
          objToPush
        )
        showStatus('Changes saved successfully.', 1000, 'success', this)
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    async updateExamSubjectTotalmarks (examSubject) {
      console.log(examSubject)
      this.loading = true
      const objToPush = JSON.parse(JSON.stringify(examSubject))
      console.log("updateExamSubjectTotalmarks",objToPush)
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(
          objToPush
        )
        showStatus('Changes saved successfully.', 1000, 'success', this)
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    async addSubject (sub) {
      this.loading = false
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId,
        criteria: {
          department: this.selectedExam.criteria.department,
          courseYear: this.selectedSubject.courseYear
        },
        // subjectCode: this.selectedSubject.subjectCode,
        // subjectName: this.selectedSubject.subjectName,
        // subjectType: this.selectedSubject.subjectType,
        subjectId: this.selectedSubject.subjectId,
        totalMarks: 0,
        isOnline: false,
        listOfTopics: [],
        questions: [],
        numberOfQuestionsToShow: 0,
        shuffle: false
      }
      console.log('This is an object to be pushed')
      console.log(objToPush, this.selectedSubject)
      try {
        await this.examSubjectRepositoryInstance.createExamSubject(objToPush)
      } catch (err) {
        console.log(err)
        showStatus('Error in adding subject', 1000, 'error', this)
      }
      // Call API to add subject in exam
      // this.examSubjects.push(res.data.examSubject)
      // this.availableSubjects = this.remove_array_element(this.availableSubjects, this.availableSubjects.indexOf(this.selectedSubject))
      // this.appliedSubjects.push(this.selectedSubject)
      this.showSubjectAdd = false
      this.fetchSubjects()
    },
    async removeSubject (sub) {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId,
        criteria: {
          department: this.selectedExam.criteria.department,
          courseYear: this.selectedSubject.courseYear
        },
        // subjectCode: this.selectedSubject.subjectCode,
        // subjectName: this.selectedSubject.subjectName,
        // subjectType: this.selectedSubject.subjectType
        subjectId: this.selectedSubject.subjectId
      }
      console.log(objToPush)
      try {
        await this.examSubjectRepositoryInstance.deleteExamSubject(objToPush)
      } catch (err) {
        console.log(err)
        showStatus('Error in deleting subject', 1000, 'error', this)
      }
      // this.examSubjects = this.remove_array_element(this.examSubjects, this.appliedSubjects.indexOf(this.selectedSubject))
      // this.appliedSubjects = this.remove_array_element(this.appliedSubjects, this.appliedSubjects.indexOf(this.selectedSubject))
      // this.availableSubjects.push(this.selectedSubject)
      this.showSubjectRemove = false
      this.fetchSubjects()
    },
    gotoEditExamSubjectQuestions () {
      // this.$router.push('/activityEditExamSubjectQuestions')
              this.$router.push('/activityDefineExamSubjects')

    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped src='./activitySelectSubjectsForExam.css'>
</style>
