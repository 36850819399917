<template src="./activityEditAdmission.html"> </template>
<script>
import inputContainer from '../../Components/inputContainer'
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SubjectsRepository from '../../Repository/Subject'
import { convertDate, convertToISTTime } from "../../Services/Utils/DateTimeFormatter";
export default {
  name: 'activityEditAdmission',
  components: {
    inputContainer

  },
  props: ['Propsitem', 'PropsSelectedSemester'],
  data() {
    return {
      progressBar: false,
      selectedInputType:'',
      addNewfieldsPopUp: false,
      editNewfieldsPopUp: false,
      progressBarCompleted: false,
      allSubjectFeesWithGst: 0,
      i: 0,
      createdField: {},
      oneTimePaymentDisc: '',
      admissionTitle: '',
      installmentDiscount: '',
      newInstallment: {
        installmentFeeAmount: null,
        installmentFeePercent: null
      },
      inputTypes: ['Text Field', 'File', 'Checkbox', 'Dropdown'],
      selectedAdmissionId: '',
      switch1: false,
      installmentCheck: false,
      startDate: '',
      installment: [],
      selectedInstallment: '',
      duration: [],
      selectedDuration: '',
      gstCheck: false,
      gstValues: ["0", "5", "12", "18", "28"],
      selectedGst: 0,
      allFormActivity: 0,
      semesters: '',
      isAllSubjectCheck: false,
      subjectWise: true,
      endDate: '',
      subjectsList: [],
      installmentDivideCheck: false,
      installmentPattern: [],
      installmentPatternArr: [],
      semestersList: [],
      selectedSemester: {},
      coursename: [],
      selectedCourseName: '',
      classname: [],
      selectedClassName: '',

      formActivity: [
        {
          id: 1,
          status: 1,
          isCompulsory: 1,
          name: 'FirstName'
        },
        {
          id: 2,
          status: 1,
          isCompulsory: 1,
          name: 'Middle Name'
        },
        {
          id: 3,
          status: 1,
          isCompulsory: 1,
          name: 'Last Name'
        },
        {
          id: 4,
          status: 1,
          isCompulsory: 1,
          name: 'Student Mobile'
        },
        {
          id: 5,
          status: 1,
          isCompulsory: 1,
          name: 'Student Email'
        },
        {
          id: 6,
          status: 0,
          isCompulsory: 0,
          name: 'Parent Full Name'
        },
        {
          id: 7,
          status: 0,
          isCompulsory: 0,
          name: 'Parent Email Id'
        },
        {
          id: 8,
          status: 0,
          isCompulsory: 0,
          name: 'Parent Mobile Number'
        },
        {
          id: 9,
          status: 0,
          isCompulsory: 0,
          name: 'Address'
        },
        {
          id: 10,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Full Name'
        },
        {
          id: 11,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Email Id'
        },
        {
          id: 12,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Mobile Number'
        },
        {
          id: 13,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Address'
        },
        {
          id: 14,
          status: 0,
          isCompulsory: 0,
          name: 'School/College Name'
        },
        {
          id: 15,
          status: 0,
          isCompulsory: 0,
          name: 'Class'
        },
        {
          id: 16,
          status: 0,
          isCompulsory: 0,
          name: 'Percentage'
        },

      ],
      selectedTab: 0,

    }
  },
  created() {
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.load()
    this.getSemesters()
    this.fetchAdmissionDetailsForEdit(this.Propsitem);
    this.getCoursebySemesters();
    this.getClassbySemesters();
    this.getInstallmentPattern()

  },
  methods: {
    selectInputType() {
      this.createdField = {
        id: this.formActivity[this.formActivity.length - 1].id + 1,
        status: 0,
        isCompulsory: 0,
        isToShow: 0,
        name: '',
        options : [{ value: '' }],
        type: this.selectedInputType
      }
      console.log('this.createdField', this.createdField);
    },
    async updateAll() {
      if (this.subjects.length == this.subjectsList.length) {
        this.isAllSubjectCheck = 1;
      }
      else {
        this.isAllSubjectCheck = 0;
      }

    },
    saveNewInput() {
      this.formActivity.push(this.createdField)
      this.clearStates()
    },
    clearStates() {
      this.addNewfieldsPopUp = false
      this.createdField = []
      this.selectedInputType = ''
      this.selectedInputName = ''
    },
    gotoAdmission() {
      this.$router.push({
        name: 'activityAdmission',
      });
    },
    removeOption(v) {
      const index = this.createdField.options.indexOf(v)
      this.createdField.options.splice(index, 1)
    },
    addOption() {
      this.createdField.options.push({ value: '' })
      console.log('this.createdField', this.createdField);
    },
    editAddedField(item){
      item.index = this.formActivity.indexOf(item)
      this.editNewfieldsPopUp = true
      this.selectedInputType = item.type
      this.createdField = item
      console.log('item', item);
    },
    removeAddedFormField(id){
      const index = this.formActivity.indexOf(id)
      this.formActivity.splice(index, 1)
    },
    clearEditStates(){
      this.editNewfieldsPopUp = false
    },
    updateNewInput(){
      this.editNewfieldsPopUp = false
      this.formActivity[this.createdField.index] = this.createdField
    },
    gotoAddNewFields() {
      this.addNewfieldsPopUp = true
      this.createdField = {
        name:'',
        type:''
      }
    },
    async getInstallmentcheck() {
      console.log("this.installmentCheck", this.installmentCheck);
    },
    async percentToRs() {
      this.installmentPattern.map((arr) => {
        arr.map((obj) => {
          obj.installmentFeeAmount = (this.allSubjectFeesWithGst / 100) * obj.installmentFeePercent
        })
      })
    },
    async rsToPercent() {
      this.installmentPattern.map((arr) => {
        arr.map((obj) => {
          obj.installmentFeePercent = obj.installmentFeeAmount / this.allSubjectFeesWithGst * 100
        })
      })
    },
    async getAllFromFieldCheck() {
      // this.allFormActivity = !this.allFormActivity
      this.formActivity.forEach((form) => {
        if (this.allFormActivity && form.status == 0) {
          form.status = 1;
        } else if (form.isCompulsory == 1) {
          form.status = 1;
        } else {
          form.status = 0;
        }
      });
    },

    async saveEditAdmission() {
      this.progressBar = true
      try {
        const objToPush = {
          admissionId: this.selectedAdmissionId,
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedCourseName,
          startDate: this.startDate,
          courseName: this.selectedClassName,
          subjectCompulsory: this.isAllSubjectCheck,
          endDate: this.endDate,
          status: this.switch1,

        }
        const saveAdmission = await this.admissionUserRepositoryInstance.saveEditAdmission(
          objToPush
        )
        this.saveEditPayment(saveAdmission.admissionId);
      }
      catch (err) {
        console.log(err)
      }

    },

    async saveEditPayment() {
      try {
        const objToPush = {
          admissionPaymentId: this.admissionPaymentId,
          admissionId: this.selectedAdmissionId,
          instalmentsNumber: this.selectedInstallment,
          instalmentDuration: this.selectedDuration,
          gstValue: this.selectedGst,
          installmentPattern: this.installmentPattern,
          oneTimePaymentDisc: this.oneTimePaymentDisc,
          installmentDiscount: this.installmentDiscount,
          status: this.switch1,
          allSubjectFeesWithGst: this.allSubjectFeesWithGst
        }
        const savePayment = await this.admissionUserRepositoryInstance.saveEditPayment(
          objToPush
        )
        this.saveEditSubject()
        if (this.startDate > this.endDate) {
          alert("start date")
        }
        else {
          console.log("false");
        }
      }

      catch (err) {
        console.log(err)
      }
    },

    async saveEditSubject() {
      try {
        const objToPush = {
          admissionId: this.selectedAdmissionId,
          subjectsLists: this.subjectsList,
        }
        const saveAdmission = await this.admissionUserRepositoryInstance.saveEditSubject(
          objToPush
        )
        this.saveEditFormActivity();
      }

      catch (err) {
        console.log(err)
      }
    },

    async saveEditFormActivity() {
      try {
        const objToPush = {
          admissionId: this.selectedAdmissionId,
          allFormActivity: this.allFormActivity,
          formActivity: this.formActivity
        }
        const saveEditFormActivity = await this.admissionUserRepositoryInstance.saveFormActivity(objToPush)
        this.progressBar = false
        this.progressBarCompleted = true
        const route = () => {
          this.$router.push({
            name: 'activityAdmission',
            params: {
              PropsSelectedSemester: this.selectedSemester,
              PropsSelectedinstituteId: this.selectedInstitute
            }
          });
        }
        setTimeout(route, 2000);
      }
      catch (err) {
        console.log(err)
      }
    },
    async addTotalfee(item) {
      console.log('hello from addtotal fee');
      let countfees = 0
      let gstvalue = this.selectedGst;
      this.subjectsList.filter(function (subject) {
        if (subject.subjectId == item.subjectId) {
          console.log('subject.totalFees', subject.totalFees);
          subject.totalFees = parseFloat(item.basicFees) + parseFloat(parseFloat(item.basicFees) * parseFloat(gstvalue)) / 100;
        }
        countfees = parseInt(countfees) + parseInt(subject.totalFees)
      });
      this.allSubjectFeesWithGst = countfees
      console.log(countfees)
    },

    async addBasicfee(item) {
      console.log('item', item);
      this.isSubjectListLoading = false;
      let gstvalue = this.selectedGst;
      this.subjectsList.filter(function (subject) {
        if (subject.subjectId == item.subjectId) {
          if (gstvalue > 0) {
            subject.basicFees = parseInt(item.basicFees)
          } else {
            subject.basicFees = parseInt(item.basicFees);
            subject.totalFees = parseInt(item.totalFees);
          }
        }
      });
      this.addTotalfee(item);
      this.isSubjectListLoading = true;
    },
    async setInstallmentPattrn() {

    },
    async getInstallmentPattern() {
      if (this.installmentPattern.length < this.selectedInstallment) {
        for (let i = 1; i < this.selectedInstallment; i++) {
          let arr = []
          for (let j = 0; j <= i; j++) {
            arr.push({ ...this.newInstallment })
          }
          this.installmentPattern.push(arr)
        }
        this.installmentDivideCheck = 0

      } else {
        this.installmentPattern = []
      }
    },
    async insttalmentDivideEqually() {
      if (this.installmentDivideCheck === 1) {
        this.installmentPattern.map((arr) => {
          arr.map((obj) => {
            obj.installmentFeeAmount = Math.round(this.allSubjectFeesWithGst / arr.length)
          })
          this.rsToPercent()
        })
      } else {
        this.installmentPattern.map((arr) => {
          arr.map((obj) => {
            obj.installmentFeeAmount = 0
          })
          this.rsToPercent()
        })
      }
    },

    async getAllSubjectcheck() {
      this.isAllSubjectCheck = this.isAllSubjectCheck;
      this.subjectsList.forEach((subject) => {
        if (this.isAllSubjectCheck) {
          subject.isCompulsory = 1;
        } else {
          subject.isCompulsory = 0;
        }
      });
    },
    async load() {
      console.log(this.Propsitem)
      this.selectedAdmissionId = this.Propsitem.admissionId;
      this.selectedInstitute = this.Propsitem.instituteId;
      this.selectedSemester = this.PropsSelectedSemester;


    },

    async getClassbySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        const res = await this.semesterRepositoryInstance.getCoursebySemesters(objToPush)
        for (let i = 0; i < res.length; i++) {
          if (this.selectedSemester.semId == res[i].semId) {
            this.classname.push(res[i])
          }
        }

      } catch (err) {
        console.log(err)
      }
    },
    async getCoursebySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        const res = await this.semesterRepositoryInstance.getCoursebySemesters(
          objToPush
        )
        for (let i = 0; i < res.length; i++) {
          if (this.selectedSemester.semId == res[i].semId) {
            this.coursename.push(res[i])
            console.log("this is semId", this.coursename)
          }
        }
        this.getInstallment()
        this.getDuration()
      }
      catch (err) {
        console.log(err)
      }
    },
    async getInstallment() {
      try {
        this.installment = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
        this.gstValues = ["0", "5", "12", "18", "28"];
      }

      catch (err) {
        console.log(err)
      }
    },

    async getDuration() {
      try {
        this.duration = ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
      }
      catch (err) {
        console.log(err)
      }
    },
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        this.semesters.reverse();
        this.semestersList = this.semesters;
      } catch (err) {
        console.log(err)
      }
    },

    async fetchAdmissionDetailsForEdit(item) {
      this.selectedAdmissionId = item.admissionId;
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataForEdit({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        admissionId: this.selectedAdmissionId,
      });
      this.subjectsList = res.subjectLists;

      this.selectedCourseName = res.admissionData[0].department;
      this.switch1 = res.admissionData[0].status;
      this.admissionTitle = res.admissionData[0].admissionTitle;

      this.selectedClassName = res.admissionData[0].courseName;
      this.startDate = res.admissionData[0].startDate.split("T")[0]
      this.endDate = res.admissionData[0].endDate.split("T")[0]

      this.isAllSubjectCheck = res.admissionData[0].subjectCompulsory;
      if (res.admissionPayment.length) {
        this.installmentDiscount = res.admissionPayment[0].installmentDiscount;
        this.allSubjectFeesWithGst = res.admissionPayment[0].allSubjectFeesWithGst;
        this.installmentPattern = res.admissionPayment[0].installmentPattern;
        this.oneTimePaymentDisc = res.admissionPayment[0].oneTimePaymentDisc;
        this.admissionPaymentId = res.admissionPayment[0].admissionPaymentId;
        this.selectedInstallment = res.admissionPayment[0].instalmentsNumber;
        this.selectedDuration = res.admissionPayment[0].instalmentDuration;
        this.selectedGst = res.admissionPayment[0].gstValue;
        if (res.admissionPayment[0].gstValue !== undefined && res.admissionPayment[0].gstValue !== null && res.admissionPayment[0].gstValue !== "") this.gstCheck = 1;
        this.installmentCheck = 1;
      }
      if (res.admissionFormActivity.length) {
        this.formActivity = res.admissionFormActivity[0].formActivity,
          this.allFormActivity = res.admissionFormActivity[0].allFormActivity;
      }
      if (this.subjectsList) {
        if (this.subjectsList[0].feesCriteria === 'subjectWise') {
          this.subjectWise = true
        } else {
          this.subjectWise = false
        }
      }
    },

  }
}
</script>
<style src="./activityEditAdmission.css" scoped>
</style>
