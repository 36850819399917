<template src='./activityCourses.html'></template>
<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import SubjectsRepository from '../../Repository/Subject'
import CourseRepository from '../../Repository/courses';
import InstituteRepository from '../../Repository/Institute';
import CorporateRepository from '../../Repository/Corporate';
import auth from '../../Services/Firebase/auth'

var firestorage = null

export default {
  name: 'activityCourses',
  props: ["prop_subject", "prop_selectedDepartment", "prop_selectedCourseYear", "prop_selectedSemester"],
  data() {
    return {
      assignedDepartment: [],
      assignedCourseYearForSubAdmin: [],
      semestersList: [],
      isCorporateInstitute: false,
      editSubjectName: false,
      deleteSubjectInfo: {},
      editSubjectInfo: {},
      deleteSubject: false,
      updatedSubjectName: '',
      addLogo: false,
      instituteLogo: [],
      addSubject: false,
      subjectNameCorporate: '',
      departmentsList: [],
      departmentsList: [],
      courseYearList: [],
      subjectsList: [],
      selectedInstitute: '',
      selectedDepartment: '',
      selectedSemester: {},
      selectedCourseYear: '',
      starIcon: 'mdi-star',
      loading: false,
      starRating: 3,
      averageRating: 0,
      isLoading: false
    }
  },
  async created() {
    this.selectedInstitute = this.$store.getters['liveData/get_admissionInstitute']
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.CourseRepositoryInstance = new CourseRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.InstituteRepositoryInstance = new InstituteRepository(this)
    this.CorporateRepositoryInstance = new CorporateRepository(this)
    this.$store.commit("liveData/set_selectedActivityName", "Courses");
    const firebase = auth.getFirebaseObject()

    firestorage = firebase.storage()

    this.isCorporateInstitute = this.$store.getters['liveData/get_isCorporateInstitute']
    console.log('isCorporateInstitute', this.isCorporateInstitute);
    if (this.prop_selectedSemester) {

      this.selectedSemester = this.prop_selectedSemester
    }
    if (this.prop_selectedDepartment) {

      this.selectedDepartment = this.prop_selectedDepartment
    }
    if (this.prop_selectedCourseYear) {

      this.selectedCourseYear = this.prop_selectedCourseYear
    }
    if (this.selectedSemester) {
      await this.fetchDepartments()
    }
    if (this.selectedDepartment) {
      await this.fetchCourseYear()
    }
    if (this.selectedCourseYear) {
      await this.fetchSubjects()
    }
    this.getSemesters();
  },
  methods: {
    editSubjectDialog(sub) {
      this.editSubjectInfo = sub
      this.updatedSubjectName = sub.subjectName
      this.editSubjectName = true
    },
    deleteDialog(sub) {
      console.log('sub', sub);
      this.deleteSubject = true
      this.deleteSubjectInfo = sub
    },
    async deleteSubjectFromAll() {
      let obj = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: this.deleteSubjectInfo.subjectId
      }
      console.log('obj', obj);
      try {
        let data = await this.CorporateRepositoryInstance.deleteSubject(obj)
        this.getSemesters()
      } catch (error) {
        console.log('error', error);
      }
      this.deleteSubject = false

    },
    async updateSubjectName() {
      try {
        let obj = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.editSubjectInfo.subjectId,
          subjectName: this.updatedSubjectName
        }
        console.log('obj', obj);
        let data = await this.CorporateRepositoryInstance.changeSubjectName(obj)
        this.getSemesters()
      } catch (error) {
        console.log('error', error);
      }
      this.editSubjectName = false
    },
    assignStudent() {
      // console.log('this.selectedSemester.semId', this.selectedSemester.semId);
      // console.log('this.selectedDepartment', this.selectedDepartment);
      // console.log('this.selectedCourseYear', this.selectedCourseYear);
      this.$router.push({
        name: 'activityEditStudentsRegistration',
        params: {
          PropsSelectedSemester: this.selectedSemester,
          // prop_SelectedDepartment: this.selectedDepartment,
          // prop_SelectedCourseYear: this.selectedCourseYear,
          // prop_SelectedDivision: this.selectedDivision,
          // tree: this.divisions
          prop_isCorporate: true,
          prop_dataOfDept: this.divisionListOfSem[0]
        }
      })
    },
    async addSubjectForCorporate() {
      let objToPush = {}
      objToPush.instituteId = this.selectedInstitute,
        objToPush.semId = this.selectedSemester.semId,
        objToPush.department = this.selectedDepartment,
        objToPush.courseYear = this.selectedCourseYear,
        objToPush.subjectName = this.subjectNameCorporate;
      objToPush.subjectCode = this.subjectNameCorporate;
      objToPush.subjectType = 0;

      console.log('objToPush', objToPush);
      try {
        let subject = await this.subjectRepositoryInstance.createSubject(objToPush)
        console.log('subject', subject);
        await this.fetchSubjects()
      } catch (error) {
        console.log(error);
      }
      this.addSubject = false
    },
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semestersList = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        if (this.semestersList.length === 1) {
          this.selectedSemester = this.semestersList[0]
          this.fetchDepartments()
        }
      } catch (err) {
        console.log(err)
      }
    },

    async fetchDepartments() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.divisionListOfSem = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        console.log('this.divisionListOfSem', this.divisionListOfSem);
        this.departmentsList = []
        this.courseYearList = []
        this.divisionList = []
        this.divisionListOfSem.map((div) => {
          if (!this.departmentsList.includes(div.department)) {
            this.departmentsList.push(div.department)
          }
        })
        let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment.length > 0) {
        this.departmentsList.map((data) => {
          if(this.assignedDepartment.includes(data)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departmentsList = subAdminAssignedDepartmentWiseLecture
        }
        if (this.departmentsList.length === 1) {
          this.selectedDepartment = this.departmentsList[0]
          this.fetchCourseYear()
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchCourseYear() {
      this.courseYearList = []
      this.divisionList = []
      this.divisionListOfSem.map((div) => {
        if (div.department === this.selectedDepartment)
          if (!this.courseYearList.includes(div.courseYear)) {
            this.courseYearList.push(div.courseYear)
          }
      })
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYearList = allowedCourseYearForSelectedDepartment
      }
      if (this.courseYearList.length === 1) {
        this.selectedCourseYear = this.courseYearList[0]
        this.fetchSubjects()
      }
    },
    gotoCourse(subject) {
      this.$router.push({
        name: 'activitySubjectCourses',
        params: {
          prop_subject: subject,
          prop_selectedSemester: this.selectedSemester
        }
      })
    },
    gotoAnalysis(subject) {
      this.$store.commit("liveData/set_selectedSubject", subject);
      this.$router.push({
        name: 'activityCourseAnalysis',
        params: {
          prop_subject: subject,
          prop_selectedSemester: this.selectedSemester
        }
      })
    },
    async fetchSubjects() {
      this.isLoading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        }
        let subjectsTemp = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush);
        const subjectIds = []
        subjectsTemp.map((subject) => {
          subjectIds.push(subject.subjectId)
        })
        let courseDetails = await this.CourseRepositoryInstance.getAllCoursesInfoOfInstitute(objToPush)
        const objToPushTemp = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          courseIds: subjectIds
        }
        let studentDetails = await this.CourseRepositoryInstance.getAllCoursesInfoOfStudent(objToPushTemp)
        subjectsTemp.map((subject) => {
          courseDetails.map((course) => {
            if (subject.subjectId === course.courseId) {
              subject.courseImage = course.courseImage
            }
          })
          subject.totalCourseRating = 0
          subject.enrolledStudents = 0
          subject.ratingGivenStudent = 0
          subject.averageRating = 0
          subject.studentsList = []
          studentDetails.map((obj) => {
            if (subject.subjectId === obj.courseId) {
              subject.enrolledStudents += 1
              subject.totalCourseRating += obj.courseRating ? parseInt(obj.courseRating) : 0
              subject.feedbackDescription += obj.feedbackDescription
              subject.ratingGivenStudent += parseInt(obj.courseRating) !== "" ? 1 : 0

              subject.studentsList.push(obj)
            }
          })
          subject.averageRating = parseInt(subject.totalCourseRating) / parseInt(subject.ratingGivenStudent) ? (parseInt(subject.totalCourseRating) / parseInt(subject.ratingGivenStudent)).toFixed(2) : 0
        })
        this.subjectsList = subjectsTemp
        this.isLoading = false
      } catch (e) {
        console.log('e', e);
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped src="./activityCourses.css"></style>