export default {
  createExam: '/exam/create',
  getExamById: '/exam/getExamById',
  getAllExams: '/exam/getAllExams',
  getExamsOfACriteria: '/exam/getExamsOfACriteria',
  getDetailsOfAnExam: '/exam/getDetailsOfAnExam',
  getAnalysisOfAnExamForAStudent: '/exam/getAnalysisOfAnExamForAStudent',
  getExams: '/examIntegration/getExamDetailsByCandidate',
  // getExams: '/exam/getExams',
  update: '/exam/update',
  publishExam: '/exam/publishExam'
}
