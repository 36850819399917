<template>
  <div class="linear-progress-container">
    <span v-if="textPlacement === 'outside'" :style="customStyle">{{
      handleText
    }}</span>
    <v-progress-linear
      :value="value"
      :buffer-value="bufferValue"
      :color="color"
      :rounded="rounded"
      :height="height"
      :indeterminate="indeterminate"
      :stream="stream"
      :class="classes"
    >
      <template v-slot:default>
        <!-- Text inside the linear progress bar -->
        <span
          v-if="textPlacement === 'inside'"
          class="progress-text"
          :style="customStyle"
          >{{ handleText }}</span
        >
      </template>
    </v-progress-linear>
  </div>
</template>
  
  <script>
export default {
  name: "LinearProgress",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    bufferValue: {
      type: Number,
      default: 100, // For buffer state
    },
    color: {
      type: String,
      default: "#00128C",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: 4, // Default height of the progress bar
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    textPosition: {
      type: String,
      default: "left",
      validator: (value) => ["right", "center", "left"].includes(value),
    },
    textPlacement: {
      type: String,
      default: "inside",
      validator: (value) => ["inside", "outside"].includes(value),
    },
    stream: {
      type: Boolean,
      default: false, // Stream animation like buffering
    },
    customClass: {
      type: String,
      default: "",
    },
    text: {
      type: [String, Number],
      default: "",
    },
    textSize: {
      type: [String, Number],
      default: 15,
    },
    textColor: {
      type: String,
      default: "#ffffff",
    },
  },
  computed: {
    classes() {
      return this.customClass;
    },
    customStyle() {
      const customFontSize =
        typeof this.textSize === "number"
          ? `${this.textSize}px`
          : `${Number(this.textSize)}px`;
      let styleObject = {
        color: this.textColor,
        fontSize: customFontSize,
      };
      if (this.textPlacement === "outside") {
        styleObject.display = "flex-start";
        styleObject.justifyContent = "flex-end";
        if (this.textPosition === "right") {
          styleObject.justifyContent = "flex-start";
        } else if (this.textPosition === "center") {
          styleObject.justifyContent = "center";
        }
      }
      return styleObject;
    },
    handleText() {
      let num = this.text;
      if (typeof this.text === "number") {
        if (this.text < 0) return 0;
        if (this.text > 100) return 100;
        num = Math.round(this.text);
      }
      return num;
    },
  },
};
</script>
  
  <style scoped>
/* Container for the linear progress */
.linear-progress-container {
  position: relative;
  width: 100%; /* Ensures it takes the full width of the parent */
}

/* Style the text inside the linear progress bar */
.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit; /* Inherit the color of the progress bar */
  font-size: 0.875rem;
}
</style>
  