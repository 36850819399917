<template src='./activityMergeLecture.html'>
</template>

<script>
import SemesterRepository from '../../Repository/Semester'
import inputContainer from '../../Components/inputContainer'
import LectureRepository from '../../Repository/Lecture'
import UserRepository from '../../Repository/User'
import SubjectRepository from '../../Repository/Subject'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import { conductableLectureTypes, days } from '../../Constants/Utils/Statics'
import { convertDate, convertTime, timeStringConverter,convertUTCDateToLocalDate } from '../../Services/Utils/DateTimeFormatter'


import apiV3 from '../../NetworkManager/apiV3'


export default {
  name: 'activityMergeLecture',
  components: {
    inputContainer
  },
  data() {
    return {
      loadingEffect:false,
      assignedDepartment: [],
      semesters: [],
      semestersList: [],
      selectedLecture: {},
      confirmCancel: false,
      days: days,
      selectedSemesterName: '',
      loading: false,
      singleSelect: false,
      checkedSubjectToMerge: [],

      selected: [],
      valuetab: [],
     
      tempStartDate: '',
      tempEndDate: '',
      tempReason: '',
      showHolidayDialog: false,
      selection: [],
      selectedDate: '',
      selectedDepartment: '',
      departments: [],
      selectedInstitute: '',
      selectedSemester: {},
      userData: {},
      divs: [],
      tempDiv: [],
      selectedDivisions: [],
      lecturesMapping: {},
      mergeLectureMapping: {},
      dataFetched: false,
      showMergeLecturesDialog: false,
      showCancelLecturesDialog: false,
      selectedDivisionForCancellingLectures: {},
      mergedLectureId: [],
      mergedLectureElement: {},

      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },

        {
          text: 'Lectures',
          value: 'lectures'
        },
        {
          text: 'Operations',
          value: 'actions'
        }
      ],
      headersForCancellingLectures: [
        {
          text: '',
          value: 'isMerged'
        },
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Faculty',
          value: 'facultyName'
        },
        {
          text: 'Subject',
          value: 'subjectName'
        },
        {
          text: 'Location',
          value: 'locationName'
        },
        // {
        //   text: 'Operations',
        //   value: 'actions'
        // }
      ]
    }
  },
  created() {
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.$store.commit('liveData/set_selectedActivityName', 'Merge Lecture')
    this.userData = this.$store.getters['user/get_userData']
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    const todaysDate = new Date()
    this.selectedDate = [todaysDate.getFullYear(), ('0' + (todaysDate.getMonth() + 1)).slice(-2), ('0' + todaysDate.getDate()).slice(-2)].join('-')
    this.fetchData()
  },
  methods: {
    getFormatedTime(item) {
      console.log('selected', this.selected)
    },
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
   getFormattedTime(time) {
      return  convertUTCDateToLocalDate(time);
    },
    selectSemester() {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester(semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      console.log(objToPush)
      try {
        this.departments = []
        this.divs = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        this.divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
        let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment.length > 0) {
        this.departments.map((data) => {
          if(this.assignedDepartment.includes(data)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departments = subAdminAssignedDepartmentWiseLecture
        }
      } catch (err) {
        console.log(err)
      }
    },
    async selectDate() {
      this.dataFetched = false
      this.selectedDepartment = false
      this.selection = []
    },
    convertDateToUTC(date) {
      return new Date(date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds())
    },
    async selectDepartmentToMerge() {
      this.selection = []
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        dateOfLecturesNeeded: this.convertDateToUTC(new Date(this.selectedDate)),
        department: this.selectedDepartment,
        day: days[(new Date(this.selectedDate)).getDay()]
      }
      let lectures = []
      this.lecturesMapping = []

      try {
        lectures = await this.lectureRepositoryInstance.getLecturesOfADepartmentForADay(objToPush)
      } catch (err) {
        lectures = []
        console.log(err)
      }
      // edit started
      lectures.forEach((lecture) => {
        if (!(this.lecturesMapping[lecture.department + '_' + lecture.courseYear])) {
          this.lecturesMapping[lecture.department + '_' + lecture.courseYear] = []
          this.lecturesMapping[lecture.department + '_' + lecture.courseYear].push(lecture)
        } else {
          this.lecturesMapping[lecture.department + '_' + lecture.courseYear].push(lecture)
        }
      })
      let ids = []
      this.tempDiv = this.divs.filter((dept) => {
        if (dept.department === this.selectedDepartment && !ids.includes(dept.courseYear)) {
          ids.push(dept.courseYear)
          return dept
        }
      })

      this.tempDiv.forEach((elem) => {
        if (!ids.includes(elem.department + '_' + elem.courseYear)) {
          elem.id = elem.department + '_' + elem.courseYear
        }
      })
      const promises = Object.keys(this.lecturesMapping).map((lecturesMappingKey) => this.getDetailsOfLectures(lecturesMappingKey))
      await Promise.allSettled(promises)
      this.dataFetched = true
      this.loading = false
    },
    async selectLecturesToMerge(item) {
      this.selectedCriteria = item
      this.showMergeLecturesDialog = true
    },
    async getDetailsOfLectures(lecturesMappingKey) {
      for (let i = 0; i < this.lecturesMapping[lecturesMappingKey].length; i++) {
        try {
          if (!this.lecturesMapping[lecturesMappingKey][i].facultyName) {
            this.lecturesMapping[lecturesMappingKey][i].facultyName = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.lecturesMapping[lecturesMappingKey][i].uId })
          }
        } catch (err) {
          console.log(err)
          this.lecturesMapping[lecturesMappingKey][i].facultyName = ''
        }

        try {
          if (!this.lecturesMapping[lecturesMappingKey][i].subjectName) {
            this.lecturesMapping[lecturesMappingKey][i].subjectName = await this.subjectRepositoryInstance.getSubjectName({
              instituteId: this.lecturesMapping[lecturesMappingKey][i].instituteId,
              semId: this.lecturesMapping[lecturesMappingKey][i].semId,
              subjectId: this.lecturesMapping[lecturesMappingKey][i].subjectId
            })
          }
        } catch (err) {
          console.log(err)
          this.lecturesMapping[lecturesMappingKey][i].subjectName = ''
        }

        try {
          if (!this.lecturesMapping[lecturesMappingKey][i].locationName) {
            this.lecturesMapping[lecturesMappingKey][i].locationName = await this.infrastructureReferenceRepositoryInstance.getRoomName({
              instituteId: this.lecturesMapping[lecturesMappingKey][i].instituteId,
              roomId: this.lecturesMapping[lecturesMappingKey][i].roomId
            })
          }
        } catch (err) {
          console.log(err)
          this.lecturesMapping[lecturesMappingKey][i].locationName = ''
        }
      }
    },
    isLectureConductable(item) {
      return conductableLectureTypes.includes(item.status)
    },
    showLecturesTo(item) {
      this.showCancelLecturesDialog = true
      this.selectedDivisionForCancellingLectures = item
    },
    dismissCancelLectureDialog() {
      this.showMergeLecturesDialog = false
      this.selectedDivisionForCancellingLectures = {}
    },
    convertDate(dateTime) {
      return convertDate(dateTime)
    },
    convertTime(dateTime) {
      return convertTime(dateTime)
    },
    gotoDashboard() {
      this.$router.push('/activityDashboard')
    },
    async saveMergedLecture() {
     this.loadingEffect=true
      let leng = this.selected.length
      let id = '';
      let mergeIds = []
      this.selected.map((obj) => {
        mergeIds.push(obj)
      })
      await apiV3.postRequest('/lecture/merge', {
        lectureIds: mergeIds
      })
      this.selectDepartmentToMerge()
      this.selected = []
      this.loadingEffect=false
      this.showMergeLecturesDialog = false
    }
  }
}
</script>

<style src='./activityMergeLecture.css'>

</style>
