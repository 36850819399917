<template src='./activityEditSemester.html'></template>
<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import activityEditSemesterDates from '../activityEditSemesterDates/activityEditSemesterDates'
import activityEditSemesterHolidays from '../activityEditSemesterHolidays/activityEditSemesterHolidays'
import activityEditSemesterShifts from '../activityEditSemesterShifts/activityEditSemesterShifts'
import activityEditSemesterBatches from '../activityEditSemesterBatches/activityEditSemesterBatches'
import activityEditSemesterUsers from '../activityEditSemesterUsers/activityEditSemesterUsers'
import activityEditSemesterHOD from '../activityEditSemesterHOD/activityEditSemesterHOD'
import activityEditSemesterClassteacher from '../activityEditSemesterClassteacher/activityEditSemesterClassteacher'
import activityEditSemesterSubjects from '../activityEditSemesterSubjects/activityEditSemesterSubjects'
import activityEditSemesterSubjects1 from '../activityEditSemesterSubjects1/activityEditSemesterSubjects1'
import activityEditFacultyAllocation from '../activityEditFacultyAllocation/activityEditFacultyAllocation'
import activityEditStudentsRegistration from '../activityEditStudentsRegistration/activityEditStudentsRegistration'
import activityEditSemesterTimetable from '../activityEditSemesterTimetable/activityEditSemesterTimetable'
export default {
  name: 'activityEditSemester',
  components: {
    inputContainer,
    activityEditSemesterDates,
    activityEditSemesterHolidays,
    activityEditSemesterShifts,
    activityEditSemesterBatches,
    activityEditSemesterUsers,
    activityEditSemesterHOD,
    activityEditSemesterClassteacher,
    activityEditSemesterSubjects,
    activityEditSemesterSubjects1,
    activityEditFacultyAllocation,
    activityEditStudentsRegistration,
    activityEditSemesterTimetable
  },
  data() {
    return {
      loading: true,
      isSubAdmin: false,
      selectedSemester: {},
      operations: [
        'Select Start Date/End Date',
        'Enter Holidays',
        'Allocate Shift',
        'Set Batch Count',
        'Add Students/Faculties',
        // 'Assign HOD',
        'Assign Classteachers',
        'Add Subjects',
        'Allocate Faculties to Subjects',
        'Register Students to Subjects',
        'Define Timetable'
      ],
      selectedOperation: '',
      selectedOperationRank: 0,
      divisions: [],
      courseYears: [],
      departments: [],
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedDivision: '',
      acList: [],
      semestersList: [],
      academicDataTree: {}
    }
  },
  props: [
    'prop_selectedOperation',
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision'
  ],
  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Edit Semester')

    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['liveData/get_editingSemester']

    this.selectedOperationToSelectedOperationRankMapping = {
      'Select Start Date/End Date': 1,
      'Enter Holidays': 2,
      'Allocate Shift': 3,
      'Set Batch Count': 4,
      'Add Students/Faculties': 5,
      'Assign HOD': 6,
      'Assign Classteachers': 7,
      'Add Subjects': 8,
      'Allocate Faculties to Subjects': 9,
      'Register Students to Subjects': 10,
      'Define Timetable': 11
    }


    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        this.constructAcademicDataTree()
      } catch (err) {
        console.log(err)
      }
    },
    async constructAcademicDataTree() {
      this.academicDataTree = {}

      this.semestersList.forEach((sem) => {
        if (!this.academicDataTree[sem.department]) {
          this.academicDataTree[sem.department] = {}
        }
        if (!this.academicDataTree[sem.department][sem.courseYear]) {
          this.academicDataTree[sem.department][sem.courseYear] = []
        }
        this.academicDataTree[sem.department][sem.courseYear].push(sem.division)
      })

      let idForDisplay = 0
      this.departments = []
      this.courseYears = []
      this.divisions = []

      Object.keys(this.academicDataTree).forEach((department, index) => {
        this.departments.push({
          name: department,
          department: department,
          id: index
        })

        const departmentObject = {
          id: idForDisplay,
          name: department,
          children: []
        }

        const courseYearsOfADepartment = {
          id: idForDisplay,
          name: department,
          department: department,
          children: []
        }

        idForDisplay++

        Object.keys(this.academicDataTree[department]).forEach((courseYear) => {
          const divisionsOfACourseYear = {
            id: idForDisplay,
            name: courseYear,
            children: []
          }

          const courseYearObject = {
            id: idForDisplay,
            name: courseYear,
            department: department,
            courseYear: courseYear
          }

          idForDisplay++

          this.academicDataTree[department][courseYear].forEach((division) => {
            // idForDisplay++

            divisionsOfACourseYear.children.push({
              id: idForDisplay++,
              department: department,
              courseYear: courseYear,
              name: division
            })
          })

          departmentObject.children.push(divisionsOfACourseYear)
          courseYearsOfADepartment.children.push(courseYearObject)
        })

        this.divisions.push(departmentObject)
        this.courseYears.push(courseYearsOfADepartment)
      })

      this.loading = false
      this.selectedOperation = this.prop_selectedOperation || ''
      this.selectedDepartment = this.prop_selectedDepartment || ''
      this.selectedCourseYear = this.prop_selectedCourseYear || ''
      this.selectedDivision = this.prop_selectedDivision || ''
      this.selectOperation()
      this.loading = true
    },
    selectOperation() {
      // default value will be 0
      this.selectedOperationRank = this.selectedOperationToSelectedOperationRankMapping[this.selectedOperation] || 0
      // switch (this.selectedOperation) {
      //   case '':
      //     this.selectedOperationRank = 0
      //     break
      //   case 'Select Start Date/End Date':
      //     this.selectedOperationRank = 1
      //     break
      //   case 'Enter Holidays':
      //     this.selectedOperationRank = 2
      //     break
      //   case 'Allocate Shift':
      //     this.selectedOperationRank = 3
      //     break
      //   case 'Set Batch Count':
      //     this.selectedOperationRank = 4
      //     break
      //   case 'Add Students/Faculties':
      //     this.selectedOperationRank = 5
      //     break
      //   case 'Assign HOD':
      //     this.selectedOperationRank = 6
      //     break
      //   case 'Assign Classteachers':
      //     this.selectedOperationRank = 7
      //     break
      //   case 'Add Subjects':
      //     this.selectedOperationRank = 8
      //     break
      //   case 'Allocate Faculties to Subjects':
      //     this.selectedOperationRank = 9
      //     break
      //   case 'Register Students to Subjects':
      //     this.selectedOperationRank = 10
      //     break
      //   case 'Define Timetable':
      //     this.selectedOperationRank = 11
      //     break
      // }
    }
  }
}
</script>
<style src='./activityEditSemester.css' scoped></style>
