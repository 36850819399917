<template src='./activityReferenceShiftDetails.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import arrayOps from '../../Services/Utils/arrayOps'
import { timeStringConverter } from '../../Services/Utils/DateTimeFormatter'
import ShiftReferenceRepository from '../../Repository/ShiftReference'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityReferenceShiftDetails',
  components: {
    inputContainer,
    smallButton
  },
  data() {
    return {
      loading: false,
      tempShiftName: '',
      tempShiftWorkingDays: [],
      userData: {},
      selectedInstitute: {},
      items: [],
      headers: [
        {
          text: 'Shift Name',
          value: 'shiftName'
        },
        {
          text: 'Working Days',
          value: 'workingDays',
          sortable: false
        },
        {
          text: 'Time Slots',
          value: 'timeSlots',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      search: '',
      selectedShifts: [],
      selectedShift: {},
      addingShift: false,
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      editing: false,
      tempTimeSlots: [],
      tempStartTime: '',
      tempEndTime: ''
    }
  },
  created() {
    this.loading = true
    // this.$store.commit('liveData/set_selectedActivityName', 'Reference Shift Details')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.userData = this.$store.getters['user/get_userData']
    this.shiftReferenceRepositoryInstance = new ShiftReferenceRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      this.editing = false
      this.items = []
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        this.items = await this.shiftReferenceRepositoryInstance.getShiftDetails(objToPush)
      } catch (err) {
        console.log(err)
        this.items = []
      }
      this.loading = false
    },
    selectWorkingDay(day) {
      this.loading = true
      if (this.tempShiftWorkingDays.includes(day)) {
        this.tempShiftWorkingDays = arrayOps.removeFromArray(this.tempShiftWorkingDays, this.tempShiftWorkingDays.indexOf(day))
      } else {
        this.tempShiftWorkingDays.push(day)
      }
      this.loading = false
    },
    removeTimeSlot(ts) {
      this.loading = true
      this.tempTimeSlots = arrayOps.removeFromArray(this.tempTimeSlots, ts)
      this.loading = false
    },
    async saveShift() {
      this.loading = true
      if (this.editing === true) {
        const shiftIndex = this.items.findIndex((shift) => shift.shiftId === this.selectedShift.shiftId)

        if (this.tempShiftName !== this.items[shiftIndex].shiftName) {
          try {
            const objToPush = {
              instituteId: this.selectedInstitute,
              shiftId: this.selectedShift.shiftId,
              shiftName: this.tempShiftName
            }
            this.tempShiftName = await this.shiftReferenceRepositoryInstance.updateShiftName(objToPush)
            this.items[shiftIndex].shiftName = this.tempShiftName
          } catch (err) {
            showStatus('Failed to update shift name.', 1000, 'error', this)
            console.log(err)
          }
        }

        // if (!arrayOps.compareArrays(this.tempShiftWorkingDays, this.items[shiftIndex].workingDays)) {
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            shiftId: this.selectedShift.shiftId,
            shiftName: this.selectedShift.shiftName,
            workingDays: this.tempShiftWorkingDays
          }
          this.tempShiftWorkingDays = await this.shiftReferenceRepositoryInstance.updateWorkingDays(objToPush)
          this.items[shiftIndex].workingDays = this.tempShiftWorkingDays
        } catch (err) {
          showStatus('Failed to update shift working days.', 1000, 'error', this)
          console.log(err)
        }
        // }

        // if (!arrayOps.compareArrays(this.tempTimeSlots, this.items[shiftIndex].timeSlots)) {
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            shiftId: this.selectedShift.shiftId,
            shiftName: this.selectedShift.shiftName,
            timeSlots: this.tempTimeSlots
          }
          this.tempTimeSlots = await this.shiftReferenceRepositoryInstance.updateTimeSlots(objToPush)
          this.items[shiftIndex].tempTimeSlots = this.tempTimeSlots
        } catch (err) {
          showStatus('Failed to update shift time slots.', 1000, 'error', this)
          console.log(err)
        }
        // }
      } else {
        const objToPush = {
          instituteId: this.selectedInstitute,
          // shiftId: this.selectedShift.shiftId,
          shiftName: this.tempShiftName,
          workingDays: this.tempShiftWorkingDays,
          timeSlots: this.tempTimeSlots
        }
        try {
          const shift = await this.shiftReferenceRepositoryInstance.createShiftDetails(objToPush)
          this.items.push(shift)
          showStatus('Created Shift Successfully.', 1000, 'success', this)
        } catch (err) {
          showStatus('Failed to create shift.', 1000, 'error', this)
          console.log(err)
        }
      }
      this.addingShift = false
      this.loading = false
    },
    async addingShiftStep1() {
      this.editing = false
      this.tempShiftName = ''
      this.selectedShift = {}
      this.tempShiftWorkingDays = []
      this.tempTimeSlots = []
      this.tempStartTime = ''
      this.tempEndTime = ''
      this.addingShift = true
      console.log(this.selectedShift)
    },
    startEditingShift(shift) {
      this.loading = true
      this.selectedShift = shift
      this.tempShiftName = this.selectedShift.shiftName
      this.tempShiftWorkingDays = this.selectedShift.workingDays
      this.tempTimeSlots = this.selectedShift.timeSlots
      this.editing = true
      this.addingShift = true
      this.loading = false
    },
    async deleteShift(shift) {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        shiftId: shift.shiftId,
        shiftName: shift.shiftName
      }
      try {
        await this.shiftReferenceRepositoryInstance.deleteShiftDetails(objToPush)
        showStatus('Shift Deleted Successfully.', 1000, 'success', this)
        const shiftIndex = this.items.findIndex((shiftObject) => shiftObject.shiftId === shift.shiftId)
        const shiftIndex2 = this.selectedShifts.findIndex((shiftObject) => shiftObject.shiftId === shift.shiftId)
        console.log(shiftIndex, shiftIndex2)
        if (shiftIndex2 > -1) {
          this.selectedShifts = arrayOps.removeFromArray(this.selectedShifts, shiftIndex2)
        }
        if (shiftIndex > -1) {
          this.items = arrayOps.removeFromArray(this.items, shiftIndex)
        }
      } catch (err) {
        showStatus('Failed to delete shift.', 1000, 'error', this)
        console.log(err)
      }
      this.loading = false
    },
    async removeMultipleShifts() {
      this.loading = true
      for (let i = 0; i < this.selectedShifts.length; i++) {
        await this.deleteShift(this.selectedShifts[i])
        this.selectedShifts.splice(this.selectedShifts, i, 1)
      }
      this.selectedShifts = []
      this.loading = false
    },
    timeStringConverter(time) {
      return timeStringConverter(time)
    },
    addTimeSlot() {
      this.loading = true
      console.log(this.tempStartTime, this.tempEndTime)
      const st = (parseInt(this.tempStartTime.split(':')[0]) * 60) + (parseInt(this.tempStartTime.split(':')[1]))
      const et = (parseInt(this.tempEndTime.split(':')[0]) * 60) + (parseInt(this.tempEndTime.split(':')[1]))
      console.log(st, et)
      if (st < et) {
        this.tempTimeSlots.push({
          startTime: this.tempStartTime.split(':').join('_'),
          endTime: this.tempEndTime.split(':').join('_')
        })
        this.tempStartTime = ''
        this.tempEndTime = ''
        this.loading = false
      } else {
        window.alert('Start time should be less that End Time.')
        this.tempEndTime = ''
        this.loading = false
      }
    },
    gotoactivityInstitutesManagment() {
      this.$router.push('/activityInstitutesManagment')
    },
  }
}
</script>
<style scoped src='./activityReferenceShiftDetails.css'>
</style>
