import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Institute'

class Institute {
  constructor (context) {
    this.context = context
  }

  async createInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.institute
  }

  async getInstituteInformation (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getInstituteInformation, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.institute
  }

  async getDesignationsOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDesignationsOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.designationDetails
  }

  async getInstitutesNames (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getInstitutesNames, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.institutes
  }

  async updateInstituteName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteName
  }
  async updateInstituteDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateInstituteAddress (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteAddress, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteAddress
  }

  async updateAffliatingUniversityName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateAffliatingUniversityName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.affliatingUniversityName
  }

  async updateAffiliatingUniversityAddress (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateAffiliatingUniversityAddress, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.affiliatingUniversityAddress
  }

  async updateInstituteEmail (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteEmail, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteEmail
  }
 async updateInstitutePhoneNumber (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstitutePhoneNumber, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.institutePhoneNumber
  }
  async updateInstitutePaymentDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstitutePaymentDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateEstablishmentYear (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateEstablishmentYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.establishmentYear
  }

  async updateInstituteType (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteType, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.institutionType
  }

  async updateOwnershipStatus (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateOwnershipStatus, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.ownershipStatus
  }

  async updateInstituteWebsite (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateInstituteWebsite, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.instituteWebsite
  }
}

export default Institute
