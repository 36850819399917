import { render, staticRenderFns } from "./activityEditSemesterSubjects1.html?vue&type=template&id=246a3e4e&scoped=true&external"
import script from "./activityEditSemesterSubjects1.vue?vue&type=script&lang=js"
export * from "./activityEditSemesterSubjects1.vue?vue&type=script&lang=js"
import style0 from "./activityEditSemesterSubjects1.css?vue&type=style&index=0&id=246a3e4e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246a3e4e",
  null
  
)

export default component.exports