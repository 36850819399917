import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Posts";

class Posts {
  constructor(context) {
    this.context = context;
  }

  async createPost(data) {
    console.log(ApiNames.createPost);
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createPost,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.result;
  }

  async getPosts(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(ApiNames.getPosts, data);
    this.context.$store.commit("liveData/set_loading", false);
    return response.result;
  }

  async deletePost(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.deleteRequest(
      ApiNames.deletePost,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createComment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createComment,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default Posts;
