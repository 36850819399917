import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Feedback'

class Feedback {
  constructor (context) {
    this.context = context
  }

  async getAllFeedbackByInstituteId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllFeedbacksByInstituteId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.feedbacks || {}
  }

  async getAllFeedbacksOfObtainedStudents (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllFeedbacksOfObtainedStudents, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.feedback || {}
  }

  async getFeedbackDetailsByFeedbackId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFeedbackDetailsByFeedbackId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.feedback || {}
  }

  async createFeedbackForm (data){
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createFeedbackForm, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateFeedbackForm (data){
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateFeedbackForm, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async publishFeedbackForm (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateFormToPublish, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateDemoExcel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateDemoExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateFeedbackResponseExcel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateFeedbackResponseExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async parseExcel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequestFileUpload(ApiNames.parseExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.data.questionsArray
  }

  async parseExcelWithoutNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequestFileUpload(ApiNames.parseExcelWithoutNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.data.questionsArray
  }

  async parseExcelBackdated (data){
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequestFileUpload(ApiNames.parseExcelBackdated, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.data
  }

  async deleteFeedbackForm (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.deleteForm, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
    return response
  }

//   async updateDepartmentNBA (data) {
//     this.context.$store.commit('liveData/set_loading', true)
//     const response = await networkManager.postRequest(ApiNames.updateDepartmentNBA, data)
//     this.context.$store.commit('liveData/set_loading', false)
//     console.log(response)
//   }

//   // 
//   async getCos(data) {
//     this.context.$store.commit('liveData/set_loading', true)
//     const response = await nbaNetworkManager.getRequest(ApiNames.getCos, data)
//     this.context.$store.commit('liveData/set_loading', false)
//     return response
//   }

//   async deleteExamLms (data) {
//     this.context.$store.commit('liveData/set_loading', true)
//     const response = await nbaNetworkManager.postRequest(ApiNames.deleteExamLms, data)
//     this.context.$store.commit('liveData/set_loading', false)
//     return response
//   }

//   async getLevelsOfInstitute (data) {
//     this.context.$store.commit('liveData/set_loading', true)
//     const response = await nbaNetworkManager.getRequest(ApiNames.getLevelsOfInstitute, data)
//     this.context.$store.commit('liveData/set_loading', false)
//     return response
//   }

//   async getTargetsOfInstitute (data) {
//     this.context.$store.commit('liveData/set_loading', true)
//     const response = await nbaNetworkManager.getRequest(ApiNames.getTargetsOfInstitute, data)
//     this.context.$store.commit('liveData/set_loading', false)
//     return response
//   }
}

export default Feedback
