export default {
  createExamStudent: '/examStudent/create',
  updateExamStudent: '/examStudent/updateExamStudent',
  getBacklogExamStudentsForAnExam: '/examStudent/getBacklogExamStudentsForAnExam',
  getExamAnalysis: '/examStudent/getExamAnalysis',
  updateExamStudentMarksByExcel: '/examStudent/updateExamStudentMarksByExcel',
  generateStudentMarksExcel: '/excel/exam/generateStudentMarksExcel',
  generateFilteredStudentsExcel: '/excel/exam/generateFilteredStudentsExcel',
  generateConsolidatedMarksheetExcel: '/excel/exam/generateConsolidatedMarksheetExcel'
}
