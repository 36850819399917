import liveData from "../Store/Modules/liveData";

export const filterAccessedDepartmentForSubAdmin = (arr) => {
    let accessDepartment = liveData?.state?.accessibleDepartment.accessibleDepartment || []
    let isSubAdmin = liveData?.state?.isSubAdmin.isSubAdmin || false

    let dept = []
    if (isSubAdmin === true) {
        if (accessDepartment.length === 0) {
            dept = arr
        } else {

            if (arr.length > 0) {
                arr.map((data) => {
                    if (accessDepartment.includes(data)) {
                        dept.push(data);
                    }
                });
            }
        }

    } else {
        dept = arr
    }
    return dept

}
export const filterAccessedCourseYearForSubAdmin = (arr, dept) => {
    let accessCourse = liveData?.state?.accessibleCourseYearForSubAdmin.accessibleCourseYearForSubAdmin || []
    let isSubAdmin = liveData?.state?.isSubAdmin.isSubAdmin || false

    let cy = []
    if (isSubAdmin === true) {
        if (accessCourse.length === 0) {
            cy = arr
        } else {

            if (arr.length > 0) {
                arr.map((data) => {
                    if (accessCourse.includes(data + '_' + dept)) {
                        cy.push(data);
                    }
                });
            }
        }

    } else {
        cy = arr
    }
    return cy
}




