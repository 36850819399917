<template src='./activityTakeAttendance.html'></template>
<script>
import UserRepository from "../../Repository/User";
import LectureRepository from "../../Repository/Lecture";
import TopicRepository from "../../Repository/Topic";
import SemesterUserRepository from "../../Repository/SemesterUser";
import OnlineLectureRepository from "../../Repository/OnlineLecture";
import showStatus from "../../NetworkManager/showStatus";
import getNamesOfUsers from "../../Services/Utils/getNamesOfUsers";
import ZoomMeetingRepository from "../../Repository/zoomMeeting";
import apiV3 from "../../NetworkManager/apiV3";
import { v4 as uuidv4 } from "uuid";
import QrcodeVue from "qrcode.vue";
import auth from "../../Services/Firebase/auth";
import arrayOps from "../../Services/Utils/arrayOps";

import {
  convertDate,
  formatToSlashSepearated,
  timeStringConverter,
  convertFromYYYYMMDDToDate,
  convertUTCDateToLocalDate,
  convertTime,
} from "../../Services/Utils/DateTimeFormatter";
export default {
  name: "activityTakeAttendance",
  props: ["prop_lecture", "prop_dashboard"],
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.uploadEnd = true;
            this.downloadURLs.push(downloadURL);
            this.uploadCount++;
            if (this.uploadCount === this.files?.length) {
              this.uploadVideo();
            } else {
              this.progressUpload = 0;
              this.upload(this.files[this.uploadCount]);
            }
          });
        }
      );
    },
    selectedForCopyFrom(newValue, oldValue) {
      this.handleModelChange(newValue, oldValue);
    },
  },
  data() {
    return {
      qrUpdateIntervalId: "",
      conductedLecForCopy: [],
      selectedForCopyFrom: [],
      lecToCopy: [],
      selectedForCopyTo: [],
      qrGetUpdateDataIntervalId: "",
      qrCodeData: "",
      qrCodeSize: 500,
      showQrCode: false,
      lectureForGroup: false,
      markAttendance: false,
      progressBar: false,
      progressBarCompleted: false,
      attendance: [],
      selection: [],
      totalConductedLectures: 0,
      topicsOfLecture: [],
      showTopicsOfLecture: false,
      dialog: false,
      loading: false,
      copyAttendanceDialog: false,
      headersForCopyAttendance: [
        {
          text: "Date",
          value: "dateOnly",
          align: "center",
          width: "100px",
        },
        {
          text: "Time",
          value: "dateTime",
          align: "center",
        },
        {
          text: "Dept_CY",
          value: "department",
          align: "center",
        },
        {
          text: "Division",
          value: "division",
          align: "center",
        },
        {
          text: "Batch",
          value: "batch",
          align: "center",
        },
        {
          text: "Group",
          value: "group",
          align: "center",
        },
        {
          text: "Subject",
          value: "subjectName",
          align: "center",
        },
        {
          text: "Faculty",
          value: "fullName",
          align: "center",
        },
      ],
      headers: [
        {
          text: "Roll Number",
          value: "rollNumber",
          width: "100px",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Remarks",
          value: "remarkForPresentStudents",
        },
        {
          text: "Total Attended",
          value: "totalAttendedLectures",
        },
        {
          text: "Total Lectures",
          value: "totalConductedLectures",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
      ],
      headers2: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Duration",
          value: "duration",
        },
      ],
      headersMobile: [
        {
          text: "Roll Number",
          value: "rollNumber",
          width: "100px",
        },
        {
          text: "Name",
          value: "name",
        },
      ],
      lectureDuration: 0,
      showOnlineAttendance: false,
      isMobileView: false,
      onlineStudents: [],
      studentsMapping: {},
      presentStudentsList: [],
      threshold: 0,
      ex3: { label: "Set Limit", color: "red" },
      isLoadingData: false,
      showAttendance: false,
      allLectures: [],
    };
  },
  components: {
    QrcodeVue,
  },
  created() {
    if (
      this.prop_lecture.groupForSubject &&
      this.prop_lecture.groupForSubject?.length
    ) {
      this.lectureForGroup = true;
    }
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    if (this.prop_dashboard) {
      // auth.deleteDocumentFromFirebase(this.prop_lecture.lectureId)
    }
    const startTimeArr = this.prop_lecture.startTime?.split("_");
    const startTime =
      parseInt(startTimeArr[0]) * 60 + parseInt(startTimeArr[1]);
    const endTimeArr = this.prop_lecture.endTime?.split("_");
    const endTime = parseInt(endTimeArr[0]) * 60 + parseInt(endTimeArr[1]);
    this.lectureDuration = endTime - startTime;
    this.threshold = this.lectureDuration * 0.8;
    this.userRepositoryInstance = new UserRepository(this);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.topicRepositoryInstance = new TopicRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this);
    this.zoomMeetingInstance = new ZoomMeetingRepository(this);
    this.getDataForCopyAttendance();

    this.userData = this.$store.getters["user/get_userData"];
    this.$store.commit("liveData/set_selectedActivityName", "Mark Attendance");
    this.fetchSemesterUsers();
    this.fetchData();
  },
  methods: {
    closeQrCode() {
      clearInterval(this.qrUpdateIntervalId);
      this.selection = this.$store.getters["liveData/get_studentAttendance"];
      this.showQrCode = false;
    },
    updateQRData() {
      const tokenForQr = uuidv4();
      this.$store.commit("liveData/set_tokenForQr", tokenForQr);
      const data = {
        instituteId: this.prop_lecture.instituteId,
        semId: this.prop_lecture?.semId,
        lectureId: this.prop_lecture.lectureId,
        token: tokenForQr,
      };
      this.qrCodeData = JSON.stringify(data);
    },
    async generateQR() {
      this.$store.commit("liveData/set_studentAttendance", []);
      this.$store.commit("liveData/set_studentAttendance", this.selection);

      try {
        const tokenForQr = uuidv4();
        this.$store.commit("liveData/set_tokenForQr", tokenForQr);
        const data = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture?.semId,
          lectureId: this.prop_lecture.lectureId,
          token: tokenForQr,
        };
        this.qrCodeData = JSON.stringify(data);

        this.showQrCode = true;
        this.qrUpdateIntervalId = setInterval(this.updateQRData, 10000);
        auth.getAttendance({
          lectureId: this.prop_lecture.lectureId,
          applicableStudent: this.attendance,
        });
      } catch (err) {
        showStatus(
          "An unknown error occured, try later or contact admin",
          6000,
          "error",
          this
        );
        console.error("Error:", err);
      }
    },
    async fetchSemesterUsers() {
      this.isLoadingData = true;
      if (!this.lectureForGroup) {
        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture?.semId,
          department: this.prop_lecture.department,
          courseYear: this.prop_lecture.courseYear,
          division: this.prop_lecture.division,
          batch: this.prop_lecture.batch || "",
          subjectId: this.prop_lecture.subjectId,
          uId: this.userData.uId,
        };

        try {
          if (
            this.prop_lecture.multiDivision &&
            this.prop_lecture.multiDivision?.length > 0
          ) {
            objToPush['divisions'] = this.prop_lecture.multiDivision;
            this.attendance =
              await this.semesterUserRepositoryInstance.getSemesterUsersForMultipleDivision(
                objToPush
              );
          } else {
            this.attendance =
              await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfADivision(
                objToPush
              );
          }
          for (let i = 0; i < this.attendance?.length; i++) {
            for (
              let j = 0;
              j < this.prop_lecture?.remarkForPresentStudents?.length;
              j++
            ) {
              if (
                this.attendance[i].uId ===
                this.prop_lecture?.remarkForPresentStudents[j].uId
              ) {
                this.attendance[i].remarkForPresentStudents =
                  this.prop_lecture?.remarkForPresentStudents[j].remark;
              }
            }
          }
          const arr1 = [];
          if (this.attendance.length > 0) {
            this.attendance.forEach((item) => {
              const { reportingDate } = item;
              if (reportingDate === undefined || reportingDate === '' || reportingDate === null) {
                arr1.push(item);
              } else {
                if (this.prop_lecture.startTime > reportingDate) {
                  arr1.push(item);
                }
              }
            });
          }
          this.attendance = [...arr1]
          this.attendance.sort((a, b) => a.rollNumber - b.rollNumber);
          this.attendance.forEach((student) => {
            this.studentsMapping[student.uId] = student;
          });
        } catch (err) {
          console.error(err);
          this.attendance = [];
        }
      } else {
        // getSemesterUsersOfASubject
        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture?.semId,
          subjectId: this.prop_lecture.subjectId,
        };
        try {
          this.attendance =
            await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
              objToPush
            );
          this.attendance.sort((a, b) => a.rollNumber - b.rollNumber);
          let filterStudent = [];
          this.attendance.map((stu) => {
            if (stu.assignedGroupForSubject?.length > 0) {
              stu.assignedGroupForSubject.map((grp) => {
                if (
                  grp.groupName ===
                  this.prop_lecture.groupForSubject[0].groupName &&
                  grp.groupId === this.prop_lecture.groupForSubject[0].groupId
                ) {
                  filterStudent.push(stu);
                }
              });
            }
          });
          this.attendance = filterStudent;
          this.attendance.sort((a, b) => a.rollNumber - b.rollNumber);
          this.attendance.forEach((student) => {
            this.studentsMapping[student.uId] = student;
          });
        } catch (error) {
          console.error("error", error);
          this.attendance = [];
        }
      }

      this.checkAndLoadPreviousAttendance(this.prop_lecture.present);
      await Promise.all([
        this.getConductedLecturesCountOfASubject(),
        this.getTotalAttendedLecturesForUsers(),
        this.addEntryForSemesterUsers(),
      ]);
      console.log('this.allLectures', this.allLectures);
      for (let i = 0; i < this.attendance.length; i++) {
        let conducted = 0
        let attended = 0
        if (this.allLectures.length > 0) {
          this.allLectures.map((lec) => {
            if (lec.present.includes(this.attendance[i].uId) || lec.absent.includes(this.attendance[i].uId)) {
              conducted++;
              if (lec.present.includes(this.attendance[i].uId)) {
                attended++;
              }
            }
          })
          this.attendance[i].totalConductedLectures = conducted
          this.attendance[i].totalAttendedLectures = attended
          this.attendance[i].percentage = (100 * attended / conducted).toFixed(2);
        }
      }
      console.log('this.attendance', this.attendance);
      this.attendance = this.attendance.slice();
      this.isLoadingData = false;
    },
    async addEntryForSemesterUsers() {
      await getNamesOfUsers(
        this.attendance,
        "name",
        this.userRepositoryInstance
      );
    },
    async getFullNameOfUser(indexOfSemesterUserInAttendance) {
      try {
        if (this.attendance[indexOfSemesterUserInAttendance].uId) {
          this.attendance[indexOfSemesterUserInAttendance].name =
            await this.userRepositoryInstance.getFullNameOfUser({
              uId: this.attendance[indexOfSemesterUserInAttendance].uId,
            });
        }
      } catch (err) {
        console.error(err);
        this.attendance[indexOfSemesterUserInAttendance].name = "";
      }
    },
    async getTotalAttendedLecturesForUsers() {
      this.showAttendance = false;
      try {
        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture?.semId,
          department: this.prop_lecture.department,
          courseYear: this.prop_lecture.courseYear,
          division: this.prop_lecture.division,
          batch: this.prop_lecture.batch || "",
          subjectId: this.prop_lecture.subjectId,
          uId: this.prop_lecture.uId,
        };
        const studentsList =
          await this.lectureRepositoryInstance.getTotalAttendedLecturesForADivisionForSubject(
            objToPush
          );
        for (let i = 0; i < this.attendance?.length; i++) {
          this.attendance[i].totalAttendedLectures =
            studentsList[this.attendance[i].uId] || 0;
          if (this.totalConductedLectures > 0) {
            this.attendance[i].percentage = (
              (parseInt(this.attendance[i].totalAttendedLectures) /
                this.totalConductedLectures) *
              100
            ).toFixed(2);
          } else {
            this.attendance[i].percentage = 0;
          }
        }
      } catch (err) {
        console.error(err);
        this.showAttendance = true;
      }
      this.showAttendance = true;
    },
    async getConductedLecturesCountOfASubject() {
      try {
        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture?.semId,
          department: this.prop_lecture.department,
          courseYear: this.prop_lecture.courseYear,
          division: this.prop_lecture.division,
          batch: this.prop_lecture.batch || "",
          subjectId: this.prop_lecture.subjectId,
          uId: this.userData.uId,
        };
        this.totalConductedLectures =
          await this.lectureRepositoryInstance.getConductedLecturesCountOfASubject(
            objToPush
          );
        objToPush.uId = this.prop_lecture.uId
        this.allLectures =
          await this.lectureRepositoryInstance.getConductedLecturesOfASubject(
            objToPush
          );
      } catch (err) {
        console.error(err);
        this.totalConductedLectures = 0;
      }
    },
    checkAndLoadPreviousAttendance(presentStudentsList) {
      const semesterUserObjectsOfPresentStudents = this.attendance.filter(
        (studentObject) => presentStudentsList.includes(studentObject.uId)
      );
      this.selection = semesterUserObjectsOfPresentStudents;
    },
    async submitAttendance() {
      this.prop_lecture.remarkForPresentStudents = [];
      this.attendance.forEach((student) => {
        if (student?.remarkForPresentStudents) {
          this.prop_lecture.remarkForPresentStudents.push({
            uId: student.uId,
            remark: student?.remarkForPresentStudents,
          });
        }
      });
      const objToPush = JSON.parse(JSON.stringify(this.prop_lecture));
      objToPush.present = [];
      objToPush.absent = [];
      this.attendance.forEach((student) => {
        if (this.selection.includes(student)) {
          objToPush.present.push(student.uId);
        } else {
          objToPush.absent.push(student.uId);
        }
      });
      try {
        const response = await this.lectureRepositoryInstance.saveAttendance(
          objToPush
        );
        // showStatus('Attendance saved successfully', 1000, 'success', this)
        await this.fetchTopicsOfALecture();
      } catch (err) {
        console.error(err);
      }
      // auth.deleteDocumentFromFirebase(this.prop_lecture.lectureId)
      this.$store.commit("liveData/set_tokenForQr", "");
      this.$store.commit("liveData/set_studentAttendance", []);
    },
    async fetchTopicsOfALecture() {
      const objToPush = {
        instituteId: this.prop_lecture.instituteId,
        semId: this.prop_lecture?.semId,
        lectureId: this.prop_lecture.lectureId,
      };
      try {
        const response = await this.topicRepositoryInstance.getTopicOfALecture(
          objToPush
        );
        this.showTopicsOfLecture = true;
        this.topicsOfLecture = response;
      } catch (err) {
        console.error(err);
      }
    },
    async handleOnClickTopic(topic) {
      this.loading = true;
      try {
        const objToPush = {
          instituteId: topic.instituteId,
          semId: topic?.semId,
          topicId: topic.topicId,
          lectureId: topic.lectureId,
        };
        if (topic.isImplemented) {
          await this.topicRepositoryInstance.markAsUnimplemented(objToPush);
        } else {
          await this.topicRepositoryInstance.markAsImplemented(objToPush);
        }
        topic.isImplemented = !topic.isImplemented;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
    async getOnlineLectureAttendance() {
      this.loading = true;
      this.showOnlineAttendance = false;
      try {
        const res = await apiV3.getRequest("/meeting/attendance", {
          lectureId: this.prop_lecture.lectureId,
        });
        this.lectureDuration = Math.round(res.totalDuration / 60);
        this.threshold = this.lectureDuration * 0.8;
        this.presentStudentsList = res.attendanceList.filter(
          (entry) => entry.moderator === false
        );
        const tempArr = [];
        this.attendance.forEach((stud) => {
          const students = this.presentStudentsList.filter(
            (stud2) => stud2.ext_user_id === stud.uId
          );
          if (students?.length > 0) {
            const studentDuration = students.reduce(
              (accu, elem) => (accu > elem.duration ? accu : elem.duration),
              0
            );
            const student = {
              uId: students[0].ext_user_id,
              duration: Math.round(studentDuration / 60),
            };
            if (student) {
              tempArr.push(student);
            }
          }
        });
        this.presentStudentsList = tempArr;
        this.processStudents();
      } catch (err) {
        this.loading = false;
        if (err.response.status === 404) {
          showStatus(
            "If lecture was conducted, data is processing....",
            6000,
            "info",
            this
          );
          return;
        }
        showStatus(
          "An unknown error occured, try later or contact admin",
          6000,
          "error",
          this
        );
        console.error(err);
      }
    },

    saveCopyAttendance() {
      this.copyAttendanceDialog = false;
      const arr = [];
      if (this.selectedForCopyTo?.length > 0) {
        this.selectedForCopyTo.map((lec) => {
          const obj = {};
          obj.present = this.selectedForCopyFrom[0].present;
          obj.absent = this.selectedForCopyFrom[0].absent;
          obj.isConducted = true;
          obj.lectureId = lec.lectureId;
          obj.semId = lec?.semId;
          obj.instituteId = lec.instituteId;
          arr.push(obj);
        });
        this.updateCopyLectureAttendance(arr);
      }
      this.dialog = true;
      // this.gotoactivityDashboard();
    },
    getStartUtc() {
      const currentDate = new Date(this.todayDate);
      currentDate.setUTCHours(18, 30, 0, 0);
      currentDate.setDate(currentDate.getDate() - 1);
      return currentDate.toISOString();
    },
    async fetchData() {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      this.loadingForDataMain = true;
      this.assignedLectures = [];
      const data = await this.userRepositoryInstance.getUserProfile({
        uId: this.userData.uId,
      });
      const date = new Date(this.selectedDate?.split("-"));
      this.today = days[date.getDay()];
      this.userName =
        `${data.firstName} ${data.middleName} ${data.lastName}`.trim();
      this.loadingLectures = true;
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.prop_lecture.instituteId,
        semId: this.prop_lecture?.semId,
        subjectIds: [this.prop_lecture.subjectId],
        fromDate: this.getStartUtc(),
        toDate: this.getEndUtc(),
      };
      this.timetable = [];
      let lecturesArray = [];
      try {
        lecturesArray =
          await this.lectureRepositoryInstance.getLecturesOfACourseYear(
            objToPush
          );
        if (lecturesArray.lecture && lecturesArray.lecture?.length > 0) {
          lecturesArray.lecture.map((info) => {
            info.fullName = "";
            info.roomName = "";
            info.subjectName = "";
            if (lecturesArray.facultyDetails.fullNames?.length > 0) {
              lecturesArray.facultyDetails.fullNames.map((fac) => {
                if (fac.uId === info.uId) {
                  info.fullName = fac.fullName;
                }
              });
            }
            if (lecturesArray.roomDetails.roomName?.length > 0) {
              lecturesArray.roomDetails.roomName.map((room) => {
                if (room.roomId === info.roomId) {
                  info.roomName = room.roomName;
                }
              });
            }
            if (lecturesArray.subjectDetails.subjects?.length > 0) {
              lecturesArray.subjectDetails.subjects.map((sub) => {
                if (sub.subjectId === info.subjectId) {
                  info.subjectName = sub.subjectName;
                  info.subjectType = sub.subjectType;
                }
              });
            }
          });
        }
        this.assignedLectures = lecturesArray.lecture;
        lecturesArray = arrayOps.sortByStartTime(lecturesArray.lecture);
        lecturesArray.map((lecture) => {
          this.lectureIds.push(lecture.lectureId);
          if (lecture.roomId !== "") {
            this.roomIds.push(lecture.roomId);
          }
          for (const [key, value] of Object.entries(this.subjectsMapping)) {
            if (key === lecture.subjectId) {
              lecture.subjectName = value.subjectName;
              lecture.lectureType = value.lectureType;
            }
          }
        });
        this.finaltimeTable = lecturesArray.filter(
          (lecture) => lecture.subjectName !== undefined
        );
      } catch (err) {
        console.error(err);
        this.lecturesArray = [];
        this.timetable = [];
        this.loadingLectures = false;
        this.loadingForDataMain = false;
        return;
      }
      if (this.roomIds?.length > 0) {
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            roomIds: this.roomIds,
          };
          const roomNames =
            (await this.infrastructureReferenceRepositoryInstance.getRoomNames(
              objToPush
            )) || "";
          this.finaltimeTable.map((lecture) => {
            roomNames.map((room) => {
              if (lecture.roomId === room.roomId) {
                lecture.roomName = room.roomName;
              }
            });
          });
        } catch (e) {
          this.timetable = [];
          this.loadingLectures = false;
          this.loadingForDataMain = false;
          console.error("error", e.stack);
        }
      }

      try {
        this.onlineAttendance = [];
        const lectureIdsArray = [];
        this.finaltimeTable.map((t) => {
          lectureIdsArray.push(t.lectureId);
        });
        const result = await apiV3.postRequest("/meeting/get-attendance", {
          lectureIds: lectureIdsArray,
        });
        this.onlineAttendance = result.totalDuration;
      } catch (e) {
        console.error("e", e);
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester?.semId,
          lectureIds: this.lectureIds,
        };
        const lectureOnlineStatus =
          await this.onlineLectureRepositoryInstance.getIsOngoingForALectures(
            objToPush
          );
        if (lectureOnlineStatus !== undefined) {
          lectureOnlineStatus.map((lecture) => {
            this.finaltimeTable.map((lecture1) => {
              if (lecture.lectureId === lecture1.lectureId) {
                lecture1.isOnline = lecture.isOnline;
              }
            });
          });
        } else {
          this.finaltimeTable.map((lecture) => {
            lecture.isOnline = false;
          });
        }
      } catch (err) {
        this.timetable = [];
        this.loadingLectures = false;
        this.loadingForDataMain = false;
        console.error(err);
      }
      this.finaltimeTable.map((t) => {
        t.afterUtc = false;
        // if (new Date("2022-10-31T00:00:00.000Z").toISOString() < t.dateTime) {
        if (!(t.startTime?.split("_")?.length > 1)) {
          t.afterUtc = true;
          t.start = t.startTime;
          t.end = t.endTime;
          t.startTime = convertUTCDateToLocalDate(new Date(t.startTime));
          t.endTime = convertUTCDateToLocalDate(new Date(t.endTime));
        }
        // }
        this.onlineAttendance.map((res) => {
          if (res.lectureId === t.lectureId) {
            t.OnlineAttendanceMarked = true;
          }
        });
      });
      this.timetable = this.finaltimeTable.map((timetable) => {
        const dateTime = new Date(timetable.dateTime);
        const dateOnly = dateTime.toISOString()?.split("T")[0];
        const modeOfConduction = timetable.isOnline ? "Online" : "Offline";
        return {
          ...timetable,
          dateOnly: dateOnly,
          modeOfConduction: modeOfConduction,
        };
      });

      this.loadingLectures = false;
      this.lecturesArray = lecturesArray;
      this.loadingForDataMain = false;
    },
    async updateCopyLectureAttendance(arr) {
      try {
        const data =
          await this.lectureRepositoryInstance.updateMultipleLectureDocument({
            bulkDataArray: arr,
          });
        await this.fetchData();
      } catch (err) {
        console.error("err", err);
      }
    },
    getDateOnly(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if needed
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    },
    handleModelChange() {
      this.lecToCopy = [];
      if (this.selectedForCopyFrom?.length > 0) {
        this.selectedForCopyFrom[0]["type"] = "Division";
        if (
          this.selectedForCopyFrom[0].groupForSubject &&
          this.selectedForCopyFrom[0].groupForSubject?.length > 0
        ) {
          this.selectedForCopyFrom[0]["type"] = "Group";
        }
        if (
          this.selectedForCopyFrom[0].batch !== undefined &&
          this.selectedForCopyFrom[0].batch !== ""
        ) {
          this.selectedForCopyFrom[0]["type"] = "Batch";
        }
        this.assignedLectures.map((lec) => {
          lec["type"] = "Division";
          if (lec.groupForSubject && lec.groupForSubject?.length > 0) {
            lec["type"] = "Group";
          }
          if (lec.batch && lec.batch !== "") {
            lec["type"] = "Batch";
          }
          if (
            lec.lectureId !== this.selectedForCopyFrom[0].lectureId &&
            lec.type === this.selectedForCopyFrom[0]["type"]
          ) {
            if (
              lec.department === this.selectedForCopyFrom[0].department &&
              lec.courseYear === this.selectedForCopyFrom[0].courseYear
            ) {
              if (lec["type"] === "Division") {
                if (this.selectedForCopyFrom[0].division === lec.division) {
                  this.lecToCopy.push(lec);
                }
              }

              if (lec["type"] === "Batch") {
                if (
                  this.selectedForCopyFrom[0].batch === lec.batch &&
                  this.selectedForCopyFrom[0].division === lec.division
                ) {
                  this.lecToCopy.push(lec);
                }
              }

              if (lec["type"] === "Group") {
                if (
                  this.selectedForCopyFrom[0]?.groupForSubject[0]?.groupName ===
                  lec?.groupForSubject[0]?.groupName
                ) {
                  this.lecToCopy.push(lec);
                }
              }
            }
          }
        });
        // this.lecToCopy = this.lecToCopy.filter((a)=>a.lectureId === this.prop_lecture.lectureId )
      }
      let temp = [];
      for (let i = 0; i < this.lecToCopy?.length; i++) {
        if (this.lecToCopy[i].lectureId === this.prop_lecture.lectureId) {
          const dateParts = this.lecToCopy[i].dateOnly.split("-");
          const formattedDateOnly = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
          this.lecToCopy[i].dateOnly = formattedDateOnly;
          temp.push(this.lecToCopy[i]);
          break;
        }
      }
      this.lecToCopy = temp;
    },
    selectedForCopyFrom(newValue, oldValue) {
      this.handleModelChange(newValue, oldValue);
    },
    async getDataForCopyAttendance() {
      try {
        const obj = {
          uId: this.prop_lecture.uId,
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture?.semId,
          subjectIds: [this.prop_lecture.subjectId],
          fromDate: this.prop_dashboard.startDate,
          toDate: this.prop_dashboard.endDate,
        };
        let lecturesArray = [];
        lecturesArray =
          await this.lectureRepositoryInstance.getLecturesOfACourseYear(obj);
        if (
          lecturesArray &&
          lecturesArray.lecture &&
          lecturesArray.lecture.length > 0
        ) {
          lecturesArray.lecture.map((info) => {
            info.afterUtc = false;
            // if (new Date("2022-10-31T00:00:00.000Z").toISOString() < t.dateTime) {
            if (!(info.startTime?.split("_")?.length > 1)) {
              info.afterUtc = true;
              info.start = info.startTime;
              info.end = info.endTime;
              info.startTime = convertUTCDateToLocalDate(
                new Date(info.startTime)
              );
              info.endTime = convertUTCDateToLocalDate(new Date(info.endTime));

              info.currentTimeUTC = new Date().toISOString();

              info.isEndTimeGreater = info.end > info.currentTimeUTC;
              info.isStartTimeGreater = info.start > info.currentTimeUTC;
            }
            // }
            this.onlineAttendance?.map((res) => {
              if (res.lectureId === info.lectureId) {
                info.OnlineAttendanceMarked = true;
              }
            });

            info.fullName = "";
            info.roomName = "";
            info.subjectName = "";
            if (lecturesArray.facultyDetails.fullNames?.length > 0) {
              lecturesArray.facultyDetails.fullNames.map((fac) => {
                if (fac.uId === info.uId) {
                  info.fullName = fac.fullName;
                }
              });
            }
            if (lecturesArray.roomDetails.roomName?.length > 0) {
              lecturesArray.roomDetails.roomName.map((room) => {
                if (room.roomId === info.roomId) {
                  info.roomName = room.roomName;
                }
              });
            }
            if (lecturesArray.subjectDetails.subjects?.length > 0) {
              lecturesArray.subjectDetails.subjects.map((sub) => {
                if (sub.subjectId === info.subjectId) {
                  info.subjectName = sub.subjectName;
                  info.subjectType = sub.subjectType;
                }
              });
            }
          });
        }
        this.assignedLectures = [];
        this.assignedLectures = lecturesArray.lecture.map(
          (assignedLectures) => {
            const dateTime = new Date(assignedLectures.dateTime);
            const dateOnly = dateTime.toISOString()?.split("T")[0];
            const modeOfConduction = assignedLectures.isOnline
              ? "Online"
              : "Offline";
            return {
              ...assignedLectures,
              dateOnly: dateOnly,
              modeOfConduction: modeOfConduction,
            };
          }
        );
        try {
          this.onlineAttendance = [];
          const lectureIdsArray = [];
          lecturesArray.lecture.map((t) => {
            lectureIdsArray.push(t.lectureId);
          });
          const result = await apiV3.postRequest("/meeting/get-attendance", {
            lectureIds: lectureIdsArray,
          });
          this.onlineAttendance = result.totalDuration;
        } catch (e) {
          console.error("e", e);
        }

        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester?.semId,
            lectureIds: this.lectureIds,
          };
          const lectureOnlineStatus =
            await this.onlineLectureRepositoryInstance.getIsOngoingForALectures(
              objToPush
            );
          if (lectureOnlineStatus !== undefined) {
            lectureOnlineStatus.map((lecture) => {
              lecturesArray.lecture.map((lecture1) => {
                if (lecture.lectureId === lecture1.lectureId) {
                  lecture1.isOnline = lecture.isOnline;
                }
              });
            });
          } else {
            lecturesArray.lecture.map((lecture) => {
              lecture.isOnline = false;
            });
          }
        } catch (err) {
          this.timetable = [];
          this.loadingLectures = false;
          this.loadingForDataMain = false;
          console.error(err);
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    async handleCopyAttendance() {
      this.copyAttendanceDialog = true;
      this.selectedForCopyFrom = [];
      this.selectedForCopyTo = [];
      this.conductedLecForCopy = [];
      let temp = [];
      if (this.assignedLectures?.length > 0) {
        this.assignedLectures.map((info) => {
          if (info.isConducted === true) {
            const dateTime = new Date(info.dateTime);
            const startHour = dateTime.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            dateTime.setHours(dateTime.getHours() + 1);
            const endHour = dateTime.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            const formattedTime = `${startHour} - ${endHour}`;

            const dateParts = info.dateOnly.split("-");
            const formattedDateOnly = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

            this.conductedLecForCopy.push({
              ...info,
              dateTime: formattedTime,
              dateOnly: formattedDateOnly,
            });
          }
        });

        for (let i = 0; i < this.conductedLecForCopy?.length; i++) {
          if (this.prop_lecture.division && this.prop_lecture.batch) {
            if (
              this.conductedLecForCopy[i].division ===
              this.prop_lecture.division &&
              this.conductedLecForCopy[i].batch === this.prop_lecture.batch
            ) {
              temp.push(this.conductedLecForCopy[i]);
            }
          } else if (this.prop_lecture.division) {
            if (
              this.conductedLecForCopy[i].division ===
              this.prop_lecture.division
            ) {
              temp.push(this.conductedLecForCopy[i]);
            }
          } else if (this.prop_lecture.groupForSubject?.length > 0) {
            if (
              this.prop_lecture?.groupForSubject?.[0]?.groupId ===
              this.conductedLecForCopy?.[i]?.groupForSubject?.[0]?.groupId
            ) {
              temp.push(this.conductedLecForCopy[i]);
            }
          }
        }
        this.conductedLecForCopy = temp;
      }
    },
    applyLimit(limit) {
      this.processStudents();
    },
    processStudents() {
      this.onlineStudents = [];
      this.presentStudentsList.forEach((student) => {
        if (student.duration >= Math.round(this.threshold)) {
          const studObj = this.studentsMapping[student.uId];
          if (studObj) {
            this.onlineStudents.push({
              uId: student.uId,
              duration: student.duration,
              name: studObj.name,
            });
          }
        }
      });
      this.showOnlineAttendance = true;
      this.loading = false;
    },
    async convertDateFormat(dateString) {
      const date = new Date(dateString);
      const timeString = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });
      const timeParts = timeString?.split(":");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);

      let period = "AM";
      if (hours >= 12) {
        period = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      }

      return `${hours}:${minutes.toString().padStart(2, "0")} ${period}`;
    },
    async saveOnlineLectureAttendance() {
      this.checkAndLoadPreviousAttendance(
        this.onlineStudents.map((stud) => {
          return stud.uId;
        })
      );
      this.showOnlineAttendance = false;
    },
    async gotoactivityDashboard() {
      this.$router.push({
        name: "activityDashboard",
        params: {
          prop_dashboardDetails: this.prop_dashboard,
        },
      });
    },
    convertToDDMMYYYY(dateString) {
      return convertDate(dateString);
    },
    convertUTCtoLocalTimeAMPM(utcDateString) {
      const utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const result =
        formattedHours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
      return result;
    },
  },
};
</script>
<style scoped src='./activityTakeAttendance.css'></style>
