import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/sendemailAlert";

class SendEmailAlert {
  constructor(context) {
    this.context = context;
  }
  async loginWithEmailAlert(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.loginWithEmailAlert,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default SendEmailAlert;
