export const theme = {
  primary: "#050D92",
  secondary: "#FF4F1F",
  black: "#3A3A3A",
  white: "#FFFFFF",
  error: "#FF0000",
  // accent: "#82B1FF",
  // error: "#FF5252",
  // info: "#2196F3",
  // success: "#4CAF50",
  // warning: "#FFC107",
};
