<template src="./activityAdmission.html"> </template>
<script>
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SubjectsRepository from '../../Repository/Subject'
import { convertDate, convertToISTTime } from "../../Services/Utils/DateTimeFormatter";
import InstituteRepository from '../../Repository/Institute'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityAdmission',
  components: {
    inputContainer
  },
  
  data() {
    return {
      reminderDayProgress: false,
      institutedata: [],
      reminderDaysUserdata: null,
      institute: [],
      dialogRemiderDay: false,
      remiderDay: null,
      createAdmissionButton: false,
      selectedAdmissionId: '',
      semesters: [],
      status: '',
      semestersList: [],
      admissionList: [],
      search: '',
      search1: '',
      selectedSemesterName: '',
      selectedSemester: {},
      loading: false,
      showAddAdmissionDialog: false,
      addAdmissionFormStepNumber: 0,
      headers: [
        {
          text: 'admissionTitle',
          align: 'left',
          sortable: true,
          value: 'admissionTitle',
          filterable: true
        },
        {
          text: 'Course Name',
          align: 'left',
          sortable: true,
          value: 'courseName',
          filterable: true
        },
        {
          text: 'StartDate',
          sortable: true,
          value: 'startDate',
          filterable: true
        },
        {
          text: 'EndDate',
          sortable: true,
          value: 'endDate',
          filterable: true
        },
        // {
        //   text: 'Subject Compulsory',
        //   sortable: true,
        //   value: 'subjectCompulsory',
        //   filterable: true
        // },

        {
          text: 'status',
          sortable: true,
          value: 'status',
          filterable: true
        },
        {
          text: 'Actions',
          value: 'action',
          align: 'center'
        }
      ],
      allFormActivity: 0,
      formActivity: [
        {
          id: 1,
          status: 1,
          isCompulsory: 1,
          name: 'FirstName'
        },
        {
          id: 2,
          status: 1,
          isCompulsory: 1,
          name: 'Middle Name'
        },
        {
          id: 3,
          status: 1,
          isCompulsory: 1,
          name: 'Last Name'
        },
        {
          id: 4,
          status: 1,
          isCompulsory: 1,
          name: 'Student Mobile'
        },
        {
          id: 5,
          status: 1,
          isCompulsory: 1,
          name: 'Student Email'
        },
        {
          id: 6,
          status: 0,
          isCompulsory: 0,
          name: 'Parent Full Name'
        },
        {
          id: 7,
          status: 0,
          isCompulsory: 0,
          name: 'Parent Email Id'
        },
        {
          id: 8,
          status: 0,
          isCompulsory: 0,
          name: 'Parent Mobile Number'
        },
        {
          id: 9,
          status: 0,
          isCompulsory: 0,
          name: 'Address'
        },
        {
          id: 10,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Full Name'
        },
        {
          id: 11,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Email Id'
        },
        {
          id: 12,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Mobile Number'
        },
        {
          id: 13,
          status: 0,
          isCompulsory: 0,
          name: 'Guardian Address'
        },
        {
          id: 14,
          status: 0,
          isCompulsory: 0,
          name: 'School/College Name'
        },
        {
          id: 15,
          status: 0,
          isCompulsory: 0,
          name: 'Class'
        },
        {
          id: 16,
          status: 0,
          isCompulsory: 0,
          name: 'Percentage'
        },

      ],
      editingParent: false,
      users: [],
      subjectsList: [],
      selectedInstitute: '',
      updating: false,
      existingUser: {},
      tempPayments: [],
      userObject: {},
      selectedTab: 0,
      fullName: '',
      parentFullName: '',
      emailId: '',
      parentEmailId: '',
      mobileNumber: '',
      parentMobileNumber: '',
      address: '',
      schoolName: '',
      className: '',
      percentage: '',
      paymentOption: '',
      installmentOption: '',
      paymentMethod: '',
      subjects: [],
      subjectsBatchMappping: {},
      selectedBatches: [],
      subjectsMap: {},
      selectedMathsBatch: {},
      selectedEcoBatch: {},
      selectedOCMBatch: {},
      isFrenchSelected: 'false',
      isGermanSelected: 'false',
      isBasicBatchSelected: 'false',
      selectedSPBatch: {},
      showUsers: [],
      semesterDivs: [],
      departments: [],
      selectedDepartment: '',
      courseYears: [],
      selectedCourseYear: '',
      divisions: [],
      selectedDivision: '',
      classname: [],
      coursename: [],
      installment: [],
      duration: [],
      status: [],
      showToStudents: false,
      selectedCourseName: '',
      selectedClassName: '',
      startDate: '',
      endDate: '',
      selectedInstallment: '',
      selectedDuration: '',
      totalFees: '',
      selectedStatus: '',
      stepOneComplete: false,
      editAdmissionDialog: false,
      selectedSubject: '',
      paymentMethods: ['Cash', 'UPI', 'Net Banking'],
      selectedPaymentMethods: [],
      subjectLength: '',
      installmentCheck: false,
      gstCheck: false,
      gstValues: ["0", "5", "12", "18", "28"],
      selectedGst: 0,
      isAllSubjectCheck: false,
      start: v => v && v.length > 0 || 'you must input start date',
      switch1: false,
      switch2: false,
      isSubjectListLoading: false,
      ins: ''
    }

  },
  created() {
    this.createAdmissionButton = false;
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.instituteRepositoryInstance = new InstituteRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.userData = this.$store.getters['user/get_userData']
    console.log('this.userData', this.userData);
    // this.userData = this.$store.getters['user/get_userData']
    this.ins = this.$store.getters[
        'instituteData/get_selectedInstitute'
      ]
    this.reminderDaysUserdata = this.$store.getters['liveData/get_reminderDays']
    this.reminderDaysValue = this.reminderDaysUserdata.reminderDays
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.getSemesters()
  },
  methods: {
    async saveReminderDays() {
        const objToPush = {
          instituteId: this.selectedInstitute,
          reminderDays: this.reminderDaysValue,
        }
        try {
           await this.instituteRepositoryInstance.updateInstituteEmail(
            objToPush
          )
      showStatus('Reminder days update successfully', 2000, 'success', this)
        } catch (err) {
          showStatus('Could not update Reminder days.', 2000, 'error', this)
          console.log(err)
        }
      this.dialogRemiderDay = false
    },
   async setReminderDays() {
    this.dialogRemiderDay = true
    this.reminderDayProgress= true
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.ins
      }
      try {
        this.institutedata = await this.instituteRepositoryInstance.getInstituteInformation(
          objToPush
        )
        // console.log('this.institute', this.institutedata);
      this.reminderDaysValue = this.institutedata.reminderDays
      } catch (e){
        console.log(e);
      }
      this.reminderDayProgress= false
      
   
    },

    gotoCreateAdmission() {
      this.$router.push({
        name: 'activityCreateAdmission',
        params: {
          PropsSelectedSemester: this.selectedSemester,
          PropsSelectedinstituteId: this.selectedInstitute
        }
      });
    },
    gotoEditAdmission(item) {
      console.log('items', item);
      this.$router.push({
        name: 'activityEditAdmission',
        params: {
          Propsitem: item,
          PropsSelectedSemester: this.selectedSemester,

        }
      });
    },

    gotoAdmissionNewUsers(item) {
      this.$router.push({
        name: 'activityAdmissionNewUsers',
        params: {
          PropsSelectedAdmission: item
        }
      });
    },

    // New Admission Setting Method
    startEditingDetailsOfUser(item) {
      this.editAdmissionDialog = true

    },

    // fetch Admission Table
    async fetchAdmissionDetails() {
      this.createAdmissionButton = true;
      await this.admissionUserRepositoryInstance.updateAdmissionStatus({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
      });
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataBySem({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
      });
      this.admissionList = res;

      this.getCoursebySemesters();
    },

    async fetchAdmissionDetailsForEdit(item) {
      this.selectedAdmissionId = item.admissionId;
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataForEdit({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        admissionId: item.admissionId
      });
      this.selectedCourseName = res.admissionData[0].department;
      this.switch1 = res.admissionData[0].status;

      this.selectedClassName = res.admissionData[0].courseName;
      this.startDate = res.admissionData[0].startDate.split("T")[0]
      this.endDate = res.admissionData[0].endDate.split("T")[0]

      this.isAllSubjectCheck = res.admissionData[0].subjectCompulsory;
      if (res.admissionPayment.length) {
        this.admissionPaymentId = res.admissionPayment[0].admissionPaymentId;
        this.selectedInstallment = res.admissionPayment[0].instalmentsNumber;
        this.selectedDuration = res.admissionPayment[0].instalmentDuration;
        this.selectedGst = res.admissionPayment[0].gstValue;
        if (res.admissionPayment[0].gstValue !== undefined && res.admissionPayment[0].gstValue !== null && res.admissionPayment[0].gstValue !== "") this.gstCheck = 1;
        this.installmentCheck = 1;
      }
      if (res.admissionFormActivity.length) {
        this.formActivity = res.admissionFormActivity[0].formActivity,
          this.allFormActivity = res.admissionFormActivity[0].allFormActivity;
      }
      if (res.subjectLists.length) {
        this.subjectsList = res.subjectLists;
      }
    },

    // admission form modal
    async createAdmission() {
      this.showAddAdmissionDialog = true;
      this.addAdmissionFormStepNumber = 1;
    },

    async getCoursebySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        const res = await this.semesterRepositoryInstance.getCoursebySemesters(
          objToPush
        )
        for (let i = 0; i < res.length; i++) {
          if (this.selectedSemester.semId == res[i].semId) {
            this.coursename.push(res[i])
          }
        }
        this.getInstallment()
        this.getDuration()
      }
      catch (err) {
        console.log(err)
      }
    },

    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        this.semesters.reverse();
        this.semestersList = this.semesters;
      } catch (err) {
        console.log(err)
      }
    },

    async saveAdmission() {

      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedCourseName,
          startDate: this.startDate,
          courseName: this.selectedClassName,
          subjectCompulsory: this.isAllSubjectCheck,
          endDate: this.endDate,
          status: this.switch1,

        }
        const saveAdmission = await this.admissionUserRepositoryInstance.saveAdmission(
          objToPush
        )
        this.savePayment(saveAdmission.admissionId);
      }
      catch (err) {
        console.log(err)
      }

    },

    async cancel() {
      this.selectedClassName = '';
      this.selectedCourseName = '';
      this.selectedSemesterName = '';
      this.startDate = '';
      this.endDate = '';
      this.subjectsList = [];
      this.selectedInstallment = '';
      this.selectedDuration = '';
      this.installmentCheck = 0;
      this.selectedStatus = '';
      this.isAllSubjectCheck = 0;
      this.gstCheck = 0;
      this.selectedGst = '';

    },

    async changeFunction() {
      this.selectedClassName = '';
      this.selectedCourseName = '';
    },

    async changeFunction1() {
      this.selectedClassName = '';
    },

    async savePayment(admissionId) {
      try {
        const objToPush = {
          admissionId: admissionId,
          instalmentsNumber: this.selectedInstallment,
          instalmentDuration: this.selectedDuration,
          gstValue: this.selectedGst,
          status: this.switch1,
        }
        const savePayment = await this.admissionUserRepositoryInstance.savePayment(
          objToPush
        )
        this.saveSubject(admissionId)
        if (this.startDate > this.endDate) {
          alert("start date")
        }
        else {
          console.log("false");
        }
      }

      catch (err) {
        console.log(err)
      }
    },

    async saveSubject(admissionId) {
      try {
        const objToPush = {
          admissionId: admissionId,
          subjectsLists: this.subjectsList,
        }
        const saveAdmission = await this.admissionUserRepositoryInstance.saveSubject(
          objToPush
        )
        this.saveFromFiled(admissionId)
      }
      catch (err) {
        console.log(err)
      }
    },

    async saveFromFiled(admissionId) {
      try {
        const objToPush = {
          admissionId: admissionId,
          allFormActivity: this.allFormActivity,
          formActivity: this.formActivity
        }
        const saveFromFiled = await this.admissionUserRepositoryInstance.saveFormActivity(objToPush)
      }
      catch (err) {
        console.log(err)
      }
    },

    async getClassbySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        const res = await this.semesterRepositoryInstance.getCoursebySemesters(objToPush)
        for (let i = 0; i < res.length; i++) {
          if (this.selectedSemester.semId == res[i].semId) {
            this.classname.push(res[i])
          }
        }

      } catch (err) {
        console.log(err)
      }
    },

    async getSubjectListbyCourse() {
      this.isSubjectListLoading = false;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedCourseName,
          courseYear: this.selectedClassName,
        }
        this.subjectsList = await this.subjectRepositoryInstance.getSubjectsOfACourseYearForAdmission(
          objToPush
        )
        this.subjectsList.forEach((subject) => {
          subject.totalFees = 0;
          subject.basicFees = 0;
          subject.isCompulsory = 0;
        });
        this.isSubjectListLoading = true;
      }
      catch (err) {
        console.log(err)
      }
    },

    async getInstallment() {
      try {
        this.installment = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
        this.gstValues = ["0", "5", "12", "18", "28"];
      }

      catch (err) {
        console.log(err)
      }
    },

    async getDuration() {
      try {
        this.duration = ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
      }
      catch (err) {
        console.log(err)
      }
    },

    async getStatus() {
      try {
        this.status = ["Active", "Inactive"],
          console.log(this.status)
      } catch (err) {
        console.log(err)
      }
    },

    async getInstallmentcheck() {
      console.log("this.installmentCheck", this.installmentCheck);
    },

    async getGstcheck() {
      console.log("this.gstCheck", this.gstCheck);
    },

    async editGstcheck() {
      this.subjectsList.forEach((subject) => {
        subject.totalFees = parseFloat(subject.basicFees) + parseFloat(parseFloat(subject.basicFees) * parseFloat(this.selectedGst)) / 100;
        console.log("addTotalfee", JSON.stringify(subject.totalFees));
        this.subjectsList = this.subjectsList;
      });
    },

    async addTotalfee(item) {
      let gstvalue = this.selectedGst;
      this.subjectsList.filter(function (subject) {
        if (subject.subjectId == item.subjectId) {
          subject.totalFees = parseFloat(item.basicFees) + parseFloat(parseFloat(item.basicFees) * parseFloat(gstvalue)) / 100;
          return true;
        }
      });
    },

    async editTotalfee() {
      this.subjectsList.forEach((subject) => {
        if (this.isAllSubjectCheck) {
          subject.isCompulsory = 1;
        } else {
          subject.isCompulsory = 0;
        }
      });
      console.log("addTotalfee", JSON.stringify(item));
      var subject = this.subjectsList.find(x => x.subjectId == item.subjectId);
      if (subject) {
        subject.totalFees = parseFloat(item.basicFees) + parseFloat(parseFloat(item.basicFees) * parseFloat(this.selectedGst)) / 100;
        this.subjectsList = this.subjectsList;
      }
    },

    async addBasicfee(item) {
      this.isSubjectListLoading = false;
      let gstvalue = this.selectedGst;
      this.subjectsList.filter(function (subject) {
        if (subject.subjectId == item.subjectId) {
          if (gstvalue > 0) {
            subject.basicFees = item.basicFees;
          } else {
            subject.basicFees = item.basicFees;
            subject.totalFees = item.basicFees;
          }
        }
      });
      this.addTotalfee(item);
      this.isSubjectListLoading = true;
    },

    async getAllSubjectcheck() {
      this.isAllSubjectCheck = this.isAllSubjectCheck;
      this.subjectsList.forEach((subject) => {
        if (this.isAllSubjectCheck) {
          subject.isCompulsory = 1;
        } else {
          subject.isCompulsory = 0;
        }
      });
    },

    async getAllFromFieldCheck() {
      // this.allFormActivity = !this.allFormActivity
      this.formActivity.forEach((form) => {
        if (this.allFormActivity && form.status == 0) {
          form.status = 1;
        } else if (form.isCompulsory == 1) {
          form.status = 1;
        } else {
          form.status = 0;
        }
      });
    },

    async updateAll() {
      if (this.subjects.length == this.subjectsList.length) {
        this.isAllSubjectCheck = 1;
      }
      else {
        this.isAllSubjectCheck = 0;
      }

    },

    async updateFromFieldStatus() {
      console.log("AAAAA==>")
    },

    convertToDDMMYYYY(dateString) {
      return convertDate(dateString);
    },

    convertToIstTime(dateString) {
      return convertToISTTime(dateString);
    },

    async submit() {
      alert("form saved successfully")
      this.saveAdmission()
    },

    async saveEditsubmit() {

      alert("form saved successfully")
      this.saveEditAdmission()

    },

    async saveEditAdmission() {

      try {
        const objToPush = {
          admissionId: this.selectedAdmissionId,
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedCourseName,
          startDate: this.startDate,
          courseName: this.selectedClassName,
          subjectCompulsory: this.isAllSubjectCheck,
          endDate: this.endDate,
          status: this.switch1,

        }
        const saveAdmission = await this.admissionUserRepositoryInstance.saveEditAdmission(
          objToPush
        )
        this.saveEditPayment(saveAdmission.admissionId);
      }
      catch (err) {
        console.log(err)
      }

    },

    async saveEditPayment() {
      try {
        const objToPush = {
          admissionPaymentId: this.admissionPaymentId,
          admissionId: this.selectedAdmissionId,
          instalmentsNumber: this.selectedInstallment,
          instalmentDuration: this.selectedDuration,
          gstValue: this.selectedGst,
          status: this.switch1,
        }
        const savePayment = await this.admissionUserRepositoryInstance.saveEditPayment(
          objToPush
        )
        this.saveEditSubject()
        if (this.startDate > this.endDate) {
          alert("start date")
        }
        else {
          console.log("false");
        }
      }

      catch (err) {
        console.log(err)
      }
    },

    async saveEditSubject() {
      try {
        const objToPush = {
          admissionId: this.selectedAdmissionId,
          subjectsLists: this.subjectsList,
        }
        const saveAdmission = await this.admissionUserRepositoryInstance.saveEditSubject(
          objToPush
        )
        this.saveEditFormActivity();
      }

      catch (err) {
        console.log(err)
      }
    },

    async saveEditFormActivity() {
      try {
        const objToPush = {
          admissionId: this.selectedAdmissionId,
          allFormActivity: this.allFormActivity,
          formActivity: this.formActivity
        }
        const saveEditFormActivity = await this.admissionUserRepositoryInstance.saveFormActivity(objToPush)
      }

      catch (err) {
        console.log(err)
      }
    },
  }
}
</script>
<style src="./activityAdmission.css" scoped>

</style>
