<template src="./activityTimeline.html"></template>

<script>
import SemesterRepository from "../../Repository/Semester";
import UserRepository from "../../Repository/User";
import CoursesRepository from "../../Repository/courses"
import inputContainer from "../../Components/inputContainer";
import arrayOps from "../../Services/Utils/arrayOps";
import PostsRepository from "../../Repository/Posts";
import DiscussionRepository from "../../Repository/Discussions";
import { v4 as uuid } from "uuid"
import DownloadTileList from "../../Components/activityTimeline/DownloadTileList.vue";
import DiscussionListCard from "../../Components/activityTimeline/DiscussionListCard.vue";
import DiscussionCard from "../../Components/activityTimeline/DiscussionCard.vue";
import { spacesUrl } from "../../NetworkManager";
import SubjectsRepository from '../../Repository/Subject'
import moment from "moment"

export default {
  name: "activityTimeline",
  components: {
    inputContainer,
    DownloadTileList,
    DiscussionListCard,
    DiscussionCard
  },
  data() {
    return {
      isMobileView: false,
      dialogBoxMobileview: false,
      dialog: false,
      userData: {},
      posts: [],
      discussionForum: [],
      files: [],
      files2: [],
      postTitle: "",
      postDescription: "",
      uploadTask: "",
      uploadEnd: false,
      uploading: false,
      showAddPostDialog: false,
      showAddDiscussionDialog: false,
      showToStudents: false,
      showToFaculties: false,
      selectedDepartment: "",
      selectedSubject: "",
      selectedDepartment2: "",
      departments: [],
      selectedCourseYear: "",
      courseYears: [],
      selectedDivision: "",
      divisions: [],
      selectedInstitute: "",
      selectedSemester: {},
      academicDataTree: { "": { "": { "": {} } } },
      subjectsMapping: {},
      isImportant: false,
      subjects: [],
      selectedFacultyCriteria: [],
      selectedStudentCriteria: [],
      fileCurrentlyUploading: "",
      postBeingDeleted: null,
      isUploadingPost: false,
      publishAt: null,
      filterByItems: ["Oldest to Newest", "Newest to Oldest"],
      userFullname: "",
      publishDate: null,
      publishTime: null,
      allowStudentToComment: false,
      addPostCurrentPage: 0,
      mediaLibraryImages: [],
      mediaLibraryVideos: [],
      mediaLibraryAudios: [],
      mediaLibraryDocuments: [],
      mediaLibraryItems: {},
      availableMediaTypes: ["Audio", "Video", "Image", "Document"],
      mediaLibrarySelects: [{ type: "", selected: "", linksOfAttachments: "", fileType: "" }],
      mediaStudentRecipients: [{ semId: "", department: "", courseYear: "", division: "", subjectId: "" }],
      mediaFacultyRecipients: [{ semId: "", department: "", courseYear: "", division: "", subjectId: "" }],
      makeAllAttachmentDownloadable: false,
      externalLink: "",
      selectAllStudent: false,
      selectAllFaculty: false,
      subjectByDeptAndCourseYear: {},
      datetime: new Date(),
      errorMsg: "",
      on: undefined,
      // Discussion
      discussions: [],
      addDiscussionCurrentPage: 0,
      discussionTitle: "",
      discussionDescription: "",
      discussionPublishDate: null,
      discussionPublishTime: null,
      discussionPublishEndDate: null,
      discussionPublishEndTime: null,
      discussionIsImportant: false,
      discussionMakeAllAttachmentDownloadable: false,
      discussionExternalLink: "",
      discussionFiles2: [],
      discussionMediaLibrarySelects: [{ type: "", selected: "", linksOfAttachments: "", fileType: "" }],
      discussionMediaStudentRecipients: [{ semId: "", department: "", courseYear: "", division: "", subjectId: "" }],
      discussionMediaFacultyRecipients: [{ semId: "", department: "", courseYear: "", division: "", subjectId: "" }],
      discussionSelectAllStudent: false,
      discussionSelectAllFaculty: false,
      errorMsg2: "",
      loadingText2: "",
      addDiscussionLoading: false,
      uuid,
      commentText: [],
      showComments: [],
      addPostLoading: false,
      loadingText: "",
      semestersList: [],
      postDiscussions: [],
      moment
    };
  },
  created() {
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];

    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.postsRepositoryInstance = new PostsRepository(this);
    this.discussionRepositoryInstance = new DiscussionRepository(this);
    this.coursesRepositoryInstance = new CoursesRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.subjectsMapping = this.$store.getters["mappings/get_subjectsMapping"];
    this.$store.commit("liveData/set_selectedActivityName", "Timeline");
    this.userData = this.$store.getters["user/get_userData"];

    this.getSemesters()
    this.fetchData();
    this.getAllMediaLibrary()
  },
  methods: {
    async fetchSubjects(semId, department, courseYear) {

      try {
        if (!this.subjectByDeptAndCourseYear[semId]) {
          this.subjectByDeptAndCourseYear[semId] = {}
        }
        if (!this.subjectByDeptAndCourseYear[semId][department]) {
          this.subjectByDeptAndCourseYear[semId][department] = {}
        }
        if (!this.subjectByDeptAndCourseYear[semId][department][courseYear]) {
          this.subjectByDeptAndCourseYear[semId][department][courseYear] = []
        }

        this.subjectByDeptAndCourseYear[semId][department][courseYear] = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
          instituteId: this.selectedInstitute,
          semId: semId,
          department: department,
          courseYear: courseYear,
        });
      } catch (e) {

      }
    },
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semestersList = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        this.semestersList.forEach(sem => {
          this.getMasterDataTree(sem.semId)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async handleSubjects() {
      this.subjects[0] = 'All Subjects'
      this.userData.subjects.map((subject) => {
        this.subjects.push(subject.subjectName)
      })
    },
    async selectSubjects() {

    },
    async fetchData() {
      try {
        const objToPush = {
          uId: this.userData.uId,
          instituteId: this.selectedInstitute,
          from: "admin"
        };
        this.userFullname = await this.userRepositoryInstance.getFullNameOfUser(objToPush)

        const tempPost = await this.postsRepositoryInstance.getPosts(objToPush);
        this.discussions = await this.discussionRepositoryInstance.getAllDiscussionsForAUser(objToPush)
        this.commentText = new Array(tempPost.length).fill("")
        this.showComments = new Array(tempPost.length).fill(false)
        this.posts = tempPost
        this.posts.forEach((post) => {
          console.log(post);
          post.count = 0;
          post.commentText = ""
          post.type = "post"
        });
        this.discussions.forEach(d => {
          d.type = "discussion"
        })
        console.log(this.posts);
        const batchSize = 100;

        const promises = [];
        for (let i = 0; i < this.posts.length; i += batchSize) {
          promises.push(
            this.getFullNamesOfUsers(
              i,
              i + batchSize < this.posts.length
                ? i + batchSize
                : this.posts.length
            )
          );
        }

        await Promise.allSettled(promises);
        this.postDiscussions = [...this.posts, ...this.discussions]
        this.postDiscussions.sort((a, b) => {
          if (a.type === b.type && a.type === "post") {
            return new Date(a.dateOfPost) - new Date(b.dateOfPost)
          } else if (a.type === b.type && a.type === "discussion") {
            return new Date(a.startDate) - new Date(b.startDate)
          } else if (a.type === "post" && b.type === "discussion") {
            return new Date(a.dateOfPost) - new Date(b.startDate)
          } else if (a.type === "discussion" && b.type === "post") {
            return new Date(a.startDate) - new Date(b.dateOfPost)
          }
          return 0
        })
        this.postDiscussions.reverse()
      } catch (err) {
        console.log(err);
      }



    },
    async getFullNamesOfUsers(firstIndex, lastIndex) {

      try {
        const objToPush = {
          uIds: []
        };

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.posts[i].postedById);
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        );

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(
            (elem) => elem.uId === this.posts[i].postedById
          );
          this.posts[i].nameOfPostee = user ? user.fullName : "";
        }
      } catch (err) {

      }
    },
    async getFullName(index) {
      try {
        this.posts[index].nameOfPostee =
          await this.userRepositoryInstance.getFullNameOfUser({
            uId: this.posts[index].postedById
          });
      } catch (err) {

        this.posts[index].nameOfPostee = "";
      }
    },
    removeFile(i) {
      this.files = arrayOps.removeFromArray(this.files, i);
    },
    filesChanged(e) {

    },
    getFileName(url) {
      const decodedUrl = decodeURIComponent(url);
      const lastIndexOfDelimiter = decodedUrl.lastIndexOf("________");
      const indexOfQueryParams = decodedUrl.lastIndexOf("?alt");

      return decodedUrl.substring(
        lastIndexOfDelimiter + "________".length,
        indexOfQueryParams
      );
    },
    async getMasterDataTree(semId) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: semId
        };
        const semestersList =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        this.constructAcademicDataTree(semestersList, semId);
      } catch (err) {

      }
    },
    async constructAcademicDataTree(semestersList, semId) {

      semestersList.forEach((sem) => {
        if (!this.academicDataTree[semId]) {
          this.academicDataTree[semId] = {}
        }
        if (!this.academicDataTree[semId][sem.department]) {
          this.academicDataTree[semId][sem.department] = {};
        }
        if (!this.academicDataTree[semId][sem.department][sem.courseYear]) {
          this.academicDataTree[semId][sem.department][sem.courseYear] = [];
        }
        this.academicDataTree[semId][sem.department][sem.courseYear].push(sem.division);
        try {
          this.fetchSubjects(semId, sem.department, sem.courseYear)
        } catch (error) {
        }
      });
    },
    selectDepartment() {
      this.courseYears = [];
      this.selectedCourseYear = "";
      this.divisions = [];
      this.selectedDivision = "";
      this.courseYears = ["All Course Years"];
      if (this.academicDataTree[this.selectedDepartment]) {
        this.courseYears = this.courseYears.concat(
          Object.keys(this.academicDataTree[this.selectedDepartment])
        );
      }
    },
    selectCourseYear() {
      this.divisions = [];
      this.selectedDivision = "";
      this.divisions = ["All Divisions"];
      if (
        this.academicDataTree[this.selectedDepartment][this.selectedCourseYear]
      ) {
        this.divisions = this.divisions.concat(
          this.academicDataTree[this.selectedDepartment][
          this.selectedCourseYear
          ]
        );
      }
    },
    dismissStudentCriteria() {
      this.resetStudentCriteria();
      this.showToStudents = false;
    },
    addStudentCriteria() {
      if (
        this.selectedDepartment === "All Departments" ||
        this.selectedCourseYear === "All Course Years" ||
        this.selectedDivision === "All Divisions"
      ) {
        this.addAllStudentCriteria();
      } else {
        if (this.selectedSubject !== "" && this.selectedSubject !== 'All Subjects') {
          const criteriaToInsert = {
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: this.selectedDivision,
            subject: this.selectedSubject
          };
          if (
            this.selectedStudentCriteria.filter(
              (elem) =>
                elem.department === criteriaToInsert.department &&
                elem.courseYear === criteriaToInsert.courseYear &&
                elem.division === criteriaToInsert.division &&
                elem.subject === criteriaToInsert.subject
            ).length === 0
          ) {
            this.selectedStudentCriteria.push(criteriaToInsert);
          }
        } else {
          const criteriaToInsert = {
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: this.selectedDivision
          };
          if (
            this.selectedStudentCriteria.filter(
              (elem) =>
                elem.department === criteriaToInsert.department &&
                elem.courseYear === criteriaToInsert.courseYear &&
                elem.division === criteriaToInsert.division
            ).length === 0
          ) {
            this.selectedStudentCriteria.push(criteriaToInsert);
          }
        }
      }
      this.resetStudentCriteria();
      this.showToStudents = false;
    },
    addAllStudentCriteria() {
      if (this.selectedDepartment === "All Departments") {
        this.selectedStudentCriteria = [];
        Object.keys(this.academicDataTree).forEach((department) => {
          Object.keys(this.academicDataTree[department]).forEach(
            (courseYear) => {
              this.academicDataTree[department][courseYear].forEach(
                (division) => {
                  this.selectedStudentCriteria.push({
                    department: department,
                    courseYear: courseYear,
                    division: division
                  });
                }
              );
            }
          );
        });
      } else if (this.selectedCourseYear === "All Course Years") {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          (elem) => elem.department !== this.selectedDepartment
        );
        Object.keys(this.academicDataTree[this.selectedDepartment]).forEach(
          (courseYear) => {
            this.academicDataTree[this.selectedDepartment][courseYear].forEach(
              (division) => {
                this.selectedStudentCriteria.push({
                  department: this.selectedDepartment,
                  courseYear: courseYear,
                  division: division
                });
              }
            );
          }
        );
      } else if (this.selectedDivision === "All Divisions") {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          (elem) =>
            elem.department !== this.selectedDepartment ||
            elem.courseYear !== this.selectedCourseYear
        );
        this.academicDataTree[this.selectedDepartment][
          this.selectedCourseYear
        ].forEach((division) => {
          this.selectedStudentCriteria.push({
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: division
          });
        });
      }
    },
    async uploadToSpaces(file, signedUrl) {

      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          // 
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {

            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );

      this.currentUploadProgress = 0;

      return res;
    },
    futch(url, opts = {}, onProgress) {

      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      const signedUrl = await this.postsRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    async uploadFiles() {
      if (this.files.length === 0) return [];
      const filePromises = [];
      const files = this.files.filter(item => item.name);

      files.forEach(documentFile => {
        filePromises.push(
          this.getSignedUrl(documentFile, `${this.userData.uId}/documents/`)
        );
      });
      const signedUrlsData = await Promise.all(filePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);

      const uploadPromises = [];
      if (signedUrls.length === files.length) {
        files.forEach((documentFile, i) => {
          uploadPromises.push(this.uploadToSpaces(documentFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);

        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: files[i].name,
          mimeType: item.mimeType,
          type: files[i].type
        }));
      }
      return [];
    },
    async uploadDiscussionFiles() {
      if (this.files2.length === 0) return [];
      const filePromises = [];
      const files = this.files2.filter(item => item.name);

      files.forEach(documentFile => {
        filePromises.push(
          this.getSignedUrl(documentFile, `${this.userData.uId}/documents/`)
        );
      });
      const signedUrlsData = await Promise.all(filePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);

      const uploadPromises = [];
      if (signedUrls.length === files.length) {
        files.forEach((documentFile, i) => {
          uploadPromises.push(this.uploadToSpaces(documentFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);

        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: files[i].name,
          mimeType: item.mimeType,
          type: files[i].type
        }));
      }
      return [];
    },
    async uploadPost() {
      try {
        this.addPostLoading = true;
        this.loadingText = this.files.length === 0 ? "Please Wait Uploading Post" : "Please Wait Uploading Files"
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          title: this.postTitle,
          description: this.postDescription,
          isImportant: this.isImportant,
          postedById: this.userData.uId,
          dateOfPost: this.publishDate === null ? new Date() : new Date(`${this.publishDate} ${this.publishTime}`),
          externalLink: this.externalLink,
          mediaLibrarySelects: this.mediaLibrarySelects,
          selectAllStudent: this.selectAllStudent,
          selectAllFaculty: this.selectAllFaculty,
          nameOfPostee: this.userFullname,
          attachedFiles: await this.uploadFiles()
        };

        objToPush['criteria'] = {
          forFaculties: this.mediaFacultyRecipients.length > 0,
          forStudents: this.mediaStudentRecipients.length > 0,
          facultyCriteria: this.mediaFacultyRecipients,
          studentCriteria: this.mediaStudentRecipients
        }

        this.loadingText = "Uploading Post"

        await this.postsRepositoryInstance.createPost(objToPush);
        this.resetAddPost();
        this.fetchData();
      } catch (err) {
        this.resetAddPost();
        this.fetchData();

      } finally {
        this.resetAddPost();
        this.fetchData();
        this.showAddPostDialog = false
        this.addPostLoading = false;
        this.loadingText = ""
      }
    },
    async uploadDiscussion() {
      try {
        this.addDiscussionLoading = true;
        this.loadingText2 = this.files2.length === 0 ? "Please Wait Uploading Post" : "Please Wait Uploading Files"

        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          title: this.discussionTitle,
          description: this.discussionDescription,
          postedById: this.userData.uId,
          postedByFullname: this.userFullname,
          isImportant: this.discussionIsImportant,
          criteria: {
            forFaculties: this.discussionMediaFacultyRecipients.length > 0,
            forStudents: this.discussionMediaStudentRecipients.length > 0,
            facultyCriteria: this.discussionMediaFacultyRecipients,
            studentCriteria: this.discussionMediaStudentRecipients
          },
          startDate: new Date(`${this.discussionPublishDate} ${this.discussionPublishTime}`),
          endDate: new Date(`${this.discussionPublishEndDate} ${this.discussionPublishEndTime}`),
          externalLink: this.discussionExternalLink,
          mediaLibrarySelects: this.discussionMediaLibrarySelects,
          selectAllStudent: this.discussionSelectAllStudent,
          selectAllFaculty: this.discussionSelectAllFaculty,
          attachedFiles: await this.uploadDiscussionFiles()
        };
        this.loadingText2 = "Uploading Post"

        await this.discussionRepositoryInstance.createDiscussion(objToPush)
      } catch (err) {
        this.resetAddDiscussion();
        this.fetchData();

      } finally {
        this.resetAddDiscussion();
        this.fetchData();
        this.showAddDiscussionDialog = false
        this.addDiscussionLoading = false;
        this.loadingText2 = ""
      }
    },
    getFileType(fileUrl) {
      const intermediatePath = fileUrl.split("?alt=media")[0];
      return intermediatePath.substring(
        intermediatePath.lastIndexOf("."),
        intermediatePath.length
      );
    },
    resetStudentCriteria() {
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedDivision = "";
      this.selectedSubject = ''
    },
    dismissFacultyCriteria() {
      this.selectedDepartment2 = "";
      this.showToFaculties = false;
    },
    addFacultyCriteria() {
      if (this.selectedDepartment2 === "All Departments") {
        this.selectedFacultyCriteria = [];
        Object.keys(this.academicDataTree).forEach((department) => {
          this.selectedFacultyCriteria.push({
            department: department
          });
        });
      } else if (
        !this.selectedFacultyCriteria.includes(this.selectedDepartment2)
      ) {
        this.selectedFacultyCriteria.push({
          department: this.selectedDepartment2
        });
      }
      this.selectedDepartment2 = "";
      this.showToFaculties = false;
    },
    removeStudentCriteria(index) {
      this.selectedStudentCriteria = arrayOps.removeFromArray(
        this.selectedStudentCriteria,
        index
      );
    },
    removeFacultyCriteria(index) {
      this.selectedFacultyCriteria = arrayOps.removeFromArray(
        this.selectedFacultyCriteria,
        index
      );
    },
    async deletePost(post) {
      this.$confirm("Are you sure you want to delete ?").then(async () => {
        try {
          const { postId, postedById } = post
          this.postBeingDeleted = postId
          await this.postsRepositoryInstance.deletePost({
            postId, postedById
          })
          this.postDiscussions = this.postDiscussions.filter((post) => post.postId !== postId)
          this.fetchData()
        } catch (err) {
          console.error(err)
        } finally {
          this.postBeingDeleted = null
        }
      })
    },
    async deleteDiscussion(discussion) {
      this.$confirm("Are you sure you want to delete ?").then(async () => {
        try {
          const { discussionId, postedById } = discussion
          await this.discussionRepositoryInstance.deleteDiscussion({
            discussionId, postedById
          })
          this.postDiscussions = this.postDiscussions.filter((discussion) => discussion.discussionId !== discussionId)
          this.fetchData()
        } catch (err) {
          console.error(err)
        }
      })
    },
    resetAddPost() {
      this.postTitle = "";
      this.postDescription = "";
      this.showToStudents = false;
      this.showToFaculties = false;
      this.selectedDepartment = "";
      this.selectedDepartment2 = "";
      this.selectedCourseYear = "";
      this.selectedDivision = "";
      this.isImportant = false;
      this.mediaLibrarySelects = [{ semId: "", type: "", selected: "", linksOfAttachments: "", fileType: "" }];
      this.mediaStudentRecipients = [{ semId: "", department: "", courseYear: "", division: "", subjectId: "", id: uuid() }];
      this.mediaFacultyRecipients = [{ semId: "", department: "", courseYear: "", division: "", subjectId: "", id: uuid() }];
      this.files = [];
      this.publishAt = null;
      this.allowStudentToComment = false
      this.publishDate = null
      this.publishTime = null
      this.makeAllAttachmentDownloadable = false
      this.externalLink = ""
      this.selectAllStudent = false
      this.selectAllFaculty = false
      this.addPostCurrentPage = 0
      this.error = ""
    },
    resetAddDiscussion() {
      this.addDiscussionLoading = false
      this.addDiscussionCurrentPage = 0
      this.discussionTitle = ""
      this.discussionDescription = ""
      this.discussionPublishDate = null
      this.discussionPublishTime = null
      this.discussionPublishEndDate = null
      this.discussionPublishEndTime = null
      this.discussionIsImportant = false
      this.discussionMakeAllAttachmentDownloadable = false
      this.discussionExternalLink = ""
      this.discussionFiles2 = []
      this.discussionMediaLibrarySelects = [{ type: "", selected: "", linksOfAttachments: "", fileType: "" }]
      this.discussionMediaStudentRecipients = [{ semId: "", department: "", courseYear: "", division: "", subjectId: "", id: uuid() }]
      this.discussionMediaFacultyRecipients = [{ semId: "", department: "", courseYear: "", division: "", subjectId: "", id: uuid() }]
      this.discussionSelectAllStudent = false
      this.discussionSelectAllFaculty = false
      this.errorMsg2 = ""
      this.loadingText2 = ""
    },
    onFilterPosts(filter) {
      // ["Oldest to Newest", "Newest to Oldest", "Videos Only", "Images Only", "Description Only", "Document", "Discussion Forum"]
      switch (filter) {
        case this.filterByItems[0]:
          this.postDiscussions.sort((a, b) => {
            if (a.type === b.type && a.type === "post") {
              return new Date(a.dateOfPost) - new Date(b.dateOfPost)
            } else if (a.type === b.type && a.type === "discussion") {
              return new Date(a.startDate) - new Date(b.startDate)
            } else if (a.type === "post" && b.type === "discussion") {
              return new Date(a.dateOfPost) - new Date(b.startDate)
            } else if (a.type === "discussion" && b.type === "post") {
              return new Date(a.startDate) - new Date(b.dateOfPost)
            }
            return 0
          })
          break;
        case this.filterByItems[1]:
          this.postDiscussions.sort((a, b) => {
            if (a.type === b.type && a.type === "post") {
              return new Date(b.dateOfPost) - new Date(a.dateOfPost)
            } else if (a.type === b.type && a.type === "discussion") {
              return new Date(b.startDate) - new Date(a.startDate)
            } else if (a.type === "post" && b.type === "discussion") {
              return new Date(b.startDate) - new Date(a.dateOfPost)
            } else if (a.type === "discussion" && b.type === "post") {
              return new Date(b.dateOfPost) - new Date(a.startDate)
            }
            return 0
          })
          break;
        case this.filterByItems[2]: break;
        case this.filterByItems[3]: break;
        case this.filterByItems[4]: break;
        case this.filterByItems[5]: break;
        case this.filterByItems[6]: break;

        default:
          break;
      }
    },
    async getAllMediaLibrary() {

      const objToPush = {
        instituteId: this.selectedInstitute
      }
      // 
      const result = await this.coursesRepositoryInstance.getAllMediaLibraryByInstitute(objToPush)

      result.map((obj) => {
        if (obj.type === "audio/mpeg") {
          this.mediaLibraryAudios.push(obj)
        }
        if (obj.type === "video/mp4") {
          this.mediaLibraryVideos.push(obj)
        }
        if (obj.type === "application/pdf" || obj.type === "application/msword") {
          this.mediaLibraryDocuments.push(obj)
        }
        if (obj.type === "image/png") {
          this.mediaLibraryImages.push(obj)
        }
      })
      // ["Audio", "Video", "Image", "Document"]
      this.mediaLibraryItems["Audio"] = this.mediaLibraryAudios
      this.mediaLibraryItems["Video"] = this.mediaLibraryVideos
      this.mediaLibraryItems["Image"] = this.mediaLibraryImages
      this.mediaLibraryItems["Document"] = this.mediaLibraryDocuments

      console.log("mediaLibrary",
        this.mediaLibraryImages,
        this.mediaLibraryVideos,
        this.mediaLibraryAudios, this.mediaLibraryDocuments
      );
    },
    changeType(event, i) {

      this.mediaLibrarySelects[i].type = event
    },
    changeSelectedMedia(event, i) {

      this.mediaLibrarySelects[i].selected = event.title
      this.mediaLibrarySelects[i].linksOfAttachments = event.linksOfAttachments
      this.mediaLibrarySelects[i].fileType = event.type
    },
    onDeleteMediaLibrary(i) {
      if (i === 0) {
        if (this.mediaLibrarySelects.length === 1) {
          this.mediaLibrarySelects = [{ type: "", selected: "", linksOfAttachments: "", fileType: "" }]
          return
        }
      }
      this.mediaLibrarySelects.splice(i, 1)
    },
    addMediaSelect() {
      this.mediaLibrarySelects.push({ type: "", selected: "" })
    },
    addStudentSelect() {
      this.mediaStudentRecipients.push({ department: "", courseYear: "", division: "", semId: "", id: uuid() })
    },
    changeSemester(event, i) {

      this.mediaStudentRecipients[i].subjectId = ""
      this.mediaStudentRecipients[i].division = ""
      this.mediaStudentRecipients[i].courseYear = ""
      this.mediaStudentRecipients[i].department = ""
      this.mediaStudentRecipients[i].semId = event.semId
    },
    changeFacultySemester(event, i) {
      this.mediaFacultyRecipients[i].subjectId = ""
      this.mediaFacultyRecipients[i].division = ""
      this.mediaFacultyRecipients[i].courseYear = ""
      this.mediaFacultyRecipients[i].department = ""
      this.mediaFacultyRecipients[i].semId = event.semId
    },
    changeDiscussionSemester(event, i) {
      this.discussionMediaStudentRecipients[i].subjectId = ""
      this.discussionMediaStudentRecipients[i].division = ""
      this.discussionMediaStudentRecipients[i].courseYear = ""
      this.discussionMediaStudentRecipients[i].department = ""
      this.discussionMediaStudentRecipients[i].semId = event.semId
    },
    changeDiscussionFacultySemester(event, i) {
      this.discussionMediaFacultyRecipients[i].subjectId = ""
      this.discussionMediaFacultyRecipients[i].division = ""
      this.discussionMediaFacultyRecipients[i].courseYear = ""
      this.discussionMediaFacultyRecipients[i].department = ""
      this.discussionMediaFacultyRecipients[i].semId = event.semId
    },
    changeDept(event, i) {
      this.mediaStudentRecipients[i].department = event
    },
    changeCourseYear(event, i) {
      this.mediaStudentRecipients[i].courseYear = event
      this.fetchSubjects(this.mediaStudentRecipients[i].semId, this.mediaStudentRecipients[i].department, this.mediaStudentRecipients[i].courseYear)
    },
    changeDivision(event, i) {
      this.mediaStudentRecipients[i].division = event
    },
    changeSubject(event, i) {
      this.mediaStudentRecipients[i].subjectId = event
    },
    onDeleteMediaStudentRecipient(i) {
      if (i === 0) {
        if (this.mediaStudentRecipients.length === 1) {
          this.mediaStudentRecipients = [{ department: "", courseYear: "", division: "", subjectId: "", semId: "", id: uuid() }]
          return
        }
      }
      this.mediaStudentRecipients.splice(i, 1)
    },
    addFacultySelect() {
      this.mediaFacultyRecipients.push({ department: "", courseYear: "", division: "", semId: "", id: uuid() })
    },
    changeFacultyDept(event, i) {
      this.mediaFacultyRecipients[i].department = event
    },
    changeFacultyCourseYear(event, i) {
      this.mediaFacultyRecipients[i].courseYear = event
    },
    changeFacultyDivision(event, i) {
      this.mediaFacultyRecipients[i].division = event
    },
    onDeleteMediaFacultyRecipient(i) {
      if (i === 0) {
        if (this.mediaFacultyRecipients.length === 1) {
          this.mediaFacultyRecipients = [{ semId: "", department: "", courseYear: "", division: "", subjectId: "", id: uuid() }]
          return
        }
      }
      this.mediaFacultyRecipients.splice(i, 1)
    },
    changeFacultySubject(event, i) {
      this.mediaFacultyRecipients[i].subjectId = event
    },
    handleSubjectsMapping() {
      this.subjectByDeptAndCourseYear = {}
      Object.keys(this.subjectsMapping).forEach(key => {
        const subject = this.subjectsMapping[key]
        if (!this.subjectByDeptAndCourseYear[subject.department]) {
          this.subjectByDeptAndCourseYear[subject.department] = {}
        }
        if (!this.subjectByDeptAndCourseYear[subject.department][subject.courseYear]) { this.subjectByDeptAndCourseYear[subject.department][subject.courseYear] = [] }

        (this.subjectByDeptAndCourseYear[subject.department][subject.courseYear]).push({ name: subject.subjectName, subjectId: subject.subjectId })
      })
    },
    onNextClick() {

      if (this.addPostCurrentPage === 0) {
        if (this.postTitle === "") {
          this.errorMsg = "Please Enter Post Title"
          return
        }
      }
      if (this.addPostCurrentPage === 2) {
        if (this.mediaStudentRecipients[0].department === "" && !this.selectAllStudent) {
          this.errorMsg = "Please Select Student Receipents"
          return
        }
        if (this.mediaFacultyRecipients[0].department === "" && !this.selectAllFaculty) {
          this.errorMsg = "Please Select Faculty Receipents"
          return
        }
      }
      if (this.addPostCurrentPage === 2) {
        this.uploadPost()
        return
      }

      this.errorMsg = ""
      this.addPostCurrentPage++;
    },
    onNextClickDiscussion() {
      if (this.addDiscussionCurrentPage === 0) {
        if (this.discussionTitle === "") {
          this.errorMsg2 = "Please Enter Discussion Title"
          return
        }
        if (this.discussionPublishDate === null) {
          this.errorMsg2 = "Please Select Discussion Start Date"
          return
        }
        if (this.discussionPublishTime === null) {
          this.errorMsg2 = "Please Select Discussion Start Time"
          return
        }
        if (this.discussionPublishEndDate === null) {
          this.errorMsg2 = "Please Select Discussion End Date"
          return
        }
        if (this.discussionPublishEndTime === null) {
          this.errorMsg2 = "Please Select Discussion End Time"
          return
        }
        if (new Date(`${this.discussionPublishDate} ${this.discussionPublishTime}`) > new Date(`${this.discussionPublishEndDate} ${this.discussionPublishEndTime}`)) {
          this.errorMsg2 = "Start Time must be greater than End Time"
          return
        }
      }
      if (this.addDiscussionCurrentPage === 2) {
        if (this.discussionMediaStudentRecipients[0].department === "" && this.discussionSelectAllStudent === false) {
          this.errorMsg2 = "Please Select Media Student Recipients"
          return
        }
        if (this.discussionMediaFacultyRecipients[0].department === "" && this.discussionMediaFacultyRecipients === false) {
          this.errorMsg2 = "Please Select Media Faculty Recipients"
          return
        }
      }
      this.errorMsg2 = ""
      if (this.addDiscussionCurrentPage === 2) {
        this.uploadDiscussion()
        return
      }
      this.addDiscussionCurrentPage++;
    },
    changeDiscussionMediaType(event, i) {

      this.discussionMediaLibrarySelects[i].type = event
    },
    changeDiscussionSelectedMedia(event, i) {

      this.discussionMediaLibrarySelects[i].selected = event.title
      this.discussionMediaLibrarySelects[i].linksOfAttachments = event.linksOfAttachments
      this.discussionMediaLibrarySelects[i].fileType = event.type
    },
    onDeleteDiscussionMediaLibrary(i) {
      if (i === 0) {
        if (this.discussionMediaLibrarySelects.length === 1) {
          this.discussionMediaLibrarySelects = [{ type: "", selected: "", linksOfAttachments: "", fileType: "" }]
          return
        }
      }
      this.discussionMediaLibrarySelects.splice(i, 1)
    },
    addDiscussionMediaSelect() {
      this.discussionMediaLibrarySelects.push({ type: "", selected: "" })
    },
    changeDiscussionDept(event, i) {
      this.discussionMediaStudentRecipients[i].department = event
    },
    changeDiscussionCourseYear(event, i) {
      this.discussionMediaStudentRecipients[i].courseYear = event
    },
    changeDiscussionDivision(event, i) {
      this.discussionMediaStudentRecipients[i].division = event
    },
    changeDiscussionSubject(event, i) {
      this.discussionMediaStudentRecipients[i].subjectId = event
    },
    onDeleteDiscussionMediaStudentRecipient(i) {
      if (i === 0) {
        if (this.discussionMediaStudentRecipients.length === 1) {
          this.discussionMediaStudentRecipients = [{ department: "", courseYear: "", division: "", subjectId: "", semId: "", id: uuid() }]
          return
        }
      }
      this.discussionMediaStudentRecipients.splice(i, 1)
    },
    addDiscussionStudentSelect() {
      this.discussionMediaStudentRecipients.push({ department: "", courseYear: "", division: "", subjectId: "", semId: "", id: uuid() })
    },
    changeDiscussionFacultyDept(event, i) {
      this.discussionMediaFacultyRecipients[i].department = event
    },
    changeDiscussionFacultyCourseYear(event, i) {
      this.discussionMediaFacultyRecipients[i].courseYear = event
    },
    changeDiscussionFacultyDivision(event, i) {
      this.discussionMediaFacultyRecipients[i].division = event
    },
    changeDiscussionFacultySubject(event, i) {
      this.discussionMediaFacultyRecipients[i].subjectId = event
    },
    onDeleteDiscussionMediaFacultyRecipient(i) {
      if (i === 0) {
        if (this.discussionMediaFacultyRecipients.length === 1) {
          this.discussionMediaFacultyRecipients = [{ semId: "", department: "", courseYear: "", division: "", subjectId: "" }]
          return
        }
      }
      this.discussionMediaFacultyRecipients.splice(i, 1)
    },
    addDiscussionFacultySelect() {
      this.discussionMediaFacultyRecipients.push({ department: "", courseYear: "", division: "", subjectId: "", semId: "", id: uuid() })
    },
    async onAddComment(index) {
      try {
        const objToPush = {
          postId: this.posts[index].postId, comment: { name: this.userFullname, publishAt: new Date(), content: this.commentText[index], uId: this.userData.uId }
        }
        if (this.commentText[index] !== "") {
          await this.postsRepositoryInstance.createComment(objToPush)
          if (Array.isArray(this.posts[index].comments)) {
            this.posts[index].comments.push(objToPush.comment)
          } else {
            this.posts[index].comments = [objToPush.comment]
          }
        }
      } catch (error) {
      } finally {
        this.commentText[index] = ""
      }
    },
    hideComments(idx) {
      const tempComments = [...this.showComments]
      tempComments[idx] = !tempComments[idx]
      this.showComments = tempComments
    },
    getCommentTitle(idx) {
      return this.showComments[idx] ? "Hide Comments" : "Show Comments"
    }
  }
};
</script>
<style src="./activityTimeline.css"></style>
