<template src="./activitySubjectCourses.html"></template>
<script>
import { createHydrationRenderer } from '@vue/runtime-core';
import { database } from 'firebase';
import inputContainer from '../../../Components/inputContainer'
import SubjectsRepository from '../../../Repository/Subject';
import UploadToDigitalOceanRepository from '../../../Repository/uploadToDigitalOcean';
import CourseRepository from '../../../Repository/courses';
import auth from '../../../Services/Firebase/auth';
import showStatus from "../../../NetworkManager/showStatus";
import draggable from "vuedraggable";
import { async } from 'q';
import VueSimpleAlert from "vue-simple-alert";
import axios from 'axios'
import moment from 'moment'
import coursesData from "../../../Repository/mediaLibrary";
import { spacesUrl } from "../../../NetworkManager";
import { v4 as uuidv4 } from 'uuid'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";




export default {
    name: 'activitySubjectCourses',
    props: ["prop_subject", "prop_selectedSemester"],
    order: 0,
    components: {
        draggable,
        inputContainer,
    },
    data() {
        return {
            solutionFileLink: [],
            editor: ClassicEditor,
            editorData: "",
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|',
                        'bold', 'italic', '|',
                        'bulletedList', 'numberedList',
                        'outdent', 'indent', '|',
                        'undo', 'redo',
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
            solutionFileName: '',
            solutionFile: [],
            uploadSolutionDialog: false,
            editImageSolution: [],
            loaderForImg: false,
            first: {},
            image: {},
            selectedMedia: {},
            model: 1,
            Certificate: [],
            subjectImage: [],
            file: {},
            certification: "",
            uploading: false,
            loading: false,
            uploadEnd: false,
            courseCertification: false,
            draggingsubSection: false,
            courseName: "",
            fileName: "",
            backUpdateButtons: "",
            uploadCertificate: "",
            contentType: "",
            courseDescription: "",
            feedback: "",
            sectionJump: "",
            isreAttemptAllowed: "",
            subSectionjump: "",
            downloadURLs: [],
            mediaLibrary: [],
            allowedFileTypeStudents: [],
            questions: [],
            fileTypesToSelect: ['PDF', 'Video', 'Audio', 'Excel', 'Image', 'Any'],
            sections: [],
            tempQuestion: {},
            uploadCount: 0,
            progressUpload: 0,
            addsubSectionDialogue: false,
            uploadQuestionExcelDialog: false,
            addingNewSubsection: false,
            addSectionDialogue: false,
            addingNewSection: false,
            updateSubsection: false,
            isSelecting: false,
            addingQuestion: false,
            jumpBetweenSubsection: false,
            courseDetails: {},
            selectedSection: {},
            icons: {
                delete: 'mdi-delete',
                edit: 'mdi-pencil'
            },
            subSectionContent: ['Assignment', 'Audio', 'Video', 'Document', 'Quiz', 'Feedback'],
            addQuestions: false,
            negativeMarking: false,
            uploadCourseImage: "",
            courseFeedback: false,
            courseImage: '',
            jumpBetweenSection: false,
            sectionTitle: '',
            credit: 0,
            selectedSubject: {},
            sectionDescription: '',
            subsectionTitle: '',
            subsectionCredit: 0,
            subsectionDescription: '',
            subsectionUploadFile: [],
            fileTypesFromStudents: [],
            subSectionContentTypeSelection: '',
            uploadAssignment: '',
            subSectionFileUrl: '',
            subSections: [],
            selectedSubSections: [],
            selectedSectionIndex: 0,
            selectedsubSecIndex: 0,
            durationOfquiz: '',
            creditOfquiz: '',
            passingPercentage: '',
            downloadSampleExcel: '',
            selectedSemester: {},
            enabled: true,
            dragging: false,
            gotFileUrl: false,
            updateQuestionCard: false,
            selectAll: false,
            firestorage: null,
            model: null,
            activeIndex: null,
            activeList: null,
            value: 0,
            subSectionFileUploadProgressBar: false,
            dialog: false,
            feedbackArr: [],
            tempFeedbackQuestion: {},
            quizQuestionExcelFile: [],
            allSelected: false,
            selectedQuestions: [],
            selectedQuestion: {},
            settingsDialog: false,
            videoTimeline: false,
            audioTimeline: false,
            pdfDownload: false,
            isCoursePublished: false,
            isSectionPublished: false,
            isSubsectionPublished: false,
            addSubSectionBtn: false,
            currentUploadProgress: 0,
            updatedOn: '',
            subSectionId: '',
            selectedSemester: '',
            quizQuestionsOption1: "",
            quizQuestionsOption2: "",
            quizQuestionsOption3: "",
            quizQuestionsOption4: "",
            hyperlink: ""
        }
    },
    watch: {
        uploadCertificate: function () {
            this.uploadCertificate.on('state_changed', sp => {
                this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
            },
                null,
                () => {
                    this.uploadCertificate.snapshot.ref.getDownloadURL().then(downloadURL => {
                        this.uploadEnd = true
                        this.downloadURLs.push(downloadURL)
                        console.log("this.downloadURLs", this.downloadURLs)
                        this.uploadCount++
                        if (this.uploadCount === this.Certificate.length) {
                        } else {
                            this.progressUpload = 0
                            console.log("this.progressUpload", this.progressUpload)
                            this.upload(this.uploadCount)
                        }
                    })
                })
        },
        uploadCourseImage: function () {
            this.uploadCourseImage.on('state_changed', sp => {
                this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
            },
                null,
                () => {
                    this.uploadCourseImage.snapshot.ref.getDownloadURL().then(downloadURL => {
                        this.uploadEnd = true
                        this.downloadURLs.push(downloadURL)
                        this.courseImage = downloadURL
                    })
                })
        },
    },
    updated() {
        if (this.currentUploadProgress === 100) {
            setTimeout(() => {
                this.addSubSectionBtn = true
            }, 1500)
        }
    },
    created() {
        this.CourseRepositoryInstance = new CourseRepository(this)
        this.UploadToDigitalOceanRepositoryInstance = new UploadToDigitalOceanRepository(this)
        const firebase = auth.getFirebaseObject()
        this.firestorage = firebase.storage()
        this.$store.commit("liveData/set_selectedActivityName", this.prop_subject.subjectName);
        this.courseName = this.prop_subject.subjectName
        this.selectedSubject = this.prop_subject
        this.selectedSemester = this.prop_selectedSemester
        this.userData = this.$store.getters['user/get_userData']
        this.mediaLibraryInstance = new coursesData(this);
        this.fetchData()
    },

    computed: {
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        }
    },

    methods: {
        async uploadSolutionFile() {
            this.solutionFileLink = [];
            this.solutionFileLink = await this.uploadFile([this.solutionFile])
            this.solutionFileName = this.solutionFileLink[0].name
            console.log('this.solutionFileLink', this.solutionFileLink);
            this.uploadSolutionDialog = false
        },

        async onChangeOfImg() {
            this.loaderForImg = true
            let uploadLink = [];
            uploadLink = await this.uploadFile([this.editImageSolution])
            this.imgForSolutionEdit = uploadLink
            this.loaderForImg = false
        },

        async uploadFile(fileArr) {
            console.log('fileArr', fileArr);
            try {
                if (fileArr.length > 0) {
                    const data = await this.UploadToDigitalOceanRepositoryInstance.upload(fileArr, this.userData.uId)
                    console.log('data', data);
                    return data
                }
            } catch (error) {
                console.log('error')
                const arr = []
                return arr
            }
        },
        async fetchData() {
            const objToPush = {
                "instituteId": this.selectedSubject.instituteId,
                "semId": this.selectedSubject.semId,
                "department": this.selectedSubject.department,
                "courseYear": this.selectedSubject.courseYear,
                "subjectId": this.selectedSubject.subjectId,
                "courseName": this.selectedSubject.subjectName,
                "courseImage": this.courseImage,
            }
            let courseDetails = await this.CourseRepositoryInstance.getSingleCourse(objToPush)
            this.subsectionInfo = courseDetails
            this.courseDetails = courseDetails[0]
            this.courseCertification = this.courseDetails.courseCertification
            this.courseFeedback = this.courseDetails.courseFeedback
            this.jumpBetweenSection = this.courseDetails.jumpBetweenSection
            this.jumpBetweenSubsection = this.courseDetails.jumpBetweenSubsection
            this.videoTimeline = this.courseDetails.videoTimeline
            if (this.courseDetails.videoTimeline === null) {
                this.videoTimeline = false
            }
            this.audioTimeline = this.courseDetails.audioTimeline
            this.pdfDownload = this.courseDetails.pdfDownload
            this.courseImage = this.courseDetails.courseImage
            this.courseDescription = this.courseDetails.courseDescription
            this.isCoursePublished = this.courseDetails.isCoursePublished
            this.sections = this.courseDetails.sections
            this.file = this.courseDetails.courseImage ? { name: this.courseDetails.courseImage.split('________')[1].split('?')[0] } : {}

            this.updatedOn = moment(this.courseDetails.updatedAt).format(' DD/MM/YYYY ');
        },
        async updateCourseInfo() {
            const objToPush = {
                "instituteId": this.selectedSubject.instituteId,
                "semId": this.selectedSubject.semId,
                "department": this.selectedSubject.department,
                "courseYear": this.selectedSubject.courseYear,
                "subjectId": this.selectedSubject.subjectId,
                "courseName": this.selectedSubject.subjectName,
                "courseDescription": this.courseDescription,
                "courseImage": this.courseImage,
                "videoTimeline": this.videoTimeline,
                "audioTimeline": this.audioTimeline,
                "pdfDownload": this.pdfDownload,
                "courseCertification": this.courseCertification,
                "courseFeedback": this.courseFeedback,
                "jumpBetweenSection": this.jumpBetweenSection,
                "isCoursePublished": this.isCoursePublished,
                "jumpBetweenSubsection": this.jumpBetweenSubsection,
            }
            let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
            this.fetchData()
            showStatus('Courses Updated Successfully!', 5000, 'success', this)
            this.settingsDialog = false
        },
        handleQuestions() {
            console.log('this.selectedQuestions', this.selectedQuestions);
        },
        async addSection() {
            if (this.sectionTitle === '') {
                alert('Please fill the Section title')
            } else {
                let isSectionPresent = false
                this.sections.map((obj) => {
                    if (obj.sectionTitle === this.sectionTitle) {
                        isSectionPresent = true
                    }
                })
                if (isSectionPresent === true) {
                    alert('This section is already present try with Diff. "Section Name"')
                    return
                } else {
                    const sectionsObj = {
                        "sectionTitle": this.sectionTitle,
                        "sectionDescription": this.sectionDescription
                    }
                    this.sections.push(sectionsObj)
                    const objToPush = {
                        "instituteId": this.selectedSubject.instituteId,
                        "semId": this.selectedSubject.semId,
                        "department": this.selectedSubject.department,
                        "courseYear": this.selectedSubject.courseYear,
                        "subjectId": this.selectedSubject.subjectId,
                        "courseName": this.selectedSubject.subjectName,
                        "sections": this.sections,
                        "isSectionPublished": false
                    }
                    let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
                    this.fetchData()
                    this.$fire({
                        text: "Section Added Successfully",
                        type: "success",
                    })
                    this.addSectionDialogue = false
                }
            }
        },
        async updateSection() {
            if (this.sectionTitle === '') {
                alert('Please fill the Section title')
            } else {
                this.sections[this.selectedSectionIndex].sectionTitle = this.sectionTitle
                this.sections[this.selectedSectionIndex].sectionDescription = this.sectionDescription
                const objToPush = {
                    "instituteId": this.selectedSubject.instituteId,
                    "semId": this.selectedSubject.semId,
                    "department": this.selectedSubject.department,
                    "courseYear": this.selectedSubject.courseYear,
                    "subjectId": this.selectedSubject.subjectId,
                    "courseName": this.selectedSubject.subjectName,
                    "sections": this.sections,
                    "isSectionPublished": this.isSectionPublished
                }
                let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
                this.fetchData()
                this.$fire({
                    text: "Section Updated Successfully",
                    type: "success",
                })
                this.addSectionDialogue = false
            }
        },
        startUpdatingSection(addingNewSection, section) {
            this.subsectionUploadFile = []
            this.selectedSection = section
            this.sectionTitle = this.selectedSection.sectionTitle
            this.subSectionId = this.selectedSection.subSectionId
            this.sectionDescription = this.selectedSection.sectionDescription
            this.selectedSectionIndex = this.sections.indexOf(this.selectedSection)
            this.addingNewSection = addingNewSection
            this.addsubSectionDialogue = false
            this.addSectionDialogue = true
            this.selectedSubSections = this.selectedSection.subSections
        },
        startAddingSection(addingNewSection) {
            this.selectedSection = {}
            this.addsubSectionDialogue = false
            this.sectionTitle = ''
            this.sectionDescription = ''
            this.subsectionUploadFile = []
            this.addingNewSection = addingNewSection
            this.addSectionDialogue = true
        },
        async deleteSection() {
            this.$confirm(`Delete ${this.selectedSection.sectionTitle}?`).then(async () => {
                this.sections.splice(this.selectedSectionIndex, 1)
                const objToPush = {
                    "instituteId": this.selectedSubject.instituteId,
                    "semId": this.selectedSubject.semId,
                    "department": this.selectedSubject.department,
                    "courseYear": this.selectedSubject.courseYear,
                    "subjectId": this.selectedSubject.subjectId,
                    "courseName": this.selectedSubject.subjectName,
                    "sections": this.sections
                }
                try {
                    let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
                    this.sectionTitle = ''
                    this.sectionDescription = ''
                    this.addSectionDialogue = false
                    this.fetchData()
                } catch (e) {
                    this.addSectionDialogue = false
                    console.log('e', e);
                }
            });
        },

        async addsubSection() {
            if (this.subsectionTitle === '') {
                alert('Please fill the subsection title')
                return
            }
            else if (this.contentType === 'Quiz' && this.durationOfquiz === '') {
                alert('Please enter the quiz time')
                return
            }
            else if (this.contentType === 'Quiz' && this.passingPercentage === '') {
                alert('Please fill passing percentage field')
                return
            } else {
                const subSectionsObj = {
                    "subSectionId": uuidv4(),
                    "contentType": this.contentType,
                    "subsectionTitle": this.subsectionTitle,
                    "subsectionCredit": this.subsectionCredit,
                    "subsectionDescription": this.subsectionDescription,
                    "subSectionFileUrl": this.subSectionFileUrl,
                    "allowedFileTypeStudents": this.allowedFileTypeStudents,
                    "durationOfquiz": this.durationOfquiz,
                    "creditOfquiz": this.creditOfquiz,
                    "passingPercentage": this.passingPercentage,
                    "isreAttemptAllowed": this.isreAttemptAllowed === 'Yes' ? true : false,
                    "questions": this.questions,
                    "feedbackArr": this.feedbackArr,
                    "isSubsectionPublished": false,
                    "hyperlink": this.hyperlink,
                    solutionFile: this.solutionFileLink
                }
                if (this.selectedSection.subSections) {
                    this.selectedSection.subSections.push(subSectionsObj)
                } else {
                    this.selectedSection.subSections = []
                    this.selectedSection.subSections.push(subSectionsObj)
                }
                this.sections[this.selectedSectionIndex] = this.selectedSection
                const objToPush = {
                    "instituteId": this.selectedSubject.instituteId,
                    "semId": this.selectedSubject.semId,
                    "department": this.selectedSubject.department,
                    "courseYear": this.selectedSubject.courseYear,
                    "subjectId": this.selectedSubject.subjectId,
                    "courseName": this.selectedSubject.subjectName,
                    "sections": this.sections
                }
                this.subsectionInfo = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
                await this.fetchData()
                this.$fire({
                    text: "Sub Section Added Successfully",
                    type: "success",
                })
                this.clearStates()
            }
        },
        clearStates() {
            this.allowedFileTypeStudents = []
            this.progressUpload = 0
            this.selectedMedia = {}
            this.addsubSectionDialogue = false
            this.subSectionFileUploadProgressBar = false
            this.subSectionFileUrl = ''
            this.quizQuestionExcelFile = []
            this.gotFileUrl = false
            this.selectedQuestions = []
            this.hyperlink = ''
        },
        async updatesubSection() {
            if (this.subsectionTitle === '') {
                alert('Please fill the subsection title')
                return
            }
            else if (this.contentType === 'Quiz' && this.durationOfquiz === '') {
                alert('Please fill the quiz time field')
                return
            }
            else if (this.contentType === 'Quiz' && this.passingPercentage === '') {
                alert('Please fill passing percentage field')
                return
            } else {
                if (this.subsectionTitle) {
                    this.selectedsubSec["subsectionTitle"] = this.subsectionTitle
                }
                if (this.subsectionDescription) {
                    this.selectedsubSec["subsectionDescription"] = this.subsectionDescription
                }
                if (this.subsectionCredit) {
                    this.selectedsubSec["subsectionCredit"] = this.subsectionCredit
                }
                if (this.subSectionFileUrl) {
                    this.selectedsubSec["subSectionFileUrl"] = this.subSectionFileUrl
                }
                if (this.durationOfquiz) {
                    this.selectedsubSec["durationOfquiz"] = this.durationOfquiz
                }
                if (this.creditOfquiz) {
                    this.selectedsubSec["creditOfquiz"] = this.creditOfquiz
                }
                if (this.allowedFileTypeStudents) {
                    this.selectedsubSec["allowedFileTypeStudents"] = this.allowedFileTypeStudents
                }
                if (this.passingPercentage) {
                    this.selectedsubSec["passingPercentage"] = this.passingPercentage
                }
                if (this.questions) {
                    this.selectedsubSec["questions"] = this.questions
                }
                if (this.isreAttemptAllowed) {
                    this.selectedsubSec["isreAttemptAllowed"] = this.isreAttemptAllowed === 'Yes' ? true : false
                }
                if (this.hyperlink) {
                    this.selectedsubSec["hyperlink"] = this.hyperlink
                }
                if (this.solutionFileLink.length !== 0) {
                    this.selectedsubSec["solutionFile"] = this.solutionFileLink
                } else {
                    this.selectedsubSec["solutionFile"] = []
                }


                this.sections[this.selectedSectionIndex].subSections[this.selectedsubSecIndex] = this.selectedsubSec
                const objToPush = {
                    "instituteId": this.selectedSubject.instituteId,
                    "semId": this.selectedSubject.semId,
                    "department": this.selectedSubject.department,
                    "courseYear": this.selectedSubject.courseYear,
                    "subjectId": this.selectedSubject.subjectId,
                    "courseName": this.selectedSubject.subjectName,
                    "sections": this.sections
                }
                let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
                this.fetchData()
                this.$fire({
                    text: "Sub Section Updated Successfully",
                    type: "success",
                })
            }
            this.clearStates()
        },

        startAddingsubSection(addingNewSubsection, section) {
            this.selectedSection = section
            this.addSectionDialogue = false
            this.updateSubsection = false
            this.addingNewSubsection = addingNewSubsection
            this.addsubSectionDialogue = true
            this.contentType = ''
            this.subsectionTitle = ''
            this.subSectionFileUrl = ''
            this.subsectionCredit = ''
            this.subsectionDescription = ''
            this.subsectionUploadFile = []
            this.selectedQuestions = []
            this.durationOfquiz = ''
            this.creditOfquiz = ''
            this.allowedFileTypeStudents = []
            this.passingPercentage = ''
            this.hyperlink = ''
            this.questions = []
            this.progressUpload = 0
            this.selectedMedia = {}
            this.feedbackArr = []
            this.tempFeedbackQuestion = {}
            this.isreAttemptAllowed = this.isreAttemptAllowed === true ? 'Yes' : 'No'
        },

        startUpdatingSubsection(subSec, index) {
            this.subsectionUploadFile = []
            this.selectedsubSec = subSec
            this.updateSubsection = true
            this.addingNewSubsection = false
            this.selectedsubSecIndex = index
            // this.selectedsubSecIndex = this.selectedSubSections.indexOf(this.selectedsubSec)
            this.addsubSectionDialogue = true
            this.addSectionDialogue = false
            this.subsectionTitle = this.selectedsubSec.subsectionTitle
            this.contentType = this.selectedsubSec.contentType
            this.subsectionCredit = this.selectedsubSec.subsectionCredit
            this.subsectionDescription = this.selectedsubSec.subsectionDescription
            if (this.selectedsubSec.subSectionFileUrl) {
                try {
                    this.subsectionUploadFile.push({ name: this.selectedsubSec.subSectionFileUrl.split('________')[1].split('?')[0].replaceAll("%20", " ") })
                } catch (e) {
                    this.subsectionUploadFile.push({ name: this.selectedsubSec.subSectionFileUrl.split('/')[this.selectedsubSec.subSectionFileUrl.split('/').length - 1] })
                }
            }
            this.allowedFileTypeStudents = this.selectedsubSec.allowedFileTypeStudents
            this.durationOfquiz = this.selectedsubSec.durationOfquiz
            this.creditOfquiz = this.selectedsubSec.creditOfquiz
            this.passingPercentage = this.selectedsubSec.passingPercentage
            this.hyperlink = this.selectedsubSec.hyperlink
            console.log('this.hyperlink', this.hyperlink);
            this.questions = this.selectedsubSec.questions
            this.isreAttemptAllowed = this.selectedsubSec.isreAttemptAllowed === true ? 'Yes' : 'No'
            this.selectedQuestions = []
            this.getMediaLibrary(this.contentType)
            if (this.contentType === 'Feedback') {
                this.feedbackArr = this.selectedsubSec.feedbackArr
                this.feedbackArr.map((obj) => {
                    this.tempFeedbackQuestion = obj
                    this.feedbackOptions = obj.feedbackOptions
                })
            }
        },
        async deleteSubsection() {
            this.$confirm(`Delete ${this.selectedsubSec.subsectionTitle}?`).then(async () => {
                this.sections[this.selectedSectionIndex].subSections.splice(this.selectedsubSecIndex, 1)
                const objToPush = {
                    "instituteId": this.selectedSubject.instituteId,
                    "semId": this.selectedSubject.semId,
                    "department": this.selectedSubject.department,
                    "courseYear": this.selectedSubject.courseYear,
                    "subjectId": this.selectedSubject.subjectId,
                    "courseName": this.selectedSubject.subjectName,
                    "sections": this.sections
                }
                try {
                    let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
                    this.contentType = ''
                    this.subsectionTitle = ''
                    this.subsectionCredit = ''
                    this.subsectionDescription = ''
                    this.subsectionUploadFile = []
                    this.addsubSectionDialogue = false
                    this.fetchData()
                } catch (e) {
                    this.addSectionDialogue = false
                    console.log('e', e);
                }
            });
        },

        startUpdatingQuestions(question, index) {
            this.selectedquestion = question
            this.editImageSolution = question.imageForSolution
            this.selectedquestionIndex = index
            this.updateQuestionCard = true
        },
        async updateQuestion() {
            this.selectedquestion.imageForSolution = this.imgForSolutionEdit

            const objToPush = {
                "instituteId": this.selectedSubject.instituteId,
                "semId": this.selectedSubject.semId,
                "department": this.selectedSubject.department,
                "courseYear": this.selectedSubject.courseYear,
                "subjectId": this.selectedSubject.subjectId,
                "courseName": this.selectedSubject.subjectName,
                "sections": this.sections,
                "Question": this.selectedquestion.question,
            }
            this.selectedquestion
            this.updateQuestionCard = false

            let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
            this.fetchData()
        },
        subSectionContentType() {
            if (this.contentType === 'Assignment') {
                this.subSectionContentTypeSelection = ".mp3,.mp4, .mkv, .3gp,.jpg, .jpeg,.pdf,.doc,.docx"
            }
            if (this.contentType === 'Audio') {
                this.subSectionContentTypeSelection = ".mp3"
            }
            if (this.contentType === 'Video') {
                this.subSectionContentTypeSelection = ".mp4, .mkv, .3gp"
            }
            if (this.contentType === 'Document') {
                this.subSectionContentTypeSelection = ".pdf"
            }
            this.getMediaLibrary(this.contentType)
        },

        async getMediaLibrary(contentType) {
            this.mediaLibrary = []
            const objToPush = {
                "instituteId": this.selectedSubject.instituteId,
                "uId": this.userData.uId
            }
            let result = await this.CourseRepositoryInstance.getMediaLibrary(objToPush)
            result.map((obj) => {
                obj.file = obj.title + " " + obj.type
                if (this.contentType === 'Assignment') {
                    this.mediaLibrary.push(obj)
                }
                if (this.contentType === 'Audio') {
                    if (obj.type === "audio/mpeg") {
                        this.mediaLibrary.push(obj)
                    }
                }
                if (this.contentType === 'Video') {
                    if (obj.type === "video/mp4") {
                        this.mediaLibrary.push(obj)
                    }
                }
                if (this.contentType === 'Document') {
                    if (obj.type === "application/pdf") {
                        this.mediaLibrary.push(obj)
                    }
                }
            })
        },

        selectFromMediaLibrary() {
            this.subSectionFileUrl = this.selectedMedia.linksOfAttachments
            this.addSubSectionBtn = true
        },

        handleFileUpload() {
            this.uploadCount = 0
            if (this.uploadCount !== this.Certificate.length) {
                this.upload(this.uploadCount)
            }
        },
        handleuploadCourseImage() {
            this.uploadCount = 0
            this.uploadCourseImages(this.file)
        },
        async handleuploadSubsectionFile() {
            this.signedUrlsData = await this.getSignedUrl(this.subsectionUploadFile, `${this.userData.uId}/documents/`)
            const signedUrl = this.signedUrlsData
            const uploadPromises = [];
            await this.uploadToSpaces(this.subsectionUploadFile, signedUrl)
            const link = 'https://render.file-workers.workers.dev/courses-bucket/' + this.subsectionUploadFile.name;
            this.subSectionFileUrl = link
        },
        getSubsectionFileUrl() {
            this.subSectionFileUrl = spacesUrl + "/" + this.signedUrlsData[0].fileName
        },
        async getSignedUrl(file, path = "") {
            const fileName = file.name;
            const body = {
                fileName: fileName,
                fileType: file.type,
                instituteId: this.selectedSubject.instituteId
            };
            const signedUrl = await this.mediaLibraryInstance.getPresignedUrlForR2(body);
            return signedUrl.url;
        },
        async uploadToSpaces(file, signedUrl) {
            const res = await this.futch(
                signedUrl,
                {
                    method: "PUT",
                    body: file
                },
                event => {
                    const progress = parseInt((event.loaded / event.total) * 100);
                    setTimeout(() => {
                        this.currentUploadProgress = progress;
                        if (progress > 99) {
                            this.currentlyUploadingNumber++;
                        }
                    }, 200);
                }
            );
            if (this.currentUploadProgress === 100) {
                setTimeout(() => {
                    this.loading = false
                }, 1500)
            }
            console.log('this.currentUploadProgress', this.currentUploadProgress);
            return res;
        },
        futch(url, opts = {}, onProgress) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.open(opts.method || "get", url);
                for (var k in opts.headers || {}) {
                    xhr.setRequestHeader(k, opts.headers[k]);
                }
                xhr.onload = e => resolve(e.target.responseText);
                xhr.onerror = reject;
                if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
                xhr.send(opts.body);
            });
        },
        upload(fileNumber) {
            this.fileName = this.Certificate[fileNumber].name
            this.uploading = true
            this.uploadCertificate = firestorage.ref('certificate/' + new Date().valueOf().toString() + '________' + this.Certificate[fileNumber].name).put(this.Certificate[fileNumber])
        },
        uploadCourseImages(fileNumber) {
            this.fileName = this.file.name
            this.uploading = true
            this.uploadCourseImage = this.firestorage.ref('courseImages/' + new Date().valueOf().toString() + '________' + this.file.name).put(this.file)
        },
        goBack(subject) {
            this.$router.push({
                name: "activityCourses",
                params: {
                    prop_subject: subject,
                    prop_selectedDepartment: subject.department,
                    prop_selectedCourseYear: subject.courseYear,
                    prop_selectedSemester: this.selectedSemester
                }
            });
        },

        async onFileChanged() {
            this.isSelecting = true;
            var fileUpload = document.getElementById("fileUpload");
            var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
            if (regex.test(fileUpload.value.toLowerCase())) {
                if (typeof FileReader !== "undefined") {
                    var reader = new FileReader();
                    //For Browsers other than IE.
                    if (reader.readAsBinaryString) {
                        reader.onload = function (e) {
                            self.ProcessExcel(e.target.result);
                        };
                        reader.readAsBinaryString(fileUpload.files[0]);
                    } else {
                        //For IE Browser.
                        reader.onload = function (e) {
                            var data = "";
                            var bytes = new Uint8Array(e.target.result);
                            for (var i = 0; i < bytes.byteLength; i++) {
                                data += String.fromCharCode(bytes[i]);
                            }
                            self.ProcessExcel(data);
                        };
                        reader.readAsArrayBuffer(fileUpload.files[0]);
                    }
                } else {
                    alert("This browser does not support HTML5.");
                }
            } else {
                alert("Please upload a valid Excel file.");
            }
            this.isSelecting = false;
        },
        onButtonClick() {
            window.addEventListener(
                "focus",
                () => {
                },
                { once: true }
            );

            this.$refs.uploader.click();
        },

        addQuestionStep1() {
            this.tempQuestion = {
                questionNumber: this.questions.length + 1,
                question: "",
                type: "mcq",
                marks: 0,
                negativeMarks: 0,
                options: [],
                answer: "",
                solution: "",
                imageForSolution: []
            };
            this.addingQuestion = true;
        },
        addtempFeedbackQuestion() {
            this.tempFeedbackQuestion = {
                questionNumber: this.feedbackArr.length + 1,
                feedbackQuestion: "",
                feedbackType: "",
                feedbackOptions: [],
            };
        },
        async saveQuestion() {
            let uploadLink = [];
            if (this.tempQuestion.imageForSolution.length !== 0) {
                uploadLink = await this.uploadFile([this.tempQuestion.imageForSolution])
                this.tempQuestion.imageForSolution = uploadLink
            }

            this.tempQuestion.options.map((option) => {
                option.toString()
            })
            await this.questions.push(this.tempQuestion);
            this.addingQuestion = false;
        },
        saveFeedbackQuestion() {
            this.feedbackArr.push(this.tempFeedbackQuestion);
            this.dialog = false;
        },
        startUpdatingFeedbackQuestions(obj, index) {
            this.dialog = true
            this.tempFeedbackQuestion.feedbackQuestion = obj.feedbackQuestion
            this.tempFeedbackQuestion.feedbackType = obj.feedbackType
            if (obj.feedbackType === 'Multiple Choice') {
                this.tempFeedbackQuestion.feedbackOptions = obj.feedbackOptions
            }
        },

        addQuestionsManually() {
            this.addQuestions = true
        },
        uploadQuestionExcel() {
            this.uploadQuestionExcelDialog = true
            // this.quizQuestionExcelFile = []
        },
        async startUploadingQuestionExcel() {
            if (this.quizQuestionExcelFile) {
                var formData = new FormData()
                formData.append('excel', this.quizQuestionExcelFile)
                let url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/courses/parse'
                const response = await axios
                    .post(
                        process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/courses/parse',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    )
                this.questions = response.data.questions
                this.addQuestionsManually()
            }
            this.uploadQuestionExcelDialog = false
        },
        minusMarks() {
            this.negativeMarking = true
        },
        addsection: function () {
            this.sections.push({ title: " " + id, id: id++ });
        },
        replacesection: function () {
            this.sections = [{ title: " ", id: id++ }];
        },
        checkMovesection: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        async draggingEnd() {
            this.dragging = false
            const objToPush = {
                "instituteId": this.selectedSubject.instituteId,
                "semId": this.selectedSubject.semId,
                "department": this.selectedSubject.department,
                "courseYear": this.selectedSubject.courseYear,
                "subjectId": this.selectedSubject.subjectId,
                "courseName": this.selectedSubject.subjectName,
                "sections": this.sections
            }
            let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
            this.fetchData()
        },


        addSubection: function () {
            this.subSections.push({ subSec: " " + id, id: id++ });
        },
        replaceSubsection: function () {
            this.subSections = [{ subSec: " ", id: id++ }];
        },
        checkMovesubSections: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
        },

        async draggingEndsubSections() {
            this.draggingsubSection = false
            const objToPush = {
                "instituteId": this.selectedSubject.instituteId,
                "semId": this.selectedSubject.semId,
                "department": this.selectedSubject.department,
                "courseYear": this.selectedSubject.courseYear,
                "subjectId": this.selectedSubject.subjectId,
                "courseName": this.selectedSubject.subjectName,
                "sections": this.sections
            }
            let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
            this.fetchData()
        },
        toggleActiveIndex(subSec, i) {
            this.activeIndex = i;
            this.activeList = subSec;
        },
        handleSelecteAll() {
            if (this.selectAll === true) {
                this.selectedQuestions = this.questions
            } else {
                this.selectedQuestions = []
            }
        },
        deleteQuizQuestion() {
            this.$confirm("You really want to delete").then(() => {
                this.selectedQuestions.map((obj) => {
                    this.questions.map((selectedquestion) => {
                        if (obj.question === selectedquestion.question) {
                            this.tempDelQue = selectedquestion.question
                            this.questions = this.questions.filter((el) => !this.selectedQuestions.includes(el));
                        }
                    })
                })
                this.selectedQuestions = []
                this.selectAll = false
            })
        },
        deleteCourseImage() {
            this.$confirm("You really want to delete the course image?").then(() => {
                this.courseDetails.courseImage = ''
                this.courseImage = ''
                this.updateCourseInfo()
            })
        },
        async publishCourse() {
            this.$confirm("You want to publish this course ?").then(async () => {
                this.subsectionInfo[0].sections.map((section) => {
                    section.isSectionPublished = true
                    section.subSections.map((subSection) => {
                        subSection.isSubsectionPublished = true
                    })
                })
                let objToPush = {
                    "instituteId": this.selectedSubject.instituteId,
                    "semId": this.selectedSubject.semId,
                    "department": this.selectedSubject.department,
                    "courseYear": this.selectedSubject.courseYear,
                    "subjectId": this.selectedSubject.subjectId,
                    "courseName": this.selectedSubject.subjectName,
                    "isCoursePublished": true,
                    "sections": this.subsectionInfo[0].sections
                }
                let result = await this.CourseRepositoryInstance.updateCourseInfo(objToPush)
            })

        }
    }
}

</script>
<style src="./activitySubjectCourses.css" scoped></style>
