<template src='./activityEditSemesterShifts.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import ShiftReferenceRepository from '../../Repository/ShiftReference'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityEditSemesterShifts',
  props: [
    'PropsSelectedSemester',
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  components: {
    inputContainer
  },
  data() {
    return {
      loading: false,
      selectedInstitute: '',
      selectedSemester: {},
      items: [],
      selection: [],
      selectionType: 'leaf',
      tempShift: '',
      tableItems: [],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Shift',
          value: 'shiftName'
        }
      ],
      shiftIDToShiftNameMapping: {},
      shiftNamesList: [],
      shiftNameToShiftIdMapping: []
    }
  },

  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Semester Shifts')
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.PropsSelectedSemester
    console.log('Created', this.selectedSemester)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.ShiftReferenceRepositoryInstance = new ShiftReferenceRepository(this)
    this.items = this.tree
    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.items
        .filter((item) => item.name === this.prop_selectedDepartment)
        .forEach((department) => {
          department.children
            .filter((child) => child.name === this.prop_selectedCourseYear)
            .forEach((courseYear) => {
              courseYear.children
                .filter((child) => child.name === this.prop_selectedDivision)
                .forEach((division) => {
                  this.selection.push(division)
                })
            })
        })
    }
    this.loading = false
    this.fetchShifts()
  },
  methods: {
    async fetchShifts() {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        const response = await this.ShiftReferenceRepositoryInstance.getShiftNames(objToPush)
        console.log('response', response)
        for (let i = 0; i < response.length; i++) {
          this.shiftNamesList.push(response[i].shiftName)
          this.shiftIDToShiftNameMapping[response[i].shiftId] = response[i].shiftName
          this.shiftNameToShiftIdMapping[response[i].shiftName] = response[i].shiftId
        }
        this.fetchDetails()
      } catch (err) {
        console.log(err)
        showStatus('Could not connect to server!', 1500, 'error', this)
        this.items = []
        this.loading = false
      }
    },
    async fetchDetails() {
      this.loading = true
      console.log(this.selectedSemester)
      this.tableItems = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      for (let i = 0; i < this.selection.length; i++) {
        try {
          const tableItem = {
            department: this.selection[i].department,
            courseYear: this.selection[i].courseYear,
            division: this.selection[i].name,
            shiftName: ''
          }
          objToPush.department = tableItem.department
          objToPush.courseYear = tableItem.courseYear
          objToPush.division = tableItem.division
          this.tableItems.push(tableItem)

          const shiftId = await this.semesterRepositoryInstance.getShiftOfADivisionOfASemester(objToPush)
          console.log('shiftId', shiftId)
          tableItem.shiftName = this.shiftIDToShiftNameMapping[shiftId]
        } catch (err) {
          showStatus('Could not connect to server!', 1500, 'error', this)
          console.log(err)
        }
      }
      this.loading = false
      // console.log(this.tableItems)
    },
    async save() {
      this.loading = true
      console.log(this.tempShift)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        semName: this.selectedSemester.semName,
        shiftId: this.shiftNameToShiftIdMapping[this.tempShift]
      }

      for (let i = 0; i < this.selection.length; i++) {
        try {
          objToPush.department = this.selection[i].department
          objToPush.courseYear = this.selection[i].courseYear
          objToPush.division = this.selection[i].name
          const response = await this.semesterRepositoryInstance.updateShiftOfADivisionInASemester(objToPush)
          if (response) {
            this.selection[i].shiftName = this.shiftIDToShiftNameMapping[response.shiftId]
          }
          const indexOfItem = this.tableItems.findIndex((item) => {
            return item.department === this.selection[i].department &&
              item.courseYear === this.selection[i].courseYear &&
              item.division === this.selection[i].name
          })

          if (indexOfItem > -1) {
            this.tableItems[indexOfItem].shiftName = this.selection[i].shiftName
          }
          showStatus('Changes saved successfully!', 500, 'success', this)
        } catch (err) {
          console.log(err)
          showStatus('Cannot connect to server!', 1500, 'error', this)
        }
      }
      this.loading = false
    },
    gotoSemestersManagment() {
      this.$router.push({
        name:'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
  },
  destroyed() {
    console.log('Destroyed')
  }
}
</script>
<style src='./activityEditSemesterShifts.css' scoped>
</style>
