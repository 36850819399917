<template src="./activityCourseMaterial.html"></template>

<script>
import SubjectsRepository from "../../../Repository/Subject";
import CourseRepository from "../../../Repository/courses";
import UserAuthenticationRepository from "../../../Repository/User";
// import UserRepository from "../../../Repository/User";
// import firebase from "firebase";
// import auth from "../../Services/Firebase/auth";
export default {
  name: "activityCourseMaterial",
  props: ["prop_subject", "prop_user","prop_instituteId",'prop_selectedSemester','prop_department','prop_courseYear','prop_subjectId','prop_courseName'],
  data() {
    return {
      userAuthData: [],
      selected: [],
      totalCreditsRecieved: 0,
      progressPercentage: 0,
      subsectionCompletedCount: 0,
      totalCredit: 0,
      courseDetails: {},
      subsectinsCount: 0,
      progressPercentage: 0,
      subsectionCompletedCount: 0,
      user: {}
    }
  },
  created() {
    this.user = this.prop_user
    this.selectedSubject = this.prop_subject
    this.courseName = this.prop_courseName
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.CourseRepositoryInstance = new CourseRepository(this);
    this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this)

    this.userData = this.$store.getters["user/get_userData"];

    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true
            try {
                const objToPush = {
                    instituteId: this.prop_instituteId,
                    semId: this.prop_selectedSemester.semId,
                    department: this.prop_department,
                    courseYear: this.prop_courseYear,
                    subjectId: this.prop_subjectId,
                    courseName: this.prop_courseName,
                    uId: this.user.uId
                }
                const { data } = await this.CourseRepositoryInstance.getSyncCoursesDataOfSingleStudent(objToPush);
                if (data.isCourseFound) {
                    this.courseDetails = data.courseDetails;
                    this.sectionCompletedCount = data.sectionCompletedCount;
                    this.totalCredit = data.totalCredit;
                    this.totalCreditsRecieved = data.totalCreditsRecieved;
                    this.subsectionCompletedCount = data.subsectionCompletedCount;
                    this.progressPercentage = data.progressPercentage;
                    this.studentCourse = data.studentCourse;
                    this.subsectinsCount = data.subsectinsCount;
                    this.isCourseCompleted = this.sectionCompletedCount === this.courseDetails.sections.length
                    this.editCourseSubsections = JSON.parse(JSON.stringify(this.courseDetails));

                    let tempCourseCompleted = true;
                    for (let i = 0; i < this.courseDetails.sections.length; i++) {
                        if (!this.courseDetails.sections[i].isSectionCompleted) {
                            tempCourseCompleted = false;
                            break;
                        }
                    }
                    this.isCourseCompleted = tempCourseCompleted
                }
            } catch (err) {
                console.log(err);
                this.showContentNotFoundModal = true
            } finally {
                this.isLoading = false
            }
    },
    goBack() {
      this.$router.push({
        name: 'activityCourseAnalysisRevamped',
        params: {
          prop_subject: this.prop_subject,
          prop_selectedSemester: this.prop_selectedSemester,
          prop_selectedDepartment: this.prop_department,
          prop_selectedCourseYear: this.prop_courseYear
        }
      })
    },
  }
}

</script>

<style scoped src="./activityCourseMaterial.css"></style>
