import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/StudyMaterial";

class StudyMaterialRepository {
  constructor(context) {
    this.context = context;
  }

  async createStudyMaterial(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createStudyMaterial,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getStudyMaterialOfFaculty(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getStudyMaterial,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async editStudyMaterialOfFaculty(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.editStudyMaterial,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async deleteStudyMaterialOfFaculty(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.deleteRequest(
      ApiNames.deleteStudyMaterial,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default StudyMaterialRepository;
