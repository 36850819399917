<template src="./activityMediaLibrary.html"></template>

<script>
import mediaLibrary from "../../Repository/mediaLibrary";

export default {
  name: "activityMediaLibrary",
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.uploadEnd = true;
            this.downloadURLs.push(downloadURL);
            this.uploadCount++;
            if (this.uploadCount === this.files.length) {
              this.createAssignment();
            } else {
              this.progressUpload = 0;
              this.upload(this.uploadCount);
            }
          });
        }
      );
    },
  },
  data() {
    return {
      loadTable: true,
      loader: null,
      uploadTask: "",
      loading: false,
      checkbox: true,
      OpenDialogBox: false,
      openUpdateDialogueBox: false,
      showRecording: false,
      files: [],
      tempTitle: "",
      attachFile: [],
      data: [],
      tempDescription: "",
      tempLink: "",
      currentUploadProgress: 0,
      search: '',
      AttatchFile: "",
      uploadCount: 0,
      downloadURLs: [],
      selectedItem: {},
      filesAccepted: [],
      userData: {},
      items: [
        { title: "New Video", icon: "mdi-play-box-multiple" },
        { title: "New PDF", icon: "mdi-file-pdf-box" },
        { title: "New Image", icon: "mdi-image" },
        { title: "New Excel", icon: "mdi-file-excel" },
        { title: "New Doc", icon: "mdi-file-document" },
        { title: "New Audio", icon: "mdi-multimedia" },
      ],
      // options: [
      //   "Newest to Oldest",
      //   "Oldest to Newest",
      //   "Videos Only",
      //   "PDF's Only",
      //   "Images Only",
      // ],
      headers: [
        {
          text: 'Title',
          align: 'left',
          // sortable: true,
          value: 'fileName',
          // filterable: true,
          class: "DataTable"
        },
        {
          text: 'Description',
          align: 'left',
          // sortable: true,
          value: 'description',
          // filterable: true
        },
        {
          text: 'Upload Date',
          align: 'left',
          // sortable: true,
          value: 'date',
          // filterable: true
        },
        {
          text: 'File Type',
          align: 'left',
          // sortable: true,
          value: 'type',
          // filterable: true
        },
        {
          text: 'File Size',
          align: 'left',
          // sortable: true,
          value: 'size',
          // filterable: true
        },
        {
          text: 'Action',
          align: 'left',
          // sortable: true,
          value: 'linksOfAttachments',
          // filterable: true
        }
      ],
    };
  },

  async created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Media Library')
    this.userData = this.$store.getters["user/get_userData"];
    this.mediaLibraryInstance = new mediaLibrary(this);
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.getData()
  },
  methods: {
    cancelCreating() {
      this.OpenDialogBox = false;
    },
    updateDialogueBox(item) {
      this.attachFile = []
      this.selectedItem = item
      this.attachFile[0] = { "name": this.selectedItem.linksOfAttachments.split('/')[this.selectedItem.linksOfAttachments.split('/').length - 1] }
      this.openUpdateDialogueBox = true
    },
    createDialogBox(item) {
      if (item.title === "New Video") {
        this.filesAccepted = ".mp4";
      } else if (item.title === "New PDF") {
        this.filesAccepted = ".pdf";
      } else if (item.title === "New Image") {
        this.filesAccepted = "image/*";
      } else if (item.title === "New Excel") {
        this.filesAccepted = ".xlsx";
      } else if (item.title === "New Doc") {
        this.filesAccepted = ".doc";
      } else if (item.title === "New Audio") {
        this.filesAccepted = ".mp3";
      }
      this.selectedItem = item;
      this.OpenDialogBox = true;
    },
    uploadFiles() {
      const l = this.loader
      this[l] = !this[l]
      this[l] = false
      this.loading = true;
      this.loader = this.loading
      this.downloadURLs = [];
      this.uploadCount = 0;
      console.log('this.attachFile', this.attachFile);
      if (this.attachFile.length !== 0) {
        this.startUploadingFiles();
      } else {
        alert("Select files first");
      }
      this.loader = null;
    },
    getFileFormat(item) {
      const type = item.linksOfAttachments.split('.')
      console.log('type', type);
      return type[type.length - 1].split('?')[0]

    },
    async startUploadingFiles() {
      console.log('this.attachFile', this.attachFile);
      if (this.selectedItem.title === "New Video") {
        const date = new Date()
        const url = await this.getPresignedUrlForR2(this.attachFile, `${date.getTime()}/mediaLibrary/`)
        await this.uploadVideoToSpaces(this.attachFile, url)
        const link = 'https://render.file-workers.workers.dev/courses-bucket/' + this.attachFile.name;
        this.uploadNewMediaToDb(link)
        this.tempTitle = '';
        this.tempDescription = '';
        this.attachFile = {};
        this.loading = false;
      } else {
        const date = new Date()
        const url = await this.getSignedUrl(this.attachFile, `${date.getTime()}/mediaLibrary/`)
        this.uploadNewMediaToDb(url)
      }
      this.getData();
    },
    async uploadVideoToSpaces(file, signedUrl) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file
        },
        event => {
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );
      console.log('this.currentUploadProgress', this.currentUploadProgress);
      return res;
    },
    async getPresignedUrlForR2(file, path = "") {
      const fileName = file.name;
      const body = {
        fileName: fileName,
        fileType: file.type,
      };
      const signedUrl = await this.mediaLibraryInstance.getPresignedUrlForR2(body);
      console.log('signedUrl', signedUrl);
      return signedUrl.url;
    },
    async uploadToSpaces(file, signedUrl) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            console.log(event);
            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );

      this.currentUploadProgress = 0;

      return res;
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    goToRecordingLink(link) {
      console.log('this.tempLink ', link);
      if (link) {
        this.tempLink = link
        window.open(link)
        this.showRecording = true
      }
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      }
      const signedUrl = await this.mediaLibraryInstance.getSignedUrl(
        body
      );
      console.log('signedUrl', signedUrl);
      await this.uploadToSpaces(file, signedUrl.signedUrl)
      return signedUrl.signedUrl.split('?')[0];
    },
    async uploadVideo(file) {
      const fileName = file.name;
      const body = {
        fileName,
        fileType: file.type
      }
      const signedUrl = await this.mediaLibraryInstance.getSignedUrl(
        body
      );
      await this.uploadToSpaces(file, signedUrl.url)
      console.log('signedUrl', signedUrl);
      const link = 'https://render.file-workers.workers.dev/courses-bucket/' + filename;
      return link
    },
    async uploadNewMediaToDb(url) {
      try {
        const objToPush = {
          title: this.tempTitle,
          description: this.tempDescription,
          linksOfAttachments: url,
          instituteId: this.selectedInstitute,
          size: this.attachFile.size,
          type: this.attachFile.type,
          uId: this.userData.uId
        };
        const response = await this.mediaLibraryInstance.uploadNewMediaToDb(objToPush);
        console.log("uploadNewMediaToDb", response);
        this.OpenDialogBox = false
        this.tempTitle = ''
        this.tempDescription = ''
        this.attachFile = []
        this.loading = false
        this.getData()
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async getData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          uId: this.userData.uId
        };
        const response = await this.mediaLibraryInstance.getMediaLibrary(objToPush);
        this.data = response.media
        this.loadTable = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async updateData() {
      try {
        console.log('updateData this.attachFile', this.attachFile);
        const l = this.loader
        this[l] = !this[l]
        this[l] = false
        this.loading = true;
        this.loader = this.loading
        let url = '';
        if (this.attachFile.type === 'video/mp4') {
          const tempUrl = await this.uploadVideo(this.attachFile);
          url = tempUrl.link;
        } else {
          url = await this.getSignedUrl(this.attachFile, `/mediaLibrary/${date.getTime()}`)
        }
        console.log('this.attachFile1', this.attachFile);
        const objToPush = {
          instituteId: this.selectedInstitute,
          uId: this.selectedItem.uId,
          mediaId: this.selectedItem.mediaId,
          title: this.selectedItem.title,
          description: this.selectedItem.description,
          linksOfAttachments: url,
          size: this.attachFile.size,
          type: this.attachFile.type,
        };

        console.log('objToPush', objToPush);
        const response = await this.mediaLibraryInstance.updateMediaById(objToPush);
        console.log('updateMediaById', response);
        // this.data = response.media;
        this.tempTitle = ''
        this.tempDescription = ''
        this.loading = false;
        this.loader = null;
        this.attachFile = [];
        this.getData();
        this.openUpdateDialogueBox = false
      } catch (err) {
        console.log(err);
      }
    },
    async deleteData(item) {
      try {
        if (confirm("Do you really want to delete?")) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            uId: this.userData.uId,
            mediaId: item.mediaId
          }
          const res = await this.mediaLibraryInstance.deleteMediaById(objToPush)
          console.log('res', res);
          this.getData()
        }
      }
      catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    convertLinkToFilename(link) {
      console.log('link.length - 1', link.length - 1)
      let arr = link.split('/')
      return arr[arr.length - 1]
    },
  },
};
</script>

<style src="./activityMediaLibrary.css" scoped></style>