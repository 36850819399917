import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/courseFile'

class CourseFile {
  constructor (context) {
    this.context = context
  }

  async generateCourseFile (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.generateCourseFile, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default CourseFile
