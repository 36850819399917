<template src="./activityExamAccessControl.html"></template>
<script>
import UserAuthenticationRepository from "../../../Repository/UserAuthentication";
import { allowedModuleDetails } from "../../../Services/Utils/allowedModule";


export default {
    name: "activityExamAccessControl",
    data() {
        return {
            selectedInstituteId: "",
            userData: {},
            examAccessControlTableHeaders: [
                {
                    text: 'Sr. No.',
                    value: 'srNo'
                },
                {
                    text: 'Module Name',
                    value: 'moduleName'
                },
                {
                    text: 'Faculty Name(assigned to)',
                    value: 'facultyNames'
                },
            ],
            examAccessControlTableItems: [],
            examAccessControlTableItems1: [],
            selectedSemester: {},
            dataLoader: false,
            search: '',
            fullNameOfUser: "",
            isSubAdmin: false
        }
    },
    created() {
        this.$store.commit("liveData/set_selectedActivityName", "Exam Access Control");
        this.selectedInstituteId =
            this.$store.getters["instituteData/get_selectedInstitute"];
        this.userData = this.$store.getters["user/get_userData"];
        this.isSubAdmin = this.$store.getters["liveData/get_isSubAdmin"].isSubAdmin;
        this.fullNameOfUser = this.userData.fullName ?? ""
        this.selectedSemester = this.$store.getters["instituteData/get_selectedExamSemester"];

        this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this);

        this.getAccessControlDetails()
    },
    methods: {
        async getAccessControlDetails() {
            this.dataLoader = true
            try {
                const data = await this.UserAuthenticationRepositoryInstance.getAllSubAdminOfInstitute(
                    { instituteId: this.selectedInstituteId }
                )
                let examModules = allowedModuleDetails.filter(item => item.id === 5)[0].subModule
                examModules.map((item, i) => {
                    this.examAccessControlTableItems.push({ srNo: i + 1, moduleName: item.subName, facultyNames: [] })
                })
                data.map((user) => {
                    if (user.allowedModule) {
                        let objToPush = {}
                        objToPush.facultyNames = []
                        let examModule = user.allowedModule.filter(item => item.id === 5 && item.isAccess && item.subModule.length > 0)[0]
                        if (examModule) {
                            examModule.subModule.map((sm) => {
                                if (sm.isAccessForSub) {
                                    let fullName = `${user.firstName[0]} ${user.lastName[0]}`
                                    let uId = user.uId
                                    this.examAccessControlTableItems[sm.subId].facultyNames.push({ uId, fullName })
                                }
                            })
                        }
                    }
                })
            } catch (error) {
                console.error('error', error);
            }
            this.dataLoader = false
        },
        gotoExams() {
            this.$router.push("/activityExams");
        },
        gotoInstituteAccessControl() {
            this.$router.push("/activityControlAccess");
        },
    }
}
</script>
<style scoped src="./activityExamAccessControl.css"></style>