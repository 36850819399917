<template>
  <div class="text-center container">
    <div>
      <img v-if="getImage()" :src="getImage()" height="40px" />
    </div>
    <div class="mt-2 caption" style="font-size: 0.70rem !important">
      <span v-if="getFooterTitle()">&#169; {{ getFooterTitle() }}. All rights reserved.</span>
      <span v-else>&#169; All rights reserved.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortalFooter",
  methods: {
    getFooterTitle() {
      if (process.env.VUE_APP_FOOTER_TITLE) {
        return process.env.VUE_APP_FOOTER_TITLE
      } else return ""
    },
    getImage() {
      if (process.env.VUE_APP_FOOTER_LOGO) {
        return require(`../Assets/Images/${process.env.VUE_APP_FOOTER_LOGO}`);
      } else return ""
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 120px;
}
</style>
