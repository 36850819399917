import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/mail'

class EmailSender {
  constructor (context) {
    this.context = context
  }

  async sendWelcomeMail (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.postRequest(ApiNames.sendWelcomeMail, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}
export default EmailSender
