import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/TopicFeedbackQuestion'

class TopicFeedbackRepository {
  constructor (context) {
    this.context = context
  }

  async getTopicFeedbackQuestion (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getTopicFeedbackQuestion,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackQuestion
  }

  async createTopicFeedbackQuestion (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(
      ApiNames.createTopicFeedbackQuestion,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateTopicFeedbackQuestion (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(
      ApiNames.updateTopicFeedbackQuestion,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default TopicFeedbackRepository
