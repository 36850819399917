<template src="./activityQuickTest.html"></template>

<script>
import SemesterRepository from "../../Repository/Semester";
import SubjectRepository from "../../Repository/Subject";
import ExamRepository from "../../Repository/Exam";
import ExamStudent from "../../Repository/ExamSubject";
import apiV3 from "../../NetworkManager/apiV3";
import { addMinutes, isAfter, isWithinInterval } from 'date-fns'
export default {
  name: "activityQuickTest",
  data() {
    return {
      assignedCourseYearForSubAdmin: [],
      assignedDepartment: [],
      subjectAveragePerformance: 0,
      totalMarksObtainedSum: 0,
      totalOutOfSum: 0,
      selectedInstitute: "",
      series: [
        {
          name: "Average Performance",
          data: [],
        },
        {
          name: "Average Passing Rate",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#059292", "#032F63"],
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#000"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        noData: {
          text: "No Data",
          align: "center",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000",
            fontSize: "14px",
          },
        },
        xaxis: {
          labels: {
            rotate: 0,
            hideOverlappingLabels: false,
            trim: false,
            style: {
              cssClass: 'apexcharts-xaxis-label',
              fontSize: '12px',
            },
            formatter: function (val) {
              return val.length > 5 ? val.substring(0, 7) + '...' : val;
            }
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
      },
      semesterData: [],
      semesterName: [],
      departmentName: [],
      courseName: [],
      divisionName: [],
      semId: [],
      semester: {},
      department: "",
      course: "",
      division: "",
      subjectName: [],
      subjectNameList: [],
      groupNameList: [],
      subjectCount: 0,
      exams: [],
      allSubject: [],
      semesters: [],
      headers: [
        {
          text: "Subject Name",
          value: "subjectName",
        },
        { text: "Total Exams", value: "count" },
        { text: "Action", value: "subject" },
      ],
      headers2: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Date (dd/mm/yyyy, hh:MM:ss)",
          value: "dateTimeUTC",
        },
        {
          text: "Duration (minutes)",
          value: "durationInMins",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Analysis",
          value: "exam",
        },
      ],
      subjectIds: [],
      allExams: {},
      examCount: 0,
      allSubjectsData: [],
      allowedStudents: [],
      allowedStudents2: [],
      allowedStudentList: [],
      totalMarksObtainedCount: 0,
      totalMarksObtainedArray: [],
      totalMarksObtainedArray2: [],
      totalMarksObtained: "",
      totalMarksObtained2: "",
      totalMarksCount: 0,
      passStudentCount: 0,
      passStudentCount2: 0,
      totalStudentCount: 0,
      totalStudentCount2: 0,
      avgPerformance: 0,
      avgPerformance2: 0,
      avgPerformanceBySubject: "",
      avgPassingRate: 0,
      avgPassingRate2: 0,
      totalMarksArray: [],
      totalMarksArray2: [],
      totalMarks: "",
      totalMarks2: "",
      totalAllExam: [],
      allExamResult: [],
      subjectAvgArray: [],
      examWiseResultAvg: [],
      totalMarksObtainedSubject: [],
      totalMarksSubject: [],
      exams: [],
      types: ['Division','Group'],
      type: '',
      selectedGroup: {},
      subjectsList: [],
      selectedSubject: {},
      showChart: false,
      groupSubjects: [],
      examName2: [],
      AllExam: [],
      subjectId: '',
      examIds: [],
      singleExam: {},
      examCount2: 0,
      exams2: [],
      examResult: [],
      examResult2: [],
      isDeletingExam: false,
      selectedExam: null,
      isDeleteDialogOpen: false,
      dialogForCard: false,
      examDetailsObject: {}
    };
  },
  created() {
    this.$store.commit("liveData/set_selectedActivityName", "Quick Test");
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.subjectServiceInstance = new SubjectRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.examRepositoryInstance = new ExamRepository(this);
    this.examSubjectRepositoryInstance = new ExamStudent(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.getSemesters();
  },
  methods: {
    openDialog(data) {
      this.dialogForCard = true
      // console.log('data', data)
      this.examDetailsObject = {
        ...data
      }
      // this.dialogTitle = data.cardTitle
      // this.dialogInfo = data.cardDescription
    },
    formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    },
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        );
      } catch (err) {
        console.log(err);
      }
    },
    async selectedSemester() {
      this.type = ''
      // console.log("prop_examWiseResultAvg", this.examWiseResultAvg);
      this.examWiseResultAvg = [];
      this.departmentName = [];
      this.department = "";
      this.course = "";
      this.division = "";
      this.courseName = [];
      this.divisionName = [];
      this.subjectNameList = [];
      this.groupNameList = []
      this.allSubjectsData = [];
      this.series[0].data = [];
      this.series[1].data = [];
      this.subjectCount = 0;
      this.getDepartments(this.semester.semId);
    },
    async getDepartments(semId) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: semId,
        };
        const departmentData =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        Array.isArray(departmentData)
          ? departmentData.map((obj) => {
              this.departmentName.push(obj.department);
              this.courseName.push(obj.courseYear);
              this.divisionName.push(obj.division);
            })
          : null;
          let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment?.length > 0) {
        this.departmentName.map((data) => {
          if(this.assignedDepartment.includes(data)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departmentName = subAdminAssignedDepartmentWiseLecture
        }
        this.getSubjects(this.semester.semId);
      } catch (err) {
        console.log(err);
      }
    },
    deptChanged() {
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin?.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.department) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseName = allowedCourseYearForSelectedDepartment
      }

    },
    async getSubjects(semId) {
      try {
        this.subjectName = [];
        this.allSubject = [];
        this.allSubjectsData = [];

        const subjects =
          await this.subjectServiceInstance.getSubjectsOfAInstituteInASemester({
            instituteId: this.selectedInstitute,
            semId: semId,
          });
          this.allSubject = subjects;
        // console.log('this.allSubject',this.type, this.allSubject)
      } catch (err) {
        console.log(err);
      }
    },
    emptySeries(){
      this.selectedSubject = {}
      this.division = ''
      this.selectedSubject = ''
      this.series= [
        {
          name: "Average Performance",
          data: [],
        },
        {
          name: "Average Passing Rate",
          data: [],
        },
      ]
    },
    async mapSubject() {
      this.subjectCount = 0;
      this.examCount = 0;
      this.avgPerformance = 0
      this.avgPassingRate = 0
      // if(this.type === 'Division') this.division = ''
      // this.selectedSubject = {}
      this.subjectIds = [];
      this.subjectName = []
      this.selectedGroup = {}
      this.selectedSubject = ''
      this.subjectIds = []
      // console.log('this.allSubject', this.allSubject)
      if(this.type === 'Group'){
            this.groupSubjects = this.allSubject.filter(sub=> sub.groupForSubject?.length>0)
            this.groupSubjects.map((obj) => {
              if (this.department === obj.department) {
                if (this.course === obj.courseYear) {
                  this.subjectName.push(obj.subjectName);
                  this.subjectIds.push(obj.subjectId);
                }
              }
            });
      }else{
        this.allSubject.map((obj) => {
              if (this.department === obj.department) {
                if (this.course === obj.courseYear) {
                  this.subjectName.push(obj.subjectName);
                  this.subjectIds.push(obj.subjectId);
                }
              }
            });
      }
      if(this.type === 'Division'){
        // console.log('this.type1212', this.type);
        let promise = [];
        this.subjectIds.forEach(async (id) => {
          promise.push(this.getExams(this.semester.semId, id));
        });
        // console.log('this.subjectIds', this.subjectIds)
        await Promise.all(promise);
        await this.getAnalysisResult();
        await this.getSubjectAnalysis();
      }
      
    },
    async getSubjectAnalysis() {
      this.series= [
        {
          name: "Average Performance",
          data: [],
        },
        {
          name: "Average Passing Rate",
          data: [],
        },
      ]
      this.subjectNameList = [];
      this.groupNameList = [];
      this.totalAllExam.map((exam) => {
        // console.log('this.type2222', this.type);
        // console.log('exam12', exam)
        let studentResult = [];
        exam.avg = 0;
        exam.totalMarksObtained = 0;
        exam.totalMarks = 0;
        exam.passStudentCount = 0;
        exam.totalStudentCount = 0;
        exam.avgPassingRate = 0;
        this.allExamResult.map((result) => {
          if (exam._id === result.examId) {
            exam.totalStudentCount++;
            if (result.status === "pass") {
              exam.passStudentCount++;
              exam.totalMarksObtained += parseInt(result.totalMarksObtained);
              exam.totalMarks += parseInt(result.totalOutOf);
            }
            studentResult.push(result);
          }
        });
        let avg = 0;
        avg =
          (parseInt(exam.totalMarksObtained) / parseInt(exam.totalMarks)) * 100;
        exam.avg = isNaN(avg) ? 0 : Math.round(avg);
        exam.avgPassingRate =
          (parseInt(exam.passStudentCount) / parseInt(exam.totalStudentCount)) *
          100;
        this.examWiseResultAvg.push({
          performanceAverage: exam.avg,
          examId: exam._id,
          examName: exam.name,
          subjectId: exam.subjectId,
          passingRate: exam.avgPassingRate,
        });
        exam["studentResult"] = studentResult;
      });
      if(this.type === 'Group'){
        let subjectGroups = this.groupSubjects.filter(sub=>sub.subjectId === this.selectedSubject.subjectId)
        subjectGroups[0].groupForSubject.map((group) => {
          group.totalMarksObtained = 0;
          group.totalMarks = 0;
          group.passStudentCount = 0;
          group.totalStudentCount = 0;
          group.avg = 0;
          group.avgPassingRate = 0;
          let exams = [];
          this.totalAllExam.map((exam) => {
            // console.log('group.groupId', exam)
            if (group.groupId === exam.assignedGroupForSubject?.groupId) {
              group.totalMarksObtained += parseInt(exam.totalMarksObtained);
              group.totalMarks += parseInt(exam.totalMarks);
              group.passStudentCount += parseInt(exam.passStudentCount);
              group.totalStudentCount += parseInt(exam.totalStudentCount);
              exams.push(exam);
            }
          });
          // console.log('group.totalMarksObtained', group.avgPassingRate, group.avgPassingRate)
          let avg = 0;
          avg =
            (parseInt(group.totalMarksObtained) /
              parseInt(group.totalMarks)) *
            100;
          group.avgPassingRate =
            (parseInt(group.passStudentCount) /
              parseInt(group.totalStudentCount)) *
            100;
          
          this.series[0].data.push(isNaN(avg) ? 0 : Math.round(avg));
          this.series[1].data.push(
            isNaN(group.avgPassingRate) ? 0 : Math.round(group.avgPassingRate)
            );
            this.groupNameList.push(group.groupName);
            group.avg = isNaN(avg) ? 0 : Math.round(avg);
            group["exams"] = exams;
          });
          // console.log('this.groupNameList', this.groupNameList);
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: { categories: this.groupNameList },
          };
          this.subjectCount = subjectGroups[0].groupForSubject?.length;
      }else{
        this.series[0].data = [];
        this.series[1].data = [];
        this.subjectNameList = [];
        this.allSubject.map((subject) => {
          // console.log('subject12', subject)
          subject.totalMarksObtained = 0;
          subject.totalMarks = 0;
          subject.passStudentCount = 0;
          subject.totalStudentCount = 0;
          subject.avg = 0;
          subject.avgPassingRate = 0;
          let exams = [];
          this.totalAllExam.map((exam) => {
            if (subject.subjectId === exam.subjectId) {
              subject.totalMarksObtained += parseInt(exam.totalMarksObtained);
              subject.totalMarks += parseInt(exam.totalMarks);
              subject.passStudentCount += parseInt(exam.passStudentCount);
              subject.totalStudentCount += parseInt(exam.totalStudentCount);
              exams.push(exam);
            }
          });
          let avg = 0;
          avg =
            (parseInt(subject.totalMarksObtained) /
              parseInt(subject.totalMarks)) *
            100;
          subject.avgPassingRate =
            (parseInt(subject.passStudentCount) /
              parseInt(subject.totalStudentCount)) *
            100;
          
          this.series[0].data.push(isNaN(avg) ? 0 : Math.round(avg));
          // console.log('Math.round(avg)', Math.round(avg), Math.round(subject.avgPassingRate))
          this.series[1].data.push(
            isNaN(subject.avgPassingRate) ? 0 : Math.round(subject.avgPassingRate)
            );
        this.subjectNameList.push(subject.subjectName);

            subject.avg = isNaN(avg) ? 0 : Math.round(avg);
            subject["exams"] = exams;
          });
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: { categories: this.subjectNameList },
          };
          this.subjectCount = this.subjectName?.length;
      }
      this.showChart = true
      // console.log('this.allSubject111',this.allSubject)
    },
    async getExams(semId, subjectId) {
      this.allSubjectsData = [];
      try {
        this.examCount = 0;
        this.allowedStudentList = [];
        this.totalAllExam = [];
        const exams = await apiV3.getRequest("/exam/all", {
          category: "quick_exam",
          semId: semId,
          subjectId: subjectId,
        });
        // console.log('this.exams', exams)
        if (this.type === 'Group') {
          exams.exams = exams.exams.filter(exam => exam.subjectId === this.selectedSubject.subjectId);
          this.exams = exams.exams
        }else{
          this.exams = exams.exams;
        }
        Array.isArray(exams.exams)
        ? exams.exams.map((obj) => {
          this.totalAllExam.push(obj);
          this.allowedStudentList = obj.allowedStudents.concat(
            this.allowedStudentList
            );
          })
          : null;
        this.examCount += parseInt(exams.exams?.length);
        if(this.type==='Group'){
          Array.isArray(this.groupSubjects)
          ? this.groupSubjects.map((subj) => {
              if (subj.subjectId === subjectId) {
                this.allSubjectsData.push({
                  subjectId: subj.subjectId,
                  subjectName: subj.subjectName,
                  count: exams.exams?.length,
                });
              }
            })
          : null;
        }else{
          Array.isArray(this.allSubject)
          ? this.allSubject.map((subj) => {
              if (subj.subjectId === subjectId) {
                this.allSubjectsData.push({
                  subjectId: subj.subjectId,
                  subjectName: subj.subjectName,
                  count: exams.exams?.length,
                });
              }
            })
          : null;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getAnalysisResult() {
      // console.log('this.type1111', this.type);
      try {
        this.totalMarksObtainedArray = [];
        this.totalMarksArray = []
        this.totalMarksObtained = "";
        this.passStudentCount = 0;
        this.passStudentArray = [];
        this.passStudent = "";
        this.totalStudentCount = 0;
        const examResult = await apiV3.postRequest(
          "/exam/getResultsByAllowedStudents",
          {
            allowedStudents: this.allowedStudentList,
          }
        );
        // console.log('this.allowedStudentList', this.allowedStudentList)
        this.allExamResult = examResult.data;
        this.allExamResult = this.allExamResult.filter(result => {
          return this.totalAllExam.some(exam => exam._id === result.examId);
        });
        // console.log('this.allExamResult', this.allExamResult)
        Array.isArray(this.allExamResult)
          ? this.allExamResult.map((obj) => {
              this.totalStudentCount++;
              if (obj.status === "pass") {
                this.passStudentCount++;
                this.totalMarksObtainedArray.push(obj.totalMarksObtained);
                this.totalMarksArray.push(obj.totalOutOf);
              }
            })
          : null;
        this.totalMarksObtained = this.totalMarksObtainedArray.reduce(function (
          a,
          b
        ) {
          return a + b;
        },
        0);
        this.totalMarks = this.totalMarksArray.reduce(function (a, b) {
          return a + b;
        }, 0);
        const avg = ((this.totalMarksObtained / this.totalMarks) * 100).toFixed(
          0
        );
        if (isNaN(avg)) {
          this.avgPerformance = 0;
        } else {
          this.avgPerformance = avg;
        }
        const avgRate = (
          (this.passStudentCount / this.totalStudentCount) *
          100
        ).toFixed(0);
        if (isNaN(avgRate)) {
          this.avgPassingRate = 0;
        } else {
          this.avgPassingRate = avgRate;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getAnalysisResult2() {
      try {
        const examResult = await apiV3.postRequest(
          "/exam/getResultsByAllowedStudents",
          {
            allowedStudents: this.allowedStudents2,
          }
        );
        this.examResult2 = examResult.data;
        Array.isArray(examResult.data)
          ? examResult.data.map((obj) => {
              //all avg calculation
              if (this.examIds.includes(obj.examId)) {
                this.totalStudentCount2++;
                if (obj.status === "pass") {
                  this.passStudentCount2++;
                  this.totalMarksObtainedArray2.push(obj.totalMarksObtained);
                  this.totalMarksArray2.push(obj.totalOutOf);
                }
              }
            })
          : null;
        this.totalMarksObtained2 = this.totalMarksObtainedArray2.reduce(function (
          a,
          b
        ) {
          return a + b;
        },
        0);
        this.totalMarks2 = this.totalMarksArray2.reduce(function (a, b) {
          return a + b;
        }, 0);
        const avg = ((this.totalMarksObtained2 / this.totalMarks2) * 100).toFixed(
          0
        );
        if (isNaN(avg)) {
          this.avgPerformance2 = 0;
        } else {
          this.avgPerformance2 = avg;
        }
        const avgRate = (
          (this.passStudentCount2 / this.totalStudentCount2) *
          100
        ).toFixed(0);
        if (isNaN(avgRate)) {
          this.avgPassingRate2 = 0;
        } else {
          this.avgPassingRate2 = avgRate;
        }
        // this.avgCalculation();
      } catch (err) {
        console.log(err);
      }
    },
    async grpSelected(){
      this.selectedGroup = {}
      this.series= [
        {
          name: "Average Performance",
          data: [],
        },
        {
          name: "Average Passing Rate",
          data: [],
        },
      ]
      let promise = [];
      this.subjectIds.forEach(async (id) => {
        promise.push(this.getExams(this.semester.semId, id));
      });
      await Promise.all(promise);
      await this.getAnalysisResult();
      await this.getSubjectAnalysis();
    },
    gotoActivityQuickTestAveragePerformance(subjectId, subjectName) {
      this.$router.push({
        name: "activityQuickTestAveragePerformance",
        params: {
          prop_selectedInstitute: this.selectedInstitute,
          prop_subjectId: subjectId,
          prop_subjectName: subjectName,
          prop_selectedSemester: this.semester.semId,
          prop_department: this.department,
          prop_course: this.course,
          prop_division: this.division,
          prop_examWiseResultAvg: this.examWiseResultAvg,
        },
      });
    },
    async getExams2() {
      try {
        // this.isLoadingExams = true;
        this.examName2 = [];
        // console.log('this.semester.semId', this.semester.semId, this.selectedSubject.subjectId)
        const { exams = [] } = await apiV3.getRequest("/exam/all", {
          category: "quick_exam",
          semId: this.semester.semId,
          subjectId: this.selectedSubject.subjectId,
        });
        this.AllExam = exams;
        Array.isArray(exams)
          ? exams.map((obj) => {
              this.allowedStudents2 = obj.allowedStudents;
              this.subjectId = obj.subjectId;
              this.examIds.push(obj._id);
              this.examName2.push(obj.name);
              this.singleExam = obj;
            })
          : null;
        this.examCount2 = this.examName2?.length;
        if (this.type === 'Group') {
          this.exams2 = exams.filter(i => (i.assignedGroupForSubject && i.assignedGroupForSubject.groupId === this.selectedGroup.groupId) || (!i.assignedGroupForSubject && !i.division));
        }
        if (this.type === 'Division') {
          this.exams2 = exams.filter(i => (i.division === this.division) || (!i.assignedGroupForSubject && !i.division))
        }
        // this.exams2 = exams;
        // this.isLoadingExams = false;
        this.getAnalysisResult2();
      } catch (err) {
        console.error(err);
      }
    },
    gotoCreateTest() {
      this.$router.push({
        name: "activityCreateTest",
        params: {
          prop_selectedInstitute: this.selectedInstitute,
          prop_selectedSemester: this.semester.semId,
          prop_subjectId: this.selectedSubject.subjectId,
          prop_subjectName: this.selectedSubject.subjectName,
          prop_selectedCourse: this.course,
          prop_selectedDivision: this.division,
          prop_selectedDepartment: this.department,
          prop_selectedSubject: this.selectedSubject,
          prop_type: this.type,
          prop_groupInfo: this.selectedGroup
        },
      });
    },
    getExamStatus(exam) {
      const now = new Date()
      const examStart = new Date(exam.dateTimeUTC)
      const examEnd = addMinutes(new Date(exam.dateTimeUTC), exam.durationInMins + 2)
      if (isWithinInterval(now, { start: examStart, end: examEnd })) {
        // console.log('examstatus1', exam)
        return 'ongoing'
      } else if (isAfter(now, examEnd)) {
        // console.log('examstatus2', exam)
        return 'conducted'
      } else {
        // console.log('examstatus3', exam)
        return 'published'
      }
    },
    async deleteExam() {
      try {
        this.isDeletingExam = true
        const exam = this.selectedExam
        await apiV3.deleteRequest('/exam/delete', {
          examId: exam._id
        })
        this.exams = []
        this.selectedExam = null
        this.isDeleteDialogOpen = false
        this.getExams2()
        showStatus('Exam deleted successfully', 3000, 'success', this)
      } catch (err) {
        showStatus('An error occured, try again later', 3000, 'error', this)
      } finally {
        this.isDeletingExam = false
      }
    },
    gotoQuickTestInsight(exam) {
      this.$store.commit("liveData/set_selectedExam", exam);
      this.$router.push({
        name: "activityQuickTestInsight",
      });
    },
  },
};
</script>

<style src="./activityQuickTest.css" scoped>
</style>