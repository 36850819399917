<template src="./StudentMarksheet.html"></template>

<script>
export default {
    name: "StudentMarksheet",
    props: ["studentDetails", "marksheetDetails", "isPreview"],
    data() {
        return {
            instituteName: "",
            instituteAddress: "",
            instituteLogo: "",
            semesterName: "",
            departmentName: "",
            certificateTitle: "",
            certificateType: "",
            studentPersonalDetails: [],
            tableData: [],
            signatureIndividual: [],
            includeInstituteNameInTheMarksheet: false,
            includeInstituteNameInTheMarksheet: false,
            currentSemesterNumber: 0,
        }
    },
    created() {
        this.instituteName = this.marksheetDetails.instituteName
        this.instituteAddress = this.marksheetDetails.instituteAddress
        this.instituteLogo = this.marksheetDetails.instituteLogo
        this.semesterName = this.marksheetDetails.semesterName
        this.departmentName = this.marksheetDetails.departmentName
        this.currentSemesterNumber = this.marksheetDetails.currentSemesterNumber
        this.signatureIndividual = this.marksheetDetails.marksheetTemplate.signatureIndividual
        this.certificateTitle = this.marksheetDetails.marksheetTemplate.certificateType.text
        this.certificateType = this.marksheetDetails.marksheetTemplate.certificateType.value
        this.includeInstituteNameInTheMarksheet = this.marksheetDetails.marksheetTemplate.includeInstituteNameInTheMarksheet
        this.studentPersonalDetails = this.marksheetDetails.marksheetTemplate.studentPersonalDetails
        this.tableData = this.marksheetDetails.marksheetTemplate.tableData
    }
}
</script>
<style src="./StudentMarksheet.css" scoped></style>