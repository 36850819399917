<template src="./basicDetails.html"></template>
<script>
import SubjectRepository from "../../../../Repository/Subject";
import NbaRepository from "../../../../Repository/NBA";
import uploadToDigitalOceanRepository from "../../../../Repository/uploadToDigitalOcean";
import showStatus from "../../../../NetworkManager/showStatus";
import auth from "../../../../Services/Firebase/auth";
import UserRepository from "../../../../Repository/User";

// Global Components
import CustomTextField from "../../../../Components/styleGuide/InputsAndControls/TextField.vue";
export default {
  name: "basicDetails",
  components: {
    // examsBasicDetails,
    // examSubjectDetails,
    // examForm,
    // hallTicket,
    // examQuestionDetails,
    // examMarks,
    CustomTextField
  },
  props: ["subjectId", "semId"],
  data() {
    return {
      tab: 0,
      secondaryColor: "#FF4F1F",
      black: "#000000",
      backgroundColor: "#E7E7FFCF",
      subject: {},
      academicYear: "",
      selectedSubjectComponent: "",
      otherSubjectComponent: "",
      selectedSubjectType: "",
      otherSubjectType: "",
      selectedCourseFocus: "",
      otherCourseFocus: "",
      userData: "",
      subjectComponents: [
        "Program Core",
        "Program Electives",
        "Open Electives",
        "Summer Project",
        "Internships/Seminars",
        "Final Dissertation",
        "Mini project",
        "Field Work",
        "Other",
      ],
      subjectTypes: ["Theory", "Practical", "Tutorial", "Other"],
      courseFocuses: [
        "Employabilty",
        "Skill development",
        "Entreprenuership",
        "Other",
      ],
      inputBg: "#E0E0FF",
      courseProofFilesToUpload: [],
      attachmentFilesToUpload: [],
      courseProofDialog: false,
      attachmentFileDialog: false,
      selectedFilesUrl: [],
      courseFilesChanged: false,
      attachmentFileChanged: false,
      saveLoader: false,
      firebaseResult: {},
      subjectTypesFirebase: {},
      allocatedFaculties: [],
      loadingFiles: false,
    };
  },
  async created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.uploadToDigitalOceanInstance = new uploadToDigitalOceanRepository(
      this
    );
    this.userRepositoryInstance = new UserRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.userData = this.$store.getters["user/get_userData"];
    await this.initialize();
  },
  computed: {
    // courseProofFileInputLabel() {
    //   return this.courseFilesChanged === false
    //     ? this.subject?.courseProof?.length  + ' files'
    //     : "";
    // },
    courseProofFileInputLabel() {
      return this.subject?.courseProof?.length + " files";
    },
    attachmentFileInputLabel() {
      return this.subject?.courseAttachment?.length + " files";
    },
    viewCourseProofModal() {
      if (this.subject.courseProof?.length > 0) {
        return true;
      }
      if (this.courseProofFilesToUpload?.length > 0) {
        return true;
      }
      return false;
    },
    viewAttachmentModal() {
      if (this.subject.courseAttachment?.length > 0) {
        return true;
      }
      if (this.attachmentFilesToUpload.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async initialize() {

      await this.fetchSubject();
      this.fillData();
      await this.getAcademicYearOfTheInstitute();
      auth.getSubjectTypeFromFirebase();
    },
    fillData() {
      this.selectedSubjectType = this.getSubjectTypeInWords(
        this.subject.subjectType
      );
     

      if (this.subject.courseFocus !== 'Other') {
        this.selectedCourseFocus = this.subject.courseFocus;
      } else {
        this.selectedCourseFocus = "Other";
        this.otherCourseFocus = this.subject.courseFocus;
      }
    },
    handleChange() {
      // Reset selectedComponent when a non-Other option is selected
      if (this.selectedComponent !== "Other") {
        this.selectedComponent = null;
      }
    },
    deleteCourseProoFiles() {
  
      // this.subject
      this.subject.courseProof = [];
    },
    deleteCourseAttachmentFile() {
      this.subject.courseAttachment = [];
    },
    async uploadFile(fileArr) {
      let urlLinkArr = [];
      try {
        if (fileArr.length > 0) {
          let data = await this.uploadToDigitalOceanInstance.upload(
            fileArr,
            this.userData.uId
          );
          return data;
        }
      } catch (error) {
        console.error("error",error);
        return urlLinkArr;
      }
    },
    async openFileDialog(type) {
      if (type === "courseProof") {

        this.courseProofDialog = true;
        if (this.courseProofFilesToUpload) {
          this.selectedFilesUrl = await Promise.all(
            this.courseProofFilesToUpload.map(async (file, index) => {
              return await URL.createObjectURL(file);
            })
          );
        }
      } else {
     
        if (this.attachmentFilesToUpload) {
          this.selectedFilesUrl = this.attachmentFilesToUpload.map(
            (file, index) => {
              return URL.createObjectURL(file);
            }
          );
        }
        this.attachmentFileDialog = true;
      }
    },
    async getAcademicYearOfTheInstitute() {
      const data = await this.nbaRepositoryInstance.getBatchesOfInstitute({
        lmsInstituteId: this.selectedInstitute,
      });

      let tempAcademicYearsSet = new Set();
      data.forEach((item) => {
    
        item.batch.forEach((batch) => {
          tempAcademicYearsSet.add(
            JSON.stringify({
              academicId: batch?.academicId || "",
              academicYear: batch?.academicYear || "",
            })
          );
        });
      });
      let tempAcademicYears = Array.from(tempAcademicYearsSet, JSON.parse);
      this.academicYear = tempAcademicYears.filter(
        (item) => item.academicId === this.subject.academicId
      )[0]?.academicYear || "";
   
      // this.academicYears = tempAcademicYears
      // this.academicYears = tempAcademicYears;
    },
    getSubjectTypeInWords(subjectTypeInNumber) {
      if (subjectTypeInNumber === 10) {
        return "Theory";
      }
      if (subjectTypeInNumber === 11) {
        return "Practical";
      }
      if (subjectTypeInNumber === 12) {
        return "Tutorial";
      }
      if (subjectTypeInNumber === 13) {
        return "Audit";
      }
    },
    async getAllocatedFacultiesName(uIdArr) {
      let response = await this.userRepositoryInstance.getFullNameOfUsers({
        uIds: uIdArr,
      });
      this.allocatedFaculties = response;
    
    },
    async fetchSubject() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.semId,
        subjectId: this.subjectId,
      };
    
      try {
        const response =
          await this.subjectRepositoryInstance.getDetailsOfASubject(objToPush);
        this.subject = response;
      
        // this.normalSubjectsForCourseYear = response.filter(
        //   (subject) => subject.courseType === "normal"
        // );
        // this.groupNameArr = [];
        // this.subjects.map((data) => {
        //   if (data.groupName !== "") {
        //     this.groupNameArr.push(data.groupName);
        //   }
        // });
        // this.groupNameArr.push("Other/ Add New");

        // let diplicateRemove = [...new Set(this.groupNameArr)];
        // this.groupNameArr = diplicateRemove;
      } catch (err) {
        console.error(err);
        // this.normalSubjectsForCourseYear = [];
      }
      this.getAllocatedFacultiesName(this.subject.faculties);
    },
    async saveSubjectDetails() {
      this.saveLoader = true;
    
      if (this.courseFilesChanged === true) {
        if (this.courseProofFilesToUpload.length > 0) {
          let res = await this.uploadFile(this.courseProofFilesToUpload);
          this.subject.courseProof = res;
        
        } else {
          this.subject.courseProof = null;
        }
      }
      if (this.attachmentFilesChanged === true) {
       
        if (this.attachmentFilesToUpload.length > 0) {
          let res = await this.uploadFile(this.attachmentFilesToUpload);
          this.subject.courseAttachment = res;
        } else {
          this.subject.courseAttachment = null;
        }
      }
      // if(this.courseProofFileToUpload && this.courseProofFileToUpload !== null){
      //   let res = await this.uploadFile([this.courseProofFileToUpload])
      //   this.subject.courseProof = res[0];
      // }
      // if(this.attachmentFileToUpload && this.subject.courseProof !== null ){
      //   if(this.attachmentFileToUpload !== null){
      //     let res = await this.uploadFile([this.attachmentFileToUpload])
      //     this.subject.courseAttachment = res[0];
      //   }
      // }
      // if(this.attachmentFileToUpload === null && Object.keys(this.subject.courseProof).length !== 0){
      //   if(Object.keys(this.subject?.courseProof).length !== 0){
      //     this.subject.courseProof = {}
      //   }
      // }
      // let filteredFiles = this.filesToUpload.filter((file)=>file !== null)
      // if(this.filesToUpload.length > 0){
      // let res = await this.uploadFile(filteredFiles)
      if (this.selectedCourseFocus !== "other") {
        this.subject.courseFocus = this.selectedCourseFocus;
      } else {
        this.subject.courseFocus = this.otherCourseFocus;
      }
     
      // if(this.filesToUpload[0] === null && this.filesToUpload[1] !== null ){
      //   this.subject.courseAttachment = res[0]
      //   this.subject.courseFocus = {}
      // }
      // if(this.filesToUpload[0] !== null && this.filesToUpload[1] === null ){
      //   this.subject.courseProof = res[0]
      //   this.subject.courseAttachment = {}
      // }
      // if(this.filesToUpload[0] !== null && this.filesToUpload[1] !== null ){
      //   this.subject.courseProof = res[0]
      //   this.subject.courseAttachment = res[1]
      // }
      // }

      // if(this.filesToUpload[0] === null && this.filesToUpload[1] === null){
      //   this.subject.courseProof = {}
      //   this.subject.courseAttachment = {}
      // }
     
      try {
        const response = await this.subjectRepositoryInstance.bulkCreateSubject(
          [this.subject]
        );

        showStatus(
          "Subject detatils saved successfully",
          2000,
          "success",
          this
        );
      } catch (err) {
        showStatus("Could not connect to server.", 2000, "error", this);
        console.error(err);
      }
      this.saveLoader = false;
    },
  },
};
</script>

<style scoped src="./basicDetails.css">
</style>