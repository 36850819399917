<template src="./activityAdmissionNewUsers.html"></template>
<script>
import UserRepository from '../../Repository/User'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import SemesterRepository from "../../Repository/Semester";
// import NetworkManager from "@/NetworkManager";
// import InstituteAPIs from "@/ApiNames/Institute";
import auth from "@/Services/Firebase/auth";
// import showStatus from '../../NetworkManager/showStatus'
// import Vue from "vue";
import { convertDate, convertToISTTime } from "../../Services/Utils/DateTimeFormatter";
import {
  Vuetify,
  VApp,
  VCard,
  Vslidegroup
  /* other imports ... */
} from "vuetify";
var firestorage = null
export default {
  name: 'activityAdmissionNewUsers',
  components: {
    inputContainer
  },
  props: ['PropsSelectedAdmission'],
  data() {
    return {
      dates2: '',
      messToShow: false,
      dateFilterArray: [],
      menu2: false,
      menu3: false,
      dates: '',
      admissionUserIdDummy: '',
      date1: '',
      modal: false,
      dueDateFOrLastInstall: '',
      dummyObj: {},
      dummyPaymentDetailsArray: [],
      reminderHeader: [{
        text: 'Due Date',
        align: 'left',
        value: 'dueDate',
      },
      {
        text: 'Amount',
        align: 'left',
        value: 'amount',
      },
      {
        text: 'Send Email',
        align: 'left',
        value: 'email',
      },
      {
        text: 'Send WhatsUp Message',
        align: 'left',
        value: 'whatsUp',
      },
      {
        text: 'icon',
        align: 'left',
        value: 'deleteIcon',
      }
      ],
      ex4: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // date5: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      allReminderDetails: [],
      amount: '',
      email: '',
      paymentMode: '',
      installments: '',
      reminderStudentName: '',
      dueDatePicker: false,
      whatsupBody: '',
      mailBody: '',
      mailSetupDialog: false,
      singleSelect: true,
      selectedModeOFReminder: [],
      selectedStudentForReminder: [],
      dialogForFinalReminderConfirmation: false,
      dialogReminder: false,
      payButton: false,
      subjectWise: true,
      uploadTask: '',
      paymentUser: null,
      selectedCourseName: '',
      coursename: [],
      selectedClassName: '',
      classname: [],
      FeeReceipt: {},
      selectedSemester: '',
      semesters: '',
      semestersList: [],
      loading: false,
      search: '',
      search2: '',
      feeAnalysisData: {
        totalPaymentReceived: 0,
        totalPayment: 0
      },
      totalFeeAnalysisPercentage: 0,
      installmentDetailsDialog: false,
      studentInstallmentDeatils: [],
      installmentPaymentDetailsDialog: false,
      selectedInstallment: null,
      headers: [
        {
          text: 'Date',
          align: 'left',
          sortable: true,
          value: 'createdAt',
          filterable: true
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'firstName',
          filterable: true
        },
        {
          text: 'Subjects / Course',
          align: 'left',
          sortable: true,
          value: 'subjects',
          filterable: true
        },
        {
          text: 'Installments',
          sortable: true,
          value: 'installments',
          filterable: true,
          align: 'center'
        },
        // {
        //   text: 'Status',
        //   align: 'left',
        //   sortable: true,
        //   value: 'isConfirmed',
        //   filterable: true
        // },
        {
          text: 'Total Fee',
          value: 'totalPayment',
          align: 'center'
        },
        {
          text: 'Paid Amount',
          value: 'totalPaidAmountCalculated',
          align: 'center'
        },
        {
          text: 'Remaining Amount',
          value: 'remainingFeesFromInstallment',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'action',
          align: 'left'
        }
      ],
      payInstallmentDetails: {
        paymentDate: null,
        paymentAmount: null,
        paymentMethod: null,
        paymentImage: null,
        paymentTransactionId: null
      },
      paymentMethod: null,
      reminderHeaders: [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'checkBox',
          filterable: true
        },

        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'firstName',
          filterable: true
        },
        {
          text: 'Last Name',
          align: 'left',
          sortable: true,
          value: 'lastName',
          filterable: true
        },
        {
          text: 'Installments No',
          sortable: true,
          value: 'installmentId',
          filterable: true
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount',
          filterable: true
        },
        {
          text: 'Due Date',
          align: 'left',
          sortable: true,
          value: 'dueDate',
          filterable: true
        },

        {
          text: 'Send Email',
          value: 'sendEmail',
          align: 'center'
        },
        {
          text: 'Send Whats Up',
          value: 'sendWhatsUp',
          align: 'center'
        },
        {
          text: 'Last reminder date by admin',
          value: 'lastReminderDate',
          align: 'center'
        }

      ],
      insatllmentHeaders: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'firstName',
          filterable: true
        },
        {
          text: 'Installments No',
          sortable: true,
          value: 'installmentId',
          filterable: true
        },
        {
          text: 'Due Date',
          align: 'left',
          sortable: true,
          value: 'dueDate',
          filterable: true
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount',
          filterable: true
        },
        {
          text: 'Payment Method',
          sortable: true,
          value: 'paymentMethod',
          filterable: true
        },
        {
          text: 'Payment Date',
          sortable: true,
          value: 'paymentDate',
          filterable: true
        },
        {
          text: 'txn Id',
          sortable: true,
          value: 'paymentTransactionId',
          filterable: true
        },
        {
          text: 'Image',
          sortable: true,
          value: 'paymentImage',
          filterable: true
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
          value: 'status',
          filterable: true
        },
        {
          text: 'Actions',
          value: 'action',
          align: 'center'
        }
      ],
      admissionList: [],
      selectedAdmission: '',
      showUsers: [],
      secondAdmissionList: [],
      installmentData: [],
      users: []
    }
  },
  computed: {
    dateRangeText() {
      return this.date.join(' ~ ')
    },
  },
  created() {
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Student Details')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'liveData/get_admissionSemester'
    ]
    if (this.PropsSelectedAdmission) {
      this.$store.commit("liveData/set_selectedAdmission", this.PropsSelectedAdmission);
    }
    this.selectedAdmission = this.$store.getters[
      'liveData/get_selectedAdmission'
    ]
    console.log('this.selectedAdmission', this.selectedAdmission);
    // this.feeAnalysis();
    this.getSemesters()
    this.getAdmissionData()
    this.fetchData()
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        'state_changed',
        sp => {
          console.log(Math.floor((sp.bytesTransferred / sp.totalBytes) * 100))
        },
        null,
        () => {
          console.log("HSHSHSHHS-->")
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("this.payInstallmentDetails.paymentImage-->", downloadURL)
            this.payInstallmentDetails.paymentImage = downloadURL;
          })

        }
      )
    }
  },
  methods: {
    dateRangeSelected() {
      console.log('onchange call')
      this.messToShow = false
    },
    async generateExcelAdmission() {
      let obj = {
      }
      let s1, e1;
      let startTime, endTime;

      if (this.dates !== '' && this.dates2 !== '') {
        // console.log('dates0', this.dates[0]);
        // console.log('dates1', this.dates[1]);
        const s = new Date(this.dates);
        s1 = s.toISOString()
        // console.log('s1', s1);
        const e = new Date(this.dates2);
        e1 = e.toISOString()
        // console.log('e1', e1);
        if (s1 > e1) {
          // console.log('s1 is grater');
          endTime = s1
          startTime = e1
        } else {
          // console.log('el is grater');
          endTime = e1
          startTime = s1
        }
        console.log('end dates', endTime);
      console.log('start dates', startTime);
      this.dateFilterArray = []
      this.users.map((data) => {
        if (data.createdAt > startTime && data.createdAt < endTime) {
          this.dateFilterArray.push(data)
        }
      })
      console.log('this.dateFilterArray', this.dateFilterArray);

      }

      obj.admissionStudentData = this.dateFilterArray
      obj.admissionDetails = this.selectedAdmission
      // let start = startTime.slice(0,10)
      // let end = endTime.slice(0,10)
// console.log('range', start,end)

      obj.startDate = startTime
      obj.endDate = endTime

      console.log('obj', obj);
      try {
        if (this.dateFilterArray.length > 0) {
          const pdfBlob =
            await this.admissionUserRepositoryInstance.generateExcelAdmission(
              obj
            )
          const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(pdfBlob2)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Student Admission list.xlsx')
          document.body.appendChild(link)
          link.click()
        } else {
          // window.alert('Change date range no student found')
          this.messToShow = true

        }
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    async setDueDate() {
      // console.log('this.selectedAdmission.admissionId', this.selectedAdmission.admissionId);
      // console.log('this.date', this.date);
      let length = this.installmentData.length
      console.log('length', length);
      console.log('this.dueDateFOrLastInstall', this.dueDateFOrLastInstall);
      this.installmentData[length - 1].dueDate = this.date1
      console.log('this.installmentData', this.installmentData)
      try {
        const res = await this.admissionUserRepositoryInstance.updateAdmissionUserPayment({
          admissionUserId: this.admissionUserIdDummy,
          payments: this.installmentData,
          remainingFeesFromInstallment: 0
        });
        console.log('hello');
      } catch (error) {
        console.log('error', error);
        console.log('APi fail');
      }
      this.date1 = ''
      this.modal = false
      this.fetchData()
    },
    deleteRow(item) {
      let index = item.installmentId - 1
      this.dummyPaymentDetailsArray.splice(index, 1)
      console.log('this.dummyPaymentDetailsArray', this.dummyPaymentDetailsArray);
    },
    addDueDate() {
      let length = 0
      length = this.dummyPaymentDetailsArray.length + 1;
      console.log('length', length);

      let amount = this.amount / this.dummyPaymentDetailsArray.length

      this.dummyObj = {
        installmentId: length,
        paymentDate: "",
        paymentMethod: "",
        paymentImage: "",
        paymentAmount: "",
        paymentTransactionId: "",
        dueDate: "",
        status: "unpaid",
        amount: "",
        reminderMedia: ["email", "whatsUp"]
      }
      console.log('this.dummyObj', this.dummyObj);
      this.dummyPaymentDetailsArray.push(this.dummyObj)

    },
    handelReminder(item) {
      console.log('item', item);
      this.reminderStudentName = item.firstName + " " + item.nameOfFather + ' ' + item.lastName
      this.installments = item.installments
      this.email = item.emailId
      this.paymentMode = item.paymentOption
      this.amount = item.totalPayment
      this.dummyPaymentDetailsArray = item.payments
      this.dummyPaymentDetailsArray.map((x) => {
        console.log('x', x.dueDate.slice(0, 10));
        x.dueDate = x.dueDate.slice(0, 10)
      })
      // console.log('this.dummyPaymentDetailsArray', this.dummyPaymentDetailsArray);
      //  this.allReminderDetails.push(obj)
      this.dialogReminder = true
      // showStatus('Reminder sent to student', 3000, 'success', this)
      // this.addDueDate()
    },
    async getImageView(url) {
      window.open(url, "_blank");
    },
    async onFacultyLogoSelect(file) {
      await this.uploadFacultyLogo(file)
    },

    async uploadFacultyLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        if (size > 300) {
          this.payButton = true;
          return
        }
        this.payButton = false;
        this.uploadTask = firestorage.ref('Transaction/' + file.name).put(file);
        // this.payInstallmentDetails.paymentImage = this.selectedInstallment.installmentId+"_"+new Date().toJSON().slice(0,10).replace(/-/g,'/')+"_"+file.name
      } else {
        this.payButton = false;
        this.payInstallmentDetails.paymentImage = "";
      }
    },

    async feeAnalysis() {
      // this.selectedAdmission = this.PropsSelectedAdmission;
      const res = await this.admissionUserRepositoryInstance.feeAnalysis({
        admissionId: this.selectedAdmission.admissionId,
      });
      this.feeAnalysisData = res.results;
      console.log("before", this.feeAnalysisData.installmentDetails)

      this.feeAnalysisData.installmentDetails.forEach(installment => {
        installment.totalInstallmentFeeAnalysisPercentage = ((installment.installmetTotalPaymentReceived * 100) / (installment.installmettTotalAmount)).toFixed(2)
      })
      console.log("after", this.feeAnalysisData)
      this.totalFeeAnalysisPercentage = ((this.feeAnalysisData.totalPaymentReceived * 100) / (this.feeAnalysisData.totalPayment)).toFixed(2)
      this.loading = false
    },

    async installmentDetails(item) {
      this.FeeReceipt = item
      this.paymentUser = item;
      this.admissionUserIdDummy = item.admissionUserId
      console.log('item.payments.length', item);

      if (item.payments.length > 0) {
        if (parseInt(item.remainingFeesFromInstallment) !== 0) {
          let tempObj = {
            remainingFees: true,
            firstName: item.firstName,
            installmentId: item.payments.length + 1,
            amount: item.remainingFeesFromInstallment,
            dueDate: "",
            paymentMethod: "",
            paymentDate: "",
            paymentTransactionId: "",
            paymentImage: "",
            status: "unpaid"
          }
          console.log('tempObj0', tempObj);
          item.payments.push(tempObj);
          console.log('item.payments', item.payments);
          this.paymentUser.payments = item.payments
        }
        this.installmentData = item.payments;
      } else {
        let tempObj = {
          remainingFees: true,
          firstName: item.firstName,
          installmentId: 1,
          amount: item.totalPayment,
          dueDate: "",
          paymentMethod: "",
          paymentDate: "",
          paymentTransactionId: "",
          paymentImage: "",
          status: "unpaid"
        }
        this.installmentData[0] = tempObj;
      }

      this.installmentDetailsDialog = true;
    },

    async installmentPaymentDetails(item) {
      this.selectedInstallment = item;
      console.log('itemselectedInstallment', item);
      this.payInstallmentDetails.remainingFees = item.remainingFees ? item.remainingFees : false;
      this.payInstallmentDetails.paymentAmount = item.amount;
      this.payInstallmentDetails.paymentImage = item.paymentImage;
      this.payInstallmentDetails.paymentDate = new Date().toISOString().substr(0, 10);
      this.payInstallmentDetails.dueDate = new Date();
      this.installmentPaymentDetailsDialog = true;

      console.log('hello');

    },

    async cancel() {
      if (this.paymentUser.remainingFeesFromInstallment > 0) {
        this.paymentUser.payments.pop()
      }
      this.payInstallmentDetails.paymentDate = null;
      this.payInstallmentDetails.paymentAmount = null;
      this.payInstallmentDetails.paymentMethod = null;
      this.payInstallmentDetails.paymentImage = null;
      this.payInstallmentDetails.paymentTransactionId = null;
      this.installmentDetailsDialog = false;
      this.installmentPaymentDetailsDialog = false;
      this.studentInstallmentDeatils = [];
      this.selectedInstallment = null;
      this.date = ''
    },

    async submitPaymentInstallment() {
      this.installmentData.pop()
      let installments = 0
      let totalFeesFromInstallment = 0
      this.payInstallmentDetails.amount = this.payInstallmentDetails.paymentAmount;
      this.installmentData.push(this.payInstallmentDetails)
      const objToPush = {
        admissionUserId: this.paymentUser.admissionUserId,
        installmentId: this.selectedInstallment.installmentId,
        payInstallmentDetails: this.payInstallmentDetails,
      }
      if (this.paymentUser.payments.length === 0) {
        installments = this.paymentUser.length
        totalFeesFromInstallment += parseInt(this.payInstallmentDetails.paymentAmount)
        objToPush.installments = installments
      } else {
        this.installmentData.map((installment) => {
          console.log('installment', installment);
          totalFeesFromInstallment += parseInt(installment.paymentAmount)
        })
        objToPush.installments = this.installmentData.length
      }
      console.log('totalFeesFromInstallment', totalFeesFromInstallment);
      objToPush.remainingFeesFromInstallment = this.paymentUser.totalPayment - totalFeesFromInstallment

      console.log('remainingFeesFromInstallment', objToPush.remainingFeesFromInstallment);
      const res = await this.admissionUserRepositoryInstance.submitPaymentInstallment(objToPush);
      console.log("This is object to push",
        {
          admissionUserId: this.paymentUser.admissionUserId,
          installmentId: this.selectedInstallment.installmentId,
          payInstallmentDetails: this.payInstallmentDetails
        })
      if (res !== null && res !== undefined) {
        this.payInstallmentDetails.paymentDate = null;
        this.payInstallmentDetails.paymentAmount = null;
        this.payInstallmentDetails.paymentMethod = null;
        this.payInstallmentDetails.paymentImage = null;
        this.payInstallmentDetails.paymentTransactionId = null;
        this.installmentDetailsDialog = false;
        this.installmentPaymentDetailsDialog = false;
        this.studentInstallmentDeatils = [];
        this.selectedInstallment = null;
      }
      this.fetchData()

    },
    async fetchStudents() {
      console.log('this.userData', this.userData);
      console.log('this.selectedAdmission', this.selectedAdmission);
      const objToPush = {
        admissionId: this.selectedAdmission.admissionId,
        department: this.selectedAdmission.department,
        courseName: this.selectedAdmission.courseName,
        semId: this.selectedAdmission.semId,
        instituteId: this.selectedAdmission.instituteId,
        sourceEmailId: this.userData.userName
      }
      const res = await this.admissionUserRepositoryInstance.addPreviousStudentsToAdmission(objToPush);
    },
    async fetchAdmissionDetailsSelect() {
      // this.selectedAdmission = this.PropsSelectedAdmission;
      this.admissionList = [this.PropsSelectedAdmission];
      let finalAdmissionList = [];
      this.admissionList.forEach(admission => {
        this.semestersList.forEach(sem => {
          if (admission.semId === sem.semId) {
            admission.semName = sem.semName
            admission.admissionName = admission.semName + '-' + admission.department + '-' + admission.courseName
            finalAdmissionList.push(admission);
          }
        });
      });
      this.admissionList = finalAdmissionList;
      this.fetchData();
    },

    backToAdmissionHome() {
      this.$router.push({
        name: 'activityAdmission',
      });
    },

    async fetchData() {
      this.dates = ''
      this.dates2 =''

      this.loading = true
      try {
        const res = await this.admissionUserRepositoryInstance.getAdmissionUsersById({
        admissionId: this.selectedAdmission.admissionId,
      });

      this.users = res.admissionUsers
      this.users.map((data) => {
        let totalPaidAmountByCalculation = 0;
        data.payments.map((item) => {
          if (item.status === 'paid') {
            totalPaidAmountByCalculation += Number(item.paymentAmount)
          }
        })
        data.totalPaidAmountCalculated = totalPaidAmountByCalculation
        data.totalAmountPaidByStudent = data.totalPayment - data.totalPaidAmountCalculated
      })
      console.log('this.users', this.users);
     
     
        
      } catch (error) {
        console.log('error', error);
        
      }
     

      // this.processUsers()
      this.loading = false
      this.feeAnalysis()
    },
    // fetch Admission Table
    async getAdmissionData() {
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataForStudent({
        status: true,
        instituteId: this.selectedInstitute,
      });
      this.secondAdmissionList = res;
      console.log('this.secondAdmissionList', this.secondAdmissionList);
    },
    async processUsers() {
      this.users.forEach(user => {
        user.batches.forEach(batch => {
          user[batch.subject] = true
        })
      });
      this.showUsers = this.users[0].payments;
    },

    selectUser(user) {
      let admissionData = {}
      console.log('this.selectedAdmission', this.selectedAdmission);
      this.secondAdmissionList.map((admission) => {
        if (admission.admissionId === this.selectedAdmission.admissionId) {
          admissionData = admission
        }
      })
      console.log("selectedAdmission user user", user)
      console.log("this.selectedAdmission user", this.selectedAdmission)
      if (user.paymentOption) {
        this.$router.push({
          name: 'activityAdmissionUser',
          params: {
            prop_user: user,
            prop_admission: this.selectedAdmission
          }
        })
      } else {
        this.$router.push({
          name: 'activityAdmissionForm',
          params: {
            prop_user: user,
            prop_admissionData: admissionData
          }
        })
      }
    },

    gotoNewAdmission() {
      let admissionData = {}
      this.secondAdmissionList.map((admission) => {
        if (admission.admissionId === this.selectedAdmission.admissionId) {
          admissionData = admission
          return
        }
      })
      this.$router.push({
        name: 'activityAdmissionForm',
        params: {
          prop_user: undefined,
          prop_admissionData: admissionData
        }
      });
    },
    gotoPaymentSchedule(user) {
      this.$router.push({
        name: 'activityPaymentSchedule',
        params: {
          prop_user: user,
          PropsSelectedAdmission: this.selectedAdmission
        }
      });
    },
    parseDate(dateOfBirth) {
      const parsedDate = new Date(dateOfBirth)
      return (
        parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getUTCDay()).slice(-2)
      )
    },

    removeFromArray(item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },

    dateConverter(dateYear) {
      let date = new Date(dateYear)
      const dateString = date.toISOString()
      return dateString
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/')
    },
    // New admission Table
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        this.semesters.reverse()
        this.semestersList = this.semesters;
      } catch (err) {
        console.log(err)
      }
      this.fetchAdmissionDetailsSelect();
    },

    async changeFunction() {
      this.selectedClassName = '';
      this.selectedCourseName = '';
    },

    async changeFunction1() {
      this.selectedClassName = '';
    },

    async getCoursebySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.coursename = await this.semesterRepositoryInstance.getCoursebySemesters(
          objToPush
        )
      }
      catch (err) {
        console.log(err)
      }
    },

    async getClassbySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.classname = await this.semesterRepositoryInstance.getCoursebySemesters(objToPush)
      } catch (err) {
        console.log(err)
      }
    },
    gotoFeeReceipt(user) {
      this.$router.push({
        name: 'activityFeeReceipt',
        params: {
          prop_user: user,
          selected_admission: this.selectedAdmission
        },
      })
    },
  },

}
</script>
<style src="./activityAdmissionNewUsers.css" scoped></style>
