export default {
  createDiscussion: "/discussion/upload",
  getAllDiscussionsForAUser: "/discussion/getAllDiscussionsForAUser",
  deleteDiscussion: "/discussion/delete",
  getSignedUrl: "/spaces/presigned_url",
  createResponse: "/discussion/response/upload",
  createComment: "/discussion/response/createComment",
  getDiscussionById: "/discussion/getDiscussionById",
  getResponseByDiscussionId: "/discussion/response/getResponseByDiscussionId",
  deleteResponse: "/discussion/response/delete"
};
