<template src="./activityDashboard.html"></template>

<script>
import SemesterRepository from "../../Repository/Semester";
import SubjectsRepository from "../../Repository/Subject";
import UserRepository from "../../Repository/User";
import LectureRepository from "../../Repository/Lecture";
import OnlineLectureRepository from "../../Repository/OnlineLecture";
import InfrastructureReferenceRepository from "../../Repository/InfrastructureReference";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import auth from '../../Services/Firebase/auth'


import {
  convertDate,
  formatToSlashSepearated,
  timeStringConverter,
  convertFromYYYYMMDDToDate, convertUTCDateToLocalDate,
  convertTime,
  convertLocalToUTC
} from "../../Services/Utils/DateTimeFormatter";
import {
  subjectTypes, conductableLectureTypes,
  lectureTypes,
} from "../../Constants/Utils/Statics";
import inputContainer from "../../Components/inputContainer";
import arrayOps from "../../Services/Utils/arrayOps";
import { looseIndexOf } from "@vue/shared";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "activityDashboard",
  props: [
    'prop_dashboardDetails'
  ],
  components: {
    inputContainer,
    apexcharts: VueApexCharts
  },
  data() {
    return {
      assignedCourseYearForSubAdmin: [],
      chartSeries: [{
        data: [0, 0, 0]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
            }
          }
        },
        colors: ['#F9E84C', '#C84A77', '#3DB2C2'],
        // colors: [
        //   "#88D66C",
        //   "#AAAAAA",
        //   "#DD4D4D",
        //   "#64CDCD",
        //   "#F47777"
        // ],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        // dataLabels: {
        //   enabled: false
        // },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
            ['Division wise'],
            ['Batchwise'],
            ['Group wise'],
          ],
        }
      },

      pieSeries: [],
      pieOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Conducted','Upcoming','Not Conducted','Cancelled and Rescheduled' ,'Cancelled'],
        dataLabels: {
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex]
        },},
        colors: [
          "#88D66C",
          "#AAAAAA",
          "#DD4D4D",
          "#64CDCD",
          "#F47777"
        ],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      allLectureForSelectedDate: [],
      uniqueUserFromFireBase: [],
      countOfManualPresent: 0,
      firebaseData: [],
      allLectureIdsForQr: [],
      createLectureArr: ['By Date', 'By Subject', 'Value Added Activity'],
      allLectureForSelectedDate2: [],
      filterLecturedByDivChange: [],
      dummyArrForFilter: [],
      facultyNamesOfTeachingLecture: [],
      roomNamesOfLecture: [],
      subjectsDetailsForLecture: [],
      assignedDepartment: [],
      allSubjectData: [],
      semNameArr: [],
      selectedSem: '',
      selectedLecture: '',
      reasonForCancellingLecture: '',
      selectedLectureId: '',
      lectureStatusLine: '',
      todayDate: '',
      deptNameArr: [],
      selectedDept: '',
      search1: '',
      selectedContact: '',
      selectedTypeForChange: '',
      dateRangeSelect: 'Today',
      courseYearNameArr: [],
      selectedCourseYear: '',
      subjectNameArr: [],
      selectedSubject: '',
      semestersAllData: [],
      accessibleModule: [],
      allDivCombinationsArr: [],
      contactTypes: ['Lectures', 'Practicals', 'Both'],
      lectureTypeArr: ['Conducted', 'Non conducted', 'Cancelled', 'All'],
      isSubAdmin: false,
      isToShowTable: false,
      cancelLoading: false,
      showDeleteDialog: false,
      showCancellingDialog: false,
      loaderForLecture: false,
      showLectureDetails: false,
      openPanel: false,
      menu2: false,
      menu1: false,
      menu3: false,
      startTime: false,
      endTime: false,
      panelOn: false,
      selectedCombination: '',
      tempStartDate: '',
      tempEndDate: '',
      tempCustomDate: '',
      semIdForSelectedSem: '',
      endTime: false,
      userData: {},
      divisionNameCountObject: {},
      batchNameCountObject: {},
      groupNameCountObject: {},
      lectureDetailsToShow: {},
      selectedInstitute: '',
      lectureTypeRadio: '',
      selectedTypeForDiv: '',
      countForLectures: 0,
      countForPractical: 0,
      countForConductedPractical: 0,
      countForCancelledAndRescheduled:0,
      countForUpcomingLecture:0,
      conductedLectureCount: 0,
      nonConductedLectureCount:0,
      cancelLectureCount: 0,
      isBatchLecture: 0,
      isGroupedLecture: 0,
      isDivisionLecture: 0,
      showLectureActivityLogger: false,
      numPreviousLogs: 5,
      searchTable: "",
      logHeaders: [
        { text: 'Department Name', value: 'department' },
        { text: 'Course Year', value: 'courseYear' },
        { text: 'Division', value: 'division' },
        { text: 'Lecture Name', value: 'subjectName' },
        { text: 'Faculty Name', value: 'facultyName' },
        { text: 'Action Type', value: 'actionType' },
        { text: 'Lecture Date Time', value: 'lectureDateTime' },
        { text: 'Saved At', value: 'savedAt' },
        { text: 'Saved By', value: 'savedBy' },
      ],
      displayLogsArray: [],
      headers: [
        {
          text: "",
          value: "dot",
          width: "110px",
        },
        {
          text: "Date",
          value: "dateTime",
          class: "heading",
          width: "130px",
        },
        {
          text: "Time",
          value: "time",
          class: "heading",
          width: "180px",
          groupable: false,
        },
        { text: "Faculty", value: "fullName", class: "heading", width: "150px" },
        {
          text: "Subject",
          value: "subjectName",
          class: "heading",
          width: "100px",
        },

        {
          text: "Mark Attendance",
          value: "markAttendance",
          class: "heading",
          width: "100px",
        },
        // {
        //   text: "Cancel Lecture",
        //   value: "cancel",
        //   class: "heading",
        //   width: "100px",
        // },
        {
          text: "Department",
          align: "left",
          value: "department",
          class: "heading",
          width: "100px",
        },
        {
          text: "Course Year",
          value: "courseYear",
          class: "heading",
          width: "100px",
        },
        {
          text: "Division",
          value: "division",
          class: "heading",
          width: "100px",
        },
        {
          text: "Group Name",
          value: "group",
          class: "heading",
          width: "100px",
        },
        { text: "Batch", value: "batch", class: "heading", width: "100px" },
        // {
        //   text: "Merged Lectures",
        //   value: "merge",
        //   class: "heading",
        //   width: "100px",
        // },
        {
          text: "Lecture details",
          value: "details",
          class: "heading",
          width: "100px",
        },
        // {
        //   text: "Delete Lecture",
        //   value: "delete",
        //   class: "heading",
        //   width: "100px",
        // }

      ],
    };
  },
  created() {
    this.userRepositoryInstance = new UserRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this);
    this.$store.commit("liveData/set_selectedActivityName", "Lectures Dashboard");
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.isSubAdmin = this.$store.getters['liveData/get_isSubAdmin'].isSubAdmin
    this.accessibleModule = this.$store.getters['liveData/get_allowedModule']
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.activityLoggerInstance = new ActivityLoggerRepository(this)
    this.todayDate = new Date().toISOString()
    this.fetchData();
  },

  methods: {
    rowClass(item) {
      if (item.subjectType === 1 || item.subjectType === 5) {
        return 'valueAddedActivity'
      } else {
        return 'noValueAddedActivity'
      }
    },
    async getLectureLogs() {
      const { logs } = await this.activityLoggerInstance.getLectureLogs({ instituteId: this.selectedInstitute, limit: this.numPreviousLogs })
      const tempArr = []
      for (let i = 0; i < logs?.length; i++) {
        tempArr.push({
          department: logs[i].department,
          courseYear: logs[i].courseYear,
          division: logs[i].division,
          subjectName: logs[i].subjectName,
          facultyName: logs[i].userDetails.firstName + ' - ' + logs[i].userDetails.lastName,
          actionType: logs[i].actionType,
          lectureDateTime: this.formatISODate(logs[i].lectureStartTime),
          savedAt: this.formatISODate(logs[i].savedAt),
          savedBy: logs[i].savedBy,
        })
      }
      this.displayLogsArray = tempArr;
    },
    formatISODate(isoString) {
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };

      const date = new Date(isoString);
      if (date == "Invalid Date") return '-'
      const formattedDate = date.toLocaleString('en-US', options);

      return formattedDate;
    },
    async fetchData() {


      try {
        this.semestersAllData = []
        this.semNameArr = []
        let semDetails = await this.semesterRepositoryInstance.getClassbySemesters({
          instituteId: this.selectedInstitute
        });
        this.semestersAllData = semDetails.semesters
        this.semestersAllData.map((sem) => {
          if (!this.semNameArr.includes(sem.semName)) {
            this.semNameArr.push(sem.semName)
          }
        })
        if (this.prop_dashboardDetails !== undefined) {
          this.selectedSem = this.prop_dashboardDetails.semName
          this.semChange()
          this.selectedDept = this.prop_dashboardDetails.department
          this.deptChange()
          this.selectedCourseYear = this.prop_dashboardDetails.courseYear
          if (this.selectedCourseYear !== '') {
            await this.courseYearChange()
          }
          this.selectedSubject = this.prop_dashboardDetails.subject
          if (this.selectedSubject !== '') {
            this.subjectChange()
          }
          this.tempCustomDate = this.prop_dashboardDetails.specificDate
          this.tempStartDate = this.prop_dashboardDetails.startDate
          this.tempEndDate = this.prop_dashboardDetails.endDate
          if (this.tempCustomDate !== '') {
            this.dateRangeSelect = 'Particular'
          }
          if (this.tempEndDate !== '') {
            this.dateRangeSelect = 'Custom'
          }
          this.selectedTypeForChange = this.prop_dashboardDetails.type
          this.selectedContact = this.prop_dashboardDetails.contact
          this.selectedTypeForDiv = this.prop_dashboardDetails.divParameter
          await this.getLectures()
          if (this.selectedTypeForDiv !== '') {
            this.divTypeChange()
            this.selectedCombination = this.prop_dashboardDetails.combination
          }
          if (this.this.selectedCombination !== '') {
            this.divCombinationChanged()
          } else {
            this.allFilterChanges()
          }
        }
      } catch (error) {
        console.error('error', error);
      }

    },
   async semChange() {
      this.allLectureForSelectedDate = []
      this.deptNameArr = []
      this.selectedDept = ''
      this.courseYearNameArr = []
      this.selectedCourseYear = ''
      this.subjectNameArr = []
      this.selectedSubject = ''
      this.isToShowTable = false

      this.deptNameArr.push('All')
      this.semestersAllData.map((sem) => {
        if (sem.semName === this.selectedSem) {
          this.semIdForSelectedSem = sem.semId
          if (!this.deptNameArr.includes(sem.department)) {
            this.deptNameArr.push(sem.department)
          }
        }
      })
      //sub admin department access control
      let subAdminAssignedDepartmentWiseLecture = [];
      if (this.assignedDepartment?.length > 0) {
        this.deptNameArr.map((data) => {
          if (this.assignedDepartment.includes(data)) {
            subAdminAssignedDepartmentWiseLecture.push(data);
          }
        });
        this.deptNameArr = subAdminAssignedDepartmentWiseLecture;
      }
      this.clearAllExternalFilters()

    },
    deptChange() {
      this.courseYearNameArr = []
      this.allLectureForSelectedDate = []
      this.selectedCourseYear = ''
      this.subjectNameArr = []
      this.selectedSubject = ''
      this.isToShowTable = false


      if (this.selectedDept === 'All') {
        this.selectedCourseYear = 'All'
      } else {
        this.courseYearNameArr.push('All')
        this.semestersAllData.map((sem) => {
          if (sem.department === this.selectedDept) {
            if (!this.courseYearNameArr.includes(sem.courseYear)) {
              this.courseYearNameArr.push(sem.courseYear)
            }
          }
        })
      }
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin?.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDept) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYearNameArr = allowedCourseYearForSelectedDepartment
      }

      this.clearAllExternalFilters()

    },
    async courseYearChange() {
      this.subjectNameArr = []
      this.allLectureForSelectedDate = []
      this.selectedSubject = ''
      let semId = ''
      this.isToShowTable = false

      if (this.selectedCourseYear !== 'All') {
        try {
          this.allSubjectData = []
          this.allSubjectData = await this.subjectsRepositoryInstance.getSubjectsOfACourseYearInASemester({
            instituteId: this.selectedInstitute,
            semId: this.semIdForSelectedSem,
            department: this.selectedDept,
            courseYear: this.selectedCourseYear
          })
          this.subjectNameArr.push('All')
          this.allSubjectData.map((data) => {
            this.subjectNameArr.push(data.subjectName + '-' + this.convertSubjectTypeToName(data.subjectType))
          })
        } catch (error) {
          console.error('error', error);
        }
      }
      this.clearAllExternalFilters()

    },
    subjectChange() {
      this.isToShowTable = false
      this.allLectureForSelectedDate = []
      this.clearAllExternalFilters()

    },
    convertSubjectTypeToName(num) {
      let type;
      switch (num) {
        case 0: {
          type = "Theory";
          break;
        }
        case 1: {
          type = "Practical";
          break;
        }
        case 2: {
          type = "Tutorial";
          break;
        }
        case 3: {
          type = "Audit Course";
          break;
        }
        case 4: {
          type = "Theory_Elective";
          break;
        }
        case 5: {
          type = "Practical_Elective";
          break;
        }
        case 6: {
          type = "Tutorial_Elective";
          break;
        }
        case 7: {
          type = "Audit Course_Elective";
          break;
        }

        default:
          type = "Theory";

        // code block
      }
      return type;
    },
    convertToUTC(date, time) {
      const dateTimeString = date + ' ' + time;
      const dateTime = new Date(dateTimeString);
      if (isNaN(dateTime)) {
        return 'Invalid date or time format';
      }
      const utcString = dateTime.toUTCString();
      return utcString;
    },


    async getLectures() {
      this.loaderForLecture = true
      this.isToShowTable = true
      this.selectedContact = ''
      this.selectedTypeForChange = ''
      this.selectedTypeForDiv = ''
      this.selectedCombination = ''
      let semId = ''
      let deptArr = []
      let courseYearArr = []
      const currentUTCDate = new Date();
      currentUTCDate.setUTCHours(0, 0, 0, 0);
      let val = convertLocalToUTC(currentUTCDate)
      const currentEndUTCDate = new Date();
      currentEndUTCDate.setUTCHours(23, 59, 59, 999);
      let val2 = convertLocalToUTC(currentEndUTCDate)

      const objToPush = {
        instituteId: this.selectedInstitute,
        fromDate: val.toISOString(),
        toDate: val2.toISOString(),
      }
      if (this.tempEndDate !== '') {
        const currentUTCDate = new Date(this.tempStartDate);
        const currentEndUTCDate = new Date(this.tempEndDate);


        if (currentEndUTCDate > currentUTCDate) {
          currentUTCDate.setUTCHours(0, 0, 0, 0);
          currentEndUTCDate.setUTCHours(23, 59, 59, 999);
          let val = convertLocalToUTC(currentUTCDate)
          let val2 = convertLocalToUTC(currentEndUTCDate)
          objToPush.fromDate = val.toISOString()
          objToPush.toDate = val2.toISOString()
        } else {
          currentEndUTCDate.setUTCHours(0, 0, 0, 0);
          currentUTCDate.setUTCHours(23, 59, 59, 999);
          let val = convertLocalToUTC(currentUTCDate)
          let val2 = convertLocalToUTC(currentEndUTCDate)
          objToPush.fromDate = val.toISOString()
          objToPush.toDate = val2.toISOString()
        }

      }
      if (this.tempCustomDate !== '') {
        const currentUTCDate = new Date(this.tempCustomDate);
        currentUTCDate.setUTCHours(0, 0, 0, 0);
        const currentEndUTCDate = new Date(this.tempCustomDate);
        currentEndUTCDate.setUTCHours(23, 59, 59, 999);
        let val = convertLocalToUTC(currentUTCDate)
        let val2 = convertLocalToUTC(currentEndUTCDate)
        objToPush.fromDate = val.toISOString()
        objToPush.toDate = val2.toISOString()
      }

      if (this.selectedDept === 'All') {

        this.semestersAllData.map((sem) => {
          if (sem.semName === this.selectedSem) {
            semId = sem.semId
            deptArr.push(sem.department)
            // courseYearArr.push(sem.courseYear)
          }
        })
        deptArr = [...new Set(deptArr)]
        objToPush.department = deptArr
        objToPush.courseYear = []
        objToPush.semId = semId
        objToPush.type = 'Dept_All'

      }

      if (this.selectedDept !== 'All' && this.selectedCourseYear === 'All') {

        this.semestersAllData.map((sem) => {
          if (sem.semName === this.selectedSem) {
            semId = sem.semId
            courseYearArr.push(sem.courseYear)
          }
        })
        courseYearArr = [...new Set(courseYearArr)]
        objToPush.department = [this.selectedDept]
        objToPush.courseYear = courseYearArr
        objToPush.type = 'CourseYear_All'
        objToPush.semId = semId

      }

      if (this.selectedDept !== 'All' && this.selectedCourseYear !== 'All') {
        let semId = ''
        this.semestersAllData.map((sem) => {
          if (sem.semName === this.selectedSem) {
            semId = sem.semId
            deptArr.push(sem.department)
          }
        })
        let subjectIds = []
        let [name, type] = this.selectedSubject.split('-')
        this.allSubjectData.map((data) => {
          if (this.selectedSubject === 'All') {
            subjectIds.push(data.subjectId)
          } else if (name === data.subjectName) {

            subjectIds.push(data.subjectId)
          }
        })
        objToPush.department = [this.selectedDept]
        objToPush.courseYear = [this.selectedCourseYear]
        objToPush.subjectIds = subjectIds
        objToPush.semId = semId
        objToPush.type = 'Normal'
      }
      try {
        let lectureData = await this.lectureRepositoryInstance.getOverAllLectureForDates(objToPush)
        this.allLectureForSelectedDate = []
        this.allLectureForSelectedDate2 = []
        this.facultyNamesOfTeachingLecture = []
        this.roomNamesOfLecture = []
        this.subjectsDetailsForLecture = []
        this.allLectureIdsForQr = []
        this.firebaseData = []
        this.allLectureForSelectedDate = lectureData.lecture
        this.allLectureForSelectedDate2 = lectureData.lecture
        this.facultyNamesOfTeachingLecture = lectureData.facultyDetails.fullNames
        this.roomNamesOfLecture = lectureData.roomDetails.roomName
        this.subjectsDetailsForLecture = lectureData.subjectDetails.subjects

        this.calculateGraphRelatedValues()
        this.loaderForLecture = false

      } catch (error) {
        console.error('error', error);
        this.loaderForLecture = false

      }

    },
    calculateGraphRelatedValues() {
      this.countForLectures = 0
      this.countForPractical = 0
      this.countForConductedPractical = 0
      this.conductedLectureCount = 0
      this.isBatchLecture = 0
      this.isGroupedLecture = 0
      this.isDivisionLecture = 0
      this.cancelLectureCount = 0
      this.divisionNameCountObject = {}
      this.batchNameCountObject = {}
      this.groupNameCountObject = {}
      // console.log('allLectureForSelectedDate', this.allLectureForSelectedDate);
      this.allLectureForSelectedDate.map((lec) => {
        lec.fullName = '-'
        lec.roomName = '-'
        lec.subjectName = '-'

        this.facultyNamesOfTeachingLecture.map((fac) => {
          if (fac.uId === lec.uId) {
            lec.fullName = fac.fullName
          }
        })
        this.roomNamesOfLecture.map((room) => {
          if (room.roomId === lec.roomId) {
            lec.roomName = room.roomName
          }
        })
        this.subjectsDetailsForLecture.map((sub) => {
          if (sub.subjectId === lec.subjectId) {
            lec.subjectName = sub.subjectName
            lec.subjectType = sub.subjectType
          }
        })

        if (lec.multiDivision && lec.multiDivision?.length > 0) {
          let divName = ''
          lec.multiDivision.map((info, i) => {
            if (i === 0) {
              divName = info
            } else {
              divName = divName + ', ' + info
            }
          })
          lec.division = divName
        }
        if (lec.subjectType === 0 || lec.subjectType === 4) {
          this.countForLectures++;
        }
        if (lec.subjectType === 1 || lec.subjectType === 5) {
          this.countForPractical++;
          if(lec.isConducted){
            this.countForConductedPractical++;
          }
        }
        // if (lec.isConducted) {
        //   this.conductedLectureCount++;
        // }else if (){
        //   this.nonConductedLectureCount++;
        // }
        // if (lec.status === 6) {
        //   this.cancelLectureCount++;
        // }
        // if (lec.status === 7){
        //   this.countForCancelledAndRescheduled++;
        // }
        // if (lec.startTime) {
        //   const lectureStartTime = new Date(lec.startTime);
        //   const currentDate = new Date();
        //   if (lectureStartTime > currentDate) {
        //     this.countForUpcomingLecture++;
        //   }
        // }
        // Assuming 'lec' is your lecture object
        if (lec.startTime) {
          const lectureStartTime = new Date(lec.startTime);
          const currentDate = new Date();
          if (lectureStartTime > currentDate) {
            this.countForUpcomingLecture++;
            lec.lectureType = 'Upcoming';
          }
        }

        if (lec.isConducted) {
          console.log("lec>>>>", lec)
          this.conductedLectureCount++;
          lec.lectureType = 'Conducted';
        }

        if(!lec.isConducted && lec.status !== 7 && lec.lectureType !== 'Upcoming'){
          this.nonConductedLectureCount++;
          lec.lectureType = 'Non-Conducted';
        }

        if (lec.status === 6) {
          this.cancelLectureCount++;
          lec.lectureType = 'Cancelled';
        }

        if (lec.status === 7) {
          this.countForCancelledAndRescheduled++;
          lec.lectureType = 'Cancelled and Rescheduled';
        }
        
        if (lec.groupForSubject && lec.groupForSubject?.length > 0) {
          this.isGroupedLecture++;
          const objectKey = lec.courseYear + '_' + lec.groupForSubject[0].groupName
          if ((Object.keys(this.groupNameCountObject)).includes(objectKey)) {
            this.groupNameCountObject[objectKey] = this.groupNameCountObject[objectKey] + 1
          } else {
            this.groupNameCountObject[objectKey] = 1
          }
        }
        if (lec.division && lec.division !== '' && lec.batch === '') {
          this.isDivisionLecture++
        }
        if (lec.batch && lec.batch !== '') {
          this.isBatchLecture++
          const objectKey = lec.courseYear + '_' + lec.division + '_' + 'Batch ' + lec.batch
          if ((Object.keys(this.batchNameCountObject)).includes(objectKey)) {
            this.batchNameCountObject[objectKey] = this.batchNameCountObject[objectKey] + 1
          } else {
            this.batchNameCountObject[objectKey] = 1
          }
        }
        if (lec.division && lec.division !== '') {
          const objectKey = lec.courseYear + '_' + lec.division
          if ((Object.keys(this.divisionNameCountObject)).includes(objectKey)) {
            this.divisionNameCountObject[objectKey] = this.divisionNameCountObject[objectKey] + 1
          } else {
            this.divisionNameCountObject[objectKey] = 1
          }
        }

      })
     
      this.chartSeries = [{ data: [this.isDivisionLecture, this.isBatchLecture, this.isGroupedLecture] }]
      this.pieSeries = [this.conductedLectureCount, this.countForUpcomingLecture, this.nonConductedLectureCount, this.countForCancelledAndRescheduled, this.cancelLectureCount]
      this.allLectureForSelectedDate.sort(this.compareUTCDateStrings)
      this.keyValuePairForDivision = Object.entries(this.divisionNameCountObject)
      this.keyValuePairForBatch = Object.entries(this.batchNameCountObject)
      this.keyValuePairForGroup = Object.entries(this.groupNameCountObject)
    },
    getFormattedTime(time) {
      return timeStringConverter(time);
    },

    convertToYYYYMMDD(dateString) {
      return this.convertToDDMMYYYY(dateString).split("-").reverse().join("-");
    },
    convertToDDMMYYYY(dateString) {
      return convertDate(dateString);
    },
    convertToDateObject(dateString) {
      return convertFromYYYYMMDDToDate(dateString);
    },
    convertToISOString(date) {
      const convertedDate = new Date(date.valueOf());
      convertedDate.setHours(5, 30, 0, 0);
      return convertedDate.toISOString();
    },
    canMarkAttendance(item) {
      if (item.afterUtc !== true) {
        const currentDate = new Date();
        let adjustForOffset = 0;
        if (convertTime(item.dateTime) !== item.startTime) {
          adjustForOffset = 19800000; // 6 * 60 * 60 * 1000 - 30 * 60 * 1000 (5:30 hours)
        }
        const parsedDateTimeOfLecture = new Date(
          new Date(item.dateTime).valueOf() - adjustForOffset
        );
        // return true
        return (
          parsedDateTimeOfLecture <= currentDate &&
          [
            lectureTypes.ORDINARY,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(item.status)
        );
      } else {
        const currentTime = new Date();
        const endTime = new Date(new Date(item.start))
        // return true
        return (
          endTime <= currentTime &&
          [
            lectureTypes.ORDINARY,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(item.status)
        );
      }
    },
    goToRecordingLink(link) {
      if (link) {
        this.mp4Link = link.recordingLinkMp4
      }
    },
    convertUTCtoLocalTimeAMPM(utcDateString) {
      const utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const result = formattedHours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
      return result;
    },
    gotoTakeAttendance(lecture) {
      let obj = {
        semName: this.selectedSem,
        department: this.selectedDept,
        courseYear: this.selectedCourseYear,
        subject: this.selectedSubject,
        specificDate: this.tempCustomDate,
        startDate: this.tempStartDate,
        endDate: this.tempEndDate,
        type: this.selectedTypeForChange,
        contact: this.selectedContact,
        divParameter: this.selectedTypeForDiv,
        combination: this.selectedCombination,
      }
      this.$router.push({
        name: "activityTakeAttendance",
        params: {
          prop_lecture: lecture,
          prop_dashboard: obj,
        }
      });
    },
    async getLectureDetailsToShow(data) {
      let numberOfCountDummy = 0
      this.countOfManualPresent = 0
      this.firebaseData = []
      this.uniqueUserFromFireBase = []
      this.showLectureDetails = true
      this.lectureDetailsToShow = data
      if (data.isConducted && data.recordingLink !== '') {
        this.lectureStatusLine = '(Lecture Conducted, Recording Available)'
      }
      if (data.isConducted && data.recordingLink === '') {
        this.lectureStatusLine = '(Lecture Conducted, Recording not Available)'
      }
      if (!data.isConducted && data.recordingLink === '') {
        this.lectureStatusLine = '(Lecture not Conducted, Recording not Available)'
      }
      if (this.lectureDetailsToShow.isConducted === true) {
        try {
          let infoFireBase = await auth.findDocumentForQrAttendance(this.selectedInstitute, this.lectureDetailsToShow.lectureId)
          this.firebaseData = infoFireBase
          let presentCount = 0
          if(this.firebaseData){
            if (this.firebaseData?.length > 0) {
              this.firebaseData.map((data) => {
                if (!this.uniqueUserFromFireBase.includes(data.uId) && data.status === 1) {
                  this.uniqueUserFromFireBase.push(data.uId)
                  presentCount++;
                }
              })
            }
          }
          this.countOfManualPresent = this.lectureDetailsToShow?.present?.length - presentCount
        } catch (error) {
          console.error('error', error);
        }
      }
    },
    calculateTimeDurationUTC(startTimeUTC, endTimeUTC) {
      // Parse the UTC datetime strings into Date objects
      const startUTC = new Date(startTimeUTC);
      const endUTC = new Date(endTimeUTC);

      // Calculate the time difference in milliseconds
      const durationMs = endUTC - startUTC;

      // Calculate hours and minutes
      const hours = Math.floor(durationMs / (60 * 60 * 1000));
      const minutes = Math.floor((durationMs % (60 * 60 * 1000)) / (60 * 1000));

      return `${hours} hours ${minutes} minutes`;
    },
    compareUTCDateStrings(a, b) {
      const dateA = new Date(a.dateTime);
      const dateB = new Date(b.dateTime);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    },
    openExpansion(e) {
      // if (
      //   !e.currentTarget.classList.contains("v-expansion-panel-header--active")
      // )
    },
    dateRangeChange() {
      this.tempEndDate = ''
      this.tempStartDate = ''
      this.tempCustomDate = ''
      if (this.dateRangeSelect === 'Today') {
        this.getLectures()
      }
    },
    findLectForParticularDate() {

    },
    allFilterChanges() {

      // let dummyArr =  this.allLectureForSelectedDate
      let dummy = []
      let dummy2 = []
      if (this.selectedTypeForDiv === '') {
        if (this.selectedTypeForChange !== '') {

          if (this.selectedTypeForChange === 'Conducted') {
            this.allLectureForSelectedDate2.map((data) => {
              if (data.isConducted === true) {
                dummy.push(data)
              }
            })
          }
          if (this.selectedTypeForChange === 'Non conducted') {
            this.allLectureForSelectedDate2.map((data) => {
              if (data.isConducted === false) {
                dummy.push(data)
              }
            })
          }
          if (this.selectedTypeForChange === 'Cancelled') {
            this.allLectureForSelectedDate2.map((data) => {
              if (data.status === 6) {
                dummy.push(data)
              }
            })
          }
          if (this.selectedTypeForChange === 'All') {
            dummy = this.allLectureForSelectedDate2
          }
          if (this.selectedContact !== '') {
            if (this.selectedContact === 'Lectures') {
              dummy.map((data) => {
                if (data.subjectType === 0 || data.subjectType === 4) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            if (this.selectedContact === 'Practicals') {
              dummy.map((data) => {
                if (data.subjectType === 1 || data.subjectType === 5) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            this.allLectureForSelectedDate = dummy2
            if (this.selectedContact === 'Both') {
              this.allLectureForSelectedDate = dummy
            }
          } else {
            this.allLectureForSelectedDate = dummy
          }
        } else {
          if (this.selectedContact !== '') {
            if (this.selectedContact === 'Lectures') {
              this.allLectureForSelectedDate2.map((data) => {
                if (data.subjectType === 0 || data.subjectType === 4) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            if (this.selectedContact === 'Practicals') {
              this.allLectureForSelectedDate2.map((data) => {
                if (data.subjectType === 1 || data.subjectType === 5) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            this.allLectureForSelectedDate = dummy2
            if (this.selectedContact === 'Both') {
              this.allLectureForSelectedDate = this.allLectureForSelectedDate2
            }
          } else {

          }
        }
      } else {
        if (this.selectedTypeForChange !== '') {
          if (this.selectedTypeForChange === 'Conducted') {
            this.filterLecturedByDivChange.map((data) => {
              if (data.isConducted === true) {
                dummy.push(data)
              }
            })
          }
          if (this.selectedTypeForChange === 'Non conducted') {
            this.filterLecturedByDivChange.map((data) => {
              if (data.isConducted === false) {
                dummy.push(data)
              }
            })
          }
          if (this.selectedTypeForChange === 'Cancelled') {
            this.filterLecturedByDivChange.map((data) => {
              if (data.status === 6) {
                dummy.push(data)
              }
            })
          }
          if (this.selectedTypeForChange === 'All') {
            dummy = this.filterLecturedByDivChange

          }
          if (this.selectedContact !== '') {
            if (this.selectedContact === 'Lectures') {
              dummy.map((data) => {
                if (data.subjectType === 0 || data.subjectType === 4) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            if (this.selectedContact === 'Practicals') {
              dummy.map((data) => {
                if (data.subjectType === 1 || data.subjectType === 5) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            // this.allLectureForSelectedDate = dummy2
            if (this.selectedContact === 'Both') {
              this.allLectureForSelectedDate = dummy
            }
          } else {
            this.allLectureForSelectedDate = dummy
          }
        } else {
          if (this.selectedContact !== '') {
            if (this.selectedContact === 'Lectures') {
              this.filterLecturedByDivChange.map((data) => {
                if (data.subjectType === 0 || data.subjectType === 4) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            if (this.selectedContact === 'Practicals') {
              this.filterLecturedByDivChange.map((data) => {
                if (data.subjectType === 1 || data.subjectType === 5) {
                  dummy2.push(data)
                }
              })
              this.allLectureForSelectedDate = dummy2
            }
            // this.allLectureForSelectedDate = dummy2
            if (this.selectedContact === 'Both') {
              this.allLectureForSelectedDate = this.filterLecturedByDivChange
            }
          } else {

          }
        }
      }
      this.calculateGraphRelatedValues()
    },
    panelClick() {
      this.panelOn = !this.panelOn
    },
    divTypeChange() {
      this.allDivCombinationsArr = []
      this.dummyArrForFilter = []
      this.selectedCombination = ''
      this.allDivCombinationsArr.push('All')
      this.semestersAllData.map((sem) => {
        if (sem.semName === this.selectedSem && sem.department === this.selectedDept && sem.courseYear === this.selectedCourseYear) {
          if (this.selectedTypeForDiv === 'Divisions') {
            this.allDivCombinationsArr.push(sem.division)
          }
          if (this.selectedTypeForDiv === 'Batchwise') {
            if (sem.numberOfBatches > 0) {
              for (let i = 0; i < sem.numberOfBatches; i++) {
                this.allDivCombinationsArr.push('Div_' + sem.division + '_' + 'Batch_' + (i + 1))
              }
            }
          }
          if (this.selectedTypeForDiv === 'Groupwise') {
            this.allSubjectData.map((sub) => {
              if (sub.groupForSubject?.length > 0) {
                sub.groupForSubject.map((grp) => {
                  this.allDivCombinationsArr.push(sub.subjectName + '_' + grp.groupName)
                })

              }
            })
          }
          if (this.selectedTypeForDiv === 'All') {
            this.selectedCombination = ''
          }
        }
      })
    },
    divCombinationChanged() {
      this.allLectureForSelectedDate = []
      this.dummyArrForFilter = []
      if (this.selectedCombination !== 'All') {
        if (this.selectedTypeForDiv === 'Divisions') {
          let dummy = []
          this.allLectureForSelectedDate2.map((lec) => {
            if (this.semIdForSelectedSem === lec.semId && lec.department === this.selectedDept && lec.courseYear === this.selectedCourseYear && (lec.division === this.selectedCombination || lec.multiDivision.includes(this.selectedCombination))) {
              this.allLectureForSelectedDate.push(lec)
            }
          })
        }
        if (this.selectedTypeForDiv === 'Batchwise') {
          let [a, b, c, d] = this.selectedCombination.split('_')
          let dummy = []
          this.allLectureForSelectedDate2.map((lec) => {
            if (this.semIdForSelectedSem === lec.semId && lec.department === this.selectedDept && lec.courseYear === this.selectedCourseYear && lec.division === b && lec.batch === d) {
              this.allLectureForSelectedDate.push(lec)
            }

          })

        }
        if (this.selectedTypeForDiv === 'Groupwise') {
          let [a, b] = this.selectedCombination.split('_')

          let dummy = []
          this.allLectureForSelectedDate2.map((lec) => {
            if (this.semIdForSelectedSem === lec.semId && lec.department === this.selectedDept && lec.courseYear === this.selectedCourseYear) {
              if (lec.groupForSubject && lec.groupForSubject?.length > 0 && lec.groupForSubject[0].groupName === b) {
                this.allLectureForSelectedDate.push(lec)
              }
            }

          })

        }
      } else {
        if (this.selectedTypeForDiv === 'Divisions') {
          this.allLectureForSelectedDate2.map((data) => {
            if (data.division && data.division !== '') {
              this.allLectureForSelectedDate.push(data)
            }
          })
        }
        if (this.selectedTypeForDiv === 'Batchwise') {
          this.allLectureForSelectedDate2.map((data) => {
            if (data.batch && data.batch !== '') {
              this.allLectureForSelectedDate.push(data)
            }
          })
        }
        if (this.selectedTypeForDiv === 'Groupwise') {
          this.allLectureForSelectedDate2.map((data) => {
            if (data.groupForSubject && data.groupForSubject?.length > 0) {
              this.allLectureForSelectedDate.push(data)
            }
          })
        }
      }
      this.filterLecturedByDivChange = []
      this.filterLecturedByDivChange = this.allLectureForSelectedDate
      if (this.selectedTypeForChange !== '' || this.selectedContact !== '') {
        this.allFilterChanges()
      }
      this.calculateGraphRelatedValues()

    },
    async clearCustomDate() {
      this.tempStartDate = ''
      this.tempEndDate = ''
      this.clearAllExternalFilters()
      await this.getLectures()
      this.calculateGraphRelatedValues()

    },
    async clearSpecificDate() {
      this.tempCustomDate = ''
      this.clearAllExternalFilters()
      await this.getLectures()
      this.calculateGraphRelatedValues()

    },
    clearAllExternalFilters() {
      this.dateRangeSelect = 'Today'
      this.selectedContact = ''
      this.selectedTypeForChange = ''
      this.selectedCombination = ''
      this.selectedTypeForDiv = ''
      this.tempEndDate = ''
      this.tempStartDate = ''
      this.tempCustomDate = ''
    },

    clearContact() {
      this.selectedContact = ''
      if (this.selectedTypeForChange === '') {
        this.allLectureForSelectedDate = this.allLectureForSelectedDate2
      }
      this.allFilterChanges()
    },
    clearTypeChange() {
      this.selectedTypeForChange = ''
      if (this.selectedContact === '') {
        this.allLectureForSelectedDate = this.allLectureForSelectedDate2
      }
      this.allFilterChanges()
    },
    clearCombination() {
      this.selectedCombination = ''
      this.selectedTypeForDiv = ''
      this.allLectureForSelectedDate = this.allLectureForSelectedDate2
      this.calculateGraphRelatedValues()
    },
    async cancelLecture() {
      this.cancelLoading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.semIdForSelectedSem,
          lectureId: this.selectedLecture.lectureId,
          reason: this.reasonForCancellingLecture
        };
        let data = await this.lectureRepositoryInstance.cancelALecture(objToPush);
        await this.getLectures()
        if (this.this.selectedCombination !== '') {
          this.divCombinationChanged()
        } else {
          this.allFilterChanges()
        }
        this.cancelLoading = false
        this.showCancellingDialog = false

      } catch (error) {
        console.error('error', error);
        this.cancelLoading = false
        this.showCancellingDialog = false

      }
    },
    async deleteLecture() {
      this.cancelLoading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.semIdForSelectedSem,
          lectureId: this.selectedLecture.lectureId,
        };
        let data = await this.lectureRepositoryInstance.deleteLecture(objToPush);
        const activityLoggerPayloadArr = [];

        activityLoggerPayloadArr.push({
          instituteId: this.selectedInstitute,
          semId: this.selectedLecture.semId,
          department: this.selectedLecture.department,
          courseYear: this.selectedLecture.courseYear,
          division: this.selectedLecture.division,
          subjectId: this.selectedLecture.subjectId,
          subjectName: this.selectedLecture.subjectName,
          facultyUid: this.selectedLecture.uId,
          type: "lectureDashboard",
          lectureStartTime: this.selectedLecture.startTime,
          lectureEndTime: this.selectedLecture.endTime,
          lectureId: this.selectedLecture.lectureId,
          savedAt: new Date().toISOString(),
          savedBy: this.userData.firstName,
          actionType: "Delete"
        })

        await this.activityLoggerInstance.createMultipleLogs({
          logsArray: activityLoggerPayloadArr
        });
        await this.getLectures()
        if (this.this.selectedCombination !== '') {
          this.divCombinationChanged()
        } else {
          this.allFilterChanges()
        }
        this.cancelLoading = false
        this.showDeleteDialog = false

      } catch (error) {
        console.error('error', error);
        this.cancelLoading = false
        this.showDeleteDialog = false


      }
    },
    canCancelDialogOpen(item) {
      this.showCancellingDialog = true
      this.reasonForCancellingLecture = ''
      this.selectedLecture = item
    },
    deleteLecDialogOpen(item) {
      this.showDeleteDialog = true
      this.selectedLecture = item
    },
   async createLectureClick(i) {
      if (i === 0) {
        this.$router.push({
          name: "activityArrangeLecture",
          params: {
            prop_InstituteId: this.instituteId,
            prop_SemId: this.semIdForSelectedSem,
            prop_Uid: this.userData.uId,
          },
        });
      }

      if (i === 1) {
        this.$router.push({
          name: "activityArrangeLectureBySubject",
          params: {
            prop_InstituteId: this.instituteId,
            prop_SemId: this.semIdForSelectedSem,
            prop_Uid: this.userData.uId,
            prop_selectedDate: [],
          },
        });
      }
      if (i === 2) {
        this.$router.push({
          name: "activityArrangeLectureByValueAdded",
          params: {
            prop_InstituteId: this.instituteId,
            prop_SemId: this.semIdForSelectedSem,
            prop_Uid: this.userData.uId,
            prop_selectedDate: [],
          },
        });
      }
    },
    async downloadExcelReport() {
      let lecArr = this.allLectureForSelectedDate
      lecArr.map((data) => {
        data.dateTime = this.convertToDDMMYYYY(data.dateTime)
        data.startTime = this.convertUTCtoLocalTimeAMPM(data.startTime)
        data.endTime = this.convertUTCtoLocalTimeAMPM(data.endTime)
      })
      let obj = {
        semName: this.selectedSem,
        department: this.selectedDept,
        courseYear: this.selectedCourseYear,
        subject: this.selectedSubject,
        lectures: lecArr,
        totalLecture: this.countForLectures,
        totalPractical: this.countForPractical,
        totalConducted: this.conductedLectureCount,
        totalNonConducted: this.allLectureForSelectedDate?.length - (this.conductedLectureCount + this.cancelLectureCount),
        totalCancelled: this.cancelLectureCount,
        specificDate: this.tempCustomDate,
        startDate: this.tempStartDate,
        endDate: this.tempEndDate,
        type: this.selectedTypeForChange,
        contact: this.selectedContact,
        divParameter: this.selectedTypeForDiv,
        combination: this.selectedCombination,
        userName: this.userData.firstName,
        reportDate: this.convertToDDMMYYYY(this.todayDate)
      }
     

      try {
        const pdfBlob =
          await this.lectureRepositoryInstance.generateLectureDashboardExcel(
            obj
          )
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `LectureDashboard.xlsx`)
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    downloadVideo(link) {
      if (link) {
        // You can use methods like window.open() to download the video
        window.open(link, '_blank');
      }
    },


  },
};
</script>

<style  src="./activityDashboard.css"></style>
