import * as XLSX from "xlsx/xlsx.mjs";
export function createExcelThroughJson(data) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Add a new worksheet to the workbook
  const ws = XLSX.utils.json_to_sheet(data);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  // Generate the Excel file and download it
  XLSX.writeFile(wb, "table_Data.xlsx");
}
