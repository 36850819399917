<template src="./activityControlAccess.html"></template>
<script>
import UserAuthenticationRepository from "../../Repository/UserAuthentication";
import AcademicReferenceRepository from "../../Repository/AcademicReference";
import { allowedModule, allowedModuleDetails, instituteCardsInfo, AcademicManagement } from "../../Services/Utils/allowedModule";

export default {
  name: 'activityControlAccess',
  data() {
    return {
      selectControlAccess: '',
      selectedCourseYearForSubAdmin: '',
      allCourseYearOfDepartment: [],
      allDepartmentOfInstitute: [],
      selectedDepartmentForSubAdmin: [],
      selectedItemForDialog: {},
      isDepartmentAssign: '',
      selectControlAccess: [],
      selectedModule: [],
      subAdminDialog: false,
      selectedInstitute: '',
      searchValue: '',
      modulesName: [],
      selectedSubModuleArr: [],
      subAdminData: [],
      // dummy: ['My Timeline', 'Institute Management', 'Academic Management', 'Manage Users', 'Lectures Dashboard', 'Manage Exams', 'Attendance Analysis', 'Cumulative Attendance', 'Feedback Analysis', 'Syllabus Tracker', 'Manage assignments', 'Manage Study Material', 'Admission Module', 'Search', 'Manage Quick Test', 'Feedback Form', 'Courses', 'Media Library'],
      dummy: [],
      subAdminHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Control Access', value: 'access' },

      ],
      dataLoader: false
    }
  },

  created() {
    this.dummy = allowedModuleDetails  //this module name taken from utility file 
    // this.$store.commit('liveData/set_selectedActivityName', 'Access Control')
    this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this);
    this.AcademicReferenceRepositoryInstance = new AcademicReferenceRepository(this);
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.allowedModuleLiveData = this.$store.getters['liveData/get_allowedModule'] //subadmin assigned module
    // this.isMobileView = this.$store.getters["liveData/get_allowedModule"];
    this.fetchData()
    // 5d22724a-a9f5-4f6b-9e1f-dec36dcf0a4f
  },

  methods: {
    async departmentChange() {
      let dummyCourseYear = []
      if (this.selectedDepartmentForSubAdmin.length !== 0) {
        if (this.selectedCourseYearForSubAdmin.length > 0) {
          this.selectedCourseYearForSubAdmin.map((cy) => {
            let [course,department] = cy.split('_')
              this.selectedDepartmentForSubAdmin.map((data) => {
              if (department === data) {
                dummyCourseYear.push(cy)
              }
            })
          })
        }
        this.selectedCourseYearForSubAdmin = dummyCourseYear

        try {
      const data = await this.AcademicReferenceRepositoryInstance.getCourseYearsForDepartments({
            instituteId: this.selectedInstitute,
            department: this.selectedDepartmentForSubAdmin
          })
          
          this.allCourseYearOfDepartment = []
          if (data.length > 0) {
            data.map((info) => {
              this.allCourseYearOfDepartment.push(info.courseYear+'_'+info.department)
            })
          }
        } catch (error) {
          console.error('error', error);
        }
      }else {
        this.selectedCourseYearForSubAdmin = []
      }
    },
    toggleAccess(info, i) {

    },
    toggleSubAccess(mod, sub, i, j) {
      if (sub.isAccessForSub === true) {
        this.selectedModule[i].isAccess = true
      }

    },
    async fetchData() {
      this.dataLoader = true
      try {
        this.subAdminData = []
        this.subAdminData = await this.UserAuthenticationRepositoryInstance.getAllSubAdminOfInstitute({
          instituteId: this.selectedInstitute
        })
        this.allDepartmentOfInstitute = await this.AcademicReferenceRepositoryInstance.getDepartments({
          instituteId: this.selectedInstitute
        })

      } catch (error) {
        console.log(error)
      }
      this.dataLoader = false
    },
  async  openAccessControlDialog(item, index) {
      this.selectedItemForDialog = item
      this.selectedDepartmentForSubAdmin = []
      if (item.assignedDepartmentForSubAdmin && item.assignedDepartmentForSubAdmin.length > 0) {

        this.selectedDepartmentForSubAdmin = item.assignedDepartmentForSubAdmin
      }
      this.selectedCourseYearForSubAdmin = []
      if (item.assignedCourseForSubAdmin && item.assignedCourseForSubAdmin.length > 0) {

        this.selectedCourseYearForSubAdmin = item.assignedCourseForSubAdmin
      }
      if (item.allowedModule && item.allowedModule.length !== 0) {
        item.allowedModule.map((aItem) => {
          if(aItem.id === 5) {
            if(aItem.subModule.length === 0) {
              aItem.subModule = this.dummy.filter(dItem => dItem.id === 5)[0].subModule
            }
          }
        })
        for (let i = 0; i < this.dummy.length; i++) {
          let dummyId = this.dummy[i].id;
          let found = false;
          for (let j = 0; j < item.allowedModule.length; j++) {
            let itemId = item.allowedModule[j].id;
            if (dummyId === itemId) {
              found = true;
              // Check and add submodules
              for (let k = 0; k < this.dummy[i].subModule.length; k++) {
                let subId = this.dummy[i].subModule[k].subId;
                let subFound = false;
                for (let l = 0; l < item.allowedModule[j].subModule.length; l++) {
                  if (item.allowedModule[j].subModule[l].subId === subId) {
                    subFound = true;
                    break;
                  }
                }
                if (!subFound) {
                  item.allowedModule[j].subModule.push({
                    subId: subId,
                    isAccessForSub: false,
                  });
                }
              }
              break;
            }
          }
          if (!found) {
            item.allowedModule.push({
              id: dummyId,
              isAccess: false,
              subModule: this.dummy[i].subModule.map(subModule => ({
                subId: subModule.subId,
                isAccessForSub: false,
              })),
            });
          }
        }
        this.selectedModule = item.allowedModule
      } else {
        this.selectedModule = this.dummy
      }
      this.selectedModule.map((data, i) => {
        data.name = allowedModule[i]
        
        if (i === 1) {
          if (data.subModule.length > 0) {
            data.subModule.map((sub, j) => {
              sub.subName = instituteCardsInfo[j].cardTitle
            })
          }
        }
        if (i === 2) {
          if (data.subModule.length > 0) {
            data.subModule.map((sub, j) => {
              sub.subName = AcademicManagement[j].cardTitle
            })
          }
        }
        data.name = allowedModule[data.id]
      })
      this.subAdminDialog = true
      this.selectedSubModuleArr = []
      this.selectedSubModuleArr = item.allowedModule ? item.allowedModule : []
      await this.departmentChange()
    },

    async saveAccessControlModule() {
      delete this.selectedModule.name;
      this.subAdminDialog = false
      try {
        let res = await this.UserAuthenticationRepositoryInstance.updateUserAuthentication({
          uId: this.selectedItemForDialog.uId,
          allowedModule: this.selectedModule,
          assignedDepartmentForSubAdmin: this.selectedDepartmentForSubAdmin,
          assignedCourseForSubAdmin: this.selectedCourseYearForSubAdmin,
        })
        await this.fetchData()
      } catch (error) {
        console.log(error)
      }
      this.subAdminDialog = false
    }

  }
}
</script>
<style scoped src="./activityControlAccess.css"></style>
