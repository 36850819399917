import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ExamTimetable'

class ExamTimetable {
  constructor (context) {
    this.context = context
  }

  async createExamTimetable (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.createExamTimetable, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getExamTimetableForAnExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamTimetableForAnExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examTimetables
  }

  async getExamTimetableForAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamTimetableForAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examTimetables
  }

  async getExamTimetableForAFacultyForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamTimetableForAFacultyForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examTimetables
  }

  async getExamTimetableForALocation (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamTimetableForALocation, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examTimetables
  }

  async getExamTimetableForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamTimetableForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examTimetables
  }

  async getExamTimetableForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamTimetableForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateExamTimetableForALocation (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateExamTimetableForALocation, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateExamTimetableForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.updateExamTimetableForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteExamTimetableForALocation (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.deleteExamTimetableForALocation, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteExamTimetableForAnExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.deleteExamTimetableForAnExam, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteExamTimetableForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.deleteExamTimetableForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default ExamTimetable
