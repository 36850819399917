<template src="./activityEditSemesterTimetable.html"></template>
<script>
import inputContainer from "../../Components/inputContainer";
import showStatus from "../../NetworkManager/showStatus";
import UserRepository from "../../Repository/User";
import SubjectRepository from "../../Repository/Subject";
import InstituteRepository from "../../Repository/Institute";
import TopicRepository from "../../Repository/Topic";
import SemesterRepository from "../../Repository/Semester";
import TimetableRepository from "../../Repository/Timetable";
import ShiftRepository from "../../Repository/ShiftReference";
import SemesterUserRepository from "../../Repository/SemesterUser";
import departmentDetailsRepository from "../../Repository/DepartmentDetails";
import InfrastructureReferenceRepository from "../../Repository/InfrastructureReference";
import { filterAccessedDepartmentForSubAdmin, filterAccessedCourseYearForSubAdmin } from "../../utils/subAdminAccessControl";
import LecturesRepository from "../../Repository/Lecture";
import { timeStringConverter, convertLocalToUTC, convertDate } from "../../Services/Utils/DateTimeFormatter";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import { subjectTypes } from "../../Constants/Utils/Statics";
import { eachDayOfInterval, format, addDays } from "date-fns";
import { generateJsonFromExcel, generateFromJson, createExcelThroughJson } from "../../utils/excel"
import VueApexCharts from "vue-apexcharts";
import { Radar } from 'vue-chartjs';

import axios from 'axios'
import { v4 as uuidv4 } from "uuid";
import html2pdf from 'html2pdf.js'
import auth from '../../Services/Firebase/auth'


export default {
  name: "activityEditSemesterTimetable",
  components: {
    inputContainer,
    apexcharts: VueApexCharts,


  },



  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_selectedDivision",
    "tree",
  ],

  data() {
    return {
      nomsForFaculty: 0,
      allDeptOfInstituteLevel: [],
      selectedSecondarySubject: '',
      allMonthData: [],
      allSemDataForInsights: [],
      semStartDateForInsights: '',
      semEndDateForInsights: '',
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          type: 'logarithmic', // Set y-axis scale to logarithmic
          logarithmicBase: 10, // Base of the logarithmic scale (default is 10)
          min: 1, // Minimum value for the logarithmic scale (adjust as needed)
          max: 100, // Maximum value for the logarithmic scale (adjust as needed)
          tickAmount: 10 // Adjust the number of ticks as needed
        },
      },
      series: [{
        name: 'Month Wise Teaching load analysis',
        data: []
      }],


      allSubjectForLec: [],
      seriesForDivision: [{
        name: 'Assigned',
        data: []
      }, {
        name: 'Conducted',
        data: []
      }
      ],
      divisionChart: {
        chart: {
          type: 'bar',
          height: 350,

        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',

          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Hrs'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: (val, { series, seriesIndex, dataPointIndex }) => this.customTooltipFormatterDivision(val, series, seriesIndex, dataPointIndex)
          }
        },
      },
      seriesForSubject: [{
        name: 'Assigned',
        data: []
      }, {
        name: 'Conducted',
        data: []
      },],
      subjectChart: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            // colors: ['#769205', '#760592']

          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Hrs'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: (val, { series, seriesIndex, dataPointIndex }) => this.customTooltipFormatter(val, series, seriesIndex, dataPointIndex)
          }
        },
      },
      semStatDateAndEndDate: [],
      objForDeptAndCy: {},
      objForAllocatedSubject: {},
      objForAllocatedDivision: {},
      objForAllocatedMonth: {},
      academicHrDetails: {
        norms: 0,
        assigned: 0,
        conducted: 0,
      },
      semHrDetails: {
        norms: 0,
        assigned: 0,
        conducted: 0,
      },
      monthHrDetails: {
        norms: 0,
        assigned: 0,
        conducted: 0,
      },
      weekHrDetails: {
        norms: 0,
        assigned: 0,
        conducted: 0,
      },
      dateHrDetails: {
        norms: 0,
        assigned: 0,
        conducted: 0,
      },
      semNameForInsights: '',
      selectedInsights: 'Faculty Load',
      selectedView: 'Master',
      selectedLoadType: 'TeachingLoad',
      selectedFacultyType: '',
      selectedFacultyForInsights: '',
      selectedDepartmentForFaculty: '',
      selectedCyForFaculty: 'All',
      selectedSubjectForFaculty: 'All',
      selectedTypeOfDiv: 'All',
      selectedDivisionForFaculty: 'All',
      selectedBatchForFaculty: '',
      selectedGroupForFaculty: '',
      allBatchForFaculty: [],
      semNameArrForInsights: [],
      allGroupForFaculty: [],
      allDivisionOfFaculty: ['All'],
      allSubjectForFaculty: ['All'],
      allDeptForFaculty: [],
      allCyForFaculty: ['All'],
      typeForDivBatch: ['All'],
      allFacultyType: [],
      allFacultyForDesignation: [],
      allInsightsView: ['Faculty Load'],
      allView: ['Master', 'Real Time'],
      allLoadType: ['TeachingLoad', 'NonTeaching', 'OtherLoad'],
      headersForSpecificDate: [
        { text: 'Date', value: 'date', width: '150px' },
        { text: 'Subject', value: 'subject', width: '200px' },
        { text: 'Day', value: 'day', width: '150px' },
        { text: 'Time', value: 'time', width: '200px', sortable: false },
        { text: 'Topic', value: 'topic', width: '200px' },
        { text: 'Faculty', value: 'faculty', width: '200px', sortable: false },
        { text: 'Mode of conduction', value: 'mode', width: '200px', sortable: false },
        { text: 'Room', value: 'room', width: '200px', sortable: false },
        { text: '', value: 'delete', width: '50px', sortable: false },
      ],
      isCancelOnLec: false,
      deleteManyDialog: false,
      focus: '',
      lecArrForEditInBulk: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      facultiesLecObject: {},
      isForOneDay: false,
      clashIgnoreFlag: false,
      flagForClashEdit: false,
      eventSlotHeight: 30,
      timeSlotHeight: 90,
      sub: {},
      groupInfo: {},
      selectedSubjectId: '',
      events2: [],
      allSubjectsNames: [],
      allSubjectsNames2: [],
      isCalenderShow: false,
      allDepartmentNames: '',
      isEditOnLec: false,
      isNew: true,
      deleteLecDialog: false,
      progressBar: false,
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {
        dateTime: '',
        day: '',
        fullName: '',
        startTime: '',
        endTime: '',
        isOnlineLecture: '',
        roomName: '',
        department: '',
        courseYear: '',
        division: '',
        batch: '',
        timetableId: '',
        reason: '',
        groupForSubject: [{
          groupName: ''
        }]
      },
      selectedElement: null,
      flagForBulkEditDataFilled: null,
      flagForCalender: false,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Lecture', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      modeOFConduction: ['Online', 'Offline'],
      flagForLec: false,
      applyAllFaculty: false,
      applyAllMode: false,
      applyAllRoom: false,
      applyAllTime: false,
      selectedDaysForLec: [],
      allLecData: [],
      selectedDepartmentForLec: '',
      selectedCyForLec: '',
      selectedSubjectForLec: '',
      selectedTypeForLec: '',
      selectedDivisionForLec: '',
      selectedBatchForLec: '',
      selectedGroupForLec: '',
      cyNamesArrForLec: [],
      allSubjectsForLec: [],
      divisionNameArrForLec: [],
      batchNameArrForLec: [],
      groupNameArrForLec: [],
      isMultiple: false,
      intervalCount: 0,
      flagForTypeSelection: false,
      createForTiming: '',
      multiDateSelection: [],
      arrForSpecificDateLec: [],
      semStartDate: '',
      semEndDate: '',
      startDateForTimeFrame: '',
      facultiesNameWithUid: [],
      selectedRoomForLect: '',
      typeForLec: '',
      selectedFacultyForLec: '',
      allFacultyForSem: [],
      selectedTopicForLec: '',
      selectedSlotsForLec: [],
      slotsForShift: [],
      selectedShiftForLec: '',
      singleDateSelection: '',
      typeForDateForEvent: '',
      endDateForTimeFrame: '',
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      selectedDivision: [],
      createEventDialog: false,
      allTopicOfSelectedSubject: [],
      allRoomsDept: [],
      groupNameArr: [],
      selectedGroup: [],
      selectedBatch: [],
      batchNameArr: [],
      divisionNameArr: [],
      selectedDepartment: [],
      selectedType: '',
      typeNameArr: ['Division', 'Batch', 'Group'],
      allSubjects: [],
      selectedSubject: [],
      selectedCy: [],
      cyNamesArr: [],
      allSemData: [],
      facultyNotAssignedFlag: false,
      facultyNotAssignedLect: [],
      selectedTimeTableView: 'Master Timetable',
      selectedUser: 'Student',
      selectedTypeOfEvent: 'Lecture',
      timeTableViewArr: ['Master Timetable', 'RealTime'],
      userArr: ['Student'],
      eventArr: ['Lecture'],
      userData: {},
      panel: [0, 1],
      selectedInstitute: '',
      selectedInstituteName: '',
      selectedSemester: {},
      mainTabNames: ['Timeslots', 'Timetable', 'Insights'],
      tabs: 0,
      createShiftDialog: false,
      creteShiftTitle: '',
      selectedWeekDaysForCrete: [],
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      arrForTimeSlotCreate: [],
      arrForTimeSlotCreateBreak: [],
      errorFlag: false,
      allShift: false,
      headersForShift: [
        {
          text: 'Shift Name',
          value: 'shiftName',
          width: '150px'
        },
        {
          text: 'Working Days',
          value: 'workingDays',
          sortable: false
        },
        {
          text: 'Time Slots',
          value: 'timeSlots',
          sortable: false
        },

      ],
      deleteShiftDialog: false,
      bulkEditModeOn: false,
      switchForTable: false,
      errorFlagEdit: false,
      deleteShiftData: {},
      editShiftDialog: false,
      deleteMultipleLectArr: [],
      headersForTableView: [
        { text: "", value: "dot", width: "110px" },
        { text: "", value: "criteria", width: "150px" },
        { text: "Batch", value: "batch", width: "110px" },
        { text: "Group", value: "group", width: "110px" },
        { text: 'Date', value: 'dateTime', width: '150px', sortable: true },
        { text: 'Day', value: 'weekDay', width: '80px', sortable: true },
        { text: 'Time', value: 'time', width: '200px', sortable: false },
        { text: 'Subject', value: 'subjectName', width: '200px' },
        { text: 'Faculty', value: 'fullName', width: '200px' },
        { text: 'Mode of conduction', value: 'mode', width: '200px', sortable: false },
        { text: 'Room', value: 'room', width: '200px', sortable: false },
      ],
      headersForClashDetection: [
        { text: "", value: "dot", width: "110px" },
        { text: "", value: "criteria", width: "150px" },
        { text: "Batch", value: "batch", width: "110px" },
        { text: "Group", value: "group", width: "110px" },
        { text: 'Date', value: 'dateTime', width: '150px' },
        { text: 'Day', value: 'weekDay', width: '80px', sortable: true },
        { text: 'Time', value: 'time', width: '200px', sortable: false },
        { text: 'Subject', value: 'subject', width: '200px' },
        { text: 'Faculty', value: 'faculty', width: '200px' },
        { text: 'Mode of conduction', value: 'mode', width: '200px', sortable: false },
        { text: 'Room', value: 'room', width: '200px', sortable: false },
      ],
      headersForClashDetectionEdit: [
        { text: "", value: "dot", width: "110px" },
        { text: "", value: "criteria", width: "150px" },
        { text: "Batch", value: "batch", width: "110px" },
        { text: "Group", value: "group", width: "110px" },
        { text: 'Date', value: 'date', width: '200px' },
        { text: 'Day', value: 'weekDay', width: '80px', sortable: true },
        { text: 'Previous Time', value: 'time', width: '200px', sortable: false },
        { text: 'Subject', value: 'subject', width: '200px' },
        { text: 'Shift', value: 'shiftForBulk', width: '200px', sortable: false },
        { text: 'Time Slot', value: 'editTimeForBulk', width: '200px', sortable: false },
        { text: 'Faculty', value: 'faculty', width: '200px' },
        { text: 'Mode of conduction', value: 'mode', width: '200px', sortable: false },
        { text: 'Room', value: 'room', width: '200px', sortable: false },
      ],
      headersForWarn: [
        { text: "Sr No", value: "srNo", width: "110px" },
        { text: 'Date', value: 'date', width: '150px' },
        { text: 'Day', value: 'weekDay', width: '80px', sortable: true },
        { text: 'Time', value: 'time', width: '200px', sortable: false },
        { text: 'Subject', value: 'subject', width: '200px' },
        { text: 'Faculty', value: 'faculty', width: '200px' },
        { text: 'Mode of conduction', value: 'mode', width: '200px', sortable: false },
        { text: 'Room', value: 'room', width: '200px', sortable: false },
        { text: 'Remove Lecture', value: 'remove', width: '200px', sortable: false },
      ],
      headersForTableViewForEdit: [
        { text: "", value: "dot", width: "110px" },
        { text: "", value: "edited", width: "60px" },
        { text: "", value: "criteria", width: "150px" },
        { text: "Batch", value: "batch", width: "110px" },
        { text: "Group", value: "group", width: "110px" },
        { text: 'Date', value: 'dateTime', width: '200px' },
        { text: 'Day', value: 'weekDay', width: '80px', sortable: true },
        { text: 'Last Time', value: 'time', width: '200px', sortable: false },
        { text: 'Select shift', value: 'shiftForBulk', width: '200px', sortable: false },
        { text: 'Select Time', value: 'editTimeForBulk', width: '200px', sortable: false },
        { text: 'Subject', value: 'subjectName', width: '200px' },
        { text: 'Faculty', value: 'fullName', width: '200px' },
        { text: 'Mode of conduction', value: 'mode', width: '200px', sortable: false },
        { text: 'Room', value: 'room', width: '200px', sortable: false },
      ],
      startDateForCalender: '',
      arrForClashInfo: [{
        fullName: '',
        startTime: '',
        endTime: '',

      }],
      deleteForMultiple: false,
      isClashAccepted: false,
      warningForBulkEdit: false,
      clashDialog: false,
      endDateForCalender: '',
      searchInTableView: '',
      typeOfClashHeading: '',
      allSubjectOfSem: [],
      facultyClashObject: {},
      roomClashObject: {},
      divisionClashObject: {},
      allToWarningForLec: [],
      facultiesForSubjects: [],
      reschedule: '',
      assignedDepartment: [],
      assignedCourseYearForSubAdmin: [],
      allFacultyOfDesignation: [],
      isSubAdmin: false,
      indexForMonth: 0,
      allLectForSelectedMonth: [],
      allWeekPairForSelectedMonth: [],
      monthNameToShow: '',
      isTimetableLec: false,
      weekStartDateForMonth: '',
      weekEndDateForMonth: '',
      monthWiseWeeklyIndex: 0,
      dateForWeek: '',
      editShiftData: {
        shiftName: '',
        workingDays: [],
        timeSlots: [],
        time: [],
        break: [],
      },

    };
  },

  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Semester Timetable')
    this.userRepositoryInstance = new UserRepository(this);
    this.shiftReferenceRepositoryInstance = new ShiftRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.timetableRepositoryInstance = new TimetableRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.topicRepositoryInstance = new TopicRepository(this);
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this);
    this.lecturesRepositoryInstance = new LecturesRepository(this);
    this.activityLoggerInstance = new ActivityLoggerRepository(this)
    this.instituteRepositoryInstance = new InstituteRepository(this)
    this.departmentDetailsRepositoryInstance = new departmentDetailsRepository(this)
    // this.SubAdminAccessControlInstance = new SubAdminAccessControl(this)
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedInstituteName = this.$store.getters["liveData/get_instituteStudentBrandingName"];
    this.selectedSemester = this.PropsSelectedSemester
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.isSubAdmin = this.$store.getters['liveData/get_isSubAdmin'].isSubAdmin
    this.semNameForInsights = this.selectedSemester.semName
    this.semNameArrForInsights = [this.selectedSemester.semName]
    // this.SubAdminAccessControlInstance.filterAccessedDepartmentForSubAdmin()
    this.fetchShiftData()

  },
  methods: {
    convertToBase10(negativeBaseNumber) {
      // Convert the negative base number to a string
      const negativeBaseString = negativeBaseNumber.toString();

      // Remove the '-' sign if present
      const absoluteValueString = negativeBaseString.replace('-', '');

      let result = 0;

      // Loop through each digit of the absolute value string
      for (let i = 0; i < absoluteValueString.length; i++) {
        // Convert each digit to a number
        const digit = parseInt(absoluteValueString[i]);

        // Calculate the value in base 10
        result += digit * Math.pow(-10, absoluteValueString.length - 1 - i);
      }

      return result;
    },
    nextDateLec(type) {
      let number = 1
      if (this.dateForWeek >= this.weekEndDateForMonth) {
        this.dateForWeek = this.weekStartDateForMonth
        number = 0
      }

      let currentDate = new Date(this.dateForWeek);
      currentDate.setDate(currentDate.getDate() + number);
      let endDate = currentDate.toISOString()
      let arr = []
      this.allLectForSelectedMonth.map((info) => {
        if (this.dateForWeek < info.dateTime && endDate > info.dateTime) {
          arr.push(info)
        }
      })
      if (type === 2) {

        this.dateForWeek = endDate
      }
      this.dateHrDetails.assigned = this.findAssignedHrForLec(arr)
      this.dateHrDetails.conducted = this.findConductedHrForLec(arr)

    },
    prvDateLec(type) {
      let number = 1


      if (this.dateForWeek === this.weekStartDateForMonth) {
        this.dateForWeek = this.weekEndDateForMonth
        number = 0
      }

      let currentDate = new Date(this.dateForWeek);
      currentDate.setDate(currentDate.getDate() - number);
      let endDate = currentDate.toISOString()
      let arr = []
      this.allLectForSelectedMonth.map((info) => {
        if (this.dateForWeek < info.dateTime && endDate > info.dateTime) {
          arr.push(info)
        }
      })
      if (type === 2) {

        this.dateForWeek = endDate
      }
      this.dateHrDetails.assigned = this.findAssignedHrForLec(arr)
      this.dateHrDetails.conducted = this.findConductedHrForLec(arr)
    },
    nextWeekData() {
      this.monthWiseWeeklyIndex++;

      if (this.allWeekPairForSelectedMonth.length - 1 < this.monthWiseWeeklyIndex) {
        this.monthWiseWeeklyIndex = 0
      }

      this.weekStartDateForMonth = this.allWeekPairForSelectedMonth[this.monthWiseWeeklyIndex][0]
      this.weekEndDateForMonth = this.allWeekPairForSelectedMonth[this.monthWiseWeeklyIndex][1]
      this.getWeeklyLecData(this.weekStartDateForMonth, this.weekEndDateForMonth)
      this.dateForWeek = this.weekStartDateForMonth
      this.nextDateLec(1)
    },
    prvWeekData() {
      this.monthWiseWeeklyIndex--;
      if (this.monthWiseWeeklyIndex < 0) {
        this.monthWiseWeeklyIndex = this.allWeekPairForSelectedMonth.length - 1
      }
      this.weekStartDateForMonth = this.allWeekPairForSelectedMonth[this.monthWiseWeeklyIndex][0]
      this.weekEndDateForMonth = this.allWeekPairForSelectedMonth[this.monthWiseWeeklyIndex][1]
      this.getWeeklyLecData(this.weekStartDateForMonth, this.weekEndDateForMonth)
      this.dateForWeek = this.weekStartDateForMonth
      this.nextDateLec(1)
    },
    getWeeklyLecData(startTime, endTime) {

      let arr = []
      this.allLectForSelectedMonth.map((info) => {
        if (startTime < info.dateTime && endTime > info.dateTime) {
          arr.push(info)
        }
      })
      this.weekHrDetails.assigned = this.findAssignedHrForLec(arr)
      this.weekHrDetails.conducted = this.findConductedHrForLec(arr)
    },
    getMonthNameWithDate(dateString) {
      const date = new Date(dateString);
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      const month = monthNames[date.getMonth()];
      const day = date.getDate();

      return `${month} ${day}`;
    },

    getMonthWeekDates(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth();

      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0); // Last day of the month

      const weekDates = [];
      let currentDay = new Date(firstDayOfMonth);

      // Generate week-wise date pairs until the end of the month
      while (currentDay <= lastDayOfMonth) {
        const startDate = new Date(currentDay);
        let endDate = new Date(currentDay);
        endDate.setDate(endDate.getDate() + 6); // Saturday of the same week

        // Adjust end date if it exceeds the last day of the month
        if (endDate > lastDayOfMonth) {
          endDate = new Date(lastDayOfMonth);
        }

        weekDates.push([startDate.toISOString(), endDate.toISOString()]);

        currentDay.setDate(currentDay.getDate() + 7); // Move to the next Sunday
      }

      return weekDates;
    },
    getMonthYear(dateString) {
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      const date = new Date(dateString);
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${month} ${year}`;
    },
    findMonthWiseDataNext() {
      this.indexForMonth++;
      this.monthWiseWeeklyIndex = 0
      let keysForMonth = Object.keys(this.objForAllocatedMonth)
      if (this.indexForMonth > keysForMonth.length - 1) {
        this.indexForMonth = 0
      }

      let value = keysForMonth[this.indexForMonth]
      this.monthHrDetails.assigned = this.findAssignedHrForLec(this.objForAllocatedMonth[value])
      this.monthHrDetails.conducted = this.findConductedHrForLec(this.objForAllocatedMonth[value])
      this.monthHrDetails.norms = (this.nomsForFaculty / 6).toFixed(0)
      this.allLectForSelectedMonth = this.objForAllocatedMonth[value]
      if (this.objForAllocatedMonth[value][0].startTime) {

        this.monthNameToShow = this.getMonthYear(this.objForAllocatedMonth[value][0].startTime)
        this.allWeekPairForSelectedMonth = this.getMonthWeekDates(this.objForAllocatedMonth[value][0].startTime)
        this.weekStartDateForMonth = this.allWeekPairForSelectedMonth[this.monthWiseWeeklyIndex][0]
        this.weekEndDateForMonth = this.allWeekPairForSelectedMonth[this.monthWiseWeeklyIndex][1]
        this.getWeeklyLecData(this.weekStartDateForMonth, this.weekEndDateForMonth)
        this.dateForWeek = this.weekStartDateForMonth
        this.nextDateLec(1)
      } else {
        this.monthNameToShow = value
      }

    },
    findMonthWiseDataPrev() {
      this.indexForMonth--;
      this.monthWiseWeeklyIndex = 0
      let keysForMonth = Object.keys(this.objForAllocatedMonth)
      if (this.indexForMonth === -1) {
        this.indexForMonth = keysForMonth.length - 1
      }
      let value = keysForMonth[this.indexForMonth]
      this.monthHrDetails.assigned = this.findAssignedHrForLec(this.objForAllocatedMonth[value])
      this.monthHrDetails.conducted = this.findConductedHrForLec(this.objForAllocatedMonth[value])
      this.allLectForSelectedMonth = this.objForAllocatedMonth[value]
      if (this.objForAllocatedMonth[value][0].startTime) {

        this.monthNameToShow = this.getMonthYear(this.objForAllocatedMonth[value][0].startTime)
        this.allWeekPairForSelectedMonth = this.getMonthWeekDates(this.objForAllocatedMonth[value][0].startTime)
        this.weekStartDateForMonth = this.allWeekPairForSelectedMonth[0][0]
        this.weekEndDateForMonth = this.allWeekPairForSelectedMonth[0][1]
        this.dateForWeek = this.weekStartDateForMonth
        this.nextDateLec(1)
      } else {
        this.monthNameToShow = value
      }
    },
    customTooltipFormatterDivision(val, series, seriesIndex, dataPointIndex) {
      // this.objForDeptAndCy
      let keys = Object.keys(this.objForDeptAndCy)
      return this.splitFullNameForDiv(keys[dataPointIndex]);
    },
    customTooltipFormatter(val, series, seriesIndex, dataPointIndex) {
      let keys = Object.keys(this.objForAllocatedSubject)
      return this.getIdAndProvideFullName(keys[dataPointIndex])
    },
    getIdAndProvideFullName(id) {
      let value = {}
      if (this.allSubjectForLec.length > 0) {
        this.allSubjectForLec.map((sub) => {
          if (sub.subjectId === id) {
            value = sub
          }
        })
      }
      return `${value.subjectName} (${value.courseYear}, ${value.department})`
      // return `${value.subjectCode}`

    },
    getIdAndProvideName(id) {
      let value = {}
      if (this.allSubjectForLec.length > 0) {
        this.allSubjectForLec.map((sub) => {
          if (sub.subjectId === id) {
            value = sub
          }
        })
      }
      // return `${value.subjectName} (${value.courseYear}, ${value.department})`
      return `${value.subjectCode}`

    },
    splitNameForDiv(name) {
      let [dept, cy, div] = name.split('_')
      return `Div ${div}`
    },
    splitFullNameForDiv(name) {
      let [dept, cy, div] = name.split('_')
      return `Div ${div} (${cy} , ${dept}) `
    },
    async getSubjectDetailsForIds(subjectIds) {
      let sub = []
      try {
        sub = await this.subjectRepositoryInstance.getSubjectDetails({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: subjectIds
        })
      } catch (err) {
        console.error('err', err);
      }
      return sub
    },
    getDeptValueInHr(item) {
      let arr = []
      let keys = Object.keys(item)
      if (keys.length > 0) {
        keys.map((info) => {
          arr = [...arr, ...item[info]]
        })
      }
      let hr = this.findConductedHrForLec(arr)
      return hr
    },

    findConductedHrForLec(arr) {
      let hr = 0
      if (arr.length > 0) {
        arr.map((lec) => {
          if (lec.present.length > 0 || lec.absent.length > 0 ) {
            let startTime = new Date(lec.startTime);
            let endTime = new Date(lec.endTime);
            let timeDifferenceInMilliseconds = endTime - startTime;
            let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60);
            let timeDifferenceInHoursFixed = timeDifferenceInHours.toFixed(2);
            if (!isNaN(timeDifferenceInHoursFixed)) {
              hr = hr + Number(timeDifferenceInHoursFixed)

            }
           
          }

        })
      }
      if (hr < 0) {
        hr = this.convertToBase10(hr)
      }
      return hr.toFixed(2)
    },
    findAssignedHrForLec(arr) {
      let hr = 0
      arr.map((lec) => {
        let startTime = new Date(lec.startTime);
        let endTime = new Date(lec.endTime);
        let timeDifferenceInMilliseconds = endTime - startTime;
        let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60);
        let timeDifferenceInHoursFixed = timeDifferenceInHours.toFixed(2);
        if (!isNaN(timeDifferenceInHoursFixed)) {
          hr = hr + Number(timeDifferenceInHoursFixed)
        }

      })
      if (hr < 0) {
        hr = this.convertToBase10(hr)
      }
      return hr.toFixed(2)

    },
    async getAllCalculationForLecture(data) {

      this.objForDeptAndCy = {}
      this.objForAllocatedSubject = {}
      this.objForAllocatedDivision = {}
      this.objForAllocatedMonth = {}
      this.academicHrDetails = {
        norms: this.nomsForFaculty,
        assigned: this.findAssignedHrForLec(data),
        conducted: this.findConductedHrForLec(data),
      }
      this.semHrDetails = this.academicHrDetails

      if (this.nomsForFaculty > 0) {
        this.monthHrDetails.norms = (this.nomsForFaculty / 6).toFixed(0)
        this.weekHrDetails.norms = (this.nomsForFaculty / (6 * 4)).toFixed(0)
        this.dateHrDetails.norms = (this.nomsForFaculty / (6 * 4 * 30)).toFixed(0)
      }
      if (this.allMonthData.length > 0) {
        this.allMonthData.map((month) => {
          if (data.length > 0) {

            data.map((lec) => {
              if (month.startDate < lec.startTime && month.endDate > lec.startTime) {
                if (this.objForAllocatedMonth[month.monthName]) {
                  this.objForAllocatedMonth[month.monthName].push(lec)
                } else {
                  this.objForAllocatedMonth[month.monthName] = []
                }

              }
            })
          }

        })
      }
      if (data.length > 0) {
        data.map((lec) => {
          if (lec.department) {

            if (!(Object.keys(this.objForDeptAndCy)).includes(lec.department)) {
              this.objForDeptAndCy[lec.department] = {}
            } else {
              if (lec.courseYear) {

                if (!(Object.keys(this.objForDeptAndCy[lec.department])).includes(lec.courseYear)) {
                  this.objForDeptAndCy[lec.department][lec.courseYear] = [lec]
                } else {
                  this.objForDeptAndCy[lec.department][lec.courseYear].push(lec)
                }
              }
            }
          }
          if (lec.subjectId && lec.subjectId !== '') {


            if (!(Object.keys(this.objForAllocatedSubject)).includes(lec.subjectId)) {
              this.objForAllocatedSubject[lec.subjectId] = [lec]
            } else {
              this.objForAllocatedSubject[lec.subjectId].push(lec)
            }
          }
          if (lec.department && lec.courseYear && lec.division) {

            if (!(Object.keys(this.objForAllocatedDivision)).includes(`${lec.department}_${lec.courseYear}_${lec.division}`)) {
              this.objForAllocatedDivision[`${lec.depgetAllCalculationForLectureartment}_${lec.courseYear}_${lec.division}`] = [lec]
            } else {
              this.objForAllocatedDivision[`${lec.department}_${lec.courseYear}_${lec.division}`].push(lec)
            }
          }


        })
        this.allSubjectForLec = []
        this.allSubjectForLec = await this.getSubjectDetailsForIds(Object.keys((this.objForAllocatedSubject)))
        let keysForSub = Object.keys(this.objForAllocatedSubject)
        if (keysForSub.length > 0) {
          let assignedValue = []
          let conductedValue = []
          let nameArr = []

          keysForSub.map((key) => {
            let assignedHr = this.findAssignedHrForLec(this.objForAllocatedSubject[key])
            let conductedHr = this.findConductedHrForLec(this.objForAllocatedSubject[key])
            let name = this.getIdAndProvideName(key)
            assignedValue.push(assignedHr)
            conductedValue.push(conductedHr)
            nameArr.push(name)
          })
          let seriesValue = [{
            data: assignedValue,
            name: 'Assigned'
          }, {
            data: conductedValue,
            name: 'Conducted'
          }]

          this.seriesForSubject = seriesValue
          this.subjectChart = {
            xaxis: {
              categories: nameArr
            }
          }
        }
        let keysForDiv = Object.keys(this.objForAllocatedDivision)
        if (keysForDiv.length > 0) {
          let assignedValue = []
          let conductedValue = []
          let nameArr = []

          keysForDiv.map((key) => {
            let assignedHr = this.findAssignedHrForLec(this.objForAllocatedDivision[key])
            let conductedHr = this.findConductedHrForLec(this.objForAllocatedDivision[key])
            let name = this.splitNameForDiv(key)
            assignedValue.push(assignedHr)
            conductedValue.push(conductedHr)
            nameArr.push(name)
          })
          let seriesValue = [{
            data: assignedValue,
            name: 'Assigned'
          }, {
            data: conductedValue,
            name: 'Conducted'
          }]

          this.seriesForDivision = seriesValue
          this.divisionChart = {
            xaxis: {
              categories: nameArr
            }
          }
        }
        let keysForMonth = Object.keys(this.objForAllocatedMonth)
        if (keysForMonth.length > 0) {
          let assignedValue = []
          let conductedValue = []
          let nameArr = []

          keysForMonth.map((key) => {
            let assignedHr = this.findAssignedHrForLec(this.objForAllocatedMonth[key])
            let conductedHr = this.findConductedHrForLec(this.objForAllocatedMonth[key])
            let name = key
            if (isNaN(assignedHr)) {
              assignedHr = 0
            }
            if (isNaN(conductedHr)) {

              conductedHr = 0
            }
            conductedValue.push(conductedHr)
            assignedValue.push(assignedHr)
            nameArr.push(name)
          })
          let seriesValue = [{
            data: assignedValue,
            name: 'Month wise Teaching load',
          }]

          this.series = seriesValue
          this.chartOptions = {
            xaxis: {
              categories: nameArr
            }
          }

        }
        this.indexForMonth = -1
        this.findMonthWiseDataNext()
      }
    },

    async facultySelectedForDesignation() {
      try {

        let data = await this.lecturesRepositoryInstance.getLecturesOfAFacultyForASem({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.selectedFacultyForInsights.uId
        })
        this.selectedDepartmentForFaculty = 'All'
        this.allDeptForFaculty = ['All']
        this.nomsForFaculty = 0
        if (this.allDeptOfInstituteLevel.length > 0) {
          this.allDeptOfInstituteLevel.map((info) => {
            if (info.departmentName === this.selectedFacultyForInsights.collegeDepartmentName) {
              if (info.loadDistributionDetails && info.loadDistributionDetails.length > 0) {
                info.loadDistributionDetails.map((load) => {
                  if (load.type === this.selectedLoadType && this.selectedFacultyType === load.designationName) {
                    if (load.activityHours > 0 && !isNaN(load.activityHours)) {

                      this.nomsForFaculty = this.nomsForFaculty + load.activityHours
                    }
                  }
                })
              }
            }
          })

        }
        this.getAllCalculationForLecture(data)
      } catch (err) {
        console.error('err', err);
      }
    },
    async facultyTypeChanged() {
      this.selectedFacultyForInsights = ''
      this.allFacultyForDesignation = []
      try {
        let data = await this.userRepositoryInstance.getUsersOfDesignationOfAInstitute({
          instituteId: this.selectedInstitute,
          designationName: this.selectedFacultyType,
        })
        this.allFacultyForDesignation = data?.users
      } catch (err) {
        console.error('err', err);
      }
    },
    async getInstituteData() {
      this.allFacultyType = []
      try {
        let data = await this.instituteRepositoryInstance.getInstituteInformation({
          instituteId: this.selectedInstitute
        })
        this.allDeptOfInstituteLevel = await this.departmentDetailsRepositoryInstance.getDepartmentDetailsForInstitute({
          instituteId: this.selectedInstitute
        })
        if (data.designationDetails) {
          this.allFacultyType = data.designationDetails
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    async facultyAddedInBulk() {
      this.lecArrForEditInBulk = []
      if (this.facultyNotAssignedLect.length > 0) {
        this.facultyNotAssignedLect.map((lec) => {
          if (lec.fullName !== '') {

            this.facultiesNameWithUid.map((fac) => {
              if (fac.fullName === lec.fullName) {
                lec.uId = fac.uId
              }
            })
            this.lecArrForEditInBulk.push({
              instituteId: lec.instituteId,
              semId: lec.semId,
              lectureId: lec.lectureId,
              uId: lec.uId,
            })
          }
        })
      }
      await this.editMultipleLec()
      this.facultyNotAssignedLect = []
      if (this.events.length > 0) {
        this.events.map((info) => {
          if (info.uId === undefined || info.uId === '') {
            info['fullName'] = ''
            this.facultyNotAssignedLect.push(info)
          }
        })
      }
    },
    filterOutFacultyNotAssignedLecture() {
      this.facultyNotAssignedFlag = !this.facultyNotAssignedFlag;
      this.bulkEditModeOn = false
      this.deleteForMultiple = false
      this.facultyNotAssignedLect = []
      if (this.events.length > 0) {
        this.events.map((info) => {
          if (info.uId === undefined || info.uId === '') {
            info['fullName'] = ''
            this.facultyNotAssignedLect.push(info)
          }
        })
      }
    },
    modeChangeEditLec() {
      if (this.selectedEvent.mode === 'Online') {
        this.selectedEvent.room = ''
      }
      let dummy = this.selectedEvent
      this.selectedEvent = {
        dateTime: '',
        day: '',
        fullName: '',
        startTime: '',
        endTime: '',
        isOnlineLecture: '',
        roomName: '',
        department: '',
        courseYear: '',
        division: '',
        batch: '',
        timetableId: '',
        reason: '',
        groupForSubject: [{
          groupName: ''
        }]
      }
      this.selectedEvent = dummy

    },
    checkFacultyAllocatedForSubject(item) {
      let check = this.sub.faculties.includes(item.uId)
    },
    getDayNameByDate(dateString, type) {
      let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let dayOfWeek;
      if (type === 1) {

        let dateFor = dateString.split('T')[0]
        let date = new Date(dateFor);
        dayOfWeek = date.getDay();
      }
      if (type === 2) {

        let date = new Date(dateString);
        dayOfWeek = date.getDay();
      }
      return daysOfWeek[dayOfWeek];
    },
    downloadExcelForLecture() {
      let arr = []
      if (this.events.length > 0) {

        this.events.map((lec, i) => {

          let obj = {
            SrNo: i + 1,
            Status: lec.isConducted ? 'Conducted' : 'Not Conducted',
            criteria: lec.department + '_' + lec.courseYear + '_' + lec.division,
            Subject: lec.subjectName,
            Date: this.formatDateToYYYYMMDD(lec.dateTime),
            Time: this.convertUTCtoLocalTimeWithAMPM(lec.startTime) + '-' + this.convertUTCtoLocalTimeWithAMPM(lec.endTime),
            Faculty: lec.fullName,
            Mode: lec.isOnlineLecture ? 'Online' : 'Offline',
            Room: lec.roomName,

          }
          arr.push(obj)


        })

        createExcelThroughJson(arr, `Lecture For ${this.startDateForCalender} To ${this.endDateForCalender}`)
      }
    },
    async openDialogForClashData(item, type) {
      this.clashIgnoreFlag = false
      this.isClashAccepted = false
      this.arrForClashInfo = [
        {
          fullName: '',
          startTime: '',
          endTime: '',

        }
      ]
      this.typeOfClashHeading = ''
      if (type === 'Faculty') {
        this.typeOfClashHeading = 'Faculty'
        this.arrForClashInfo = item.facultyClashData
      }
      if (type === 'Room') {
        this.typeOfClashHeading = 'Room'
        this.arrForClashInfo = item.roomClashData
      }
      if (type === 'Time') {

        if (item.facultyClashData) {
          this.arrForClashInfo = [...item.facultyClashData]
        }
        if (item.divisionClashData) {
          this.arrForClashInfo = [...item.divisionClashData]
        }
        if (item.roomClashData) {
          this.arrForClashInfo = [...item.roomClashData]
        }
      }
      if (type === 'Division') {
        this.typeOfClashHeading = 'Division'
        this.arrForClashInfo = item.divClashData
      }
      let uIds = []
      let roomIds = []
      let subjectIds = []
      if (this.arrForClashInfo.length > 0) {
        this.arrForClashInfo.map((info) => {
          info.id = uuidv4()
          info.editDateForBulk = info.dateTime.split('T')[0]
          info.editTimeSlotArr = []
          info.editedTime = ''
          info.shiftForBulk = ''
          info.isEdited = false
          info.editModeForBulk = info.isOnlineLecture ? 'Online' : 'Offline'
          info.isCalenderOn = false
          info.editedKeyArr = []

          if (this.facultiesNameWithUid.length > 0) {
            this.facultiesNameWithUid.map((fac) => {
              if (fac.uId === info.uId) {
                info.fullName = fac.fullName
              }
            })
          }
          if (info.subjectId && info.subjectId !== '') {

            subjectIds.push(info.subjectId)
          }
          if (info.roomId && info.roomId !== '') {
            roomIds.push(info.roomId)

          }
        })
      }
      let sub = [];
      let room = [];
      try {
        sub = await this.subjectRepositoryInstance.getSubjectDetails({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: subjectIds
        })
        room = await this.infrastructureReferenceRepositoryInstance.getRoomNames({
          instituteId: this.selectedInstitute,
          roomIds: roomIds
        })


      } catch (err) {
        console.error('err', err);
      } finally {
        this.arrForClashInfo.map((info) => {
          if (sub.length > 0) {
            sub.map((subj) => {
              if (subj.subjectId === info.subjectId) {
                info.subjectName = subj.subjectName
              }
            })
          }
          if (room.length > 0) {
            room.map((rooms) => {
              if (rooms.roomId === info.roomId) {
                info.roomName = rooms.roomName
              }
            })
          }

        })
      }

      this.clashDialog = true
      this.clashIgnoreFlag = false

    },
    removeEditedValue() {
      this.events.map((info) => {
        info.editDateForBulk = info.dateTime.split('T')[0]
        info.editTimeSlotArr = []
        info.editedTime = ''
        info.isEdited = false
        info.editModeForBulk = info.isOnlineLecture ? 'Online' : 'Offline'
        info.isCalenderOn = false
        info.editedKeyArr = []
        info.shiftForBulk = ''
      })
    },
    async allClashInSem() {

      let currentDate = new Date(this.startDateForCalender);
      currentDate.setDate(currentDate.getDate() - 1);
      let startDate = currentDate.toISOString()
      let startDateForLec = startDate.split('T')[0]
      try {
        const min = new Date(`${startDateForLec}T18:30:00`)
        const max = new Date(`${this.endDateForCalender}T18:30:59`)
        let obj = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          fromDate: min,
          toDate: max,
        }
        let data = await this.lecturesRepositoryInstance.findAllClashOfLecOnSemLevel(obj)
        this.facultyClashObject = data.facultyClashObject
        this.roomClashObject = data.roomClashObject
        this.divisionClashObject = data.divisionClashObject
        if (this.events.length > 0) {
          this.events.map((info) => {
            info.clashType = [] //No clash
            info.facultyClashData = [] //No clash
            info.roomClashData = [] //No clash
            info.divClashData = [] //No clash

            //faculty clash
            let keyString;
            if (info.uId && info.uId !== '') {
              keyString = info.uId + '_' + info.startTime + '_' + info.endTime
              let allKeyOfFaculty = []
              allKeyOfFaculty = Object.keys(this.facultyClashObject)
              if (allKeyOfFaculty.length > 0) {
                allKeyOfFaculty.map((fac) => {

                  if (fac === keyString) {

                    info.clashType.push(1) //faculty clash

                    info.facultyClashData = this.facultyClashObject[fac]
                  } else {
                    let [faculty, start, end] = fac.split('_')
                    if (((start < info.startTime && end > info.startTime) || (start < info.endTime && end > info.endTime)) && faculty === info.uId) {
                      info.clashType.push(1) //faculty clash

                      info.facultyClashData = this.facultyClashObject[fac]

                    }
                  }

                })

              }
            }
            //Room clash
            let keyStringRoom;

            if (info.roomId && info.roomId !== '') {
              keyStringRoom = info.roomId + '_' + info.startTime + '_' + info.endTime
              let allKeyOfRoom = []
              allKeyOfRoom = Object.keys(this.roomClashObject)
              if (allKeyOfRoom.length > 0) {
                allKeyOfRoom.map((room) => {

                  if (room === keyStringRoom) {
                    info.clashType.push(2)
                    info.roomClashData = this.roomClashObject[room]
                  } else {
                    let [roomId, start, end] = room.split('_')
                    if (((start < info.startTime && end > info.startTime) || (start < info.endTime && end > info.endTime)) && roomId === info.roomId) {
                      info.clashType.push(2)
                      info.roomClashData = this.roomClashObject[room]

                    }
                  }

                })

              }
            }
            //Division clash
            let keyStringDivision;
            if (info.division && info.division !== '') {
              keyStringDivision = info.department + '-' + info.courseYear + '-' + info.division + '_' + info.startTime + '_' + info.endTime
              let allKeyOfDivision = []
              allKeyOfDivision = Object.keys(this.divisionClashObject)
              if (allKeyOfDivision.length > 0) {
                allKeyOfDivision.map((div) => {

                  if (div === keyStringDivision) {
                    info.clashType.push(3)
                    info.divClashData = this.divisionClashObject[div]
                  } else {
                    let [division, start, end] = div.split('_')
                    if (((start < info.startTime && end > info.startTime) || (start < info.endTime && end > info.endTime)) && division === info.department + '-' + info.courseYear + '-' + info.division) {
                      info.clashType.push(3)
                      info.divClashData = this.divisionClashObject[div]
                    }
                  }

                })

              }
            }
          })
        }

      } catch (err) {
        console.error('err', err);
      }
    },
    async tableViewChange() {
      this.editInBulkToggle = false
      this.deleteForMultiple = false
      if (this.switchForTable === true) {
        const compareDateStrings = (a, b) => {
          return new Date(a.dateTime) - new Date(b.dateTime);
        };
        await this.allClashInSem()
        // this.events.sort(compareDateStrings);
      }
    },
    deleteManyDialogOpen() {
      this.deleteLecDialog = true
    },
    async deleteManyLecture() {
      if (this.deleteMultipleLectArr.length > 0) {
        this.deleteMultipleLectArr.map((info) => {
          info.isForDelete = true
        })
        try {
          this.progressBar = true
          await this.lecturesRepositoryInstance.updateMultipleLectureDocument({
            bulkDataArray: this.deleteMultipleLectArr
          })
          let start = {
            date: this.startDateForCalender
          }
          let end = {
            date: this.endDateForCalender
          }
          await this.updateRange({ start, end })
          this.allClashInSem()
          showStatus('Lecture deleted Successfully.', 1000, 'success', this)
        } catch (err) {
          console.error('err', err);
          showStatus('Something went wrong', 1000, 'error', this)

        }
      }
    },
    dateChangedForBulkEdit(data, i) {
      data.isCalenderOn = true
      data.editedKeyArr.push('Date')

      this.$set(this.events, i, data);


    },
    dateChangedForBulkEditClash(data, i) {
      data.isCalenderOn = true
      data.editedKeyArr.push('Date')

      this.$set(this.arrForClashInfo, i, data);


    },
    async clashEdit() {
      this.flagForClashEdit = false
      let lectureForEdit = []
      let arrForLec = []
      this.lecArrForEditInBulk = []
      let count = 0
      if (this.arrForClashInfo.length > 0) {
        this.arrForClashInfo.map((info) => {


          if (info.isEdited === true) {
            if (info.timetableId && info.timetableId !== '') {

            } else {

              if (info.editDateForBulk === '' || info.editedTime === '' || info.editModeForBulk === '') {
                count++
              }
            }
            lectureForEdit.push(info)
          }
        })
      }
      if (count === 0) {
        this.clashDialog = false

        if (lectureForEdit.length > 0) {
          lectureForEdit.map((lec) => {
            let lectureToArrange = {}
            lectureToArrange = {
              instituteId: lec.instituteId,
              semId: lec.semId,
              department: lec.department,
              courseYear: lec.courseYear,
              subjectId: lec.subjectId,
              isOnlineLecture: lec.editModeForBulk === 'Offline' ? false : true,
              day: new Date(lec.editDateForBulk).getDay(),
              lectureId: lec.lectureId,
            }
            if (lec.fullName !== '') {
              lectureToArrange.fullName = lec.fullName

              this.facultiesNameWithUid.map((fac) => {
                if (fac.fullName === lec.fullName) {
                  lectureToArrange.uId = fac.uId
                }

              })
            }
            if (lec.batch !== '') {
              lectureToArrange.batch = lec.batch
            }
            if (this.groupInfo.groupName) {
              lectureToArrange['groupForSubject'] = [this.groupInfo]
            }
            if (lec.division !== '') {
              lectureToArrange['division'] = lec.division
            }
            lectureToArrange.roomId = ''
            if (lec.roomName !== '') {
              lectureToArrange.roomName = lec.roomName
              this.allRoomsDept.map((room) => {
                if (room.roomName === lec.roomName) {
                  lectureToArrange.roomId = room.roomId
                }
              })
            }
            if (lectureToArrange.isOnlineLecture === true) {
              lectureToArrange.roomId = ''
            }
            if (lec.timetableId && lec.timetableId !== '') {
              lectureToArrange.dateTime = lec.dateTime
              lectureToArrange.startTime = lec.startTime
              lectureToArrange.endTime = lec.endTime
            } else {
              let [startTime, endTime] = lec.editedTime.split('-')

              lectureToArrange.dateTime = this.getUtcTimeOfLecture(lec.editDateForBulk, startTime)
              lectureToArrange.startTime = this.getUtcTimeOfLecture(lec.editDateForBulk, startTime)
              lectureToArrange.endTime = this.getUtcTimeOfLecture(lec.editDateForBulk, endTime)
            }
            arrForLec.push(lectureToArrange)
          })
          try {
            if (arrForLec.length > 0) {
              let lect = await this.lecturesRepositoryInstance.updateMultipleLectureDocument({ bulkDataArray: arrForLec })
            }

            let start = {
              date: this.startDateForCalender
            }
            let end = {
              date: this.endDateForCalender
            }
            await this.updateRange({ start, end })
            await this.allClashInSem()

            showStatus('Lecture updated Successfully.', 1000, 'success', this)
          } catch (err) {
            console.error('err', err);
            showStatus('Something went wrong', 1000, 'error', this)

          }
        }
      } else {
        this.flagForClashEdit = true
      }



    },
    closeDateForEditBulk(data, i) {
      data.isCalenderOn = false
      this.$set(this.events, i, data);

    },
    closeDateForEditBulkClash(data, i) {
      data.isCalenderOn = false
      this.$set(this.arrForClashInfo, i, data);

    },
    isEdited(item, name) {
      item.isEdited = true
      item.editedKeyArr.push(name)

    },
    isEditedClash(item, name) {
      item.isEdited = true
    },
    shiftChangeClash(data, i) {
      data.editTimeSlotArr = []
      this.allShift.map((shift) => {
        if (shift.shiftName === data.shiftForBulk) {
          if (shift.timeSlots.length > 0) {
            shift.timeSlots.map((time) => {
              if (time.isBreak) {
                if (time.isBreak === false) {
                  data.editTimeSlotArr.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))

                }
              } else {

                data.editTimeSlotArr.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))
              }

            })
          }
        }
      })
      this.$set(this.arrForClashInfo, i, data);
    },
    roomChangedForBulkEdit(item) {
      item.isEdited = true
      item.editedKeyArr.push('Room')

    },
    modeChangedForBulkEdit(data, i) {
      data.isEdited = true
      data.editedKeyArr.push('Mode')
      if (data.editModeForBulk === '' || data.editModeForBulk === 'Online') {
        data.roomName = ''
      }
      this.$set(this.events, i, data);
    },

    shiftNameChangedForLecForEditBulk(data, i) {

      data.editTimeSlotArr = []
      this.allShift.map((shift) => {
        if (shift.shiftName === data.shiftForBulk) {
          if (shift.timeSlots.length > 0) {
            shift.timeSlots.map((time) => {
              if (time.isBreak) {
                if (time.isBreak === false) {
                  data.editTimeSlotArr.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))

                }
              } else {

                data.editTimeSlotArr.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))
              }

            })
          }
        }
      })
      this.$set(this.events, i, data);
    },
    async editInBulkToggleOn() {
      this.bulkEditModeOn = !this.bulkEditModeOn
      this.deleteForMultiple = false
      this.facultyNotAssignedFlag = false
      this.events.map((info) => {
        info.editDateForBulk = info.dateTime.split('T')[0]
        info.editTimeSlotArr = []
        info.editedTime = ''
        info.isEdited = false
        info.editModeForBulk = info.isOnlineLecture ? 'Online' : 'Offline'
        info.isCalenderOn = false
        info.editedKeyArr = []
      })
      await this.allClashInSem()

    },
    async deleteInBulkToggle() {
      this.deleteForMultiple = !this.deleteForMultiple
      this.bulkEditModeOn = false
      this.facultyNotAssignedFlag = false

      this.deleteMultipleLectArr = []
      await this.allClashInSem()

    },
    resetSelectedEventValue() {
      let obj = this.selectedEvent
      this.selectedEvent = {
        dateTime: '',
        day: '',
        fullName: '',
        startTime: '',
        endTime: '',
        isOnlineLecture: '',
        roomName: '',
        department: '',
        courseYear: '',
        division: '',
        batch: '',
        timetableId: '',
        reason: '',
        groupForSubject: [{
          groupName: ''
        }]
      }
      this.selectedEvent = obj
    },
    convertTo24HourFormat(time12hr) {
      let match = time12hr.match(/(\d+):(\d+)\s*([APMapm]{2})/);
      if (!match) {
        // Invalid input format
        return null;
      }

      let hours = parseInt(match[1], 10);
      let minutes = parseInt(match[2], 10);
      let period = match[3].toUpperCase();

      // Adjust hours for PM
      if (period === 'PM' && hours < 12) {
        hours += 12;
      }

      // Adjust hours for AM (midnight)
      if (period === 'AM' && hours === 12) {
        hours = 0;
      }

      // Format the result as HH:mm
      let hours24 = hours.toString().padStart(2, '0');
      let minutes24 = minutes.toString().padStart(2, '0');

      return hours24 + ':' + minutes24;
    },
    getMonthByDate(date) {
      const inputDate = new Date(date);
      const formattedDate = inputDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      return formattedDate
    },
    filterOutAllParameter() {
      let cyFilter = []
      let subFilter = []
      let typeFilter = []

      if (this.events.length > 0) {
        if (this.selectedCyForLec !== '' && this.selectedCyForLec !== 'All') {
          this.events.map((event) => {
            if (this.selectedCyForLec === event.courseYear) {
              cyFilter.push(event)
            }
          })
          this.events = cyFilter
          if (this.selectedSubjectForLec !== '') {
            if (this.selectedSubjectForLec !== 'All') {

              if (cyFilter.length > 0) {
                cyFilter.map((event) => {
                  if (this.sub.subjectId === event.subjectId) {
                    subFilter.push(event)
                  }
                })
              }
              this.events = subFilter

              if (subFilter.length > 0) {

                if (this.selectedTypeForLec === 'Division') {

                  if (this.selectedDivisionForLec !== '' && this.selectedDivisionForLec !== 'All') {
                    subFilter.map((event) => {
                      if (this.selectedDivisionForLec === event.division) {
                        typeFilter.push(event)
                      }
                    })


                  }
                  if (this.selectedDivisionForLec === 'All') {
                    this.events = subFilter
                  } else {
                    this.events = typeFilter
                  }
                  if (this.selectedDivisionForLec === '') {
                    this.events = []
                    subFilter.map((info) => {
                      if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && (info.batch === undefined || info.batch === '') && (info.groupForSubject === undefined || info.groupForSubject.length === 0)) {
                        this.events.push(info)
                      }
                    })

                  }
                }
                if (this.selectedTypeForLec === 'Batch') {

                  if (this.selectedBatchForLec !== '') {
                    if (this.selectedBatchForLec !== 'All') {

                      cyFilter.map((event) => {
                        if (this.selectedDivisionForLec === event.division && this.selectedBatchForLec === event.batch) {
                          typeFilter.push(event)
                        }
                      })
                    } else {
                      subFilter.map((event) => {
                        if (this.selectedDivisionForLec === event.division) {
                          typeFilter.push(event)
                        }
                      })
                    }

                  }
                  this.events = typeFilter
                  if (this.selectedBatchForLec === '') {
                    this.events = []
                    subFilter.map((info) => {
                      if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && info.batch && info.batch !== '') {
                        this.events.push(info)
                      }
                    })

                  }
                }
                if (this.selectedTypeForLec === 'Group') {

                  if (this.selectedGroupForLec !== '') {
                    if (this.selectedGroupForLec !== 'All') {

                      subFilter.map((event) => {
                        if (event.groupForSubject && event.groupForSubject.length > 0 && event.groupForSubject[0].groupId === this.groupInfo.groupId) {

                          typeFilter.push(event)
                        }
                      })
                      this.events = typeFilter

                    } else {
                      this.event = subFilter
                    }

                  }
                  if (this.selectedGroupForLec === '') {
                    this.events = []
                    if (this.events2.length > 0) {
                      this.events2.map((info) => {
                        if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && info.groupForSubject !== undefined && info.groupForSubject.length > 0) {
                          this.events.push(info)
                        }
                      })
                    }
                  }

                }
              }
            } else {
              if (cyFilter.length > 0) {
                if (this.selectedTypeForLec === 'Division') {

                  if (this.selectedDivisionForLec !== '' && this.selectedDivisionForLec !== 'All') {
                    cyFilter.map((event) => {
                      if (this.selectedDivisionForLec === event.division) {
                        typeFilter.push(event)
                      }
                    })


                  }
                  if (this.selectedDivisionForLec === 'All') {
                    this.events = cyFilter
                  } else {
                    this.events = typeFilter

                  }
                  if (this.selectedDivisionForLec === '') {
                    this.events = []
                    if (this.events2.length > 0) {
                      this.events2.map((info) => {
                        if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && (info.batch === undefined || info.batch === '') && (info.groupForSubject === undefined || info.groupForSubject.length === 0)) {
                          this.events.push(info)
                        }
                      })
                    }
                  }
                }
                if (this.selectedTypeForLec === 'Batch') {

                  if (this.selectedBatchForLec !== '') {
                    if (this.selectedBatchForLec !== 'All') {

                      cyFilter.map((event) => {
                        if (this.selectedDivisionForLec === event.division && this.selectedBatchForLec === event.batch) {
                          typeFilter.push(event)
                        }
                      })
                    } else {
                      cyFilter.map((event) => {
                        if (this.selectedDivisionForLec === event.division) {
                          typeFilter.push(event)
                        }
                      })
                    }

                    this.events = typeFilter
                  }
                  if (this.selectedBatchForLec === '') {
                    this.events = []
                    if (this.events2.length > 0) {
                      this.events2.map((info) => {
                        if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && info.batch && info.batch !== '') {
                          this.events.push(info)
                        }
                      })
                    }
                  }
                  //issue there
                }
                if (this.selectedTypeForLec === 'Group') {

                  if (this.selectedGroupForLec !== '') {
                    if (this.selectedGroupForLec !== 'All') {

                      cyFilter.map((event) => {
                        if (event.groupForSubject && event.groupForSubject.length > 0 && event.groupForSubject[0].groupId === this.groupInfo.groupId) {

                          typeFilter.push(event)
                        }
                      })
                      this.events = typeFilter

                    } else {
                      this.event = cyFilter
                    }


                  }
                  if (this.selectedGroupForLec === '') {
                    this.events = []
                    if (this.events2.length > 0) {
                      this.events2.map((info) => {
                        if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && info.groupForSubject !== undefined && info.groupForSubject.length > 0) {
                          this.events.push(info)
                        }
                      })
                    }
                  }

                }
              }
            }

          }

        }
      }
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: 'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
    deleteDialogOpenForLec() {
      this.deleteLecDialog = true;
      this.selectedOpen = false
      this.selectedLecForDelete = this.selectedEvent
    },
    async deleteLectureData() {
      this.progressBar = true
      this.selectedOpen = false
      this.deleteLecDialog = false;

      try {
        let data = await this.lecturesRepositoryInstance.deleteLecture(this.selectedLecForDelete);

        let start = {
          date: this.startDateForCalender
        }
        let end = {
          date: this.endDateForCalender
        }
        await this.updateRange({ start, end })
        showStatus('Lecture deleted Successfully.', 1000, 'success', this)

      } catch (err) {
        console.error('err', err);
        showStatus('Something went wrong', 1000, 'error', this)

      }
      this.progressBar = false

    },

    shiftNameChangedForLecForEdit() {
      this.slotsForShift = []
      this.allShift.map((shift) => {
        if (shift.shiftName === this.selectedEvent.shift) {
          if (shift.timeSlots.length > 0) {
            shift.timeSlots.map((time) => {
              if (time.isBreak) {
                if (time.isBreak === false) {
                  this.slotsForShift.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))

                }
              } else {

                this.slotsForShift.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))
              }

            })
          }
        }
      })
    },
    convertUTCtoLocalTimeWithAMPM(utcDateString) {
      const utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const result = formattedHours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm
      return result;
    },
    convertUTCtoLocalTimeAMPM(utcDateString) {
      const utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const result = formattedHours + ':' + (minutes < 10 ? '0' : '') + minutes
      return result;
    },
    formatDateToYYYYMMDD(date) {
      // const isoString = date.toISOString();
      return date.split('T')[0]; // Extract yyyy-mm-dd part
    },
    getMonthStartAndEndDates() {
      const currentDate = new Date();
      const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      return { start: firstDay.toISOString(), end: lastDay.toISOString() };
    },
    getDataForCriteria() {
      this.flagForCalender = true
    },
    removeElementFromWarnArr(i) {
      let lectureId = this.lecArrForEditInBulk[i].lectureId
      this.lecArrForEditInBulk.splice(i, 1)
      this.events.map((info) => {
        if (info.lectureId === lectureId) {
          info.editDateForBulk = info.dateTime.split('T')[0]
          info.editTimeSlotArr = []
          info.editedTime = ''
          info.isEdited = false
          info.editModeForBulk = info.isOnlineLecture ? 'Online' : 'Offline'
          info.isCalenderOn = false
          info.editedKeyArr = []
          info.shiftForBulk = ''
        }
      })
    },
    async getAllLectureForDates(start, end) {
      this.progressBar = true
      // this.progressBar = true
      let dept = []
      if (this.selectedDepartmentForLec === 'All') {
        dept = this.allDepartmentNames
      } else {
        dept = [this.selectedDepartmentForLec]
      }

      let obj = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: dept,
        fromDate: start,
        toDate: end,
        type: 'DepartmentLevel'
      }
      try {
        this.events = []
        this.events2 = []
        let allLecture = await this.lecturesRepositoryInstance.getOverAllLectureForDates(obj)
        this.allLecData = allLecture
        if (allLecture.lecture && allLecture.lecture.length > 0) {

          allLecture.lecture.map((info) => {
            info.id = uuidv4()
            info.editDateForBulk = info.dateTime.split('T')[0]
            info.editTimeSlotArr = []
            info.editedTime = ''
            info.isEdited = false
            info.editModeForBulk = info.isOnlineLecture ? 'Online' : 'Offline'
            info.isCalenderOn = false
            info.editedKeyArr = []

            info.clashType = []
            info.facultyClashData = []
            info.roomClashData = []
            info.divClashData = []

            info.fullName = ''
            info.roomName = ''
            info.subjectName = ''

            info.weekDay = this.getDayNameByDate(info.dateTime, 1)

            if (info.dateTime > new Date().toISOString()) {
              info.statusForLec = 3
            } else {
              if (info.isConducted) {
                info.statusForLec = 1
              } else {
                info.statusForLec = 2
              }
            }
            if (info.status && (info.status === 6 || info.status === 2 || info.status === 7)) {
              info.statusForLec = 4
            }
            if (allLecture?.facultyDetails?.fullNames.length > 0) {

              allLecture.facultyDetails.fullNames.map((fac) => {

                if (fac.uId === info.uId) {
                  info.fullName = fac.fullName
                }
              })
            }
            if (allLecture.roomDetails.roomName.length > 0) {

              allLecture.roomDetails.roomName.map((room) => {
                if (room.roomId === info.roomId) {
                  info.roomName = room.roomName
                }
              })
            }
            if (allLecture.subjectDetails.subjects.length > 0) {

              allLecture.subjectDetails.subjects.map((sub) => {
                if (sub.subjectId === info.subjectId) {
                  info.subjectName = sub.subjectName
                  info.subjectType = sub.subjectType
                }
              })
            }

            let eventObj = {
              name: "Lecture",
              start: this.formatDateToYYYYMMDD(info.dateTime) + 'T' + this.convertTo24HourFormat(this.convertUTCtoLocalTimeWithAMPM(info.startTime)),
              end: this.formatDateToYYYYMMDD(info.dateTime) + 'T' + this.convertTo24HourFormat(this.convertUTCtoLocalTimeWithAMPM(info.endTime)),

              timed: true,
              ...info
            }
            if (info.timetableId && info.timetableId !== '') {
              eventObj.color = '#f7f7f9'
            } else {
              eventObj.color = '#7EAAFF1A'
            }
            if (this.selectedTimeTableView === 'Master Timetable') {
              if (info.timetableId && info.timetableId !== '') {
                this.events.push(eventObj)
              }
            } else {
              this.events.push(eventObj)

            }
          })
        }

        this.events2 = this.events
        this.filterOutAllParameter()
        if (this.switchForTable && !this.bulkEditModeOn && !this.deleteForMultiple) {
          await this.allClashInSem()
        }

        // this.events = allLecture.lecture
        this.progressBar = false
        this.isNew = false


      } catch (err) {
        console.error('err', err);
        this.progressBar = false
        this.isNew = false


      }
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    getSubjectAndAllocatedFaculty() {
      this.facultiesForSubjects = []
      try {
        let faculties = []

        if (this.allLecData.subjectDetails.subjects.length > 0) {

          this.allLecData.subjectDetails.subjects.map((sub) => {
            if (this.selectedEvent.subjectId === sub.subjectId) {
              faculties = sub.faculties
            }
          })
        }
        if (faculties.length > 0) {
          faculties.map((info) => {

            if (this.facultiesNameWithUid.length > 0) {
              this.facultiesNameWithUid.map((fac) => {
                if (fac.uId === info) {
                  this.facultiesForSubjects.push(fac.fullName)
                }
              })
            }
          })
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    showEvent({ nativeEvent, event }) {
      this.isTimetableLec = false
      this.isCancelOnLec = false
      this.reschedule = ''
      this.isEditOnLec = false
      const open = () => {
        this.selectedEvent.reason = ''
        this.selectedEvent = event
        this.isTimetableLec = false
        this.selectedEvent.dateForEdit = this.selectedEvent.dateTime.split('T')[0]
        this.selectedEvent.time = ''
        this.selectedEvent.shift = ''
        this.selectedEvent.room = this.selectedEvent.roomName ? this.selectedEvent.roomName : ''
        this.selectedEvent.faculty = this.selectedEvent.fullName ? this.selectedEvent.fullName : ''
        this.selectedEvent.mode = this.selectedEvent.isOnlineLecture ? 'Online' : 'Offline'
        this.selectedEvent.topic = ''
        this.selectedEvent.isCancelAndReschedule = false
        this.selectedEvent.timeToShow = this.convertUTCtoLocalTimeWithAMPM(this.selectedEvent.startTime) + '-' + this.convertUTCtoLocalTimeWithAMPM(this.selectedEvent.endTime)
        this.selectedElement = nativeEvent.target
        this.getSubjectAndAllocatedFaculty()
        if (this.selectedEvent.timetableId && this.selectedEvent.timetableId !== '') {
          this.isTimetableLec = true
          this.selectedEvent.time = '-'
        }
        if (this.selectedEvent.status && (this.selectedEvent.status === 6 || this.selectedEvent.status === 7)) {
          this.isCancelOnLec = true
        }
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async updateRange({ start, end }) {
      this.isForOneDay = false
      const events = []
      let currentDate = new Date(start.date);
      this.startDateForCalender = currentDate.toISOString().split('T')[0]
      currentDate.setDate(currentDate.getDate() - 1);
      let startDate = currentDate.toISOString()
      let startDateForLec = startDate.split('T')[0]
      this.endDateForCalender = end.date
      if (this.startDateForCalender === this.endDateForCalender) {
        this.isForOneDay = true
      }
      const min = new Date(`${startDateForLec}T23:59:59`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      this.getAllLectureForDates(min.toISOString(), max.toISOString())
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    getDayNameFromDayNumber(dayNumber) {
      switch (dayNumber) {
        case 0:
          return "Sunday";
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
      }
    },
    getUtcTimeOfLecture(date, time) {
      const [year, month, day] = date.split('-');
      const [hours, minutes, period] = time.split(/[:\s]/);
      let adjustedHours = parseInt(hours, 10);
      if (period.toUpperCase() === 'PM' && adjustedHours !== 12) {
        adjustedHours += 12;
      } else if (period.toUpperCase() === 'AM' && adjustedHours === 12) {
        adjustedHours = 0;
      }
      const localDate = new Date(year, month - 1, day, adjustedHours, minutes);
      const utcTimeString = localDate.toISOString();
      return utcTimeString;
    },
    removeLecFromArr(i) {
      this.arrForSpecificDateLec.splice(i, 1)
      if (this.typeForDateForEvent === 'SpecificDates') {
        this.multiDateSelection.splice(i, 1)
      }
    },
    timeChangedInTable(i) {
      if (i === 0) {
        this.applyAllTime = false
      }

    },
    facultyChangedInTable(i) {
      if (i === 0) {
        this.applyAllFaculty = false
      }

    },
    modeChangedInTable(i) {
      if (i === 0) {
        this.applyAllMode = false
      }
      if (this.arrForSpecificDateLec[i].mode === 'Online') {

        this.arrForSpecificDateLec[i].room = ''
        let arr = this.arrForSpecificDateLec
        this.arrForSpecificDateLec = []
        this.arrForSpecificDateLec = arr
      }


    },
    roomChangedInTable(i) {
      if (i === 0) {
        this.applyAllRoom = false
      }

    },
    applyAllForFaculty() {

      if (this.applyAllFaculty === true) {

        let fac = this.arrForSpecificDateLec[0].faculty
        this.arrForSpecificDateLec.map((info) => {
          info.faculty = fac
        })
      } else {
        this.arrForSpecificDateLec.map((info, i) => {
          if (i !== 0) {

            info.faculty = ''
          }
        })
      }
    },
    applyAllForTime() {
      if (this.applyAllTime === true) {

        let time = this.arrForSpecificDateLec[0].time
        this.arrForSpecificDateLec.map((info) => {
          info.time = time
        })
      } else {
        this.arrForSpecificDateLec.map((info, i) => {
          if (i !== 0) {

            info.time = []
          }
        })
      }
    },
    applyAllForRoom() {
      if (this.applyAllRoom === true) {

        let room = this.arrForSpecificDateLec[0].room
        this.arrForSpecificDateLec.map((info) => {
          info.room = room
        })
      } else {

        this.arrForSpecificDateLec.map((info, i) => {
          if (i !== 0) {

            info.room = ''
          }
        })
      }
    },
    applyAllForMode() {

      if (this.applyAllMode === true) {

        let mode = this.arrForSpecificDateLec[0].mode
        this.arrForSpecificDateLec.map((info) => {
          info.mode = mode
          if (mode === 'Online') {
            info.room = ''
          }
        })
      } else {
        this.arrForSpecificDateLec.map((info, i) => {
          if (i !== 0) {

            info.mode = ''
          }
        })
      }
    },
    timingRadioChanged() {
      if (this.arrForSpecificDateLec.length > 0) {
        this.arrForSpecificDateLec.map((info) => {
          info.time = []
        })
      }
    },
    timeFrameChanged() {
      this.applyAllFaculty = false
      this.applyAllRoom = false
      this.applyAllTime = false
      this.applyAllMode = false
      if (this.selectedDaysForLec.length > 0) {
        this.weekDaysChanged()
      }

      if (this.typeForDateForEvent === 'SpecificIntervals') {
        this.createArrForInterval()
      }
      if (this.typeForDateForEvent === 'SpecificDates') {
        this.multiDateSelection = []
        this.createForTiming = ''
        this.selectedShiftForLec = ''
        this.arrForSpecificDateLec = []
        this.flagForTypeSelection = false
      }
    },
    intervalCountChanged() {
      this.createForTiming = ''
      this.selectedShiftForLec = ''
      this.arrForSpecificDateLec = []
      this.applyAllFaculty = false
      this.applyAllRoom = false
      this.applyAllTime = false
      this.applyAllMode = false
      if (this.intervalCount <= 0) {
        this.intervalCount = 1
      }
      if (this.intervalCount > 0) {
        this.flagForTypeSelection = true

      }
    },
    getDatesForInterval(startDate, endDate, interval) {
      const result = [];
      let currentDate = startDate;

      while (currentDate <= endDate) {
        result.push(format(currentDate, 'yyyy-MM-dd'));
        currentDate = addDays(currentDate, interval);
      }

      return result;
    },
    dayNameToNumericValue(dayName) {
      switch (dayName.toLowerCase()) {
        case 'sunday':
          return 0;
        case 'monday':
          return 1;
        case 'tuesday':
          return 2;
        case 'wednesday':
          return 3;
        case 'thursday':
          return 4;
        case 'friday':
          return 5;
        case 'saturday':
          return 6;
        default:
          // Handle invalid input
          return -1;
      }
    },

    weekDaysChanged() {
      let arr = []
      this.arrForSpecificDateLec = []
      if (this.selectedDaysForLec.length > 0) {
        this.selectedDaysForLec.map((info) => {
          arr.push(this.dayNameToNumericValue(info))
        })
      }
      if (arr.length > 0) {

        let dates = this.getWeekdaysInDateRange(new Date(this.startDateForTimeFrame), new Date(this.endDateForTimeFrame), arr)
        if (dates.length > 0) {
          dates.map((info) => {
            this.createLecArr(info)
          })
        }
      }

    },
    getWeekdaysInDateRange(startDate, endDate, weekdays) {
      const resultDates = [];
      eachDayOfInterval({ start: startDate, end: endDate }).forEach((currentDate) => {
        if (weekdays.includes(currentDate.getDay())) {
          const formattedDate = format(currentDate, 'yyyy-MM-dd');
          resultDates.push(formattedDate);
        }
      });
      return resultDates;
    },


    flagChangeForDateType() {
      this.flagForLec = false
      this.applyAllFaculty = false
      this.applyAllRoom = false
      this.applyAllTime = false
      this.applyAllMode = false
      this.flagForTypeSelection = false
      this.createForTiming = ''
      this.selectedShiftForLec = ''
      this.arrForSpecificDateLec = []
      this.multiDateSelection = []
      this.singleDateSelection = ''
      this.selectedDaysForLec = []
      this.intervalCount = 0
      this.selectedTopicForLec = ''
      this.selectedFacultyForLec = ''
      this.typeForLec = ''
      this.selectedRoomForLect = ''
      if (this.typeForDateForEvent === 'SpecificDates' && this.multiDateSelection.length > 0) {
        this.flagForTypeSelection = true
      }
      if (this.typeForDateForEvent === 'SpecificDays') {
        this.flagForTypeSelection = true
      }
      if (this.typeForDateForEvent === 'SpecificIntervals' && this.intervalCount > 0) {
        this.flagForTypeSelection = true
      }

    },
    timeChangedForSpecificDate() {
      let time = this.arrForSpecificDateLec[0].time
      this.arrForSpecificDateLec.map((info) => {
        info.time = time
      })
    },
    createSpecificDateArr() {
      this.applyAllFaculty = false
      this.applyAllRoom = false
      this.applyAllTime = false
      this.applyAllMode = false
      this.createForTiming = ''
      this.selectedShiftForLec = ''
      this.arrForSpecificDateLec = []
      if (this.multiDateSelection.length > 0) {
        this.multiDateSelection.map((info) => {
          this.createLecArr(info)
        })
      }
      if (this.typeForDateForEvent === 'SpecificDates' && this.multiDateSelection.length > 0) {
        this.flagForTypeSelection = true
      }
    },
    async editMultipleLec() {
      try {

        if (this.lecArrForEditInBulk.length > 0) {
          let lect = await this.lecturesRepositoryInstance.updateMultipleLectureDocument({ bulkDataArray: this.lecArrForEditInBulk })
        }

        let start = {
          date: this.startDateForCalender
        }
        let end = {
          date: this.endDateForCalender
        }
        await this.updateRange({ start, end })
        await this.allClashInSem()
        showStatus('Lecture updated Successfully.', 1000, 'success', this)
      } catch (err) {
        console.error('err', err);
      }
      // this.removeEditedValue()
      this.warningForBulkEdit = false
    },
    async editMultipleLecture() {
      this.flagForBulkEditDataFilled = false
      let lectureForEdit = []
      let arrForLec = []
      this.lecArrForEditInBulk = []
      let count = 0
      if (this.events.length > 0) {
        this.events.map((info) => {

          if (info.isEdited === true) {
            if (info.timetableId && info.timetableId !== '') {

            } else {

              if (info.editDateForBulk === '' || info.editedTime === '' || info.editModeForBulk === '') {
                count++
              }
            }
            lectureForEdit.push(info)
          }
        })
      }
      if (count === 0) {
        if (lectureForEdit.length > 0) {
          lectureForEdit.map((lec) => {
            let lectureToArrange = {}

            lectureToArrange = {
              instituteId: lec.instituteId,
              semId: lec.semId,
              department: lec.department,
              courseYear: lec.courseYear,
              subjectId: lec.subjectId,
              isOnlineLecture: lec.editModeForBulk === 'Offline' ? false : true,
              day: new Date(lec.editDateForBulk).getDay(),
              lectureId: lec.lectureId,
              subjectName: lec.subjectName,
            }
            lectureToArrange.editedKeyArr = lec.editedKeyArr
            if (lec.fullName !== '') {
              lectureToArrange.fullName = lec.fullName

              this.facultiesNameWithUid.map((fac) => {
                if (fac.fullName === lec.fullName) {
                  lectureToArrange.uId = fac.uId
                }

              })
            }
            if (lec.batch !== '') {
              lectureToArrange.batch = lec.batch
            }
            if (this.groupInfo.groupName) {
              lectureToArrange['groupForSubject'] = [this.groupInfo]
            }
            if (lec.division !== '') {
              lectureToArrange['division'] = lec.division
            }
            lectureToArrange.roomId = ''
            if (lec.roomName !== '') {
              lectureToArrange.roomName = lec.roomName
              this.allRoomsDept.map((room) => {
                if (room.roomName === lec.roomName) {
                  lectureToArrange.roomId = room.roomId
                }
              })
            }
            if (lectureToArrange.isOnlineLecture === true) {
              lectureToArrange.roomId = ''
            }
            if (lec.timetableId && lec.timetableId !== '') {
              lectureToArrange.dateTime = lec.dateTime
              lectureToArrange.startTime = lec.startTime
              lectureToArrange.endTime = lec.endTime
            } else {
              let [startTime, endTime] = lec.editedTime.split('-')

              lectureToArrange.dateTime = this.getUtcTimeOfLecture(lec.editDateForBulk, startTime)
              lectureToArrange.startTime = this.getUtcTimeOfLecture(lec.editDateForBulk, startTime)
              lectureToArrange.endTime = this.getUtcTimeOfLecture(lec.editDateForBulk, endTime)
            }
            arrForLec.push(lectureToArrange)
          })
          this.lecArrForEditInBulk = arrForLec
        }
      } else {
        this.flagForBulkEditDataFilled = true
      }

      if (this.lecArrForEditInBulk.length > 0) {
        this.warningForBulkEdit = true
      }


    },
    async editLecture() {
      this.progressBar = true
      let lectureToArrange;
      try {
        if (this.selectedEvent.reason && this.selectedEvent.reason !== '' && this.isCancelOnLec) {
          lectureToArrange = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedEvent.department,
            courseYear: this.selectedEvent.courseYear,
            lectureId: this.selectedEvent.lectureId,
            reason: this.selectedEvent.reason,
            status: 6
          }
          if (this.reschedule === 'Yes') {
            let [startTime, endTime] = this.selectedEvent.time.split('-')
            lectureToArrange.status = 7
            let arrForData = []
            let cancelLectureData = [{
              previousDate: this.selectedEvent.dateTime
            }]
            if (this.selectedEvent.canceledLectureData && this.selectedEvent.canceledLectureData.length > 0) {
              arrForData = [...this.selectedEvent.canceledLectureData, ...cancelLectureData]
            } else {
              arrForData = cancelLectureData
            }
            lectureToArrange.canceledLectureData = arrForData
            lectureToArrange.dateTime = this.getUtcTimeOfLecture(this.selectedEvent.dateForEdit, startTime)
            lectureToArrange.startTime = this.getUtcTimeOfLecture(this.selectedEvent.dateForEdit, startTime)
            lectureToArrange.endTime = this.getUtcTimeOfLecture(this.selectedEvent.dateForEdit, endTime)
          }
        } else {
          lectureToArrange = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedEvent.department,
            courseYear: this.selectedEvent.courseYear,
            subjectId: this.selectedEvent.subjectId,
            isOnlineLecture: this.selectedEvent.mode === 'Offline' ? false : true,
            day: new Date(this.selectedEvent.dateForEdit).getDay(),
            lectureId: this.selectedEvent.lectureId,
          }
          lectureToArrange.uId = this.selectedEvent.uId
          if (this.selectedEvent.faculty !== '') {
            this.facultiesNameWithUid.map((fac) => {
              if (fac.fullName === this.selectedEvent.faculty) {
                lectureToArrange.uId = fac.uId
              }

            })
          }
          if (this.selectedEvent.batch && this.selectedEvent.batch !== '') {
            lectureToArrange.batch = this.selectedEvent.batch
          }
          if (this.selectedEvent.groupForSubject && this.selectedEvent.groupForSubject.length > 0) {
            lectureToArrange['groupForSubject'] = this.selectedEvent.groupForSubject
          }
          if (this.selectedEvent.division && this.selectedEvent.division !== '') {
            lectureToArrange['division'] = this.selectedEvent.division
          }
          lectureToArrange.roomId = ''
          if (this.selectedEvent.room !== '') {
            if (this.allRoomsDept.length > 0) {

              this.allRoomsDept.map((room) => {
                if (this.selectedEvent.room) {

                  if (room.roomName === this.selectedEvent.room) {
                    lectureToArrange.roomId = room.roomId
                  }
                }
              })
            }
          }
          if (lectureToArrange.isOnlineLecture === true) {
            lectureToArrange.roomId = ''

          }

          if (this.selectedEvent.timetableId === undefined || this.selectedEvent.timetableId === '') {
            let [startTime, endTime] = this.selectedEvent.time.split('-')

            lectureToArrange.dateTime = this.getUtcTimeOfLecture(this.selectedEvent.dateForEdit, startTime)
            lectureToArrange.startTime = this.getUtcTimeOfLecture(this.selectedEvent.dateForEdit, startTime)
            lectureToArrange.endTime = this.getUtcTimeOfLecture(this.selectedEvent.dateForEdit, endTime)
          }

        }
        let lect = await this.lecturesRepositoryInstance.updateMultipleLectureDocument({ bulkDataArray: [lectureToArrange] })

        let start = {
          date: this.startDateForCalender
        }
        let end = {
          date: this.endDateForCalender
        }
        await this.updateRange({ start, end })
        showStatus('Lecture updated Successfully.', 1000, 'success', this)


      } catch (err) {
        console.error('err', err);
        showStatus('Something went wrong', 1000, 'error', this)

      }
      this.progressBar = false

    },
    async createLecForMultipleDate() {
      this.createEventDialog = false
      this.progressBar = true


      let arrForLec = []
      let arrForTimeSlots = []
      try {
        if (this.arrForSpecificDateLec.length > 0) {
          this.arrForSpecificDateLec.map((data) => {
            if (data.time.length > 0) {
              data.time.map((time) => {
                let lectureToArrange;
                let timetableId = uuidv4()
                let obj = {
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  subjectId: this.sub.subjectId,
                  day: this.getDayNameFromDayNumber(new Date(data.date).getDay()),
                  department: this.selectedDepartmentForLec,
                  courseYear: this.selectedCyForLec,
                  // timetableId: timetableId
                }
                let [startTime, endTime] = time.split('-')
                let [a, b] = startTime.split(':')
                let [c, d] = b.split(' ')
                let timeTableStart = a + '_' + c
                let [x, y] = endTime.split(':')
                let [z, f] = y.split(' ')
                let timeTableEnd = x + '_' + z
                obj.startTime = timeTableStart
                obj.endTime = timeTableEnd

                lectureToArrange = {
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  department: this.selectedDepartmentForLec,
                  courseYear: this.selectedCyForLec,
                  // subjectId: data.subject.subjectId,
                  isOnlineLecture: data.mode === 'Offline' ? false : true,
                  topicId: data.topic ? this.selectedTopicForLec.topicId : '',
                  day: new Date(data.date).getDay()
                }
                if (data.subject.subjectId) {
                  lectureToArrange.subjectId = data.subject.subjectId
                  obj.subjectId = data.subject.subjectId
                }
                if (data.faculty.uId) {
                  lectureToArrange.uId = data.faculty.uId
                  obj.uId = data.faculty.uId
                }
                if (this.selectedBatchForLec !== '') {
                  lectureToArrange.batch = this.selectedBatchForLec
                  obj.batch = this.selectedBatchForLec
                }
                if (this.groupInfo.groupName) {
                  lectureToArrange['groupForSubject'] = [this.groupInfo]
                  obj['groupsForSubject'] = [this.groupInfo]
                }
                if (this.selectedDivisionForLec !== '') {
                  lectureToArrange['division'] = this.selectedDivisionForLec
                  obj['division'] = this.selectedDivisionForLec
                }

                if (data.room.roomId) {
                  lectureToArrange.roomId = data.room.roomId
                  obj.roomId = data.room.roomId
                }
                let flagForDuplicate = true
                if (this.selectedTimeTableView === 'Master Timetable') {
                  let id = ''
                  if (arrForTimeSlots.length > 0) {
                    arrForTimeSlots.map((slot) => {
                      if (slot.startTime === timeTableStart && slot.endTime === timeTableEnd && slot.day === this.getDayNameFromDayNumber(new Date(data.date).getDay())) {
                        flagForDuplicate = false
                        id = slot.timetableId
                      }
                    })
                  }
                  if (id === '') {

                    lectureToArrange.timetableId = timetableId
                    obj.timetableId = timetableId
                  } else {
                    lectureToArrange.timetableId = id
                    obj.timetableId = id
                  }
                }
                lectureToArrange.dateTime = this.getUtcTimeOfLecture(data.date, startTime)
                lectureToArrange.startTime = this.getUtcTimeOfLecture(data.date, startTime)
                lectureToArrange.endTime = this.getUtcTimeOfLecture(data.date, endTime)
                arrForLec.push(lectureToArrange)
                if (flagForDuplicate) {

                  arrForTimeSlots.push(obj)
                }
              })
            }

          })
        }

        if (arrForLec.length > 0) {
          let lect = await this.lecturesRepositoryInstance.createMultipleLectures(arrForLec)
        }
        if (this.selectedTimeTableView === 'Master Timetable' && arrForTimeSlots.length > 0) {
          await this.timetableRepositoryInstance.createSlotsInBulk({ slotsToAdd: arrForTimeSlots })
        }

        let start = {
          date: this.startDateForCalender
        }
        let end = {
          date: this.endDateForCalender
        }
        await this.updateRange({ start, end })
        showStatus('Lecture created Successfully.', 1000, 'success', this)

      } catch (err) {
        console.error('err', err);
        showStatus('Something went wrong', 1000, 'error', this)

      }
      this.progressBar = false

    },
    async createLecForSingleDate() {
      let lectureToArrange;
      let arrForLec = []
      let arrForTimeSlots = []
      // this.progressBar = true

      try {
        if (this.selectedSlotsForLec.length > 0) {
          this.selectedSlotsForLec.map((info) => {
            let timetableId = uuidv4()
            let obj = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              subjectId: this.sub.subjectId,
              day: this.getDayNameFromDayNumber(new Date(this.singleDateSelection).getDay()),
              department: this.selectedDepartmentForLec,
              courseYear: this.selectedCyForLec,
              timetableId: timetableId
            }
            let [startTime, endTime] = info.split('-')
            let [a, b] = startTime.split(':')
            let [c, d] = b.split(' ')
            let timeTableStart = a + '_' + c
            let [x, y] = endTime.split(':')
            let [z, f] = y.split(' ')
            let timeTableEnd = x + '_' + z
            obj.startTime = timeTableStart
            obj.endTime = timeTableEnd

            lectureToArrange = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartmentForLec,
              courseYear: this.selectedCyForLec,
              subjectId: this.sub.subjectId,
              isOnlineLecture: this.typeForLec === 'offline' ? false : true,
              topicId: this.selectedTopicForLec ? this.selectedTopicForLec.topicId : '',
              day: new Date(this.singleDateSelection).getDay()
            }
            if (this.selectedSecondarySubject.subjectId) {
              lectureToArrange.subjectId =  this.selectedSecondarySubject.subjectId
              obj.subjectId =  this.selectedSecondarySubject.subjectId
            }
            if (this.selectedFacultyForLec.uId) {
              lectureToArrange.uId = this.selectedFacultyForLec.uId
              obj.uId = this.selectedFacultyForLec.uId
            }
            if (this.selectedBatchForLec !== '') {
              lectureToArrange.batch = this.selectedBatchForLec
              obj.batch = this.selectedBatchForLec
            }
            if (this.groupInfo.groupName) {
              lectureToArrange['groupForSubject'] = [this.groupInfo]
              obj['groupsForSubject'] = [this.groupInfo]
            }
            if (this.selectedDivisionForLec !== '') {
              lectureToArrange['division'] = this.selectedDivisionForLec
              obj['division'] = this.selectedDivisionForLec
            }

            if (this.selectedRoomForLect.roomId) {
              lectureToArrange.roomId = this.selectedRoomForLect.roomId
              obj.roomId = this.selectedRoomForLect.roomId
            }
            if (this.selectedTimeTableView === 'Master Timetable') {
              lectureToArrange.timetableId = timetableId
            }
            lectureToArrange.dateTime = this.getUtcTimeOfLecture(this.singleDateSelection, startTime)
            lectureToArrange.startTime = this.getUtcTimeOfLecture(this.singleDateSelection, startTime)
            lectureToArrange.endTime = this.getUtcTimeOfLecture(this.singleDateSelection, endTime)
            arrForLec.push(lectureToArrange)
            arrForTimeSlots.push(obj)


          })
        }
        if (arrForLec.length > 0) {
          let lect = await this.lecturesRepositoryInstance.createMultipleLectures(arrForLec)
        }
        if (this.selectedTimeTableView === 'Master Timetable' && arrForTimeSlots.length > 0) {
          await this.timetableRepositoryInstance.createSlotsInBulk({ slotsToAdd: arrForTimeSlots })
        }

        showStatus('Lecture created Successfully.', 1000, 'success', this)

      } catch (err) {
        console.error('err', err);
        showStatus('Something went wrong', 1000, 'error', this)

      }
      this.createEventDialog = false
      this.progressBar = false

    },
    checkAllDataFiledForLecture() {
      let isFilled = true
      this.arrForSpecificDateLec.map((info) => {
        if (info.time.length === 0 || info.mode == '' || info.subject.subjectId === undefined) {
          isFilled = false
        }

      })
      return isFilled;
    },
    createLecture() {
      try {
        if (this.typeForDateForEvent === 'SingleDate') {

          this.createLecForSingleDate()
        } else {

        }
        let check = this.checkAllDataFiledForLecture()
        if (check) {
          this.createLecForMultipleDate()
          this.flagForLec = false
        } else {
          this.flagForLec = true
        }


      } catch (err) {
        console.error('err', err);
      }

    },
    clearAllDataForLectCreation() {
      this.startDateForTimeFrame = ''
      this.endDateForTimeFrame = ''
      this.typeForDateForEvent = ''
      this.singleDateSelection = ''
      this.selectedShiftForLec = ''
      this.selectedSlotsForLec = []
      this.selectedTopicForLec = ''
      this.selectedFacultyForLec = ''
      this.selectedSecondarySubject = ''
      this.typeForLec = ''
      this.selectedRoomForLect = ''
      this.multiDateSelection = []
      this.createForTiming = ''
      this.selectedDaysForLec = []
      this.flagForTypeSelection = false
      this.intervalCount = 0
      this.applyAllFaculty = false
      this.applyAllMode = false
      this.applyAllRoom = false
      this.applyAllTime = false
    },
    getSemStartEndDate() {

      if (this.allSemData.length > 0) {
        this.semStartDate = this.allSemData[0]?.startDate
        this.semEndDate = this.allSemData[0]?.endDate
        this.allSemData.map((sem) => {
          if (this.semStartDate > sem.startDate) {
            this.semStartDate = sem.startDate
          }
          if (this.semEndDate < sem.endDate) {
            this.semEndDate = sem.endDate
          }

        })
      }
      this.startDateForTimeFrame = this.semStartDate?.split('T')[0]
      this.endDateForTimeFrame = this.semEndDate?.split('T')[0]
    },
    getMonthNamesBetweenDates(startDate, endDate) {
      let currentDate = new Date(startDate);
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      const result = [];

      while (currentDate <= endDate) {
        const year = currentDate.getUTCFullYear();
        const month = currentDate.getUTCMonth();
        const monthName = monthNames[month];
        const firstDayOfMonth = new Date(Date.UTC(year, month, 1));
        const lastDayOfMonth = new Date(Date.UTC(year, month + 1, 0));

        result.push({
          monthName: monthName,
          startDate: firstDayOfMonth.toISOString(),
          endDate: lastDayOfMonth.toISOString()
        });

        currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
      }
      return result;
    },



    getSemStartEndDateForInsights() {

      if (this.allSemDataForInsights.length > 0) {
        this.semStartDateForInsights = this.allSemDataForInsights[0]?.startDate
        this.semEndDateForInsights = this.allSemDataForInsights[0]?.endDate
        this.allSemDataForInsights.map((sem) => {
          if (this.semStartDateForInsights > sem.startDate) {
            this.semStartDateForInsights = sem.startDate
          }
          if (this.semEndDateForInsights < sem.endDate) {
            this.semEndDateForInsights = sem.endDate
          }

        })
      }
      this.allMonthData = this.getMonthNamesBetweenDates(new Date(this.semStartDateForInsights), new Date(this.semEndDateForInsights))


    },
    async getAllFacultyForSem() {
      let facultyId = []
      try {
        let faculties =
          await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        if (faculties.length > 0) {
          faculties.map((data) => {
            facultyId.push(data.uId)
          })
          this.facultiesNameWithUid = []
          this.facultiesNameWithUid = await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: facultyId,
          });

        }

      } catch (error) {
        console.error('error', error);
      }


    },

    checkAssigneFaculty(i, info) {
      let filterArray = []
          if (this.facultiesNameWithUid.length > 0) {
            this.facultiesNameWithUid.map((fac) => {
              fac.isAllocated = false
              if (info.subject.faculties.includes(fac.uId)) {
                fac.isAllocated = true
                filterArray.unshift(fac)

              } else {
                filterArray.push(fac)
              }
            })
          }
          info.facultyNameArr = filterArray
      this.$set(this.arrForSpecificDateLec, i, info);

    },
    checkAssigneFacultyForSingleLec() {
      let filterArray = []
          if (this.facultiesNameWithUid.length > 0) {
            this.facultiesNameWithUid.map((fac) => {
              fac.isAllocated = false
              if (this.selectedSecondarySubject.faculties.includes(fac.uId)) {
                fac.isAllocated = true
                filterArray.unshift(fac)

              } else {
                filterArray.push(fac)
              }
            })
          }
          this.facultiesNameWithUid = filterArray

    },
    createLecArr(info) {
      let obj = {
            date: info,
            time: [],
            topic: '',
            faculty: '',
            mode: '',
            room: '',
            subject: '',
            facultyNameArr: this.facultiesNameWithUid
      }
      if (this.selectedSubjectForLec !== 'All') {
        obj.subject = this.sub
      }
      this.arrForSpecificDateLec.push(obj)
    },
    createArrForInterval() {
      this.arrForSpecificDateLec = []
      let dates = this.getDatesForInterval(new Date(this.startDateForTimeFrame), new Date(this.endDateForTimeFrame), this.intervalCount)
      if (dates.length > 0) {
        dates.map((info) => {
          this.createLecArr(info)
        })
      }
    },
    shiftNameChangedForLec() {
      this.applyAllTime = false
      this.slotsForShift = []
      this.allShift.map((shift) => {
        if (shift.shiftName === this.selectedShiftForLec) {
          if (shift.timeSlots.length > 0) {
            shift.timeSlots.map((time) => {
              if (time.isBreak) {
                if (time.isBreak === false) {
                  this.slotsForShift.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))

                }
              } else {

                this.slotsForShift.push(this.timeStringConverter(time.startTime) + '-' + this.timeStringConverter(time.endTime))
              }

            })
          }
        }
      })
      if (this.typeForDateForEvent === 'SpecificIntervals') {
        this.createArrForInterval()
      }
    },
    createEventDialogOpen() {
      this.createEventDialog = true
      this.clearAllDataForLectCreation()
      this.getSemStartEndDate()
    },
    async getTopicOfSubjects(ids) {
      try {
        let id = ids
        if (this.selectedTypeOfEvent === 'Lecture' && this.selectedSubjectForLec !== 'All') {
          id = [this.sub.subjectId]
        }
        this.allTopicOfSelectedSubject = await this.topicRepositoryInstance.getTopicsOfMultiSubject(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            subjectIds: id,
          }
        ) || []
      } catch (err) {
        console.error('err', err);
      }
    },
    async getRoomsForDept() {
      let obj = {
        instituteId: this.selectedInstitute,
      }
      if (this.selectedTypeOfEvent !== 'Lecture') {
        obj.department = this.selectedDepartment
      } else {
        if (this.selectedDepartmentForLec !== '') {

          if (this.selectedDepartmentForLec === 'All') {

            obj.department = this.allDepartmentNames

          } else {

            obj.department = [this.selectedDepartmentForLec]
          }
        }
      }

      try {
        this.allRoomsDept = await this.infrastructureReferenceRepositoryInstance.getRoomsOfMultiDepartment(
          obj
        ) || []
      } catch (err) {
        console.error('err', err);
      }
    },
    async tabChange() {
      if (this.tabs === 1) {
        await this.getSemData()
      }
      if (this.tabs === 2) {
        await this.getInstituteData()
        await this.getSemDetailsForInsights()
      }

    },
    async getSemDetailsForInsights() {
      try {
        this.allSemDataForInsights = await this.semesterRepositoryInstance.getSemestersBySemId({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        })
        this.getSemStartEndDateForInsights()
      } catch (err) {
        console.error('err', err);
      }
    },
    async getSemData() {
      try {

        this.allSemData = await this.semesterRepositoryInstance.getSemestersBySemId({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        })
        this.allDepartmentNames = ['All']
        if (this.allSemData.length > 0) {
          this.allSemData.map((info) => {
            if (!this.allDepartmentNames.includes(info.department)) {

              this.allDepartmentNames.push(info.department)
            }
          })
        }
        this.allDepartmentNames = filterAccessedDepartmentForSubAdmin(this.allDepartmentNames)

        await this.getAllFacultyForSem()
        this.getSemStartEndDate()
      } catch (err) {
        console.error('err', err);
      }
    },
    clearAllDataForLec() {
      this.selectedDepartmentForLec = ''
      this.selectedCyForLec = ''
      this.selectedSubjectForLec = ''
      this.selectedTypeForLec = ''
      this.selectedDivisionForLec = ''
      this.selectedBatchForLec = ''
      this.selectedGroupForLec = ''
      this.cyNamesArrForLec = []
      this.allSubjectsForLec = []
      this.divisionNameArrForLec = []
      this.batchNameArrForLec = []
      this.groupNameArrForLec = []
      this.flagForCalender = false
      this.events = []
    },
    async deptChangeForLec() {
      this.progressBar = true
      this.isCalenderShow = false
      this.selectedCyForLec = ''
      this.selectedSubjectForLec = ''
      this.selectedTypeForLec = ''
      this.selectedDivisionForLec = ''
      this.selectedBatchForLec = ''
      this.selectedGroupForLec = ''
      this.cyNamesArrForLec = []
      this.allSubjectsForLec = []
      this.divisionNameArrForLec = []
      this.batchNameArrForLec = []
      this.groupNameArrForLec = []
      if (this.selectedDepartmentForLec !== '') {
        this.isCalenderShow = true

        this.progressBar = false
        let datesForMonth = this.getMonthStartAndEndDates()
        let start = {
          date: datesForMonth.start.split('T')[0]
        }
        let end = {
          date: datesForMonth.end.split('T')[0]
        }
        await this.updateRange({ start, end })
        // this.progressBar = true
      } else {
        this.events = []
        this.progressBar = false

      }
      this.getRoomsForDept()
      if (this.selectedDepartmentForLec !== '' && this.selectedDepartmentForLec !== 'All') {
        if (this.allSemData.length > 0) {
          this.cyNamesArrForLec = ['All']
          this.allSemData.map((sem) => {
            if (this.selectedDepartmentForLec === sem.department) {
              if (!this.cyNamesArrForLec.includes(sem.courseYear)) {

                this.cyNamesArrForLec.push(sem.courseYear)
              }
            }
          })
        }
      }
      this.cyNamesArrForLec = filterAccessedCourseYearForSubAdmin(this.cyNamesArrForLec, this.selectedDepartmentForLec)
    },
    deptChange() {
      if (this.selectedDepartment.length > 0) {
        this.getRoomsForDept()
      }
      this.cyNamesArr = []
      this.selectedCy = []
      this.allSubjects = []
      this.selectedSubject = []
      this.selectedDivision = []
      this.divisionNameArr = []
      this.selectedType = ''
      this.selectedBatch = []
      this.batchNameArr = []
      this.groupNameArr = []
      this.selectedGroup = []
      if (this.allSemData.length > 0) {
        this.allSemData.map((sem) => {
          if (this.selectedDepartment.includes(sem.department)) {
            this.cyNamesArr.push(sem.department + '_' + sem.courseYear)
          }
        })
      }
    },
    async courseYearChangeForLec() {
      this.flagForCalender = false
      this.selectedSubjectForLec = ''
      this.selectedTypeForLec = ''
      this.selectedDivisionForLec = ''
      this.selectedBatchForLec = ''
      this.selectedGroupForLec = ''
      this.allSubjectsForLec = []
      this.divisionNameArrForLec = []
      this.batchNameArrForLec = []
      this.groupNameArrForLec = []
      this.allSubjectsNames = ['All']
      this.allSubjectNames2 = []
      if (this.selectedCyForLec !== '' && this.selectedCyForLec !== 'All') {
        this.events = []
        if (this.events2.length > 0) {
          this.events2.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec) {
              this.events.push(info)
            }
          })
        }
        try {
          let allSubjectForDept = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartmentForLec,
            courseYear: this.selectedCyForLec
          })
          this.allSubjectOfSem = allSubjectForDept
          if (allSubjectForDept.length > 0) {
            allSubjectForDept.map((sub) => {
              if (this.selectedDepartmentForLec === sub.department && this.selectedCyForLec === sub.courseYear) {
                this.allSubjectsForLec.push(sub)
                this.allSubjectsNames.push(sub.subjectName)
                this.allSubjectsNames2.push(sub)
              }
            })
          }
        } catch (err) {
          console.error('err', err);
        }
      }
      if (this.allSemData.length > 0 && this.selectedCyForLec !== 'All' && this.selectedCyForLec !== '') {

        this.allSemData.map((sem) => {
          if (this.selectedDepartmentForLec === sem.department && this.selectedCyForLec === sem.courseYear) {
            this.divisionNameArrForLec.push(sem.division)
          }
        })
      }

    },
    async courseYearChange() {
      this.allSubjects = []
      this.selectedSubject = []
      this.selectedDivision = []
      this.divisionNameArr = []
      this.selectedType = ''
      this.selectedBatch = []
      this.batchNameArr = []
      this.groupNameArr = []
      this.selectedGroup = []
      if (this.allSemData.length > 0) {
        this.allSemData.map((sem) => {
          if (this.selectedCy.includes(sem.department + '_' + sem.courseYear)) {
            this.divisionNameArr.push(sem.department + '_' + sem.courseYear + '_' + sem.division)
          }
        })
      }
      try {
        let allSubjectForDept = await this.subjectRepositoryInstance.getSubjectsOfMultiDepartment({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
        })
        this.allSubjects = []
        if (allSubjectForDept.subjects.length > 0) {
          allSubjectForDept.subjects.map((sub) => {
            if (this.selectedCy.includes(sub.department + '_' + sub.courseYear)) {
              this.allSubjects.push(sub)
            }
          })
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    batchNameChangeForLec() {

      if (this.selectedBatchForLec !== '') {
        if (this.selectedBatchForLec !== 'All') {
          this.events = []
          if (this.events2.length > 0) {
            this.events2.map((info) => {
              if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId && info.division === this.selectedDivisionForLec && this.selectedBatchForLec === info.batch) {
                this.events.push(info)
              }
            })
          }
        } else {
          this.events = []
          if (this.events2.length > 0) {
            this.events2.map((info) => {
              if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId && this.selectedDivisionForLec === info.division) {
                this.events.push(info)
              }
            })
          }
        }
      }
      if (this.selectedSubjectForLec === 'All') {
        this.events = []
        if (this.events2.length > 0) {
          this.events2.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedDivisionForLec === info.division) {
              if (this.selectedBatchForLec === info.batch) {

                this.events.push(info)
              }
            }
          })
        }
      }
    },
    groupNameChangeForLec() {
      // groupForSubject
      this.groupInfo = {}

      if (this.selectedGroupForLec !== '') {
        if (this.selectedGroupForLec !== 'All') {
          this.sub.groupForSubject.map((grp) => {
            if (grp.groupName === this.selectedGroupForLec) {
              this.groupInfo = grp
            }
          })
          this.events = []
          if (this.events2.length > 0) {
            this.events2.map((info) => {
              if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId) {
                if (info.groupForSubject && info.groupForSubject.length > 0 && info.groupForSubject[0].groupId === this.groupInfo.groupId) {

                  this.events.push(info)
                }
              }
            })
          }

        } else {
          this.events = []
          if (this.events2.length > 0) {
            this.events2.map((info) => {
              if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId) {
                if (info.groupForSubject && info.groupForSubject.length > 0) {
                  this.events.push(info)
                }
              }
            })
          }
        }
      }
    },
    async subjectChangeForLec() {

      this.flagForCalender = false
      this.selectedGroupForLec = ''
      this.groupNameArrForLec = []
      let idArr = []
      this.sub = {}

      if (this.selectedSubjectForLec !== '') {
        if (this.selectedSubjectForLec !== 'All') {
          this.selectedSubjectId = ''
          this.allSubjectsForLec.map((info) => {
            if (this.selectedSubjectForLec === info.subjectName) {
              this.selectedSubjectId = info.subjectId
              this.sub = info
              this.selectedSecondarySubject = info
            }
          })
          let filterArray = []
          if (this.facultiesNameWithUid.length > 0) {
            this.facultiesNameWithUid.map((fac) => {
              fac.isAllocated = false
              if (this.sub.faculties.includes(fac.uId)) {
                fac.isAllocated = true
                filterArray.unshift(fac)

              } else {
                filterArray.push(fac)
              }
            })
          }
          this.facultiesNameWithUid = filterArray
          this.events = []
          if (this.events2.length > 0) {
            this.events2.map((info) => {
              if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId) {
                this.events.push(info)
              }
            })
          }
          this.groupNameArrForLec = ['All']
          if (this.sub.groupForSubject && this.sub.groupForSubject.length > 0) {
            this.sub.groupForSubject.map((info) => {
              this.groupNameArrForLec.push(info.groupName)
            })
          }
        } else {
          this.events = []
          if (this.events2.length > 0) {
            this.events2.map((info) => {
              if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec) {
                this.events.push(info)
              }
            })
          }
        }

      }
      if (this.selectedSubjectForLec !== 'All') {

        if (this.selectedTypeForLec !== '') {
          if (this.selectedDivisionForLec !== '') {
            let arr = []
            if (this.events.length > 0) {
              this.events.map((info) => {
                if (info.division === this.selectedDivisionForLec) {
                  arr.push(info)
                }
              })
              this.events = arr
            }
          }
          if (this.selectedBatchForLec !== '') {
            let arr = []
            if (this.events.length > 0) {
              this.events.map((info) => {
                if (info.batch === this.selectedBatchForLec) {
                  arr.push(info)
                }
              })
              this.events = arr
            }
          }
          if (this.selectedTypeForLec === 'Group') {
            this.events = []
            if (this.events2.length > 0) {
              this.events2.map((info) => {
                if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId) {
                  if (info.groupForSubject && info.groupForSubject.length > 0) {

                    this.events.push(info)
                  }
                }
              })
            }
          }
        }
      } else {
        this.events = []
        if (this.events2.length > 0) {
          this.events2.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec) {
              this.events.push(info)
            }
          })
        }
        if (this.selectedTypeForLec !== '') {
          if (this.selectedDivisionForLec !== '') {
            let arr = []
            if (this.events.length > 0) {
              this.events.map((info) => {
                if (info.division === this.selectedDivisionForLec) {
                  arr.push(info)
                }
              })
              this.events = arr
            }
          }
          if (this.selectedBatchForLec !== '') {
            let arr = []
            if (this.events.length > 0) {
              this.events.map((info) => {
                if (info.batch === this.selectedBatchForLec) {
                  arr.push(info)
                }
              })
              this.events = arr
            }
          }
          if (this.selectedTypeForLec === 'Group') {
            this.events = []
            if (this.events2.length > 0) {
              this.events2.map((info) => {
                if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec) {
                  if (info.groupForSubject && info.groupForSubject.length > 0) {

                    this.events.push(info)
                  }
                }
              })
            }
          }
        }
      }

      await this.getTopicOfSubjects(idArr)

    },
    subjectChange() {
      this.groupNameArr = []
      this.selectedGroup = []
      let idArr = []
      if (this.selectedSubject.length > 0) {
        this.selectedSubject.map((sub) => {
          idArr.push(sub.subjectId)
          if (sub.groupForSubject && sub.groupForSubject.length > 0) {
            this.groupNameArr.push(sub.groupForSubject[0].groupName)
          }
        })
      }
      if (idArr.length > 0) {

        this.getTopicOfSubjects(idArr)
      }
    },
    typeChangeForLec() {
      this.flagForCalender = false

      this.selectedDivisionForLec = ''
      this.selectedBatchForLec = ''
      this.selectedGroupForLec = ''
      this.groupInfo = {}
      let data = []
      this.events = []
      if (this.selectedSubjectForLec !== 'All') {

        if (this.events2.length > 0) {
          this.events2.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId) {
              data.push(info)
            }
          })
        }
      } else {
        if (this.events2.length > 0) {
          this.events2.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec) {
              data.push(info)
            }
          })
        }

      }
      if (this.selectedTypeForLec === 'Group') {
        if (data.length > 0) {
          data.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec) {
              if (info.groupForSubject && info.groupForSubject.length > 0) {
                this.events.push(info)
              }
            }
          })
        }
      }
      if (this.selectedTypeForLec === 'Batch') {
        if (data.length > 0) {
          data.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && info.batch && info.batch !== '') {
              this.events.push(info)

            }
          })
        }
      }
      if (this.selectedTypeForLec === 'Division') {
        if (data.length > 0) {
          data.map((info) => {
            if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && (info.batch === undefined || info.batch === '') && (info.groupForSubject === undefined || info.groupForSubject.length === 0)) {
              this.events.push(info)
            }
          })
        }

      }

    },
    
    typeChange() {
      this.selectedDivision = []
      this.selectedBatch = []
      this.selectedGroup = []
      if (this.selectedTypeForLec === 'Batch') {
        let newArray = divisionNameArrForLec.filter(function (element) {
          return element !== 'All';
        });
        this.divisionNameArrForLec = newArray
      }
      if (this.selectedTypeForLec === 'Division') {
        if (!this.divisionNameArrForLec.includes('All')) {
          this.divisionNameArrForLec.unshift('All')
        }
      }


    },

    divisionChangeForLec() {
      this.flagForCalender = false

      this.selectedBatchForLec = ''
      this.batchNameArrForLec = []
      if (this.selectedDivisionForLec !== '') {
        if (this.selectedDivisionForLec !== 'All') {

          if (this.allSemData.length > 0) {
            this.allSemData.map((sem) => {
              if (this.selectedDepartmentForLec === sem.department && this.selectedCyForLec === sem.courseYear && this.selectedDivisionForLec === sem.division) {
                if (sem.batchesOfDivision.length > 0) {
                  sem.batchesOfDivision.map((batch) => {
                    this.batchNameArrForLec.push(batch.batchName)
                  })
                }
              }
            })
          }

        }

        if (this.selectedSubjectForLec !== 'All') {
          if (this.selectedTypeForLec === 'Batch') {
            this.events = []
            if (this.events2.length > 0) {
              this.events2.map((info) => {
                if (info.courseYear === this.selectedCyForLec && this.selectedSubjectId === info.subjectId && info.department === this.selectedDepartmentForLec && this.selectedDivisionForLec === info.division && info.batch && info.batch !== '') {
                  this.events.push(info)
                }
              })
            }
          }

          if (this.selectedTypeForLec === 'Division') {
            this.events = []
            if (this.events2.length > 0) {
              this.events2.map((info) => {
                if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedSubjectId === info.subjectId && this.selectedDivisionForLec === info.division && (info.batch === undefined || info.batch === '') && (info.groupForSubject === undefined || info.groupForSubject.length === 0)) {
                  this.events.push(info)
                }
              })
            }
          }

        } else {
          if (this.selectedTypeForLec === 'Batch') {
            this.events = []
            if (this.events2.length > 0) {
              this.events2.map((info) => {
                if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedDivisionForLec === info.division && info.batch && info.batch !== '') {
                  this.events.push(info)
                }
              })
            }
          }

          if (this.selectedTypeForLec === 'Division') {
            this.events = []
            if (this.events2.length > 0) {
              this.events2.map((info) => {
                if (info.courseYear === this.selectedCyForLec && info.department === this.selectedDepartmentForLec && this.selectedDivisionForLec === info.division && (info.batch === undefined || info.batch === '') && (info.groupForSubject === undefined || info.groupForSubject.length === 0)) {
                  this.events.push(info)
                }
              })
            }
          }
        }


      }


    },
    divisionChange() {
      this.selectedBatch = []
      this.batchNameArr = []
      if (this.allSemData.length > 0) {
        this.allSemData.map((sem) => {
          if (this.selectedDivision.includes(sem.department + '_' + sem.courseYear + '_' + sem.division)) {
            this.batchNameArr = [...this.batchNameArr, ...sem.batchesOfDivision]
          }
        })
      }
    },

    clearAllDataFromDept() {
      this.isMultiple = false
      if (this.selectedTypeOfEvent !== 'Lecture') {
        this.isMultiple = true
      }
      this.selectedDepartment = []
      this.cyNamesArr = []
      this.selectedCy = []
      this.allSubjects = []
      this.selectedSubject = []
      this.selectedDivision = []
      this.divisionNameArr = []
      this.selectedType = ''
      this.selectedBatch = []
      this.batchNameArr = []
      this.groupNameArr = []
      this.selectedGroup = []
      this.clearAllDataForLec()
    },
    reAssignedData() {
      let data = this.editShiftData
      this.editShiftData = {
        shiftName: '',
        workingDays: [],
        timeSlots: [],
        time: [],
        break: [],
      },
        this.editShiftData = data
    },

    removeTimeslotFromArrEdit(i) {
      this.editShiftData.time.splice(i, 1)
      let count = this.checkEditDataFilled()
      if (count === 0) {
        this.errorFlagEdit = false
      }
      this.reAssignedData()
    },
    removeTimeslotFromArrEditBreak(i) {
      this.editShiftData.break.splice(i, 1)
      let count = this.checkEditDataFilled()
      if (count === 0) {
        this.errorFlagEdit = false
      }
      this.reAssignedData()

    },
    closeEditShiftDialog() {
      this.editShiftDialog = false;
      this.editShiftData = {
        shiftName: '',
        workingDays: [],
        timeSlots: [],
        time: [],
        break: []
      }
    },
    editShiftDialogOpen(data) {
      this.updateShiftDetails = false
      this.editShiftDialog = true
      this.editShiftData = data
      this.editShiftData.time = []
      this.editShiftData.break = []

      this.editShiftData.timeSlots.map((info) => {

        info.id1 = uuidv4()
        info.id2 = uuidv4()
        let [hour, min] = info.startTime.split('_')
        if (hour !== undefined && min !== undefined) {

          info.startTime = hour + ':' + min
        }

        let [hour1, min1] = info.endTime.split('_')
        if (hour1 !== undefined && min1 !== undefined) {

          info.endTime = hour1 + ':' + min1
        }
        if (!info.name) {
          info.name === ''
        }

        if (info.isBreak && info.isBreak === true) {
          this.editShiftData.break.push(info)

        } else {
          info.isBreak = false
          this.editShiftData.time.push(info)

        }
      })
    },
    checkEditDataFilled() {
      let count = 0
      if (this.editShiftData.time.length > 0) {

        this.editShiftData.time.map((info) => {
          if (info.startTime === '' || info.endTime === '' || this.checkForEqualTime(info.startTime, info.endTime)) {
            count++
            this.errorFlagEdit = true
          }
        })
      }
      if (this.editShiftData.break.length > 0) {

        this.editShiftData.break.map((info) => {
          if (info.startTime === '' || info.endTime === '' || info.name === '' || this.checkForEqualTime(info.startTime, info.endTime)) {
            count++
            this.errorFlagEdit = true
          }
        })
      }
      return count;
    },
    async editShift() {
      let count = this.checkEditDataFilled()
      if (count === 0) {
        this.editShiftDialog = false
        this.editShiftData.timeSlots = []
        this.editShiftData.timeSlots = [...this.editShiftData.time, ...this.editShiftData.break]
        this.editShiftData.timeSlots.map((info) => {
          let value = this.compareTimes(info.startTime, info.endTime)
          if (value === 1) {
            let start = info.startTime
            info.startTime = info.endTime
            info.endTime = start
          }
          let [hour, min] = info.startTime.split(':')
          if (hour === 0) {
            hour = 12
          }
          info.startTime = hour + '_' + min
          let [hour1, min1] = info.endTime.split(':')
          if (hour1 === 0) {
            hour1 = 12
          }
          info.endTime = hour1 + '_' + min1
          delete info.id1
          delete info.id2
        })

        try {
          const shift = await this.shiftReferenceRepositoryInstance.updateShiftDetails(this.editShiftData)
          showStatus('Edit Shift Successfully.', 1000, 'success', this)
          await this.fetchShiftData()
        } catch (err) {
          showStatus('Failed to edit shift.', 1000, 'error', this)
          console.error(err)
        }
      }

    },
    async deleteShift() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        shiftId: this.deleteShiftData.shiftId,
        shiftName: this.deleteShiftData.shiftName
      }
      try {
        await this.shiftReferenceRepositoryInstance.deleteShiftDetails(objToPush)
        showStatus('Shift Deleted Successfully.', 1000, 'success', this)
        this.fetchShiftData()
      } catch (err) {
        showStatus('Failed to delete shift.', 1000, 'error', this)
        console.error(err)
      }
      this.deleteShiftDialog = false
    },
    deleteShiftDialogOpen(data) {
      this.deleteShiftDialog = true
      this.deleteShiftData = data
    },
    compareStartTime(event1, event2) {
      const time1 = event1.startTime;
      const time2 = event2.startTime;

      const date1 = new Date(`2000-01-01 ${time1}`);
      const date2 = new Date(`2000-01-01 ${time2}`);

      return date1.getTime() - date2.getTime();
    },
    timeStringConverter(time) {
      return timeStringConverter(time)
    },
    extractHour(key) {
      let match = key.match(/(\d+)_([APMapm]{2})/);
      let hour = parseInt(match[1], 10);
      let amPm = match[2].toUpperCase();

      // Convert PM hours to 24-hour format
      if (amPm === "PM" && hour !== 12) {
        hour += 12;
      }

      return hour;
    },

    // Sort the keys based on the extracted hour

    async fetchShiftData() {
      this.allShift = []
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        this.allShift = await this.shiftReferenceRepositoryInstance.getShiftDetails(objToPush)
        let obj = {}
        if (this.allShift.length > 0) {
          this.allShift.map((info) => {

            info.timeSlots.sort(this.compareStartTime)
            info.timeWiseSortedSlots = {}
            info.sortedKeys = []
            let arr = []
            if (info.timeSlots.length > 0) {
              info.timeSlots.map((time) => {
                let startTimeNumber = this.timeStringConverter(time.startTime).split(':')[0]
                let value = this.timeStringConverter(time.startTime).split(':')[1].split(' ')[1];
                if (!Object.keys(info.timeWiseSortedSlots).includes(startTimeNumber + '_' + value)) {

                  info.timeWiseSortedSlots[startTimeNumber + '_' + value] = [time]
                } else {
                  info.timeWiseSortedSlots[startTimeNumber + '_' + value].push(time)
                }

              })
            }
            info.sortedKeys = Object.keys(info.timeWiseSortedSlots).sort((a, b) => {
              let hourA = this.extractHour(a);
              let hourB = this.extractHour(b);
              return hourA - hourB;
            });
          })

        }
      } catch (err) {
        console.error(err)
      }
    },
    compareTimes(time1, time2) {
      let [hour, min] = time1.split(':')
      if (Number(hour) === 0) {
        hour = 12
      }
      time1 = hour + ':' + min
      let [hour1, min1] = time2.split(':')
      if (Number(hour1) === 0) {
        hour1 = 12
      }
      time2 = hour1 + ':' + min1
      const date1 = new Date(`2000-01-01 ${time1}`);
      const date2 = new Date(`2000-01-01 ${time2}`);

      // Get the timestamps
      const timestamp1 = date1.getTime();
      const timestamp2 = date2.getTime();

      if (timestamp1 < timestamp2) {
        return -1;
      } else if (timestamp1 > timestamp2) {
        return 1;
      } else {
        return 0;
      }
    },
    removeTimeslotFromArr(i) {
      this.arrForTimeSlotCreate.splice(i, 1)
      let count = this.checkCreateDataFiled()
      if (count === 0) {

        this.errorFlag = false
      }
    },
    removeTimeslotFromArrBreak(i) {
      this.arrForTimeSlotCreateBreak.splice(i, 1)
      let count = this.checkCreateDataFiled()
      if (count === 0) {

        this.errorFlag = false
      }
    },
    addTimeSlotsInRowEdit() {
      // this.arrForTimeSlotCreate = []
      this.editShiftData.time.push({
        id1: uuidv4(),
        id2: uuidv4(),
        isBreak: false,
        startTime: '',
        name: '',
        endTime: ''
      })
      this.reAssignedData()


    },
    addTimeSlotsInRowEditBreak() {
      // this.arrForTimeSlotCreate = []
      this.editShiftData.break.push({
        id1: uuidv4(),
        id2: uuidv4(),
        isBreak: true,
        startTime: '',
        endTime: '',
        name: ''
      })

      this.reAssignedData()

    },
    addTimeSlotsInRow() {
      // this.arrForTimeSlotCreate = []
      this.arrForTimeSlotCreate.push({
        id1: uuidv4(),
        id2: uuidv4(),
        isBreak: false,
        startTime: '',
        endTime: ''
      })

    },
    addTimeSlotsInRowBreak() {
      // this.arrForTimeSlotCreate = []
      this.arrForTimeSlotCreateBreak.push({
        id1: uuidv4(),
        id2: uuidv4(),
        isBreak: true,
        startTime: '',
        name: '',
        endTime: ''
      })

    },
    createShiftDialogOpen() {
      this.createShiftDialog = true
      this.errorFlag = false
      this.creteShiftTitle = ''
      this.selectedWeekDaysForCrete = []
      this.arrForTimeSlotCreate = []
    },
    checkForEqualTime(start, end) {
      const dateStartTime = new Date(`2000-01-01 ${start}`);
      const dateEndTime = new Date(`2000-01-01 ${end}`);

      const isTimingEqual = dateStartTime.getTime() === dateEndTime.getTime();
      return isTimingEqual
    },
    checkCreateDataFiled() {
      let count = 0
      this.arrForTimeSlotCreate.map((info) => {
        if (info.startTime === '' || info.endTime === '' || this.checkForEqualTime(info.startTime, info.endTime)) {
          count++
          this.errorFlag = true
        }

      })
      if (this.arrForTimeSlotCreateBreak.length > 0) {

        this.arrForTimeSlotCreateBreak.map((info) => {
          if (info.startTime === '' || info.endTime === '' || info.name === '' || this.checkForEqualTime(info.startTime, info.endTime)) {
            count++
            this.errorFlag = true
          }

        })
      }
      return count;
    },
    async creteShift() {
      let count = this.checkCreateDataFiled()

      if (count === 0) {
        this.createShiftDialog = false
        this.arrForTimeSlotCreate = [...this.arrForTimeSlotCreate, ...this.arrForTimeSlotCreateBreak]
        this.arrForTimeSlotCreate.map((info) => {


          let value = this.compareTimes(info.startTime, info.endTime)
          if (value === 1) {
            let start = info.startTime
            info.startTime = info.endTime
            info.endTime = start
          }
          let [hour, min] = info.startTime.split(':')

          info.startTime = hour + '_' + min
          let [hour1, min1] = info.endTime.split(':')

          info.endTime = hour1 + '_' + min1
          delete info.id1
          delete info.id2
        })

        const objToPush = {
          instituteId: this.selectedInstitute,
          shiftName: this.creteShiftTitle,
          workingDays: this.selectedWeekDaysForCrete,
          timeSlots: this.arrForTimeSlotCreate
        }
        try {
          const shift = await this.shiftReferenceRepositoryInstance.createShiftDetails(objToPush)
          showStatus('Created Shift Successfully.', 1000, 'success', this)
          this.fetchShiftData()
        } catch (err) {
          showStatus('Failed to create shift.', 1000, 'error', this)
          console.error(err)
        }
      }


    }
  },
};
</script>
<style >
.custom-table {
  padding: 10px;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid #DDDDDD;
  text-align: left;
  /* padding: 8px; */
}

.custom-table th {
  background-color: #F2F2F2;
}

.custom-table tr {
  border-bottom: 1px solid #DDDDDD;
}

.custom-table td {
  border-right: 1px solid #DDDDDD;
}
</style>
<style src="./activityEditSemesterTimetable.css" scoped></style>
