<template src='./activityEditSemesterClassteacher.html'></template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import SemesterUserRepository from '../../Repository/SemesterUser'
import showStatus from '../../NetworkManager/showStatus'
import ExcelJS from 'exceljs'
import axios from "axios";

export default {
  name: 'activityEditSemesterClassteacher',
  props: [
    'PropsSelectedSemester',
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  components: {
    inputContainer,
    smallButton
  },
  data() {
    return {
      arrayForInvalidRollNo: [],
      resultOfUpload: {},
      resultOfExcelUpload: false,
      fileForMentor: [],
      showUploadUsersDialog: false,
      loading: false,
      selectedFacultyId: '',
      loadingForMentor: false,
      addStudentDialog: false,
      fullNameOfAllFaculty: [],
      removedStudentFromMentor: [],
      selectedStudentFromMentor: [],
      availableStudent: [],
      allFaculty: [],
      assignedStudentList: [],
      allSemUserWithName: [],
      allStudentOfSem: [],
      search: '',
      search1: '',
      isForMentor: false,
      types: ['Assign Class-teacher', 'Assign Mentor'],
      selectedFacultyForMentor: '',
      selectedType: '',
      selectedSemester: '',
      currentTeacherUid: '',
      currentTeacherName: '',
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedDivision: '',
      departments: [],
      courseYears: [],
      divisions: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      headersMentor: [
        // {
        //   text: 'Sr.No',
        //   value: 'srNo'
        // },
        {
          text: 'Roll No',
          value: 'rollNumber'
        },
        {
          text: 'Name',
          value: 'fullName'
        },
        // {
        //   text: 'Roll no',
        //   value: 'actions'
        // }
      ],
      faculties: [],
      search: '',
      criteriaSelected: false
    }
  },
  created() {

    this.$store.commit('liveData/set_selectedActivityName', 'Semester Classteacher')
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.PropsSelectedSemester

    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    console.log(this.tree)
    if (this.tree) {
      for (let i = 0; i < this.tree.length; i++) {
        this.departments.push(this.tree[i].name)
      }
    } else {
      this.departments = []
    }
    if (this.prop_selectedDepartment && this.prop_selectedCourseYear && this.prop_selectedDivision) {
      this.selectedDepartment = this.prop_selectedDepartment
      this.selectDepartment()
      this.selectedCourseYear = this.prop_selectedCourseYear
      this.selectCourseYear()
      this.selectedDivision = this.prop_selectedDivision
      this.selectDivision()
    }
    this.loading = false
  },
  methods: {
  async uploadDialogClose() {
      this.resultOfExcelUpload = false
      await this.getAllSemUser()

       this.onChangeFaculty()
    },
    openUploadStudentsToMentorDialog() {
      this.showUploadUsersDialog = true
    },
    async uploadExcel() {
      this.showUploadUsersDialog = false
      let response = []
      var formData = new FormData()
      // console.log('this.fileForMentor[0]', this.fileForMentor);
      // console.log('this.allSemUserWithName', this.allSemUserWithName);
      let dummyArr = []
      if(this.allSemUserWithName.length > 0) {
        this.allSemUserWithName.map((data) => {
          if (data.department === this.selectedDepartment && data.courseYear === this.selectedCourseYear) {
            dummyArr.push(data)
          }
        })
      }
      formData.append('excel', this.fileForMentor)
      formData.append('availableStudents', JSON.stringify(dummyArr));
      formData.append('assignedMentorId', this.selectedFacultyId);
      const url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/semester/ParseExcelStudentToMentor'
      try {
        response = await axios.post(
          `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_API_PORT}/excel/semester/ParseExcelStudentToMentor`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then( (res) => {
          // console.log('hello')
          response = res
          this.resultOfExcelUpload = true
          // console.log('this.resultOfExcelUpload', this.resultOfExcelUpload);
          this.resultOfUpload = response.data
          this.arrayForInvalidRollNo = []
          this.arrayForInvalidRollNo = response.data.notAvailableRollNumber
         
        }).catch((err) => {
          this.resultOfExcelUpload = true
          this.resultOfUpload.message = 'Something went wrong with excel.Please try again'
          this.resultOfUpload.notAddedStudents = 0
          this.resultOfUpload.addedStudents = 0
          this.arrayForInvalidRollNo = []
          // this.arrayForInvalidRollNo = err.notAvailableRollNumber
          console.log('err', err)
        });
      } catch (error) {
        console.log('error')
        console.error(error);
        this.loading = false;
      }

    },
    addStyleToExcelCell(cell) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFBF80' }
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
      cell.font = {
        name: 'Calibri',
        family: 4,
        size: 12,
        bold: true
      };
    },
    addBorderAndBoldToExcelCell(cell) {

      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
      cell.font = {
        name: 'Calibri',
        family: 4,
        size: 12,
        bold: true
      };
    },
    async generateExcelMentor() {

      try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
        worksheet.mergeCells('A1:C1');

        worksheet.getCell(1, 1).value = 'Add Student To Mentor'
        worksheet.getCell(1, 1).font = {
          name: 'Calibri',
          family: 4,
          size: 16,
          bold: true
        };
        // worksheet.getCell(2, 1).value = 'Department'
        // worksheet.getCell(3, 1).value = 'Course Year'
        // worksheet.getCell(4, 1).value = 'Mentor Name'
        worksheet.getCell(2, 1).value = 'Note'
        worksheet.getCell(4, 1).value = 'Add Roll Number Below'

        // worksheet.getCell(2, 2).value = this.selectedDepartment
        // worksheet.getCell(3, 2).value = this.selectedCourseYear
        // worksheet.getCell(4, 2).value = this.selectedFacultyForMentor
        worksheet.getCell(2, 2).value = 'Only Add roll no in provided column for assign mentor to student'
        worksheet.getCell(2, 2).alignment = { wrapText: true }
        worksheet.getCell(2, 1).alignment = { vertical: 'top', horizontal: 'left' }
        worksheet.getCell(2, 2).font = {
          name: 'Calibri',
          family: 4,
          size: 12,
          bold: false
        };
        // this.addBorderAndBoldToExcelCell(worksheet.getCell(2, 2))
        // this.addBorderAndBoldToExcelCell(worksheet.getCell(3, 2))
        // this.addBorderAndBoldToExcelCell(worksheet.getCell(4, 2))
        // this.addBorderAndBoldToExcelCell(worksheet.getCell(2, 1))

        // this.addStyleToExcelCell(worksheet.getCell(2, 1))
        // this.addStyleToExcelCell(worksheet.getCell(3, 1))
        this.addStyleToExcelCell(worksheet.getCell(2, 1))
        this.addStyleToExcelCell(worksheet.getCell(4, 1))

        const columnArray = []
        for (let i = 0; i < worksheet.columnCount; i++) {
          columnArray.push({ width: 20 })
        }
        worksheet.columns = columnArray

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `AddStudentToMentor.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    async removeStudent() {
      let dummyArr = []
      this.loadingForMentor = true
      this.removedStudentFromMentor.map((data) => {
        let obj = {}
        obj.uId = data.uId
        obj.instituteId = data.instituteId
        obj.semId = data.semId
        obj.assignedMentor = ''
        obj.department = this.selectedDepartment
        dummyArr.push(obj)
      })
      try {
        let data = await this.semesterUserRepositoryInstance.updateSemesterUser({
          bulkDataArray: dummyArr
        })
        await this.getAllSemUser()
        this.onChangeFaculty()
        this.loadingForMentor = false
      } catch (error) {
        console.log('error', error);
      }
      this.removedStudentFromMentor = []
    },
    async addMentorToStudent() {
      let dummyArr = []
      this.loadingForMentor = true
      this.addStudentDialog = false
      // console.log('this.selectedStudentFromMentor',this.selectedStudentFromMentor)
      this.selectedStudentFromMentor.map((data) => {
        let obj = {}
        obj.uId = data.uId
        obj.instituteId = data.instituteId
        obj.semId = data.semId
        obj.assignedMentor = this.selectedFacultyId
        obj.department = this.selectedDepartment
        dummyArr.push(obj)
        // console.log('obj',obj)
      })
      try {
        console.log('dummyArr', dummyArr)
        let data = await this.semesterUserRepositoryInstance.updateSemesterUser({
          bulkDataArray: dummyArr
        })
        await this.getAllSemUser()
        this.onChangeFaculty()
        this.loadingForMentor = false
      } catch (error) {
        console.log('error', error);
      }
      this.selectedStudentFromMentor = []
    },
     onChangeFaculty() {
      console.log('call');
      this.assignedStudentList = []
      this.availableStudent = []
      this.selectedFacultyId = ''
      console.log('this.allFaculty', this.allFaculty);
      this.allFaculty.map((fac) => {
        if (fac.fullName === this.selectedFacultyForMentor) {
          this.selectedFacultyId = fac.uId
        }
      })
      this.allSemUserWithName.map((stu) => {
        if (stu.assignedMentor && stu.assignedMentor === this.selectedFacultyId) {
          this.assignedStudentList.push(stu)
        } else {
          this.availableStudent.push(stu)
        }
      })
      console.log('this.selectedFacultyId', this.selectedFacultyId);
      console.log('this.assignedStudentList', this.assignedStudentList);
    },
    async typeChanged() {
      this.selectedFacultyForMentor = ''
      this.selectedDivision = ''
      this.loadingForMentor = true
      if (this.selectedType === 'Assign Mentor') {
        this.isForMentor = true
        await this.getAllFacultyOfDepartment()
        await this.getAllSemUser()
      } else {
        this.isForMentor = false
        this.loadingForMentor = false
      }
    },
    async getAllSemUser() {
      this.allStudentOfSem = []
      let uidArr = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      try {
        //name is getStudentsOfAInstitute but it will give all sem student 
        let semUser = await this.semesterUserRepositoryInstance.getStudentsOfAInstitute(objToPush)
        if (semUser.length > 0) {
          semUser.map((data) => {
            uidArr.push(data.uId)
          })
          let fullNameForStudent = await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: uidArr
          })
          semUser.map((info) => {
            fullNameForStudent.map((ele) => {
              if (ele.uId === info.uId) {
                console.log(info.uId)
                info.fullName = ele.fullName
              }
            })
          })
          this.allSemUserWithName = semUser
          console.log('this.allSemUserWithName', this.allSemUserWithName);
        }
        this.loadingForMentor = false
      } catch (error) {
        console.log('error', error);
      }
    },
    async getAllFacultyOfDepartment() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear
      }
      let uidArr = []
      this.fullNameOfAllFaculty = []
      this.allFaculty = []
      try {
        const facultyObjects = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(objToPush) || []
        if (facultyObjects.length > 0) {
          facultyObjects.map((data) => {
            uidArr.push(data.uId)
          })
          this.allFaculty = await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: uidArr
          })
          this.allFaculty.map((data) => {
            this.fullNameOfAllFaculty.push(data.fullName)
          })
        }

      } catch (error) {
        console.log('error', error);
      }
    },
    selectDepartment() {
      this.loading = true
      this.criteriaSelected = false
      this.courseYears = []
      this.divisions = []
      this.selectedCourseYear = ''
      this.selectedType = ''
      this.selectedFacultyForMentor = ''
      this.resetCurrentTeacher()
      this.selectedDivision = ''
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      this.courseYears = this.tree[indexOfDepartment].children.map((child) => child.name)
      console.log(this.courseYears)
      this.loading = false
    },

    selectCourseYear() {
      this.loading = true
      this.divisions = []
      this.selectedType = ''
      this.selectedFacultyForMentor = ''
      this.resetCurrentTeacher()
      this.selectedDivision = ''
      // this.criteriaSelected = false
      const indexOfDepartment = this.departments.indexOf(this.selectedDepartment)
      const indexOfCourseYear = this.courseYears.indexOf(this.selectedCourseYear)
      this.divisions = this.tree[indexOfDepartment].children[indexOfCourseYear].children.map((child) => child.name)
      this.loading = false
    },

    async selectDivision() {
      this.loading = true
      this.resetCurrentTeacher()
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision
      }

      console.log(objToPush)

      try {
        this.currentTeacherUid = await this.semesterRepositoryInstance.getClassTeacherOfADivisionOfASemester(objToPush)

        if (this.currentTeacherUid) {
          this.currentTeacherName = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.currentTeacherUid })
          console.log(this.currentTeacherName)
        } else {
          this.resetCurrentTeacher()
        }

        console.log(this.currentTeacherName, this.currentTeacherUid)
        this.criteriaSelected = true

        // caching faculties for a department. Only when a department changes, get faculties
        if (this.faculties.filter((faculty) => faculty.department === this.selectedDepartment).length === 0) {
          console.log(objToPush)
          this.faculties = []

          const facultyObjects = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(objToPush) || []

          // this.faculties = []

          for (let i = 0; i < facultyObjects.length; i++) {
            const nameOfFaculty = await this.userRepositoryInstance.getFullNameOfUser({ uId: facultyObjects[i].uId })

            if (nameOfFaculty) {
              this.faculties.push(
                {
                  uId: facultyObjects[i].uId,
                  name: nameOfFaculty,
                  department: facultyObjects[i].department
                }
              )
            }
          }
        }
        console.log(this.faculties)
      } catch (err) {
        showStatus('Could not connect to server.', 1000, 'error', this)
        console.log(err)
      }
      this.loading = false
    },

    async assignFaculty(item) {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision,
          classTeacher: item.uId
        }
        console.log(objToPush)

        await this.semesterRepositoryInstance.updateClassTeacherOfADivisionOfASemester(objToPush)
        showStatus('Class teacher allocated successfully.', 1000, 'success', this)
        this.currentTeacherUid = item.uId
        this.currentTeacherName = item.name
      } catch (err) {
        showStatus('Selected faculty may be a class teacher of another division.', 1000, 'error', this)
      }
      this.loading = false
    },

    resetCurrentTeacher() {
      this.currentTeacherUid = ''
      this.currentTeacherName = ''
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: 'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
  }
}
</script>
<style src='./activityEditSemesterClassteacher.css' scoped></style>
