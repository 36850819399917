<template src="./activityFeedbackForm.html"> </template>

<script>
import inputContainer from '../../Components/inputContainer'
import TimetableRepository from '../../Repository/Timetable'
import SubjectsRepository from '../../Repository/Subject'
import TopicFeedbackRepository from '../../Repository/TopicFeedback'
import TopicRepository from '../../Repository/Topic'
import LectureRepository from '../../Repository/Lecture'
import { timeStringConverter } from '../../Services/Utils/DateTimeFormatter'

import { subjectTypes } from '../../Constants/Utils/Statics'
import moment from 'moment'

export default {
  name: 'activityFeedbackForm',
  components: { inputContainer },
  props: ['prop_subject'],
  data() {
    return {
      totalFeedbackRating: 0,
      totalFeedbackUsers: 0,
      userData: null,
      selectedInstitute: '',
      selectedSemester: '',
      subjects: [],
      dropdownSubjects: [],
      selectedSubject: '',
      subjectTypes: subjectTypes,
      feedbackTableHeaders: [
        {
          text: 'Feedback Name',
          value: 'name',
          width: '50%'
        },

        {
          text: 'End Date',
          value: 'endDate',
          width: '30%'
        },
        {
          text: 'Status',
          value: 'status',
          width: '30%'
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '30%',
          align: 'center'
        },
        {
          text: '',
          value: 'action',
          width: '30%',
          align: 'center'
        }
      ],
      feedbacks: [],
      publishedFeedbackTableHeaders: [
        {
          text: 'Feedback Name',
          value: 'name',
          width: '30%'
        },
        {
          text: 'Published on',
          value: 'publishedOn',
          width: '20%',
          align: 'center'
        },
        {
          text: 'End Date',
          value: 'endDate',
          width: '20%',
          align: 'center'
        },
        {
          text: 'Average Feedback',
          value: 'average',
          width: '20%',
          align: 'center'
        },
        {
          text: 'Insights',
          value: 'actions',
          width: '30%',
          align: 'center'
        }
      ],
      publishedFeedbacks: [],
      lectureIds: [],
      topicsList: [],
      topicFeedbacks: [],
      lecturesList: [],
      feedbacksData: [],
      // feedback
      feedbackTopics: ['Computer_SE_A_Accounts_Theory'],
      selectedTopic: 'Computer_SE_A_Accounts_Theory',
      endDateDialog: false,
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      selectedItemIndexToChangeEndDate: undefined
    }
  },
  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Feedback Form')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.subjectsRepositoryInstance = new SubjectsRepository(this)

    this.topicFeedbackRepository = new TopicFeedbackRepository(this)
    this.topicRepository = new TopicRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    // Call API to fetch subjects
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId
        }
        console.log('objToPush', objToPush);
        this.subjects = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(
          objToPush
        )
      } catch (err) {
        console.log(err)
        this.subjects = []
      }
      const promises = []
      for (let i = 0; i < this.subjects.length; i++) {
        try {
          promises.push(this.getDetailsOfSubject(i))
        } catch (err) {
          console.log(err)
        }
      }
      await Promise.allSettled(promises)
      this.subjects = this.subjects.slice()
      this.dropdownSubjects = this.subjects.map(item => {
        item.subject = `${item.courseYear}/${item.department}/${item.division}/${item.subjectName}`
        return `${item.courseYear}/${item.department}/${item.division}/${item.subjectName}`
      })
      if (!this.selectedSubject) {
        if (this.prop_subject) {
          const {
            courseYear,
            department,
            division,
            subjectName
          } = this.prop_subject
          this.selectedSubject = `${courseYear}/${department}/${division}/${subjectName}`
          await this.onSubjectSelect(this.selectedSubject)
        } else {
          this.selectedSubject = this.dropdownSubjects[0]
          await this.onSubjectSelect(this.selectedSubject)
        }
      } else {
        await this.onSubjectSelect(this.selectedSubject)
      }
    },
    async fetchTopicsData(params) {
      // await this.fetchTopicFeedbacks()
      this.feedbacks = []
      this.topicsList = []
      this.lecturesList = []
      this.topics = []

      try {
        this.topics = await this.topicRepository.getTopicsOfASubjectOfAFaculty(
          params
        )
        this.topicsObject = this.topics.reduce((group, topic) => {
          const { topicId } = topic;
          group[topicId] = group[topicId] ?? [];
          group[topicId].push(topic);
          return group;
        }, {});
        this.isTopicSelected = false
        this.topics.forEach(topic => {
          this.lectureIds.push(topic.lectureId)
        });
        this.getLectures()
      } catch (err) {
        console.log(err)
      }
    },
    async getLectures() {
      const objToPush = {
        lectures: this.lectureIds
      }
      this.lecturesList = await this.lectureRepositoryInstance.getLecturesBySubject(
        objToPush
      )
      for (const [key, value] of Object.entries(this.topicsObject)) {
        console.log('key', key);
        if (key !== undefined) {
          let implementedCount = 0
          value.map((topic) => {
            if (topic.isImplemented) {
              implementedCount++
            }
          })
          if (implementedCount === value.length) {
            value[0].isTopicCompleted = true
          }
          this.topicsList.push(value[0])
        }
      }
      this.fetchTopicFeedbacks()
    },
    async fetchTopicFeedbacks() {
      console.log('fetchTopicFeedbacks');
      let tempSubject = {}
      this.subjects.forEach(subject => {
        if (subject.subject === this.selectedSubject) {
          tempSubject = subject
        }
      }
      )
      const obj = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        courseYear: tempSubject.courseYear,
        department: tempSubject.department,
        division: tempSubject.division,
        subjectId: tempSubject.subjectId
      }
      this.topicFeedbacks = await this.topicFeedbackRepository.getTopicFeedbacksForAFacultyForASubject(
        obj
      )
      // const date = new Date()
      // this.topicFeedbacks[0].endDate = date
      this.publishedFeedbacks = []
      this.feedbacksData = []
      this.topicFeedbacks.map((item) => {
        this.topicsList.forEach((topic) => {
          if (topic.topicId === item.topicId) {
            item.endDate = moment(item.endDate).format('YYYY-MM-DD')
            if (moment().diff(moment(item.endDate), 'days') > 0) {
              item.status = 'Published'
              item.publishedOn = item.publishedOn ? item.publishedOn.substr(0, 10) : null
              item.isPublished = true
              item.name = topic.topicName
              this.publishedFeedbacks.push(item)
            } else {
              item.name = topic.topicName
              this.feedbacksData.push(item)
              item.status = 'Ongoing'
            }
          }
        })
      })
      const tempFeedbackData = []
      this.topicsList.map((topic) => {
        if (topic.isTopicCompleted) {
          topic.name = topic.topicName
          topic.status = '-'
          tempFeedbackData.push(topic)
        }
      })
      tempFeedbackData.map((topic) => {
        let topicCheck = false
        this.publishedFeedbacks.map((obj) => {
          if (obj.topicId === topic.topicId) {
            topicCheck = true
          }
          if (obj.responses !== undefined) {
            const responses = []
            for (const [key, value] of Object.entries(obj.responses)) {
              responses.push(key)
              console.log('value', value);
            }
            const temp = parseInt(obj.totalRatingGiven) / responses.length
            obj.average = parseInt(temp) / parseInt(obj.totalUsersResponded)
          }
        })
        this.feedbacksData.map((obj) => {
          if (obj.topicId === topic.topicId) {
            topicCheck = true
          }
        })
        if (!topicCheck) {
          this.feedbacksData.push(topic)
        }
      })
      let totalFeedbackavg = 0
      this.publishedFeedbacks.map((feedback) => {
        if (feedback.average !== undefined) {
          totalFeedbackavg += feedback.average
        }
      })
      this.totalFeedbackRating = Math.round(parseInt(totalFeedbackavg) / this.publishedFeedbacks.length)
    },
    async processTopicLectures(topicLectures) {
      const obj = {}
      obj.topicId = topicLectures[0].topicId
      obj.topicName = topicLectures[0].topicName
      obj.implementedCount = 0
      obj.conductedCount = 0
      obj.lectureCount = topicLectures.length
      obj.isSelected = false
      obj.lectures = topicLectures
      const dates = []
      const dateStrings = []

      for (let i = 0; i < topicLectures.length; i++) {
        const topicLecture = topicLectures[i]
        try {
          const objToPush = {
            instituteId: topicLecture.instituteId,
            semId: topicLecture.semId,
            lectureId: topicLecture.lectureId
          }
          const lecture = await this.lectureRepositoryInstance.getLecture(
            objToPush
          )
          const dateString = lecture.dateTime.slice(0, 10)
          const date = new Date(dateString)
          if (!dates.includes(date.toISOString())) {
            dates.push(date.toISOString())
          }
          if (!dateStrings.includes(dateString)) {
            dateStrings.push(dateString)
          }
          topicLecture.isConducted = lecture.isConducted
          topicLecture.date = lecture.dateTime
          topicLecture.startTime = lecture.startTime
          topicLecture.endTime = lecture.endTime
          if (moment().diff(moment(lecture.endDate), 'days') > 0) {
            this.feedbacksData.push(lecture)
          }

          if (topicLecture.isImplemented) {
            obj.implementedCount++
          }
          if (topicLecture.isConducted) {
            obj.conductedCount++
          }
        } catch (err) {
          console.log(err)
        }
      }
      dates.sort()
      obj.startDate = dates[0]
      obj.startDateString = timeStringConverter(dates[0].slice(0, 10))
      obj.endDate = dates[dates.length - 1]
      obj.endDateString = timeStringConverter(
        dates[dates.length - 1].slice(0, 10)
      )
      obj.dates = dateStrings
      this.topicsList.push(obj)
      // if (moment().diff(moment(feedback.endDate), 'days') > 0) {
      //   feedback.status = 'Published'
      // }
      if (
        moment().diff(moment(obj.endDateString), 'days') > 0 &&
        obj.conductedCount === topicLectures.length
      ) {
        this.feedbacks.push({
          id: obj.topicId,
          name: obj.topicName,
          endDate: 'N/A',
          status: '-'
        })
      }
    },
    async onSubjectSelect(selectedSubject) {
      this.feedbacksData = []
      this.publishedFeedbacks = []
      if (this.subjects.length > 0) {
        let tempSubject = {}
        this.subjects.forEach(subject => {
          if (subject.subject === selectedSubject) {
            tempSubject = subject
          }
        }
        )
        const obj = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          courseYear: tempSubject.courseYear,
          department: tempSubject.department,
          division: tempSubject.division,
          subjectId: tempSubject.subjectId,
          uId: this.userData.uId
        }
        await this.fetchTopicsData(obj)
      }
    },
    // async onSubjectSelect(selectedSubject) {
    //   this.feedbacks = [];
    //   this.topicsList = [];
    //   console.log({ selectedSubject });
    //   this.selectedSubject = selectedSubject;
    //   const params = selectedSubject.split("_");
    //   const subjectId = this.subjects.filter(
    //     item => item.subjectName === params[3]
    //   )[0];
    //   const data = await this.fetchTopics({
    //     instituteId: this.selectedInstitute,
    //     semId: this.selectedSemester.semId,
    //     courseYear: params[0],
    //     department: params[1],
    //     division: params[2],
    //     subjectId: subjectId
    //   });
    //   this.topicsList = data;
    //   this.feedbacks = data.map(item => ({
    //     name: item.topicName,
    //     endDate: "N/A",
    //     status: "-"
    //   }));
    // },
    async getDetailsOfSubject(index) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.subjects[index].subjectId
        }
        const subject = await this.subjectsRepositoryInstance.getDetailsOfASubject(
          objToPush
        )
        this.$store.commit('mappings/add_subjectsMapping', subject)
        this.subjects[index].subjectName = subject.subjectName
        this.subjects[index].lectureType = this.subjectTypes[
          subject.subjectType
        ]
      } catch (err) {
        console.log(err)
      }
    },
    async gotoAttendanceAnalysis(subject) {
      console.log(subject)
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityAttendanceAnalysis',
        params: {
          prop_subject: subject
        }
      })
    },
    async gotoTopics(subject) {
      console.log(subject)
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityTopics',
        params: {
          prop_subject: subject
        }
      })
    },
    gotoAssignments(subject) {
      console.log(subject)
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityAssignments',
        params: {
          prop_subject: subject
        }
      })
    },
    gotoQuickTest(subject) {
      console.log(subject)
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityQuickTest',
        params: {
          prop_subject: subject
        }
      })
    },
    goToFeedbackAnalysis(item) {
      console.log('item', item);
      console.log('totalFeedbackRating', this.totalFeedbackRating);
      this.$store.dispatch('liveData/set_selectedActivity', 10)
      this.$router.push({
        name: 'activityFeedbackAnalysis',
        params: {
          prop_data: {
            avgFeedbackRating: this.totalFeedbackRating,
            SelectedFeedback: item
          }
        }
      })
    },
    goToCreateFeedback(item) {
      this.$store.dispatch('liveData/set_selectedActivity', 11)
      this.$store.commit('liveData/set_selectedTopic', {
        name: item.name,
        id: item.topicId
      })
      console.log('selected topic', item)
      this.$router.push('/activityCreateFeedback')
    },
    onEditEndDate(index) {
      this.endDateDialog = true
      console.log('index', index);
      this.date = this.feedbacksData[index].endDate
      this.selectedItemIndexToChangeEndDate = index
    },
    async onEndDateChange() {
      const index = this.selectedItemIndexToChangeEndDate
      if (index !== undefined) {
        this.feedbacksData[index].endDate = this.date
        this.selectedItemIndexToChangeEndDate = undefined
        this.endDateDialog = false
        const res = await this.topicFeedbackRepository.updateTopicFeedback(this.feedbacksData[index])
        console.log('res', res);
      }
    }
  }
}
</script>

<style src="./activityFeedbackForm.css">
</style>
