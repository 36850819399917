<template src="./activityExamGrouping.html"></template>

<script>
import SemesterUserRepository from "../../../Repository/SemesterUser";
import showStatus from "../../../NetworkManager/showStatus";
import SubjectRepository from "../../../Repository/Subject";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSettingsRepository from "../../../Repository/ExamSettings";
export default {
  name: "activityExamGrouping",
  data() {
    return {
      progressIndicator1: 0,
      examName: "",
      descriptionOfExam: "",
      backgroundColor: "#E7E7FFCF",
      calenderIcon: "mdi-calendar-month-outline",
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
      },
      examFormat: null,
      startDateModel: false,
      startDate: null,
      subjects: [],
      selectedSubjects: [],
      allExams: [],
      uniqueIds: [],
      exams: [],
      model: null,
      cardTexts: ["Internal", "External", "Credits"],
      examSettings: {},
      examCieSeePreference: "",
      subjectsToUpdate: [],
      endDateModel: false,
      endDate: null,
      attainmentCheckbox: false,
      examFormCheckbox: false,
      hallTicketCheckbox: false,
      invigilationCheckbox: false,
      exam: {},
      allSubjectIds: [],
      examToEdit: null,
      examId: null,
      uploadBasicDetailsViaExcel: false,
      basicDetailsExcelFile: null,
      schema: {},
      selectAll: false,
      examTabDependecies: {},
      headers: [
        {
          text: "Select Exams",
          value: "sectionNames",
          sortable: false,
          width: "auto",
          align: "center",
        },
        {
          text: "Exam Name",
          value: "questionNumber",
          sortable: false,
          width: "auto",
          align: "center",
        },
        {
          text: "Marks",
          value: "totalMarks",
          sortable: false,
          width: "auto",
          align: "center",
        },
        {
          text: "Type",
          value: "question",
          sortable: false,
          width: "auto",
          // minwidth: "300px",
          // minWidth: "30%",
          align: "center",
        },
        {
          text: "Date",
          value: "questionMarks",
          width: "auto",
          align: "center",
        },

        {
          text: "Exam Session",
          value: "delete",
          sortable: false,
          width: "auto",
          align: "left",
        },
      ],
      selectedCourseYear: "",
      selectedDepartment: "",
      selectedInstitute: "",
      selectedSemester: {},
      dataLoader: false,
    };
  },
  async created() {
    this.dataLoader = true;
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    await this.fetchData();
    this.selectAll = false;
    await this.toggleSelectAll();
    this.dataLoader = false;
  },
  methods: {
    async fetchData() {
      await this.fetchSubjects();
      await this.getExamSettings();
    },
    async fetchSubjects() {
      const allSubjects =
        await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          }
        );
      this.subjectsArray = allSubjects;
      this.subjects = allSubjects;
    },
    async getExamSettings() {
      const result =
        await this.examSettingsRepositoryInstance.getInstituteExamSetting({
          instituteId: this.selectedInstitute,
        });
      this.examSettings = result.result[0];
      this.examCieSeePreference = this.examSettings.selectedCieSeePreference;
    },
    async toggleSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll === true) {
        this.selectedSubjects = this.subjects?.map((subject) => subject);
      } else {
        this.selectedSubjects = [];
      }
      await this.fechSelectedSubjectExams();
    },
    async fechSelectedSubjectExams() {
      this.uniqueIds = [];
      this.selectedSubjects.forEach((subject) => {
        this.uniqueIds.push(subject.subjectId);
      });
      if (this.uniqueIds?.length > 0) await this.getExamsOfSubjects();
    },
    async getExamsOfSubjects() {
      try {
        this.allExams =
          await this.newExamRepositoryInstance.getAllTypeExamsBySubjectIds({
            subjectIds: this.uniqueIds,
          });
        this.allSubjectIds = [];
        if (this.selectAll) {
          const uniqueSubjectIds = new Set();
          this.allExams?.forEach((exam) => {
            exam?.subjectIds.forEach((id) => {
              uniqueSubjectIds.add(id);
            });
          });

          this.allSubjectIds = Array.from(uniqueSubjectIds);
          //   const data = await this.subjectRepositoryInstance.getSubjectsOnlyByIds(
          //   {
          //     subjectIds: this.subjectsToUpdate,
          //   }
          // );
        }
        const examIdSet = new Set();

        for (let j = 0; j < this.selectedSubjects?.length; j++) {
          const groupedExams = this.selectedSubjects[j]?.groupedExams;
          if (groupedExams) {
            for (const examId of groupedExams) {
              examIdSet.add(examId);
            }
          }
        }

        for (let i = 0; i < this.allExams?.length; i++) {
          if (examIdSet.has(this.allExams[i].examId)) {
            this.allExams[i].selected = true;
          }
        }
      } catch (e) {
        console.error("e", e);
      }
    },
    async subjectExams() {
      this.subjectsToUpdate = [];
      let examList = this.allExams.filter((exam) => {
        if (exam.selected) return exam;
      });
      for (let i = 0; i < this.selectedSubjects?.length; i++) {
        let subject = this.selectedSubjects[i];
        let examIds = [];
        examList.forEach((exam) => {
          if (exam.subjectIds.includes(subject.subjectId))
            examIds.push(exam.examId);
        });
        subject.groupedExams = examIds;
        (subject.instituteId = this.selectedInstitute),
          (subject.semId = this.selectedSemester.semId),
          (subject.department = this.selectedDepartment),
          (subject.courseYear = this.selectedCourseYear),
          this.subjectsToUpdate.push(subject);
      }
      try {
        const data =
          await this.subjectRepositoryInstance.updateMultipleSubjectDetails({
            subjectsToUpdate: this.subjectsToUpdate,
          });
        showStatus("Subject exams grouped successfully", 2000, "success", this);
      } catch (error) {
        console.error("error", error);
      }
    },
  },
};
</script>

<style src="./activityExamGrouping.css" scoped></style>