import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/LectureUnblock";

class LectureUnblock {
  constructor(context) {
    this.context = context;
  }

  async getAllReqForSubjectForFacultyForDivision(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllReqForSubjectForFacultyForDivision,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getAllSemesterRequestToUnblockLec(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllSemesterRequestToUnblockLec,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createSingleRequestForLectureUnlock(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createSingleRequestForLectureUnlock,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async updateSingleRequest(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateSingleRequest,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default LectureUnblock;
