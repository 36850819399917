<template src="./activityQuickTestAveragePerformance.html"></template>

<script>
import apiV3 from "../../NetworkManager/apiV3";

export default {
  name: "activityQuickTestAveragePerformance",
  props: [
    "prop_subjectId",
    "prop_course",
    "prop_division",
    "prop_selectedSemester",
    "prop_department",
    "prop_subjectName",
    "prop_selectedInstitute",
    "prop_examWiseResultAvg",
  ],
  data() {
    return {
      selectedSubject: "",
      series: [
        {
          name: "Average Passing Rate",
          data: [],
        },
        {
          name: "Average Performance",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 380,
          width: "100%",
          type: "line",
        },
        colors: ["#050D92", "#059292"],
        grid: {
          borderColor: "#e7e7e7",
        },
        yaxis: {
          min: 0,
          max: 100,
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return val.toFixed(1);
            },
          },
        },
      },

      exams: [],
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Date (dd/mm/yyyy, hh:MM:ss)",
          value: "dateTimeUTC",
        },
        {
          text: "Duration (minutes)",
          value: "durationInMins",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
          value: "exam",
        },
      ],
      isLoadingExams: false,
      examCount: "",
      examName: [],
      examIds: [],
      allowedStudents: [],
      avgPerformance: 0,
      avgPassingRate: 0,
      passStudentCount: 0,
      totalStudentCount: 0,
      totalMarksObtainedArray: [],
      totalMarksArray: [],
      totalMarksObtained: "",
      totalMarks: "",
      examResult: [],
      AvgPerformanceofExam: "",
      avgPassingRateofExam: "",
      passStudentOfExam: 0,
      totalStudentOfExam: 0,
      AllExam: [],
      avgPerformanceData: [],
      avgPassingRateData: [],
    };
  },
  created() {
    this.getExams();
  },
  methods: {
    async getExams() {
      try {
        this.isLoadingExams = true;
        this.examName = [];
        const { exams = [] } = await apiV3.getRequest("/exam/all", {
          category: "quick_exam",
          semId: this.prop_selectedSemester,
          subjectId: this.prop_subjectId,
        });
        this.AllExam = exams;
        Array.isArray(exams)
          ? exams.map((obj) => {
              this.allowedStudents = obj.allowedStudents;
              this.subjectId = obj.subjectId;
              this.examIds.push(obj._id);
              this.examName.push(obj.name);
              this.singleExam = obj;
            })
          : null;
        this.examCount = this.examName.length;
        this.exams = exams;
        this.isLoadingExams = false;
        this.getAnalysisResult();
      } catch (err) {
        console.error(err);
      }
    },
    async getAnalysisResult() {
      try {
        const examResult = await apiV3.postRequest(
          "/exam/getResultsByAllowedStudents",
          {
            allowedStudents: this.allowedStudents,
          }
        );
        this.examResult = examResult.data;
        Array.isArray(examResult.data)
          ? examResult.data.map((obj) => {
              //all avg calculation
              if (this.examIds.includes(obj.examId)) {
                this.totalStudentCount++;
                if (obj.status === "pass") {
                  this.passStudentCount++;
                  this.totalMarksObtainedArray.push(obj.totalMarksObtained);
                  this.totalMarksArray.push(obj.totalOutOf);
                }
              }
            })
          : null;
        this.totalMarksObtained = this.totalMarksObtainedArray.reduce(function (
          a,
          b
        ) {
          return a + b;
        },
        0);
        this.totalMarks = this.totalMarksArray.reduce(function (a, b) {
          return a + b;
        }, 0);
        const avg = ((this.totalMarksObtained / this.totalMarks) * 100).toFixed(
          0
        );
        if (isNaN(avg)) {
          this.avgPerformance = 0;
        } else {
          this.avgPerformance = avg;
        }
        const avgRate = (
          (this.passStudentCount / this.totalStudentCount) *
          100
        ).toFixed(0);
        if (isNaN(avgRate)) {
          this.avgPassingRate = 0;
        } else {
          this.avgPassingRate = avgRate;
        }
        this.avgCalculation();
      } catch (err) {
        console.log(err);
      }
    },
    async avgCalculation() {
      let avgExamName = [];
      this.examResult.map((result) => {
        Array.isArray(this.prop_examWiseResultAvg)
          ? this.prop_examWiseResultAvg.map((exam) => {
              if (exam.subjectId === this.prop_subjectId) {
                if (result.examId === exam.examId) {
                  avgExamName.push(exam.examName);
                  console.log('exam.passingRate', exam.passingRate);
                  this.series[0].data.push(
                    isNaN(exam.passingRate) ? 0 : Math.round(exam.passingRate)
                  );
                  this.series[1].data.push(
                    isNaN(exam.performanceAverage)
                      ? 0
                      : Math.round(exam.performanceAverage)
                  );
                }
              }
            })
          : null;
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: { categories: avgExamName },
        };
      });
    },
    checkItem(exam) {
      this.$store.commit("liveData/set_selectedExam", exam);
      this.$router.push({
        name: "activityCreateTest",
        params: {
          prop_selectedTest: this.exams,
        },
      });
    },
    async deleteExam() {
      try {
        this.isDeletingExam = true;
        const exam = this.selectedExam;
        await apiV3.deleteRequest("/exam/delete", {
          examId: exam._id,
        });
        this.exams = [];
        this.selectedExam = null;
        this.isDeleteDialogOpen = false;
        this.getExams();
        showStatus("Exam deleted successfully", 3000, "success", this);
      } catch (err) {
        showStatus("An error occured, try again later", 3000, "error", this);
      } finally {
        this.isDeletingExam = false;
      }
    },
    getExamStatus(exam) {
      const now = new Date();
      const examStart = new Date(exam.dateTimeUTC);
      const examEnd = addMinutes(
        new Date(exam.dateTimeUTC),
        exam.durationInMins + 2
      );
      if (isWithinInterval(now, { start: examStart, end: examEnd })) {
        return "ongoing";
      } else if (isAfter(now, examEnd)) {
        return "conducted";
      } else {
        return "published";
      }
    },
    gotoCreateTest() {
      this.$router.push({
        name: "activityCreateTest",
        params: {
          prop_selectedInstitute: this.prop_selectedInstitute,
          prop_selectedSemester: this.prop_selectedSemester,
          prop_subjectId: this.prop_subjectId,
          prop_subjectName: this.prop_subjectName,
          prop_selectedCourse: this.prop_course,
          prop_selectedDivision: this.prop_division,
          prop_selectedDepartment: this.prop_department,
        },
      });
    },
    gotoQuickTestInsight(exam) {
      this.$store.commit("liveData/set_selectedExam", exam);
      this.$router.push({
        name: "activityQuickTestInsight",
      });
    },
  },
};
</script>

<style src="./activityQuickTestAveragePerformance.css" scoped></style>