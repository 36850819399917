import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/GetInstitutesAndSemesters'
import InstituteDataStoreHelper from '../StoreHelpers/InstituteData'

class InstituteData {
  constructor (context) {
    this.context = context
    this.instituteDataStoreHelper = new InstituteDataStoreHelper(context)
  }

  async getInstitutes (data) {
    let institutes = this.context.$store.getters['instituteData/get_applicableInstitutes']
    if (institutes && institutes.length > 0) {
      return institutes
    } else {
      this.context.$store.commit('liveData/set_loading', true)
      const response = await networkManager.getRequest(ApiNames.getInstitutesOfAUser, data)
      institutes = response.instituteUsers || []
      for (let i = 0; i < institutes.length; i++) {
        if (!this.instituteDataStoreHelper.getInstitute(institutes[i].instituteId)) {
          institutes[i].instituteName = await this.getInstituteName({ instituteId: institutes[i].instituteId }) || ''
        }
      }
      this.context.$store.commit('instituteData/set_applicableInstitutes', institutes)
      this.context.$store.commit('liveData/set_loading', false)
      return institutes
    }
  }

  async getInstituteName (data) {
    const response = await networkManager.getRequest(ApiNames.getNameOfInstitute, data)
    return response.instituteName
  }
}

export default InstituteData
