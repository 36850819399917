export default {
  createBuilding: "/infrastructureBuilding/createBuilding",
  createRoom: "/infrastructureRoom/createRoom",
  createEquipments: "/infrastructureRoom/createEquipments",
  getRoomName: "/infrastructureRoom/getRoomName",
  getBuildings: "/infrastructureBuilding/getBuildings",
  getRooms: "/infrastructureRoom/getRoomsOfABuilding",
  getRoomNames: "/infrastructureRoom/getRoomNames",
  getRoomsFromRoomInventory: "/infrastructureRoom/getRoomsFromRoomInventory",
  getRoomsOfADepartment: "/infrastructureRoom/getRoomsOfADepartment",
  getRoomsOfMultiDepartment: "/infrastructureRoom/getRoomsOfMultiDepartment",
  getAllRoomsOfAInstituteAndDepartment: "/infrastructureRoom/getAllRoomsOfAInstituteAndDepartment",
  getEquipmentsOfADepartment: "/infrastructureRoom/getEquipmentsOfADepartment",
  updateRoomName: "/infrastructureRoom/updateRoomName",
  updateRoomDepartment: "/infrastructureRoom/updateDepartmentOfRoom",
  updateSharingDepartments: "/infrastructureRoom/updateSharingDepartments",
  updateType: "/infrastructureRoom/updateType",
  deleteRoom: "/infrastructureRoom/deleteRoom",
  deleteBuilding: "/infrastructureBuilding/deleteBuilding",
  getSignedUrl: "/spaces/presigned_url",
};
