<template src="./activityCourseSubjectAnalysis.html"></template>

<script>
import UserRepository from '../../Repository/User'
export default {
    name: "activityCourseSubjectAnalysis",
    props: ["prop_subSection", 'prop_sections', "prop_user", "prop_selectedSectionIndex", "prop_selectedsubSectionIndex"],
    data() {
        return {
            items: ['All', 'Pending', 'Checked', 'Submitted'],
            search: "",
            subSection: {},
            totalQuizeMarks: 0,
            file: '',
            selectedType: '',
            prop_selectedStudentIndex: 0,
            subsectionCompletedCount: 0,
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Status', value: 'status' },
            ],
            user: [],
            students: [],
            series: [],
            chartOptions: {
                chart: {
                    type: 'donut',
                    width: 400
                },
                labels: ["Pass", "Fail"]
            },
            series1: [{
                name: 'PRODUCT A',
                data: [44, 55, 41, 67, 22, 43]
            }],
            chartOptions1: {
                chart1: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    type: 'datetime',
                    categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
                        '01/05/2011 GMT', '01/06/2011 GMT'
                    ],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },
        }
    },
    created() {
        this.selectedSubject = this.$store.getters['liveData/get_selectedSubject']
        this.userRepositoryInstance = new UserRepository(this)
        this.$store.commit("liveData/set_selectedActivityName", "Courses");
        this.subSection = this.prop_subSection
        console.log('this.subSection', this.subSection);
        this.user = this.prop_user
        if (this.subSection.contentType === 'Assignment') {
            this.headers.push({ text: 'Action', value: 'actions' })
        }
        this.filterStudentData()
        if (this.subSection.contentType === 'Quiz') {
            this.headers.push({ text: 'Action', value: 'quiz' })
            this.passOrFail()
        }
        if (this.subSection.contentType === 'Feedback') {
            this.headers.push({ text: 'Action', value: 'feedback' })
            this.passOrFail()
        }
    },
    computed: {
        filteredProducts() {
            return this.studentFilteredData.filter(p => {
                return p.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
            });
        }
    },
    methods: {
        passOrFail() {
            console.log('this.user', this.user);
            this.subSection.questions.map((q) => {
                this.totalQuizeMarks += parseInt(q.marks)
            })
            console.log('this.totalQuizeMarks', this.totalQuizeMarks);
            console.log('this.subsection.passingPercentage', this.subSection);
            let passStudentCount = 0
            this.user.map((student) => {
                student.sections.map((obj) => {
                    if (this.prop_sections[this.prop_selectedSectionIndex].sectionTitle === obj.sectionTitle) {
                        obj.subSections.map((obj1) => {
                            if (obj1.subsectionTitle === this.prop_sections[this.prop_selectedSectionIndex].subSections[this.prop_selectedsubSectionIndex].subsectionTitle) {
                                if (parseInt(obj1.studentMarks) / parseInt(this.totalQuizeMarks) * 100 >= parseInt(this.subSection.passingPercentage)) {
                                    passStudentCount += 1
                                }
                            }
                        })
                    }
                })
            })
            let failedStudentsCount = this.user.length - passStudentCount
            this.series = [passStudentCount, failedStudentsCount]
        },
        filterStudentData() {
            this.selectedStudent = []
            this.user.map((student) => {
                student.sections.map((obj) => {
                    if (this.prop_sections[this.prop_selectedSectionIndex].sectionTitle === obj.sectionTitle) {
                        obj.subSections.map((obj1) => {
                            if (obj1.subsectionTitle === this.prop_sections[this.prop_selectedSectionIndex].subSections[this.prop_selectedsubSectionIndex].subsectionTitle) {
                                console.log('obj1dfg', obj1);
                                if (obj1.isSubsectionCompleted === true) {
                                    student.isSubsectionCompleted = true
                                } else {
                                    student.isSubsectionCompleted = false
                                }
                            }
                        })
                    }
                })
            })
            this.studentFilteredData = this.user
        },
        filterByassignmentStatus() {
            this.studentFilteredData = []
            if (this.selectedType === "All") {
                this.studentFilteredData = this.user
            } else {
                this.user.map((student) => {
                    if (this.selectedType === "Submitted") {
                        if (student.isSubsectionCompleted === true) {
                            this.studentFilteredData.push(student)
                        }
                    }
                })
                this.user.map((student) => {
                    if (this.selectedType === "Pending") {
                        if (student.isSubsectionCompleted === false) {
                            this.studentFilteredData.push(student)
                        }
                    }
                })
                this.user.map((student) => {
                    if (this.selectedType === "Checked") {
                        if (student.isSubsectionCompleted === true) {
                            this.studentFilteredData.push(student)
                        }
                    }
                })
            }
        },
        goBack() {
            console.log('hello');
            this.$router.push({
                name: "activityCourseAnalysis",
                params: {
                    prop_tab: 2,
                    prop_subSection: this.subSection,
                    prop_user: this.prop_user,
                    prop_sections: this.prop_sections,
                    prop_assignmentUser: this.user,
                    prop_selectedSectionIndex: this.prop_selectedSectionIndex,
                    prop_selectedStudentIndex: this.selectedStudentIndex,
                    prop_selectedsubSectionIndex: this.prop_selectedsubSectionIndex
                }
            });
        },
        gotoactivityCourseAssignmentChecking(user) {
            this.selectedStudentIndex = this.studentFilteredData.indexOf(user)
            console.log('this.selectedStudentIndex', this.selectedStudentIndex);
            {
                this.$router.push({
                    name: "activityCourseAssignmentChecking",
                    params: {
                        prop_subSection: this.subSection,
                        prop_user: this.prop_user,
                        prop_sections: this.prop_sections,
                        prop_assignmentUser: user,
                        prop_selectedSectionIndex: this.prop_selectedSectionIndex,
                        prop_selectedStudentIndex: this.selectedStudentIndex,
                        prop_selectedsubSectionIndex: this.prop_selectedsubSectionIndex
                    }
                });
            }
        },
        gotoactivityCourseQuizResult(user) {
            this.selectedStudentIndex = this.studentFilteredData.indexOf(user)
            console.log('this.selectedStudentIndex', this.selectedStudentIndex);
            {
                this.$router.push({
                    name: "activityQuizResult",
                    params: {
                        prop_subSection: this.subSection,
                        prop_user: this.prop_user,
                        prop_sections: this.prop_sections,
                        prop_selectedSectionIndex: this.prop_selectedSectionIndex,
                        prop_selectedsubSectionIndex: this.prop_selectedsubSectionIndex,
                        prop_selectedStudentIndex: this.selectedStudentIndex
                    }
                });
            }
        },
        gotoFeedback(user) {
            this.selectedStudentIndex = this.studentFilteredData.indexOf(user)
            {
                this.$router.push({
                    name: "feedbackSubmitted",
                    params: {
                        prop_subSection: this.subSection,
                        prop_user: this.prop_user,
                        prop_sections: this.prop_sections,
                        prop_selectedUser: user,
                        prop_selectedSectionIndex: this.prop_selectedSectionIndex,
                        prop_selectedsubSectionIndex: this.prop_selectedsubSectionIndex,
                        prop_selectedStudentIndex: this.selectedStudentIndex
                    }
                });
            }
        },
    }
}

</script>

<style scoped src="./activityCourseSubjectAnalysis.css">

</style>
