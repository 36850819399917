export default {
  getNBA: '/nba/getNBA',
  getDepartmentNBA: '/nba/getDepartmentNBA',
  updateNBA: '/nba/updateNBA',
  updateDepartmentNBA: '/nba/updateDepartmentNBA',

  // 
  getCos: '/criteria3.1/course_outcome/getBylmsInstituteId',
  getPos: '/criteria3.1/PO_PSO/program_outcome/getByLmsInstituteId',
  getPsos: '/criteria3.1/PO_PSO/program_specific_outcome/getByLmsInstituteId',
  deleteExamLms:'/criteria3.2/criteria3.2.1/deleteExamFromLMS',
  getLevelsOfInstitute:'/criteria3.2/criteria3.2.1/getLevelsByLmsInstituteId',
  getTargetsOfInstitute:'/criteria3.2/criteria3.2.1/getTargetsByLmsInstituteId',
  getBatchesOfInstitute:'/setup/batch/getBatchesByLmsInstituteId',
}
