<template src="./activityManageStudyMaterial.html"> </template>
<script>
import Vue from "vue";
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import TopicsRepository from "../../Repository/Topic";
import StudyMaterialRepository from "../../Repository/StudyMaterial";
import _ from "lodash";
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SubjectsRepository from '../../Repository/Subject'
import { convertDate, convertToISTTime } from "../../Services/Utils/DateTimeFormatter";
import { spacesUrl } from "../../NetworkManager";

import {
  Vuetify,
  VApp,
  /* other imports ... */
} from "vuetify";
export default {
  name: 'activityManageStudyMaterial',
  components: {
    inputContainer
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            console.log("hello", downloadURL);
            const indexOfSlash = this.fileCurrentlyUploading.type.indexOf("/");
            this.downloadURLs.push({
              downloadURL: downloadURL,
              isMedia: ["image", "video"].includes(
                this.fileCurrentlyUploading.type
                  .substring(0, indexOfSlash)
                  .toLowerCase()
              )
            });
            this.uploadCount++;
            if (this.uploadCount === this.files.length) {
              this.uploadPost();
            } else {
              this.progressUpload = 0;
              this.upload(this.uploadCount);
            }
          });
        }
      );
    },
    publishAt(val) {
      if (!val) {
        this.$refs.publishAtRef.clearHandler()
      }
    }
  },
  data() {
    return {
      assignedCourseYearForSubAdmin: [],
      isDownloadable: false,
      assignedDepartment: [],
      nonEmptyFiles: 0,
      currentUploadProgress: 0,
      isLoadingMaterial: false,
      isDeletingPost: false,
      isAddingMaterial: false,
      showAddingDialog: false,
      isMobileView: false,
      snackbar: false,
      snackbarColor: 'green',
      editingPostId: "",
      selectedSubjectId: "",
      selectedDepartment: "",
      selectedTopic: "",
      selectedTopicId: "",
      message: "",
      fileCurrentlyUploading: "",
      postIdInFocus: "",
      publishAt: "",
      selectedCourseYear: "",
      selectedDivision: "",
      selectedSubject: "",
      postTitle: "",
      postDescription: "",
      filterFormat: "All",
      filterTopicId: "all",
      filterTopic: "All",
      filterFileFormats: [],
      fileFormats: ["All", "Videos", "Images", "Documents"],
      videoFormats: ["mp4", "avi", "mpeg", "mov", "wmv"],
      imageFormats: ["jpg", "png"],
      documentFormats: ["pdf", "doc", "docx", "txt"],
      semestersList: [],
      departmentsList: [],
      courseYearList: [],
      divisionList: [],
      subjectsList: [],
      posts: [],
      topics: [],
      imageFiles: [],
      documentFiles: [],
      videoFiles: [],
      uploadedVideoFiles: [],
      uploadedImageFiles: [],
      uploadedDocumentFiles: [],
      selectedSemester: {},
      types: ['Division', 'Group'],
      type: '',
      selectedGroup: ''
    }

  },
  created() {
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.createAdmissionButton = false;
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.studyMaterialRepositoryInstance = new StudyMaterialRepository(this);
    this.topicsRepositoryInstance = new TopicsRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Manage Study-Material')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.getSemesters()
  },
  methods: {
    async fetchTopics() {
      this.filterTopic = ''
      console.log('this.selectedSubject', this.selectedSubject)
      this.selectedSubjectId = this.selectedSubject.subjectId
      try {
        const topics = await this.topicsRepositoryInstance.getTopicsOfASubject({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.selectedSubject.subjectId
        });
        // this.topics = topics;
        // if (this.type === 'Group') {
        //   topics = topics.filter
        // }
        let uniqueTopics = topics.map(item => ({
          topicId: item.topicId,
          topicName: item.topicName,
          assignedGroupForSubject: item.assignedGroupForSubject ?? {}
        }));
        if (this.type === 'Group') {
          uniqueTopics = uniqueTopics.filter(topic=> topic.assignedGroupForSubject.groupId === this.selectedGroup.groupId)
        } else {
          uniqueTopics = uniqueTopics.filter(topic=> !topic.assignedGroupForSubject.groupId)
        }
        this.topics = [{ topicId: "all", topicName: "All" }, ...uniqueTopics];
        console.log("Unique Topics", uniqueTopics);
      } catch (error) {
        console.log(error.stack);
      }
      this.fetchPosts()
    },
    appendVideoFileInput() {
      this.videoFiles.push({
        name: "",
        thumbnailUrl: "",
        url: ""
      });
    },

    appendImageFileInput() {
      this.imageFiles.push({
        name: "",
        thumbnailUrl: "",
        url: ""
      });
    },
    appendDocumentFileInput() {
      this.documentFiles.push({
        name: "",
        thumbnailUrl: "",
        url: ""
      });
    },
     async uploadVideos() {
      if (this.videoFiles.length === 0) return [];

      const videoFilePromises = [];
      const videoFiles = this.videoFiles.filter(item => item.name);

      videoFiles.forEach(videoFile => {
        videoFilePromises.push(
          this.getSignedUrl(videoFile, `${this.userData.uId}/videos/`)
        );
      });
      const signedUrlsData = await Promise.all(videoFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      console.log("Signed urls", signedUrlsData);

      const uploadPromises = [];
      if (signedUrls.length === videoFiles.length) {
        videoFiles.forEach((videoFile, i) => {
          uploadPromises.push(this.uploadToSpaces(videoFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);
        console.log("Upload response", uploadResponse);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: videoFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async onTopicSelect(topicName) {
      this.selectedTopic = topicName;
      const selectedTopicObj = this.topics.filter(
        item => item.topicName === topicName
      );
      if (selectedTopicObj.length > 0) {
        const { topicId } = selectedTopicObj[0];
        this.selectedTopicId = topicId;
        console.log("Selected Topic", topicId, topicName);
      }
    },
     async uploadToSpaces(file, signedUrl) {
       console.log('signedUrl', signedUrl);
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          // console.log("Progress", `${(event.loaded / event.total) * 100}%`);
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            console.log(event);
            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );
      console.log('res', res);
      this.currentUploadProgress = 0;

      return res;
    },
      futch(url, opts = {}, onProgress) {
        console.log('url nilesh', url);
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      const signedUrl = await this.studyMaterialRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    onDialogCancelClick() {
      this.showAddingDialog = false;
      this.imageFiles = [];
      this.videoFiles = [];
      this.documentFiles = [];
      this.currentlyUploadingNumber = 0;
    },
    async deleteMaterial() {
      try {
        this.isDeletingPost = true
        await this.studyMaterialRepositoryInstance.deleteStudyMaterialOfFaculty({
          _id: this.postIdInFocus
        });
        this.postIdInFocus = ''
        this.snackbarColor = 'green'
        this.message = 'Deleted Successfully'
        this.snackbar = true
        await this.fetchPosts();
      } catch (e) {
        console.error(e)
        this.snackbarColor = 'red'
        this.message = 'An error occured'
        this.snackbar = true
      } finally {
        this.isDeletingPost = false
      }
    },
    async onDialogAddClick() {
      this.currentlyUploadingNumber = 0;
      this.currentUploadProgress = 0;
      this.uploading = true;
      this.nonEmptyFiles =
        this.imageFiles.filter(item => item.name).length +
        this.videoFiles.filter(item => item.name).length +
        this.documentFiles.filter(item => item.name).length;
      console.log("Total Files", this.nonEmptyFiles);

      this.uploadedVideoFiles = await this.uploadVideos();
      this.uploadedImageFiles = await this.uploadImages();
      this.uploadedDocumentFiles = await this.uploadDocuments();

      this.onDialogCancelClick();
      this.uploading = false;
    },
    async checkifDownloadable() {
      console.log('isDownloadable', this.isDownloadable);
    },
    async uploadImages() {
      if (this.imageFiles.length === 0) return [];
      const imageFilePromises = [];
      const imageFiles = this.imageFiles.filter(item => item.name);
      imageFiles.forEach(imageFile => {
        imageFilePromises.push(
          this.getSignedUrl(imageFile, `${this.userData.uId}/images/`)
        );
      });
      const signedUrlsData = await Promise.all(imageFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      console.log("Signed urls", signedUrlsData);

      const uploadPromises = [];
      if (signedUrls.length === imageFiles.length) {
        imageFiles.forEach((imageFile, i) => {
          uploadPromises.push(this.uploadToSpaces(imageFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);
        console.log("Upload response", uploadResponse);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: imageFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async uploadDocuments() {
      if (this.documentFiles.length === 0) return [];

      const documentFilePromises = [];
      const documentFiles = this.documentFiles.filter(item => item.name);

      documentFiles.forEach(documentFile => {
        documentFilePromises.push(
          this.getSignedUrl(documentFile, `${this.userData.uId}/documents/`)
        );
      });
      const signedUrlsData = await Promise.all(documentFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      console.log("Signed urls", signedUrlsData);

      const uploadPromises = [];
      if (signedUrls.length === documentFiles.length) {
        documentFiles.forEach((documentFile, i) => {
          uploadPromises.push(this.uploadToSpaces(documentFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);
        console.log("Upload response", uploadResponse);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: documentFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async onAddStudyMaterial() {
      try {
        this.isAddingMaterial = true
        const videoMaterials = this.uploadedVideoFiles;
        const imageMaterials = this.uploadedImageFiles;
        const documentMaterials = this.uploadedDocumentFiles;

        const media = [
          ...videoMaterials,
          ...imageMaterials,
          ...documentMaterials
        ];
        const mimeTypes = _.uniqBy(media, "mimeType").map(item => item.mimeType);
        let data = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          media,
          subjectId: this.selectedSubjectId,
          isDownloadable: this.isDownloadable,
          topicId: this.selectedTopicId,
          topicName: this.selectedTopic,
          title: this.postTitle,
          description: this.postDescription,
          isImportant: this.isImportant,
          mimeTypes,
          publishAt: this.publishAt ? this.publishAt : new Date().toISOString()
        };
        if(this.type === 'Group'){
          data.assignedGroupForSubject = {
            groupName: this.selectedGroup.groupName,
            groupId: this.selectedGroup.groupId
          }
        }
        const response = await this.studyMaterialRepositoryInstance.createStudyMaterial(
          data
        );
        console.log("Response", response);
        this.clearFields();
        this.message = "Course Content added successfully!";
        this.snackbarColor = 'green'
        this.snackbar = true;
        await this.fetchPosts();
      } catch (e) {
        this.message = "An error occured, try later";
        this.snackbarColor = 'red'
        this.snackbar = true;
      } finally {
        this.isAddingMaterial = false
      }
    },
     clearFields() {
      this.videoFiles = [];
      this.uploadedVideoFiles = [];
      this.imageFiles = [];
      this.uploadedImageFiles = [];
      this.documentFiles = [];
      this.uploadedDocumentFiles = [];
      this.currentlyUploadingNumber = 0;
      this.isDownloadable = false;
      this.currentUploadProgress = 0;
      this.selectedTopicId = "";
      this.selectedTopic = "";
      this.isImportant = false;
      this.postTitle = "";
      this.postDescription = "";
      this.publishAt = "";
    },
    async onFilterByFileFormat(fileFormat) {
      this.filterFileFormat = fileFormat;
      let mimeTypes = [];
      switch (fileFormat) {
        case "Videos": {
          mimeTypes = this.videoFormats;
          break;
        }
        case "Images": {
          mimeTypes = this.imageFormats;
          break;
        }
        case "Documents": {
          mimeTypes = this.documentFormats;
          break;
        }
        default: {
          mimeTypes = [];
        }
      }

      this.filterFileFormats = mimeTypes;
      const params = {};
      if (mimeTypes.length > 0) {
        params.mimeTypes = mimeTypes;
      }

      if (this.filterTopicId && this.filterTopicId !== "all") {
        params.topicId = this.filterTopicId;
      }
      await this.fetchPosts(params);
    },
    async onFilterByTopic(topicName) {
      this.filterTopic = topicName;
      const selectedTopicObj = this.topics.filter(
        item => item.topicName === topicName
      );
      if (selectedTopicObj.length > 0) {
        const { topicId } = selectedTopicObj[0];
        console.log("Selected Topic", topicId, topicName);
        const params = {
          topicId
        };
        if (topicId === "all") {
          delete params.topicId;
          this.filterTopicId = "";
        } else {
          this.filterTopicId = topicId;
        }
        if (this.filterFileFormats.length > 0) {
          params.mimeTypes = this.filterFileFormats;
        }
        await this.fetchPosts(params);
      }
    },
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semestersList = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
      } catch (err) {
        console.log(err)
      }
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    async fetchDepartments() {
      
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.divisionListOfSem = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        this.departmentsList = []
        this.courseYearList = []
        this.divisionList = []
        this.selectedDepartment = ""
        this.selectedCourseYear = ""
        this.selectedDivision = ""
        this.type = ''
        this.selectedSubject = ''
        this.divisionListOfSem.map((div) => {
          if (!this.departmentsList.includes(div.department)) {
            this.departmentsList.push(div.department)
          }
        })
        let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment.length > 0) {
        this.departmentsList.map((data) => {
          if(this.assignedDepartment.includes(data)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departmentsList = subAdminAssignedDepartmentWiseLecture
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchCourseYear() {
      this.courseYearList = []
      this.divisionList = []
      this.type = ''
      this.selectedSubject = ''
      this.selectedCourseYear = ''
      this.divisionListOfSem.map((div) => {
        if (div.department === this.selectedDepartment)
          if (!this.courseYearList.includes(div.courseYear)) {
            this.courseYearList.push(div.courseYear)
          }
      })
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYearList = allowedCourseYearForSelectedDepartment
      }
    },

    async fetchDivision() {
      this.divisionList = []
      this.type = ''
      this.selectedSubject = ''
      this.selectedDivision = ''
      this.divisionListOfSem.map((div) => {
        if (div.department === this.selectedDepartment) {
          if (div.courseYear === this.selectedCourseYear) {
            if (!this.divisionList.includes(div.division)) {
              this.divisionList.push(div.division)
            }
          }
        }
      })
      // await this.fetchSubjects()
      // console.log('this.subjectsList111', this.subjectsList)
    },
    async fetchSubjects() {
      this.selectedSubject = ''
      try {
        let list = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        });
        console.log('this.type', this.type)
        if(this.type === 'Group'){
          list = list.filter(i=>i.groupForSubject.length>0)
        }
        this.subjectsList = list
        console.log('this.subjectsList123', this.subjectsList)
      } catch (e) {
        console.log('e', e);
      }
    },
    async fetchAssignments() {
      try {
        let list = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        });
        console.log('this.type', this.type)
        if(this.type === 'Group'){
          list = list.filter(i=>i.groupForSubject.length>0)
        }
        this.subjectsList = list
      } catch (e) {
        console.log('e', e);
      }
    },
    async fetchPosts(_params = {}) {
      try {
        this.isLoadingMaterial = true
        const params = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.selectedSubjectId,
          ..._params
        };
        const response = await this.studyMaterialRepositoryInstance.getStudyMaterialOfFaculty(
          params
        );
        if (this.type === "Group") {
          for (let i = response.data.length - 1; i >= 0; i--) {
            if (response.data[i].assignedGroupForSubject?.groupId !== this.selectedGroup.groupId) {
              response.data.splice(i, 1);
            }
            else if (!response.data[i].assignedGroupForSubject) {
              response.data.splice(i, 1);
            }
          }
          this.posts = response.data;
        } else {
          this.posts = response.data.filter(post=> !post.assignedGroupForSubject)
        }
        console.log('this.posts', this.posts)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingMaterial = false
      }
    },
  }
}
</script>
<style src="./activityManageStudyMaterial.css" scoped>
</style>
