<template src="./activityAdditionalFacility.html"></template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import AdditionalFacilityReference from '../../Repository/AdditionalFacilityReference'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import NbaRepository from "../../Repository/NBA";
import { spacesUrl } from "../../NetworkManager";
export default {
    name: 'activityAdditionalFacility',
    components: {
        inputContainer,
        smallButton
    },
    data() {
        return {
            userData: {},
            searchValue: '',
            allFacilityData: [],
            selectedDepartment: [],
            headers: [
                { text: 'Facility Name', value: 'facilityName', sortable: false },
                { text: 'Details', value: 'details' },
                { text: 'Reasons for Creating Facility', value: 'reasonsForCreating', sortable: false },
                { text: 'Utilization', value: 'utilization', sortable: false },
                { text: 'Area in which students are expected to have enhanced learning', value: 'areaOfLearning', sortable: false },
                { text: 'Relevance to POs & PSOs', value: 'pso', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            selectedInstituteId: '',
            addFacility: false,
            departments: [],
            totalPos: [],
            totalPsos: [],
            poList: [],
            psoList: [],
            facilityData: {
                departMent: '',
                category: "",
                facilityName: '',
                quantity: '',
                details: '',
                reasonsForCreating: [{ details: '' }],
                utilization: [{ details: '' }],
                areaOfLearning: [{ details: '' }],
                files: [],
                po: [],
                pso: [],
                expenditure: '',
                year: '',

            },
            facilityEditingData: {},
            facilityEditingModel: false,
            deleteModel: false,
            deletingId: '',
            tableFilterDepartment: "",
            tableFilterByCategory: "",
            AllFacility: [],
            isFilter: false,
        }
    },
    created() {
        this.selectedInstituteId = this.$store.getters['instituteData/get_selectedInstitute']
        this.userData = this.$store.getters["user/get_userData"];
        this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(
            this
        )
        this.additionalFacilityReferenceInstance = new AdditionalFacilityReference()
        this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
        this.nbaRepositoryInstance = new NbaRepository(this);
        this.fetchDepartments()
        this.getProgramOutcomes()
        this.getProgramSpecificOutcomes()
        this.getAllFacility()
    },
    methods: {
        gotoInstituteManagement() {
            this.$router.push('/activityInstitutesManagment')
        },
        refreshPage() {
            this.$router.push('/activityAdditionalFacility')
        },
        async fetchDepartments() {
            this.departments = []

            const objToPush = {
                instituteId: this.selectedInstituteId
            }
            try {
                this.departments = await this.academicReferenceRepositoryInstance.getDepartments(
                    objToPush
                )
            } catch (err) {
                this.departments = []
                console.log(err)
            }
            this.loading = false
        },
        setSelectedDepartment() {
            try {
                this.totalPos = [],
                    this.totalPsos = [],
                    this.poList = [],
                    this.psoList = [],
                    this.selectedDepartment = this.facilityData.departMent
                this.getProgramOutcomes()
                this.getProgramSpecificOutcomes()

            } catch (err) {
                console.log(err)
            }

        },
        async getProgramOutcomes() {
            try {
                const objToPush = {
                    lmsInstituteId: this.selectedInstituteId,
                    department: this.selectedDepartment,
                };
                const nbaData = await this.nbaRepositoryInstance.getPos(objToPush);
                nbaData.data.map((d) => {
                    this.totalPos.push({
                        poNumber: d.poNumber,
                        poName: d.poName
                    });
                });

                nbaData.data.map((d) => {
                    this.poList.push(d.poNumber);
                });
            } catch (error) {
                console.log(error);
            }

        },

        async getProgramSpecificOutcomes() {

            try {
                const nbaData = await this.nbaRepositoryInstance.getPsos({
                    lmsInstituteId: this.selectedInstituteId,
                    department: this.selectedDepartment
                });
                nbaData.data.map((d) => {
                    this.totalPsos.push({
                        psoNumber: d.psoNumber,
                        psoName: d.psoName
                    });
                });

                nbaData.data.map((d) => {
                    this.psoList.push(d.psoName);
                });
            } catch (error) {
                console.log(error);
            }
        },

        closeTheModel() {
            this.facilityData = {
                departMent: '',
                category: "",
                facilityName: '',
                quantity: '',
                details: '',
                reasonsForCreating: [{ details: '' }],
                utilization: [{ details: '' }],
                areaOfLearning: [{ details: '' }],
                files: [],
                po: [],
                pso: [],
                expenditure: '',
                year: '',

            }
            this.facilityEditingModel = false
            this.addFacility = false
        },
        editItem(item) {
            this.facilityData = item;
            this.facilityEditingModel = true
            this.selectedDepartment = item.departMent
            this.setSelectedDepartment()
        },

        async saveEditFacilityData() {
            // cleaning the empty fields from the multiple array
            //  Filtering empty fields
            if (!this.facilityData.facilityName) {
                return
            }
            this.facilityData.reasonsForCreating = this.facilityData.reasonsForCreating.filter((reason, index) => {
                if (index > 0) {
                    return reason.details !== '';
                }
                return true;
            });

            this.facilityData.utilization = this.facilityData.utilization.filter((util, index) => {
                if (index > 0) {
                    return util.details !== '';
                }
                return true;
            });

            this.facilityData.areaOfLearning = this.facilityData.areaOfLearning.filter((area, index) => {
                if (index > 0) {
                    return area.details !== '';
                }
                return true;
            });

            // Checking if arrays are empty and pushing empty object if necessary
            if (this.facilityData.reasonsForCreating.length === 0) {
                this.facilityData.reasonsForCreating.push({ details: '' });
            }

            if (this.facilityData.utilization.length === 0) {
                this.facilityData.utilization.push({ details: '' });
            }

            if (this.facilityData.areaOfLearning.length === 0) {
                this.facilityData.areaOfLearning.push({ details: '' });
            }



            this.facilityData.files = [this.facilityData.files]
            const hasFileChanges = this.facilityData.files.some(file => !file.url);
            // handle file upload if there are changes
            if (hasFileChanges) {
                this.facilityData.files = await this.uploadFilesAndGetUrls(this.facilityData.files);
            }




            const postData = {
                AdditionalFacilityId: this.facilityData.AdditionalFacilityId,
                instituteId: this.selectedInstituteId,
                ...this.facilityData,
            };

            // send updated data to the API
            let response = await this.additionalFacilityReferenceInstance.editFacility(postData);
            this.closeTheModel()
            this.getAllFacility();
        }
        ,
        deleteItem(item) {
            // Delete the selected item
            this.deleteModel = true
            this.deletingId = item.AdditionalFacilityId
        },
        async deleteFacility() {
            const postData = {
                AdditionalFacilityId: this.deletingId
            };
            const response = await this.additionalFacilityReferenceInstance.deleteFacility(postData);
            this.getAllFacility();
            this.deleteModel = false

        },
        async getAllFacility() {
            try {
                const objToPush = {
                    instituteId: this.selectedInstituteId,
                };
                const response = await this.additionalFacilityReferenceInstance.getAllFacility(objToPush);
                this.AllFacility = response.data
                this.allFacilityData = response.data;
                console.log(response.data)
            } catch (error) {
                console.log("errOnGettingData1", error);
            }

        },

        // ___ ADD NEW INPUT FEALD IN FORM
        addReason() {
            if (this.facilityData.reasonsForCreating[this.facilityData.reasonsForCreating.length - 1].details === '') {
                return;
            }
            this.facilityData.reasonsForCreating.push({ details: '' });
        },

        addUtilization() {
            if (this.facilityData.utilization[this.facilityData.utilization.length - 1].details === '') {
                return;
            }
            this.facilityData.utilization.push({ details: '' });
        },
        addAreaOfLearning() {
            if (this.facilityData.areaOfLearning[this.facilityData.areaOfLearning.length - 1].details === '') {
                return;
            }
            this.facilityData.areaOfLearning.push({ details: '' });
        },

        // saving the form data 
        async saveTheFacilityData() {
            // cleaning the empty fealds from the multiple array
            // Filtering empty fields
            if (!this.facilityData.facilityName) {
                return
            }

            this.facilityData.reasonsForCreating = this.facilityData.reasonsForCreating.filter((reason, index) => {
                if (index > 0) {
                    return reason.details !== '';
                }
                return true;
            });

            this.facilityData.utilization = this.facilityData.utilization.filter((util, index) => {
                if (index > 0) {
                    return util.details !== '';
                }
                return true;
            });

            this.facilityData.areaOfLearning = this.facilityData.areaOfLearning.filter((area, index) => {
                if (index > 0) {
                    return area.details !== '';
                }
                return true;
            });

            // Checking if arrays are empty and pushing empty object if necessary
            if (this.facilityData.reasonsForCreating.length === 0) {
                this.facilityData.reasonsForCreating.push({ details: '' });
            }

            if (this.facilityData.utilization.length === 0) {
                this.facilityData.utilization.push({ details: '' });
            }

            if (this.facilityData.areaOfLearning.length === 0) {
                this.facilityData.areaOfLearning.push({ details: '' });
            }

            // handle file form here

            if (this.facilityData.files) {
                this.facilityData.files = [this.facilityData.files]
                this.facilityData.files = await this.uploadFilesAndGetUrls(this.facilityData.files);
            }

            const postdata = {
                instituteId: this.selectedInstituteId, ...this.facilityData,
            }
            // send to api
            let response = this.additionalFacilityReferenceInstance.saveFacility(postdata)
            // clean and false the model
            this.facilityData = {
                departMent: '',
                category: "",
                facilityName: '',
                quantity: '',
                details: '',
                reasonsForCreating: [{ details: '' }],
                utilization: [{ details: '' }],
                areaOfLearning: [{ details: '' }],
                file: [],
                po: [],
                pso: [],
                expenditure: '',
                year: '',
            }
            this.getAllFacility()
            this.addFacility = false

        },

        // file uploading

        async uploadFilesAndGetUrls(files) {
            const urls = [];
            const attachedFiles = files.filter(file => file.name);
            const attachedFilePromises = attachedFiles.map(attachedFile =>
                this.getSignedUrl(attachedFile, `facility/${this.selectedInstituteId}`)
            );
            const signedUrlsData = await Promise.all(attachedFilePromises);
            const signedUrls = signedUrlsData.map(item => item.signedUrl);
            const uploadPromises = [];
            if (signedUrls.length === attachedFiles.length) {
                await Promise.all(
                    attachedFiles.map((attachedFile, i) =>
                        this.uploadToSpaces(attachedFile, signedUrls[i])
                    )
                );
                signedUrlsData.forEach((item, i) => {
                    urls.push({
                        url: spacesUrl + "/" + item.fileName,
                        name: attachedFiles[i].name,
                        mimeType: item.mimeType,
                    });
                });
            }
            return urls;
        },

        async getSignedUrl(file) {
            const fileName = file.name;
            const body = {
                fileName,
                fileType: file.type,
            };
            const signedUrl = await this.infrastructureReferenceRepositoryInstance.getSignedUrl(
                body
            );
            return signedUrl;
        }
        ,
        async uploadToSpaces(file, signedUrl) {
            const res = await this.futch(signedUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type,
                    "x-amz-acl": "public-read",
                },
            });
            return res;
        },

        futch(url, opts = {}, onProgress) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.open(opts.method || "get", url);
                for (var k in opts.headers || {}) {
                    xhr.setRequestHeader(k, opts.headers[k]);
                }
                xhr.onload = (e) => resolve(e.target.responseText);
                xhr.onerror = reject;
                if (xhr.upload && onProgress)
                    xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
                xhr.send(opts.body);
            });
        },
        filterTable() {
            let filtered = []
            this.isFilter = true

            if (this.tableFilterDepartment) {
                filtered = this.AllFacility.filter(item => item.departMent === this.tableFilterDepartment);
            }
            if (this.tableFilterByCategory) {
                filtered = this.AllFacility.filter(item => item.category === this.tableFilterByCategory);
            }
            this.allFacilityData = filtered

            if (this.tableFilterDepartment === "" && this.tableFilterByCategory === "") {
                this.getAllFacility()
                this.isFilter = false
            }

        },
        removeFilterDepartment() {
            this.tableFilterDepartment = ""
            this.filterTable()
        },
        removeFilterCategory() {
            this.tableFilterByCategory = ""
            this.filterTable()
        }

    }
}
</script>
<style scoped src="./activityAdditionalFacility.css"></style>
