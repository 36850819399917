<template src='./activitySyllabusTracking.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import { subjectTypes } from '../../Constants/Utils/Statics'
import smallButton from '../../Components/smallButton'
import UserRepository from '../../Repository/User'
import LectureRepository from '../../Repository/Lecture'
import TopicRepository from '../../Repository/Topic'
import SemesterRepository from '../../Repository/Semester'
import SemesterUserRepository from '../../Repository/SemesterUser'
import TimetableRepository from '../../Repository/Timetable'
import SubjectRepository from '../../Repository/Subject'
import { formatToSlashSepearated, timeStringConverter, convertDate, convertUTCDateToLocalDate } from '../../Services/Utils/DateTimeFormatter'
import arrayOps from '../../Services/Utils/arrayOps'
export default {
  name: 'activitySyllabusTracking',
  components: {
    inputContainer,
    smallButton
  },
  data() {
    return {
      assignedDepartment: [],
      dataFetched: false,
      updatingTopic: false,
      editbtn: false,
      loading: false,
      isMobileView: false,
      stepOneCompleted: false,
      semesters: [],
      semestersList: [],
      selectedSemesterName: '',
      departments: [],
      lectureIds: [],
      selectedDepartment: '',
      selectedDate: '',
      selectedFaculty: '',
      faculties: [],
      facultyNames: [],
      allocations: [],
      // occupiedSlots: [],
      subjectsMapping: {},
      selectedAllocation: {
        name: ''
      },
      dates: [],
      datesBackup: [],
      userData: {},
      addingTopic: false,
      subjectProgress: 0,
      topicsList: [],
      headers: [
        {
          text: 'Name Of Topic',
          value: 'topicName'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'End Date',
          value: 'endDate'
        },
        {
          text: 'No. Of Lectures',
          value: 'lectureCount'
        },
        {
          text: 'Feedback',
          value: 'status',
          align: 'center'
        }
      ],
      tempTopicName: '',
      selectedTopic: {},
      topicsMapping: {},
      lecturesList: [],
      headers2: [
        {
          text: '',
          value: 'selection'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Time',
          value: 'time'
        }
      ],
      headers3: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Conducted',
          value: 'isConducted'
        },
        {
          text: 'Implementation',
          value: 'action'
        }
      ],
      headers4: [
        {
          text: '',
          value: 'statusDot'
        },
        {
          text: 'Question',
          value: 'question'
        },
        {
          text: 'Average Answer',
          value: 'averageAnswer'
        }
      ],
      selection: [],
      isTopicSelected: false,
      selectedSubject: {},
      processingDate: false,
      showStudentFeedback: false,
      topicFeedbackQuestions: [],
      isFeedbackLaunched: false,
      subjectTypes: subjectTypes,
      count: 0
    }
  },
  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Syllabus Tracker')
    // if (this.prop_subject) {
    //   this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    // } else {
    //   this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    // }
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.topicRepositoryInstance = new TopicRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    selectSemester() {
      this.departments = []
      this.facultyNames = []
      this.selectedFaculty = ''
      this.selectedDepartment = ''
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      this.$store.dispatch(
        "instituteData/set_selectedSemester",
        this.selectedSemester
      );
      this.setupDataForActivity()
    },
    getSemesterObjectOfSelectedSemester(semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity() {
      this.dataFetched = false
      this.stepOneCompleted = false
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      console.log(objToPush)
      try {
        this.departments = []
        const divs = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
        let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment.length > 0) {
        this.departments.map((data) => {
          if(this.assignedDepartment.includes(data)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departments = subAdminAssignedDepartmentWiseLecture
        }
      } catch (err) {
        console.log(err)
      }
    },
    async downloadTemplate() {
      this.selectedSubject['uId'] = this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId
      console.log("this.selectedSubject", this.selectedSubject)
      try {
        const pdfBlob =
          await this.topicRepositoryInstance.generateTopicReport(
            this.selectedSubject
          )
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Topic Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    async selectDate() {
      this.dataFetched = false
      this.selectedDepartment = ''
      this.selectedFaculty = ''
      this.faculties = []
      this.facultyNames = []
      console.log(new Date(this.selectedDate))
    },
    async selectDepartment() {
      this.dataFetched = false
      this.stepOneCompleted = false
      this.faculties = []
      this.facultyNames = []
      this.selectedFaculty = ''
      this.faculties = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment
      })
      let promises = []
      promises = this.faculties.map((faculty) => {
        return this.userRepositoryInstance.getFullNameOfUser({ uId: faculty.uId })
      })
      const allSettledPromises = await Promise.allSettled(promises)
      this.facultyNames = []
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          // console.log(allSettledPromises[i].value)
          this.facultyNames.push(allSettledPromises[i].value)
        }
      }
      console.log(this.facultyNames)
    },
    async selectFaculty() {
      this.dataFetched = false
      this.stepOneCompleted = false
      console.log(this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId
      }
      try {
        let res = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(objToPush)
        console.log('selectedDepartment', this.selectedDepartment)
        res = res.filter(i=>i.department === this.selectedDepartment)
        console.log(res)
        this.allocations = res
      } catch (err) {
        console.log(err)
        this.allocations = []
      }
      // try {
      //   objToPush.dateOfLecturesNeeded = new Date(this.selectedDate).toISOString()
      //   this.occupiedSlots = await this.lecturesRepositoryInstance.getLecturesForADay(objToPush)
      //   console.log(this.occupiedSlots)
      // } catch (err) {
      //   this.occupiedSlots = []
      //   console.log(err)
      // }
      try {
        // for (let i = 0; i < this.allocations.length; i++) {
        //   await this.getSubjectDetails(this.allocations[i])
        // }
        let promises = []
        promises = this.allocations.map((allocation) => {
          return this.getSubjectDetails(allocation.subjectId)
        })
        const allSettledPromises = await Promise.allSettled(promises)
        for (let i = 0; i < allSettledPromises.length; i++) {
          if (allSettledPromises[i].value) {
            // console.log(allSettledPromises[i].value)
            this.subjectsMapping[allSettledPromises[i].value.subjectId] = (allSettledPromises[i].value)
          }
        }
        console.log(this.subjectsMapping, allSettledPromises)
        this.dataFetched = true
      } catch (err) {
        console.log(err)
      }
    },
    async getSubjectDetails(subjectId) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: subjectId
      }
      try {
        const response = await this.subjectRepositoryInstance.getDetailsOfASubject(objToPush)
        console.log(objToPush, {
          subjectId: subjectId,
          subjectName: response.subjectName,
          subjectType: response.subjectType
        })
        return {
          subjectId: subjectId,
          subjectName: response.subjectName,
          subjectType: response.subjectType
        }
      } catch (err) {
        console.log(err)
        return err
      }
    },
    async selectAllocation(allocation) {
      this.selectedAllocation = {
        name: allocation.subjectId + '_' + allocation.department + '_' + allocation.courseYear + '_' + allocation.division + '_' + allocation.batch,
        department: allocation.department,
        courseYear: allocation.courseYear,
        division: allocation.division,
        batch: allocation.batch,
        subjectId: allocation.subjectId
      }
      allocation.subjectName = this.subjectsMapping[allocation.subjectId].subjectName
      this.selectedSubject = allocation
      this.stepOneCompleted = true
      await this.fetchData2()
    },
    async fetchData2() {
      this.selectedTopic = {}
      this.selection = []
      this.lecturesList = []
      this.dates = []
      this.topicsList = []
      this.topics = []
      this.topicsMapping = {}
      console.log('this.selectedSubject', this.selectedSubject)

      try {
        this.topics = await this.topicRepositoryInstance.getTopicsOfASubjectOfAFaculty(
          this.selectedSubject
        )
        console.log('this.topics111', this.topics)
        if (this.topics.length) {
          this.isAllTopicCount = this.topics.length;
        }
        this.isImplementedCountNumber = 0
        this.topics.forEach(topic => {
          if (topic.isImplemented === true) {
            this.isImplementedCountNumber++
          }
          this.lectureIds.push(topic.lectureId)
        });
        this.topicsObject = this.topics.reduce((group, topic) => {
          const { topicId } = topic;
          group[topicId] = group[topicId] ?? [];
          group[topicId].push(topic);
          return group;
        }, {});
        // for (const [key, value] of Object.entries(this.topicsObject)) {

        // }
        this.fetchLectures()
        this.isTopicSelected = false
        if (this.isAllTopicCount > 0) {
          this.subjectProgress = ((this.isImplementedCountNumber / this.isAllTopicCount) * 100).toFixed(0)
        } else {
          this.subjectProgress = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchLectures() {
      const objToPush = {
        lectures: this.lectureIds
      }
      this.lecturesList = await this.lectureRepositoryInstance.getLecturesBySubject(
        objToPush
      )
      for (const [key, value] of Object.entries(this.topicsObject)) {
        if (key !== 'undefined') {
          value[0].conductedCount = 0
          value[0].implementedCount = 0
          let dates = []
          value.map((val) => {
            this.lecturesList.map((lecture) => {
              if (val.lectureId === lecture.lectureId) {
                if (lecture.isConducted === true) {
                  value[0].conductedCount++
                }
                if (val.isImplemented === true) {
                  value[0].implementedCount++
                }
                if(lecture.dateTime){
                  if (!(dates.includes(lecture.dateTime)))
                  {
                    dates.push(lecture.dateTime)
                  }
                }
              }
            })
          })
          value[0].startDate = dates[0] ? dates[0].slice(0, 10) : "-"
          value[0].endDate = dates[0] ? dates[dates.length - 1].slice(0, 10) : "-"
          value[0].lectureCount = 0
          value[0].lectureCount = value.length
          console.log('value[0]', value[0], this.selectedSubject.groupsForSubject?.length > 0)
          if (this.selectedSubject.groupsForSubject?.length === 0 && value[0].division !== '') {
            this.topicsList.push(value[0])
          }
          if (this.selectedSubject.groupsForSubject?.length > 0 && value[0].assignedGroupForSubject) {
            this.topicsList.push(value[0])
          }
          // this.topicsList.push(value[0])
        }
      }
      console.log('this.topicsList', this.topicsList);
    },
    async processTopicLectures(topicLectures) {
      const obj = {}
      obj.topicId = topicLectures[0].topicId
      obj.topicName = topicLectures[0].topicName
      obj.implementedCount = 0
      obj.conductedCount = 0
      obj.lectureCount = topicLectures.length
      obj.isSelected = false
      obj.lectures = topicLectures
      const dates = []
      const dateStrings = []
      for (let i = 0; i < topicLectures.length; i++) {
        const topicLecture = topicLectures[i]
        try {
          const objToPush = {
            instituteId: topicLecture.instituteId,
            semId: topicLecture.semId,
            lectureId: topicLecture.lectureId
          }
          console.log(objToPush)
          const lecture = await this.lectureRepositoryInstance.getLecture(objToPush)
          const dateString = lecture.dateTime.slice(0, 10)
          const date = new Date(dateString)
          if (!(dates.includes(date.toISOString()))) { dates.push(date.toISOString()) }
          if (!(dateStrings.includes(dateString))) { dateStrings.push(dateString) }
          topicLecture.isConducted = lecture.isConducted
          topicLecture.date = lecture.dateTime
          topicLecture.startTime = lecture.startTime
          topicLecture.endTime = lecture.endTime
          if (topicLecture.isImplemented) { obj.implementedCount++ }
          if (topicLecture.isConducted) { obj.conductedCount++ }
        } catch (err) {
          console.log(err)
        }
      }
      if (obj.implementedCount === topicLectures.length) {
        this.count++
        console.log('implemented Topic Found!')
      }
      dates.sort()
      obj.startDate = dates[0] ? dates[0].slice(0, 10) : "-"
      obj.endDate = dates[0] ? dates[dates.length - 1].slice(0, 10) : "-"
      obj.dates = dateStrings
      console.log('value[0]1', obj)
      this.topicsList.push(obj)
    },
    async startAddingTopic() {
      this.isTopicSelected = false
      this.addingTopic = true
    },
    sliceDateFunc(date) {
      return date.slice(0, 10)
    },
    async datesChanged(e) {
      this.processingDate = true
      let addedDates = []
      if (this.addingTopic !== true) {
        this.datesBackup = e
      }
      addedDates = e.filter((v, i, a) => a.indexOf(v) === i);

      if (this.updatingTopic === true) {
        this.editbtn = false
        this.selectedTopic.lectures = []
      }
      this.createLecturesList = []
      for (let i = 0; i < addedDates.length; i++) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          division: this.selectedSubject.division,
          batch: this.selectedSubject.batch,
          dateOfLecturesNeeded: new Date(addedDates[i]).toISOString()
        }
        try {
          let timetable = await this.lectureRepositoryInstance.getLecturesForADayForAFaculty(
            objToPush
          )
          timetable = timetable.filter(
            tSlot =>
              tSlot.department === this.selectedSubject.department &&
              tSlot.courseYear === this.selectedSubject.courseYear &&
              tSlot.division === this.selectedSubject.division &&
              tSlot.batch === this.selectedSubject.batch &&
              tSlot.subjectId === this.selectedSubject.subjectId
          )
          timetable.forEach(tSlot => {
            tSlot.date = formatToSlashSepearated(tSlot.dateTime.slice(0, 10))
            tSlot.time =
            this.convertUTCDateToLocalDate(tSlot.startTime) +
              ' - ' +
              this.convertUTCDateToLocalDate(tSlot.endTime)
            this.createLecturesList.push(tSlot)
          })
        } catch (err) {
          console.log(err)
        }
      }
      this.selectedTopic.lectures = this.createLecturesList
      if (this.updatingTopic === true) {
        this.selectedTopic.lectureCount = this.createLecturesList.length
      }
      this.processingDate = false
    },
    convertUTCDateToLocalDate(date){
     return convertUTCDateToLocalDate(date)
    },
    formatToSlashSepearated(item) {
      return formatToSlashSepearated(item)
    },
    timeStringConverter(item) {
      return timeStringConverter(item)
    },
    async selectTopic(e) {
      this.editbtn = true
      this.updatingTopic = false
      const selectedTopic = []
      this.selectedTopic = {}
      this.dates = []
      if (e.lectureId) {
        const newLecturesList = []
        for (const [key, value] of Object.entries(this.topicsObject)) {
          if (key === e.topicId) {
            value.map((obj) => {
              selectedTopic.push(obj.lectureId)
            })
          }
        }
        this.lecturesList.filter((lecture) => {
          if (selectedTopic.includes(lecture.lectureId)) {
            newLecturesList.push(lecture)
          }
        })
        for (const [key, value] of Object.entries(this.topicsObject)) {
          if (key === e.topicId) {
            newLecturesList.map((lecture) => {
              value.map((val) => {
                if (lecture.lectureId === val.lectureId) {
                  lecture.isImplemented = val.isImplemented
                }
              })
            })
          }
        }
        this.selectedTopic.conductedCount = 0
        this.selectedTopic.lectureCount = 0
        this.selectedTopic.implementedCount = 0
        this.selectedTopic.topicId = e.topicId
        this.selectedTopic.topicName = e.topicName
        newLecturesList.map((obj) => {
          this.dates.push(obj.dateTime.slice(0, 10))
          obj.date = obj.dateTime.slice(0, 10)
          obj.time = this.convertUTCDateToLocalDate(obj.dateTime)
          this.selectedTopic.isImplemented = obj.isImplemented
          if (obj.isConducted === true) {
            this.selectedTopic.conductedCount++
          }
          if (obj.isImplemented === true) {
            this.selectedTopic.implementedCount++
          }
        })
        this.selectedTopic.lectureCount = newLecturesList.length
        this.selectedTopic['dates'] = this.dates
        this.selectedTopic.lectures = newLecturesList
      } else {
        this.updatingTopic = true
        this.addingTopic = false
        this.selectedTopic = {}
        this.selectedTopic.conductedCount = 0
        this.selectedTopic.lectureCount = 0
        this.selectedTopic.implementedCount = 0
        this.selectedTopic.topicId = e.topicId
        this.selectedTopic.topicName = e.topicName
        this.selectedTopic.isImplemented = e.isImplemented
        this.dates = []
        this.selectedTopic['dates'] = []
        this.selectedTopic.lectures = []
      }
      e.isSelected = true
      this.addingTopic = false
      // Call API to check if Topic Feedback is launched
      this.isFeedbackLaunched = false
      this.isTopicSelected = true
    },
    async updateTopic(e) {
      this.editbtn = false
      const lectureIds = []
      const objToPush = {
        instituteId: this.selectedSubject.instituteId,
        semId: this.selectedSubject.semId,
        department: this.selectedSubject.department,
        courseYear: this.selectedSubject.courseYear,
        division: this.selectedSubject.division,
        batch: this.selectedSubject.batch,
        subjectId: this.selectedSubject.subjectId,
        topicName: e.topicName,
        topicId: e.topicId
      }
      if (this.selection.length > 0) {
        this.selection.map((obj) => {
          lectureIds.push(obj.lectureId)
        })
        objToPush['lectureIds'] = lectureIds
      }
      const res = await this.topicRepositoryInstance.updateTopic(objToPush)
      console.log('res', res);
      this.selectedTopic.lectures = []
      this.updatingTopic = false
      this.fetchData()
    },
    async deleteTopic(topic) {
      console.log(topic)
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicId: topic.topicId
        }
        await this.topicRepositoryInstance.deleteTopic(objToPush)
        let tempTopic = []
        this.topicsList.map((obj)=>{
          if(obj.topicId !== topic.topicId){
            tempTopic.push(obj)
          }else{
            console.log('obj', obj);
          }
        })
        this.topicsList = []
        console.log('tempTopic', tempTopic);
        this.selectedTopic = {}
        this.topicsList = tempTopic
      } catch (err) {
        console.log(err)
      }
    },
    goToFeedbackForm(topic) {
      console.log('topic', topic);
      this.$router.push({
        name: 'activityFeedbackForm',
        params: {
          prop_subject:this.selectedSubject,
          prop_topicsList: this.topic
        }
      })
    },
    editTopic() {
      this.editbtn = false
      this.updatingTopic = true
    },
    async createTopic() {
      // console.log('this.selectedSubject', this.selectedSubject)
      try {
        if (this.tempTopicName === '') {
          alert('Please enter topic name')
          return
        }
        this.updatingTopic = false
        this.editbtn = false
        let lectureIds = []
        const objToPush = {
          instituteId: this.selectedSubject.instituteId,
          semId: this.selectedSubject.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          // division: this.selectedSubject.division,
          batch: this.selectedSubject.batch,
          subjectId: this.selectedSubject.subjectId,
          topicName: this.tempTopicName
        }
        if (this.selectedSubject.groupsForSubject.length > 0) {
          objToPush.assignedGroupForSubject = {
            groupName: this.selectedSubject.groupsForSubject[0].groupName,
            groupId: this.selectedSubject.groupsForSubject[0].groupId
          }
          objToPush.division = ""
        } else if (this.selectedSubject.groupsForSubject.length === 0) {
          objToPush.division = this.selectedSubject.division
        }
        if (this.selection.length > 0) {
          lectureIds = this.selection.map(item => item.lectureId)
          objToPush['lectureIds'] = lectureIds
        }
        await this.topicRepositoryInstance.createTopic(objToPush)
        this.tempTopicName = ''
        this.dates = []
        this.createLecturesList = []
        this.selection = []
        this.isTopicSelected = false
        this.fetchData2()
      } catch (err) {
        console.log(err)
      }
    },
    async markAsImplemented(item, isImplemented) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicId: this.selectedTopic.topicId,
          lectureId: item.lectureId
        }
        if (isImplemented) {
          await this.topicRepositoryInstance.markAsImplemented(objToPush)
        } else {
          await this.topicRepositoryInstance.markAsUnimplemented(objToPush)
        }
        const indexOfLectureTopic = this.selectedTopic.lectures.indexOf(item)
        if (indexOfLectureTopic > -1) {
          this.selectedTopic.lectures[indexOfLectureTopic].isImplemented = isImplemented
        }

        const indexOfTopic = this.topicsList.findIndex((topic) => topic.topicId === this.selectedTopic.topicId)
        if (indexOfTopic > -1) {
          this.topicsList[indexOfTopic].lectures[indexOfLectureTopic].isImplemented = isImplemented
        }
      } catch (err) {
        console.log(err)
      }
    },
    async gotoFeedbackAnalysis(topic) {
      this.$store.commit('liveData/set_selectedTopic', topic)
      this.$router.push('/activityFeedbackAnalysis')
    },
    async fetchStudentFeedback() {
      // Call Api to fetch students feedback for the topic
      const objToPush = JSON.parse(JSON.stringify(this.selectedTopic))
      console.log(objToPush)
      this.topicFeedbackQuestions = []
      this.showStudentFeedback = true
    },
    async launchStudentFeedback() {
      // Call Api to lauch students feedback for the topic
      const objToPush = JSON.parse(JSON.stringify(this.selectedTopic))
      console.log(objToPush)
    },
    convertDate(date) {
      return convertDate(date)
    }
  }
}
</script>

<style src='./activitySyllabusTracking.css'>
</style>
