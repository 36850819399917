<template src="./sessionPlan.html"></template>

<script>
import NbaRepository from "../../../../Repository/NBA";
import SyallbusRepository from "../../../../Repository/Syllabus";
import SessionRepository from "../../../../Repository/Session";
import showStatus from "../../../../NetworkManager/showStatus";
import { v4 } from "uuid";
export default {
  name: "sessionPlan",
  components: {
    // basicDetails,
    // examsBasicDetails,
    // examSubjectDetails,
    // examForm,
    // hallTicket,
    // examQuestionDetails,
    // examMarks,
  },
  props: ["subjectId", "semId", "courseYear", "selectedDepartment"],
  async created() {
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.syllabusInstance = new SyallbusRepository(this);
    this.sessionInstance = new SessionRepository(this);
    await this.getSyllabus();
    await this.getSessions();
    // this.syllabusInstance = new SyallbusRepository(
    //   this
    // );
    // await this.fetchCos();
  },
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      selectedInstitute: "",
      fileDialog: "",
      headers: [
        {
          text: "session no",
          value: "sessionNumber",
          width: "10%",
          sortable: false,
        },
        {
          text: "type",
          value: "sessionType",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Select Unit",
          value: "selectUnit",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Select Topic",
          value: "selectTopic",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Select Sub Topic",
          value: "selectSubTopic",
          width: "10%",
          sortable: false,
          cellClass: "unitClass",
        },
      ],
      syllabus: [],
      selectedItem: {},
      totalTeachingHours: "",
      sessionLength: "",
      sessions: [],
      sessionTypeList: ["Teaching", "Assessment", 'Tutorial'],
      syllabus: null,
      totalSessions: 0,

      // selects:{}
    };
  },
  methods: {
    async getSyllabus() {
      let res = await this.syllabusInstance.getSyllabus({
        instituteId: this.selectedInstitute,
        semId: this.semId,
        department: this.selectedDepartment,
        courseYear: this.courseYear,
        subjectId: this.subjectId,
      });
      this.syllabus = res.result;
    },
    handleSessionArray() {
      if (this.totalTeachingHours && this.sessionLength) {
        this.totalSessions = Math.floor(
          this.totalTeachingHours / this.sessionLength
        );
        const currentSessionCount = this.sessions.length;
        if (currentSessionCount < this.totalSessions) {
          for (let i = currentSessionCount; i < this.totalSessions; i++) {
            this.sessions.push({
              sessionNumber : i+1,
              sessionId: v4(),
            });
          }
        } else if (currentSessionCount > this.totalSessions) {
          this.sessions.splice(
            this.totalSessions,
            currentSessionCount - this.totalSessions
          );
        }
        // if (this.sessions.length < this.totalSessions) {
        //   for (let i = 1; i < this.totalSessions; i++) {
        //     this.sessions.push({
        //       sessionId: v4(),
        //     });
        //   }
        // } else {
        //   this.sessions.splice(this.totalSessions);
        // }
      }
    },
    addSelectUnit(item) {
      if (!item.hasOwnProperty("units")) {
        this.$set(item, "units", []);
      }
      item["units"].push({
        unitId: "",
      });
    },
    removeUnit(item) {
      item.units.pop();
    },
    removeTopic(item, index) {
      item.units[index].topics.pop();
      // item.units.pop()
    },
    addSelectTopic(item, index1) {
      // const element = document.getElementById("topicSelectElement");
      // item.units[index1][topics].push({
      //   topicId: "",
      // });
      if (item.units[index1].topics) {
        item.units[index1].topics.push({
          topicId: "",
        });
      } else {
        this.$set(item.units[index1], "topics", [{ topicId: "" }]);
        // item.units[index1]['topics'] = [{ topicId: ""}];
      }
    },
    getTopicsByUnitId(unitId) {
      const unit = this.syllabus?.units?.find((u) => u.unitId === unitId);
      return unit ? unit.topics || [] : [];
    },
    getSubTopicsByTopicId(topicId, unitId) {
      const unit = this.syllabus?.units?.find((u) => u.unitId === unitId);
      if (!unit) {
        return [];
      }
      const topic = unit.topics?.find((t) => t.topicId === topicId);
      if (!topic) {
        return [];
      }
      return topic.subTopics || [];
    },

    async getSessions(){
      try {
        let res = await this.sessionInstance.getSessions({
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
        })
        if(res.result.length>=1){
          this.sessions = res.result
          this.totalTeachingHours = res.result[0].totalTeachingHours
          this.sessionLength = res.result[0].sessionLength
        }
      } catch (error) {
        console.error(error)
      }
    },
    async saveSessions() {
      if (this.sessions && Array.isArray(this.sessions)) {
        this.sessions.forEach((s) => {
          s.totalTeachingHours = Number(this.totalTeachingHours);
          s.sessionLength = Number(this.sessionLength);
          if (s.units && Array.isArray(s.units)) {
            s.units.forEach((u) => {
              if (u.topics && Array.isArray(u.topics)) {
                u.topics.forEach((t) => {
                  if (
                    t.selectedSubTopicId &&
                    Array.isArray(t.selectedSubTopicId)
                  ) {
                    if (!t.subTopics) {
                      t.subTopics = [];
                    }
                    t.selectedSubTopicId.forEach((item) => {
                      const existingSubTopic = t.subTopics.find(
                        (subTopic) => subTopic.subTopicId === item
                      );
                      if (!existingSubTopic) {
                        t.subTopics.push({ subTopicId: item });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }

      try {
        let res = await this.sessionInstance.createSessions({
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
          sessions: this.sessions,
        });
        showStatus("Session saved successfully", 2000, "success", this);
      } catch (error) {
        console.error(error);
        showStatus("Something went wrong", 2000, "error", this);
      }
    },
  },
};
</script>

<style scoped src="./sessionPlan.css">
</style>