import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Topic'

class SubjectsRepository {
  constructor (context) {
    this.context = context
  }

  async createTopic (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTopic, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topic
  }

  async generateTopicReport(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateTopicReport, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  
  async getTopicsOfASubjectOfAFaculty(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfASubjectOfAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async getTopicsOfADivisionOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfADivisionOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async getTopicsOfASubjectOfAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfASubjectOfAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async markAsImplemented (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.markAsImplemented, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.isImplemented
  }

  async markAsUnimplemented (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.markAsUnimplemented, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.isImplemented
  }

  async deleteTopic (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteTopic, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getTopicsOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async getTopicRatingForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicRatingForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicRating
  }

  async updateTopicRating (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateTopicRating, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicRating
  }

  async updateTopic(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateTopic, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  
  async getTopicsOfMultiSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfMultiSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }
  async getTopicsOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async getTopicsOfAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }
}

export default SubjectsRepository
