<template src="./activitySubjectGroup.html"></template>
<script>
import inputContainer from "../../Components/inputContainer";
import smallButton from "../../Components/smallButton";
import { v4 as uuidv4 } from "uuid";
import { subjectTypes } from "../../Constants/Utils/Statics";
import UserRepository from "../../Repository/User";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SubjectInstituteLevelRepository from "../../Repository/SubjectInstituteLevel";
import uploadToDigitalOceanRepository from "../../Repository/uploadToDigitalOcean";
import SubjectRepository from "../../Repository/Subject";
import AssignmentRepository from "../../Repository/Assignment";
import ValueAddedActivityRepository from "../../Repository/ValueAddedActivity";
import arrayOps from "../../Services/Utils/arrayOps";
import showStatus from "../../NetworkManager/showStatus";
import axios from "axios";
import apiV3 from "../../NetworkManager/apiV3";
import ExamSettingsRepository from "../../Repository/ExamSettings";
import CorporateRepository from "../../Repository/Corporate";
export default {
  name: "activitySubjectGroup",
  components: {
    inputContainer,
    smallButton,
  },
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "tree",
  ],
  data() {
    return {
      selectedCourseYear: "",
      selectedDepartment: "",
      selectedSubjectName: "",
      newSubjectGroup: "",
      deleteSubjectGroup: "",
      departments: [],
      courseYears: [],
      subjectsList: [],
      allSubjectData: [],
      selectedSubject: {},
      groupForSubject: [],
      // facultyTableTitle : "",
      semUsers: [],
      getSemisterUsers: [],
      getFacultiesTeachingASubject: [],
      getFacultiesData: [],
      assignedGroupForSubjectToFaculty: [],
      search: "",
      removeFacultySearch: "",
      groupStudentsSearch: "",
      availableFacultiesSearch: "",
      availStudentsSearch: "",
      loading: false,
      showDataTable: false,
      showUpdatedGroupStudents: false,
      showDataTableForAvailStudents: false,
      addGroup: false,
      deleteGroupFromSubject: false,
      usersToDeleteGroupFrom: [],
      singleSelect: false,
      selected: [],
      facultiNames: [],
      facultiNamesForDelete: [],
      groupForDeletion: "",
      groupForDeletionId: "",
      groupIndexForDeletion: null,
      selectedForAvailStudents: [],
      selectedForGroupStudents: [],
      availableFaculties: [],
      assignedFaculties: [],
      groupStudents: [],
      groupName: "",
      groupId: "",
      getSemisteryUsers: [],
      getAvailSemisterFacultyUsers: [],
      getAssignSemisterFacultyUsers: [],
      getSemisterFacultyUsers: [],
      availableStudents: [],
      getFullNamesOfFaculties: [],
      showAvailFaculty: false,
      showAvailStudents: false,
      headers: [
        {
          text: "Student PRN",
          value: "collegePRNNo",
        },
        {
          text: "Student Name",
          value: "fullName",
        },
        {
          text: "Roll Number",
          value: "rollNumber",
        },
      ],
      facultyHeaders: [
        {
          text: "Faculty Name",
          value: "fullName",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      activeButtonIndex: null,
      showUploadUsersDialog: false,
      showAlertForUserExcel: false,
      file: null,
      addedStudents: [],
      notAddedStudents: [],
      studentsStatusAddOrNot: false
    };
  },

  created() {
    this.$store.commit(
      "liveData/set_selectedActivityName",
      "Semester Subjects"
    );
    this.loading = true;
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.cieSee = this.$store.getters["liveData/get_cieSeePreference"];
    this.selectedSemester = this.PropsSelectedSemester;
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectInstituteLevelInstance = new SubjectInstituteLevelRepository(
      this
    );
    this.uploadToDigitalOceanInstance = new uploadToDigitalOceanRepository(
      this
    );
    this.valueAddedActivityInstance = new ValueAddedActivityRepository(this);
    this.assignmentRepositoryInstance = new AssignmentRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);

    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.CorporateRepositoryInstance = new CorporateRepository(this);
    if (this.tree) {
      for (let i = 0; i < this.tree.length; i++) {
        this.departments.push(this.tree[i].name);
      }
    } else {
      this.departments = [];
    }
    if (
      this.prop_selectedDepartment &&
      this.prop_selectedCourseYear &&
      this.prop_selectedSubjectName &&
      this.prop_selectedDivision
    ) {
      this.selectedDepartment = this.prop_selectedDepartment;
      this.selectDepartment();
      this.selectedCourseYear = this.prop_selectedCourseYear;
      this.selectCourseYear();
      this.selectedSubjectName = this.prop_selectedSubjectName;
      this.selectSubjectName();
    }
    // this.fetchSubjectDocumentData();
    // this.getAllValueAddedActivity();
    // this.getExamSettings();
    this.loading = false;
  },
  methods: {
    async selectDepartment() {
      // this.loading = true;
      this.courseYears = [];
      this.subjectsList = [];
      this.selectedCourseYear = "";
      this.selectedSubjectName = "";
      this.students = [];
      const index = this.departments.indexOf(this.selectedDepartment);
      for (let i = 0; i < this.tree[index].children.length; i++) {
        this.courseYears.push(this.tree[index].children[i].name);
      }
      // this.loading = false;
    },
    async selectCourseYear() {
      // this.loading = true;
      this.subjects = [];
      this.subjectsList = [];
      this.selectedSubjectName = "";
      this.subjectGroups = {};
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
      };

      try {
        this.allSubjectData =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            objToPush
          );
        // console.log("allSubjectData", this.allSubjectData);
        for (let i = 0; i < this.allSubjectData.length; i++) {
          this.subjectsList.push(this.allSubjectData[i].subjectName);
        }
        // this.loading = false;
      } catch (err) {
        console.log(err);
        this.subjects = [];
      }

      this.subjectGroups = [];

      for (let i = 0; i < this.subjects.length; i++) {
        if (!this.subjectGroups[this.subjects[i].groupId]) {
          this.subjectGroups[this.subjects[i].groupId] = [];
        }
        this.subjectGroups[this.subjects[i].groupId].push(this.subjects[i]);
      }
      // console.log('this.subjectGroups', this.subjects)
      this.loading = false;
      this.subjectGroups.slice();
    },
    async onChangeSelectedSubject() {
      this.groupName = "";
      this.loading = true;
      for (let i = 0; i < this.allSubjectData.length; i++) {
        if (
          this.allSubjectData[i]["subjectName"] === this.selectedSubjectName
          ) {
          this.selectedSubject = this.allSubjectData[i];
          // console.log("groupForSubject", this.allSubjectData);
          this.groupForSubject = this.allSubjectData[i].groupForSubject;
          // this.selectedSubject.groupForSubject = ["group1", "group2", "group3"];
        }
      }

      const semesterUserObject = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: this.selectedSubject.subjectId,
      };
      // console.log('semesterUserObject',semesterUserObject)
      this.getSemisterUsers;
      try {
        this.getSemisterUsers =
          await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
            semesterUserObject
          );

        for (let i = 0; i < this.getSemisterUsers.length; i++) {
          this.semUsers.push(this.getSemisterUsers[i].uId);
        }
        // console.log("getSemisterUsers", this.getSemisterUsers);
        const userObject = {
          uIds: this.semUsers,
        };
        let getFullNamesOfUsers =
          await this.userRepositoryInstance.getFullNameOfUsers(userObject);

          // console.log("getFullNamesOfUsers", getFullNamesOfUsers);

        for (let i = 0; i < this.getSemisterUsers.length; i++) {
          for (let j = 0; j < getFullNamesOfUsers.length; j++) {
            if (this.getSemisterUsers[i].uId === getFullNamesOfUsers[j].uId) {
              this.getSemisterUsers[i].fullName =
                getFullNamesOfUsers[j].fullName;
                this.getSemisterUsers[i].collegePRNNo =
                getFullNamesOfUsers[j].collegePRNNo;
            }
          }
        }

        // console.log("this.getSemisterUsers", this.getSemisterUsers);
        this.showDataTable = true;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
  
    },
    async addGroupFun() {
      this.newSubjectGroup = "";
      this.addGroup = true;
    },
    async pushGroup() {
      this.loading = true;
      // console.log("newSubjectGroup", this.newSubjectGroup);
      this.selectedSubject.groupForSubject.push({
        groupName: this.newSubjectGroup,
        groupId: uuidv4(),
      });
      const objToUpdate = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        subjectId: this.selectedSubject.subjectId,
        groupForSubject: this.selectedSubject.groupForSubject,
      };
      // console.log("objToUpdate", objToUpdate);

      try {
        let updatedSubjectData =
          await this.subjectRepositoryInstance.updateSubjectName(objToUpdate);
        // console.log("updatedSubjectData", updatedSubjectData);
        // await this.selectCourseYear()
      } catch (err) {
        console.log(err);
      }
      this.addGroup = false;
      this.loading = false;
      // console.log("groupForSubject", this.selectedSubject.groupForSubject);
    },
    async deleteGroup(item, index) {
      this.loading = true;
      // console.log(index, item);
      this.groupForDeletion = item.groupName;
      this.groupForDeletionId = item.groupId;
      this.groupIndexForDeletion = index;
      // console.log(this.selectedSubject.groupForSubject);
      // this.selectedSubject.groupForSubject.splice(index, 1);
      // console.log(this.selectedSubject.groupForSubject);
      this.deleteGroupFromSubject = true;
      this.loading = false;
    },
    async updateGroup() {
      this.loading = true;
      this.selectedSubject.groupForSubject.splice(
        this.groupIndexForDeletion,
        1
      );
      // this.loading = true;
      const objToUpdate = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        subjectId: this.selectedSubject.subjectId,
        groupForSubject: this.selectedSubject.groupForSubject,
      };
      // console.log("objToUpdate", objToUpdate);

      try {
        let updatedSubjectData =
          await this.subjectRepositoryInstance.updateSubjectName(objToUpdate);
        // console.log("updatedSubjectData", updatedSubjectData);
      } catch (err) {
        console.log(err);
      }
      this.deleteGroupFromSubject = false;

      for (let i = 0; i < this.getSemisterUsers.length; i++) {
        this.getSemisterUsers[i].instituteId = this.selectedInstitute
        this.getSemisterUsers[i].semId = this.selectedSemester.semId
        for (
          let j = 0;
          j < this.getSemisterUsers[i].assignedGroupForSubject.length;
          j++
        ) {
          if (
            this.groupForDeletionId ===
            this.getSemisterUsers[i].assignedGroupForSubject[j].groupId
          ) {
            this.getSemisterUsers[i].assignedGroupForSubject.splice(j, 1);
            this.usersToDeleteGroupFrom.push(this.getSemisterUsers[i]);
          }
        }
      }
      for (let i = 0; i < this.getFacultiesData.length; i++) {
        this.getFacultiesData[i].instituteId = this.selectedInstitute
        this.getFacultiesData[i].semId = this.selectedSemester.semId
        for (
          let j = 0;
          j < this.getFacultiesData[i].assignedGroupForSubject.length;
          j++
        ) {
          if (
            this.groupForDeletionId ===
            this.getFacultiesData[i].assignedGroupForSubject[j].groupId
          ) {
            // console.log('this.getFacultiesData[i].assignedGroupForSubject', this.getFacultiesData[i].assignedGroupForSubject);
            this.getFacultiesData[i].assignedGroupForSubject.splice(j, 1);
            // console.log('this.getFacultiesData[i].assignedGroupForSubject', this.getFacultiesData[i].assignedGroupForSubject);
            this.usersToDeleteGroupFrom.push(this.getFacultiesData[i]);
          }
        }
      }
      // console.log(
      //   "this.getFacultiesData",
      //   this.getFacultiesData
      // );
      // console.log(
      //   "this.usersToDeleteGroupFrom",
      //   this.usersToDeleteGroupFrom
      // );

      // console.log("bulk", bulk);
      try {
        let updatedSemUserData =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.usersToDeleteGroupFrom,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        await this.getUpdatedSemUsers();
        // console.log("updatedSemUserData", updatedSemUserData);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.groupForDeletion = '';
      this.groupForDeletionId = ""
      this.groupName = ''
      this.groupIndexForDeletion = null
      
    },
    async openUploadStudentsToGroupDialog() {
      this.file = null
      this.addedStudents = []
      this.notAddedStudents = []
      this.showUploadUsersDialog = true
    },
    async generateExcelPRN() {
      let obj = {
        groupName : this.groupName,
        selectedSubject : this.selectedSubject.subjectName
      }
      // // console.log('call');
      try {
        const pdfBlob =
          await this.semesterUserRepositoryInstance.generateExcelAddStudentsToGroup(
            obj
          )
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Add Students In Group Sample.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    async uploadExcel() {
      this.showUploadUsersDialog = false
      this.loading = true
      if (this.file) {
        if(this.availableStudents.length>0){
          var formData = new FormData()

          formData.append('excel', this.file)
          formData.append('semId', this.selectedSemester.semId)
          formData.append('instituteId', this.selectedInstitute)
          formData.append('availableStudents', JSON.stringify(this.availableStudents));
          formData.append('groupName', this.groupName);
          formData.append('groupId', this.groupId);
          formData.append('subjectId', this.selectedSubject.subjectId);
          const url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/semester/ParseExcelAddStudentInGroups'
          try {
            const response = await axios.post(
              `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_API_PORT}/excel/semester/ParseExcelAddStudentInGroups`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            );

            
            if (response.status === 200) {
              if(response.data.notAddedStudents.length===0&&response.data.addedStudents.length>0){
                this.addedStudents = response.data.addedStudents
                // showStatus(`Successfully added Students are ${response.data.addedStudents} for ${this.groupName} group!`, 2000, 'success', this);
              }
              else if(response.data.notAddedStudents.length>0&&response.data.addedStudents.length>0){
                this.addedStudents = response.data.addedStudents
                this.notAddedStudents = response.data.notAddedStudents
                // showStatus(`Successfully added Students are ${response.data.addedStudents} and not available Students are ${response.data.notAddedStudents} for ${this.groupName} group!`, 5000, 'success', this);
              }
              await this.getUpdatedSemUsers();
            }
          } catch (error) {
            console.error(error);
            this.loading = false;
            
            if (error.response && error.response.status === 400) {
              this.notAddedStudents = error.response.data.notAddedStudents
              // showStatus(`Students whose Roll Numbers are ${error.response.data.notAddedStudents} are not available for getting added into ${this.groupName} group`, 5000, 'error', this);
            }
          }
        }else{
          showStatus(`These Students are not available for getting added into ${this.groupName} group`, 4000, 'error', this)
        }
      }
      this.studentsStatusAddOrNot = true
      this.loading = false
    },
    async addStudent() {
      this.search = ""
      this.removeFacultySearch= ""
      this.groupStudentsSearch= ""
      this.availableFacultiesSearch= ""
      this.availStudentsSearch= ""
      this.loading = true;
      this.showAvailStudents = false;
      // console.log(
      //   "this.selectedForAvailStudents",
      //   this.selectedForAvailStudents
      // );
      if (this.selectedForAvailStudents.length > 0) {
        for (let i = 0; i < this.selectedForAvailStudents.length; i++) {
          this.selectedForAvailStudents[i].instituteId = this.selectedInstitute
          this.selectedForAvailStudents[i].semId = this.selectedSemester.semId
          if (
            this.selectedForAvailStudents[i]["assignedGroupForSubject"] &&
            !this.selectedForAvailStudents[i]["assignedGroupForSubject"].some(
              (group) =>
                group.groupName === this.groupName &&
                group.groupId === this.groupId &&
                group.subjectId === this.selectedSubject.subjectId
            )
          ) {
            this.selectedForAvailStudents[i]["assignedGroupForSubject"].push({
              groupName: this.groupName,
              groupId: this.groupId,
              subjectId: this.selectedSubject.subjectId,
            });
          }
        }
      }
      // console.log(
      //   "this.selectedToAddAvailStudentsInGroup",
      //   this.selectedForAvailStudents
      // );
      // console.log("bulk", bulk);
      try {
        let updatedSemUserData =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.selectedForAvailStudents,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        await this.getUpdatedSemUsers();
        // console.log("updatedSemUserData", updatedSemUserData);
      } catch (err) {
        console.log(err);
      }
      this.selectedForAvailStudents = [];
      this.loading = false;
    },

    async removeStudent() {
      this.search = ""
      this.removeFacultySearch= ""
      this.groupStudentsSearch= ""
      this.availableFacultiesSearch= ""
      this.availStudentsSearch= ""
      this.loading = true;
      for (let i = 0; i < this.selectedForGroupStudents.length; i++) {
        this.selectedForGroupStudents[i].instituteId = this.selectedInstitute
        this.selectedForGroupStudents[i].semId = this.selectedSemester.semId
        for (
          let j = 0;
          j <
          (this.selectedForGroupStudents[i]?.assignedGroupForSubject?.length ||
            0);
          j++
        ) {
          if (
            this.selectedForGroupStudents[i]?.assignedGroupForSubject[j]
              ?.groupId === this.groupId &&
            this.selectedForGroupStudents[i]?.assignedGroupForSubject[j]
              ?.subjectId === this.selectedSubject.subjectId
          ) {
            this.selectedForGroupStudents[i].assignedGroupForSubject.splice(
              j,
              1
            );
          }
        }
      }
      // console.log(
      //   "this.selectedToRemoveGroupStudents",
      //   this.selectedForGroupStudents
      // );

      // console.log("bulk", bulk);
      try {
        let updatedSemUserData =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.selectedForGroupStudents,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        await this.getUpdatedSemUsers();
        // console.log("updatedSemUserData", updatedSemUserData);
      } catch (err) {
        console.log(err);
      }
      this.selectedForGroupStudents = [];
      this.loading = false;
    },

    async getUpdatedSemUsers() {
      this.loading = true;
      this.showDataTableForAvailStudents = false;
      this.availableStudents = [];
      this.groupStudents = [];
      const semesterUserObject = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: this.selectedSubject.subjectId,
      };
      try {
        this.getSemisterUsers =
          await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
            semesterUserObject
          );
        const userObject = {
          uIds: this.semUsers,
        };
        let getFullNamesOfUsers =
          await this.userRepositoryInstance.getFullNameOfUsers(userObject);

        for (let i = 0; i < this.getSemisterUsers.length; i++) {
          for (let j = 0; j < getFullNamesOfUsers.length; j++) {
            if (this.getSemisterUsers[i].uId == getFullNamesOfUsers[j].uId) {
              this.getSemisterUsers[i].fullName =
                getFullNamesOfUsers[j].fullName;
            }
          }
        }

        let getPrnOfUsers = await this.userRepositoryInstance.getUsers(
          userObject
        );

        for (let i = 0; i < this.getSemisterUsers.length; i++) {
          for (let j = 0; j < getPrnOfUsers.length; j++) {
            if (this.getSemisterUsers[i].uId == getPrnOfUsers[j].uId) {
              this.getSemisterUsers[i].collegePRNNo =
                getPrnOfUsers[j].collegePRNNo;
            }
          }
        }

        for (let i = 0; i < this.getSemisterUsers.length; i++) {
          const index = this.getSemisterUsers[
            i
          ]?.assignedGroupForSubject?.findIndex(
            (group) => group.subjectId === this.selectedSubject.subjectId
          );
          if (index === -1) {
            this.availableStudents.push(this.getSemisterUsers[i]);
          }
        }

        for(let i = 0; i < this.availableStudents.length; i++){
        if(this.availableStudents[i].rollNumber === undefined) this.availableStudents[i].rollNumber = '-'
      }

      // const groupStudentsPRNs = new Set(this.groupStudents.map(student => student.collegePRNNo));
      this.getSemisterUsers.forEach(user => {
        const assignedGroups = user?.assignedGroupForSubject;
        const matchingGroup = assignedGroups?.find(
          group => group.groupName === this.groupName &&
                  group.groupId === this.groupId &&
                  group.subjectId === this.selectedSubject.subjectId
        );

        if (matchingGroup) {
          this.groupStudents.push(user);
          // groupStudentsPRNs.add(user.collegePRNNo);
        }
      });

        for(let i = 0; i < this.groupStudents.length; i++){
        if(this.groupStudents[i].rollNumber === undefined) this.groupStudents[i].rollNumber = '-'
      }

        this.showDataTableForAvailStudents = true;
        // console.log({
        //   availableStudents: this.availableStudents,
        //   groupStudents: this.groupStudents,
        // });
      } catch (err) {
        console.log(err);
      }
      // console.log(item, index, this.selectedSubject.subjectId);
      this.loading = false;
    },

    async showGroupSubjects(item, index) {
      this.groupName = ""
      this.loading = true;
      this.activeButtonIndex = index;
      this.showDataTableForAvailStudents = false;
      this.groupName = item.groupName;
      // this.facultyTableTitle = `Available Faculties for ${item.groupName}`
      this.groupId = item.groupId;
      this.assignedFaculties = [];
      this.availableFaculties = [];
      this.availableStudents = [];
      this.groupStudents = [];
      // console.log(item, index, this.selectedSubject.subjectId);
      for (let i = 0; i < this.getSemisterUsers.length; i++) {
        const index = this.getSemisterUsers[
          i
        ]?.assignedGroupForSubject?.findIndex(
          (group) => group.subjectId === this.selectedSubject.subjectId
        );
        if (index === -1) {
          this.availableStudents.push(this.getSemisterUsers[i]);
        }
      }
      for(let i = 0; i < this.availableStudents.length; i++){
        if(this.availableStudents[i].rollNumber === undefined) this.availableStudents[i].rollNumber = '-'
      }

      // const groupStudentsPRNs = new Set(this.groupStudents.map(student => student.collegePRNNo));

      this.getSemisterUsers.forEach(user => {
        const assignedGroups = user?.assignedGroupForSubject;
        const matchingGroup = assignedGroups?.find(
          group => group.groupName === this.groupName &&
                  group.groupId === this.groupId &&
                  group.subjectId === this.selectedSubject.subjectId
        );

        if (matchingGroup) {
          this.groupStudents.push(user);
          // groupStudentsPRNs.add(user.collegePRNNo);
        }
      });

      for(let i = 0; i < this.groupStudents.length; i++){
        if(this.groupStudents[i].rollNumber === undefined) this.groupStudents[i].rollNumber = '-'
      }

      // console.log({
      //   availableStudents: this.availableStudents,
      //   groupStudents: this.groupStudents,
      // });
      this.showDataTableForAvailStudents = true;

      const subjectObject = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: this.selectedSubject.subjectId,
      };
      // console.log("subjectObject", subjectObject);
      try {
        this.getFacultiesTeachingASubject =
          await this.subjectRepositoryInstance.getFacultiesTeachingASubjectOfACourseYearInASemester(
            subjectObject
          );

        // console.log(
        //   "this.getFacultiesTeachingASubject",
        //   this.getFacultiesTeachingASubject
        // );
        const userFacultyObject = {
          uIds: this.getFacultiesTeachingASubject,
        };
        this.getFullNamesOfFaculties =
          await this.userRepositoryInstance.getFullNameOfUsers(
            userFacultyObject
          );
        // console.log("getFullNamesOfFacultyes", this.getFullNamesOfFaculties);

        let getFacultyObject = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uIds: this.getFacultiesTeachingASubject,
        };
        let getFacultiesData =
          await this.semesterUserRepositoryInstance.getMultipleSemesterUsers(
            getFacultyObject
          );
          this.getFacultiesData = getFacultiesData
        // console.log("getFacultiesData", getFacultiesData);

        for (let i = 0; i < getFacultiesData.length; i++) {
          // console.log(
          //   "getFacultiesData",
          //   this.groupId,
          //   this.groupName,
          //   this.selectedSubject.subjectId
          // );
          const assignedGroupForSubject =
            getFacultiesData[i]?.assignedGroupForSubject;
          const index = assignedGroupForSubject?.some(
            (group) =>
              group.groupId === this.groupId &&
              group.groupName === this.groupName &&
              group.subjectId === this.selectedSubject.subjectId
          )
            ? 1
            : -1;
          // console.log("index", index);
          if (
            !assignedGroupForSubject ||
            assignedGroupForSubject.length === 0
          ) {
            this.availableFaculties.push(getFacultiesData[i]);
          } else {
            if (index === 1) {
              this.assignedFaculties.push(getFacultiesData[i]);
            } else if (index === -1) {
              this.availableFaculties.push(getFacultiesData[i]);
            }
          }
        }

        // console.log("this.availableFaculties", this.availableFaculties);
        // console.log("this.assignedFaculties", this.assignedFaculties);
        for (let i = 0; i < this.getFullNamesOfFaculties.length; i++) {
          for (let j = 0; j < this.availableFaculties.length; j++) {
            if (
              this.getFullNamesOfFaculties[i].uId ==
              this.availableFaculties[j].uId
            ) {
              this.availableFaculties[j].fullName =
                this.getFullNamesOfFaculties[i].fullName;
            }
          }
        }
        for (let i = 0; i < this.getFullNamesOfFaculties.length; i++) {
          for (let j = 0; j < this.assignedFaculties.length; j++) {
            if (
              this.getFullNamesOfFaculties[i].uId ==
              this.assignedFaculties[j].uId
            ) {
              this.assignedFaculties[j].fullName =
                this.getFullNamesOfFaculties[i].fullName;
            }
          }
        }
        // console.log('this.assignedFaculties, this.availableFaculties, this.availableStudents, this.groupStudents', this.assignedFaculties, this.availableFaculties, this.availableStudents, this.groupStudents)
        this.getAvailSemisterFacultyUsers = this.availableFaculties;
        this.facultiNamesForDelete = [];
        this.facultiNames = [];
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },

    async addFaculty() {
      this.search = ""
      this.removeFacultySearch= ""
      this.groupStudentsSearch= ""
      this.availableFacultiesSearch= ""
      this.availStudentsSearch= ""
      this.loading = true;
      this.showAvailFaculty = false;
      this.availableFaculties = [];
      this.assignedFaculties = [];

      for (let i = 0; i < this.facultiNames.length; i++) {
        this.facultiNames[i].instituteId = this.selectedInstitute
        this.facultiNames[i].semId = this.selectedSemester.semId
        if (
          !this.facultiNames[i]["assignedGroupForSubject"].some(
            (group) =>
              group.groupName === this.groupName &&
              group.groupId === this.groupId &&
              group.subjectId === this.selectedSubject.subjectId
          )
        ) {
          this.facultiNames[i]["assignedGroupForSubject"].push({
            groupName: this.groupName,
            groupId: this.groupId,
            subjectId: this.selectedSubject.subjectId,
          });
        }
      }

      try {
        let updatedSemUserData =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.facultiNames,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });

        let getFacultyObject = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uIds: this.getFacultiesTeachingASubject,
        };

        let getFacultiesData =
          await this.semesterUserRepositoryInstance.getMultipleSemesterUsers(
            getFacultyObject
          );
        this.getFacultiesData = getFacultiesData
        for (let i = 0; i < getFacultiesData.length; i++) {
          const assignedGroupForSubject =
            getFacultiesData[i]?.assignedGroupForSubject;
          const index = assignedGroupForSubject?.some(
            (group) =>
              group.groupId === this.groupId &&
              group.groupName === this.groupName &&
              group.subjectId === this.selectedSubject.subjectId
          )
            ? 1
            : -1;
          if (getFacultiesData[i]?.assignedGroupForSubject?.length === 0) {
            this.availableFaculties.push(getFacultiesData[i]);
          } else {
            if (index === 1) {
              this.assignedFaculties.push(getFacultiesData[i]);
            } else if (index === -1) {
              this.availableFaculties.push(getFacultiesData[i]);
            }
          }
        }

        for (let i = 0; i < this.getFullNamesOfFaculties.length; i++) {
          for (let j = 0; j < this.availableFaculties.length; j++) {
            if (
              this.getFullNamesOfFaculties[i].uId ==
              this.availableFaculties[j].uId
            ) {
              this.availableFaculties[j].fullName =
                this.getFullNamesOfFaculties[i].fullName;
            }
          }
        }

        for (let i = 0; i < this.getFullNamesOfFaculties.length; i++) {
          for (let j = 0; j < this.assignedFaculties.length; j++) {
            if (
              this.getFullNamesOfFaculties[i].uId ==
              this.assignedFaculties[j].uId
            ) {
              this.assignedFaculties[j].fullName =
                this.getFullNamesOfFaculties[i].fullName;
            }
          }
        }

        this.getAvailSemisterFacultyUsers = this.availableFaculties;
        this.facultiNamesForDelete = [];
        this.facultiNames = [];
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    async showAvailFacultyTable() {
      this.search = ""
      this.removeFacultySearch= ""
      this.groupStudentsSearch= ""
      this.availableFacultiesSearch= ""
      this.availStudentsSearch= ""
      this.showAvailFaculty = true;
    },

    async showAvailStudentsTable() {
      this.search = ""
      this.removeFacultySearch= ""
      this.groupStudentsSearch= ""
      this.availableFacultiesSearch= ""
      this.availStudentsSearch= ""
      this.showAvailStudents = true;
    },

    async removeFaculty() {
      this.search = ""
      this.removeFacultySearch= ""
      this.groupStudentsSearch= ""
      this.availableFacultiesSearch= ""
      this.availStudentsSearch= ""
      this.loading = true;
      this.availableFaculties = [];
      this.assignedFaculties = [];

      for (let i = 0; i < this.facultiNamesForDelete.length; i++) {
        this.facultiNamesForDelete[i].instituteId = this.selectedInstitute
        this.facultiNamesForDelete[i].semId = this.selectedSemester.semId
        for (
          let j = 0;
          j < this.facultiNamesForDelete[i].assignedGroupForSubject.length;
          j++
        ) {
          if (
            this.facultiNamesForDelete[i].assignedGroupForSubject[j]
              ?.groupId === this.groupId &&
            this.facultiNamesForDelete[i].assignedGroupForSubject[j]
              ?.subjectId === this.selectedSubject.subjectId
          ) {
            this.facultiNamesForDelete[i].assignedGroupForSubject.splice(j, 1);
          }
        }
      }

      try {
        let updatedSemUserData =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.facultiNamesForDelete,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });

        let getFacultyObject = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uIds: this.getFacultiesTeachingASubject,
        };

        let getFacultiesData =
          await this.semesterUserRepositoryInstance.getMultipleSemesterUsers(
            getFacultyObject
          );
        this.getFacultiesData = getFacultiesData
        for (let i = 0; i < getFacultiesData.length; i++) {
          const assignedGroupForSubject =
            getFacultiesData[i]?.assignedGroupForSubject;
          const index = assignedGroupForSubject?.some(
            (group) =>
              group.groupId === this.groupId &&
              group.groupName === this.groupName &&
              group.subjectId === this.selectedSubject.subjectId
          )
            ? 1
            : -1;
          if (getFacultiesData[i]?.assignedGroupForSubject?.length === 0) {
            this.availableFaculties.push(getFacultiesData[i]);
          } else {
            if (index === 1) {
              this.assignedFaculties.push(getFacultiesData[i]);
            } else if (index === -1) {
              this.availableFaculties.push(getFacultiesData[i]);
            }
          }
        }

        for (let i = 0; i < this.getFullNamesOfFaculties.length; i++) {
          for (let j = 0; j < this.availableFaculties.length; j++) {
            if (
              this.getFullNamesOfFaculties[i].uId ==
              this.availableFaculties[j].uId
            ) {
              this.availableFaculties[j].fullName =
                this.getFullNamesOfFaculties[i].fullName;
            }
          }
        }

        for (let i = 0; i < this.getFullNamesOfFaculties.length; i++) {
          for (let j = 0; j < this.assignedFaculties.length; j++) {
            if (
              this.getFullNamesOfFaculties[i].uId ==
              this.assignedFaculties[j].uId
            ) {
              this.assignedFaculties[j].fullName =
                this.getFullNamesOfFaculties[i].fullName;
            }
          }
        }

        this.getAvailSemisterFacultyUsers = this.availableFaculties;
        this.facultiNamesForDelete = [];
        this.facultiNames = [];
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    gotoSemestersManagment() {
      this.$router.push({
        name:'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
  },
};
</script>
<style scoped src="./activitySubjectGroup.css"></style>
