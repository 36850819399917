<template src='./activityExamAnalysis.html'>
</template>
<script>
import bar from '../../../Components/AttendanceAnalysisBarGraph'
import pie from '../../../Components/AttendanceAnalysisPieChart'
import { subjectTypes } from '../../../Constants/Utils/Statics'
import ExamSubjectRepository from '../../../Repository/ExamSubject'
import ExamStudentRepository from '../../../Repository/ExamStudent'
import UserRepository from '../../../Repository/User'
import SubjectRepository from '../../../Repository/Subject'
export default {
  name: 'activityExamAnalysis',
  data () {
    return {
      analysisData: [],
      selectedInstitute: '',
      selectedExam: {},
      selectedSemester: {},
      // departments: [],
      exams: [],
      examsStrings: [],
      // courseYears: [],
      examSubjects: [],
      // selectedDepartment: '',
      // selectedExamString: '',
      // selectedCourseYear: '',
      // examSubjectsStrings: [],
      userMappings: {},
      items: [],
      itemsBackup: [],
      namesMapping: {},
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Total',
          value: 'total'
        }
      ],
      headersBackup: [],
      dataProccessed: false,
      subjectIds: [],
      attendanceDistribution: {
        '0 % - 10 %': 0,
        '10 % - 20 %': 0,
        '20 % - 30 %': 0,
        '30 % - 40 %': 0,
        '40 % - 50 %': 0,
        '50 % - 60 %': 0,
        '60 % - 70 %': 0,
        '70 % - 80 %': 0,
        '80 % - 90 %': 0,
        '90 % - 100 %': 0
      },
      attendanceDistributionBackup: {},
      disable: false,
      failingLimit: 40,
      failedCount: 0,
      limits: [0, 100],
      tempLimit: 40,
      changeDefaulterLimit: false,
      search: '',
      // selectedExamSubjectString: '',
      // selectedExamSubject: null,
      subjectTypes: subjectTypes
    }
  },
  components: {
    bar,
    pie
  },
  created () {
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.examStudentRepositoryInstance = new ExamStudentRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.fetchExamSubjects()
    // axios.get(`${apiUrl}/users/mappings`).then(res => {
    //   this.userMappings = res.data.result
    // axios.post(apiConfig.getExams, {
    //   instituteId: this.selectedInstitute,
    //   semId: this.selectedSemester.semId
    // }).then(res2 => {
    // console.log(res2)
    // this.examsStrings = []
    // this.exams = res2.data.exams
    // for (let i = 0; i < this.exams.length; i++) {
    //   this.examsStrings.push(this.exams[i].name + ' | ' + this.exams[i].type)
    // }
    // this.departments = res.data.result
    // }).catch(err => {
    //   console.log(err)
    // })
    // }).catch(err => {
    //   console.log(err)
    // })
  },
  methods: {
    async fetchExamSubjects () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId
      }
      console.log(objToPush)
      // Call API to get exam subjects
      try {
        this.examSubjects = await this.examSubjectRepositoryInstance.getSubjectsForAnExam(objToPush)
      } catch (err) {
        console.log(err)
        this.examSubjects = []
      }
      this.fetchAnalysis()
      // this.selectedExam = this.exams[this.examsStrings.indexOf(this.selectedExamString)]
      // axios.post(apiConfig.getExamSubjects).then(res => {
      //   console.log(res)
      // this.examSubjectsStrings = []
      // this.examSubjects = res.data.examSubject
      // for (let i = 0; i < this.examSubjects.length; i++) {
      //   this.examSubjectsStrings.push(this.examSubjects[i].subjectName + ' | ' + this.subjectTypes[this.examSubjects[i].subjectType])
      // }
      //   this.fetchAnalysis()
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    async fetchAnalysis () {
      // this.selectedExamSubject = this.examSubjects[this.examSubjectsStrings.indexOf(this.selectedExamSubjectString)]
      this.dataProccessed = false
      this.disable = true
      this.items = []
      this.subjectIds = []
      this.limits = [0, 100]
      this.headers = [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Total',
          value: 'total'
        }
      ]
      // Call API to get exam Analysis
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId
        // subjectCode: this.selectedExamSubjectString !== '' ? this.selectedExamSubject.subjectCode : undefined,
        // subjectName: this.selectedExamSubjectString !== '' ? this.selectedExamSubject.subjectName : undefined,
        // subjectType: this.selectedExamSubjectString !== '' ? this.selectedExamSubject.subjectType : undefined
      }
      try {
        this.analysisData = await this.examStudentRepositoryInstance.getExamAnalysis(objToPush)
      } catch (err) {
        console.log(err)
        this.analysisData = []
      }
      // console.log(this.analysisData)
      this.attendanceDistribution = {
        '0 % - 10 %': 0,
        '10 % - 20 %': 0,
        '20 % - 30 %': 0,
        '30 % - 40 %': 0,
        '40 % - 50 %': 0,
        '50 % - 60 %': 0,
        '60 % - 70 %': 0,
        '70 % - 80 %': 0,
        '80 % - 90 %': 0,
        '90 % - 100 %': 0
      }
      this.failedCount = 0
      const promises = []
      const subjectIdToSubjectMapping = {}
      for (let i = 0; i < this.analysisData.length; i++) {
        const obj = {}
        obj.uId = this.analysisData[i].uId
        obj.name = ''
        let totalP = 0
        let totalT = 0
        for (let j = 0; j < this.analysisData[i].exams.length; j++) {
          if (!subjectIdToSubjectMapping[this.analysisData[i].exams[j].subjectId]) {
            try {
              const subject = await this.subjectRepositoryInstance.getDetailsOfASubject({
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester.semId,
                subjectId: this.analysisData[i].exams[j].subjectId
              })
              subjectIdToSubjectMapping[this.analysisData[i].exams[j].subjectId] = subject
            } catch (err) {
              console.log(err)
            }
          }
          const obj2 = {
            text: subjectIdToSubjectMapping[this.analysisData[i].exams[j].subjectId].subjectName + ' | ' + this.subjectTypes[subjectIdToSubjectMapping[this.analysisData[i].exams[j].subjectId].subjectType],
            value: subjectIdToSubjectMapping[this.analysisData[i].exams[j].subjectId].subjectId
          }
          if (!this.subjectIds.includes(this.analysisData[i].exams[j].subjectId)) {
            this.headers.push(obj2)
            this.subjectIds.push(this.analysisData[i].exams[j].subjectId)
          }
          if (parseInt(this.analysisData[i].exams[j].total) > 0) {
            obj[subjectIdToSubjectMapping[this.analysisData[i].exams[j].subjectId].subjectId] = this.analysisData[i].exams[j].marksObtained + ' (' + Math.round(parseInt(this.analysisData[i].exams[j].marksObtained) / parseInt(this.analysisData[i].exams[j].total) * 100) + '%)'
          } else {
            obj[subjectIdToSubjectMapping[this.analysisData[i].exams[j].subjectId].subjectId] = this.analysisData[i].exams[j].marksObtained
          }
          totalP = totalP + parseInt(this.analysisData[i].exams[j].marksObtained.split('/')[0])
          totalT = totalT + parseInt(this.analysisData[i].exams[j].total ? this.analysisData[i].exams[j].total :'100')
          console.log("analysis data",this.analysisData[i].exams[j])
        }
        let total = 0
        let totalTemp = 0
        if (totalT !== 0) {
          total = Math.ceil((totalP / totalT) * 100) + ' %'
          totalTemp = Math.ceil((totalP / totalT) * 100)
        }
        obj.total = total
        if (totalTemp > 0 && totalTemp <= 10) {
          this.attendanceDistribution['0 % - 10 %']++
        }
        if (totalTemp > 10 && totalTemp <= 20) {
          this.attendanceDistribution['10 % - 20 %']++
        }
        if (totalTemp > 20 && totalTemp <= 30) {
          this.attendanceDistribution['20 % - 30 %']++
        }
        if (totalTemp > 30 && totalTemp <= 40) {
          this.attendanceDistribution['30 % - 40 %']++
        }
        if (totalTemp > 40 && totalTemp <= 50) {
          this.attendanceDistribution['40 % - 50 %']++
        }
        if (totalTemp > 50 && totalTemp <= 60) {
          this.attendanceDistribution['50 % - 60 %']++
        }
        if (totalTemp > 60 && totalTemp <= 70) {
          this.attendanceDistribution['60 % - 70 %']++
        }
        if (totalTemp > 70 && totalTemp <= 80) {
          this.attendanceDistribution['70 % - 80 %']++
        }
        if (totalTemp > 80 && totalTemp <= 90) {
          this.attendanceDistribution['80 % - 90 %']++
        }
        if (totalTemp > 90 && totalTemp <= 100) {
          this.attendanceDistribution['90 % - 100 %']++
        }
        if (totalTemp < this.failingLimit) {
          this.failedCount++
        }
        this.items.push(obj)
        promises.push(this.getNameOfUser(this.items.length - 1))
      }
      this.itemsBackup = JSON.parse(JSON.stringify(this.items))
      this.headersBackup = JSON.parse(JSON.stringify(this.headers))
      this.attendanceDistributionBackup = JSON.parse(JSON.stringify(this.attendanceDistributionBackup))
      this.disable = false
      this.dataProccessed = true
      await Promise.allSettled(promises)
    },
    async getNameOfUser (indexOfUser) {
      let name = ''
      try {
        if (this.namesMapping[this.items[indexOfUser].uId]) {
          name = this.namesMapping[this.items[indexOfUser].uId]
        } else {
          name = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.items[indexOfUser].uId })
          this.namesMapping[this.items[indexOfUser].uId] = name
        }
      } catch (err) {
        console.log(err)
      }
      this.items[indexOfUser].name = name
    },
    recalculateDefaulters () {
      this.failedCount = 0
      this.failingLimit = this.tempLimit
      for (let i = 0; i < this.itemsBackup.length; i++) {
        if (this.itemsBackup[i].total < this.failingLimit) {
          this.failedCount++
        }
      }
      this.changeDefaulterLimit = false
    },
    applyLimit () {
      this.dataProccessed = false
      this.items = []
      for (let i = 0; i < this.itemsBackup.length; i++) {
        console.log(this.itemsBackup[i])
        const total = parseInt(this.itemsBackup[i].total.split('%').join('').split(' ').join(''))
        if (total <= this.limits[1] && total >= this.limits[0]) {
          this.items.push(Object.assign(this.itemsBackup[i], { name: this.namesMapping[this.itemsBackup[i].uId] }))
        }
      }
      console.log(this.items)
      this.dataProccessed = true
    },
    showDefaulters () {
      this.limits = [0, this.failingLimit - 1]
      this.applyLimit()
    }
  }
}
</script>
<style scoped src='./activityExamAnalysis.css'>
</style>
