<template src="./StudentMarksheetStmiras.html"></template>
<script>
export default {
    name: "StudentMarksheetStmiras",
    props: ["studentDetails", "marksheetDetails", "isPreview"],
    data() {
        return {
            instituteName: "",
            instituteAddress: "",
            instituteLogo: "",
            semesterName: "",
            departmentName: "",
            studentPersonalDetails: [],
            tableData: [],
            signatureIndividual: [],
            includeInstituteNameInTheMarksheet: false,
            courseYear: "",
            includeCgpaTable: false,
            includeSgpaTable: false,
            sessionTitle: ""
        }
    },
    created() {
        console.log('this.marksheetDetails', this.marksheetDetails);
        this.courseYear = this.marksheetDetails.courseYear ? this.marksheetDetails.courseYear : ""
        this.instituteName = this.marksheetDetails.instituteName
        this.instituteAddress = this.marksheetDetails.instituteAddress
        this.instituteLogo = this.marksheetDetails.instituteLogo
        this.semesterName = this.marksheetDetails.semesterName
        this.departmentName = this.marksheetDetails.departmentName
        this.departmentCode = this.marksheetDetails.departmentCode
        this.signatureIndividual = this.marksheetDetails.marksheetTemplate.signatureIndividual
        this.includeInstituteNameInTheMarksheet = this.marksheetDetails.marksheetTemplate.includeInstituteNameInTheMarksheet
        this.includeSgpaTable = this.marksheetDetails.marksheetTemplate.includeSgpaTable
        this.sessionTitle = this.marksheetDetails.marksheetTemplate.sessionTitle
        this.includeCgpaTable = this.marksheetDetails.marksheetTemplate.includeCgpaTable
        this.studentPersonalDetails = this.marksheetDetails.marksheetTemplate.studentPersonalDetails
        this.tableData = this.marksheetDetails.marksheetTemplate.tableData
    }
}
</script>
<style scoped src="./StudentMarksheetStmiras.css">

</style>