<template>
  <div class="progress-container">
    <v-progress-circular
      :value="customValue"
      :size="size"
      :width="width"
      :color="color"
      :indeterminate="indeterminate"
      :rotate="rotate"
      :class="classes"
    >
      <slot></slot>
      <span :style="textStyle">
        {{ progressPercentage }}
      </span>
    </v-progress-circular>
  </div>
</template>
    
    <script>
export default {
  name: "CircularLoader",
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    size: {
      type: [Number, String],
      default: 40,
    },
    width: {
      type: [Number, String],
      default: 4,
    },
    color: {
      type: String,
      default: "#00128C",
    },
    indeterminate: {
      type: Boolean,
      default: true,
    },
    rotate: {
      type: Number,
    },
    customClass: {
      type: String,
      default: "",
    },
    progressPercentage: {
      type: String,
      default: "",
    },
    textSize: {
      type: [Number, String],
      default: 16, // in pixels(px)
    },
    textColor: {
      type: String,
      default: "#00128C",
    },
  },
  computed: {
    classes() {
      return this.customClass;
    },
    customValue() {
      const customValue =
        typeof this.value === "number" ? this.value : `${Number(this.value)}`;
      return customValue;
    },
    textStyle() {
      const customFontSize =
        typeof this.textSize === "number"
          ? `${this.textSize}px`
          : `${Number(this.textSize)}px`;
      const customFontColor = this.textColor;
      return {
        fontSize: customFontSize,
        color: customFontColor,
      };
    },
  },
};
</script>
    
    <style scoped>
/* Centering the progress component using Flexbox */
.progress-container {
  display: flex;
  justify-content: center;
}
</style>
    