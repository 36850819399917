export default {
  createTopicFeedback: '/topicFeedback/createTopicFeedback',
  getTopicFeedbackById: '/topicFeedback/getTopicFeedbackById',
  getTopicFeedbacksForAStudent: '/topicFeedback/getTopicFeedbacksForAStudent',
  getTopicFeedbacksForAStudentForASubject: '/topicFeedback/getTopicFeedbacksForAStudentForASubject',
  getTopicFeedbacksForAFacultyForASubject: '/topicFeedback/getTopicFeedbacksForAFacultyForASubject',
  getTopicFeedbacksForAFaculty: '/topicFeedback/getTopicFeedbacksForAFaculty',
  getTopicFeedbacksForADivisionForASubject: '/topicFeedback/getTopicFeedbacksForADivisionForASubject',
  getTopicFeedbacksByTopicId: '/topicFeedback/getTopicFeedbacksByTopicId',
  submitFeedbackOfAStudent: '/topicFeedback/submitFeedbackOfAStudent'
}
