<template src='./activityCreateInstituteOrUser.html'>
</template>
<script>
import activityProfile from '../activityProfile/activityProfile'
import activityInstitutesBasicDetails from '../activityInstitutesBasicDetails/activityInstitutesBasicDetails'
export default {
  name: 'activityCreateInstituteOrUser',
  components: {
    activityProfile,
    activityInstitutesBasicDetails
  },
  created () {
    this.creatingInstitute = (this.$router.history.current.name === 'acitivityCreateInstitute')
    console.log(this.creatingInstitute)
  },
  data () {
    return {
      creatingInstitute: true
    }
  }
}
</script>
<style src='./activityCreateInstituteOrUser.css' scoped>
</style>
