import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Discussion";

class Discussions {
  constructor(context) {
    this.context = context;
  }

  async createDiscussion(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createDiscussion,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.result;
  }

  // getDiscussionById
  async getDiscussionById(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getDiscussionById,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.result;
  }

  // getResponseByDiscussionId
  async getResponseByDiscussionId(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getResponseByDiscussionId,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.result;
  }

  async getAllDiscussionsForAUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getAllDiscussionsForAUser,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.result;
  }

  async deleteDiscussion(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.deleteRequest(
      ApiNames.deleteDiscussion,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async deleteResponse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.deleteRequest(
      ApiNames.deleteResponse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createResponse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createResponse,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createComment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createComment,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default Discussions;
