<template src='./activityDepartmentManagement.html'></template>
<script>
import inputContainer from '../../Components/inputContainer'
import showStatus from '../../NetworkManager/showStatus'
import arrayOps from '../../Services/Utils/arrayOps'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import SemesterRepository from '../../Repository/Semester'
export default {
  name: 'activityDepartmentManagement',
  components: {
    inputContainer
  },
  props: [
    'PropsSelectedSemester',
    'tree'
  ],
  data() {
    return {
      updateBtnFlag: false,
      selectedDepartmentByDropDown: '',
      divisionForSelect: [],
      prevDept: [],
      courseYearForSelect: [],
      departmentForSelect: [],
      academicData: [],
      loading: false,
      showAddDivisionDialog: false,
      showAddDepartmentDialog: false,
      showAddCourseDialog: false,
      showAddDivisionDialog: false,
      tempDivisionName: '',
      semesterName: '',
      tempCourseName: '',
      tempDepartmentName: '',
      departments: [],
      selectedDepartment: '',
      tempDepartment: '',
      showRemoveDepartmentDialog: false,
      courseYears: [],
      selectedCourseYear: '',
      tempCourseYear: '',
      showRemoveCourseYearDialog: false,
      divisions: [],
      academicDetails: [],
      departmentsList: [],
      selectedInstitute: '',
      academicTree: {},
      selectedSemester: {},
      tempDivision: '',
      showRemoveDivisionDialog: false
    }
  },
  created() {
    this.loading = true
    this.$store.commit('liveData/set_selectedActivityName', 'Department Management')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.selectedSemester = this.PropsSelectedSemester
    this.semesterName = this.selectedSemester.semName
    this.loading = false
    this.fetchDepartments()
  },
  methods: {
  
    async fetchDepartments() {
      try {
        this.divisionListOfSem = []
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.divisionListOfSem = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        await this.handleData()
      } catch (err) {
        console.log(err)
      }
    },
    async handleData() {
      this.departments = []
      this.courseYears = []
      this.divisions = []
      this.courseYearList = []
      this.departmentsList = []
      this.divisionList = []
      let tempDept = []
      this.divisionListOfSem.map((div) => {
        const obj = {
          department: div.department,
          isNew: false
        }
        if (!tempDept.includes(div.department)) {
          this.departmentsList.push(obj)
          tempDept.push(div.department)
        }
      })
      this.prevDept = []
      this.prevDept = tempDept
      try {
        this.departmentForSelect = []
        this.academicData = await this.academicReferenceRepositoryInstance.getAcademicReference({
          instituteId: this.selectedInstitute
        })
        // console.log('tempDept', tempDept);
        if (this.academicData.departments.length > 0) {
          this.academicData.departments.map((info) => {
            if (!tempDept.includes(info.department)) {
              this.departmentForSelect.push(info.department)
            }
          })

        }
        // console.log('this.academicData', this.academicData);

      } catch (err) {
        console.error('err', err);
      }
    },
    async startAddingDepartment() {
      this.showAddDepartmentDialog = true
    },
    cancelAddDepartment() {
      this.showAddDepartmentDialog = false
      this.tempDepartmentName = ''
    },
    cancelAddCourse() {
      this.showAddCourseDialog = false
      this.tempDepartmentName = ''
    },
    startAddingCourseName() {
      // console.log('this.courseYears', this.courseYears);
      this.courseYearForSelect = []
      const dummyArr = []
      if(this.courseYears.length > 0){
        this.courseYears.map((data) => {
          // console.log('data', data);
          dummyArr.push(data.courseYear)
        })
      }
      if (this.academicData.departments.length > 0) {
          this.academicData.departments.map((info) => {
            if (info.department === this.selectedDepartment && !dummyArr.includes(info.courseYear)) {
              this.courseYearForSelect.push(info.courseYear)
            }
          })

        }
      this.showAddCourseDialog = true
    },
    startAddingDivision() {
      this.divisionForSelect = []
      const dummyArr = []
      if(this.divisions.length > 0){
        this.divisions.map((data) => {
          // console.log('data', data);
          dummyArr.push(data.division)
        })
      }
      if (this.academicData.departments.length > 0) {
          this.academicData.departments.map((info) => {
            if (info.department === this.selectedDepartment && info.courseYear === this.selectedCourseYear && !dummyArr.includes(info.division)) {
              this.divisionForSelect.push(info.division)
            }
          })

        }
      this.showAddDivisionDialog = true
    },
    cancelAddDivision() {
      this.showAddDivisionDialog = false
      this.tempDivisionName = ''
    },
    addDepartment() {
      if (!this.departmentsList.includes(this.tempDepartmentName) && this.tempDepartmentName !== "") {
        this.departmentsList.push({
          department: this.tempDepartmentName,
          isNew: true
        })
        this.showAddDepartmentDialog = false
        console.log('this.divisionListOfSem', this.divisionListOfSem);
        this.tempDepartmentName = ''
      } else {
        alert("This department already exist")
        this.tempDepartmentName = ''
        return
      }
    },
    addCourse() {
      if (!this.courseYears.includes(this.tempCourseName)) {
        this.courseYears.push({
          courseYear: this.tempCourseName,
          isNew: true
        })
        this.showAddCourseDialog = false
        this.tempCourseName = ''
      } else {
        alert("This course already exist")
        this.tempCourseName = ''
        return
      }
    },
    addDivision() {
      if (!this.divisions.includes({ division: this.tempDivisionName })) {
        this.divisions.push({ division: this.tempDivisionName, isNew: true })
        this.divisionListOfSem.push({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          semName: this.selectedSemester.semName,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.tempDivisionName,
          isNew: true
        })
        this.updateBtnFlag = true
        this.showAddDivisionDialog = false
        this.tempDivisionName = ''
      } else {
        this.updateBtnFlag = false
        alert("This division already exist")
        this.tempDivisionName = ''
        return
      }
    },
    selectDepartment(department) {
      let tempCourseYears = []
      this.courseYears = []
      this.divisions = []
      this.selectedCourseYear = ''
      this.selectedDivision = ''
      this.selectedDepartment = department
      let tempcourseArr = []
      // if (this.prevDept.includes(department)) {

        this.divisionListOfSem.map((e) => {
          if (e.department === department && e.courseYear !== undefined && !tempcourseArr.includes(e.courseYear)) {
            tempCourseYears.push({
              courseYear: e.courseYear,
              isNew: e.isNew
            })
            tempcourseArr.push(e.courseYear)
          }
        })
      // } else {
      // if(this.academicData.departments.length > 0){
      //  this.academicData.departments.map((e) => {

      //    console.log('e', e);
      //     if (e.department === department && e.courseYear !== undefined && !tempcourseArr.includes(e.courseYear)) {
      //       tempCourseYears.push({
      //         courseYear: e.courseYear,
      //         isNew: e.isNew
      //       })
      //       tempcourseArr.push(e.courseYear)
      //     }
      //   })
      // }
      // }
      if (tempCourseYears.length > 0 && tempCourseYears[0] !== '') {
        this.courseYears = tempCourseYears
      }
    },
    startRemovingDepartment(department) {
      this.updateBtnFlag = false
      let i = this.departmentsList.indexOf(department)
      this.departmentsList.splice(i, 1)
      // console.log('this.divisionListOfSem', this.divisionListOfSem);
      const tempArr = []
      this.divisionListOfSem.forEach((obj) => {
        if (obj.department === department.department) {
        } else {
          tempArr.push(obj)
        }
      })
      this.divisionListOfSem = tempArr
      this.courseYears = []
      this.divisions = []
    },
    startRemovingCourseYear(courseYear) {
      this.updateBtnFlag = false

      let i = this.courseYears.indexOf(courseYear)
      // console.log('i', i);
      // console.log('this.divisionListOfSem1', this.divisionListOfSem);
      this.courseYears.splice(i, 1)
      // console.log('this.divisionListOfSem2', this.divisionListOfSem);
      const tempArr = []
      this.divisionListOfSem.forEach((obj) => {
        if (obj.department === this.selectedDepartment && obj.courseYear === courseYear.courseYear) {
        } else {
          tempArr.push(obj)
        }
      })
      // console.log('tempArr', tempArr);
      this.divisionListOfSem = tempArr
      this.divisions = []
      this.selectedCourseYear = ''
    },
    startRemovingDivision(division) {
      this.updateBtnFlag = false
      let i = this.divisions.indexOf(division)
      this.divisions.splice(i, 1)
      const tempArr = []
      this.divisionListOfSem.forEach((obj) => {
        if (obj.department === this.selectedDepartment && obj.courseYear === this.selectedCourseYear && obj.division === division.division) {
        } else {
          tempArr.push(obj)
        }
      })
      this.divisionListOfSem = tempArr
      this.divisions = []
      this.selectedCourseYear = ''
    },
    selectCourseYear(courseYear) {
      // let divisions = []
      this.divisions = []
      this.updateBtnFlag = false
      this.selectedCourseYear = courseYear
      let tempDivisions = []
      this.divisionListOfSem.map((e) => {
        if (e.department === this.selectedDepartment && e.courseYear === courseYear && !tempDivisions.includes(e.division)) {
          this.divisions.push({ division: e.division, isNew: e.isNew })
          tempDivisions.push(e.division)
        }
      })
    },
    async createDepartment() {
      const objToPush = {
        divisionList: this.divisionListOfSem
      }
      try {
        // console.log('objToPush', objToPush);
        await this.semesterRepositoryInstance.createDepartment(objToPush)
        showStatus('Semester Created Successfully', 1000, 'success', this)
        this.fetchDepartments()
      } catch (err) {
        console.log(err)
        showStatus('Error in creating semester', 1000, 'error', this)
      }
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: 'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
  }
}
</script>
<style src='./activityDepartmentManagement.css' scoped></style>
