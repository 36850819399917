<template src='./activityEditExamSubjectQuestions.html'>
</template>
<script>
import auth from "../../../Services/Firebase/auth";
import {
  subjectTypes,
  questionTypesTemp,
  optionEnabled
} from "../../../Constants/Utils/Statics";
import SubjectRepository from '../../../Repository/Subject'
import ExamSubjectRepository from '../../../Repository/ExamSubject'
import showStatus from '../../../NetworkManager/showStatus'
import inputContainer from '../../../Components/inputContainer'
import axios from "axios";
var firestorage = null;
export default {
  name: 'activityEditExamSubjectQuestions',
  components: {
    inputContainer,
  },
  data () {
    return {
      selectedInstitute: '',
      selectedSemester: {},
      selectedExam: null,
      subjects: [],
      examSubjects: [],
      availableSubjects: [],
      selectedSubject: '',
      showSubjectAdd: false,
      showSubjectRemove: false,
      appliedSubjects: [],
      appliedSubjectsMapping: {},
      subjectTypes: subjectTypes,
      subjectGroups: {},
      loading: true,
      subjectGroupsIdToNameMapping: {},
      showQuestions: false,
      headers: [
        {
          text: 'Subject Name',
          value: 'subjectName'
        },
        {
          text: 'Subject Code',
          value: 'subjectCode'
        },
        {
          text: 'Subject Type',
          value: 'subjectType'
        },
        {
          text: 'Exam Mode',
          value: 'examMode'
        },
        {
          text: 'Marks',
          value: 'marks'
        },
        {
          text: 'Questions',
          value: 'questions'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      openTextEditor: false,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          BoldPlugin,
          ItalicPlugin,
          MathType,
          LinkPlugin
        ],
        toolbar: {
          items: ["MathType"],
          shouldNotGroupWhenFull: true
        },
        placeholder: "Question Statement"
      },
      editorConfigAddOption: {
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          BoldPlugin,
          ItalicPlugin,
          MathType,
          LinkPlugin
        ],
        toolbar: {
          items: ["MathType"],
          shouldNotGroupWhenFull: true
        },
        placeholder: "Add Option"
      },
      isDownloading: false,
      defaultButtonText: "Upload File",
      selectedFile: null,
      isSelecting: false,
      loading2: false,
      loading: true,
      selectedExam: {},
      selectedExamSubject: {},
      showQuestions: false,
      search: "",
      headers2: [
        {
          text: "Ques. No.",
          value: "questionNumber"
        },
        {
          text: "Question",
          value: "question"
        },
        {
          text: "Type",
          value: "type"
        },
        {
          text: "Marks",
          value: "mark"
        },
        {
          text: "Negative Marks",
          value: "nmark"
        },
        {
          text: "Options",
          value: "options"
        },
        {
          text: "Answer",
          value: "answer"
        },
        {
          text: "Images",
          value: "imageLink"
        },
        {
          text: "Edit",
          value: "edit"
        },
        {
          text: "Remove",
          value: "remove"
        }
      ],
      refresh: true,
      selectedQuestion: {},
      showEditQuestion: false,
      files: [],
      addingQuestion: false,
      tempQuestion: {},
      optionEnabled: optionEnabled,
      subjectTypes: subjectTypes,
      questionTypes: questionTypesTemp,
      addingOption: false,
      tempOption: "",
      downloadURL: [],
      images: [],
      others: [],
      uploadCount: 0,
      fileName: "",
      uploading: false,
      uploadTask: "",
      uploadEnd: false,
      progressUpload: 0,
    }
  },
  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    }
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            console.log("hello", downloadURL);
            this.images.push(downloadURL);
            // if (this.uploadTask.type === 'image') {
            //   this.images.push(downloadURL)
            //   console.log(downloadURL, 'Hello')
            // } else {
            //   this.others.push(downloadURL)
            // }
            this.downloadURL.push(downloadURL);
            this.uploadCount++;
            if (this.uploadCount === this.files.length) {
              if (this.showEditQuestion) {
                this.editQuestion();
              } else {
                this.addQuestion();
              }
            }
          });
        }
      );
    }
  },
  created () {
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    const firebase = auth.getFirebaseObject();
    firestorage = firebase.storage();
    this.fetchSubjects()
  },
  mounted() {
    const wiris = document.createElement("script");
    wiris.setAttribute(
      "src",
      "https://polyfill.io/v3/polyfill.min.js?features=es6"
    );
    document.head.appendChild(wiris);
    const mathjax = document.createElement("script");
    mathjax.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js"
    );
    mathjax.setAttribute("id", "MathJax-script");
    document.head.appendChild(mathjax);
    // this.createFieldBox()
  },
  methods: {
    async fetchSubjects () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedExam.criteria.department,
        courseYear: this.selectedExam.criteria.courseYear ? this.selectedExam.criteria.courseYear : undefined
      }
      const objToPush2 = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId
      }
      console.log(objToPush, objToPush2)
      this.subjects = []
      try {
        this.subjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(objToPush)
      } catch (err) {
        console.log(err)
      }
      this.examSubjects = []
      try {
        this.examSubjects = await this.examSubjectRepositoryInstance.getSubjectsForAnExam(objToPush2) || []
      } catch (err) {
        console.log(err)
        this.examSubjects = []
      }
      console.log('this.examSubjects', this.examSubjects)
      this.availableSubjects = []
      this.appliedSubjects = []
      this.appliedSubjectsMapping = {}
      this.examSubjects.forEach((sub) => {
        // this.appliedSubjects.push(sub.department + '_' + sub.courseYear + '_' + sub.division + '_' + sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType)
        // this.appliedSubjects.push(sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType + '_' + sub.criteria.courseYear)
        this.appliedSubjectsMapping[sub.subjectId] = sub
        this.appliedSubjectsMapping[sub.subjectId].isOnline2 = sub.isOnline ? 'Online' : 'Offline'
        this.appliedSubjects.push(sub.subjectId)
      })
      // for (let i = 0; i < this.examSubjects.length; i++) {
      //   let sub = this.examSubjects[i]
      //   console.log(sub)
      // }
      this.subjectGroups = {}
      this.subjects.forEach((sub) => {
        // const subCode = sub.subjectCode.split('-')[0]
        const subCode = sub.groupId
        if (this.subjectGroups[subCode]) {
          this.subjectGroups[subCode].push(sub)
        } else {
          this.subjectGroups[subCode] = []
          this.subjectGroups[subCode].push(sub)
        }
        this.subjectGroupsIdToNameMapping[subCode] = {
          groupName: sub.groupName,
          department: sub.department,
          courseYear: sub.courseYear
        }
      })
      // for (let i = 0; i < this.subjects.length; i++) {
      //   let sub = this.subjects[i]
      // let subString = sub.department + '_' + sub.courseYear + '_' + sub.division + '_' + sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType
      // let subString = sub.subjectCode + '_' + sub.subjectName + '_' + sub.subjectType
      // if (this.appliedSubjects.indexOf(subString) === -1) {
      //   this.availableSubjects.push(subString)
      // }
      // }
      this.loading = false
    },
    async updateExamSubject (examSubject) {
      console.log(examSubject)
      this.loading = true
      examSubject.isOnline = (examSubject.isOnline2 === 'Online')
      const objToPush = JSON.parse(JSON.stringify(examSubject))
      delete objToPush.isOnline2
      console.log(objToPush)
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(
          objToPush
        )
        showStatus('Changes saved successfully.', 1000, 'success', this)
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    async addSubject (sub) {
      this.loading = false
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId,
        criteria: {
          department: this.selectedExam.criteria.department,
          courseYear: this.selectedSubject.courseYear
        },
        // subjectCode: this.selectedSubject.subjectCode,
        // subjectName: this.selectedSubject.subjectName,
        // subjectType: this.selectedSubject.subjectType,
        subjectId: this.selectedSubject.subjectId,
        totalMarks: 0,
        isOnline: false,
        listOfTopics: [],
        questions: [],
        numberOfQuestionsToShow: 0,
        shuffle: false
      }
      console.log('This is an object to be pushed')
      console.log(objToPush, this.selectedSubject)
      try {
        await this.examSubjectRepositoryInstance.createExamSubject(objToPush)
      } catch (err) {
        console.log(err)
        showStatus('Error in adding subject', 1000, 'error', this)
      }
      // Call API to add subject in exam
      // this.examSubjects.push(res.data.examSubject)
      // this.availableSubjects = this.remove_array_element(this.availableSubjects, this.availableSubjects.indexOf(this.selectedSubject))
      // this.appliedSubjects.push(this.selectedSubject)
      this.showSubjectAdd = false
      this.fetchSubjects()
    },
    async removeSubject (sub) {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId,
        criteria: {
          department: this.selectedExam.criteria.department,
          courseYear: this.selectedSubject.courseYear
        },
        // subjectCode: this.selectedSubject.subjectCode,
        // subjectName: this.selectedSubject.subjectName,
        // subjectType: this.selectedSubject.subjectType
        subjectId: this.selectedSubject.subjectId
      }
      console.log(objToPush)
      try {
        await this.examSubjectRepositoryInstance.deleteExamSubject(objToPush)
      } catch (err) {
        console.log(err)
        showStatus('Error in deleting subject', 1000, 'error', this)
      }
      // this.examSubjects = this.remove_array_element(this.examSubjects, this.appliedSubjects.indexOf(this.selectedSubject))
      // this.appliedSubjects = this.remove_array_element(this.appliedSubjects, this.appliedSubjects.indexOf(this.selectedSubject))
      // this.availableSubjects.push(this.selectedSubject)
      this.showSubjectRemove = false
      this.fetchSubjects()
    },
    createFieldBox() {
      setTimeout(function() {
        ClassicEditor.create(document.querySelector("#mathEquation"), {
          plugins: [
            EssentialsPlugin,
            ParagraphPlugin,
            BoldPlugin,
            ItalicPlugin,
            MathType,
            LinkPlugin
          ],
          toolbar: ["MathType"],
          placeholder: "Question Statement"
        })
          .then(editor => {
            console.log("Editor was initialized", editor);
            let mathButton = document.querySelector("#mathEquation");
            if (btnSend) {
              mathButton.setAttribute("v-model", "tempQuestion.question");
              console.log("attrivute setted");
            }
          })
          .catch(error => {
            console.error(error.stack);
          });
        // this.openTextEditor = true
        console.log("yo");
      }, 500);
    },
    convertMaths () {
      setTimeout(function () {
        MathJax.typeset()
      }, 500)
      // var math = MathJax.Hub.getAllJax('mathdiv')[0]
      // MathJax.Hub.Queue(['Text', math, s])
    },
    gotoDefineExamSubjects () {
      this.$router.push('/activityDefineExamSubjects')
    },
    async removeQuestion(que) {
      const tempObj = JSON.stringify(this.selectedExamSubject);
      const tempObj2 = JSON.parse(tempObj);
      tempObj2.questions = this.remove_array_element(
        tempObj2.questions,
        que.questionNumber - 1
      );
      tempObj2.totalObtainableMarks = 0;
      tempObj2.numberOfQuestionsToShow = tempObj2.questions.length;
      for (let i = 0; i < tempObj2.questions.length; i++) {
        tempObj2.questions[i].questionNumber = i + 1;
        tempObj2.totalObtainableMarks += parseInt(
          tempObj2.questions[i].mark.plus
        );
        delete tempObj2.questions[i].mark.elm;
        delete tempObj2.questions[i].mark.isRootInsert;
      }

      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2);
        this.selectedExamSubject = JSON.parse(JSON.stringify(tempObj2));
      } catch (err) {
        console.log(err);
        showStatus(
          "Could not remove question. Try again.",
          1000,
          "error",
          this
        );
      }
      // Call APi to update examSubject
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
    },
    editQuestions (item) {
      console.log(item)
      this.selectedExamSubject = item
      this.showQuestions = true
      this.convertMaths()
    },
    editQuestionStep1(que) {
      this.selectedQuestion = JSON.parse(JSON.stringify(que));
      this.showEditQuestion = true;
      this.files = [];
    },
    addQuestionStep1() {
      this.files = [];
      this.tempQuestion = {
        questionNumber: this.selectedExamSubject.questions.length + 1,
        question: "",
        type: "",
        mark: {
          plus: 0,
          minus: 0
        },
        options: [],
        answer: [],
        imageLink: []
      };
      this.addingQuestion = true;
    },
    filesChanged(e) {
      console.log(e);
    },
    uploadFiles() {
      this.downloadURL = [];
      this.images = [];
      this.others = [];
      this.uploadCount = 0;
      console.log(this.files);
      if (this.files.length === 0) {
        if (this.showEditQuestion) {
          this.editQuestion();
        } else {
          this.addQuestion();
        }
      } else {
        Array.from(Array(this.files.length).keys()).map(x => {
          this.upload(this.files[x]);
        });
      }
    },
    async editQuestion() {
      console.log("editing question");
      const tempObj = JSON.stringify(this.selectedExamSubject);
      const tempObj2 = JSON.parse(tempObj);
      this.refresh = false;
      // tempObj2.imageLink = this.images
      this.selectedQuestion.imageLink = this.images;
      tempObj2.questions[this.selectedQuestion.questionNumber - 1] = JSON.parse(
        JSON.stringify(this.selectedQuestion)
      );
      tempObj2.totalObtainableMarks = 0;
      tempObj2.numberOfQuestionsToShow = tempObj2.questions.length;
      for (let i = 0; i < tempObj2.questions.length; i++) {
        tempObj2.questions[i].questionNumber = i + 1;
        tempObj2.totalObtainableMarks += parseInt(
          tempObj2.questions[i].mark.plus
        );
        delete tempObj2.questions[i].mark.elm;
        delete tempObj2.questions[i].mark.isRootInsert;
      }
      console.log(this.images, tempObj2);
      // Call APi to update examSubject
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2);
        this.selectedExamSubject = JSON.parse(JSON.stringify(tempObj2));
        this.showEditQuestion = false;
        this.refresh = true;
      } catch (err) {
        console.log(err);
      }
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
      this.convertMaths();
    },
    async addQuestion() {
      console.log("adding Question");
      const tempObj = JSON.stringify(this.selectedExamSubject);
      const tempObj2 = JSON.parse(tempObj);
      this.refresh = false;
      // this.selectedExamSubject.imageLink = this.images
      this.tempQuestion.imageLink = this.images;
      tempObj2.questions.push(this.tempQuestion);
      // tempObj2.questions[this.selectedQuestion.questionNumber - 1] = JSON.parse(JSON.stringify(this.selectedQuestion))
      tempObj2.totalObtainableMarks = 0;
      tempObj2.numberOfQuestionsToShow = tempObj2.questions.length;
      for (let i = 0; i < tempObj2.questions.length; i++) {
        tempObj2.questions[i].questionNumber = i + 1;
        tempObj2.totalObtainableMarks += parseInt(
          tempObj2.questions[i].mark.plus
        );
        delete tempObj2.questions[i].mark.elm;
        delete tempObj2.questions[i].mark.isRootInsert;
      }
      console.log(tempObj2);
      // Call APi to update examSubject
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2);
        this.selectedExamSubject = JSON.parse(JSON.stringify(tempObj2));
        this.addingQuestion = false;
        this.refresh = true;
      } catch (err) {
        console.log(err);
      }
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
      console.log("Finishes");
      this.convertMaths();
    },
    upload(file) {
      console.log("uploading");
      this.fileName = file.name;
      this.uploading = true;
      if (file.type.split("/")[0] === "image") {
        if (this.showEditQuestion) {
          this.uploadTask = firestorage
            .ref(
              "exams/" +
                this.selectedExam.examId +
                "/" +
                this.selectedQuestion.questionNumber +
                "/" +
                file.name
            )
            .put(file);
        } else {
          this.uploadTask = firestorage
            .ref(
              "exams/" +
                this.selectedExam.examId +
                "/" +
                this.tempQuestion.questionNumber +
                "/" +
                file.name
            )
            .put(file);
        }
      } else {
        // this.uploadTask = firestorage.ref('posts/' + this.userData.id + '/' + new Date().valueOf().toString() + '________' + file.name).put(file)
        window.alert("Only Image Files are supported.");
        this.uploadCount++;
      }
    },
    remove_array_element(array, n) {
      if (n > -1) {
        array.splice(n, 1);
      }
      return array;
    },
    async addInBulkaddQuestionStep1(obj) {
      this.files = [];
      this.tempQuestion = {
        questionNumber: this.selectedExamSubject.questions.length + 1,
        question: obj.Question,
        type: "Single Answer MCQ",
        mark: {
          plus: obj.PositiveMarks,
          minus: obj.NegativeMarks
        },
        options: [obj.Option1, obj.Option2, obj.Option3, obj.Option4],
        answer: obj.CorrectOption,
        imageLink: []
      };
      // this.addingQuestion = true
      this.downloadURL = [];
      this.images = [];
      this.others = [];
      this.uploadCount = 0;
      await this.addQuestion();
    },
    async addInBulk(jsonObject) {
      for (var i = 0; i < jsonObject.length; i++) {
        var obj = jsonObject[i];
        console.log(obj);
        await this.addInBulkaddQuestionStep1(obj);
      }
      // dfdf
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          console.log("selected");
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      var formData = new FormData();
      var imagefile = document.querySelector("#sampleFile");
      formData.append("sampleFile", imagefile.files[0]);
      console.log(">> formData >> ", formData);
      axios
        .post(
          "https://cors-anywhere.herokuapp.com/https://xlsprocessapp.herokuapp.com/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(
          response => {
            console.log(response.data);
            this.addInBulk(response.data);
          },
          error => {
            console.log(error);
          }
        )
        .then(() => {
          this.isSelecting = false;
        });
      // do something
    },
    goBack () {
      this.$router.go(-1)
    },
    gotoDefineExamSubjects () {
      this.$router.push('activityDefineExamSubjects')
    },
    downloadSample() {
      this.isDownloading = true;
      axios({
        url:
          "https://cors-anywhere.herokuapp.com/https://xlsprocessapp.herokuapp.com/file",
        method: "GET",
        responseType: "blob"
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Sample_Questionnaire.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
        this.isDownloading = false;
      });
    },
    onEditorFocus() {
      console.log("Focused");
      // document.getElementsByClassName('dialogue1').blur()
      // document.getElementsByClassName('dialogue2').blur()
    }
  }
}
</script>
<style scoped src='./activityEditExamSubjectQuestions.css'>
</style>
