<template src="./activityArrangeLectureByValueAdded.html"></template>

<script>
import inputContainer from "../../Components/inputContainer";
import SubjectRepository from "../../Repository/Subject";
import SemesterUserRepository from "../../Repository/SemesterUser";
import UserRepository from "../../Repository/User";
import SemesterRepository from "../../Repository/Semester";
import ShiftRepository from "../../Repository/ShiftReference";
import TimetableRepository from "../../Repository/Timetable";
import LecturesRepository from "../../Repository/Lecture";
import InfrastructureReferenceRepository from "../../Repository/InfrastructureReference";
import ValueAddedActivityRepository from "../../Repository/ValueAddedActivity";
import {
  convertDate,
  formatDateTimeToDate,
  timeStringConverter,
  convertFromYYYYMMDDToDate,
  convertUTCDateToLocalDate
} from "../../Services/Utils/DateTimeFormatter";
import showStatus from "../../NetworkManager/showStatus";
import apiV3 from '../../NetworkManager/apiV3'
import ActivityLoggerRepository from "../../Repository/ActivityLogger";


export default {
  name: "activityArrangeLectureByValueAdded",
  props: ["prop_selectedDate", "prop_occupiedSlots"],
  components: {
    inputContainer,
  },
  data() {
    return {
      valueAddedActivityItem: {},
      assignedDepartment: [],
      time: '',
      time1: '',
      menu2: false,
      menu1: false,
      startTime: false,
      endTime: false,
      tempStartTime: '',
      tempEndTime: '',
      endTime: false,
      allValueAddedActivity: [],
      semesters: [],
      semestersList: [],
      occupiedSlots: [],
      selectedDate: this.prop_selectedDate,
      dataFetched: false,
      facultyNames: [],
      selectedDepartment: "",
      departments: [],
      selectedFaculty: "",
      faculties: [],
      userData: {},
      selectedInstitute: "",
      selectedSemester: {},
      allocations: [],
      subjectsMapping: {},
      freeSlots: [],
      selectedAllocation: {
        name: "",
      },
      tempTimeSlot: "",
      tempLocation: "",
      locations: [],
      locationNames: [],
      freeSlotsList: [],
      mySlots: [],
      finalStep: false,
      timetable: [],
      shift: {},
      facultyOccupiedSlots: [],
      divisionOccupiedSlots: [],
      allData: [],
      departmentsNew: [],
      selectDepartmentNew: "",
      courseYearList: [],
      selectCourseYear: "",
      isLoadingSubjects: false,
      subjects: [],
      selectedSubject: null,
      divisionList: [],
      selectDivision: null,
      isDatesModalOpen: false,
      dates: [],
      isArrangingLectures: false,
      tempLectureList: [],
      lecturesToMerge: [],
      isSubjectInfoLoading: false,
    };
  },
  created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.shiftRepositoryInstance = new ShiftRepository(this);
    this.timetableRepositoryInstance = new TimetableRepository(this);
    this.lecturesRepositoryInstance = new LecturesRepository(this);
    this.ValueAddedActivityRepositoryInstance = new ValueAddedActivityRepository(this);
    this.infrastructureReferenceRepositoryInstance =
      new InfrastructureReferenceRepository(this);
    this.subjectsMapping = {};
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
      this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.$store.commit("liveData/set_selectedActivityName", "Arrange Lecture By Dashboard");
    this.userData = this.$store.getters["user/get_userData"];
    console.log('this.userData', this.userData);
    console.log('this.prop_selectedDate', this.prop_selectedDate);
    this.activityLoggerInstance = new ActivityLoggerRepository(this)
    this.fetchData();
  },
  methods: {
    arrangeLectureDialog(item) {
      this.time = '',
        this.time1 = '',
        this.tempLocation = ''
        this.dates = []
        this.valueAddedActivityItem = {}
      this.valueAddedActivityItem = item
      this.finalStep = true


    },
    async createLectureForValueAdded() {
      this.dates.map(async (date) => {

        const lectureToArrange = {
          instituteId: this.valueAddedActivityItem.instituteId,
          semId: this.valueAddedActivityItem.semId,
          department: this.valueAddedActivityItem.department,
          valueAddedActivityId: this.valueAddedActivityItem.valueAddedActivityId,

          day: this.getDayNameFromDayNumber(new Date(date).getDay())
        }
        if (this.valueAddedActivityItem.assignedFaculty.length > 0) {

          lectureToArrange.uId =  this.valueAddedActivityItem.assignedFaculty[0]
        }else {
          lectureToArrange.uId =  this.userData.uId

        }
        if (this.valueAddedActivityItem.course) {

          lectureToArrange.subjectId = this.valueAddedActivityItem.course
        }
        if (this.tempLocation !== '') {

          this.locations.map((location) => {
            if (location.roomName === this.tempLocation) {
              lectureToArrange.roomId = location.roomId
            }
          })
        }

        const dateTime = await this.getDateTimeOfLecture(
          date,
          this.tempStartTime
        )
        const utcStartTime = await this.getUtcTimeOfLecture(
          date,
          this.tempStartTime
        )
        console.log('utcStartTime', utcStartTime);
        const utcEndTime = await this.getUtcTimeOfLecture(
          date,
          this.tempEndTime
        )
        lectureToArrange.startTime = utcStartTime
        lectureToArrange.endTime = utcEndTime
        lectureToArrange.dateTime = new Date(dateTime).toISOString()

        console.log('lectureToArrange', lectureToArrange)
        try {
          const objToPush = {
            lectures: [lectureToArrange]
          }
          const lectures = await this.lecturesRepositoryInstance.arrangeALecture(objToPush);
          const activityLoggerPayloadArr = [];
          for (let i = 0;i < lectures.length;i++) {
            activityLoggerPayloadArr.push({
              instituteId: lectures[i].instituteId,
              semId: lectures[i].semId,
              department: lectures[i].department,
              courseYear: '-',
              division: '-',
              subjectId: lectures[i].valueAddedActivityId,
              subjectName: this.valueAddedActivityItem.title,
              facultyUid: lectures[i].uId,
              type:"lectureDashboard",
              lectureStartTime: lectures[i].startTime,
              lectureEndTime: lectures[i].endTime,
              lectureId: '-',
              savedAt: new Date().toISOString(),
              savedBy: this.userData.firstName,
              actionType: "Create"
            })
          }
          await this.activityLoggerInstance.createMultipleLogs({
            logsArray: activityLoggerPayloadArr
          });
        } 
        catch (e) {
          console.log(e)
        }
      })
      this.finalStep = false
    },
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        );
        const promises = [];
        for (const semester of this.semesters) {
          promises.push(
            this.getDivisionsOfASemester({
              instituteId: this.selectedInstitute,
              semId: semester.semId,
              uId: this.userData.uId,
            })
          );
        }
        await Promise.all(promises);
        this.semesters.reverse();

        if (this.semesters.length > 0) {
          this.selectedSemester = this.semesters[0];
          this.selectSemester(this.semesters[0]);
        }
      } catch (err) {
        console.log(err);
        this.semesters = [];
        this.semestersList = [];
      }

    },
    convertUTCDateToLocalDate(date) {
      return convertUTCDateToLocalDate(date)
    },
    formatDateTimeToDate(date) {
      return formatDateTimeToDate(date)
    },
    selectSemester() {
      this.subjects = []
      this.selectedSubject = null
      this.departmentsNew = []
      this.tempLectureList = []
      const data = this.allData.filter((el) => el.semName === this.selectedSemester.semName)
      const departments = []
      for (const sem of data) {
        if (!departments.includes(sem.department)) {
          departments.push(sem.department)
        }
      }
      this.departmentsNew = departments
      let subAdminAssignedDepartmentWiseLecture = []
      if(this.assignedDepartment.length > 0) {
        this.departmentsNew.map((data) => {
          if(this.assignedDepartment.includes(data)){
            subAdminAssignedDepartmentWiseLecture.push(data)
          }
        })
        this.departmentsNew = subAdminAssignedDepartmentWiseLecture
        }
    },
    async selectAllocation(allocation) {
      this.tempTimeSlot = "";
      this.tempLocation = "";
      const objToPush = {
        instituteId: allocation.instituteId,
        semId: allocation.semId,
        department: allocation.department,
        courseYear: allocation.courseYear,
        division: this.selectDivision,
      };

      this.locations = [];

      try {
        this.locations =
          await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(
            objToPush
          );
      } catch (err) {
        console.log(err);
        this.locations = [];
      }

      this.locations.forEach((location) => {
        this.locationNames.push(location.roomName);
      });

      try {
        const shiftId =
          await this.semesterRepositoryInstance.getShiftOfADivisionOfASemester(
            objToPush
          );
        if (shiftId) {
          this.shift = await this.shiftRepositoryInstance.getShift({
            instituteId: allocation.instituteId,
            shiftId: shiftId,
          });
        }
      } catch (err) {
        console.log(err);
      }

      try {
        this.selectedDate.forEach(async (date) => {
          objToPush.dateOfLecturesNeeded = new Date(this.convertToDateObject(date)),
            console.log('objToPush', objToPush);
          this.timetable = await this.lecturesRepositoryInstance.getLecturesOfADivisionForADay(objToPush);
        })
      } catch (err) {
        console.log(err);
        this.timetable = [];
      }
      this.freeSlots = [];
      this.freeSlotsList = [];
      this.facultyOccupiedSlots = [];
      this.divisionOccupiedSlots = [];
      if (this.shift.timeSlots) {
        this.shift.timeSlots.forEach((timeSlot) => {
          this.freeSlots.push(timeSlot);
        });
      }
      this.freeSlots.forEach((tSlot) => {
        this.freeSlotsList.push(
          timeStringConverter(tSlot.startTime) +
          " - " +
          timeStringConverter(tSlot.endTime)
        );
        this.mySlots.push(tSlot.startTime + '-' + tSlot.endTime)
      });
      this.occupiedSlots.forEach((tSlot) => {
        this.facultyOccupiedSlots.push(
          timeStringConverter(tSlot.startTime) +
          " - " +
          timeStringConverter(tSlot.endTime)
        );
      });
      this.timetable.forEach((tSlot) => {
        this.divisionOccupiedSlots.push(
          timeStringConverter(tSlot.startTime) +
          " - " +
          timeStringConverter(tSlot.endTime)
        );
      });
      console.log(
        this.freeSlots,
        this.freeSlotsList,
        this.facultyOccupiedSlots,
        this.divisionOccupiedSlots
      );
    },
    convertToYYYYMMDD(dateString) {
      return this.convertToDDMMYYYY(dateString).split("-").reverse().join("-");
    },
    convertToDDMMYYYY(dateString) {
      return convertDate(dateString);
    },
    convertToDateObject(dateString) {
      return convertFromYYYYMMDDToDate(dateString);
    },
    convertToISOString(date) {
      const convertedDate = new Date(date.valueOf());
      convertedDate.setHours(5, 30, 0, 0);
      return convertedDate.toISOString();
    },
    async selectDepartment() {
      this.dataFetched = false;
      this.faculties = [];
      this.selectedFaculty = "";
      this.tempLectureList = []
      this.faculties =
        await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectDepartmentNew,
        });
      let promises = [];
      promises = this.faculties.map((faculty) => {
        return this.userRepositoryInstance.getFullNameOfUser({
          uId: faculty.uId,
        });
      });
      const allSettledPromises = await Promise.allSettled(promises);
      this.facultyNames = [];
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          this.facultyNames.push(allSettledPromises[i].value);
        }
      }
      try {
        this.locations =
          await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(
            {
              instituteId: this.selectedInstitute,
              department: this.selectDepartmentNew,
            }
          );
        console.log('this.locations', this.locations);
      } catch (err) {
        console.log(err);
        this.locations = [];
      }

    },
    async selectFaculty() {
      this.dataFetched = false;
      console.log(
        this.faculties[this.facultyNames.indexOf(this.selectedFaculty)].uId
      );
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.faculties[this.facultyNames.indexOf(this.selectedFaculty)]
          .uId,
      };
      try {
        const res =
          await this.timetableRepositoryInstance.getAllocationsOfAFaculty(
            objToPush
          );
        console.log(res);
        this.allocations = res;
      } catch (err) {
        console.log(err);
        this.allocations = [];
      }
      try {

        objToPush.dateOfLecturesNeeded = this.convertToISOString(
          convertFromYYYYMMDDToDate(this.selectedDate)
        );
        this.occupiedSlots =
          await this.lecturesRepositoryInstance.getLecturesForADay(objToPush);
        console.log(this.occupiedSlots);
      } catch (err) {
        console.log(err);
      }
      try {

        let promises = [];
        promises = this.allocations.map((allocation) => {
          return this.getSubjectDetails(allocation.subjectId);
        });
        const allSettledPromises = await Promise.allSettled(promises);
        for (let i = 0; i < allSettledPromises.length; i++) {
          if (allSettledPromises[i].value) {
            this.subjectsMapping[allSettledPromises[i].value.subjectId] =
              allSettledPromises[i].value;
          }
        }
        this.dataFetched = true;
      } catch (err) {
        console.log(err);
      }
    },
    async selectDate() {
      this.dataFetched = false;
      this.selectedDepartment = "";
      this.selectedFaculty = "";
      this.faculties = [];
      this.facultyNames = [];
      console.log(new Date(this.selectedDate));
    },
    async getSubjectDetails(subjectId) {
      const response =
        await this.subjectRepositoryInstance.getDetailsOfASubject({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: subjectId,
        });
      return {
        subjectId: subjectId,
        subjectName: response.subjectName,
        subjectType: response.subjectType,
      };
    },
    unselectAllocation() {
      this.valueAddedActivityItem = {}
      this.finalStep = false;
      this.selectedAllocation = {
        name: "",
      };
      this.tempTimeSlot = "";
      this.tempLocation = "";
    },
    getDayNameFromDayNumber(dayNumber) {
      switch (dayNumber) {
        case 0:
          return "Sunday";
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
      }
    },
    getDateTimeOfLecture(dateObject, startTimeOfLecture) {
      const timeArray = startTimeOfLecture
        .split("_")
        .map((time) => parseInt(time));

      timeArray[0] += 5;
      timeArray[1] += 30;

      return new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
    },
    convertToISOString(date) {
      try {
        const convertedDate = new Date(date.valueOf());
        // IST is 5.30 hours ahead of UTC
        convertedDate.setHours(5, 30, 0, 0);
        return convertedDate.toISOString();
      } catch (err) {
        console.log(err);
        return undefined;
      }
    },
    async arrangeLecture() {
      try {
        this.isArrangingLectures = true
        const lectures = await this.lecturesRepositoryInstance.arrangeALecture({
          lectures: this.tempLectureList
        });
        if (this.lecturesToMerge.length > 1) {
          const merge = []
          for (let i = 0; i < lectures.length; i++) {
            if (this.lecturesToMerge.includes(i)) {
              merge.push(lectures[i].lectureId)
            }
          }
          if (merge.length !== this.lecturesToMerge.length) {
            showStatus("Could not merge, not all lectures were created", 3000, "error", this);
          } else {
            await apiV3.postRequest('/lecture/merge', {
              lectureIds: merge
            })
          }
        }
        this.tempLectureList = []
        this.lecturesToMerge = []
        this.reset()
        showStatus("Lectures arranged successfully.", 3000, "success", this);
      } catch (e) {
        if (e.response.status === 404) {
          showStatus(
            "A lecture already exists",
            3000,
            "error",
            this
          );
          return
        }
        console.error(e)
        showStatus(
          "Could not arrange lectures. Try again",
          3000,
          "error",
          this
        );
      } finally {
        this.isArrangingLectures = false
      }
    },
    addLecturesToList() {
      try {
        this.isArrangingLectures = true
        const indexOfSlot = this.freeSlotsList.indexOf(this.tempTimeSlot)
        const indexOfLocation = this.locationNames.indexOf(this.tempLocation);
        for (const date of this.dates) {
          let dateTime = this.getDateTimeOfLecture(
            date,
            this.mySlots[indexOfSlot].split('-')[0]
          )
          let utcStartTime = this.getUtcTimeOfLecture(
            date,
            this.mySlots[indexOfSlot].split('-')[0]
          )
          console.log('utcStartTime', new Date(utcStartTime));
          let utcEndTime = this.getUtcTimeOfLecture(
            date,
            this.mySlots[indexOfSlot].split('-')[1]
          )
          const lectureToArrange = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedSubject.department,
            courseYear: this.selectedSubject.courseYear,
            division: this.selectDivision,
            batch: "",
            subjectName: this.selectedSubject.subjectName,
            timeSlot: this.tempTimeSlot,
            subjectId: this.selectedSubject.subjectId,
            facultyName: this.selectedFaculty,
            uId: this.faculties[this.facultyNames.indexOf(this.selectedFaculty)]
              .uId,
            roomId: this.locations[indexOfLocation].roomId,
            startTime: new Date(utcStartTime).toISOString(),
            endTime: new Date(utcEndTime).toISOString(),
            day: this.getDayNameFromDayNumber(new Date(date).getDay()),
            dateTime: new Date(dateTime).toISOString()
          };
          this.tempLectureList.push(lectureToArrange)
          console.log('this.tempLectureList', this.tempLectureList);
        }
        this.unselectAllocation();
        this.selectDivision = ""
        this.selectedSubject = null
        this.subjects = []
        this.dates = []
      } catch (err) {
        console.log(err);
        showStatus(
          "Could not arrange lectures. Try again",
          3000,
          "error",
          this
        );
      } finally {
        this.isArrangingLectures = false
      }
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    getDateTimeOfLecture(date, startTimeOfLecture) {
      const dateObject = new Date(date)
      const timeArray = startTimeOfLecture
        .split("_")
        .map((time) => parseInt(time));
      return new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
    },
    getUtcTimeOfLecture(date, tempTime) {
      const dateObject = new Date(date)
      const timeArray = tempTime
        .split("_")
        .map((time) => parseInt(time));
      let time = new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
      return time
    },
    async onSelectedDepartmentNew() {
      this.courseYearList = [];
      for (const dept of this.allData) {
        if (dept.department === this.selectDepartmentNew) {
          if (!this.courseYearList.includes(dept.courseYear)) {
            this.courseYearList.push(dept.courseYear);
          }
        }
      }
      this.selectDepartment()
      this.locations =
        await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment({
          instituteId: this.selectedInstitute,
          department: this.selectDepartmentNew
        });
      this.locations.forEach((location) => {
        this.locationNames.push(location.roomName);
      });
      try {
        let res = await this.ValueAddedActivityRepositoryInstance.getValueAddedActivityAll({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectDepartmentNew,
        })
        console.log('res2', res);
        this.allValueAddedActivity = []
        this.allValueAddedActivity = res
      } catch (error) {
        this.allValueAddedActivity = []

        console.log(error)
      }

    },
    onSelectCourseYear() {
      this.divisionList = []
      this.tempLectureList = []
      const divisions = this.allData.filter((el) => el.department === this.selectDepartmentNew && el.courseYear === this.selectCourseYear)
      for (const div of divisions) {
        if (!this.divisionList.includes(div.division)) {
          this.divisionList.push(div.division)
        }
      }
    },
    async onSelectDivision() {
      try {
        this.isLoadingSubjects = true
        this.subjects = []
        console.log('this.allData', this.allData);
        this.subjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectDepartmentNew,
          courseYear: this.selectCourseYear,
        });
      } catch (e) {
        if (e.response.status === 404) {
          showStatus('No subjects found.', 4000, 'info', this)
          return
        }
        console.error(e)
        showStatus('An error occured, try later', 3000, 'error', this)
      } finally {
        this.isLoadingSubjects = false
      }
    },
    async getDivisionsOfASemester({ instituteId, semId, uId }) {
      try {
        const divisions =
          await this.semesterRepositoryInstance.getDivisionsOfASemester({
            instituteId,
            semId,
            uId,
          });
        const semDetails = this.semesters.find((sem) => sem.semId === semId)
        const data = [];
        for (const div of divisions) {
          data.push({ ...div, ...semDetails });
        }
        this.allData.push(...data)
      } catch (e) {
        console.error(e)
        showStatus('An error occured, try later', 3000, 'error', this)
      }
    },
    async onSetTimetable(subject) {
      try {
        //unselect
        if (this.selectedSubject && subject.subjectId === this.selectedSubject.subjectId) {
          this.selectedSubject = null
          window.scroll({ top: 0 });
          return
        }
        this.isSubjectInfoLoading = true
        this.selectedSubject = subject
        this.freeSlots = []
        this.freeSlotsList = []
        const { semId, department, courseYear } = subject
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId,
          department,
          courseYear,
          division: this.selectDivision,
          withDuplications: true,
        }
        const timeslots = await this.timetableRepositoryInstance.getTimetableOfADivisionInASemester(objToPush)
        if (Array.isArray(timeslots) && timeslots.length === 0) {
          showStatus('Timetable slots not found.', 3000, 'info', this)
          return
        }
        await this.selectAllocation(subject)
        window.scrollTo(0, document.body.scrollHeight);
        this.freeSlots = timeslots
        for (const tSlot of timeslots) {
          this.freeSlotsList.push(
            timeStringConverter(tSlot.startTime) +
            " - " +
            timeStringConverter(tSlot.endTime)
          );
          this.mySlots.push(tSlot.startTime + '-' + tSlot.endTime)
        }
        /* const resp = await this.lecturesRepositoryInstance.getLecturesOfADepartmentForADay(objToPush) */
        /* console.log(resp) // */

      } catch (e) {
        console.error(e)
        if (e.response.status === 404) {
          showStatus('Timetable slots not found.', 3000, 'info', this)
          return
        }
        showStatus('An error occured, try later', 3000, 'error', this)
      } finally {
        this.isSubjectInfoLoading = false
      }
    },
    onAddNew() {
      this.finalStep = true
    },
    reset() {
      this.selectSemester = ""
      this.selectDepartmentNew = ""
      this.selectCourseYear = ""
      this.selectDivision = ""
      this.selectedSubject = null
      this.subjects = []
    },
    gotoDashboard() {
      this.$router.push('/activityDashboard')
    },
  },
};
</script>

<style src="./activityArrangeLectureByValueAdded.css"></style>
