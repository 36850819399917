import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/DepartmentDetails'

class DepartmentDetails {
  constructor (context) {
    this.context = context
  }

  async createDepartmentDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createDepartmentDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.division
  }
  async getDepartmentDetailsForInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDepartmentDetailsForInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.departmentDetails
  }
  async getDepartmentDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDepartmentDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.departmentDetails
  }
  async updateDepartmentDocument (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateDepartmentDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async updateDesignationHourForDepartmentInBulk (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateDesignationHourForDepartmentInBulk, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async deleteDepartmentDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.deleteDepartmentDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }


}

export default DepartmentDetails
