import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Assignment'

class Assignment {
  constructor (context) {
    this.context = context
  }
  
  async createAssignment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createAssignment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignment
  }

  async getAssignmentById(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignment
  }

  async getAssignmentsByAFaculty(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsByAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }

  async getAssignmentsByAFacultyForASubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsByAFacultyForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }
  
  async getAssignmentsByAFacultyForASubjectForADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsByAFacultyForASubjectForADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }
  async updateAssignment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateAssignment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignment
  }
  async deleteAssignment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteAssignment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async generateAssignmentReport(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateAssignmentReport, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAssignmentsOfADivisionOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsOfADivisionOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }

  async getAssignmentsByAFacultyForASubjectForAGroup (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsByAFacultyForASubjectForAGroup, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }

  async getAssignmentsBySubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsBySubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }
}

export default Assignment
