<template src="./activityArrangeLecture.html"></template>

<script>
import inputContainer from "../../Components/inputContainer";
import SubjectRepository from "../../Repository/Subject";
import SemesterUserRepository from "../../Repository/SemesterUser";
import UserRepository from "../../Repository/User";
import SemesterRepository from "../../Repository/Semester";
import ShiftRepository from "../../Repository/ShiftReference";
import TimetableRepository from "../../Repository/Timetable";
import LecturesRepository from "../../Repository/Lecture";
import InfrastructureReferenceRepository from "../../Repository/InfrastructureReference";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import {
  timeStringConverter,
  convertFromYYYYMMDDToDate,
} from "../../Services/Utils/DateTimeFormatter";
import showStatus from "../../NetworkManager/showStatus";
export default {
  name: "activityArrangeLecture",
  props: ["prop_selectedDate", "prop_occupiedSlots"],
  components: {
    inputContainer,
  },
  data() {
    return {
      roomClashCheck: false,
      facultyClashCheck: false,
      roomClash: [],
      facultyClash: [],
      menu2: false,
      batchesNameArr: [],
      tempBatch: '',
      selectedFacultyUid: '',
      selectedSubForLec: {},
      allSubjectDetails: [],
      allCardsToShow: [],
      assignedDepartment: [],
      facultiesNameWithUid: [],
      allFacultyOfInstitute: [],
      menu1: false,
      time: null,
      endTime: false,
      selectTimeSlotDisable: false,
      startTime: false,
      tempStartTime: '',
      tempEndTime: '',
      addManuallyShow: false,
      semesters: [],
      semestersList: [],
      selectedSemesterName: "",
      occupiedSlots: [],
      selectedDate: "",
      dataFetched: false,
      facultyNames: [],
      selectedDepartment: "",
      departments: [],
      selectedFaculty: "",
      faculties: [],
      userData: {},
      selectedInstitute: "",
      selectedSemester: "",
      allocations: [],
      subjectsMapping: {},
      freeSlots: [],
      selectedAllocation: {
        name: "",
      },
      tempTimeSlot: "",
      tempLocation: "",
      locations: [],
      locationNames: [],
      freeSlotsList: [],
      finalStep: false,
      timetable: [],
      shift: {},
      facultyOccupiedSlots: [],
      divisionOccupiedSlots: [],
    };
  },
  created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.shiftRepositoryInstance = new ShiftRepository(this);
    this.timetableRepositoryInstance = new TimetableRepository(this);
    this.lecturesRepositoryInstance = new LecturesRepository(this);
    this.infrastructureReferenceRepositoryInstance =
      new InfrastructureReferenceRepository(this);
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.subjectsMapping = {};
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.$store.commit("liveData/set_selectedActivityName", "Arrange Lecture By Date");
    this.userData = this.$store.getters["user/get_userData"];
    this.activityLoggerInstance = new ActivityLoggerRepository(this)
    this.fetchData();
  },
  methods: {
    async checkRoomClash() {
      this.roomClash = []
      let utcStartTime;
      let utcEndTime;
      try {
        if (this.tempLocation !== '') {
          const indexOfLocation = this.locationNames.indexOf(this.tempLocation)
          const obj = {
            instituteId: this.selectedInstitute,
            roomId: this.locations[indexOfLocation].roomId
          }
          if (this.tempTimeSlot !== '') {
            // this.tempTimeSlotArray.map(async (timeSlotSelected) => {
              if (this.roomClash.length === 0) {
                const indexOfSlot = this.freeSlotsList.indexOf(this.tempTimeSlot);

                utcStartTime = this.getUtcTimeOfLecture(
                  this.selectedDate,
                  this.freeSlots[indexOfSlot].startTime
                )
                utcEndTime = this.getUtcTimeOfLecture(
                  this.selectedDate,
                  this.freeSlots[indexOfSlot].endTime
                )
                utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
                utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
                obj.startTime = utcStartTime.toISOString()
                obj.endTime = utcEndTime.toISOString()
                this.roomClash = await this.lecturesRepositoryInstance.roomClashDetection(obj)
              }
            // })
          } else if (this.tempEndTime !== '') {
            utcStartTime = this.getUtcTimeOfLecture(
              this.selectedDate,
              this.tempStartTime.split(':').join('_')
            )
            utcEndTime = this.getUtcTimeOfLecture(
              this.selectedDate,
              this.tempEndTime.split(':').join('_')
            )
            utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
            utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
            obj.startTime = utcStartTime.toISOString()
            obj.endTime = utcEndTime.toISOString()
            this.roomClash = await this.lecturesRepositoryInstance.roomClashDetection(obj)
          }
          // roomClashDetection
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    async checkLectureClash() {
      try {
        this.facultyClash = []
        let utcStartTime;
        let utcEndTime;
        const obj = {
          instituteId: this.selectedInstitute,
          uId: this.userData.uId
        }
        if (this.tempTimeSlot !== '') {
            const indexOfSlot = this.freeSlotsList.indexOf(this.tempTimeSlot);

            if (this.facultyClash.length === 0) {
              utcStartTime = this.getUtcTimeOfLecture(
                this.selectedDate,
                this.freeSlots[indexOfSlot].startTime
              );
              utcEndTime = this.getUtcTimeOfLecture(
                this.selectedDate,
                this.freeSlots[indexOfSlot].endTime
              );
              utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
              utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
              obj.startTime = utcStartTime.toISOString();
              obj.endTime = utcEndTime.toISOString();

              this.facultyClash = await this.lecturesRepositoryInstance.facultyLectureClashDetection(obj);
            }
        } else if (this.tempEndTime !== '') {
          utcStartTime = this.getUtcTimeOfLecture(
            this.selectedDate,
            this.tempStartTime.split(':').join('_')
          )
          utcEndTime = this.getUtcTimeOfLecture(
            this.selectedDate,
            this.tempEndTime.split(':').join('_')
          )
          utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
          utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
          obj.startTime = utcStartTime.toISOString()
          obj.endTime = utcEndTime.toISOString()
          this.facultyClash = await this.lecturesRepositoryInstance.facultyLectureClashDetection(obj)
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    handelToggleManually() {
      this.addManuallyShow = !this.addManuallyShow
      this.tempTimeSlot = ''
      this.tempTimeSlotArray = []
      this.lectureToShow = []
      this.selectTimeSlotDisable = !this.selectTimeSlotDisable
      if (!this.selectTimeSlotDisable) {
        this.tempStartTime = ''
        this.tempEndTime = ''
      }
    },
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        );
        this.semesters.reverse();
        this.semestersList = this.semesters.map((semester) => semester.semName);

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0];
          this.selectSemester(this.semestersList[0]);
        }
        // await  this.selectSemester()
      } catch (err) {
        console.log(err);
        this.semesters = [];
        this.semestersList = [];
      }
    },
    async selectSemester() {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(
        this.selectedSemesterName
      );
      // this.setupDataForActivity();
      await this.getAllFacultyOfSem();

    },

    getSemesterObjectOfSelectedSemester(semName) {
      const index = this.semestersList.indexOf(semName);
      return this.semesters[index];
    },
    async setupDataForActivity() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId,
      };
      try {
        this.departments = [];
        const divs =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department);
          }
        });
        let subAdminAssignedDepartmentWiseLecture = []
        if (this.assignedDepartment.length > 0) {
          this.departments.map((data) => {
            if (this.assignedDepartment.includes(data)) {
              subAdminAssignedDepartmentWiseLecture.push(data)
            }
          })
          this.departments = subAdminAssignedDepartmentWiseLecture
        }
      } catch (err) {
        console.log(err);
      }
    },
    async selectAllocation(allocation) {
      this.tempTimeSlot = "";
      this.tempLocation = "";
      this.finalStep = true;

      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: allocation.department,
        courseYear: allocation.subjectDetails.courseYear,
        division: allocation.division,
        dateOfLecturesNeeded: this.convertToISOString(
          convertFromYYYYMMDDToDate(this.selectedDate)
        ),
      };
      this.locations = [];

      try {
        this.locations =
          await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(
            objToPush
          );
      } catch (err) {
        console.log(err);
        this.locations = [];
      }

      this.locations.forEach((location) => {
        this.locationNames.push(location.roomName);
      });

      try {
        const shiftId =
          await this.semesterRepositoryInstance.getShiftOfADivisionOfASemester(
            objToPush
          );
        if (shiftId) {
          this.shift = await this.shiftRepositoryInstance.getShift({
            instituteId: this.selectedInstitute,
            shiftId: shiftId,
          });
        }
      } catch (err) {
        console.log(err);
      }
      if (allocation.division !== undefined) {
        try {
          let batches =
            await this.semesterRepositoryInstance.getNumberOfBatchesOfADivisionInASemester(
              objToPush
            );
          this.batchesNameArr = []
          console.log('batches', batches);
          for (let i = 0; i < batches; i++) {
            this.batchesNameArr.push(`Batch-${i + 1}`)
          }
          console.log('this.batchesNameArr', this.batchesNameArr);
        } catch (err) {
          console.log(err);
          this.batchesNameArr = [];
        }
      }
      this.freeSlots = [];
      this.freeSlotsList = [];
      this.facultyOccupiedSlots = [];
      this.divisionOccupiedSlots = [];
      if (this.shift.timeSlots) {
        this.shift.timeSlots.forEach((timeSlot) => {
          this.freeSlots.push(timeSlot);
        });
      }
      this.freeSlots.forEach((tSlot) => {
        this.freeSlotsList.push(
          timeStringConverter(tSlot.startTime) +
          " - " +
          timeStringConverter(tSlot.endTime)
        );
      });
      this.occupiedSlots.forEach((tSlot) => {
        this.facultyOccupiedSlots.push(
          timeStringConverter(tSlot.startTime) +
          " - " +
          timeStringConverter(tSlot.endTime)
        );
      });
      this.timetable.forEach((tSlot) => {
        this.divisionOccupiedSlots.push(
          timeStringConverter(tSlot.startTime) +
          " - " +
          timeStringConverter(tSlot.endTime)
        );
      });
    },
    async getAllFacultyOfSem() {

      this.dataFetched = false;
      this.faculties = [];
      let facultyId = []
      this.selectedFaculty = "";
      try {
        this.faculties =
          await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        let allSubjectIds = []
        if (this.faculties.length > 0) {
          this.faculties.map((data) => {
            if (data.subjects && data.subjects.length > 0) {
              data.subjects.map((sub) => {
                if (!allSubjectIds.includes(sub.subjectId)) {
                  allSubjectIds.push(sub.subjectId)
                }
              })
            }
            if (data.assignedGroupForSubject && data.assignedGroupForSubject.length > 0) {
              data.assignedGroupForSubject.map((grp) => {

                if (!allSubjectIds.includes(grp.subjectId)) {
                  allSubjectIds.push(grp.subjectId)
                }
              })
            }
            facultyId.push(data.uId)
          })
          this.facultiesNameWithUid = []
          this.facultiesNameWithUid = await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: facultyId,
          });
          this.facultyNames = []
          this.facultiesNameWithUid.map((fac) => {
            this.facultyNames.push(fac.fullName)
          })
        }
        // if (allSubjectIds.length > 0) {
          this.allSubjectDetails = await this.subjectRepositoryInstance.getSubjectsOfAInstituteAndSemId({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId
          })
          // console.log('this.allSubjectDetails', this.allSubjectDetails);
        // }
      } catch (error) {
        console.log('error', error);
        this.facultyNames = []
      }

    },
    async selectFaculty() {
      // this.allSubjectDetails
      // this.facultiesNameWithUid
      this.facultiesNameWithUid.map((data) => {
        if (data.fullName === this.selectedFaculty) {
          this.selectedFacultyUid = data
        }
      })
      this.allCardsToShow = []
      let facultyData = {}
      let mergeAllData = []
      this.faculties.map((data) => {
        if (data.uId === this.selectedFacultyUid.uId) {
          facultyData = data
        }
      })
      mergeAllData = [...facultyData.subjects, ...facultyData.assignedGroupForSubject]
      mergeAllData.map((obj) => {
        this.allSubjectDetails.map((sub) => {
          if (sub.subjectId === obj.subjectId) {
            obj.department = sub.department
            obj.subjectDetails = sub
          }
        })
      })
      this.dataFetched = true;

      this.allocations = mergeAllData
    },

    async selectDate() {
      this.dataFetched = false;
      // this.selectedDepartment = "";
      this.selectedFaculty = "";
      // this.selectedSemesterName = "";
      // this.faculties = [];
      // this.facultyNames = [];
      // console.log(new Date(this.selectedDate));
    },
    async getSubjectDetails(subjectId) {
      const response =
        await this.subjectRepositoryInstance.getDetailsOfASubject({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: subjectId,
        });
      return {
        subjectId: subjectId,
        subjectName: response.subjectName,
        subjectType: response.subjectType,
      };
    },
    unselectAllocation() {
      this.finalStep = false;
      this.selectedAllocation = {
        name: "",
      };
      this.tempTimeSlot = "";
      this.tempLocation = "";
    },
    getDayNameFromDayNumber(dayNumber) {
      switch (dayNumber) {
        case 0:
          return "Sunday";
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
      }
    },
    getDateTimeOfLecture(date, startTimeOfLecture) {
      const dateObject = new Date(date)
      const timeArray = startTimeOfLecture
        .split("_")
        .map((time) => parseInt(time));
      return new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
    },
    convertToISOString(date) {
      try {
        const convertedDate = new Date(date.valueOf());
        // IST is 5.30 hours ahead of UTC
        // convertedDate.setHours(5, 30, 0, 0);
        return convertedDate.toISOString();
      } catch (err) {
        console.log(err);
        return undefined;
      }
    },

    async arrangeLecture() {
      // let a, b;
      // if (this.tempBatch !== '') {
      //   [a, b] = this.tempBatch.split('-')
      // }
      // console.log('a', a, b);
      if (this.tempTimeSlot !== '') {
        const indexOfSlot = this.freeSlotsList.indexOf(this.tempTimeSlot);
        // const indexOfLocation = this.locationNames.indexOf(this.tempLocation);
        let indexOfLocation = null;
        if (this.tempLocation) {

          indexOfLocation = this.locationNames.indexOf(this.tempLocation);
        }
        let dateTime = await this.getDateTimeOfLecture(
          this.selectedDate,
          this.freeSlots[indexOfSlot].startTime
        )
        let utcStartTime = await this.getUtcTimeOfLecture(
          this.selectedDate,
          this.freeSlots[indexOfSlot].startTime
        )
        let utcEndTime = await this.getUtcTimeOfLecture(
          this.selectedDate,
          this.freeSlots[indexOfSlot].endTime
        )
        const lectureToArrange = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubForLec.department,
          courseYear: this.selectedSubForLec.subjectDetails.courseYear,
          division: this.selectedSubForLec.division,
          batch: this.selectedSubForLec.batchName !== '' && this.selectedSubForLec.batchName ? this.selectedSubForLec.batchName : '',
          subjectId: this.selectedSubForLec.subjectDetails.subjectId,
          uId: this.facultiesNameWithUid[this.facultyNames.indexOf(this.selectedFaculty)]
            .uId,
          // roomId: this.locations[indexOfLocation].roomId,
          startTime: utcStartTime,
          endTime: utcEndTime,
          day: this.getDayNameFromDayNumber(new Date(this.selectedDate).getDay()),
          dateTime: new Date(dateTime).toISOString()
        };
        if (indexOfLocation !== null) {
          lectureToArrange.roomId = this.locations[indexOfLocation].roomId
        }
        if (this.selectedSubForLec.groupName && this.selectedSubForLec.groupName !== '') {
          lectureToArrange.division = ''
          lectureToArrange.batch = ''
          lectureToArrange.groupForSubject = [{
            groupName: this.selectedSubForLec.groupName,
            groupId: this.selectedSubForLec.groupId,
          }]
        }
        try {
          const objToPush = {
            lectures: [lectureToArrange],
          };
          const lectures = await this.lecturesRepositoryInstance.arrangeALecture(
            objToPush
          );
          const activityLoggerPayloadArr = [];
          for (let i = 0;i < lectures.length;i++) {
            activityLoggerPayloadArr.push({
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedSubForLec.department,
              courseYear: this.selectedSubForLec.subjectDetails.courseYear,
              division: this.selectedSubForLec.division,
              subjectId: lectures[i].subjectId,
              subjectName: this.selectedSubForLec.subjectDetails.subjectName,
              facultyUid: lectures[i].uId,
              type:"lectureDashboard",
              lectureStartTime: lectures[i].startTime,
              lectureEndTime: lectures[i].endTime,
              lectureId: lectures[i].lectureId,
              savedAt: new Date().toISOString(),
              savedBy: this.userData.firstName,
              actionType: "Create"
            })
          }
          await this.activityLoggerInstance.createMultipleLogs({
            logsArray: activityLoggerPayloadArr
          });
          this.unselectAllocation();
          showStatus("Lectures Arranged successfully.", 1000, "success", this);
        } catch (err) {
          console.log(err);
          showStatus(
            "Could not arrange lectures. Try again",
            1000,
            "error",
            this
          );
        }
      } else {
        let indexOfLocation = null;
        if (this.tempLocation) {

          indexOfLocation = this.locationNames.indexOf(this.tempLocation);
        }
        let dateTime = await this.getDateTimeOfLecture(
          this.selectedDate,
          this.tempStartTime.split(':').join('_')
        )
        let utcStartTime = await this.getUtcTimeOfLecture(
          this.selectedDate,
          this.tempStartTime.split(':').join('_')
        )
        let utcEndTime = await this.getUtcTimeOfLecture(
          this.selectedDate,
          this.tempEndTime.split(':').join('_')
        )
        const lectureToArrange = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubForLec.department,
          courseYear: this.selectedSubForLec.subjectDetails.courseYear,
          division: this.selectedSubForLec.division,
          batch: this.selectedSubForLec.batchName !== '' && this.selectedSubForLec.batchName ? this.selectedSubForLec.batchName : '',
          subjectId: this.selectedSubForLec.subjectDetails.subjectId,
          uId: this.facultiesNameWithUid[this.facultyNames.indexOf(this.selectedFaculty)]
            .uId,
          startTime: utcStartTime,
          endTime: utcEndTime,
          day: this.getDayNameFromDayNumber(new Date(this.selectedDate).getDay()),
          dateTime: new Date(dateTime).toISOString()
        };
        if (indexOfLocation !== null) {
          lectureToArrange.roomId = this.locations[indexOfLocation].roomId
        }
        if (this.selectedSubForLec.groupName && this.selectedSubForLec.groupName !== '') {
          lectureToArrange.division = ''
          lectureToArrange.batch = ''
          lectureToArrange.groupForSubject = [{
            groupName: this.selectedSubForLec.groupName,
            groupId: this.selectedSubForLec.groupId,
          }]
        }

        try {
          const objToPush = {
            lectures: [lectureToArrange],
          };
          const lectures = await this.lecturesRepositoryInstance.arrangeALecture(
            objToPush
          );
          this.unselectAllocation();
          showStatus("Lectures Arranged successfully.", 1000, "success", this);
          const self = this;
          // setTimeout(() => self.$router.go(-1), 1000);
        } catch (err) {
          console.log(err);
          showStatus(
            "Could not arrange lectures. Try again",
            1000,
            "error",
            this
          );
        }

      }
    },
    getUtcTimeOfLecture(date, tempTime) {
      const dateObject = new Date(date)
      const timeArray = tempTime
        .split("_")
        .map((time) => parseInt(time));
      let time = new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
      return time
    },
    gotoDashboard() {
      this.$router.push('/activityDashboard')
    },
    async subjectSelectedForLecture(item) {
      this.tempBatch = ''
      this.selectedSubForLec = item
      this.selectAllocation(item)
      this.finalStep = true
      this.roomClashCheck = false
      this.facultyClashCheck = false
      this.roomClash = []
      this.facultyClash = []
    }
  },
};
</script>

<style src="./activityArrangeLecture.css"></style>
