import { render, staticRenderFns } from "./activityAdmissionNewUsers.html?vue&type=template&id=20a5c6c0&scoped=true&external"
import script from "./activityAdmissionNewUsers.vue?vue&type=script&lang=js"
export * from "./activityAdmissionNewUsers.vue?vue&type=script&lang=js"
import style0 from "./activityAdmissionNewUsers.css?vue&type=style&index=0&id=20a5c6c0&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a5c6c0",
  null
  
)

export default component.exports