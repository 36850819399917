export default {
    createExam: '/newExam/create',
    createExamFromCourseFile: '/newExam/createExamFromCourseFile',
    createOrUpdateExamAndSubjectExam: '/newExam/createOrUpdateExamAndSubjectExam',
    getExams: '/newExam/getExams',
    getExamsOnly: '/newExam/getExamsOnly',
    getExam: '/newExam/getExam',
    getExamsForCourseFile: '/newExam/getExamsForCourseFile',
    getAllTypeExamsBySubjectIds: '/newExam/getAllTypeExamsBySubjectIds',
    deleteExam: '/newExam/deleteExam'
}
