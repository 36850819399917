import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/TopicFeedbackAction'

class TopicFeedbackAction {
  constructor (context) {
    this.context = context
  }

  async createTopicFeedbackAction (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTopicFeedbackAction, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackAction
  }

  async getTopicFeedbackActionsOfATopicFeedback (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbackActionsOfATopicFeedback, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackActions
  }

  async submitActionByAStudentForATopicFeedbackAction (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.putRequest(ApiNames.submitActionByAStudentForATopicFeedbackAction, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async submitActionResponseByAFacultyForATopicFeedbackAction (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.putRequest(ApiNames.submitActionResponseByAFacultyForATopicFeedbackAction, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default TopicFeedbackAction
