export default {
  createAdmissionUser: '/admissionUser/createAdmissionUser',
  getAdmissionUsers: '/admissionUser/getAdmissionUsers',
  getAdmissionUsersById: '/admissionUser/getAdmissionUsersById',
  getAdmissionUser: '/admissionUser/getAdmissionUser',
  updateAdmissionUser: '/admissionUser/updateAdmissionUser',
  updateAdmissionUserPayment: '/admissionUser/updateAdmissionUserPayment',
  deleteAdmissionUser: '/admissionUser/deleteAdmissionUser',
  addUserDetails: '/admissionUser/addUserDetails',
  addPreviousStudentsToAdmission: '/admissionUser/addPreviousStudentsToAdmission',
  saveAdmission :'/admission/createAdmission',
  savePayment:'/admission/createAdmissionPayment',
  feeAnalysis:'/admission/getAdmissionFeeAnalysis',
  submitPaymentInstallment:'/admission/submitPaymentInstallment',
  saveSubject:'/admission/createAdmissionSubject',
  getAdmissionDataBySem:'/admission/getAdmissionDataBySem',
  updateAdmissionStatus:'/admission/updateAdmissionStatus',
  getAdmissionDataForEdit:'/admission/getAdmissionDataBYInstituteAndSem',
  saveEditAdmission:'/admission/updateAdmission',
  saveEditPayment:'/admission/updateAdmissionPayment',
  saveEditSubject:'/admission/updateAdmissionSubject',
  saveFormActivity:'/admission/createAdmissionFormActivity',
  getAdmissionDataForStudent:'/admission/getAdmissionDataForStudent',
  generateExcelAdmission:'/excel/admission/generateExcelAdmission',
}
