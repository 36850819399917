<template src="./addAdditionalFacility.html"></template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import showStatus from '../../NetworkManager/showStatus'
import { spacesUrl } from "../../NetworkManager";
import SemesterUserRepository from '../../Repository/SemesterUser'
import AdditionalFacilityReference from '../../Repository/AdditionalFacilityReference'
import NbaRepository from "../../Repository/NBA"
import { read, utils } from 'xlsx'
import { createExcelThroughJson } from '../../Services/Utils/createExcelFromJson'
export default {
  name: 'addAdditionalFacility',
  components: {
    inputContainer,
    smallButton
  },
  data() {
    return {
      loading: false,
      editingRoomInventory: "",
      isRoomDataEnteringMode: false,
      allRoomData: [],
      showDepartmentMenu: false,
      isFacilityAvailable: false,
      tempSubscriptionOrBought: '',
      tempInterNetProvider: '',
      tempBW: '',
      tempCyberSecurityOrSafety: '',
      tempRoomMeasures: '',
      tempRoomWifiDetails: '',
      excelUploadingMode: '',
      uploadExcelModal: '',
      selectedBuildingId: '',
      selectedExcelFile: [],
      tempRoomFiles: null,
      tempSelectedPos: [],
      tempSelectedPso: [],
      poList: [],
      psoList: [],
      tempRoomDepartment: '',
      tempTotalRoomQuantity: '',
      tempSelectedDepartment: '',
      tempReasonForCreatingThisFacility: '',
      tempUtilization: '',
      tempSelectedDepartmentForEquipments: '',
      tempCategoryName: '',
      currentUploadProgress: 0,
      selectedCategory: {},
      tempRoomName: '',
      tempSelectedRoomType: '',
      tempRoomSharingDepartments: [],
      academicFacility: ["Campus", "Housing", "Value added Courses", "Industrial Visit", "Guest Lectures", "Placement Team", "Digital Board", "Multimedia Facility"],
      attachedFiles: [],
      tempSelectedFaculty: '',
      tempSelectedCategories: [],
      tempSelectedEquipments: [],
      deptFaculties: [],
      tempSelectedFacultyDesignation: '',
      tempRoomAddElibraryResourse: '',
      tempSelectedFacultyEducation: '',
      facultyEducation: ["Ph.D", "M.Sc", "M.Tech", "B.Tech", "Other"],
      tempSelectedTechnicalFacility: [],
      tempSelectedPhysicalFacility: [],
      tempSelectedAdditionalFacility: [],
      SelectedAdditionalFacility: [],
      tempSelectedsafetyMeasures: [],
      facultyDesignation: ["Assistant Professor", "Professor", "Lab Assistant Professor", "Lecturer", "Associate Professor", "Other"],
      tempSoftWareType: ["Legal", "Administration", "Education"],
      tempRoomClassRoom: false,
      safetyMeasures: ["Fire Extinguisher", "Hand gloves", "First Aid", "Safety Shoes", "Other"],
      userData: {},
      tempRoomArea: 0,
      tempBatchSize: 0,
      tempUtilizationHour: 0,
      selectedInstitute: {},
      tempSelectedBuilding: {},
      tempFinalSelectedEquipments: [],
      tempSelectedSubCategories: [],
      items: [],
      roomsData: [],
      tempCategories: [],
      tempRoomElibraryResourses: [],
      eLibraryResourses: ["E-Journals", "Databases", "E-Books", "E-ShodhSindhu", "Shodhganga"],
      regulerStartTimeOfPrejectLab: null,
      regulerEndTimeOfPrejectLab: null,
      extraStartTimeOfPrejectLab: null,
      extraEndTimeOfPrejectLab: null,
      roomTypes: ["Class Room", "Library", "Laboratory", "Hall", "E-Library", "Project Laboratory", "Others"],
      step: 1,
      buildings: [],
      selectedBuilding: {},
      tempSelectedCategory: {},
      selectedBuildingId: '',
      addingRoom: true,
      addingEquipments: false,
      addingSubcategory: false,
      departments: [],
      editing: false,
      inputRules: {

        valueLimit: value => parseInt(value) <= 10 || `value more err`,
      },
      tempRoomData: {}
    }
  },
  created() {
    this.loading = true
    this.$store.commit(
      'liveData/set_selectedActivityName',
      'Infrastructure'
    )
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedBuildingId = this.$store.getters[
      'liveData/get_selectedBuilding'
    ].selectedBuilding
    // getting editing data if any
    this.editingRoomInventory = this.$store.getters[
      'liveData/get_selectedEditingRoomInventory'
    ].editingRoomInventory
    this.userData = this.$store.getters['user/get_userData']
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.additionalFacilityReferenceInstance = new AdditionalFacilityReference()
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.getProgramOutcomes()
    this.getProgramSpecificOutcomes()
    if (this.editingRoomInventory) {
      this.handleEditingRoomInventory()
    }
    this.fetchData()
  },
  updated() {
    if (
      this.tempRoomFiles &&
      !(Array.isArray(this.tempRoomFiles) && this.tempRoomFiles?.length)
    ) {
      const file = this.tempRoomFiles;
      if (!file.type) {
        return
      }
      if (!file.type?.startsWith('image/') && file.type !== 'application/pdf') {
        this.tempRoomFiles = null;
        showStatus('Please upload an Image or PDF', 1000, 'warning', this);
      }
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      this.editing = false
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        this.departments = await this.academicReferenceRepositoryInstance.getDepartments(
          objToPush
        )
        this.buildings = await this.infrastructureReferenceRepositoryInstance.getBuildings(
          objToPush
        )
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async uploadFiles(subCategories, index) {
      if (subCategories.attachedFiles.length === 0) return [];
      const attachedFilePromises = [];
      subCategories.maintenanceImages = []
      const attachedFiles = subCategories.attachedFiles.filter(item => item.name);
      attachedFiles.forEach(attachedFile => {
        attachedFilePromises.push(
          this.getSignedUrl(attachedFile, `equipmentFiles/${this.selectedInstitute}`)
        );
      });
      const signedUrlsData = await Promise.all(attachedFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      const uploadPromises = [];
      if (signedUrls.length === attachedFiles.length) {
        attachedFiles.forEach((attachedFile, i) => {
          uploadPromises.push(this.uploadToSpaces(attachedFile, signedUrls[i], subCategories, index));
        })
        await Promise.all(uploadPromises);
        signedUrlsData.map((item, i) => {
          subCategories.maintenanceImages.push({
            url: spacesUrl + "/" + item.fileName,
            name: attachedFiles[i].name,
            mimeType: item.mimeType
          })
        });
        this.selectedCategory.subCategories[index] = subCategories
      }
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      const signedUrl = await this.infrastructureReferenceRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    async uploadToSpaces(file, signedUrl, subCategories, index) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            subCategories.currentUploadProgress = progress;
            this.selectedCategory.subCategories[index] = subCategories
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );
      return res;
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    async selectBuilding(building) {
      this.loading = true
      this.selectedBuilding = building
      this.selectedBuildingId = building.buildingId
      // setting selected Building to state
      this.$store.commit("liveData/set_selectedBuilding", {
        selectedBuilding: building.buildingId,
      });
      const objToPush = {
        instituteId: this.selectedInstitute,
        buildingId: this.selectedBuildingId,
        buildingName: this.selectedBuilding.buildingName
      }
      try {
        this.allRoomData = await this.infrastructureReferenceRepositoryInstance.getRoomsFromRoomInventory(objToPush)
      } catch (err) {
        console.error(err)
        this.allRoomData = []
      }
      this.loading = false
    },

    async uploadFilesAndGetUrls(files) {
      const urls = [];
      const attachedFiles = files.filter(file => file.name);
      const attachedFilePromises = attachedFiles.map(attachedFile =>
        this.getSignedUrl(attachedFile, `facility/${this.selectedInstitute}`)
      );
      const signedUrlsData = await Promise.all(attachedFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      const uploadPromises = [];
      if (signedUrls.length === attachedFiles.length) {
        await Promise.all(
          attachedFiles.map((attachedFile, i) =>
            this.uploadToSpaces(attachedFile, signedUrls[i])
          )
        );
        signedUrlsData.forEach((item, i) => {
          urls.push({
            url: spacesUrl + "/" + item.fileName,
            name: attachedFiles[i].name,
            mimeType: item.mimeType,
          });
        });
      }
      return urls;
    },

    async getSignedUrl(file) {
      const fileName = file.name;
      const body = {
        fileName,
        fileType: file.type,
      };
      const signedUrl = await this.infrastructureReferenceRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    }
    ,
    async uploadToSpaces(file, signedUrl) {
      const res = await this.futch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      return res;
    },
    async createRoom(status) {
      try {
        if (this.tempSelectedRoomType === '') {
          alert("please fill Type Room")
          return
        } else {
          // TODO ROOM FILE GETTING LOST
          let uploadedFiles = this.tempRoomFiles ?? []
          if (this.tempRoomFiles && this.tempRoomFiles && !this.tempRoomFiles.url) {
            uploadedFiles = await this.uploadFilesAndGetUrls([this.tempRoomFiles])
          }
          const objToPush = {
            buildingId: this.selectedBuildingId,
            instituteId: this.selectedInstitute,
            selectedDepartment: this.tempSelectedDepartment,
            sharingDepartments: this.tempRoomSharingDepartments,
            selectedPO: this.tempSelectedPos,
            selectedPso: this.tempSelectedPso,
            safetyMeasures: this.tempSelectedsafetyMeasures,
            reasonForCreating: this.tempReasonForCreatingThisFacility,
            utilization: this.tempUtilization,
            files: uploadedFiles,
            roomType: this.tempSelectedRoomType,
            subscriptionOrBought: this.tempSubscriptionOrBought,
            BW: this.tempBW,
            cyberSecurityOrSafety: this.tempCyberSecurityOrSafety,
            roomMeasures: this.tempRoomMeasures,
            interNetProvider: this.tempInterNetProvider,
            roomWifiDetails: this.tempRoomWifiDetails,
            status: status === 'completed' ? 'completed' : 'draft',
            rooms: this.tempRoomData?.rooms ?? [],
            roomInventoryId: this.tempRoomData?.roomInventoryId ?? '',
            isAdditionalFacility: true
          }

          // sending data to api 


          const response = await this.infrastructureReferenceRepositoryInstance.createRoom(
            objToPush
          )
          if (response) {
            this.tempRoomData = response
          }
        }
        if (status === 'completed') {
          this.gotoInfraStructure()
        }
      } catch (e) {
        console.error(e);
      }

    },

    startAddingRoom() {
      if (this.tempSelectedBuilding.hasOwnProperty('buildingId')) {
        this.addingRoom = true
      } else {
        alert("Select Building First")
      }
    },
    startAddingEquipments() {
      if (this.tempSelectedBuilding.hasOwnProperty('buildingId')) {
        this.addingEquipments = true
      } else {
        alert("Select Building First")
      }
    },
    goToPreviousSection() {
      this.step--
    },
    goToNextSection() {
      this.step++
      this.createRoom('next')
    },
    async getFacultiesOfDepartment() {
      try {
        const faculties = await this.semesterUserRepositoryInstance.getAllFacultiesOfADepartment({
          instituteId: this.selectedInstitute,
          department: this.tempSelectedDepartment,
        })
        this.deptFaculties = faculties
      } catch (e) {
        console.error('e', e);
        this.deptFaculties = []
      }
    },


    gotoInfraStructure() {
      this.$router.push('/activityReferenceInfrastructure')
    },
    addDataInTableRow() {
    },
    // handlingChangeInNewTable
    handleCreatingRoom() {
      try {
        if (this.tempSelectedRoomType && this.tempTotalRoomQuantity) {

          let dummyRoom = []
          for (let i = 1; i <= parseInt(this.tempTotalRoomQuantity); i++) {
            dummyRoom.push({ roomName: '', department: '', roomArea: '', hardware: [{ hardwareName: "", hardwareQuantity: "" }], software: [{ softwareName: '', softwareLicenseQuantity: "", typeOfSoftware: "" }] }) //TODO need to add all filed names after the creation of rooms completed 
          }
          let dummyRoomsData = {
            roomType: this.tempSelectedRoomType, rooms: dummyRoom
          }
          this.tempRoomData = dummyRoomsData


        }
      } catch (error) {
        console.error(error)
      }

    },
    handleDepartMentChange() {
      this.getProgramOutcomes()
      this.getProgramSpecificOutcomes()
      this.getFacultiesOfDepartment()
    }
    ,
    async getProgramOutcomes() {
      try {
        const objToPush = {
          lmsInstituteId: this.selectedInstitute,
          department: this.tempSelectedDepartment
        };
        const nbaData = await this.nbaRepositoryInstance.getPos(objToPush);
        nbaData.data.map((d) => {
          this.poList.push({
            poNumber: d.poNumber,
            poName: d.poName
          });
        });
      } catch (error) {
        console.error(error);
      }

    },
    async getProgramSpecificOutcomes() {
      try {
        const nbaData = await this.nbaRepositoryInstance.getPsos({
          lmsInstituteId: this.selectedInstitute,
          department: this.tempSelectedDepartment
        });
        nbaData.data.map((d) => {
          this.psoList.push({
            psoNumber: d.psoNumber,
            c: d.psoName
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    downloadSampleRoomExcel(option) {
      let data = []
      if (!option) {
        console.error("invalid option")
        return
      }
      if (option === 1) {
        let roomName = 'Room Name';
        let department = 'Department';
        let roomArea = 'Area';


        [0].map(() => {
          let objToPush = {}
          objToPush[`${roomName}`] = ''
          objToPush[`${department}`] = ''
          objToPush[`${roomArea}`] = ''

          data.push(objToPush)
        })
      } else if (option === 2) {
        let faculty = 'Room in charge';
        let roomName = 'Room Name';
        let facultyDesignation = 'Room in charge Designation';
        let facultyEducation = 'Room in charge Qualification';
        let batchSize = 'Number of students / Batch';
        let utilizationHour = 'Weekly Utilization of the Room';
        this.tempRoomData?.rooms?.map((room) => {
          let objToPush = {}
          objToPush[`${roomName}`] = room.roomName ?? ''
          objToPush[`${faculty}`] = ''
          objToPush[`${facultyDesignation}`] = ''
          objToPush[`${facultyEducation}`] = ''
          objToPush[`${batchSize}`] = ''
          objToPush[`${utilizationHour}`] = ''
          data.push(objToPush)
        })
      } else if (option === 3) {

        let roomName = 'Room Name';
        let hardwareName = 'Hardware Name';
        let hardwareQuantity = 'Hardware Quantity';
        let softwareName = 'Software Name';
        let softwareLicenseQuantity = 'Software License Quantity';
        let tempSoftWareType = 'Type of Software';


        this.tempRoomData?.rooms?.map((room) => {
          let objToPush = {}
          objToPush[`${roomName}`] = room.roomName ?? ''
          objToPush[`${hardwareName}`] = ''
          objToPush[`${hardwareQuantity}`] = ''
          objToPush[`${softwareName}`] = ''
          objToPush[`${softwareLicenseQuantity}`] = ''
          objToPush[`${tempSoftWareType}`] = ''
          data.push(objToPush)
        })
      } else {
        console.error("Invalid option")
      }
      createExcelThroughJson(data)
    },
    uploadDataFromExcel(option) {
      this.excelUploadingMode = option
      this.uploadExcelModal = true
    },
    cancelUploadExcelDialog() {
      this.selectedExcelFile = []
      this.uploadExcelModal = ''
    },
    readExcelFile(file) {
      const reader = new FileReader()

      return new Promise((resolve, reject) => {
        reader.onload = function (e) {
          try {
            const workbook = read(e.target.result, { type: 'binary' })
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            const range = utils.decode_range(worksheet['!ref'])

            const data = []

            for (let row = range.s.r + 1; row <= range.e.r; row++) {
              const rowData = {}

              for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = utils.encode_cell({ r: row, c: col })
                const cellValue = worksheet[cellAddress]?.v || ''
                const headerAddress = utils.encode_cell({ r: range.s.r, c: col })
                const headerValue = worksheet[headerAddress]?.v || ''

                rowData[headerValue] = cellValue
              }

              data.push(rowData)
            }

            resolve(data)
          } catch (error) {
            reject(error)
          }
        }

        reader.onerror = function (e) {
          reject(e.target.error)
        }

        reader.readAsBinaryString(file)
      })
    },
    async uploadExcelForRoomData() {
      try {
        let parsedExcel = await this.readExcelFile(this.selectedExcelFile);
        if (!parsedExcel.length) {
          this.cancelUploadExcelDialog();
          return;
        }
        let ExcelDataArr = [];

        if (this.excelUploadingMode && this.excelUploadingMode === 1) {
          parsedExcel.forEach((data) => {
            ExcelDataArr.push({
              roomType: this.tempSelectedRoomType ?? "",
              roomName: data["Room Name"] ?? "",
              department: data["Department"] ?? "",
              roomArea: data["Area"] ?? "",
            });
          });

          if (!ExcelDataArr.length) {
            this.cancelUploadExcelDialog();
            return;
          }

          let dummyRoom = ExcelDataArr.map((room) => {
            return {
              roomName: room.roomName ?? "",
              department: room.department ?? "",
              roomArea: room.roomArea ?? "",
              hardware: [{ hardwareName: "", hardwareQuantity: "" }],
              software: [{ softwareName: '', softwareLicenseQuantity: "", typeOfSoftware: "" }]
            };
          });

          let dummyRoomsData = {
            roomType: this.tempSelectedRoomType ?? "",
            rooms: dummyRoom,
          };
          this.tempRoomData = dummyRoomsData;
        } else if (this.excelUploadingMode === 2) {
          let faculty = 'Room in charge';
          let roomName = 'Room Name';
          let facultyDesignation = 'Room in charge Designation';
          let facultyEducation = 'Room in charge Qualification';
          let batchSize = 'Number of students / Batch';
          let utilizationHour = 'Weekly Utilization of the Room';

          parsedExcel.forEach((data) => {
            ExcelDataArr.push({
              roomType: this.tempSelectedRoomType ?? "",
              roomName: data[roomName] ?? "",
              faculty: data[faculty] ?? "",
              facultyDesignation: data[facultyDesignation] ?? "",
              facultyEducation: data[facultyEducation] ?? "",
              batchSize: data[batchSize] ?? "",
              utilizationHour: data[utilizationHour] ?? "",
            });
          });

          if (!ExcelDataArr.length) {
            this.cancelUploadExcelDialog();
            return;
          }
          let dummyRoom = this.tempRoomData?.rooms.map((room) => {
            let isRoomData = ExcelDataArr.find((data) => data.roomName === room.roomName);

            if (isRoomData) {
              return { ...room, ...isRoomData };
            } else {
              return room;
            }
          });

          let dummyRoomsData = {
            roomType: this.tempSelectedRoomType ?? "",
            rooms: dummyRoom,
          };
          this.tempRoomData = dummyRoomsData;
        } else if (this.excelUploadingMode === 3) {
          let roomName = 'Room Name';
          let hardwareName = 'Hardware Name';
          let hardwareQuantity = 'Hardware Quantity';
          let softwareName = 'Software Name';
          let softwareLicenseQuantity = 'Software License Quantity';
          let tempSoftWareType = 'Type of Software';
          parsedExcel.forEach((data) => {
            ExcelDataArr.push({
              roomType: this.tempSelectedRoomType ?? "",
              roomName: data[roomName] ?? "",
              hardwareName: data[hardwareName] ?? "",
              hardwareQuantity: data[hardwareQuantity] ?? "",
              softwareName: data[softwareName] ?? "",
              softwareLicenseQuantity: data[softwareLicenseQuantity] ?? "",
              tempSoftWareType: data[tempSoftWareType] ?? "",
            });
          });

          if (!ExcelDataArr.length) {
            this.cancelUploadExcelDialog();
            return;
          }
          let dummyRoom = this.tempRoomData?.rooms.map((room) => {
            let isRoomData = ExcelDataArr?.filter((data) => data.roomName === room.roomName);

            if (isRoomData.length) {
              let hardware = isRoomData.map((data) => {
                return { hardwareName: data.hardwareName ?? '', hardwareQuantity: data.hardwareQuantity ?? '' }
              });
              let software = isRoomData.map((data) => {
                return { softwareName: data.softwareName ?? '', softwareLicenseQuantity: data.softwareLicenseQuantity ?? '', typeOfSoftware: data.tempSoftWareType ?? '' }
              });
              return { ...room, hardware, software };
            } else {
              return room;
            }
          });

          let dummyRoomsData = {
            roomType: this.tempSelectedRoomType ?? "",
            rooms: dummyRoom,
          };
          this.tempRoomData = dummyRoomsData;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.cancelUploadExcelDialog();
      }
    }
    ,
    addNewEquipmentRowInTable(roomIndex) {

      //tampering existing room data
      let currentTableRoomsData = this.tempRoomData?.rooms ?? []
      currentTableRoomsData = currentTableRoomsData.map((room, index) => {

        if (index === roomIndex) {
          return { ...room, hardware: [...room?.hardware ?? [], { hardwareName: "", hardwareQuantity: "" }], software: [...room?.software ?? [], { softwareName: '', softwareLicenseQuantity: "", typeOfSoftware: "" }] }
        }
        return { ...room }
      })
      this.tempRoomData = { ...this.tempRoomData, rooms: currentTableRoomsData }
    },
    async handleEditingRoomInventory() {

      this.tempRoomData = this.editingRoomInventory;
      this.tempTotalRoomQuantity = this.editingRoomInventory?.rooms ? this.editingRoomInventory?.rooms?.length : ''
      this.selectedBuildingId = this.tempRoomData?.buildingId ?? '';
      this.tempSelectedDepartment = this.tempRoomData?.selectedDepartment ?? '';
      this.tempRoomSharingDepartments = this.tempRoomData?.sharingDepartments ?? [];
      this.tempSelectedPos = this.tempRoomData?.selectedPO ?? [];
      this.tempSelectedPso = this.tempRoomData?.selectedPso ?? [];
      this.tempSelectedsafetyMeasures = this.tempRoomData?.safetyMeasures ?? [];
      this.tempReasonForCreatingThisFacility = this.tempRoomData?.reasonForCreating ?? '';
      this.tempUtilization = this.tempRoomData?.utilization ?? '';
      this.tempRoomFiles = this.tempRoomData?.files ?? [];
      this.tempSelectedRoomType = this.tempRoomData?.roomType ?? '';
      this.tempSubscriptionOrBought = this.tempRoomData?.subscriptionOrBought ?? '';
      this.tempBW = this.tempRoomData?.BW ?? '';
      this.tempCyberSecurityOrSafety = this.tempRoomData?.cyberSecurityOrSafety ?? '';
      this.tempRoomMeasures = this.tempRoomData?.roomMeasures ?? '';
      this.tempInterNetProvider = this.tempRoomData?.interNetProvider ?? '';
      this.tempRoomWifiDetails = this.tempRoomData?.roomWifiDetails ?? '';

      await this.handleDepartMentChange()
      // triggering functions for get state in selection  else can see the value 
    }

  }
}
</script>
<style scoped src="./addAdditionalFacility.css"></style>
