export default {
  getAssignmentUsersOfAnAssignmentForADivision:"/assignmentUser/getAssignmentUsersOfAnAssignmentForADivision",
  getAssignmentUsersOfAnAssignmentForAGroup:"/assignmentUser/getAssignmentUsersOfAnAssignmentForAGroup",
  bulkUpdateMarksObtainedForAnAssignment:
    "/assignmentUser/bulkUpdateMarksObtainedForAnAssignment",
  getSubmittedCountOfAnAssignmentForADivision:"/assignmentUser/getSubmittedCountOfAnAssignmentForADivision",
  getSubmittedCountOfAnAssignment:"/assignmentUser/getSubmittedCountOfAnAssignment",
  updateMarksObtainedOfAnUserForAnAssignment:"/assignmentUser/updateMarksObtainedOfAnUserForAnAssignment",
  updateRemarkOfAnUserForAnAssignment:"/assignmentUser/updateRemarkOfAnUserForAnAssignment",
  checkAssignment: "/assignmentUser/checkAssignment",
  createAssignmentUser: '/assignmentUser/createAssignmentUser',
  updateQuestionsMarksOfAnUserForAnAssignment:
    "/assignmentUser/updateQuestionsMarksOfAnUserForAnAssignment",
    updateQuestionsMarksOfAllAssignmentUsers:
    "/assignmentUser/updateQuestionsMarksOfAllAssignmentUsers",
    updateUserAssignment:
    "/assignmentUser/updateUserAssignment",
    bulkUpdateUserAssignment:
    "/assignmentUser/bulkUpdateUserAssignment",
  getSubmittedAssignmentsOfAnUser: '/assignmentUser/getSubmittedAssignmentsOfAnUser',
  assignmentAnalysis: '/assignmentUser/assignmentAnalysis'
};