<template src="./activityTransferUsers.html"></template>

<script>
import inputContainer from "../../Components/inputContainer";
import SemesterRepository from "../../Repository/Semester";
import UserRepository from "../../Repository/User";
import InstituteUserRepository from "../../Repository/InstituteUser";
import SemesterUserRepository from "../../Repository/SemesterUser";
import arrayOps from "../../Services/Utils/arrayOps";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import showStatus from "../../NetworkManager/showStatus";

export default {
  name: "activityTransferUsers",
  components: {
    inputContainer,
  },
  props: [
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_selectedDivision",
    "tree",
  ],
  data() {
    return {
      selectedToCourseYearForNew: [],
      studentsToTransfer: {},
      askForTransfer: false,
      loading: false,
      selectedSemesterName: "",
      transferring: true,
      stepOneCompleted: false,
      modes: ["New Users", "Semester Users"],
      userTypes: ["Faculties", "Students"],
      selectedMode: "New Users",
      selectedUserType: "Faculties",
      selectedFromSemester: {},
      selectedSemester: {},
      selectedInstitute: "",
      selectedFromSemesterName: "",
      fromDepartments: [],
      selectedFromDepartment: "",
      fromCourseYears: [],
      selectedFromCourseYear: "",
      fromDivisions: [],
      selectedFromDivision: "",
      toDepartments: [],
      selectedToDepartment: "",
      toCourseYears: [],
      selectedToCourseYear: "",
      toDivisions: [],
      selectedToDivision: "",
      semestersObjectsList: [],
      semestersNamesList: [],
      selectedSemUser: [],
      semesterNamesToObjectsMapping: {},
      academicDataTree1: {},
      academicDataTree2: {},
      fromUsers: [],
      toUsers: [],
      headers: [
        {
          text: 'ID',
          value: 'collegePRNNo'
        },
        {
          text: 'Name',
          value: 'fullName'
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  created() {
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.$store.getters["liveData/get_editingSemester"];
    this.userData = this.$store.getters["user/get_userData"];
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.activityLoggerInstance = new ActivityLoggerRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.selectedFromSemesterName = this.selectedSemester.semName;
      this.loading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId:  this.selectedSemester.semId,
        };
        this.semestersObjectsList =
          await this.semesterRepositoryInstance.getSemesterNames(objToPush);
        this.semestersObjectsList.reverse();
        this.semestersNamesList = this.semestersObjectsList.map(
          (semester) => semester.semName
        );
        // this.semestersNamesList = arrayOps.removeFromArray(this.semestersNamesList, this.semestersNamesList.indexOf(this.selectedSemester.semName))
        this.selectedToDepartment = "";
        this.selectedToCourseYear = "";
        this.toCourseYears = [];
        this.selectedToDivision = "";
        this.toDivisions = [];
        const obj = await this.getAcademicTreeForSemester(
          this.selectedSemester.semId
        );
        this.academicDataTree2 = obj.academicDataTree;
        this.toDepartments = obj.departments;
        this.getDepartments1();
        this.selectedSemUser = []
      let data = await this.semesterUserRepositoryInstance.getSemesterUsers(objToPush);
        if(data.length > 0) {

          this.selectedSemUser =  data.map((value) =>value.uId) 
        }
        // console.log('this.selectedSemUser', this.selectedSemUser);
      } catch (err) {
        console.error(err);
        // this.semestersObjectsList = [];
        // this.semestersNamesList = [];
        // this.toDepartments = [];
        
      }
      this.loading = false;
    },
    async completeStepOne() {
      this.loading = true;
      this.fromUsers = [];
      this.toUsers = [];
      // this.selectedFromSemesterName = ''
      this.selectedFromDepartment = ''
      this.fromDepartments = []
      this.selectedFromCourseYear = ''
      this.fromCourseYears = []
      this.selectedFromDivision = ''
      this.fromDivisions = []
      this.selectedToDepartment = ''
      this.selectedToCourseYear = ''
      this.toCourseYears = []
      this.selectedToDivision = ''
      this.toDivisions = []
      if (this.selectedMode === 'New Users') {
        const uIds = await this.semesterUserRepositoryInstance.getUsersNotInAnySemester({
          instituteId: this.selectedInstitute
        })
        const response = await this.userRepositoryInstance.getFullNameOfUsers({uIds: uIds});
         
        this.fromUsers = response
     
      }
      this.stepOneCompleted = true;
      this.loading = false;
    },
    getSemesterObjectOfSelectedSemester(semName) {
      const index = this.semestersNamesList.findIndex(
        (item) => item.semName === semName
      );
      return this.semestersObjectsList[index];
    },
    async setupDataForActivity() {},
    async getDepartments1() {
      this.loading = true;
      this.selectedFromDepartment = "";
      this.selectedFromCourseYear = "";
      this.fromCourseYears = [];
      this.selectedFromDivision = "";
      this.fromDivisions = [];
      // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
      // const semObject = this.semestersObjectsList[index]
      const semObject = this.semestersObjectsList.find(
        (element) => element.semName === this.selectedSemesterName
      );
      const obj = await this.getAcademicTreeForSemester(semObject.semId);
      this.academicDataTree1 = obj.academicDataTree;
      this.fromDepartments = obj.departments;
      this.loading = false;
    },
    async getCourseYears1() {
      this.loading = true;
      this.selectedFromCourseYear = "";
      this.selectedFromDivision = "";
      this.fromDivisions = [];
      this.fromCourseYears = Object.keys(
        this.academicDataTree1[this.selectedFromDepartment]
      );
      this.loading = false;
    },
    async getDivisions1() {
      this.loading = true;
      this.selectedFromDivision = "";
      this.fromDivisions =
        this.academicDataTree1[this.selectedFromDepartment][
          this.selectedFromCourseYear
        ];
      this.loading = false;
    },
    async getCourseYears2() {
      this.loading = true;
      this.selectedToCourseYear = "";
      this.selectedToDivision = "";
      this.toDivisions = [];
      this.toCourseYears = Object.keys(
        this.academicDataTree2[this.selectedToDepartment]
      );
      this.loading = false;
    },
    async getDivisions2() {
      this.loading = true;
      this.selectedToDivision = "";

      if (this.selectedToCourseYearForNew.length > 0) {
        this.toDivisions =
          this.academicDataTree2[this.selectedToDepartment][
            this.selectedToCourseYearForNew[0]
          ];
      } else {
        this.toDivisions =
          this.academicDataTree2[this.selectedToDepartment][
            this.selectedToCourseYear
          ];
      }
      this.loading = false;
    },
    async fetchFaculties() {
      this.loading = true;
      // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
      const semObject = this.semestersObjectsList.find(
        (element) => element.semName === this.selectedSemesterName
      );
      // const semObject = this.semestersObjectsList[index]
      const facultyIds = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
        instituteId: this.selectedInstitute,
        semId: semObject.semId,
        department: this.selectedFromDepartment
      })
      const uIds = facultyIds.map((faculty)=>{
        return faculty.uId;
      })
      const response = await this.userRepositoryInstance.getFullNameOfUsers({uIds: uIds});
      this.fromUsers = response
      this.loading = false
    },
    async fetchStudents() {
      this.loading = true;
      // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
      const semObject = this.semestersObjectsList.find(element => element.semName === this.selectedSemesterName)
      const studentIds = await this.semesterUserRepositoryInstance.getStudentsOfADivision({
        instituteId: this.selectedInstitute,
        semId: semObject.semId,
        department: this.selectedFromDepartment,
        courseYear: this.selectedFromCourseYear,
        division: this.selectedFromDivision
      })
      const uIds = studentIds.map((studentId)=>{
        return studentId.uId;
      })
      const response = await this.userRepositoryInstance.getFullNameOfUsers({uIds: uIds});
      this.fromUsers = response
      this.loading = false
    },
    async getDetailsOfUId(uId) {
      const nameObj = await this.getFullNameOfUser({ uId: uId })
      // const instituteProvidedIdObj = await this.getInstituteProvidedIdOfUser({ uId: uId })
      return {
        uId: uId,
        name: nameObj.name,
        // instituteProvidedId: instituteProvidedIdObj.instituteProvidedId
      }
    },
    async getFullNameOfUser(userObject) {
      try {
        if (!userObject.name) {
          userObject.name = await this.userRepositoryInstance.getFullNameOfUser(
            { uId: userObject.uId }
          );
        }
      } catch (err) {
        console.log(err);
        userObject.name = "";
      }
      return userObject;
    },
    async getInstituteProvidedIdOfUser(userObject) {
      try {
        if (!userObject.instituteProvidedId) {
          userObject.instituteProvidedId =
            await this.instituteUserRepositoryInstance.getInstituteProvidedId({
              instituteId: this.selectedInstitute,
              uId: userObject.uId,
            });
        }
      } catch (err) {
        console.log(err);
        userObject.instituteProvidedId = "--";
      }
      return userObject;
    },
    async getAcademicTreeForSemester(semId) {
      this.loading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: semId,
        };
        const semestersList =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        const academicDataTree = {};
        semestersList.forEach((sem) => {
          if (!academicDataTree[sem.department]) {
            academicDataTree[sem.department] = {};
          }
          if (!academicDataTree[sem.department][sem.courseYear]) {
            academicDataTree[sem.department][sem.courseYear] = [];
          }
          academicDataTree[sem.department][sem.courseYear].push(sem.division);
        });
        const departments = Object.keys(academicDataTree);
        return {
          academicDataTree: academicDataTree,
          departments: departments,
        };
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    removeUser(item) {
      this.toUsers = arrayOps.removeFromArray(
        this.toUsers,
        this.toUsers.indexOf(item)
      );
    },
    async transferStudents(transferRollNoAndExamNo) {
      this.askForTransfer = false;
      if (transferRollNoAndExamNo) {
        // const index = this.semestersNamesList.indexOf(this.selectedFromSemesterName)
        // const semObject = this.semestersObjectsList[index]
        const semObject = this.semestersObjectsList.find(
          (element) => element.semName === this.selectedSemesterName
        );
        this.studentsToTransfer.previousSemId = semObject.semId;
        this.studentsToTransfer.maintainRollNumbers = transferRollNoAndExamNo;
      }
      try {
        this.loading = true;
        let multipleUsers = [];

        await this.semesterUserRepositoryInstance.importStudentsOfADivision(
          this.studentsToTransfer
        );
        //action tracker
        let objToPush = {
          instituteId: this.selectedInstitute,
          from: `${this.selectedSemesterName} | ${this.selectedFromDepartment} | ${this.selectedFromCourseYear} | ${this.selectedFromDivision}`,
          to: `${this.selectedSemester.semName} | ${this.selectedToDepartment} | ${this.selectedToCourseYear} | ${this.selectedToDivision}`,
          semId: this.selectedSemester.semId,
          department: this.selectedToDepartment,
          selectMode: this.selectedMode,
          selectedUserType: this.selectedUserType,
          savedBy: this.userData.firstName,
          type: "semesterUser",
          semesterLogType: "save",
          actionType: "create",
        };
        multipleUsers = this.toUsers.map((user) => ({
          ...objToPush,
          userId: user.uId,
          userName: user.name,
        }));
        await this.activityLoggerInstance.createLogsForMultipleUsers({
          multipleUsers,
        });
        //end of action tracker
        this.transferring = false;
        this.loading = false;
        showStatus("Transfer successful", 1000, "success", this);
        // this.completeStepOne()
      } catch (err) {
        console.log(err);
      }
    },
    async transferUsersIntoSemester() {
      this.transferring = true;
      if (this.selectedMode !== "New Users") {
        if (this.selectedUserType === "Students") {
          if (this.selectedSemesterName === this.selectedSemester.semName) {
            let fromData =
              this.selectedFromDepartment +
              this.selectedFromCourseYear +
              this.selectedFromDivision;
            let toData =
              this.selectedToDepartment +
              this.selectedToCourseYear +
              this.selectedToDivision;
            if (fromData !== toData) {
              let objToPush = {
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester.semId,
                uIds: this.toUsers.map((user) => user.uId),
                department: this.selectedToDepartment,
                courseYear: this.selectedToCourseYear,
                division: this.selectedToDivision,
              };
              try {
                let res =
                  await this.semesterUserRepositoryInstance.createMultipleSemesterUsers(
                    objToPush
                  );
                this.transferring = false;
                showStatus("Transfer successful", 1000, "success", this);
                this.loading = true;
                this.completeStepOne();
              } catch (error) {
                console.log("error", error);
              }
            }
          } else {
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uIdsArray: this.toUsers.map((user) => user.uId),
              department: this.selectedToDepartment,
              courseYear: this.selectedToCourseYear,
              division: this.selectedToDivision,
            };
            this.studentsToTransfer = objToPush;
          }
          this.askForTransfer = true;
        } else {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uIdsArray: this.toUsers.map((user) => user.uId),
            department: this.selectedToDepartment,
          };
          try {
            let multipleUsers = [];
            const importUser = await this.semesterUserRepositoryInstance.importFaculties(
              objToPush
            );
            //action tracker
            let objOfMultipleUsers = {
              instituteId: this.selectedInstitute,
              from: `${this.selectedSemesterName} | ${this.selectedFromDepartment}`,
              to: `${this.selectedSemester.semName} | ${this.selectedToDepartment}`,
              semId: this.selectedSemester.semId,
              department: this.selectedToDepartment,
              selectMode: this.selectedMode,
              selectedUserType: this.selectedUserType,
              savedBy: this.userData.firstName,
              type: "semesterUser",
              semesterLogType: "save",
              actionType: "create",
            };

            multipleUsers = this.toUsers.map((user) => ({
              ...objOfMultipleUsers,
              userId: user.uId,
              userName: user.name,
            }));
            await this.activityLoggerInstance.createLogsForMultipleUsers({
              multipleUsers,
            });
            //end of action tracker
            this.transferring = false;
            showStatus("Transfer successful", 1000, "success", this);
            this.loading = true;
            this.completeStepOne();
          } catch (err) {
            showStatus("Error Transfering successful", 1000, "error", this);
            console.log(err);
          }
        }
      } else {
        for (let i = 0; i < this.toUsers.length; i++) {
          try {
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: this.toUsers[i].uId,
            };
            objToPush.department = this.selectedToDepartment;
            if (this.selectedUserType === "Students") {
              if (this.selectedToCourseYearForNew.length > 0) {
                let userName = "";
                this.toUsers.map((user) => {
                  userName = user.name;
                });
                this.selectedToCourseYearForNew.map(async (data) => {
                  objToPush.courseYear = data;
                  objToPush.division = this.selectedToDivision;
                  objToPush.subjects = [];
                  //action tracker
                  objToPush.userName = userName;
                  objToPush.selectMode = this.selectedMode;
                  objToPush.selectedUserType = this.selectedUserType;
                  objToPush.savedBy = this.userData.firstName;
                  objToPush.actionType = "create";
                  objToPush.semesterLogType = "save";
                  objToPush.savedAt = new Date().toISOString();
                  await this.semesterUserRepositoryInstance.createSemesterUser(
                    objToPush
                  );
                  await this.activityLoggerInstance.createLogForSingleUser(
                    objToPush
                  );
                  //end of action tracker
                });
              }
            } else {
              await this.semesterUserRepositoryInstance.createSemesterUser(
                objToPush
              );
              let userName = "";
              this.toUsers.map((user) => {
                userName = user.name;
              });
              //action tracker
              objToPush.selectMode = this.selectedMode;
              objToPush.userName = userName;
              objToPush.selectedUserType = this.selectedUserType;
              objToPush.actionType = "create";
              objToPush.semesterLogType = "save";
              objToPush.savedBy = this.userData.firstName;
              objToPush.savedAt = new Date().toISOString();
              await this.activityLoggerInstance.createLogForSingleUser(
                objToPush
              );
              //end of action tracker
            }
          } catch (err) {
            console.log(err);
          }
        }
        this.transferring = false;
        showStatus("Transfer successful", 1000, "success", this);
        this.loading = true;
        this.completeStepOne();
      }
    },
    pushAllUsers() {
      for (const user of this.fromUsers) {
        if (!this.toUsers.find((elem) => elem.uId === user.uId)) {
          this.toUsers.push(user);
        }
      }
    },
    removeAllUsers() {
      this.toUsers = [];
    },
    gotoactivityEditSemesterUsers() {
      this.$router.push("/activitySemestersManagment");
    },
  },
};
</script>

<style src="./activityTransferUsers.css"></style>
