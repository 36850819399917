import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/mediaLibrary'

class mediaLibrary {
  constructor(context) {
    this.context = context
  }

  async uploadNewMediaToDb(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.uploadNewMediaToDb, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignment
  }

  async getMediaLibrary(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getMediaLibrary, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async updateMediaById(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateMediaById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async deleteMediaById(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.deleteMediaById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getMediaSignedUrl(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getMediaSignedUrl, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getPresignedUrlForR2(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getPresignedUrlForR2, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

}

export default mediaLibrary
