<template src='./activityTopicFeedbackTemplate.html'>

</template>

<script>
import arrayOps from '../../Services/Utils/arrayOps'
import inputContainer from '../../Components/inputContainer'
import showStatus from '../../NetworkManager/showStatus'

import { convertDate } from '../../Services/Utils/DateTimeFormatter'
import TopicFeedbackTemplateRepository from '../../Repository/TopicFeedbackTemplate'

export default {
  name: 'activityTopicFeedbackTemplate',
  components: {
    inputContainer
  },
  data () {
    return {
      userData: {},
      selectedInstitute: '',
      selectedSemester: {},
      loading: false,
      topicFeedbackTemplates: [],
      showAddQuestionDialog: false,
      showAddTopicFeedbackTemplateDialog: false,
      showAddOptionDialog: false,
      tempTemplateName: '',
      tempQuestionName: '',
      tempOption: '',
      tempOptions: [],
      isEditing: false,
      tempTopicFeedbackTemplate: {},
      headersForTopicFeedbackTemplates: [
        {
          text: 'Feedback Template Name',
          value: 'topicFeedbackTemplateName'
        },
        {
          text: 'Created on',
          value: 'dateOfCreation'
        },
        {
          text: 'Number Of Questions',
          value: 'questions'
        },
        {
          text: '',
          value: 'action'
        }
      ],
      headersForTopicFeedbackTemplateQuestions: [
        {
          text: 'Question',
          value: 'question'
        },
        {
          text: 'Options',
          value: 'options'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  created () {
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.topicFeedbackTemplateRepositoryInstance = new TopicFeedbackTemplateRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.topicFeedbackTemplates = []
      try {
        this.topicFeedbackTemplates = await this.topicFeedbackTemplateRepositoryInstance.getTopicFeedbackTemplatesOfAnInstitute({
          instituteId: this.selectedInstitute
        })
      } catch (err) {
        console.log(err)
      }
    },
    openAddTopicFeedbackTemplateDialogForViewing (item) {
      this.isEditing = false
      this.tempQuestion = ''
      this.tempOption = ''
      this.tempTemplateName = item.topicFeedbackTemplateName
      this.tempOptions = []
      this.tempTopicFeedbackTemplate = item
      this.showAddTopicFeedbackTemplateDialog = true
    },
    openAddTopicFeedbackTemplateDialogForCreation () {
      this.isEditing = true
      this.tempQuestion = ''
      this.tempOption = ''
      this.tempTemplateName = ''
      this.tempOptions = []
      this.tempTopicFeedbackTemplate = {
        questions: []
      }
      this.showAddTopicFeedbackTemplateDialog = true
    },
    openAddQuestionDialog () {
      this.tempQuestion = ''
      this.tempOption = ''
      this.tempOptions = []
      this.showAddQuestionDialog = true
      this.showAddTopicFeedbackTemplateDialog = false
    },
    // openAddOptionDialog () {
    //   this.tempOption = ''
    //   this.tempOptions = []
    //   this.showAddOptionDialog = true
    //   this.showAddQuestionDialog = false
    // },
    closeAddTopicFeedbackTemplateDialog () {
      this.tempQuestion = ''
      this.tempOption = ''
      this.tempTemplateName = ''
      this.tempOptions = []
      this.tempTopicFeedbackTemplate = {}
      this.isEditing = true
      this.showAddTopicFeedbackTemplateDialog = false
    },
    closeAddQuestionDialog () {
      this.tempQuestionName = ''
      this.tempOption = ''
      this.tempOptions = []
      this.showAddQuestionDialog = false
      this.showAddTopicFeedbackTemplateDialog = true
    },
    addOption () {
      this.tempOptions.push(this.tempOption)
      console.log(this.tempOptions, this.tempOption)
    },
    // closeAddOptionDialog () {
    //   this.tempOption = ''
    //   this.showAddOptionDialog = false
    //   this.showAddQuestionDialog = true
    // },
    saveQuestion () {
      this.tempTopicFeedbackTemplate.questions.push({
        questionId: this.tempTopicFeedbackTemplate.questions.length + 1,
        type: 0,
        question: this.tempQuestionName,
        options: this.tempOptions
      })
      this.closeAddQuestionDialog()
    },
    // addOption () {
    //   this.tempOptions.push(this.tempOption)
    //   console.log(this.tempOptions)
    // },
    // saveOptions () {
    //   // this.tempOptions.push(this.tempOption)
    //   console.log(this.tempOptions, this.tempOption)
    //   this.closeAddOptionDialog()
    // },
    async saveTemplate () {
      this.tempTopicFeedbackTemplate.topicFeedbackTemplateName = this.tempTemplateName
      const objToPush = {
        instituteId: this.selectedInstitute,
        topicFeedbackTemplateName: this.tempTemplateName,
        questions: this.tempTopicFeedbackTemplate.questions,
        createdBy: this.userData.uId,
        dateOfCreation: new Date().toISOString()
      }
      console.log(objToPush)
      try {
        await this.topicFeedbackTemplateRepositoryInstance.createTopicFeedbackTemplate(objToPush)
        this.fetchData()
      } catch (err) {
        console.log(err)
        showStatus('Could not create template', 1000, 'error', this)
      }
    },
    deleteQuestion (question) {
      const index = this.tempTopicFeedbackTemplate.questions.findIndex((elem) => elem.questioId === question.questionId)
      if (index > -1) {
        this.tempTopicFeedbackTemplate.questions = arrayOps.removeFromArray(this.tempTopicFeedbackTemplate.questions, index)
        for (let i = 0; i < this.tempTopicFeedbackTemplate.questions.length; i++) {
          this.tempTopicFeedbackTemplate.questions.questionId = i
        }
        this.currentTopicFeedbackQuestions.slice()
      }
    },
    deleteOption (option) {
      const index = this.tempOptions.indexOf(option)
      if (index > -1) {
        this.tempOptions = arrayOps.removeFromArray(this.tempOptions, index)
      }
    },
    convertDate (item) {
      return convertDate(item)
    }
  }
}
</script>

<style src='./activityTopicFeedbackTemplate.css'>

</style>
