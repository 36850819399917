<template src='./activityEditSemesterSubjects.html'></template>
<script>
import inputContainer from "../../Components/inputContainer";
import smallButton from "../../Components/smallButton";
import { subjectTypes } from "../../Constants/Utils/Statics";
import { createExcelThroughJson } from "../../utils/excel"
import UserRepository from "../../Repository/User";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SubjectInstituteLevelRepository from "../../Repository/SubjectInstituteLevel";
import uploadToDigitalOceanRepository from "../../Repository/uploadToDigitalOcean";
import SubjectRepository from "../../Repository/Subject";
import AssignmentRepository from "../../Repository/Assignment";
import ValueAddedActivityRepository from "../../Repository/ValueAddedActivity";
import arrayOps from "../../Services/Utils/arrayOps";
import showStatus from "../../NetworkManager/showStatus";
import axios from "axios";
import apiV3 from "../../NetworkManager/apiV3";
import ExamSettingsRepository from "../../Repository/ExamSettings";
import CorporateRepository from "../../Repository/Corporate";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "activityEditSemesterSubjects",
  components: {
    inputContainer,
    smallButton,
  },
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "tree",
  ],
  data() {
    return {
      editor: ClassicEditor,
      prerequisite: "",
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic', '|',
            'bulletedList', 'numberedList',
            'outdent', 'indent', '|',
            'undo', 'redo',
          ],
          shouldNotGroupWhenFull: true
        }
      },
      selectedSubjectType: null,
      addValueAdded: false,
      errorMessageByExcel: "",
      showErrorOfExcel: false,
      cieSee: "",
      searchValueAdded: false,
      searchValueAdded: "",
      valueAddedSelectedForCreation: [],
      allErrorArray: [],
      allValueAddedActivity: [],
      headersValueAdded: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Level",
          value: "levelOfActivity",
        },
        {
          text: "Credits",
          value: "credits",
        },
      ],
      nameOfSubjectCo: [],
      subjectDocumentSelectedForCreation: [],
      allSubjectDocument: [],
      searchSubject: "",
      date: "",
      menu: false,
      date1: "",
      menu1: false,
      tempSubjectId: "",
      tempSubjectObjectiveFile: [],
      tempSubjectSyllabusFile: [],
      tempOutcomesFile: [],
      tempInstructionMaterialFile: [],
      tempPrerequisiteFile: [],
      tempMethodologyFile: [],
      subjectTableLoader: false,
      step1Progrss: false,
      step2Progrss: false,
      finialGroupName: "",
      groupNameDisabled: false,
      groupNameSelectDisabled: false,
      subjectGroupNameNewBySelect: "",
      groupNameArr: [],
      subjectNameNew: "",
      subjectCodeNew: "",
      subjectGroupNameNew: "",
      subjectComponent: [],
      competenceLevel: "",
      subjectDescription: "",
      totalCredits: null,
      totalMarks: null,
      totalCieMarks: null,
      totalSeeMarks: null,
      totalHrsWeek: null,
      subjectObjectiveFile: [],
      subjectObjectiveFileLink: [],
      subjectSyllabusFile: [],
      subjectSyllabusFileLink: [],
      lectureHrs: 0,
      tutorialHrs: 0,
      practicalHrs: 0,
      selfStudyHrs: 0,
      totalContactHrs: 0,
      subjectTypeArr: [],
      subjectTypeRadioBtn: "",
      chooseElectiveByStudent: "",
      arrForBookNameRow: [],
      outcomesFile: [],
      outcomesFileLink: [],
      selectedCoordinaterName: [],
      instructionMaterialFile: [],
      instructionMaterialFileLink: [],
      prerequisiteFile: [],
      prerequisiteFileLink: [],
      methodologyFile: [],
      methodologyFileLink: [],
      departmentFacultyNamesArr: [],
      arrSubjectComponent: [
        "Basic Sciences",
        "Engineering Science",
        "Humanities & social Sciences",
        "Program Core",
        "Program Electives",
        "Open Electives",
        "Project",
        "Internship/Seminars",
      ],
      arrCompetenceLevel: [
        'Beginner',
        "Intermediate",
        "Advanced"
      ],
      dummyInfoArr: ["test1", "test2", "test3"],
      e1: 1,
      ex7: "",
      ex8: "",
      uploadExcelForSubject: false,
      file: [],
      loading: true,
      departments: [],
      courseDescription: "",
      selectedDepartment: "",
      courseYears: [],
      selectedCourseYear: "",
      subjects: [],
      subjectGroups: {},
      addingSubject: false,
      tempSubjectName: "",
      tempGroupName: "",
      tempSubjectCode: "",
      tempTheory: false,
      tempPractical: false,
      tempTutorial: false,
      tempAuditCourse: false,
      tempElective: false,
      tempElectiveType: 1,
      tempSubjectType: "",
      electiveTypes: [1, 2, 3, 4],
      headers: [
        {
          text: "Subject Name",
          value: "subjectName",
        },

        {
          text: "Subject Code",
          value: "subjectCode",
        },
        {
          text: "Subject Group",
          value: "groupName",
        },
        {
          text: "Credits",
          value: "credits",
        },

        {
          text: "Subject Type",
          value: "subjectType",
        },
        {
          text: "Form completed %",
          value: "percentageCompleted",
        },

        {
          text: "",
          value: "actions",
        },
      ],
      subjectTypes: subjectTypes,
      editingGroupId: "",
      editing: false,
      tempVariantName: "",
      tempCredits: 0,
      editingVariant: false,
      deletingVariant: false,
      tempVariant: {},
      tempSubjectCoordinators: [],
      tempFaculties: [],
      faculties: [],
      headers2: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      headersSubject: [
        {
          text: "Subject Name",
          value: "subjectName",
        },
        {
          text: "Subject Code",
          value: "subjectCode",
        },
        {
          text: "Subject Type",
          value: "subjectType",
        },
      ],
      search: "",
      selectedInstitute: "",
      selectedSemester: {},
      facultyNames: [],
      addingFaculties: false,
      addingCoordinator: false,
      tempSubjectForDeleting: {},
      examData: [],
      examHeaders: [
        {
          text: "SrNo",
          value: "no",
        },
        {
          text: "Exam Name",
          value: "examName",
        },
        {
          text: "Total Marks",
          value: "totalMarks",
        },
        {
          text: "Passing Marks",
          value: "passingMarks",
        },
        // {
        //   text: "Passing % Promotion",
        //   value: "action"
        // },
      ],
      selectedExams: [],
      selectedAssessments: [],
      selectedSubjectId: "",
      marksSum: 0,
      seeMarksSum: 0,
      cieMarksSum: 0,
      searchFromAllExams: "",
      searchFromSeeExams: "",
      searchFromCieExams: "",
      examDataLoader: true,
      allExams: [],
      allAssignments: [],
      marksError: false,
      examSettings: {},
      cieExams: [],
      seeExams: [],
      isExamSettingsPresent: false,
      isSeparatePassing: false
    };
  },

  created() {
    this.$store.commit(
      "liveData/set_selectedActivityName",
      "Semester Subjects"
    );
    this.loading = true;
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.cieSee = this.$store.getters["liveData/get_cieSeePreference"];
    // this.cieSee = "no";
    this.selectedSemester = this.PropsSelectedSemester;
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectInstituteLevelInstance = new SubjectInstituteLevelRepository(
      this
    );
    this.uploadToDigitalOceanInstance = new uploadToDigitalOceanRepository(
      this
    );
    this.valueAddedActivityInstance = new ValueAddedActivityRepository(this);
    this.assignmentRepositoryInstance = new AssignmentRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);

    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.CorporateRepositoryInstance = new CorporateRepository(this);
    if (this.tree) {
      for (let i = 0; i < this.tree.length; i++) {
        this.departments.push(this.tree[i].name);
      }
    } else {
      this.departments = [];
    }
    if (
      this.prop_selectedDepartment &&
      this.prop_selectedCourseYear &&
      this.prop_selectedDivision
    ) {
      this.selectedDepartment = this.prop_selectedDepartment;
      this.selectDepartment();
      this.selectedCourseYear = this.prop_selectedCourseYear;
      this.selectCourseYear();
    }
    this.fetchSubjectDocumentData();
    this.getAllValueAddedActivity();
    this.getExamSettings();
    this.loading = false;
  },
  methods: {
    downloadExcel() {
      let dept = this.subjects[0].department;
      let courseYear = this.subjects[0].courseYear;
      const extractedArray = this.subjects.map(subject => {
        const totalCieMarks = subject.totalCieMarks || "-";
        const totalSeeMarks = subject.totalSeeMarks || "-";
        const totalMarks = subject.totalMarks || "-" ; 

        return {
          subjectName: subject.subjectName,
          subjectCode: subject.subjectCode,
          credit: subject.credits,
          totalCieMarks,
          totalSeeMarks,
          totalMarks,
          totalHrsForWeek: subject.totalHrsForWeek,
          lectureHrs: subject.lectureHrs,
          tutorialHrs: subject.tutorialHrs,
          practicalHrs: subject.practicalHrs,
          totalContactHrs: subject.totalContactHrs,
        };
      });
    createExcelThroughJson(extractedArray, `subjects-for-${dept}-${courseYear}`)
    },
    async getExamSettings() {
      try {
        const result =
          await this.examSettingsRepositoryInstance.getInstituteExamSetting({
            instituteId: this.selectedInstitute,
          });
        this.examSettings = result.result[0];
        if (Object.keys(this.examSettings).length !== 0) this.isExamSettingsPresent = true
      } catch (error) {
        console.error(error);
      }
    },
    async handleAllExamsForPromotion(obj) {
      let error = false;
      if (obj.totalMarks && obj.totalMarks !== undefined) {
        if (obj?.considerForPromotion) {
          this.marksSum += Number(obj.totalMarks);
        } else {
          this.marksSum -= Number(obj.totalMarks);
        }
        if (Number(this.marksSum) > Number(this.totalMarks)) {
          error = true;
          showStatus(
            "The limit of the Total marks has been exceeded.",
            9000,
            "error",
            this
          );
        } else {
          error = false;
        }
      }

      if (!error) {
        this.updateExamForPromotion(obj);
      }
    },
    handleSeeExamsForPromotion(obj) {
      let error = false;
      if (obj.totalMarks && obj.totalMarks !== undefined) {
        if (obj.typeOfExam == "see") {
          if (obj.considerForPromotion) {
            this.seeMarksSum += Number(obj.totalMarks);
          } else {
            this.seeMarksSum -= Number(obj.totalMarks);
          }
        }
      }

      if (Number(this.seeMarksSum) > Number(this.totalSeeMarks)) {
        error = true;
        showStatus(
          "The limit of the Total SEE(External) marks has been exceeded.",
          9000,
          "error",
          this
        );
      } else {
        error = false;
      }

      if (!error) {
        this.updateExamForPromotion(obj);
      }
    },
    handleCieExamsForPromotion(obj) {
      let error = false;
      if (obj.totalMarks && obj.totalMarks !== undefined) {
        if (obj.typeOfExam == "cie") {
          if (obj?.considerForPromotion) {
            this.cieMarksSum += Number(obj.totalMarks);
          } else {
            this.cieMarksSum -= Number(obj.totalMarks);
          }
        }
      }

      if (Number(this.cieMarksSum) > Number(this.totalCieMarks)) {
        error = true;
        showStatus(
          "The limit of the Total CIE(Internal) marks has been exceeded.",
          9000,
          "error",
          this
        );
      } else {
        error = false;
      }

      if (!error) {
        this.updateExamForPromotion(obj);
      }
    },
    async updateExamForPromotion(obj) {
      try {
        await apiV3.postRequest("/manageExam/updateForPromotion", obj);
      } catch (error) {
        console.error(error);
      }
    },
    async getAllValueAddedActivity() {
      try {
        const res =
          await this.valueAddedActivityInstance.getValueAddedActivityAll({
            instituteId: this.selectedInstitute,
          });
        this.allValueAddedActivity = res.activity;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSubjectDocumentData() {
      this.allSubjectDocument = [];

      try {
        const res =
          await this.subjectInstituteLevelInstance.getSubjectDocumentOfAInstitute(
            {
              instituteId: this.selectedInstitute,
            }
          );
        this.allSubjectDocument = res;
      } catch (error) {
        console.error("error", error);
        this.allSubjectDocument = [];
      }
    },
    async getAllExamsAndAssignments() {
      this.examData = [];
      this.cieExams = [];
      this.seeExams = [];
      this.examDataLoader = true;
      this.marksSum = 0;
      this.seeMarksSum = 0;
      this.cieMarksSum = 0;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.selectedSubjectId,
        };
        let response = await apiV3.getRequest(
          "/manageExam/getExamsBySubject",
          objToPush
        );
        this.allExams = response.data;
        for (let index = 0; index < this.allExams.length; index++) {
          const exam = response.data[index];
          let objToPush = {};
          if(exam.examStatus === 'Published') {
            objToPush["examName"] = exam.name;
            objToPush["examId"] = exam.examId;
            objToPush["semId"] = exam.semId;
            objToPush["attemptNumber"] = exam.attemptNumber;
            objToPush["instituteId"] = exam.instituteId;
            if (exam.subjectDetails.length > 0) {
              const data = exam.subjectDetails.filter(
                (item) => item.subjectId === this.selectedSubjectId
              )[0];
              if (data) {
                objToPush["considerForPromotion"] = data.considerForPromotion
                  ? data.considerForPromotion
                  : false;
                objToPush["subjectId"] = data.subjectId;
                objToPush["totalMarks"] = data.totalMarks;
                objToPush["passingMarks"] = data.passingMarks;
                if (data.totalMarks === 0 || !data.totalMarks) {
                  objToPush["disabled"] = true;
                }
              }
              if (this.isExamSettingsPresent && this.examSettings.selectedCieSeePreference === "yes") {
                if (exam.isForNBA) {
                  if (exam.nbaAttainment.typeOfExam === "cie") {
                    this.cieExams.push({ ...objToPush, typeOfExam: "cie" });
                    if (data?.considerForPromotion) {
                      this.cieMarksSum += Number(data.totalMarks);
                    }
                  } else if (exam.nbaAttainment.typeOfExam === "see") {
                    this.seeExams.push({ ...objToPush, typeOfExam: "see" });
                    if (data?.considerForPromotion) {
                      this.seeMarksSum += Number(data.totalMarks);
                    }
                  }
                } else {
                  if (exam.type === "CIE(Internal)") {
                    this.cieExams.push({ ...objToPush, typeOfExam: "cie" });
                    if (data?.considerForPromotion) {
                      this.cieMarksSum += Number(data.totalMarks);
                    }
                  } else if (exam.type === "SEE(External)") {
                    this.seeExams.push({ ...objToPush, typeOfExam: "see" });
                    if (data?.considerForPromotion) {
                      this.seeMarksSum += Number(data.totalMarks);
                    }
                  }
                }
              } else {
                this.examData.push(objToPush);
                if (data && data?.considerForPromotion) {
                  this.marksSum += Number(data.totalMarks);
                }
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      }

      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          subjectId: this.selectedSubjectId,
        };
        const assignments =
          await this.assignmentRepositoryInstance.getAssignmentsBySubject(
            objToPush
          );
        this.allAssignments = assignments;
        for (let index = 0; index < this.allAssignments.length; index++) {
          const element = assignments[index];

          let objToPush = {};
          objToPush["examName"] = element.title;
          objToPush["assignmentId"] = element.assignmentId;
          objToPush["totalMarks"] = element.totalObtainableMarks;
          objToPush["considerForPromotion"] = element.considerForPromotion
            ? element.considerForPromotion
            : false;

          if (this.isExamSettingsPresent && this.examSettings.selectedCieSeePreference === "yes") {
            this.cieExams.push({ ...objToPush, typeOfExam: "cie" });
            if (element?.considerForPromotion) {
              this.cieMarksSum += Number(element.totalObtainableMarks);
            }
          } else {
            this.examData.push(objToPush);
            if (element?.considerForPromotion) {
              this.marksSum += Number(element.totalObtainableMarks);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }

      this.examData.sort(
        (a, b) =>
          Number(b?.considerForPromotion) - Number(a?.considerForPromotion)
      );
      if (this.cieExams.length > 0) {
        this.cieExams.sort(
          (a, b) =>
            Number(b?.considerForPromotion) - Number(a?.considerForPromotion)
        );
      }
      if (this.seeExams.length > 0) {
        this.seeExams.sort(
          (a, b) =>
            Number(b?.considerForPromotion) - Number(a?.considerForPromotion)
        );
      }
      this.examDataLoader = false;
    },
    clearDates() {
      this.date = "";
      this.date1 = "";
    },
    removeLink(index, array) {
      array.splice(index, 1);
    },
    cancelEditingDialog() {
      (this.subjectGroupNameNewBySelect = ""),
        // this.groupNameArr = [],
        (this.subjectNameNew = ""),
        (this.subjectCodeNew = ""),
        (this.subjectGroupNameNew = ""),
        (this.subjectComponent = []),
        (this.subjectDescription = ""),
        (this.totalCredits = null),
        (this.totalMarks = null),
        (this.totalHrsWeek = null),
        (this.subjectObjectiveFile = []),
        (this.subjectObjectiveFileLink = []),
        (this.subjectSyllabusFile = []),
        (this.subjectSyllabusFileLink = []),
        (this.lectureHrs = 0),
        (this.tutorialHrs = 0),
        (this.practicalHrs = 0),
        (this.selfStudyHrs = 0),
        (this.totalContactHrs = 0),
        (this.subjectTypeArr = []),
        (this.subjectTypeRadioBtn = ""),
        (this.chooseElectiveByStudent = ""),
        (this.outcomesFile = []),
        (this.outcomesFileLink = []),
        (this.selectedCoordinaterName = []),
        (this.instructionMaterialFile = []),
        (this.instructionMaterialFileLink = []),
        (this.prerequisiteFile = []),
        (this.prerequisite = ""),
        (this.competenceLevel = ""),
        (this.prerequisiteFileLink = []),
        (this.methodologyFile = []),
        (this.methodologyFileLink = []),
        (this.date = ""),
        (this.date1 = "");
      if (this.isExamSettingsPresent && this.examSettings.selectedCieSeePreference === "yes") {
        this.totalSeeMarks = null;
        this.totalCieMarks = null;
      }

      this.editingVariant = false;
    },
    async uploadFile(fileArr) {
      try {
        let urlLinkArr = [];
        if (fileArr.length > 0) {
          const fileName = fileArr.filter((item) => item.name);

          let data = await this.uploadToDigitalOceanInstance.upload(
            fileName,
            this.userData.uId
          );

          return data;
        }
      } catch (error) {
        console.error("error", error);
        let arr = [];
        return arr;
      }
    },

    clearGroupNameField() {
      this.subjectGroupNameNewBySelect = "";
    },
    clearGroupNameSelect() {
      this.subjectGroupNameNew = "";
    },
    giveSubjectTypeNumber(type, subtype) {
      if (type === "Theory" && subtype === "compulsory") {
        return 0;
      }
      if (type === "Practical" && subtype === "compulsory") {
        return 1;
      }
      if (type === "Tutorial" && subtype === "compulsory") {
        return 2;
      }
      if (type === "AuditCourse" && subtype === "compulsory") {
        return 3;
      }
      if (type === "Theory" && subtype === "elective") {
        return 4;
      }
      if (type === "Practical" && subtype === "elective") {
        return 5;
      }
      if (type === "Tutorial" && subtype === "elective") {
        return 6;
      }
      if (type === "AuditCourse" && subtype === "elective") {
        return 7;
      }
    },
    async editSubjectByNewFormat() {
      this.step2Progrss = true;
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        subjectId: this.tempSubjectId,
      };

      if (this.subjectGroupNameNew === "") {
        this.finialGroupName = this.subjectGroupNameNewBySelect;
      }
      if (this.subjectGroupNameNewBySelect === "Other/ Add New") {
        this.finialGroupName = this.subjectGroupNameNew;
      }
      let startDate;
      let endDate;
      if (this.date && this.date1) {
        if (new Date(this.date) > new Date(this.date1)) {
          startDate = this.date1;
          endDate = this.date;
        } else {
          startDate = this.date;
          endDate = this.date1;
        }
        objToPush.electiveStartDate = new Date(startDate);
        objToPush.electiveEndDate = new Date(endDate);
      }

      if (this.outcomesFile.length > 0) {
        this.outcomesFileLink = await this.uploadFile(this.outcomesFile);
      }
      if (this.instructionMaterialFile.length > 0) {
        this.instructionMaterialFileLink = await this.uploadFile(
          this.instructionMaterialFile
        );
      }
      if (this.prerequisiteFile.length > 0) {
        this.prerequisiteFileLink = await this.uploadFile(
          this.prerequisiteFile
        );
      }
      if (this.methodologyFile.length > 0) {
        this.methodologyFileLink = await this.uploadFile(this.methodologyFile);
      }

      // objToPush = {}

      let tempsubjectObjectiveFileLink = [
        ...this.subjectObjectiveFileLink,
        ...this.tempSubjectObjectiveFile,
      ];
      let tempoutcomesFile = [
        ...this.outcomesFileLink,
        ...this.tempOutcomesFile,
      ];
      let tempinstructionMaterialFile = [
        ...this.instructionMaterialFileLink,
        ...this.tempInstructionMaterialFile,
      ];
      let tempprerequisiteFile = [
        ...this.prerequisiteFileLink,
        ...this.tempPrerequisiteFile,
      ];
      let tempmethodologyFile = [
        ...this.methodologyFileLink,
        ...this.tempMethodologyFile,
      ];

      if (this.chooseElectiveByStudent) {
        if (this.chooseElectiveByStudent === "true") {
          objToPush.allowStudentToSelect = true;
        } else {
          objToPush.allowStudentToSelect = false;
        }
      }
      let electiveNumber = this.giveSubjectTypeNumber(
        this.selectedSubjectType,
        this.subjectTypeRadioBtn
      );
      objToPush.subjectComponent = this.subjectComponent;
      objToPush.competenceLevel = this.competenceLevel;
      objToPush.subjectDescription = this.subjectDescription;
      objToPush.subjectGroupName = this.finialGroupName;
      objToPush.subjectName = this.subjectNameNew;
      objToPush.subjectCode = this.subjectCodeNew;
      objToPush.isSeparatePassing = this.isSeparatePassing;

      objToPush.credits = Number(this.totalCredits);
      objToPush.totalMarks = Number(this.totalMarks);
      objToPush.totalHrsForWeek = Number(this.totalHrsWeek);
      objToPush.subjectObjectiveFile = tempsubjectObjectiveFileLink;
      objToPush.subjectSyllabusFile = this.subjectSyllabusFileLink;
      objToPush.lectureHrs = Number(this.lectureHrs);
      objToPush.tutorialHrs = Number(this.tutorialHrs);
      objToPush.practicalHrs = Number(this.practicalHrs);
      objToPush.selfStudyHrs = Number(this.selfStudyHrs);
      objToPush.totalContactHrs = this.totalContactHrs;
      objToPush.subjectType = electiveNumber;

      objToPush.refBookDetails = this.arrForBookNameRow;
      objToPush.subjectCoordinators = this.selectedCoordinaterName;
      objToPush.outcomesFile = tempoutcomesFile;
      objToPush.instructionMaterialFile = tempinstructionMaterialFile;
      //objToPush.prerequisiteFile = tempprerequisiteFile;
      objToPush.prerequisite = this.prerequisite;
      objToPush.methodologyFile = tempmethodologyFile;

      if (this.isExamSettingsPresent && this.examSettings.selectedCieSeePreference === "yes") {
        objToPush.totalSeeMarks = this.totalSeeMarks;
        objToPush.totalCieMarks = this.totalCieMarks;
      }
      try {
        await this.subjectRepositoryInstance.updateSubjectName(objToPush);

        // this.editingVariant = false
        this.e1 = 3;
      } catch (error) {
        console.error(error);
        showStatus("Subject not updated Please try again", 3000, "error", this);
      }
      this.step2Progrss = false;
    },
    saveDetailsPage3() {
      showStatus("Subject updated successfully", 3000, "success", this);
      this.editingVariant = false;
      this.selectCourseYear();
    },
    async createSubjectByNewFormat() {
      this.step2Progrss = true;
      let startDate;
      let endDate;
      if (new Date(this.date) > new Date(this.date1)) {
        startDate = this.date1;
        endDate = this.date;
      } else {
        startDate = this.date;
        endDate = this.date1;
      }

      this.subjectTypeArr.map(async (type) => {
        const objToPush = {};
        let electiveNumber = this.giveSubjectTypeNumber(
          type,
          this.subjectTypeRadioBtn
        );
        if (this.chooseElectiveByStudent) {
          if (this.chooseElectiveByStudent === "true") {
            objToPush.allowStudentToSelect = true;
          } else {
            objToPush.allowStudentToSelect = false;
          }
        }

        objToPush.instituteId = this.selectedInstitute;
        objToPush.semId = this.selectedSemester.semId;
        objToPush.department = this.selectedDepartment;
        objToPush.courseYear = this.selectedCourseYear;
        objToPush.groupId = new Date().valueOf();

        objToPush.subjectName = this.subjectNameNew;
        objToPush.subjectCode = this.subjectCodeNew;
        objToPush.isSeparatePassing = this.isSeparatePassing;

        objToPush.groupName = this.finialGroupName;
        objToPush.subjectComponent = this.subjectComponent;
        objToPush.competenceLevel = this.competenceLevel;
        objToPush.subjectDescription = this.subjectDescription;

        objToPush.credits = Number(this.totalCredits);
        objToPush.totalMarks = Number(this.totalMarks);
        objToPush.totalHrsForWeek = Number(this.totalHrsWeek);
        objToPush.subjectObjectiveFile = this.subjectObjectiveFileLink;
        objToPush.subjectSyllabusFile = this.subjectSyllabusFileLink;
        objToPush.lectureHrs = Number(this.lectureHrs);
        objToPush.tutorialHrs = Number(this.tutorialHrs);
        objToPush.practicalHrs = Number(this.practicalHrs);
        objToPush.selfStudyHrs = Number(this.selfStudyHrs);
        objToPush.totalContactHrs = this.totalContactHrs;
        objToPush.subjectType = electiveNumber;
        objToPush.electiveStartDate = new Date(startDate);
        objToPush.electiveEndDate = new Date(endDate);

        if (this.isExamSettingsPresent && this.examSettings.selectedCieSeePreference === "yes") {
          objToPush.totalSeeMarks = this.totalSeeMarks;
          objToPush.totalCieMarks = this.totalCieMarks;
        }

        if (this.arrForBookNameRow.length !== 0) {
          objToPush.refBookDetails = this.arrForBookNameRow;
        }
        if (this.selectedCoordinaterName.length !== 0) {
          objToPush.subjectCoordinators = this.selectedCoordinaterName;
        }

        if (this.outcomesFile.length > 0) {
          this.outcomesFileLink = await this.uploadFile(this.outcomesFile);
          if (this.outcomesFileLink.length !== 0) {
            objToPush.outcomesFile = this.outcomesFileLink;
          } else {
            showStatus(
              "OutcomesFile was not uploaded Please try again.",
              3000,
              "error",
              this
            );
          }
        }

        if (this.instructionMaterialFile.length > 0) {
          this.instructionMaterialFileLink = await this.uploadFile(
            this.instructionMaterialFile
          );
          if (this.instructionMaterialFileLink.length !== 0) {
            objToPush.instructionMaterialFile =
              this.instructionMaterialFileLink;
          } else {
            showStatus(
              "InstructionMaterialFile was not uploaded Please try again.",
              2000,
              "error",
              this
            );
          }
        }

        if (this.prerequisiteFile.length > 0) {
          this.prerequisiteFileLink = await this.uploadFile(
            this.prerequisiteFile
          );
          if (this.prerequisiteFileLink.length !== 0) {
            objToPush.prerequisiteFile = this.prerequisiteFileLink;
          } else {
            showStatus(
              "PrerequisiteFile was not uploaded Please try again.",
              2000,
              "error",
              this
            );
          }
        }
        if (this.methodologyFile.length > 0) {
          this.methodologyFileLink = await this.uploadFile(
            this.methodologyFile
          );
          if (this.methodologyFileLink.length !== 0) {
            objToPush.methodologyFile = this.methodologyFileLink;
          } else {
            showStatus(
              "MethodologyFile was not uploaded Please try again.",
              2000,
              "error",
              this
            );
          }
        }
        try {
          const response = await this.subjectRepositoryInstance.createSubject(
            objToPush
          );
          showStatus("Subject Created Successfully.", 1000, "success", this);

          this.selectCourseYear();
        } catch (err) {
          showStatus("Could not connect to server.", 2000, "error", this);
          console.error(err);
          // this.addingSubject = true
          // this.step2Progrss = false
          showStatus(
            "Subject not added. Please try again",
            3000,
            "error",
            this
          );
        }
      });
      this.addingSubject = false;

      this.step2Progrss = false;
    },

    async createSubjectByDocument() {
      this.step2Progrss = true;

      this.subjectDocumentSelectedForCreation.map(async (document) => {
        const objToPush = {};

        objToPush.instituteId = this.selectedInstitute;
        objToPush.semId = this.selectedSemester.semId;
        objToPush.department = this.selectedDepartment;
        objToPush.courseYear = this.selectedCourseYear;
        objToPush.groupId = new Date().valueOf();

        objToPush.subjectName = document.subjectName;
        objToPush.subjectCode = document.subjectCode;
        objToPush.subjectType = document.subjectType;
        objToPush.instituteSubjectId = document.instituteSubjectId;
        objToPush.isForNaac = document.isForNaac;

        if (document.framework) {
          objToPush.framework = document.framework;
        }
        if (document.subjectGroupName) {
          objToPush.groupName = document.subjectGroupName;
        }

        if (document.subjectComponent) {
          objToPush.subjectComponent = document.subjectComponent;
        }

        if(document.competenceLevel){
          objToPush.competenceLevel = document.competenceLevel
        }

        if (document.credits) {
          objToPush.credits = document.credits;
        }

        if (document.totalMarks) {
          objToPush.totalMarks = document.totalMarks;
        }

        if (document.totalCieMarks) {
          objToPush.totalCieMarks = document.totalCieMarks;
        }

        if (document.totalHrsForWeek) {
          objToPush.totalHrsForWeek = document.totalHrsForWeek;
        }

        if (document.subjectObjectiveFile) {
          objToPush.subjectObjectiveFile = document.subjectObjectiveFile;
        }

        if (document.subjectSyllabusFile) {
          objToPush.subjectSyllabusFile = document.subjectSyllabusFile;
        }

        if (document.lectureHrs) {
          objToPush.lectureHrs = document.lectureHrs;
        }

        if (document.tutorialHrs) {
          objToPush.tutorialHrs = document.tutorialHrs;
        }

        if (document.practicalHrs) {
          objToPush.practicalHrs = document.practicalHrs;
        }

        if (document.selfStudyHrs) {
          objToPush.selfStudyHrs = document.selfStudyHrs;
        }

        if (document.totalContactHrs) {
          objToPush.totalContactHrs = document.totalContactHrs;
        }

        if (document.refBookDetails) {
          objToPush.refBookDetails = document.refBookDetails;
        }

        if (document.outcomesFile) {
          objToPush.outcomesFile = document.outcomesFile;
        }

        if (document.instructionMaterialFile) {
          objToPush.instructionMaterialFile = document.instructionMaterialFile;
        }

        if (document.prerequisiteFile) {
          objToPush.prerequisiteFile = document.prerequisiteFile;
        }

        if(document.prerequisite){
          objToPush.prerequisite = document.prerequisite
        }

        if (document.methodologyFile) {
          objToPush.methodologyFile = document.methodologyFile;
        }

        try {
          const response = await this.subjectRepositoryInstance.createSubject(
            objToPush
          );

          showStatus("Subject added successfully", 3000, "success", this);

          this.selectCourseYear();
        } catch (err) {
          showStatus("Could not connect to server.", 2000, "error", this);
          console.error(err);
        }
      });
      this.addingSubject = false;

      this.step2Progrss = false;
    },
    uploadSyllabusExcel(subjectSyllabusFile) {
      if (subjectSyllabusFile) {
        var formData = new FormData();

        formData.append("excel", subjectSyllabusFile);
        let url =
          process.env.VUE_APP_SERVER_URL +
          process.env.VUE_APP_API_PORT +
          "/excel/subject/subjectSyllabusFile";
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/subject/subjectSyllabusFile",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(
            (response) => {
              if (response.status === 200) {
                this.subjectSyllabusFileLink = [];
                this.subjectSyllabusFileLink =
                  response.data.subjectSyllabusData;
              } else {
                // console.error("not parse")
              }
            },
            (error) => {
              console.error(error);
            }
          )
          .then(() => {
            this.uploadExcelForSubject = false;
          });
      }
    },
    async gotoSecondStepEdit() {
      // if (this.examSettings.selectedCieSeePreference === "yes") {
      //   if (this.totalCieMarks === null || this.totalSeeMarks === null)
      //     alert("Please fill compulsory fields");
      // } else {
      //   if (this.totalMarks === null) alert("Please fill compulsory fields");
      // }
      if (
        this.subjectNameNew === "" ||
        this.subjectCodeNew === "" ||
        this.subjectTypeArr.length === 0 ||
        this.subjectTypeRadioBtn === "" ||
        this.totalCredits === (null || "")
      ) {
        alert("Please fill compulsory fields");
      } else {
        if (
          this.subjectTypeRadioBtn === "elective" &&
          this.chooseElectiveByStudent === ""
        ) {
          alert("Please fill compulsory fields");
        } else if (
          this.chooseElectiveByStudent === "true" &&
          (this.date === "" || this.date1 === "")
        ) {
          alert("Please fill compulsory fields");
        } else {
          this.step1Progrss = true;
          if (this.subjectObjectiveFile.length > 0) {
            this.subjectObjectiveFileLink = await this.uploadFile(
              this.subjectObjectiveFile
            );
          }
          if (this.subjectSyllabusFile.length > 0) {
            await this.uploadSyllabusExcel(this.subjectSyllabusFile);
          }
          this.step1Progrss = false;
          this.e1 = 2;
        }
      }
    },
    async gotoSecondStepNewFormat() {
      if (this.isExamSettingsPresent && this.examSettings.selectedCieSeePreference === "yes") {
        if (this.totalCieMarks === null || this.totalSeeMarks === null)
          alert("Please fill compulsory fields");
      } else {
        if (this.totalMarks === null) alert("Please fill compulsory fields");
      }

      if (this.subjectGroupNameNew === "") {
        this.finialGroupName = this.subjectGroupNameNewBySelect;
      }
      if (this.subjectGroupNameNewBySelect === "Other/New Name") {
        this.finialGroupName = this.subjectGroupNameNew;
      }
      if (
        this.subjectNameNew === "" ||
        this.subjectCodeNew === "" ||
        this.finialGroupName === "" ||
        this.subjectComponent.length === 0 ||
        this.subjectDescription === "" ||
        this.totalCredits === "" ||
        this.totalCredits === null ||
        this.totalHrsWeek === "" ||
        this.totalHrsWeek === null ||
        this.subjectObjectiveFile.length === 0 ||
        this.subjectSyllabusFile.length === 0 ||
        this.totalContactHrs === 0 ||
        this.subjectTypeArr.length === 0 ||
        this.subjectTypeRadioBtn === ""
      ) {
        alert("Please fill compulsory fields");
      } else {
        if (
          this.subjectTypeRadioBtn === "elective" &&
          this.chooseElectiveByStudent === ""
        ) {
          alert("Please fill compulsory fields");
        } else if (
          this.chooseElectiveByStudent === "true" &&
          (this.date === "" || this.date1 === "")
        ) {
          alert("Please fill compulsory fields");
        } else {
          this.step1Progrss = true;
          this.subjectObjectiveFileLink = await this.uploadFile(
            this.subjectObjectiveFile
          );
          this.subjectSyllabusFileLink = await this.uploadFile(
            this.subjectSyllabusFile
          );
          if (
            this.subjectObjectiveFileLink.length > 0 &&
            this.subjectSyllabusFileLink.length > 0
          ) {
            this.e1 = 2;
          } else {
            showStatus(
              "File was not uploaded Please try again.",
              3000,
              "error",
              this
            );
          }
          this.step1Progrss = false;
        }
      }
    },
    countTotalContactHrs() {
      this.totalContactHrs =
        Number(this.lectureHrs) +
        Number(this.tutorialHrs) +
        Number(this.practicalHrs);
    },
    addRowBookName() {
      let obj = {
        refBookName: "",
        refLink: "",
        addIcon: false,
      };
      this.arrForBookNameRow.push(obj);
    },
    deleteRowBookName(i) {
      this.arrForBookNameRow.splice(i, 1);
    },
    async handelAddSubjectExcel(res) {
      res.data.subjectData.map((data) => {
        if (
          data.subjectName !== "" &&
          data.subjectCode !== "" &&
          data.subjectType !== ""
        ) {
          // compasary field condition
          const [a, b, c, d] = data.subjectType.split(",");
          let dummySubjectArray = [];
          dummySubjectArray = [a, b, c, d];
          let typeWiseSubjectArray = dummySubjectArray.filter((item) => {
            if (item !== undefined) {
              return item.trim(" ");
            }
          });
          typeWiseSubjectArray.map(async (type) => {
            if (
              type === "Theory" ||
              type === "Practical" ||
              type === "Tutorial" ||
              type === "AuditCourse"
            ) {
              let objToPush = {};
              let subjectType = null;
              if (type === "Theory") {
                subjectType = 0;
              }
              if (type === "Practical") {
                subjectType = 1;
              }
              if (type === "Tutorial") {
                subjectType = 2;
              }
              if (type === "AuditCourse") {
                subjectType = 3;
              }
              objToPush.instituteId = this.selectedInstitute;
              objToPush.semId = this.selectedSemester.semId;
              objToPush.department = this.selectedDepartment;
              objToPush.courseYear = this.selectedCourseYear;
              objToPush.subjectName = data.subjectName;
              objToPush.subjectCode = data.subjectCode;
              objToPush.subjectType = subjectType;
              objToPush.groupName = data.groupName;
              objToPush.groupId = new Date().valueOf();
              objToPush.credits = 0;
              objToPush.subjectCoordinators = [];
              objToPush.faculties = [];
              objToPush.electiveType = undefined;

              try {
                const response =
                  await this.subjectRepositoryInstance.createSubject(objToPush);
                showStatus(
                  "Subject Created Successfully.",
                  500,
                  "success",
                  this
                );
                // let data = {
                //   subjectId: response.subject.subjectId,
                //   courseName: response.subject.subjectName + response.subject.subjectType,
                //   courseDescription: "this is course for" + response.subject.subjectName
                // }
                // const res = await axios.post("https://courses-backend-production.up.railway.app/api/v1/create-subject-course", data)
                if (!this.subjectGroups[this.tempSubjectName]) {
                  this.subjectGroups[this.tempSubjectName] = [];
                }
                this.subjectGroups[this.tempSubjectName].push(response);
              } catch (err) {
                showStatus(
                  "Subject not added.Please try again",
                  1000,
                  "error",
                  this
                );
                console.error(err);
              }
            }
          });
        }
      });
      setTimeout(() => {
        this.selectCourseYear();
      }, 1000);
    },
    uploadExcel() {
      if (this.file) {
        var formData = new FormData();
        formData.append("excel", this.file);
        formData.append("instituteId", this.selectedInstitute);
        let url =
          process.env.VUE_APP_SERVER_URL +
          process.env.VUE_APP_API_PORT +
          "/excel/subject/parse";
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/subject/parse",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(
            (response) => {
              this.uploadExcelForSubject = false;
              if (response.status === 200) {
                this.showErrorOfExcel = true;
                this.allErrorArray = [];
                this.allErrorArray = response.data.errorData;
                this.errorMessageByExcel = response.data.errorMessage;
                if (response.data.subjectData.length > 0) {
                  this.handelAddSubjectExcel(response);
                }
              }
            },
            (error) => {
              this.uploadExcelForSubject = false;
              // showStatus('Subject not added.Please try again', 3000, 'error', this)
              this.showErrorOfExcel = true;
              this.errorMessageByExcel = "Subject not added.Please try again";

              console.error(error);
            }
          )
          .then((data) => {
            this.uploadExcelForSubject = false;
          });
      }
    },
    openUploadSubjectDialog() {
      this.uploadExcelForSubject = true;
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search) !== -1
      );
    },
    addingSubjectStep1() {
      this.subjectDocumentSelectedForCreation = [];
      this.addingSubject = true;
    },
    addingValueAddedStep1() {
      this.valueAddedSelectedForCreation = [];
      this.addValueAdded = true;
    },
    convertSubjectTypeToName(num) {
      let type;
      switch (num) {
        case 0: {
          type = "Theory & Compulsory";
          break;
        }
        case 1: {
          type = "Practical & Compulsory";
          break;
        }
        case 2: {
          type = "Tutorial & Compulsory";
          break;
        }
        case 3: {
          type = "Audit Course & Compulsory";
          break;
        }
        case 4: {
          type = "Theory & Elective";
          break;
        }
        case 5: {
          type = "Practical & Elective";
          break;
        }
        case 6: {
          type = "Tutorial & Elective";
          break;
        }
        case 7: {
          type = "Audit Course & Elective";
          break;
        }

        default:
          type = "Theory";

        // code block
      }
      return type;
    },
    async selectDepartment() {
      this.loading = true;
      this.courseYears = [];
      this.selectedCourseYear = "";
      const index = this.departments.indexOf(this.selectedDepartment);
      for (let i = 0; i < this.tree[index].children.length; i++) {
        this.courseYears.push(this.tree[index].children[i].name);
      }
      this.loading = false;
    },
    async selectCourseYear() {
      this.loading = true;
      this.subjects = [];
      this.subjectGroups = {};
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
      };

      try {
        const response =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            objToPush
          );
        this.subjects = response;
        this.groupNameArr = [];
        this.subjects.map((data) => {
          if (data.groupName !== "") {
            this.groupNameArr.push(data.groupName);
          }
        });
        this.groupNameArr.push("Other/ Add New");

        let diplicateRemove = [...new Set(this.groupNameArr)];
        this.groupNameArr = diplicateRemove;
      } catch (err) {
        console.error(err);
        this.subjects = [];
      }

      if (
        this.faculties.filter(
          (faculty) => faculty.department === this.selectedDepartment
        ).length === 0
      ) {
        this.faculties = [];
        let facultyObjects = [];

        try {
          facultyObjects =
            (await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(
              objToPush
            )) || [];
        } catch (err) {
          console.error(err);
        }

        for (let i = 0; i < facultyObjects.length; i++) {
          try {
            const nameOfFaculty =
              await this.userRepositoryInstance.getFullNameOfUser({
                uId: facultyObjects[i].uId,
              });

            if (nameOfFaculty) {
              this.facultyNames[facultyObjects[i].uId] = nameOfFaculty;
              this.faculties.push({
                uId: facultyObjects[i].uId,
                name: nameOfFaculty,
                department: facultyObjects[i].department,
              });
            }
          } catch (err) {
            console.error(err);
          }
        }
      }

      this.subjectGroups = [];

      for (let i = 0; i < this.subjects.length; i++) {
        if (!this.subjectGroups[this.subjects[i].groupId]) {
          this.subjectGroups[this.subjects[i].groupId] = [];
        }
        this.subjectGroups[this.subjects[i].groupId].push(this.subjects[i]);
      }

      this.loading = false;
      this.subjectGroups.slice();

      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        };
        let facultyList =
          await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(
            objToPush
          );
        let uIdArr = [];
        facultyList.map((fac) => {
          uIdArr.push(fac.uId);
        });
        let names = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: uIdArr,
        });
        names.map((nam) => {
          this.nameOfSubjectCo.push(nam.fullName);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async gotoNextStep() {
      if (this.tempSubjectName === "") {
        alert("Enter your subject name");
        return;
      } else if (this.tempSubjectCode === "") {
        alert("Enter your subject code");
        return;
      } else if (
        this.tempTheory == false &&
        this.tempPractical == false &&
        this.tempTutorial == false &&
        this.tempAuditCourse == false
      ) {
        alert("Select Subject Type", this.tempTheory);
        return;
      } else {
        this.saveSubject();
      }
    },
    async check() { },
    async saveSubject() {
      this.loading = true;
      const tempGroupId = new Date().valueOf();
      if (this.editing) {
        for (
          let i = 0;
          i < this.subjectGroups[this.editingGroupId].length;
          i++
        ) {
          try {
            const obj = JSON.parse(
              JSON.stringify(this.subjectGroups[this.editingGroupId][i])
            );
            obj.groupName = this.tempGroupName;
            const response =
              await this.subjectRepositoryInstance.updateGroupName(obj);
            showStatus("Changes Updated Successfully.", 1000, "success", this);
            if (response) {
              this.subjectGroups[this.editingGroupId][i] = obj;
            }
          } catch (err) {
            showStatus("Could not connect to server.", 1000, "error", this);
            console.error(err);
          }
        }
      } else {
        const tempSubjects = [];
        if (this.tempTheory) {
          const obj = {};
          obj.instituteId = this.selectedInstitute;
          obj.semId = this.selectedSemester.semId;
          obj.department = this.selectedDepartment;
          obj.courseYear = this.selectedCourseYear;
          obj.subjectName = this.tempSubjectName;
          obj.subjectCode = this.tempSubjectCode;
          obj.subjectType = this.tempElective ? 4 : 0;
          obj.groupName = this.tempGroupName;
          obj.groupId = tempGroupId;
          obj.credits = 0;
          obj.subjectCoordinators = [];
          obj.faculties = [];
          obj.electiveType = this.tempElective
            ? this.tempElectiveType
            : undefined;
          tempSubjects.push(obj);
        }
        if (this.tempPractical) {
          const obj = {};
          obj.instituteId = this.selectedInstitute;
          obj.semId = this.selectedSemester.semId;
          obj.department = this.selectedDepartment;
          obj.courseYear = this.selectedCourseYear;
          obj.subjectName = this.tempSubjectName;
          obj.subjectCode = this.tempSubjectCode;
          obj.subjectType = this.tempElective ? 5 : 1;
          obj.groupName = this.tempGroupName;
          obj.groupId = tempGroupId;
          obj.credits = 0;
          obj.subjectCoordinators = [];
          obj.faculties = [];
          obj.electiveType = this.tempElective
            ? this.tempElectiveType
            : undefined;
          tempSubjects.push(obj);
        }
        if (this.tempTutorial) {
          const obj = {};
          obj.instituteId = this.selectedInstitute;
          obj.semId = this.selectedSemester.semId;
          obj.department = this.selectedDepartment;
          obj.courseYear = this.selectedCourseYear;
          obj.subjectName = this.tempSubjectName;
          obj.subjectCode = this.tempSubjectCode;
          obj.subjectType = this.tempElective ? 6 : 2;
          obj.groupName = this.tempGroupName;
          obj.groupId = tempGroupId;
          obj.credits = 0;
          obj.subjectCoordinators = [];
          obj.faculties = [];
          obj.electiveType = this.tempElective
            ? this.tempElectiveType
            : undefined;
          tempSubjects.push(obj);
        }
        if (this.tempAuditCourse) {
          const obj = {};
          obj.instituteId = this.selectedInstitute;
          obj.semId = this.selectedSemester.semId;
          obj.department = this.selectedDepartment;
          obj.courseYear = this.selectedCourseYear;
          obj.subjectName = this.tempSubjectName;
          obj.subjectCode = this.tempSubjectCode;
          obj.subjectType = this.tempElective ? 7 : 3;
          obj.groupName = this.tempGroupName;
          obj.groupId = tempGroupId;
          obj.credits = 0;
          obj.subjectCoordinators = [];
          obj.faculties = [];
          obj.electiveType = this.tempElective
            ? this.tempElectiveType
            : undefined;
          tempSubjects.push(obj);
        }
        for (let i = 0; i < tempSubjects.length; i++) {
          try {
            const response = await this.subjectRepositoryInstance.createSubject(
              tempSubjects[i]
            );
            showStatus("Subject Created Successfully.", 500, "success", this);
            let data = {
              subjectId: response.subject.subjectId,
              courseName:
                response.subject.subjectName + response.subject.subjectType,
              courseDescription:
                "this is course for" + response.subject.subjectName,
            };
            const res = await axios.post(
              "https://courses-backend-production.up.railway.app/api/v1/create-subject-course",
              data
            );
            if (!this.subjectGroups[this.tempSubjectName]) {
              this.subjectGroups[this.tempSubjectName] = [];
            }
            this.subjectGroups[this.tempSubjectName].push(response);
          } catch (err) {
            showStatus("Could not connect to server.", 1000, "error", this);
            console.error(err);
          }
        }
        this.subjectGroups.slice();
      }
      await this.selectCourseYear();
      this.loading = false;
      this.addingSubject = false;
    },
    editSubjectVariant(sub) {
      console.log('sub', sub);
        (this.subjectObjectiveFile = []),
        (this.subjectSyllabusFile = []),
        (this.outcomesFile = []),
        (this.instructionMaterialFile = []),
        //(this.prerequisiteFile = []),
        (this.methodologyFile = []);
        (this.prerequisite = ""),
        this.selectedSubjectId = sub.subjectId;
        if (sub.electiveStartDate) {
          let startDate = sub.electiveStartDate.slice(0, 10);

          this.date = startDate;
        }

      if (sub.electiveEndDate) {
        let endDate = sub.electiveEndDate.slice(0, 10);

        this.date1 = endDate;
      }

      this.tempSubjectId = sub.subjectId;

      if (sub.subjectComponent.length > 0) {
        this.subjectComponent = sub.subjectComponent[0];
      }

      if (sub.allowStudentToSelect !== undefined) {
        if (sub.allowStudentToSelect === true) {
          this.chooseElectiveByStudent = "true";
        } else {
          this.chooseElectiveByStudent = "false";
        }
      }
      if (sub.subjectObjectiveFile) {
        this.tempSubjectObjectiveFile = sub.subjectObjectiveFile;
        this.subjectObjectiveFile = [];
      }

      let electiveNumber = sub.subjectType;

      if (electiveNumber === 0 || electiveNumber === 4) {
        this.subjectTypeArr.push("Theory");
      }
      if (electiveNumber === 1 || electiveNumber === 5) {
        this.subjectTypeArr.push("Practical");
      }
      if (electiveNumber === 2 || electiveNumber === 6) {
        this.subjectTypeArr.push("Tutorial");
      }
      if (electiveNumber === 3 || electiveNumber === 7) {
        this.subjectTypeArr.push("AuditCourse");
      }

      if (
        electiveNumber === 0 ||
        electiveNumber === 1 ||
        electiveNumber === 2 ||
        electiveNumber === 3
      ) {
        this.subjectTypeRadioBtn = "compulsory";
      } else {
        this.subjectTypeRadioBtn = "elective";
      }

      this.subjectGroupNameNewBySelect = "";
      this.subjectNameNew = sub.subjectName;
      this.subjectCodeNew = sub.subjectCode;
      this.isSeparatePassing = sub.isSeparatePassing;
      if (sub.groupName) {
        this.subjectGroupNameNewBySelect = sub.groupName;
      }
      if (sub.subjectDescription) {
        this.subjectDescription = sub.subjectDescription;
      }
      if (sub.credits !== undefined) {
        this.totalCredits = sub.credits;
      }

      if (sub.totalMarks !== undefined) {
        this.totalMarks = sub.totalMarks;
      }

      if (sub.totalCieMarks !== undefined) {
        this.totalCieMarks = sub.totalCieMarks;
      }

      if (sub.totalSeeMarks !== undefined) {
        this.totalSeeMarks = sub.totalSeeMarks;
      }
      if (sub.totalHrsForWeek) {
        this.totalHrsWeek = sub.totalHrsForWeek;
      }
      if (sub.lectureHrs) {
        this.lectureHrs = sub.lectureHrs;
      }
      if (sub.tutorialHrs) {
        this.tutorialHrs = sub.tutorialHrs;
      }
      if (sub.practicalHrs) {
        this.practicalHrs = sub.practicalHrs;
      }
      if (sub.selfStudyHrs) {
        this.selfStudyHrs = sub.selfStudyHrs;
      }
      if (sub.totalContactHrs) {
        this.totalContactHrs = sub.totalContactHrs;
      }

      if (sub.refBookDetails) {
        this.arrForBookNameRow = sub.refBookDetails;
      }

      if (sub.outcomesFile) {
        this.tempOutcomesFile = sub.outcomesFile;
      }

      if (sub.selectedCoordinaterName) {
        this.selectedCoordinaterName = sub.selectedCoordinaterName;
      }

      if (sub.instructionMaterialFile) {
        this.tempInstructionMaterialFile = sub.instructionMaterialFile;
      }

      if (sub.prerequisiteFile) {
        this.tempPrerequisiteFile = sub.prerequisiteFile;
      }

      if(sub.prerequisite){
        this.prerequisite = sub.prerequisite
      }

      if(sub.competenceLevel){
        this.competenceLevel = sub.competenceLevel
      }

      if (sub.methodologyFile) {
        this.tempMethodologyFile = sub.methodologyFile;
      }
      this.getAllExamsAndAssignments();

      this.e1 = 1;
      this.editingVariant = true;
    },
    async updateGroupNameOfSubject(subject) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        subjectId: this.tempVariant.subjectId,
        groupName: this.tempGroupName,
      };

      const response =
        await this.subjectRepositoryInstance.updateGroupNameOfSubject(
          objToPush
        );
    },
    deleteSubjectVariant(sub) {
      this.tempSubjectForDeleting = JSON.parse(JSON.stringify(sub));
      this.deletingVariant = true;
    },
    showNameForId(id) {
      return id;
    },
    startEditing(id) {
      this.editingGroupId = id;
      this.tempGroupName = this.subjectGroups[id][0].groupName;
      this.editing = true;
      this.addingSubject = true;
    },
    async removeSubjectCoordinator(item) {
      arrayOps.removeFromArray(this.tempSubjectCoordinators, item);
    },
    removeTeacher(item) {
      arrayOps.removeFromArray(this.tempFaculties, item);
    },
    assignCoordinator(item) {
      this.tempSubjectCoordinators.push(item.uId);
      this.addingCoordinator = false;
    },
    assignTeacher(item) {
      this.tempFaculties.push(item.uId);
      this.addingFaculties = false;
    },
    async saveVariantChanges() {
      this.loading = true;
      const indexOfSubject = this.subjectGroups[
        this.tempVariant.groupId
      ].findIndex(
        (subject) =>
          subject.subjectType === this.tempVariant.subjectType &&
          subject.subjectCode === this.tempVariant.subjectCode &&
          subject.subjectId === this.tempVariant.subjectId
      );
      if (indexOfSubject > -1) {
        try {
          await this.updateGroupNameOfSubject(indexOfSubject);
        } catch (error) {
          console.error("error", error);
        }

        await this.updateSubjectName(indexOfSubject);
        await this.updateSubjectCredits(indexOfSubject);
        await this.updateSubjectCoordinators(indexOfSubject);
        await this.updateFacultiesTeachingASubject(indexOfSubject);
        await this.updateSubjectType(indexOfSubject);
        showStatus("Changes Updated Successfully.", 1000, "success", this);
        this.selectCourseYear();
        this.selectDepartment();

        this.editingVariant = false;
      }
      this.loading = false;
    },
    async deleteVariant() {
      try {

        await this.CorporateRepositoryInstance.deleteSubject(this.tempSubjectForDeleting)
        this.selectCourseYear();
        showStatus(
          "Subject deleted successfully",
          4000,
          "success",
          this
        );
      } catch (error) {
        console.error(error)
        showStatus(
          "Subject Not deleted",
          4000,
          "error",
          this
        );
      }

      // await this.subjectRepositoryInstance.deleteSubject(
      //   this.tempSubjectForDeleting
      // );
      this.deletingVariant = false;
    },
    async updateSubjectName(indexOfSubject) {
      try {
        if (
          this.tempVariant.subjectName.trim() !== this.tempVariantName.trim()
        ) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            oldSubjectName: this.tempVariant.subjectName.trim(),
            newSubjectName: this.tempVariantName.trim(),
          };

          await this.subjectRepositoryInstance.updateSubjectName(objToPush);
          this.subjectGroups[this.tempVariant.groupId][
            indexOfSubject
          ].subjectName = this.tempVariantName;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateSubjectCredits(indexOfSubject) {
      try {
        if (this.tempVariant.credits !== this.tempCredits) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            credits: this.tempCredits,
          };
          await this.subjectRepositoryInstance.updateSubjectCredits(objToPush);
          this.subjectGroups[this.tempVariant.groupId][indexOfSubject].credits =
            this.tempCredits;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateSubjectCoordinators(indexOfSubject) {
      try {
        if (
          !arrayOps.compareArrays(
            this.tempVariant.subjectCoordinators,
            this.tempSubjectCoordinators
          )
        ) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            subjectCoordinators: this.tempSubjectCoordinators,
          };
          await this.subjectRepositoryInstance.updateSubjectCoordinators(
            objToPush
          );
          this.subjectGroups[this.tempVariant.groupId][
            indexOfSubject
          ].subjectCoordinators = this.tempSubjectCoordinators;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateFacultiesTeachingASubject(indexOfSubject) {
      try {
        if (
          !arrayOps.compareArrays(
            this.tempVariant.faculties,
            this.tempFaculties
          )
        ) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            subjectType: this.tempVariant.subjectType,
            faculties: this.tempFaculties,
          };
          await this.subjectRepositoryInstance.updateFacultiesTeachingASubject(
            objToPush
          );
          this.subjectGroups[this.tempVariant.groupId][
            indexOfSubject
          ].faculties = this.tempFaculties;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateSubjectType(indexOfSubject) {
      try {
        const subjectType = this.subjectTypes.indexOf(this.tempSubjectType);
        if (!this.tempVariant.subjectType !== subjectType) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            subjectId: this.tempVariant.subjectId,
            subjectCode: this.tempVariant.subjectCode,
            oldSubjectType: this.tempVariant.subjectType,
            newSubjectType: subjectType,
          };
          await this.subjectRepositoryInstance.updateSubjectType(objToPush);
          this.subjectGroups[this.tempVariant.groupId][
            indexOfSubject
          ].subjectType = subjectType;
        }
      } catch (err) {
        console.error(err);
      }
    },
    gotoSemestersManagment() {
      this.$router.push({
        name:'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },

    percentageCompleted(item) {
      let addPercentage = 5.8;
      let totalCount = 0;

      if (item.subjectName) {
        totalCount = totalCount + addPercentage;
      }

      if (item.subjectCode) {
        totalCount = totalCount + addPercentage;
      }

      if (item.subjectType !== undefined) {
        totalCount = totalCount + addPercentage;
      }

      if (item.groupName !== "") {
        totalCount = totalCount + addPercentage;
      }

      if (item.subjectComponent.length > 0) {
        totalCount = totalCount + addPercentage;
      }

      if (item.subjectDescription !== "") {
        totalCount = totalCount + addPercentage;
      }

      if (item.credits !== 0) {
        totalCount = totalCount + addPercentage;
      }

      if (item.totalHrsForWeek !== 0) {
        totalCount = totalCount + addPercentage;
      }

      if (item.subjectObjectiveFile.length > 0) {
        totalCount = totalCount + addPercentage;
      }

      if (item.subjectSyllabusFile.length > 0) {
        totalCount = totalCount + addPercentage;
      }

      if (item.totalContactHrs !== 0) {
        totalCount = totalCount + addPercentage;
      }

      item.refBookDetails.map((data, i) => {
        if (data.refBookName !== "" && i === 0) {
          totalCount = totalCount + addPercentage;
        }
      });

      if (item.outcomesFile.length > 0) {
        totalCount = totalCount + addPercentage;
      }
      if (item.subjectCoordinators.length > 0) {
        totalCount = totalCount + addPercentage;
      }
      if (item.instructionMaterialFile.length > 0) {
        totalCount = totalCount + addPercentage;
      }
      if (item.prerequisiteFile.length > 0) {
        totalCount = totalCount + addPercentage;
      }
      if (item.methodologyFile.length > 0) {
        totalCount = totalCount + addPercentage;
      }
      return Math.floor(totalCount);
    },
  },
};
</script>

<style src='./activityEditSemesterSubjects.css' scoped></style>
