<template src="./activityCreateAdmission.html"> </template>
<script>
import inputContainer from '../../Components/inputContainer'
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SubjectsRepository from '../../Repository/Subject'
import { convertDate, convertToISTTime } from "../../Services/Utils/DateTimeFormatter";
import Vue from "vue";
import {
  Vuetify,
  VApp,
  Vtextfield,
  VCard,
  Vslidegroup,
  Vcheckbox,
  Vprogresscircular
  /* other imports ... */
} from "vuetify";
export default {
  name: 'activityCreateAdmission',
  components: {
    inputContainer
  },
  props: ['PropsSelectedSemester', 'PropsSelectedinstituteId'],
  data() {
    return {
      progressBar: false,
      editNewfieldsPopUp: false,
      progressBarCompleted: false,
      allSubjectFeesWithGst: 0,
      admissionTitle: '',
      oneTimePaymentDisc: '',
      installmentDiscount: '',
      newInstallment: {
        installmentFeeAmount: null,
        installmentFeePercent: null
      },
      selectedInstitute: '',
      selectedSemester: {},
      createdField: {},
      courseName: '',
      selectedInputType: '',
      selectedInputName: '',
      className: '',
      semestersList: [],
      installmentPattern: [],
      installmentPatternArr: [],
      inputTypes: ['Text Field', 'File', 'Checkbox', 'Dropdown'],
      selectedCourseName: '',
      classname: [],
      subjectsList: [],
      coursename: [],
      selectedCourseName: '',
      selectedClassName: '',
      selectedDepartment: '',
      isAllSubjectCheck: false,
      installmentNo: '',
      switch1: false,
      startDate: '',
      endDate: '',
      installmentCheck: false,
      installmentDivideCheck: false,
      addNewfieldsPopUp: false,
      installment: [],
      selectedInstallment: '',
      duration: [],
      selectedDuration: '',
      gstCheck: false,
      gstValues: ["0", "5", "12", "18", "28"],
      selectedGst: 0,
      isSubjectListLoading: false,
      selectedCourseYear: [],
      feesCriteria: '',
      allFormActivity: 0,
      formActivity: [
        {
          id: 1,
          status: 1,
          isCompulsory: 1,
          isToShow: 1,
          name: 'FirstName',
          type: 'Text Field'
        },
        {
          id: 2,
          status: 1,
          isToShow: 1,
          isCompulsory: 1,
          type: 'Text Field',
          name: 'Middle Name'
        },
        {
          id: 3,
          status: 1,
          isCompulsory: 1,
          isToShow: 1,
          type: 'Text Field',
          name: 'Last Name'
        },
        {
          id: 4,
          status: 1,
          isCompulsory: 1,
          isToShow: 1,
          type: 'Text Field',
          name: 'Student Mobile'
        },
        {
          id: 5,
          status: 1,
          isCompulsory: 1,
          isToShow: 1,
          type: 'Text Field',
          name: 'Student Email'
        },
        {
          id: 6,
          status: 1,
          isCompulsory: 1,
          isToShow: 1,
          type: 'Dropdown',
          name: 'Student Gender'
        },
        {
          id: 7,
          status: 1,
          isCompulsory: 1,
          isToShow: 1,
          type: 'Date',
          name: 'Student Date Of Birth'
        },
        {
          id: 8,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Name Of Father'
        },
        {
          id: 9,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Mobile Number Of Father'
        },
        {
          id: 10,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Occupation Of Father'
        },
        {
          id: 11,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Name Of Mother'
        },
        {
          id: 12,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Mobile Number Of Mother'
        },
        {
          id: 13,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Occupation Of Mother'
        },
        {
          id: 14,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Correspondence Flat No/Apt No/StreetNo'
        },
        {
          id: 15,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Correspondence Landmark/Location nearby'
        },
        {
          id: 16,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Correspondence City'
        },
        {
          id: 17,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Correspondence State'
        },
        {
          id: 18,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Correspondence PinCode'
        },
        {
          id: 19,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Permanent Flat No/Apt No/StreetNo'
        },
        {
          id: 20,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Permanent Landmark/Location nearby'
        },
        {
          id: 21,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Permanent City'
        },
        {
          id: 22,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Permanent State'
        },
        {
          id: 23,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Permanent PinCode'
        },

        {
          id: 24,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Guardian Full Name'
        },
        {
          id: 25,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Guardian Email Id'
        },
        {
          id: 26,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Guardian Mobile Number'
        },
        {
          id: 27,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Guardian Address'
        },
        {
          id: 28,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Previous School/college Name'
        },
        {
          id: 29,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Previous Class'
        },
        {
          id: 30,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Previous Percentage'
        },
        {
          id: 31,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Current School/college Name'
        },
        {
          id: 32,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'Text Field',
          name: 'Current Class'
        },
        {
          id: 33,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'File',
          name: 'Profile Pic'
        },
        {
          id: 34,
          status: 0,
          isCompulsory: 0,
          isToShow: 0,
          type: 'File',
          name: 'Adhar Pic'
        },
      ],
      selectedTab: 0,
    }
  },
  created() {
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.load()
    this.fetchAdmissionDetails();
    this.getSemesters()
    this.getInstallmentcheck()

  },
  methods: {
    async load() {
      this.selectedSemester = this.PropsSelectedSemester;
      this.selectedInstitute = this.PropsSelectedinstituteId;
    },
    async handlecompulsory() {

    },
    async getInstallmentcheck() {
      if (this.installmentCheck === 0 || this.installmentCheck === false) {
        this.selectedInstallment = '1'
        this.selectedDuration = '0'
      }
    },
    async percentToRs() {
      this.installmentPattern.map((arr) => {
        arr.map((obj) => {
          obj.installmentFeeAmount = (this.allSubjectFeesWithGst / 100) * obj.installmentFeePercent
        })
      })
    },
    async rsToPercent() {
      this.installmentPattern.map((arr) => {
        arr.map((obj) => {
          obj.installmentFeePercent = obj.installmentFeeAmount / this.allSubjectFeesWithGst * 100
          obj.installmentFeePercent = obj.installmentFeePercent.toFixed(5)
        })
      })
    },

    async getInstallmentPattern() {
      this.installmentPattern = []
      if (this.installmentPattern.length < this.selectedInstallment) {
        for (let i = 1; i < this.selectedInstallment; i++) {
          let arr = []
          for (let j = 0; j <= i; j++) {
            arr.push({ ...this.newInstallment })
          }
          this.installmentPattern.push(arr)
        }
        this.installmentDivideCheck = 0

      } else {
        this.installmentPattern = []
      }
    },
    async insttalmentDivideEqually() {
      if (this.installmentDivideCheck === 1) {
        this.installmentPattern.map((arr) => {
          arr.map((obj) => {
            obj.installmentFeeAmount = (this.allSubjectFeesWithGst / arr.length).toFixed(5)
          })
          this.rsToPercent()
        })
      } else {
        this.installmentPattern.map((arr) => {
          arr.map((obj) => {
            obj.installmentFeeAmount = 0
          })
          this.rsToPercent()
        })
      }
    },

    async getGstcheck() {
      console.log("this.gstCheck", this.gstCheck);
    },

    async editGstcheck() {
      if (this.feesCriteria === 'subjectWise') {
        this.subjectsList.forEach((subject) => {
          subject.totalFees = parseFloat(subject.basicFees) + parseFloat(parseFloat(subject.basicFees) * parseFloat(this.selectedGst)) / 100;
          console.log("addTotalfee", JSON.stringify(subject.totalFees));
          this.subjectsList = this.subjectsList;
        });
      }
    },
    editAddedField(item) {
      item.index = this.formActivity.indexOf(item)
      this.editNewfieldsPopUp = true
      this.selectedInputType = item.type
      this.createdField = item
      console.log('item', item);
    },
    removeAddedFormField(id) {
      const index = this.formActivity.indexOf(id)
      this.formActivity.splice(index, 1)
    },
    clearEditStates() {
      this.editNewfieldsPopUp = false
    },
    updateNewInput() {
      this.editNewfieldsPopUp = false
      this.formActivity[this.createdField.index] = this.createdField
    },
    gotoAddNewFields() {
      this.addNewfieldsPopUp = true
      this.createdField = {
        name: '',
        type: ''
      }
    },
    async addTotalfee(item) {
      let countfees = 0
      let gstvalue = this.selectedGst;
      this.subjectsList.filter(function (subject) {
        if (subject.subjectId == item.subjectId) {
          subject.totalFees = parseFloat(item.basicFees) + parseFloat(parseFloat(item.basicFees) * parseFloat(gstvalue)) / 100;
        }
        countfees = countfees + subject.totalFees
      });
      this.allSubjectFeesWithGst = countfees
      console.log(countfees)
    },
    selectInputType() {
      this.createdField = {
        id: this.formActivity[this.formActivity.length - 1].id + 1,
        status: 0,
        isCompulsory: 0,
        isToShow: 0,
        name: '',
        options: [{ value: '' }],
        type: this.selectedInputType
      }
      console.log('this.createdField', this.createdField);
    },
    addOption() {
      this.createdField.options.push({ value: '' })
      console.log('this.createdField', this.createdField);
    },
    removeOption(v) {
      const index = this.createdField.options.indexOf(v)
      this.createdField.options.splice(index, 1)
      console.log('this.createdField.options', index, this.createdField.options.splice(index, 1));
    },
    saveNewInput() {
      this.formActivity.push(this.createdField)
      this.clearStates()
    },
    clearStates() {
      this.addNewfieldsPopUp = false
      this.createdField = []
      this.selectedInputType = ''
      this.selectedInputName = ''
    },
    async submit() {
      if (this.feesCriteria === '') {
        alert('Enter Fees Criteria..!')
        return
      } else if (this.startDate === '') {
        alert('Enter startDate..!')
        return
      } else if (this.endDate === '') {
        alert('Select endDate...!')
        return
      } else if (this.selectedInstallment > 1 && this.installmentPattern.length <= 0) {
        alert('Select Installment Fee Percent...!')
        return
      } else if (this.selectedInstallment > 1 && this.installmentPattern.length <= 0) {
        alert('Select Installment Due date')
      } else if (this.selectedInstallment > 1 && this.installmentPattern.length <= 0) {
        alert('Select Installment Fee Percent...!')
        return
      } else if (this.selectedInstallment > 1 && this.installmentPattern.length <= 0) {
        alert('Select Installment Due date')
      } else {
        this.saveAdmission()
      }

    },
    async saveAdmission() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedCourseName,
          startDate: this.startDate,
          courseName: this.selectedClassName,
          subjectCompulsory: this.isAllSubjectCheck,
          endDate: this.endDate,
          status: this.switch1,
          admissionTitle: this.admissionTitle,
        }
        this.progressBar = true
        const saveAdmission = await this.admissionUserRepositoryInstance.saveAdmission(
          objToPush
        )
        this.savePayment(saveAdmission.admissionId);
      }
      catch (err) {
        console.log(err)
      }

    },
    async savePayment(admissionId) {
      try {

        const objToPush = {
          admissionId: admissionId,
          instalmentsNumber: this.selectedInstallment,
          instalmentDuration: this.selectedDuration,
          gstValue: this.selectedGst,
          status: this.switch1,
          installmentPattern: this.installmentPattern,
          oneTimePaymentDisc: this.oneTimePaymentDisc,
          installmentDiscount: this.installmentDiscount,
          allSubjectFeesWithGst: this.allSubjectFeesWithGst
        }
        const savePayment = await this.admissionUserRepositoryInstance.savePayment(
          objToPush
        )
        this.saveSubject(admissionId)
        if (this.startDate > this.endDate) {
          alert("start date")
        }
        else {
          console.log("false");
        }
      }

      catch (err) {
        console.log(err)
      }
    },

    async saveSubject(admissionId) {
      try {
        const objToPush = {
          admissionId: admissionId,
          feesCriteria: this.feesCriteria,
        }
        if (this.feesCriteria === 'courseWise') {
          objToPush.subjectsLists = []
          objToPush.subjectsLists = this.selectedCourseYear
        }
        if (this.feesCriteria === 'subjectWise') {
          objToPush.subjectsLists = this.subjectsList
        }
        const saveAdmission = await this.admissionUserRepositoryInstance.saveSubject(
          objToPush
        )
        this.saveFromFiled(admissionId)
      }
      catch (err) {
        console.log(err)
      }
    },

    async saveFromFiled(admissionId) {
      try {
        const objToPush = {
          admissionId: admissionId,
          allFormActivity: this.allFormActivity,
          formActivity: this.formActivity
        }
        const saveFromFiled = await this.admissionUserRepositoryInstance.saveFormActivity(objToPush)
        this.progressBar = false
        this.progressBarCompleted = true
        const route = () => {
          this.$router.push({
            name: 'activityAdmission',
            params: {
              PropsSelectedSemester: this.selectedSemester,
              PropsSelectedinstituteId: this.selectedInstitute
            }
          })
        }
        setTimeout(route, 2000);

      }
      catch (err) {
        console.log(err)
      }
    },

    async getAllFromFieldCheck() {
      this.formActivity.forEach((form) => {
        if (this.allFormActivity && form.status == 0) {
          form.status = 1;
        } else if (form.isCompulsory == 1) {
          form.status = 1;
        } else {
          form.status = 0;
        }
      });
    },
    addBasicfeeToCourse(obj, i) {
      if (this.selectedGst) {
        this.selectedCourseYear[i].totalFees = parseFloat(this.selectedCourseYear[i].basicFees) + parseFloat(parseFloat(this.selectedCourseYear[i].basicFees) * parseFloat(this.selectedGst)) / 100;
      } else {
        this.selectedCourseYear[i].totalFees = this.selectedCourseYear[i].basicFees
      }
      this.allSubjectFeesWithGst = 0
      this.selectedCourseYear.map((a) => this.allSubjectFeesWithGst += (a.totalFees))
    },

    addBasicfeeToSubjects(obj, i) {
      if (this.selectedGst) {
        this.subjectsList[i].totalFees = parseInt(this.subjectsList[i].basicFees) + parseInt((this.subjectsList[i].basicFees) * (this.selectedGst)) / 100;
      } else {
        this.subjectsList[i].totalFees = this.subjectsList[i].basicFees
      }
      this.allSubjectFeesWithGst = 0
      this.subjectsList.map((a) => this.allSubjectFeesWithGst += (a.totalFees))
      console.log('this.allSubjectFeesWithGst', this.allSubjectFeesWithGst);

    },

    async addBasicfee(item,i) {
      this.isSubjectListLoading = false;
      let gstvalue = this.selectedGst;
      this.subjectsList.filter(function (subject) {
        if (subject.subjectId == item.subjectId) {
          if (gstvalue > 0) {
            subject.basicFees = item.basicFees;
          } else {
            subject.basicFees = item.basicFees;
            subject.totalFees = item.basicFees;
          }
        }
      });
      this.addTotalfee(item);
      this.isSubjectListLoading = true;
    },

    async updateAll(subject,i) {
      console.log("subject.isCompulsory", this.subjectsList)
      // this.subjectsList.map((subjects) => {
      //   if (subjects.subjectId === subject.subjectId) {
      //     subjects.isCompulsory = subject.isCompulsory
      //   }
      //   if (subjects.isCompulsory === 1) {
      //     this.isAllSubjectCheck = 1
      //   }
      //   if (subjects.isCompulsory === 0) {
      //     this.isAllSubjectCheck = 0
      //   }
      // })
      // this.subjectsList[i].isCompulsory =
    },
    async getAllSubjectcheck() {
      this.subjectsList.forEach((subject) => {
        if (this.isAllSubjectCheck) {
          subject.isCompulsory = 1;
        } else {
          subject.isCompulsory = 0;
        }
      });
    },

    async cancel() {
      this.selectedClassName = '';
      this.selectedCourseName = '';
      this.selectedSemesterName = '';
      this.startDate = '';
      this.endDate = '';
      this.subjectsList = [];
      this.selectedInstallment = '';
      this.selectedDuration = '';
      this.installmentCheck = 0;
      this.selectedStatus = '';
      this.isAllSubjectCheck = 0;
      this.gstCheck = 0;
      this.selectedGst = '';

    },
    async getSubjectListbyCourse() {
      this.allSubjectFeesWithGst = 0
      this.selectedCourseYear = []
      this.isSubjectListLoading = false;
      try {
        console.log('this.feesCriteria', this.feesCriteria);
        if (this.feesCriteria === 'subjectWise') {
          if (this.subjectsList.length === 0) {
            if (this.selectedCourseName === '') {
              alert('First select Department Name')
              return
            }
            if (this.selectedClassName === '') {
              alert('First select Course Name')
              return
            }
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedCourseName,
              courseYear: this.selectedClassName,
            }
            this.subjectsList = await this.subjectRepositoryInstance.getSubjectsOfACourseYearForAdmission(
              objToPush
            )
          }
          this.subjectsList.forEach((subject) => {
            subject.totalFees = 0;
            subject.basicFees = 0;
            subject.isCompulsory = 0;
            subject.feesCriteria = 'subjectWise'
            subject.isAdded = false
          });
        } else {
          this.selectedCourseYear.push({
            courseName: this.selectedClassName,
            basicFees: 0,
            isAdded:false,
            totalFees: 0,
            feesCriteria : 'courseWise'
          })
        }
        this.isSubjectListLoading = true;
      }
      catch (err) {
        console.log(err)
      }
    },
    gotoAddFees() {
      this.selectedCourseYear.push({
        courseName: "",
        isAdded:true,
        basicFees: 0,
        totalFees: 0
      })
    },
    gotoAddFeesinSubjectWise() {
      this.subjectsList.push({
        subjectName: "",
        groupName: "Miscellaneous",
        basicFees: 0,
        totalFees: 0,
        isCompulsory:false,
        isAdded: true
      })
    },
    deleteFeesinSubjectWise(i) {
      this.subjectsList.splice(i,1)
      this.allSubjectFeesWithGst = 0
      this.subjectsList.map((a) => this.allSubjectFeesWithGst += parseInt(a.totalFees))
    },
    deleteFeesinCourseWise(i) {
      this.selectedCourseYear.splice(i,1)
      this.allSubjectFeesWithGst = 0
      this.selectedCourseYear.map((a) => this.allSubjectFeesWithGst += parseInt(a.totalFees))
      console.log('this.allSubjectFeesWithGst', this.allSubjectFeesWithGst);
    },
    async getSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        this.semesters.reverse();
        this.semestersList = this.semesters;
      } catch (err) {
        console.log(err)
      }
    },
    async fetchAdmissionDetails() {
      this.createAdmissionButton = true;
      await this.admissionUserRepositoryInstance.updateAdmissionStatus({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
      });
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataBySem({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
      });
      this.admissionList = res;
      this.getCoursebySemesters();
    },
    async getCoursebySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        const res = await this.semesterRepositoryInstance.getCoursebySemesters(
          objToPush
        )
        for (let i = 0; i < res.length; i++) {
          if (this.selectedSemester.semId == res[i].semId) {
            this.coursename.push(res[i])
          }
        }
        this.getInstallment()
        this.getDuration()
      }
      catch (err) {
        console.log(err)
      }
    },
    async changeFunction1() {
      this.selectedClassName = '';
    },
    async getDuration() {
      try {
        this.duration = ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
      }
      catch (err) {
        console.log(err)
      }
    },

    async getInstallment() {
      try {
        this.installment = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
        this.gstValues = ["0", "5", "12", "18", "28"];
      }

      catch (err) {
        console.log(err)
      }
    },

    async getClassbySemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        const res = await this.semesterRepositoryInstance.getCoursebySemesters(objToPush)
        for (let i = 0; i < res.length; i++) {
          if (this.selectedSemester.semId == res[i].semId) {
            this.classname.push(res[i])
          }
        }

      } catch (err) {
        console.log(err)
      }
    },

  }
}
</script>
<style src="./activityCreateAdmission.css" scoped>

</style>
