<template src="./activityFBForm.html"></template>
<script>
import Feedback from "../../../Repository/Feedback";
import showStatus from '../../../NetworkManager/showStatus'
import AccreditationTag from '../../../Components/styleGuide/AccreditationTag'

export default {
    name: 'activityFBForm',
    components: {
        AccreditationTag,
    },
    data() {
        return {
            assignedDepartment: [],
            assignedCourseYearForSubAdmin: [],
            loading: false,
            isMobileView: false,
            userData: null,
            selectedInstitute: '',
            selectedSemester: '',
            selectedItemId: '',
            headers: [
                {
                    text: '',
                    value: 'isNBACriteriaAttainment',
                    sortable: false
                },
                {
                    text: 'Form Name',
                    align: 'left',
                    value: 'formTitle',
                    class: "DataTable"
                },
                {
                    text: 'Start Date',
                    align: 'left',
                    value: 'startDate'
                },
                {
                    text: 'End Date',
                    align: 'left',
                    value: 'endDate',
                },
                {
                    text: 'Form Type',
                    align: 'left',
                    value: 'formType',
                },
                {
                    text: 'Action',
                    align: 'center',
                    value: 'action',
                    width: '250'
                },
            ],
            draftsFeedbackFormsList: [],
            publishedFeedbackFormsList: [],
            loadTable: false,
            deletingVariant: false
        }
    },
    created() {
        this.uId = this.$store.getters['user/get_userData'].uId
        this.selectedTopic = this.$store.getters['liveData/get_selectedTopic']
        this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
        this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
        this.$store.commit('liveData/set_selectedActivityName', 'Feedback Form')

        this.isMobileView = this.$store.getters['liveData/get_isMobileView']

        this.userData = this.$store.getters['user/get_userData']
        this.selectedInstitute = this.$store.getters[
            'instituteData/get_selectedInstitute'
        ]

        this.feedbackRepositoryInstance = new Feedback(this);

        this.getAllFeedback()
    },
    methods: {
        activity() {
            this.$tours['activityFbForm'].start()
        },
        gotoCreateNewFeedbackActivity(state, id, tab) {
            this.$router.push({
                name: 'activityCreateFBForm',
                params: {
                    formState: state, //CREATE, EDIT, PUBLISH
                    formId: id,
                    activeTab: tab
                },
            })
        },
        draftRowClick(id) {
            this.gotoCreateNewFeedbackActivity("EDIT", id, 1)
        },
        publishRowClick(id, tab) {
            this.gotoCreateNewFeedbackActivity("PUBLISH", id, tab)
        },
        copyToClipboard(url) {
            navigator.clipboard.writeText('https://feedbacks.studiumtech.in/' + url);
            showStatus("Link copied successfully !", 6000, 'success', this)
        },
        async getAllFeedback() {
            this.loadTable = true
            this.publishedFeedbackFormsList = []
            this.draftsFeedbackFormsList = []
            try {
                const objToPush = {
                    instituteId: this.selectedInstitute,
                };
                let res = await this.feedbackRepositoryInstance.getAllFeedbackByInstituteId(
                    objToPush
                );
                // console.log('res', res);
                let dummy = []
                if (this.assignedDepartment?.length > 0) {
                    res.map((data) => {
                        if (this.assignedDepartment.includes(data.department)) {
                            dummy.push(data)
                        }
                    })
                    res = dummy

                }
                let allowedCourseYearForSelectedDepartment = []
                if (this.assignedCourseYearForSubAdmin.length > 0) {
                    this.assignedCourseYearForSubAdmin.map((cy) => {
                        let [course, department1] = cy.split('_')
                        res.map((fb) => {

                            if (fb.department === department1 && fb.courseYear === course) {
                                allowedCourseYearForSelectedDepartment.push(fb)
                            }
                        })
                    })
                    res = allowedCourseYearForSelectedDepartment
                }

                res.map((item, index) => {
                    if (item.isPublish) {
                        this.publishedFeedbackFormsList.push(item)
                    } else {
                        this.draftsFeedbackFormsList.push(item)
                    }
                })
                if (this.publishedFeedbackFormsList.length > 0) {
                    this.publishedFeedbackFormsList.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                }
                if (this.draftsFeedbackFormsList.length > 0) {
                    this.draftsFeedbackFormsList.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                }
                this.loadTable = false
            } catch (err) {
                this.loadTable = false
                showStatus("Error fetching feedback forms.", 6000, 'error', this)
            }
        },
        async publishDraftForm(itemID) {
            try {
                const objToPush = {
                    feedbackID: itemID,
                };
                const res = await this.feedbackRepositoryInstance.publishFeedbackForm(
                    objToPush
                );

                this.getAllFeedback()
                showStatus("Feedback Published", 6000, 'success', this)
            } catch (err) {
                showStatus("Error publishing feedback form.", 6000, 'error', this)
            }
        },
        editDraftForm(itemID) {
            //EDIT FROM
        },
        async deleteDraftForm(itemID) {
            try {
                const objToPush = {
                    feedbackID: this.selectedItemId,
                };
                const res = await this.feedbackRepositoryInstance.deleteFeedbackForm(
                    objToPush
                );
                this.getAllFeedback()
                showStatus("Feedback Deleted", 6000, 'success', this)
            } catch (err) {
                showStatus("Error deleing feedback form.", 6000, 'error', this)
            } finally {
              this.deletingVariant = false;
            }
        },
        deleteSubjectVariant(id) {
          this.selectedItemId = id
          this.deletingVariant = true;
        },
    }
}
</script>
<style scoped src="./activityFBForm.css"></style>
