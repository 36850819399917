<template src="./activityPaymentSchedule.html"> </template>
<script>
import UserRepository from '../../Repository/User'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SemesterUserRepository from '../../Repository/SemesterUser'
import semesterRepository from '../../Repository/Semester'
import auth from "@/Services/Firebase/auth";
var firestorage = null;
import Vue from "vue";
import {
  Vuetify,
  VApp,
  Vtextfield,
  VCard,
  Vslidegroup,
  Vcheckbox
  /* other imports ... */
} from "vuetify";

export default {
  name: 'activityPaymentSchedule',
  components: {
    inputContainer
  },
  props: ['prop_user', 'PropsSelectedAdmission'],
  data() {
    return {
      user: [],
      discountedAmount: '',
      discountPercentage: '',
      addDiscountDialog: false,
      newInstallment: {
        installmentFeeAmount: null,
        installmentFeePercent: null,
        dueDate: null
      },
      installmentDivideCheck: false,
      installmentOption: '',
      paymentsOptions: [],
      selectedInstallment: '',
      installmentPattern: [],
      installment: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      allSubjectFeesWithGst: 0
    }
  },
  created() {
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    console.log('prop_user', this.prop_user);
    this.user = this.prop_user
    console.log('PropsSelectedAdmission', this.PropsSelectedAdmission);
  },
  watch: {

  },
  methods: {
    async addDiscount() {
      this.discountedAmount = this.user.totalPayment - (this.user.totalPayment / 100 * this.discountPercentage)
    },
    async checkinstallmentPattern() {
      this.installmentPattern.map((arr) => {
        arr.map((obj) => {
          if (obj.installmentFeeAmount === '' || obj.installmentFeeAmount === null) {
            alert('Choose correct installment Fees..!')
            return false
          } else if (obj.installmentFeePercent === '' || obj.installmentFeePercent === null) {
            alert('Choose correct installment Fees Percentage..!')
            return false
          } else if (obj.dueDate === '' || obj.dueDate === null) {
            alert('Choose correct installment dueDate..!')
            return false
          } else {
            return true
          }
        })
      })

    },
    async saveDiscount() {
      if (this.discountPercentage === '') {
        alert('Enter Discount Percentage..!')
        return
      } else if (this.installmentPattern.length <= 0) {
        alert('Select installment pattern...!')
        return
      } else if (this.installmentPattern[0][0].installmentFeePercent === null) {
        alert('Select Installment Fee Percent...!')
        return
      } else if(this.installmentPattern[0][0].dueDate === null || this.installmentPattern[0][0].dueDate === ''){
        alert('Select Installment Due date')
      } else if (this.installmentPattern[0][1].installmentFeePercent === null) {
        alert('Select Installment Fee Percent...!')
        return
      } else if(this.installmentPattern[0][1].dueDate === null || this.installmentPattern[0][1].dueDate === ''){
        alert('Select Installment Due date')
      }else{
        let data = {
          totalPayment: this.discountedAmount,
          installmentPattern: this.installmentPattern,
          discountPercentage: this.discountPercentage,
          ispersonnelDiscountGiven: true,
          emailId: this.user.emailId
        }
        const res = await this.admissionUserRepositoryInstance.updateAdmissionUser(
          data
        )
        alert("Discount successfully given")
        this.$router.push({
          name: 'activityAdmissionNewUsers',
          params: {
            PropsSelectedAdmission: this.PropsSelectedAdmission
          }
        });
      }
    },
    async percentToRs() {
      this.installmentPattern.map((arr) => {
        arr.map((obj) => {
          obj.installmentFeeAmount = (this.discountedAmount / 100) * obj.installmentFeePercent
        })
      })
    },
    async rsToPercent() {
      this.installmentPattern.map((arr) => {
        arr.map((obj) => {
          obj.installmentFeePercent = obj.installmentFeeAmount / this.discountedAmount * 100
        })
      })
    },
    async insttalmentDivideEqually() {
      if (this.installmentDivideCheck === 1) {
        this.installmentPattern.map((arr) => {
          arr.map((obj) => {
            obj.installmentFeeAmount = Math.round(this.discountedAmount / arr.length)
          })
          this.rsToPercent()
        })
      } else {
        this.installmentPattern.map((arr) => {
          arr.map((obj) => {
            obj.installmentFeeAmount = 0
          })
          this.rsToPercent()
        })
      }
    },
    async getInstallmentPattern() {
      this.installmentPattern = []
      if (this.installmentPattern.length < this.selectedInstallment) {
        for (let i = 1; i < this.selectedInstallment; i++) {
          let arr = []
          for (let j = 0; j <= i; j++) {
            arr.push({ ...this.newInstallment })
          }
          this.installmentPattern.push(arr)
        }
        this.installmentDivideCheck = 0
        console.log('Installment Pattern', this.installmentPattern);
      } else {
        this.installmentPattern = []
      }
    },
    handleInstallmentChange(installment) {
      this.tempPayments = []
      let finalAmount = 0
      const date = new Date()
      let installmentDiscount = this.admissionPayment.installmentDiscount
      let oneTimePaymentDisc = this.admissionPayment.oneTimePaymentDisc
      let installmentPattern = this.admissionPayment.installmentPattern[installment.instalmentsNumber - 2] //current installment pattern from database

      console.log('admission payment', this.admissionPayment);

      for (let i = 1; i <= installment.instalmentsNumber; i++) {
        let instalmentPaymentOption = {}
        let installmentAmount = installment.instalmentsNumber == 1 ? this.userObject.initialPayment / installment.instalmentsNumber : installmentPattern[i - 1].installmentFeeAmount
        if (installment.instalmentsNumber == 1) {
          if (oneTimePaymentDisc != null) {
            this.userObject.paymentDiscount = oneTimePaymentDisc + '%'
            this.userObject.totalPayment = this.userObject.initialPayment - (this.userObject.initialPayment / 100) * oneTimePaymentDisc
            finalAmount = this.userObject.totalPayment ? this.userObject.totalPayment : installmentAmount
          } else {
            this.userObject.paymentDiscount = ''
            this.userObject.totalPayment = this.userObject.initialPayment
            finalAmount = this.userObject.initialPayment
          }
        } else {
          if (installmentDiscount != null) {
            this.userObject.paymentDiscount = installmentDiscount + '%'
            this.userObject.totalPayment = this.userObject.initialPayment - (this.userObject.initialPayment / 100) * installmentDiscount
            finalAmount = this.userObject.totalPayment / installment.instalmentsNumber
          } else {
            this.userObject.paymentDiscount = ''
            this.userObject.totalPayment = 'Not Applicable'
            finalAmount = installmentAmount
          }
        }


        instalmentPaymentOption.instalmentsNumber = i
        instalmentPaymentOption.installmentId = i
        instalmentPaymentOption.paymentDate = ''
        instalmentPaymentOption.paymentMethod = ''
        instalmentPaymentOption.paymentImage = ''
        instalmentPaymentOption.paymentAmount = ''
        instalmentPaymentOption.paymentTransactionId = ''
        instalmentPaymentOption.instalmentsName =
          i == 1 ? 'One Time Payment' : i + '  ' + 'Installments'
        instalmentPaymentOption.dueDate =
          i == 1
            ? new Date()
            : new Date(
              date.setDate(
                date.getDate() + parseInt(installment.instalmentDuration)
              )
            )
        instalmentPaymentOption.isPaidStatus = 0
        instalmentPaymentOption.status = 'unpaid'
        instalmentPaymentOption.amount = finalAmount
        instalmentPaymentOption.amount = instalmentPaymentOption.amount.toFixed(2)
        this.tempPayments.push(instalmentPaymentOption)
      }
    },

  }
}
</script>
<style src="./activityPaymentSchedule.css" scoped>
</style>
