<template src='./activityCreateExam.html'></template>
<script>
import ExamRepository from "../../../Repository/Exam";
import ExamStudentRepository from "../../../Repository/ExamStudent";
import SemesterRepository from "../../../Repository/Semester";
import {
  convertFromYYYYMMDDToDate,
  convertToISTTime,
} from "../../../Services/Utils/DateTimeFormatter";
import showStatus from "../../../NetworkManager/showStatus";
import inputContainer from "../../../Components/inputContainer";
import VueApexCharts from "vue-apexcharts";
import SubjectRepository from "../../../Repository/Subject";
import apiV3 from "../../../NetworkManager/apiV3";
import nba from "../../../NetworkManager/nba";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import InfrastructureReferenceRepository from "../../../Repository/InfrastructureReference";
import axios from "axios";
import nbaRepository from "../../../Repository/NBA";
import auth from "../../../Services/Firebase/auth";
import { v4 } from "uuid"
import {
  generateJsonFromExcel,
  generateFromJson,
  createExcelThroughJson,
  createExcelThroughJsonForMultipleSheets
} from "../../../utils/excel";
export default {
  name: "activityCreateExam",
  components: {
    inputContainer,
    apexcharts: VueApexCharts,
  },

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selectedDate: "",
      assignedCourseYearForSubAdmin: [],
      selectedTime: "",
      assignedDepartment: [],
      dateModal: false,
      timeModal: false,
      e1: 1,
      limits: [0, 100],
      userData: null,
      examName: "",
      examDescription: "",
      examType: "",
      criteria: [],
      startDate: "",
      endDate: "",
      isOnline: "",
      examTypes: ["CIE(Internal)", "SEE(External)"],
      reExam: false,
      modal1: false,
      modal2: false,
      loading: true,
      emptyName: false,
      emptyDescription: false,
      noCriteria: false,
      noStartDate: false,
      invalidStartDate: false,
      noEndDate: false,
      invalidEndDate: false,
      checkbox: true,
      dialog: false,
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedDivision: "",
      departments: [],
      courseYears: [],
      divisions: [],
      selectedInstitute: "",
      selectedSemester: {},
      subjects: [],
      subjectNames: [],
      subjectAllocation: [],
      allSubjectSelection: [],
      allSubject: [],
      subjectCheck: false,
      subjectObject: [],
      allUsers: [],
      facultyList: [],
      facultyArray: [],
      studentList: [],
      studentArray: [],
      studentNames: [],
      facultyNames: [],
      addNewRoomDialog: false,
      studentDialog: false,
      search: "",
      applicableStudents: [],
      duration: "",
      totalMarks: "",
      passingMarks: "",
      tempSelectedRoom: {},
      editingRoom: false,
      roomAllocation: [],
      subjectDetails: [],
      selectedStudent: [],
      selectedStudentArray: [],
      selectedFaculty: [],
      selectedFacultyArray: [],
      selectedApplicablestudents: [],
      openPanel: undefined,
      editSubjectDetails: false,
      editQuestionDetails: false,
      roomData: [],
      roomNames: [],
      editStatus: false,
      deleteDialog: false,
      uploadMarksDialog: false,
      uploadMarksManuallyStatus: false,
      perMarks: "",
      value: [0, 40],
      isEditable: false,
      coDialog: false,
      questionsArray: [],
      questionSet: [],
      questionDetails: [],
      subjectDetails: [],
      totalQuestions: 0,
      COFromNba: [],
      selectedCOS: [],
      applicableHeader: [
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "PRN",
          value: "prn",
        },
      ],
      studentHeaders: [
        {
          text: "Roll No",
          align: "start",
          sortable: false,
          value: "rollNo",
        },
        { text: "Student Name", value: "fullName" },
        { text: "PRN", value: "studentPrn" },
      ],
      facultyHeaders: [
        {
          text: "Faculty Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "email", value: "email" },
      ],
      marksHeader: [],
      studentData: [],

      selected: [],
      series: [44, 55, 41, 17, 15],

      headerSubject: [
        {
          text: "RN",
          value: "rollNo",
        },
        { text: "Name", value: "Name" },
        { text: "Overall", value: "overall" },
      ],
      subjectData: [],
      studentMarks: "",
      queTitle: "",
      selectedStud: {},
      questionWiseMarks: [],
      indivitualMarks: "",
      questionsSet: [],
      nbaAttainmentObject: {},
      updatedSubjectThresholdArray: [],
      subjectThresholdArray: [],
      subjectThreshold: [],
      nbaCriteria: false,
      show: true,
      isBasicDetailEditable: false,
      selectedExamType: "",
      thresholdType: null,
      overallThreshold: 0,
      overallThresholdPercentage: 0,
      uploadQuestionManuallyStatus: false,
      indivitualThresholdAvg: 0,
      overallPercentageStatus: false,
      indivitualThresholdStatus: false,
      allFaculty: [],
      allStudents: [],
      allowedRoom: "",
      allRoom: [],
      roomName: "",
      allStudentList: {},
      courseOutcomeData: [],
      roomStatus: false,
      handleCourseState: false,
      selectedDateArray: [],
      tempSelectedSubject: "",
      tempTotalMarks: "",
      bloomDialog: false,
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)",
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)",
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)",
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)",
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)",
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)",
        },
      ],
      roomAllocationObject: {},
      publishExam: false,
      saveSubjectDetails: false,
      toggleQuestionUpload: null,
      assignedCoArray: [],
      toggleMarksUpload: null,
      uploadOverallMarksDialog: false,
      obtainedMarks: 0,
      graceMarksSymbol: "$",
      maxGraceMarks: 5,
      graceMarks: 0,
      overallMarksTableHeader: [
        {
          text: "",
          value: "action",
          align: "center",
        },
        {
          text: "RN",
          value: "rollNo",
        },
        {
          text: "Student Name",
          value: "studentName",
        },
        {
          text: "PRN",
          value: "studentPrn",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Obtained Marks",
          value: "obtainedMarks",
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
        },
        {
          text: "Symbol for grace marks",
          value: "graceMarksSymbol",
        },
      ],
      noQuestionOverallThresholdPercentage: 0,
      toggleCancelQuestionDialog: false,
      marksUploadSelected: false,
      subjectDetailsLoaded: false,
      questionDetailsLoaded: false,
      uploadQuestionExcelDialog: false,
      questionExcelFile: "",
      questionsLoadedViaExcel: false,
      uploadMarksExcelDialog: false,
      marksExcelFile: "",
      marksLoadedViaExcel: false,
      marksSampleExcelLink: "",
      maxMarks: 0,
      maxQuestionMarks: 0,
      inputRules: {
        numberOnly: (value) => {
          if(value) {
            const pattern = /^[0-9]*$/;
            return pattern.test(value) || "Please Enter Only Numbers.";
          }
        },
        specialCharactersOnly: (value) => {
          if (value) {
            const pattern = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
            return pattern.test(value) || "Only special characters allowed.";
          }
        },
        onlySpecificStrings: (value) => {
          if (value) {
            const pattern = /^[0-9]*(\.[0-9]+)?$|^(NA|X|ABS|NE|W|F|SNG|P|NP)$/i;
            return pattern.test(value) || "Only NA, F, ABS, W, NE, X, SNG, P, NP  are allowed.";
          }
        },
        numberCounter: (value) => value.length <= 2 || "Max 2 numbers",
        valueLimit: (value) => {
          if (!isNaN(value)) {
            return Number(value) <= this.maxMarks
              ? null
              : `Marks cannot be more than ${this.maxMarks}`;
          }
        },
        maxQuestionMarks: (value) =>
          Number(value) <= this.maxQuestionMarks ||
          `Marks cannot be more than ${this.maxQuestionMarks}`,
        maxGraceMarks: (value) => 
            Number(value) <= this.maxGraceMarks ||
            `Grace Marks cannot be more than ${this.maxGraceMarks}`,
        passingMarksLimit: (value) => 
          Number(value) <= this.totalMarks ||
          `Marks cannot be more than ${this.totalMarks}`,
      },
      saveAsDraftLoader: false,
      publishExamLoader: false,
      assignedBloomsArray: [],
      excelGenerated: false,
      instituteType: "",
      nbaData: {},
      instituteCieSee: "",
      attainmentCalculationType: "",
      excelCustomError: false,
      excelCustomErrorMessage: "",
      studentExceedingMarksLimit: [],
      subjectToUploadMarks: {},
      subjectToUploadQuestions: {},
      allCourseYearStudents: [],
      allCourseYearStudentDetails: [],
      examFormTableHeaders: [
        {
          text: "Sr.no",
          value: "srNo",
        },
        {
          text: "Name",
          value: "studentName",
        },
        {
          text: "PRN",
          value: "studentPrn",
        },
        {
          text: "Exam form status",
          value: "examFormStatus",
        },
      ],
      examFormTableData: [],
      courseYearDataLoader: false,
      examFormPublished: false,
      examFormTableSearch: '',
      maxCapacityOfOfTheRoom: 0,
      emailSentNotificationArray: [],
      emailNotificationSentModal: false,
      backgroundColor:'#E7E7FF',
      tabs: 0,
      tabs1: 0,
      tabs2: 0,
      secondaryTabItems: ['Question Paper'], //, 'Rubrics', 'Model Answers'
      createdSets: [{
        no: 1,
        setName: 'set 1'
      }],
      setsObjectForQuestions: {
        'set 1': []
      },
      headers5: [],
      questionsArray1: [],
      coNames:[],
      bloomsNames: [],
      questionDialogue: false,
      orQuestionDialogue: false,
      subQuestionDialogue: false,
      headers4: [
        {
          text: 'Sections',
          value: 'sectionNames',
          sortable: false,
          width: "11%",
          align: "center"
        },
        {
          text: "Qn No",
          value: "questionNumber",
          sortable: false,
          width: "7%",
          align: "center"
        },
        {
          text: 'Sub Qn No',
          value: 'subQnNo',
          sortable: false,
          width: "8%",
          align: "center"
        },
        {
          text: 'Question Statements and Section Names   ',
          value: 'question',
          sortable: false,
          width: "40%",
          align: "center"
        },
        {
          text: "Marks",
          value: "questionMarks",
          width: "7%",
          align: "center"
        },
        {
          text: 'Assign Marks',
          value: 'assignMarks',
          sortable: false,
          width: "10%",
          align: "left"
        }
      ],
      headers1: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "9%",
          align: "center"
        },
        {
          text: "PRN No",
          value: "studentPrn",
          width: "9%",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'studentName',
          width: "20%",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
          width: "10%",
          align: "center"
        },
        {
          text: "Symbol for grace marks",
          value: "graceMarksSymbol",
          width: "10%",
          align: "center"
        },
        {
          text: "Assign Total Marks",
          value: "obtainedMarks",
          sortable: false,
          width: "14%",
          align: "center"
        }
      ],
      headers2: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "auto",
          align: "center"
        },
        {
          text: "PRN No",
          value: "studentPrn",
          width: "auto",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'studentName',
          width: "auto",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Assign Section Wise Marks",
          value: "allSections",
          sortable: false,
          width: "auto",
          align: "center"
          // subheaders: [{ text: "Section Name", value: "sectionName" }]
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
          width: "10%",
          align: "center"
        },
        {
          text: "Symbol for grace marks",
          value: "graceMarksSymbol",
          width: "10%",
          align: "center"
        },
        {
          text: 'Total Marks',
          value: 'obtainedMarks',
          sortable: false,
          width: "14%",
          align: "center"
        }
      ],
      headers3: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "9%",
          align: "center"
        },
        {
          text: "PRN No",
          value: "studentPrn",
          width: "9%",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'studentName',
          width: "20%",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Obtained Marks",
          value: "obtainedMarks",
          sortable: false,
          width: "14%",
          align: "center"
          // subheaders: [
          //   { text: 'Section 1', value: 'section1Marks', align: 'center' },
          //   { text: 'Section 2', value: 'section2Marks', align: 'center' }
          // ]
        }
      ],
      allQuestions: [],
      allSections: [],
      black: '#000000',
      selectedRadio: null,
      createdQuestions: null,
      sectionsOfSelectedSet: [],
      questionWiseMarksDialog: false,
      openQuestionsPreview: false,
      openQuestionsPreview: false,
      uploadOverallMarksViaExcel:false,
      schema : {},
      questionMarksExcelFile: null,
      excelErrors: [],
      uploadSectionMarksViaExcel: false,
      uploadQuestionViseMarksViaExcel : false
    };
  },
  async created() {
    for (let i = 0; i < this.allBlooms.length; i++) {
      this.bloomsNames.push(this.allBlooms[i].name)
    }
    this.assignedDepartment =
      this.$store.getters[
        "liveData/get_accessibleDepartment"
      ].accessibleDepartment;
    this.editRoomIndex = -1;
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.instituteType = this.$store.getters["liveData/get_instituteType"];
    this.attainmentCalculationType =
      this.$store.getters["liveData/get_attainmentCalculationType"];
    this.instituteCieSee = this.$store.getters["liveData/get_instituteCieSee"];
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.nbaRepositoryInstance = new nbaRepository(this);
    this.examRepositoryInstance = new ExamRepository(this);
    this.examStudentRepositoryInstance = new ExamStudentRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.infrastructureReferenceRepositoryInstance =
      new InfrastructureReferenceRepository(this);

    this.fetchData();
    const temp = await auth.getTempThresholdFromFirebase(
      this.selectedInstitute
    );
    if (temp) {
      this.tempGlobalThreshold = temp
      this.thresholdType = "overallThreshold"
    } else {
      this.tempGlobalThreshold = 0
    }
    this.overallThresholdPercentage = this.tempGlobalThreshold
  },
  watch: {
    drawer(newValue) {
      if (!newValue) {
        this.panel = false;
      }
    },
  },
  methods: {
    rowClass(item) {
      if (item.sectionNames) {
        return "sectionBackgrond"
      }
    },
    setActiveSet(set) {
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray
      this.activeSet = set;
      this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName]
    },
    saveSets() {
      this.isSetNameEmpty = false
      this.setsToCreate.forEach(set => {
        if (set.setName.trim() === '') this.isSetNameEmpty = true
      })
      if (!this.isSetNameEmpty) {
        this.createSetDialog = false
        this.createdSets.push(...this.setsToCreate)
      }
      for (let i = 0; i < this.setsToCreate.length; i++) {
        this.setsObjectForQuestions[this.setsToCreate[i].setName] = []
      }
    },
    addSection() {
      let sectionNames = ++this.sectionNo
      for (let i = this.questionsArray1?.length - 1; i >= 0; i--) {
        if (this.questionsArray1[i]?.sectionNames) {
          sectionNames = Number(this.questionsArray1[i].sectionNames[this.questionsArray1[i].sectionNames.length - 1]) + 1
        }
      }
      this.questionNo = 0
      const quesObject = {
        sectionNames: 'Section' + sectionNames,
        question: '',
        sectionId: v4(),
        questionMarks: null,
        cos: [],
        blooms:[]
      }
      this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    addAnyTypeQuestion() {
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        questionForSection: this.questionItem,
        anyCount: null,
        outOfCount: null,
        questionId: v4(),
        question: 'Solve any_out of',
        questionMarks: null,
        cos: [],
        blooms: []
      }
      let foundSec = false
      let breaked = false
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.sectionNames === this.questionItem) {
          foundSec = true
        } else if (this.questionsArray1[i].sectionNames && this.questionsArray1[i].sectionNames !== this.questionItem && foundSec) {
          this.questionsArray1.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray1.push(quesObject)
      }
      // this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    dummy(item, index) {
      if (item.outOfCount && item.anyCount && item.questionMarks) {
        this.addSubquestionsOfAny(item.outOfCount, item.anyCount, item.questionMarks, index)
      }
    },
    addSubquestionsOfAny(outOfCount, anyCount, questionMarks, index) {
      const qMarks = questionMarks / anyCount;
      const quesObject = {
        subQnNo: 'Any ' + anyCount,
        anyQuestionId: v4(),
        questionNumberAny: 'Any ' + anyCount
        // questionNumber: 'Any ' + anyCount
      }
      this.questionsArray1.splice(index, 0, quesObject)
      index++

      for (let i = index; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.questionNo) {
          break
        } else if ((this.questionsArray1[i].anyQuestionId) || (this.questionsArray1[i].anySubQuestionId)) {
          this.questionsArray1.splice(i, 1)
          i--
        }
      }

      // this.questionsArray.push(quesObject)
      let subQueCount = 0
      for (let i = 0; i < outOfCount; i++) {
        // index++
        const obj = {
          question: '',
          anySubQuestionId: v4(),
          anyCount: anyCount,
          subQnNo: 'Q' + this.questionNo + '.' + ++subQueCount,
          anyQue: true,
          questionMarks: qMarks,
          cos: [],
          blooms: []
        }
        this.questionsArray1.splice(index, 0, obj)
        this.resetQueNo()
      }
    },
    addNormalQuestion() {
      this.subQuestionNo = 0
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        questionForSection: this.questionItem,
        question: '',
        questionId: v4(),
        questionMarks: null,
        cos: [],
        blooms:[]
      }
      let foundSec = false
      let breaked = false
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.sectionNames === this.questionItem) {
          foundSec = true
        } else if (this.questionsArray1[i].sectionNames && this.questionsArray1[i].sectionNames !== this.questionItem && foundSec) {
          this.questionsArray1.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray1.push(quesObject)
      }
      // for (let i = 0; i < this.allQuestionObjectArray.length; i++) {
      //   if (this.allQuestionObjectArray[i][this.questionItem]) {
      //     this.allQuestionObjectArray[i][this.questionItem].push(quesObject)
      //   }
      // }
      // this.arrayOfQuestionsToUpdate = []
      // for (let j = 0; j < this.allQuestionObjectArray.length; j++) {

      // }
      // const sectionNames = Object.keys(this.allQuestionObjectArray)
      // for (let i = 0; i < sectionNames.length; i++) {
      //   this.questionsArray1.push()
      // }
      // this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    addOrQuestion(nextIndex) {
      this.subQuestionNo = 0
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        question: '',
        questionId: v4(),
        questionMarks: null,
        cos: [],
        blooms: []
      }
      // quesObject.questionNumber = this.questionsArray1[this.questionItemIndex] ? 'Q' + (parseInt(this.questionsArray1[this.questionItemIndex - 2]?.questionNumber[this.questionsArray1[this.questionItemIndex - 2].questionNumber.length - 1]) + 1) : 'Q' + ++this.questionNo
      // this.questionsArray1.splice(this.questionItemIndex, 0, quesObject)
      // for (let i = this.questionItemIndex + 1; i < this.questionsArray1.length; i++) {
      //   const previousQuestion = this.questionsArray1[i - 1];
      //   const currentQuestion = this.questionsArray1[i];

      //   if (previousQuestion?.questionNumber === 'OR') {
      //     this.questionNo = parseInt(this.questionsArray1[i - 2]?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   } else {
      //     this.questionNo = parseInt(previousQuestion?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   }
      // }
      // this.questionsArray1.splice(this.questionItemIndex + 1, 0, quesObject)
      // this.questionsArray1.push(quesObject)
      if (nextIndex) this.questionsArray1.splice(nextIndex + 1, 0, quesObject)
      else this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    addInOrQuestion() {
      const quesObject = {
        questionNumber: 'OR',
        questionId: v4()
      }

      // let foundQue = false
      // let breaked = false
      // for (let i = 0; i < this.questionsArray1.length; i++) {
      //   if (this.questionsArray1[i]?.questionNo === this.questionItem) {
      //     console.log('else if condition1')
      //     foundQue = true
      //   } else if (this.questionsArray1[i].questionNo && this.questionsArray1[i].questionNo !== this.questionItem && foundQue) {
      //     console.log('this.questionsArray1[i]?.sectionNames', this.questionsArray1[i])
      //     this.questionsArray1.splice(i, 0, quesObject)
      //     breaked = true
      //     break
      //   }
      // }
      // if (!breaked) {
      //   this.questionsArray1.push(quesObject)
      // }
      let nextIndex = null
      let isQuestionFound = false
      for (let i = this.questionItemIndex; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i].questionNumber) {
          this.questionsArray1.splice(i, 0, quesObject)
          isQuestionFound = true
          nextIndex = i
          break
        } else if (this.questionsArray1[i].sectionNames) {
          this.questionsArray1.splice(i - 1, 0, quesObject)
          isQuestionFound = true
          nextIndex = i
          break
        }
      }
      if (!isQuestionFound) {
        this.questionsArray1.push(quesObject)
      }

      // this.questionsArray1.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo()
      // this.questionsArray1.push(quesObject)
      this.addOrQuestion(nextIndex)
    },
    addSubQuestion() {
      const quesObject = {
        question: '',
        subQuestionId: v4(),
        subQnNo: 'Q' + this.questionNo + '.' + ++this.subQuestionNo,
        questionMarks: null,
        cos:[],
        blooms: []
      }
      let foundQn = false
      let breaked = false
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.questionNo === this.questionItem && i === this.questionItemIndex - 1) {
          foundQn = true
        } else if ((this.questionsArray1[i].questionNo && this.questionsArray1[i].questionNo !== this.questionItem && foundQn) || (this.questionsArray1[i].questionNumber && foundQn)) {
          this.questionsArray1.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray1.push(quesObject)
      }
      // this.questionsArray1.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo()
    },
    addOrSubQuestion() {
      const quesObject = {
        subQnNo: 'OR',
        subQuestionId: v4()
      }
      this.questionsArray1.splice(this.subQnIndex, 0, quesObject)
      const quesObject2 = {
        question: '',
        subQuestionId: v4(),
        subQnNo: 'Q' + this.questionNo + '.' + ++this.subQuestionNo,
        questionMarks: null,
        cos: [],
        blooms: []
      }
      this.questionsArray1.splice(this.subQnIndex + 1, 0, quesObject2)
      // this.questionsArray1.push(quesObject)
      // this.addSubQuestion()
      this.resetQueNo()
    },
    resetQueNo() {
      let secNo = 1
      let qnNo = 1
      let sQnNo = 1
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i].sectionNames) {
          qnNo = 1
          sQnNo = 1
          this.questionsArray1[i].questionNo = 'Section' + secNo
          this.questionsArray1[i].sectionNames = 'Section' + secNo
          secNo++
        } else if (this.questionsArray1[i].questionNumber && this.questionsArray1[i].questionNumber !== 'OR') {
          sQnNo = 1
          this.questionsArray1[i].questionNo = 'Q' + qnNo
          this.questionsArray1[i].questionNumber = 'Q' + qnNo
          qnNo++
        } else if (this.questionsArray1[i].subQnNo && this.questionsArray1[i].subQnNo !== 'OR' && !this.questionsArray1[i].questionNumberAny) {
          this.questionsArray1[i].subQnNo = 'Q' + (qnNo - 1) + '.' + sQnNo
          sQnNo++
        }
      }
    },
    handleRadio3(subject){
      const sets = [];
      for (let i = 0; i < this.createdQuestions?.questions.length; i++) {
        if (this.createdQuestions?.questions[i].questionType === 'Set') {
          sets.push({
            questionId: this.createdQuestions.questions[i].questionId,
            setName: this.createdQuestions.questions[i].questionStatement
          })
        }
      }
      let index = this.questionDetails.indexOf(subject);
      this.questionDetails[index].studentData.map((eachStudent)=>{
        eachStudent['sets'] = sets
      })
    },
    handleRadio2(subject) {
      this.allSections = []
      const sets = [];
      let index = this.questionDetails.indexOf(subject);
      for (let i = 0; i < this.createdQuestions?.questions.length; i++) {
        if (this.createdQuestions?.questions[i].questionType === 'Set') {
          for (let j = 0; j < this.createdQuestions.questions[i].question.length; j++) {
            this.allSections.push({ questionId: this.createdQuestions.questions[i].question[j].questionId, sectionName: this.createdQuestions.questions[i].question[j].questionStatement, obtainedMarks:null, questionMarks: this.createdQuestions.questions[i].question[j].questionMarks })
          }
          sets.push({
            questionId: this.createdQuestions.questions[i].questionId,
            setName: this.createdQuestions.questions[i].questionStatement
          })
        }
      }
      this.questionDetails[index].studentData.forEach((eachStudent) => {
        eachStudent['allSections'] = this.allSections;
        eachStudent['sets'] = sets;
      });
    },
    async fetchAssignmentQuestions(sub) {
      // if (this.exam !== null) {
      //   const assignmentQuestions = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.getExamSubjectQuestions({
      //     instituteId: this.selectedInstitute,
      //     examId: this.exam.exam.examId,
      //     subjectId: this.prop_selectedSubject.subjectId
      //   })
      // }
      this.createdQuestions = sub
      if (this.createdQuestions?.questions?.length > 0) {
        this.activeSet = {
          no: 1,
          setName: 'set 1'
        }
        this.setActiveSet(this.activeSet)
        this.createdSets = []
        this.setsObjectForQuestions = {}
        for(let i = 0; i < this.createdQuestions.questions.length; i++) {
          if (this.createdQuestions.questions[i].questionType === 'Set') {
            const questionStatement = this.createdQuestions.questions[i].questionStatement;
            this.createdSets.push({
              no: i + 1,
              setName: questionStatement
            })
            if (!Object.prototype.hasOwnProperty.call(this.setsObjectForQuestions, questionStatement)) {
              this.setsObjectForQuestions[questionStatement] = [];
            }
            this.setsObjectForQuestions[questionStatement].push(...this.getQuestionsOfSelectedSet({ setName: questionStatement, forShowingQuestions: true }, null));
          }
        }
        this.questionsArray1 = this.setsObjectForQuestions['set 1']
      }
    },
    finalSetQuestions(sub) {
      this.allQuestions = []
      this.setsObjectForQuestions['set 1'] = this.questionsArray1
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        this.questionsArray1 = this.setsObjectForQuestions[sets[i]]
        let isBreaked = false
        for (let j = 0; j < this.questionsArray.length; j++) {
          if (this.questionsArray[j]?.question === '' || this.questionsArray[j]?.questionMarks === '') {
            isBreaked = true
            showStatus("Enter question statement or marks in " + (this.questionsArray[j].sectionNames ? this.questionsArray[j].sectionNames : (this.questionsArray[j].questionNumber ? this.questionsArray[j].questionNumber : this.questionsArray[j].subQnNo)), 5000, "error", this);
            break
          }
        }
        if (isBreaked) {
          this.questionsValidationSuccess = false
          break
        } else {
          this.questionsValidationSuccess = true
          this.validateSectionWiseMarks(sets[i],sub)
        }
        // this.validateSectionWiseMarks(sets[i],sub)
       }
       if (this.questionsValidationSuccess) {
        this.saveQuestions(sub);
        // this.finalQuestionsSave(sub)
      }
    },
    deleteQuestion1(index) {
      if (index >= 0 && index < this.questionsArray1.length) {
        if (this.questionsArray1[index].sectionNames) {
          this.questionsArray1.splice(index, 1);
          for (let i = index; i < this.questionsArray1.length; i++) {
            if (this.questionsArray1[i].sectionNames) {
              break;
            } else {
              this.questionsArray1.splice(i, 1);
              i--;
            }
          }
        } else if (this.questionsArray1[index].questionNumber) {
          this.questionsArray1.splice(index, 1);
          for (let i = index; i < this.questionsArray1.length; i++) {
            if (this.questionsArray1[i].questionNumber || this.questionsArray1[i].sectionNames) {
              break;
            } else {
              // const qnNo = this.questionsArray[i].questionNumber
              this.questionsArray1.splice(i, 1);
              // this.questionsArray[i].questionNumber = qnNo
              i--;
            }
          }
        } else if (this.questionsArray1[index].subQnNo) {
          this.questionsArray1.splice(index, 1);
          for (let i = index; i < this.questionsArray1.length; i++) {
            if (this.questionsArray1[i].subQnNo) {
              break;
            } else {
              this.questionsArray1.splice(i, 1);
              i--;
            }
          }
        }
      }
      this.resetQueNo()
    },
    prepareQuestionsArray(allQuestions) {
      const nestedObject = {
        allQuestions: []
      };
      let currentSet;
      let currentSection;
      let currentQuestion;
      let currentSubQuestion;
      let orType = false;
      let NumberOfQuestionsToSolve = 0;
      // console.log('nestedObject', nestedObject
      allQuestions.forEach((item, index) => {
        if(item.setName){
          currentSet = {
            questionId: v4(),
            questionType: 'Set',
            questionMarks: item.Marks,
            questionStatement: item.setName,
            cos : [],
            blooms : [],
            question: []
          };
          nestedObject.allQuestions.push(currentSet);
        }else if(item.Section){
          currentSection = {
            questionId: v4(),
            questionType: 'Section',
            questionMarks: item.Marks,
            questionStatement: item.Section,
            questionNumber: item["Section No."],
            cos: item.Cos,
            blooms: item.Blooms,
            question: []
          }
          currentSet.question.push(currentSection);
        }else if(item.Question){
          currentQuestion = {
          questionId: v4(),
          questionType: 'Question',
          questionMarks: item.Marks,
          questionStatement: item.Question,
          questionNumber: item['Question No.'],
          cos: item.Cos,
          blooms: item.Blooms,
          question: []
          }
          if(orType == true){
            currentQuestion['questionType'] = 'OrQuestion',
            orType = false;
          }
          if(NumberOfQuestionsToSolve !== 0){
            currentQuestion['NumberOfQuestionsToSolve'] = NumberOfQuestionsToSolve,
            NumberOfQuestionsToSolve = 0;
          }
          currentSection.question.push(currentQuestion);
        }else if(item['Sub-Question']){
          currentSubQuestion = {
            questionType: 'SubQuestion',
            questionMarks: item.Marks,
            questionStatement: item['Sub-Question'],
            questionNumber: item['Sub-Question No.'],
            cos: item.Cos,
            blooms: item.Blooms,
            // question: []
          };
          if(orType == true){
            currentQuestion['questionType'] = 'OrSubQuestion',
            orType = false;
          }
          currentQuestion.question.push(currentSubQuestion)
        }else if(item['Question No.'] == 'OR'){
            orType = true;
        } else if (item['Question No.']?.split(' ')[0] == 'Any'){
          NumberOfQuestionsToSolve = item['Question No.'].split(' ')[1];
          currentQuestion['NumberOfQuestionsToSolve'] = NumberOfQuestionsToSolve
        }
      })
      return [nestedObject]
    },
    async finalQuestionsSave() {
      let tempArr = this.prepareQuestionsArray(this.allQuestions)
      try {
        const objToPass = {
          subjectId: this.prop_selectedSubject?.subjectId,
          examId: this.exam?.exam?.examId,
          instituteId: this.selectedInstitute,
          marks: this.exam?.exam?.totalMarks,
          allquestionsArray1: tempArr
        }
        // const data = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.parseExcel(objToPass);
        // if (data) showStatus("Questions successfully added in exam.", 2000, 'success', this);
      } catch (error) {
        console.error(error);
      }
    },
    validateSectionWiseMarks(set) {
      let totalSectionMarks = 0
      if (this.questionsArray1.length > 0) {
        for (let i = 0; i < this.questionsArray1.length; i++) {
          if (this.questionsArray1[i]?.sectionNames) {
            totalSectionMarks += Number(this.questionsArray1[i].questionMarks)
          }
        }
        if (totalSectionMarks !== this.tempTotalMarks) {
          showStatus("Section marks should be equal to the total marks of assignment", 5000, "error", this);
          this.questionsValidationSuccess = false
        } else {
          this.validateQuestionUnderSectionWiseMarks(set)
        }
      } else {
        showStatus("Questions are not added yet", 5000, "error", this);
        this.questionsValidationSuccess = false
      }
    },
    validateQuestionUnderSectionWiseMarks(set) {
      let totalQuestionUnderSectionMarks = 0
      let sectionMarks = 0
      let areQuestionPresent = false
      let checkNext = true
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.sectionNames) {
          if (totalQuestionUnderSectionMarks !== sectionMarks && areQuestionPresent) {
            showStatus(`Total questions under ${this.questionsArray1[i].sectionNames} should be equal to the marks assigned to it`, 5000, "error", this);
            checkNext = false
            this.questionsValidationSuccess = false
            break
          }
          sectionMarks = Number(this.questionsArray1[i].questionMarks)
          totalQuestionUnderSectionMarks = 0
          areQuestionPresent = false
        } else if (this.questionsArray1[i]?.questionNumber && this.questionsArray1[i]?.questionNumber !== 'OR') {
          totalQuestionUnderSectionMarks += Number(this.questionsArray1[i].questionMarks)
          areQuestionPresent = true
        } else if (this.questionsArray1[i]?.questionNumber && this.questionsArray1[i]?.questionNumber === 'OR') {
          totalQuestionUnderSectionMarks -= Number(this.questionsArray1[i + 1].questionMarks)
        }
      }
      if (totalQuestionUnderSectionMarks !== sectionMarks && areQuestionPresent) {
        checkNext = false
        showStatus(`Total questions under section should be equal to the marks assigned to it`, 5000, "error", this);
        checkNext = false
        this.questionsValidationSuccess = false
      }
      if (checkNext) {
        this.validateSubQUestionUnderQuestionUnderSectionWiseMarks(set)
      }
    },
    // validateSubQUestionUnderQuestionUnderSectionWiseMarks(set) {
    //   let totalSubQuestionUnderQuestionUnderSectionMarks = 0
    //   let questionMarks = 0
    //   let areSubQuestionsAvailable = false
    //   let saveQuestions = true
    //   for (let i = 0; i < this.questionsArray1.length; i++) {
    //     if (this.questionsArray1[i]?.questionNumber && this.questionsArray1[i]?.questionNumber !== 'OR') {
    //       if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
    //         showStatus(`Total sub questions marks under ${this.questionsArray1[i].question} should be equal to the marks assigned to it`, 5000, "error", this);
    //         saveQuestions = false
    //         this.questionsValidationSuccess = false
    //         break
    //       }
    //       questionMarks = Number(this.questionsArray1[i].questionMarks)
    //       console.log('this.questionsArray1[i]', this.questionsArray1[i])
    //       totalSubQuestionUnderQuestionUnderSectionMarks = 0
    //       areSubQuestionsAvailable = false
    //     } else if (this.questionsArray1[i]?.subQnNo && this.questionsArray1[i]?.subQnNo !== 'OR' && !this.questionsArray1[i]?.questionNumberAny && !this.questionsArray1[i]?.anySubQuestionId) {
    //       totalSubQuestionUnderQuestionUnderSectionMarks += Number(this.questionsArray1[i].questionMarks)
    //       areSubQuestionsAvailable = true
    //       console.log('areSubQuestionsAvailable', this.questionsArray1[i])
    //       } else if (this.questionsArray1[i]?.subQnNo && this.questionsArray1[i]?.subQnNo === 'OR') {
    //       totalSubQuestionUnderQuestionUnderSectionMarks -= Number(this.questionsArray1[i + 1].questionMarks)
    //     }
    //   }
    //   if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
    //     console.log('totalSubQuestionUnderQuestionUnderSectionMarks', totalSubQuestionUnderQuestionUnderSectionMarks, questionMarks, areSubQuestionsAvailable)
    //     saveQuestions = false
    //     showStatus(`Total sub questions marks under question should be equal to the marks assigned to it`, 5000, "error", this);
    //     saveQuestions = false
    //     this.questionsValidationSuccess = false
    //   }
    //   console.log("save questions", saveQuestions)
    //   if (saveQuestions) {
    //     this.saveQuestions1(set)
    //   }
    //   console.log("validate section 4")
    //   console.log("validate section 6")
    // },
    validateSubQUestionUnderQuestionUnderSectionWiseMarks(set) {
      let totalSubQuestionUnderQuestionUnderSectionMarks = 0
      let questionMarks = 0
      let areSubQuestionsAvailable = false
      let saveQuestions = true
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.questionNumber && this.questionsArray[i]?.questionNumber !== 'OR') {
          if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
            showStatus(`Total sub questions marks under ${this.questionsArray[i].question} should be equal to the marks assigned to it`, 5000, "error", this);
            saveQuestions = false
            this.questionsValidationSuccess = false
            break
          }
          questionMarks = (this.questionsArray[i].questionMarks - '')
          totalSubQuestionUnderQuestionUnderSectionMarks = 0
          areSubQuestionsAvailable = false
        } else if (this.questionsArray[i]?.subQnNo && this.questionsArray[i]?.subQnNo !== 'OR' && !this.questionsArray[i]?.questionNumberAny && !this.questionsArray[i]?.anySubQuestionId && !this.questionsArray[i].anyStatement) {
          totalSubQuestionUnderQuestionUnderSectionMarks += (this.questionsArray[i].questionMarks - '')
          areSubQuestionsAvailable = true
        } else if (this.questionsArray[i]?.subQnNo && this.questionsArray[i]?.subQnNo === 'OR') {
          totalSubQuestionUnderQuestionUnderSectionMarks -= (this.questionsArray[i + 1].questionMarks - '')
        }
      }
      if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
        saveQuestions = false
        showStatus(`Total sub questions marks under question should be equal to the marks assigned to it`, 5000, "error", this);
        saveQuestions = false
        this.questionsValidationSuccess = false
      }
      if (saveQuestions) {
        this.saveQuestions1(set)
      }
    },
    handleExPan(event, subject, i) {
      // if panel is open
      if (
        !event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        this.questionPanel(subject, i);
      }
    },
    resetQuestionForSubject(){
      this.questionsArray1 = [];
    },
    saveQuestions1(set) {
      this.questionToGiveMarks = this.questionsArray1
      this.allQuestions.push({
        setName: set
      })
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i].sectionNames) {
          this.allQuestions.push({
            'Section No.': this.questionsArray1[i].sectionNames,
            Section: this.questionsArray1[i].question,
            Marks: this.questionsArray1[i].questionMarks,
            Cos: this.questionsArray1[i].cos,
            Blooms: this.questionsArray1[i].selectedBlooms
          })
          continue
        }
        if (this.questionsArray1[i].questionNumber && this.questionsArray1[i].questionNumber !== 'OR') {
          this.allQuestions.push({
            'Question No.': this.questionsArray1[i].questionNumber,
            Question: this.questionsArray1[i].question,
            Marks: this.questionsArray1[i].questionMarks,
            Cos: this.questionsArray1[i].cos,
            Blooms: this.questionsArray1[i].selectedBlooms
          })
          continue
        }
        if (this.questionsArray1[i].questionNumber && this.questionsArray1[i].questionNumber === 'OR') {
          this.allQuestions.push({
            'Question No.': 'OR'
          })
          continue
        }
        if (this.questionsArray1[i].subQnNo && this.questionsArray1[i].subQnNo !== 'OR' && !this.questionsArray1[i].questionNumberAny) {
          this.allQuestions.push({
            'Sub-Question No.': this.questionsArray1[i].subQnNo,
            'Sub-Question': this.questionsArray1[i].question,
            Marks: this.questionsArray1[i].questionMarks,
            Cos: this.questionsArray1[i].cos,
            Blooms: this.questionsArray1[i].selectedBlooms
          })
          continue
        }
        if (this.questionsArray1[i].subQnNo && this.questionsArray1[i].subQnNo === 'OR') {
          this.allQuestions.push({
            'Sub-Question No.': 'OR'
          })
          continue
        }
        if (this.questionsArray1[i].questionNumberAny !== undefined) {
          this.allQuestions.push({
            'Question No.': this.questionsArray1[i].questionNumberAny
          })
          continue
        }
      }
    },
    fieldBackgroundColors(item) {
      if (item.questionNumber === 'OR' || item.subQnNo === 'OR' || item.questionNumberAny) {
      } else {
        return "#9205050D"
      }
    },
    getSetSections(selectedSet) {
      this.sectionsOfSelectedSet = []
      for (let i = 0; i < this.setsObjectForQuestions[selectedSet.setName].length; i++) {
        if (this.setsObjectForQuestions[selectedSet.setName][i].sectionNames) {
          this.sectionsOfSelectedSet.push(this.setsObjectForQuestions[selectedSet.setName][i].question)
        }
      }
    },
    async updateMarksOfAStudent(item, limit) {
      if (Number(item.obtainedMarks) > limit) {
        showStatus("Invalid marks entered", 8000, "error", this);
        item.obtainedMarks = 0;
      }
      if (item.obtainedMarks !== "") {
            item["answers"] =  [{ questionId: item.selectedSet?.questionId, obtainedMarks: Number(item.obtainedMarks) }]
            // try {
          //   const objToPush = {
          //     instituteId: this.selectedInstitute,
          //     semId: this.selectedSemester.semId,
          //     uId: item.uId,
          //     examId: this.exam.exam.examId,
          //     attemptNumber: item.attemptNumber,
          //     obtainedMarks: item.obtainedMarks,
          //     status: 'Submitted',
          //     subjectId: this.prop_selectedSubject.subjectId,
          //     answers: [{ questionId: item.selectedSet?.questionId, obtainedMarks: item.obtainedMarks }]
          //   };
          //   if (item.status !== 'Submitted') {
          //     objToPush.submittedOn = item?.submittedOn ? new Date(item?.submittedOn).toISOString() : new Date().toISOString();
          //   }
          //   // await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
          //   //   objToPush
          //   // );
          //   this.students[this.students.indexOf(item)].obtainedMarks = item.obtainedMarks
          //   this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          //   showStatus("Marks updated successfully", 2000, 'success', this);
          // } catch (err) {
          //   console.error(err);
          //   showStatus("Error updating marks", 1000, "error", this);
          // }
        }
    },
    handleRadio1(subject,i){
      if (this.toggleQuestionUpload !== 'uploadQuestions') {
        if (this.headers1[3].text === 'Set attempted') {
          this.headers1.splice(3, 1);
        }
        console.log("headers 1", this.headers1);
      }
      const sets = [];
      for (let i = 0; i < this.createdQuestions.questions.length; i++) {
      if (this.createdQuestions.questions[i].questionType === 'Set') {
        sets.push({
          questionId: this.createdQuestions.questions[i].questionId,
          setName: this.createdQuestions.questions[i].questionStatement
        })
        }
      }
      let index = this.questionDetails.indexOf(subject);
      this.questionDetails[index].studentData.map((eachStudent)=>{
        eachStudent['sets'] = sets
      })
    },
    displayedValue(item, data) {
      if (item[data.sectionName]) return item[data.sectionName]
      else if (item && item.answers && data) {
        for (let i = 0; i < item.answers.length; i++) {
          if (item.answers[i].questionId === data.questionId) {
            return item.answers[i]?.obtainedMarks;
          }
        }
      }
    },
    async sectionWiseStudentMarks(item, secName) {
      const answersArray = []
      let obtainedMarks = 0;
      let incorrectFlag = false;
      for (let i = 0; i < this.allSections.length; i++) {
        if (item[this.allSections[i].sectionName] ) {
          if(item[this.allSections[i].sectionName] > Number(secName.questionMarks)){
            incorrectFlag = true;
            item[this.allSections[i].sectionName] = 0;
          }
          answersArray.push({
            questionId: this.allSections[i].questionId,
            obtainedMarks: Number(item[this.allSections[i].sectionName])
          })
          obtainedMarks += Number(item[this.allSections[i].sectionName])
        }
      }
      if(incorrectFlag == true){
        showStatus("Given Section Marks can't be greater than Total assignment Marks", 1000, "error", this);
          item['obtainedMarks'] = obtainedMarks;  
          item['answers'] = answersArray;
      }
      else if (obtainedMarks > item.total ) {
        showStatus("Given Section Marks can't be greater than Total assignment Marks", 1000, "error", this);
      } 
      else {
        try {
          item['obtainedMarks'] = obtainedMarks;  
          item['answers'] = answersArray;
          // const objToPush = {
          //   instituteId: this.selectedInstitute,
          //   semId: this.selectedSemester.semId,
          //   uId: item.uId,
          //   examId: this.exam.exam.examId,
          //   attemptNumber: item.attemptNumber,
          //   obtainedMarks: obtainedMarks,
          //   status: 'Submitted',
          //   subjectId: this.prop_selectedSubject.subjectId,
          //   answers: answersArray
          // };
          // await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
          //   objToPush
          // );
          // this.students[this.students.indexOf(item)].obtainedMarks = item.obtainedMarks
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      }
      // }

    },
    getQuestionsOfSelectedSet(set, item) {
      this.studentData = item
      let anyQuestionFound = false
      let setQuestions = [];
      for (let i = 0; i < this.createdQuestions.questions.length; i++) {
        if (this.createdQuestions.questions[i].questionType === 'Set' && this.createdQuestions.questions[i].questionStatement === set.setName) {
          setQuestions = this.createdQuestions.questions[i].question;
        }
      }
      this.seperatedSetQuestions = []

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          questionMarks: setQuestions[i].questionMarks,
          cos: setQuestions[i].cos,
          blooms: setQuestions[i].blooms
        })
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === 'OrQuestion') {
              anyQuestionFound = false
              this.seperatedSetQuestions.push({
                questionNumber: 'OR'
              })
            }
            anyQuestionFound = false
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 'Solve any (' + sectionQuestion[j].NumberOfQuestionsToSolve + ')' : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              solveAnyCount: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 0 : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              questionMarks: sectionQuestion[j]?.questionMarks,
              cos: sectionQuestion[j]?.cos,
              blooms: sectionQuestion[j]?.blooms
            })
            if (sectionQuestion[j].NumberOfQuestionsToSolve) {
              anyQuestionFound = true
              this.seperatedSetQuestions.push({
                subQnNo: 'Any ' + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true
              })
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === 'OrSubQuestion') {
                  this.seperatedSetQuestions.push({
                    subQnNo: 'OR',
                    anyStatement: false
                  })
                }
                if (anyQuestionFound) {
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    cos: subQuestion[k].cos,
                    blooms: subQuestion[k].blooms
                  })
                } else {
                  anyQuestionFound = false
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    cos: subQuestion[k].cos,
                    blooms: subQuestion[k].blooms
                  })
                }
              }
            }
          }
        }
      }
      // this.questionWiseMarksDialog = true
      if (!set.forShowingQuestions && this.studentData?.answers) {
        const studentDataMap = new Map(this.studentData?.answers.map(data => [data.questionId, data]));
        for (const setQuestion of this.seperatedSetQuestions) {
          const studentDataForQuestion = studentDataMap.get(setQuestion.questionId);
          if (studentDataForQuestion) {
            setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
          }
        }
        this.questionWiseMarksDialog = true
      } else return this.seperatedSetQuestions
    },
    // validateGivenSectionWiseMarks() {
    //   let totalSectionMarks = 0
    //   if (this.seperatedSetQuestions.length > 0) {
    //     for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
    //       if (this.seperatedSetQuestions[i]?.sectionNames) {
    //         totalSectionMarks += parseInt(this.seperatedSetQuestions[i]?.assignMarks)
    //       }
    //     }
    //     if (totalSectionMarks > this.exam.exam.totalMarks) {
    //       showStatus("Section marks should be equal or less to the total marks of assignment", 5000, "error", this);
    //       this.questionsValidationSuccess = false
    //     } else {
    //       this.validateGivenQuestionUnderSectionWiseMarks()
    //     }
    //   } else {
    //     showStatus("Questions are not added yet", 5000, "error", this);
    //     this.questionsValidationSuccess = false
    //   }
    // },
    async saveQuestionWiseMarks(itm) {
      let marksIncorrect = false
      let answers=[];
      let obtainedMarks=0;
      try {
        const objToPush = {
          // instituteId: this.selectedInstitute,
          // semId: this.selectedSemester.semId,
          // uId: this.studentData.uId,
          // examId: this.exam.exam.examId,
          // attemptNumber: this.studentData.attemptNumber,
          // obtainedMarks: null,
          // status: 'Submitted',
          // subjectId: this.prop_selectedSubject.subjectId,
          answers: []
        };
        for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
          if (this.seperatedSetQuestions[i].assignMarks) {
            if (Number(this.seperatedSetQuestions[i].assignMarks) > Number(this.seperatedSetQuestions[i].questionMarks)) {
              marksIncorrect = true
              showStatus(`Given Marks can not be greater than question marks`, 5000, "error", this);
            } else {
              answers.push({
                questionId: this.seperatedSetQuestions[i]?.questionId,
                obtainedMarks: Number(this.seperatedSetQuestions[i].assignMarks)
              })
              obtainedMarks += this.seperatedSetQuestions[i].sectionNames ? Number(this.seperatedSetQuestions[i].assignMarks) : 0
            }
          }
        }
        if (!marksIncorrect) {
          this.studentData['answers'] = answers;
          this.studentData['obtainedMarks'] = obtainedMarks;
          // await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
          //   objToPush
          // );
          // this.students[this.students.indexOf(this.studentData)].obtainedMarks = this.studentData.obtainedMarks
          // showStatus("Marks updated successfully", 2000, 'success', this);
          this.questionWiseMarksDialog = false
        }
      } catch (err) {
        console.error(err);
        showStatus("Error updating marks", 1000, "error", this);
      }
    },
    async showQuestions(sub) {
      // console.log('clicked quesiton array 1', this.questionsArray1)
      // console.log('clicked', this.seperatedSetQuestions)
      await this.fetchAssignmentQuestions(sub)
      if (this.createdQuestions !== null) {
        this.openQuestionsPreview = true
      } else {
        showStatus("Questions are not added yet for this assignment", 9000, "warning", this);
      }
    },
    sortByAlphaNumericValue(array, fieldToSortBy) {
      const newArray = [...array];
      newArray.sort((a, b) => {
        if ( 
          a[fieldToSortBy] === null ||
          b[fieldToSortBy] === null ||
          a[fieldToSortBy] === undefined ||
          b[fieldToSortBy] === undefined
        ) {
          // If either fieldToSortBy is null, move the element with null fieldToSortBy to the end.
          if (a[fieldToSortBy] === null || a[fieldToSortBy] === undefined)
            return 1;
          if (b[fieldToSortBy] === null || b[fieldToSortBy] === undefined)
            return -1;
        }
        return a[fieldToSortBy].localeCompare(b[fieldToSortBy]); // Compare non-null fieldToSortBy strings
      });
      return newArray;
    },
    downloadOverallMarksReport(students) {
      let sortedStudents = this.sortByAlphaNumericValue(students, 'studentPrn')
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });
        for (let j = 0; j < sortedStudents?.length; j++) {
          const objToPush = {}
          objToPush.PRN = sortedStudents[j]?.studentPrn
          objToPush.Name = sortedStudents[j]?.studentName
          objToPush[`Overall_Marks(${this.tempTotalMarks})`] = sortedStudents[j]?.obtainedMarks === 0 ? '' : sortedStudents[j]?.obtainedMarks
          data[i][sets[i]].push(objToPush);
        }
      }
      createExcelThroughJsonForMultipleSheets(data, this.examName + '_Marks')
    },
    downloadSectionMarksReport(students) {
      let sortedStudents = this.sortByAlphaNumericValue(students, 'studentPrn')
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });

        for (let k = 0; k < sortedStudents.length; k++) {
          const objToPush = {
            PRN: sortedStudents[k].studentPrn,
            Name: sortedStudents[k].studentName
          };

          let sectionName = '';
          for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < sortedStudents[k].answers?.length; y++) {
                if (sortedStudents[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }
          }

          data[i][sets[i]].push(objToPush);
        }
      }
      // console.log('data12', data)
      createExcelThroughJsonForMultipleSheets(data,  this.examName + '_Marks')
    },
    downloadQuestionMarksReport(students) {
      let sortedStudents = this.sortByAlphaNumericValue(students, 'studentPrn')
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });

        for (let k = 0; k < sortedStudents.length; k++) {
          const objToPush = {
            PRN: sortedStudents[k].studentPrn,
            Name: sortedStudents[k].studentName
          };

          let sectionName = '';
          for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
            let qNo = '';

            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < sortedStudents[k].answers?.length; y++) {
                if (sortedStudents[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }

            if (this.setsObjectForQuestions[sets[i]][j].questionNumber && this.setsObjectForQuestions[sets[i]][j].questionNumber !== 'OR') {
              qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < sortedStudents[k].answers?.length; y++) {
                if (sortedStudents[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}_${qNo}(${questionMark})`] = colValue;
            }

            if (this.setsObjectForQuestions[sets[i]][j].subQnNo && this.setsObjectForQuestions[sets[i]][j].subQnNo !== 'OR' && (!this.setsObjectForQuestions[sets[i]][j].anyStatement || (this.setsObjectForQuestions[sets[i]][j].anyStatement && this.setsObjectForQuestions[sets[i]][j].question))) {
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < sortedStudents[k].answers?.length; y++) {
                if (sortedStudents[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}(${questionMark})`] = colValue;
            }
          }

          data[i][sets[i]].push(objToPush);
        }
      }
      // console.log('data12', data)
      createExcelThroughJsonForMultipleSheets(data,  this.examName + '_Marks')
    },
    async startUploadingOverallMarksExcel(students) {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentPrn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentName'
          },
          [`Overall_Marks(${this.tempTotalMarks})`]: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'overall_marks'
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = students.find(s => s.studentPrn === data.studentPrn);
              // console.log('datadata', data)
              if (student) {
                if (this.tempTotalMarks < data.overall_marks) {
                  this.excelErrors.push('Marks given to ' + student.studentName + ' should not be greater than ' + this.tempTotalMarks)
                } else {
                  student['obtainedMarks'] = data.overall_marks
                  student['answers'] = [];
                  // objectsArrayToUpdateMarks.push({
                  //   uId: student.uId,
                  //   instituteId: this.selectedInstitute,
                  //   semId: this.selectedSemester.semId,
                  //   examId: this.exam.exam.examId,
                  //   status: 'Submitted',
                  //   submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  //   subjectId: this.prop_selectedSubject.subjectId,
                  //   attemptNumber: student.attemptNumber,
                  //   totalMarksObtained: data.overall_marks,
                  //   : [],
                  //   marksGivenTo: data,
                  //   setName: sheet
                  // });
                }
              }
            }
          }

          if (this.excelErrors.length === 0) {
            // await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
            //   bulkDataArray: objectsArrayToUpdateMarks
            // });
            this.uploadOverallMarksViaExcel = false
            this.questionMarksExcelFile = null
            // this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
          // this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    async startUploadingSectionMarksExcel(students) {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentPrn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentName'
          }
        }
        for (let k = 0; k < students.length; k++) {
          data[i][sets[i]].push({ PRN: students[k].studentPrn, Name: students[k].studentName })
        }
        let sectionName = '';
        for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
          if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
            sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: sectionName
            }
            data[i][sets[i]].push({
              [sectionName]: ''
            });
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = students.find(s => s.studentPrn === data.studentPrn);

              if (student) {
                objectsArrayToUpdateMarks.push({
                  uId: student.studentId,
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  // examId: this.exam.exam.examId,
                  status: 'Submitted',
                  // submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  // subjectId: this.prop_selectedSubject.subjectId,
                  attemptNumber: student.attemptNumber,
                  obtainedMarks: 0,
                  answers: [],
                  marksGivenTo: data,
                  setName: sheet
                });
              }
            }
          }

          for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
            const marksGivenToKeys = Object.keys(objectsArrayToUpdateMarks[i].marksGivenTo)
            for (let j = 0; j < marksGivenToKeys.length; j++) {
              const sectionAndQuestion = marksGivenToKeys[j].split('_')
              if (sectionAndQuestion.length === 1) {
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                  ) {
                    objectsArrayToUpdateMarks[i].obtainedMarks += objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    });
                  } else if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]) {
                    this.excelErrors.push('Given Marks of ' + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]] + ' are greater than its assigned marks')
                    // showStatus("Given Marks of " + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + " are greater than its assigned marks", 5000, 'error', this);
                  }
                }
              }
            }
          }
          if (this.excelErrors.length === 0) {
            objectsArrayToUpdateMarks.forEach(updateObject =>{
              const matchingStudent = students.find(student => student.studentId === updateObject.uId);
              if(matchingStudent){
                matchingStudent.obtainedMarks = updateObject.obtainedMarks;
                matchingStudent.answers = updateObject.answers
              }
            })
            // await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
            //   bulkDataArray: objectsArrayToUpdateMarks
            // });
            this.uploadSectionMarksViaExcel = false
            this.questionMarksExcelFile = null
            // this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    async startUploadingQuestionMarksExcel(students) {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentPrn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentName'
          }
        }
        for (let k = 0; k < students.length; k++) {
          data[i][sets[i]].push({ PRN: students[k].PRN_No, Name:students[k].name })
        }
        let sectionName = '';
        for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
          let qNo = '';

          if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
            sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: sectionName
            }
            data[i][sets[i]].push({
              [sectionName]: ''
            });
          }

          if (this.setsObjectForQuestions[sets[i]][j].questionNumber && this.setsObjectForQuestions[sets[i]][j].questionNumber !== 'OR') {
            qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}_${qNo}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: `${sectionName}_${qNo}`
            }
            data[i][sets[i]].push({
              [`${sectionName}_${qNo}`]: ''
            });
          }

          if (this.setsObjectForQuestions[sets[i]][j].subQnNo && this.setsObjectForQuestions[sets[i]][j].subQnNo !== 'OR' && (!this.setsObjectForQuestions[sets[i]][j].anyStatement || (this.setsObjectForQuestions[sets[i]][j].anyStatement && this.setsObjectForQuestions[sets[i]][j].question))) {
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: `${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}`
            }
            data[i][sets[i]].push({
              [`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}`]: ''
            });
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = students.find(s => s.studentPrn === data.studentPrn);

              if (student) {
                objectsArrayToUpdateMarks.push({
                  uId: student.studentId,
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  // examId: this.exam.exam.examId,
                  // status: 'Submitted',
                  // submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  // subjectId: this.prop_selectedSubject.subjectId,
                  // attemptNumber: student.attemptNumber,
                  obtainedMarks: 0,
                  answers: [],
                  marksGivenTo: data,
                  setName: sheet
                });
              }
            }
          }

          for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
            const marksGivenToKeys = Object.keys(objectsArrayToUpdateMarks[i].marksGivenTo)
            for (let j = 0; j < marksGivenToKeys.length; j++) {
              const sectionAndQuestion = marksGivenToKeys[j].split('_')
              if (sectionAndQuestion.length === 1) {
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                  ) {
                    objectsArrayToUpdateMarks[i].obtainedMarks += objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    });
                  } else if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]) {
                    this.excelErrors.push('Given Marks of ' + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]] + ' are greater than its assigned marks')
                    // showStatus("Given Marks of " + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + " are greater than its assigned marks", 5000, 'error', this);
                  }
                }
              } else {
                let secName = ''
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames) secName = this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames
                  if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]
                    });
                  } else if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                    this.excelErrors.push("Given Marks of " + secName + "-" + (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber ? this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber : this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')] + " are greater than its assigned marks");
                  }
                }
              }
            }
          }
          if (this.excelErrors.length === 0) {
            // await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
            //   bulkDataArray: objectsArrayToUpdateMarks
            // });
            objectsArrayToUpdateMarks.forEach(updateObject =>{
              const matchingStudent = students.find(student => student.studentId === updateObject.uId);
              if(matchingStudent){
                matchingStudent.obtainedMarks = updateObject.obtainedMarks;
                matchingStudent.answers = updateObject.answers
              }
            })
            this.uploadQuestionViseMarksViaExcel = false
            this.questionMarksExcelFile = null
            // this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
          // this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          // showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    handleQuestionTableHeaders(){
      if(this.nbaCriteria) {
        this.headers5 =  [
          {
            text: 'Sections',
            value: 'sectionNames',
            sortable: false,
            width: "10%",
            align: "center"
          },
          {
            text: "Qn No",
            value: "questionNumber",
            sortable: false,
            width: "100px",
            align: "center"
          },
          {
            text: 'Sub Qn No',
            value: 'subQnNo',
            sortable: false,
            width: "100px",
            align: "center"
          },
          {
            text: 'Question Statements and Section Names   ',
            value: 'question',
            sortable: false,
            width: "300px",
            align: "center"
          },
          {
            text: "Marks",
            value: "questionMarks",
            width: "100px",
            align: "center"
          }
        ]
        if(this.instituteType === 'Tier-1'){
          this.headers5.push({
            text: 'CLO’s mapped',
            value: 'cos',
            sortable: false,
            width: "13%",
            align: "center"
          },
          {
            text: 'Blooms Mapped',
            value: 'blooms',
            sortable: false,
            width: "15%",
            align: "left"
          },
          {
            text: '',
            value: 'delete',
            sortable: false,
            width: "5%",
            align: "left"
          }
         )
        }else{
          this.headers5.push(
            {
            text: 'CO’s mapped',
            value: 'cos',
            sortable: false,
            width: "13%",
            align: "center"
          },
          {
            text: 'Blooms Mapped',
            value: 'blooms',
            sortable: false,
            width: "15%",
            align: "left"
          },
          {
            text: '',
            value: 'delete',
            sortable: false,
            width: "5%",
            align: "left"
          })
        }
      }else{
        this.headers5 =  [
        {
          text: 'Sections',
          value: 'sectionNames',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Qn No",
          value: "questionNumber",
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: 'Sub Qn No',
          value: 'subQnNo',
          sortable: false,
          width: "11%",
          align: "center"
        },
        {
          text: 'Question Statements and Section Names   ',
          value: 'question',
          sortable: false,
          width: "30%",
          align: "center"
        },
        {
          text: "Marks",
          value: "questionMarks",
          width: "9%",
          align: "center"
        },
        {
          text: '',
          value: 'delete',
          sortable: false,
          width: "5%",
          align: "left"
        }
      ]
      }
    },
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        };
        this.semestersList =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        this.constructAcademicDataTree();
      } catch (err) {
        console.error(err);
      }
    },
    async constructAcademicDataTree() {
      this.academicDataTree = {};

      this.semestersList.forEach((sem) => {
        if (!this.academicDataTree[sem.department]) {
          this.academicDataTree[sem.department] = {};
        }
        if (!this.academicDataTree[sem.department][sem.courseYear]) {
          this.academicDataTree[sem.department][sem.courseYear] = [];
        }
        this.academicDataTree[sem.department][sem.courseYear].push(
          sem.division
        );
      });

      this.departments = Object.keys(this.academicDataTree);
      let subAdminAssignedDepartmentWiseLecture = [];
      if (this.assignedDepartment.length > 0) {
        this.departments.map((data) => {
          if (this.assignedDepartment.includes(data)) {
            subAdminAssignedDepartmentWiseLecture.push(data);
          }
        });
        this.departments = subAdminAssignedDepartmentWiseLecture;
      }
      this.courseYears = [];
      this.divisions = [];

      this.loading = false;
    },
    async nbaDetails() {
      this.isBasicDetailEditable = false;
      if (this.nbaCriteria) {
        if (this.attainmentCalculationType === "Threshold") {
          if (
            this.selectedExamType === "CIE(Internal)" &&
            this.thresholdType === "individualThreshold"
          ) {
            this.nbaAttainmentObject = {
              typeOfExam: "cie",
              thresholdType: "individualThreshold",
            };
          } else if (
            this.selectedExamType === "CIE(Internal)" &&
            this.thresholdType === "overallThreshold"
          ) {
            this.nbaAttainmentObject = {
              typeOfExam: "cie",
              thresholdType: "overallThreshold",
              thresholdPercentage: this.overallThresholdPercentage,
            };
          } else if (
            this.selectedExamType === "SEE(External)" &&
            this.thresholdType === "individualThreshold"
          ) {
            this.nbaAttainmentObject = {
              typeOfExam: "see",
              thresholdType: "individualThreshold",
              thresholdPercentage: this.subjectAllocation,
            };
          } else if (
            this.selectedExamType === "SEE(External)" &&
            this.thresholdType === "overallThreshold"
          ) {
            this.nbaAttainmentObject = {
              typeOfExam: "see",
              thresholdType: "overallThreshold",
              thresholdPercentage: this.overallThresholdPercentage,
            };
          }
        } else {
          if (this.selectedExamType === "SEE(External)") {
            this.nbaAttainmentObject = {
              typeOfExam: "see",
              thresholdType: null,
            };
          } else {
            this.nbaAttainmentObject = {
              typeOfExam: "cie",
              thresholdType: null,
            };
          }
        }
      }
    },
    selectDepartment() {
      this.courseYears = [];
      this.selectedCourseYear = "";
      this.divisions = [];
      this.selectedDivision = "";
      this.courseYears = Object.keys(
        this.academicDataTree[this.selectedDepartment]
      );
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDepartment) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYears = allowedCourseYearForSelectedDepartment
      }
      // console.log('this.courseYears', this.courseYears);
    },
    async selectCourseYear() {
      this.courseYearDataLoader = true;
      this.divisions =
        this.academicDataTree[this.selectedDepartment][this.selectedCourseYear];
      await this.getSubjects();
      await this.getStudentsOfACourseYear();
      this.courseYearDataLoader = false;
    },
    handleSubejcts() {
      this.subjectAllocation.length === this.subjectNames.length
        ? (this.subjectCheck = true)
        : (this.subjectCheck = false);
    },
    selectAll() {
      this.subjectObject = [];
      this.subjectAllocation = [];
      this.allSubjectSelection = [];
      this.questionDetails = [];
      if (this.subjectCheck === true) {
        this.subjectNames.forEach((sub) => {
          this.allSubjectSelection.push(sub.subjectName);
          this.subjectAllocation.push(sub);
          this.subjectObject.push(sub.subjectId);
        });
      } else {
        this.allSubjectSelection = [];
        this.subjectAllocation = [];
      }
    },
    remove_array_element(array, n) {
      if (n > -1) {
        array.splice(n, 1);
      }
      return array;
    },
    clearErrors() {
      this.emptyName = false;
      this.emptyDescription = false;
      this.noCriteria = false;
      this.noEndDate = false;
      this.noStartDate = false;
      this.invalidStartDate = false;
      this.invalidEndDate = false;
    },
    async createExam() {
      this.publishExamLoader = true;
      this.subjectObject = [];
      let noErrors = true;
      if (!this.examName) {
        this.emptyName = true;
        noErrors = false;
        showStatus("Please Enter Valid Exam Name.", 9000, "error", this);
      }
      if (this.selectedDepartment === "") {
        this.noCriteria = true;
        noErrors = false;
      }
      if (this.startDate) {
        if (
          new Date(this.startDate) ==
          new Date(new Date().toLocaleString().split(",")[0])
        ) {
          this.invalidStartDate = true;
          noErrors = false;
        }
      } else {
        this.noStartDate = true;
        noErrors = false;
      }
      if (this.endDate) {
        if (this.startDate) {
          if (!(new Date(this.startDate) <= new Date(this.endDate))) {
            this.invalidEndDate = true;
            noErrors = false;
            showStatus(
              "Error creating exam. Please enter correct start date and end date.",
              9000,
              "error",
              this
            );
          }
        }
      } else {
        this.noEndDate = true;
        noErrors = false;
      }
      if (noErrors) {
        const criteria = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear
            ? this.selectedCourseYear
            : undefined,
          division: "All",
        };
        this.subjectAllocation.forEach((sub) => {
          this.subjectObject.push(sub.subjectId);
        });
        const studentIds = this.examFormTableData.map((item) => {
          if (item.examFormStatus) return item.studentUId;
        });
        let objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          name: this.examName,
          description: this.examDescription,
          type: this.selectedExamType,
          startDate: this.startDate,
          endDate: this.endDate,
          criteria: criteria,
          isForNBA: this.nbaCriteria,
          applicableSubjects: this.subjectObject,
          subjectDetails: this.subjectDetails,
          questionDetails: this.questionDetails,
          examStatus: "Published",
          attemptNumber: 1,
          reExam: this.reExam,
          examFormPublished: this.examFormPublished,
          allFaculties: this.facultyNames
        };
        if (this.examFormPublished) {
          objToPush.studentsAllowedToFillExamForm = studentIds;
        }
        if (this.nbaCriteria) {
          objToPush.nbaAttainment = this.nbaAttainmentObject;
        }

        try {
          const res = await apiV3.postRequest("/manageExam/create", objToPush);
          showStatus("Exam Created Successfully", 5000, "success", this);
          this.publishExamLoader = false;
          if (res.emailToBeSent && res.emailToBeSent.length > 0) {
            this.emailSentNotificationArray = res.emailToBeSent
            this.emailNotificationSentModal = true
          } else {
            this.$router.push("./activityExams");
          }
          // this.$router.push("./activityExams");
        } catch (err) {
          console.error(err);
          this.publishExamLoader = false;
          showStatus("Error creating exam", 9000, "error", this);
        }
      }
    },
    async saveAsDraft() {
      this.subjectObject = [];
      this.saveAsDraftLoader = true;
      if (this.nbaCriteria) {
        await this.nbaDetails();
      }
      await this.createArray();

      let noErrors = true;
      if (!this.examName) {
        this.emptyName = true;
        noErrors = false;
      }
      if (this.selectedDepartment === "") {
        this.noCriteria = true;
        noErrors = false;
      }
      if (this.startDate) {
        if (
          new Date(this.startDate) ==
          new Date(new Date().toLocaleString().split(",")[0])
        ) {
          this.invalidStartDate = true;
          noErrors = false;
        }
      } else {
        this.noStartDate = true;
        noErrors = false;
      }
      if (this.endDate) {
        if (this.startDate) {
          if (!(new Date(this.startDate) < new Date(this.endDate))) {
            this.invalidEndDate = true;
            noErrors = false;
            showStatus(
              "Error creating exam. Please enter correct start date and end date.",
              9000,
              "error",
              this
            );
          }
        }
      } else {
        this.noEndDate = true;
        noErrors = false;
      }
      if (noErrors === false) {
        showStatus("Please Enter Valid Data", 9000, "error", this);
      }
      if (noErrors) {
        const criteria = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear
            ? this.selectedCourseYear
            : undefined,
          division: "All",
        };
        this.subjectAllocation.forEach((sub) => {
          this.subjectObject.push(sub.subjectId);
        });
        const studentIds = this.examFormTableData.map((item) => {
          if (item.examFormStatus) return item.studentUId;
        });
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          name: this.examName,
          description: this.examDescription ? this.examDescription : "",
          type: this.selectedExamType ? this.selectedExamType : "",
          startDate: this.startDate,
          endDate: this.endDate,
          criteria: criteria ? criteria : "",
          isForNBA: this.nbaCriteria,
          applicableSubjects: this.subjectObject ? this.subjectObject : [],
          subjectDetails: this.subjectDetails ? this.subjectDetails : [],
          questionDetails: this.questionDetails ? this.questionDetails : [],
          examStatus: "Draft",
          attemptNumber: 1,
          reExam: this.reExam,
          examFormPublished: this.examFormPublished,
          allFaculties: this.facultyNames
        };
        if (this.examFormPublished) {
          objToPush.studentsAllowedToFillExamForm = studentIds;
        }
        if (this.nbaCriteria) {
          objToPush.nbaAttainment = this.nbaAttainmentObject;
        }
        try {
          const res = await apiV3.postRequest("/manageExam/saveAsDraft", objToPush);
          if (this.examFormPublished) {
            showStatus(
              "Exam form published successfully & exam Saved as a draft.",
              6000,
              "success",
              this
            );
          } else {
            showStatus("Exam Saved as a draft", 5000, "success", this);
          }
          if (res.emailToBeSent && res.emailToBeSent.length > 0) {
            this.emailSentNotificationArray = res.emailToBeSent
            this.emailNotificationSentModal = true
          } else {
            this.$router.push("./activityExams");
          }
          this.saveAsDraftLoader = false;
          // this.$router.push("./activityExams");
        } catch (err) {
          console.error(err);
          this.saveAsDraftLoader = false;
          showStatus("Error while saving as a draft", 9000, "error", this);
        }
      }
    },
    handleNotificationClose() {
      this.emailNotificationSentModal = false
      this.$router.push("./activityExams");
    },
    async getStudentsOfACourseYear() {
      this.allCourseYearStudents = [];
      try {
        this.allCourseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          });
      } catch (error) {
        this.allCourseYearStudents = [];
        this.courseYearDataLoader = false;
      }
      const studentUIds = this.allCourseYearStudents.map((item) => item.uId);
      this.allCourseYearStudentDetails =
        await this.userRepositoryInstance.getUsers({ uIds: studentUIds });
      for (
        let index = 0;
        index < this.allCourseYearStudentDetails.length;
        index++
      ) {
        const element = this.allCourseYearStudentDetails[index];

        this.examFormTableData.push({
          studentUId: element.uId,
          studentName:
            element.firstName + element.middleName + element.lastName,
          studentPrn: element.collegePRNNo,
          srNo: index + 1,
          examFormStatus: true,
          // studentRollNo:
        });
      }
    },
    // subject
    async getCourseOutcome(subject) {
      try {
        this.courseOutcomeData = [];
        this.COFromNba = [];
        this.nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: subject.subjectId,
        });
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.courseOutcomeData.push({
              Id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName,
              coText : this.instituteType==='Tier-1'? `CLO ${nba.CONumber} - ${nba.COName}` : `CO ${nba.CONumber} - ${nba.COName}`
            });
            this.sortByNumericValueInAscendingOrder(this.courseOutcomeData, 'coNumber')
            this.coNames.push(nba.COName);
            this.coNumbers.push(nba.CONumber);
          });
        }
        // this.assignedCoArray = this.courseOutcomeData
      } catch (err) {
        console.error(err);
      }
    },
    sortByNumericValueInAscendingOrder(array, fieldToSortBy) {
      array.sort((a, b) => a[fieldToSortBy] - b[fieldToSortBy]);
      return array
    },
    async getRoomName() {
      try {
        this.roomNames = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.selectedDepartment,
        };
        this.roomData =
          await this.infrastructureReferenceRepositoryInstance.getAllRoomsOfAInstituteAndDepartment(
            objToPush
          );
        this.roomData.map((room) => {
          this.roomNames.push({ roomName: room.roomName, roomId: room.roomId, batchSize: room.batchSize });
        });
        this.allRoom = this.roomNames;
      } catch (err) {
        console.error(err);
        this.locations = [];
      }
      if (this.roomNames.length === 0) {
        showStatus(`No rooms are added yet for this department.`, 9000, "error", this);
      }
    },
    async getSubjects() {
      this.subjects = [];
      this.subjectNames = [];
      try {
        this.subjects =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
            }
          );
        this.subjects.forEach((sub) => {
          this.subjectNames.push({
            subjectName: sub.subjectName,
            subjectId: sub.subjectId,
            subjectCode: sub.subjectCode,
          });
          this.allSubject.push(sub);
        });
        this.handleCourseState = true;
      } catch (err) {
        console.error(err);
      }
    },
    async getFaculties() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        };
        this.facultyList =
          await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute(
            objToPush
          );
        this.facultyList.forEach((faculty) => {
          this.facultyArray.push(faculty.uId);
        });
        const objForFullName = {
          uIds: this.facultyArray,
        };
        this.facultyNames =
          await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);
        this.allFaculty = this.facultyNames;
      } catch (err) {
        console.error(err);
      }
    },
    onSubjectExpansionPanelClick(e, sub) {
      if (
        !e.currentTarget.classList.contains("v-expansion-panel-header--active")
      ) {
        this.subjectPanel(sub);
      }
    },
    async subjectPanel(subject) {
      // applicableHeader: [
      //   {
      //     text: "Name",
      //     value: "fullName",
      //   },
      // ],
      if(this.reExam){
        if(this.applicableHeader.length > 2) {
          this.applicableHeader.splice(2)
        }
        this.applicableHeader.push(
        {
          text: "Internal",
          value: "internal",
        },
        {
          text: "External",
          value: "external",
        },
        )
      }
      this.subjectDetailsLoaded = false;
      // if (!subject.totalStudents) {
      await this.getStudents(subject);
      // }
      await this.getRoomName();
      await this.getFaculties();
      await this.handleDates();
      this.subjectDetailsLoaded = true;
    },
    handleDates() {
      this.subjectDetails.map((sub) => {
        if (sub.selectedDate !== undefined) {
          this.selectedDateArray.push(sub.selectedDate);
        }
      });
    },
    async getStudents(subject) {
      this.applicableStudents = []
      this.studentArray = [];
      this.allStudents = []
      this.studentNames = []
      // try {
      // const objOfStudent = {
      //   instituteId: this.selectedInstitute,
      //   semId: this.selectedSemester.semId,
      //   subjectId: subject.subjectId,
      // };
      // this.studentList =
      //   await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
      //     objOfStudent
      //   );this.examFormTableData
      const studentIds = this.examFormTableData.map((item) => {
        if (item.examFormStatus) return item.studentUId;
      });
      this.studentList = this.allCourseYearStudents.filter(
        (item) =>
          item.subjects.includes(subject.subjectId) &&
          studentIds.includes(item.uId)
      );
      this.studentList.forEach((student) => {
        this.studentArray.push(student.uId);
      });

      // this.studentNames =
      //   await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);
      const studentFullNames = [];
      this.allCourseYearStudentDetails.map((stu) => {
        if (this.studentArray.includes(stu.uId)) {
          let objToPush = {
            fullName: (stu.firstName + " " + stu.middleName + " " + stu.lastName).trim(),
            uId: stu.uId,
            prn: stu.collegePRNNo
          }
          if(this.reExam) {
            objToPush['internal'] = false
            objToPush['external'] = false
          }
          studentFullNames.push(objToPush);
        }
      });
      this.studentNames = studentFullNames;
      this.applicableStudents = studentFullNames;
      this.allStudents = this.applicableStudents;
      // } catch (err) {
      //   console.error(err);
      // }
    },
    handleUploadQuestionExcelDialog(subject) {
      this.uploadQuestionExcelDialog = true;
      this.subjectToUploadQuestions = subject;
    },
    async tempAddRoom(subject) {
      if (subject.roomStatus === true) {
        this.editRoomIndex = -1;
        this.selectedStudent = [];
        this.selectedFaculty = [];
        // let roomDta = subject.roomNames
        // roomDta.forEach((room,i)=> {
        //   if(room.roomId === this.allowedRoom){
        //     roomDta.splice(room, 1)
        //   }
        // })
        // subject.roomNames = roomDta
        subject.facultyNames = subject.facultyNames.filter(
          (item) => !this.selectedFacultyArray.includes(item.uId)
        );
        subject.applicableStudents = subject.applicableStudents.filter(
          (item) => !this.selectedStudentArray.includes(item.uId)
        );
      } else {
        subject["applicableStudents"] = this.applicableStudents;
        subject["facultyNames"] = this.allFaculty;
        subject["roomNames"] = this.allRoom;
      }
      this.allowedRoom = "";
      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.editingRoom = false;
      this.tempSelectedRoom = {
        roomNumber: this.roomAllocation.length + 1,
        roomName: "",
        appliedStudents: [],
        appliedFaculties: [],
      };
      this.allStudentList = subject;
      this.addNewRoomDialog = true;
    },
    handleRoomSelection() {
      const result = this.roomNames.filter(item => item.roomId === this.allowedRoom)[0]
      this.roomName = result.roomName
      this.maxCapacityOfOfTheRoom = Number(result.batchSize)
    },
    async saveTempRoom(subject) {
      this.selectedStudentArray = [];
      this.selectedFacultyArray = [];
      // console.log('this.selectedStudent', this.selectedStudent);
      // console.log('this.allowedRoom', this.allowedRoom);
      // this.roomNames.forEach((room) => {
      //   if (room.roomId === this.allowedRoom) {
      //     this.roomName = room.roomName;
      //     this.maxCapacityOfOfTheRoom = Number(room.batchSize)
      //   }
      // });
      if (this.selectedStudent.length <= this.maxCapacityOfOfTheRoom) {
        this.tempSelectedRoom.roomName = this.allowedRoom;

        this.selectedStudent.map((stud) => {
          this.selectedStudentArray.push(stud.uId);
        });
        this.tempSelectedRoom.appliedStudents = this.selectedStudentArray;

        this.selectedFaculty.map((faculty) => {
          this.selectedFacultyArray.push(faculty.uId);
        });
        this.tempSelectedRoom.appliedFaculties = this.selectedFacultyArray;

        if (this.editRoomIndex !== -1) {
          this.roomAllocation[this.editRoomIndex] = this.tempSelectedRoom;
          this.editRoomIndex = -1;
        } else {
          if (subject.applicableStudents.length > 0) {
            this.roomAllocation.push(this.tempSelectedRoom);
            subject["roomStatus"] = true;
          } else {
            showStatus(`No Students are remaining`, 9000, "error", this);
          }
        }
        this.addNewRoomDialog = false;
      } else {
        showStatus(`Maximum student limit of the room is exceeded.`, 9000, "error", this);
      }
      // showStatus(`Room added successfully.`, 2000, "success", this);
    },
    async editRoom(index, subject) {
      subject.roomNames = this.allRoom;
      subject.applicableStudents = this.applicableStudents;
      subject.facultyNames = this.allFaculty;

      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.studentNames.map((stud) => {
        this.roomAllocation[index].appliedStudents.map((obj) => {
          if (stud.uId === obj) {
            this.selectedStudent.push(stud);
          }
        });
      });
      this.facultyNames.map((faculty) => {
        this.roomAllocation[index].appliedFaculties.map((obj) => {
          if (faculty.uId === obj) {
            this.selectedFaculty.push(faculty);
          }
        });
      });
      this.tempSelectedRoom = {
        roomNumber: index,
        roomName: this.roomAllocation[index].allowedRoom,
        appliedStudents: this.roomAllocation[index].appliedStudents,
        appliedFaculties: this.roomAllocation[index].appliedFaculties,
      };
      this.editRoomIndex = index;
      this.addNewRoomDialog = true;
    },
    async openDeleteDailog(index, roomAllocation, subject) {
      this.indexValue = index;
      this.roomAllocationObject = roomAllocation;
      this.deleteSubject = subject;
      this.deleteDialog = true;
    },
    async deleteRoom(indexValue, roomAllocation, deleteSubject) {
      let subjectIndex = this.subjectDetails.indexOf(deleteSubject);
      this.allRoom.forEach((room) => {
        if (room.roomId === roomAllocation[indexValue].roomName) {
          roomAllocation[indexValue].roomName = "";
          deleteSubject.roomNames.push(room);
          deleteSubject["roomNames"] = deleteSubject.roomNames;
        }
      });
      //faculty
      this.allFaculty.forEach((faculty) => {
        roomAllocation[indexValue].appliedFaculties.forEach((selected, i) => {
          if (faculty.uId === selected) {
            this.selectedFacultyArray.map((element) => {
              if (element === selected) {
                this.selectedFacultyArray.splice(element, 1);
              }
            });
            if (!deleteSubject.facultyNames.includes(faculty)) {
              deleteSubject.facultyNames.push(faculty);
            }
          }
        });
      });
      //students
      this.allStudents.forEach((student) => {
        roomAllocation[indexValue].appliedStudents.forEach((selected, i) => {
          if (student.uId === selected) {
            this.selectedStudentArray.map((element) => {
              if (element === selected) {
                this.selectedStudentArray.splice(element, 1);
              }
            });
            if (!deleteSubject.applicableStudents.includes(student)) {
              deleteSubject.applicableStudents.push(student);
            }
          }
        });
      });
      this.subjectDetails[subjectIndex] = deleteSubject;
      roomAllocation.splice(indexValue, 1);
      this.deleteDialog = false;
    },
    async cancelRoom() {
      this.tempSelectedRoom = {};
      this.addNewRoomDialog = false;
    },
    async editSubject(subject) {
      this.applicableStudents = []
      this.selectedApplicablestudents = []
      subject.editSubjectDetails = false;
      this.studentNames.forEach((obj) => {
        subject.totalStudents.forEach((object) => {
          if (object === obj.uId) {
            if(subject.reExamStudentDetails) {
                const result = subject.reExamStudentDetails.filter(item => item.uId === obj.uId)[0]
                if(result) {
                  obj['internal'] = result.internal
                  obj['external'] = result.external
                }
              }
            this.applicableStudents.push(obj);
          }
        });
      });
      (this.selectedApplicablestudents = subject.totalStudents),
        (this.selectedDate = subject.selectedDate);
      (this.selectedTime = subject.selectedTime),
        (this.duration = subject.duration);
      this.totalMarks = subject.totalMarks ? subject.totalMarks : "";
      this.passingMarks = subject.passingMarks ? subject.passingMarks : "";
      this.roomAllocation = subject.roomAllocation;
    },
    async saveSubject(subject) {
      this.selectedApplicablestudents = [];
      let error = false;
      if (Number(this.passingMarks) > Number(this.totalMarks)) {
        error = true;
      } else {
        error = false;
      }
      if (!error) {
        try {
          this.selectedApplicablestudents = [];
          const tempArray = []
          this.applicableStudents.forEach((obj) => {
            this.selectedApplicablestudents.push(obj.uId);
            if(this.reExam){
              if(!tempArray.find((item) => item.uId === obj.uId)) {
                tempArray.push({
                  uId: obj.uId,
                  internal: obj.internal,
                  external: obj.external
                })
              }
            }
          });

          this.subjectDetails.forEach((subj) => {
            if (subj.subjectId === subject.subjectId) {
              subj.subjectId = subject.subjectId;
              subj.subjectName = subject.subjectName;
              subj.totalStudents = this.selectedApplicablestudents;
              if(this.reExam) {
                subj.reExamStudentDetails = tempArray;
              }
              subj.selectedDate = this.selectedDate;
              subj.selectedTime = this.selectedTime;
              subj.duration = this.duration;
              subj.totalMarks = this.totalMarks;
              subj.passingMarks = this.passingMarks;
              subj.roomAllocation = this.roomAllocation;
              subj.editSubjectDetails = true;
            }
          });
          this.editSubjectDetails = true;
          this.selectedApplicablestudents = [];
          this.selectedDate = "";
          this.selectedTime = "";
          this.duration = "";
          this.totalMarks = "";
          this.passingMarks = "";
          this.roomAllocation = [];
          this.applicableStudents = [];
        } catch (e) {
          console.error(e);
        }
        let result = this.subjectDetails.every(this.checkSubject);
        result
          ? (this.saveSubjectDetails = true)
          : (this.saveSubjectDetails = false);
      }
    },

    checkSubject(sub) {
      return sub.totalMarks ? true : false;
    },
    // question
    async questionPanel(subject) {
      this.selectedRadio = null;
      this.fetchAssignmentQuestions(subject); 
      this.maxMarks = 0;
      this.tempTotalMarks = 0;
      this.subjectDetails.map((sub) => {
        if (sub.subjectId === subject.subjectId) {
          this.tempTotalMarks = Number(sub.totalMarks);
          this.maxMarks = Number(sub.totalMarks);
        }
      });
      this.questionDetailsLoaded = false;
      this.assignedCoArray = [];
      this.assignedBloomsArray = [];
      this.allBlooms.map((bloom) => {
        this.assignedBloomsArray.push(bloom.shortCode);
      });
      let index = this.questionDetails.indexOf(subject);
      if (subject.editQuestionDetails === true) { //database
        this.overallThresholdPercentage = subject.overallThreshold;
        this.totalQuestions = subject.totalQuestions;
        this.questionsArray = subject.questions;
        this.questionSet = subject.questions;
        this.studentData = subject.studentData;
        if (subject.uploadQuestions === true) {
          this.toggleQuestionUpload = "uploadQuestions";
          this.uploadQuestionManually();
          this.saveQuestions(subject);
        } else {
          this.toggleQuestionUpload = "dontUploadQuestions";
          this.assignedCoArray = subject.assignedCo;
          this.assignedBloomsArray = subject.assignedBlooms;
          this.uploadQuestionManually();
          this.saveQuestions(subject);
        }
        if (subject.uploadMarksType === "aggregate") {
          this.toggleMarksUpload = "aggregate";
          this.uploadMarksManually(subject);
        } else if (subject.uploadMarksType === "individual") {
          this.toggleMarksUpload = "individual";
          this.uploadMarksManually(subject);
        }
      } else { //first time
        this.toggleMarksUpload = null;
        this.toggleQuestionUpload = "";
        this.assignedCo = [];
        this.overallThreshold = 0;
        this.totalQuestions = 0;
        this.questionsArray = [];
        this.questionSet = [];
        this.uploadQuestionManuallyStatus = false;
        this.editQuestionDetails = false;
        this.uploadMarksManuallyStatus = false;
        if (this.nbaCriteria === true) {
          if (Array.isArray(this.nbaAttainmentObject.thresholdPercentage)) {
            this.nbaAttainmentObject.thresholdPercentage.map((obj, i) => {
              if (obj.subjectId === subject.subjectId) {
                this.overallThresholdPercentage = obj.subjectThreshold;
              }
            });
          } else if (
            this.nbaAttainmentObject.typeOfExam === "cie" &&
            this.nbaAttainmentObject.thresholdType === "individualThreshold"
          ) {
            this.overallThresholdPercentage = 0;
          } else {
            this.overallThresholdPercentage = this.overallThresholdPercentage
              ? this.overallThresholdPercentage
              : 0;
          }
        } else {
          this.overallThresholdPercentage = 0;
        }
        let tempStudentData = [];
        this.questionDetails[index].studentData = [];
        this.studentNames.forEach((eachStudent, i) => {
          this.subjectDetails.forEach((stud, index) => {
            if (stud.subjectId === subject.subjectId) {
              stud.totalStudents.forEach((obj) => {
                if (obj === eachStudent.uId) {
                  const temp = {
                    studentName: eachStudent.fullName,
                    studentId: eachStudent.uId,
                    total: stud.totalMarks,
                    subjectName: stud.subjectName,
                    subjectId: stud.subjectId,
                    studentPrn: eachStudent.prn,
                    graceMarks: 0,
                    graceMarksSymbol : ''
                  };
                  tempStudentData.push(temp);
                }
              });
            }
          });
        });
        this.studentData = tempStudentData;
        // this.getCourseOutcome(subject)
        await this.getCourseOutcome(subject);
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.assignedCoArray.push(nba.CONumber);
          });
        }
        this.assignData(index, this.studentData);
      }
      this.noQuestionOverallThresholdPercentage = this
        .overallThresholdPercentage
        ? this.overallThresholdPercentage
        : 0;
      if (!subject.questions) {
        this.indivitualThresholdStatus = false;
        this.overallPercentageStatus = false;
      }
      if (this.overallThresholdPercentage) {
        this.overallPercentageStatus = true;
      }
      this.questionDetailsLoaded = true;
    },
    assignData(index, studentData) {
      this.questionDetails[index].studentData = studentData;
    },
    addNewQuestion() {
      const tempQuestion = {
        number: this.questionSet.length + 1,
        marks: "",
        indivitualThreshold: "",
        question: "",
        COArray: [],
        coDialog: false,
        bloomArray: [],
        bloomDialog: false,
      };
      this.questionsArray = [...this.questionsArray, tempQuestion];
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },
    deleteQuestion(index) {
      const temp = [...this.questionSet];
      temp.splice(index, 1);
      this.questionsArray = temp;
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },
    // toggleQuestionUploadFunc(subject, i){
    //   // this.questionDetails.push({ subjectName: subject.subjectName, subjectId: subject.subjectId })
    //   // this.questionDetails[i] = {}
    //   if (this.toggleMarksUpload === 'uploadQuestions') {
    //     delete subject.uploadQuestions
    //     delete subject.assignedCo
    //     delete subject.editQuestionDetails
    //     delete subject.uploadQuestions
    //     delete subject.overallThreshold
    //   } else {
    //     delete subject.questions
    //     delete subject.overallThreshold
    //     delete subject.totalQuestions
    //     delete subject.uploadQuestions
    //   }
    // },
    async saveQuestions(subject) {
      this.subjectDetails.map((sub) => {
        if (subject.subjectId === sub.subjectId) {
          this.tempTotalMarks = Number(sub.totalMarks);
        }
      });
      if (this.toggleQuestionUpload === "uploadQuestions") {
        let add = 0;
        let tempQuestionArr = this.prepareQuestionsArray(this.allQuestions);
        this.questionSet = tempQuestionArr[0].allQuestions;
        // this.questionSet.map((que) => {
        //   add += Number(que.marks);
        // });
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            if (add <= this.tempTotalMarks) {
              subj.uploadQuestions = true;
              (subj.subjectId = subject.subjectId),
                (subj.subjectName = subject.subjectName),
                (subj.overallThreshold = this.overallThresholdPercentage),
                (subj.totalQuestions = this.totalQuestions),
                (subj.questions = this.questionSet);
              subj.editQuestionDetails = true;
              if (this.toggleMarksUpload !== null) {
                subj.uploadMarks =
                  this.toggleMarksUpload === "individual"
                    ? "individual"
                    : "aggregate";
              }
              this.editQuestionDetails = true;
            } else {
              this.editQuestionDetails = false;
              showStatus(
                `Total of Marks should be Less than  ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
            }
          }
        });
      } else if (this.toggleQuestionUpload === "dontUploadQuestions") {
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            subj.uploadQuestions = false;
            subj.subjectId = subject.subjectId;
            subj.subjectName = subject.subjectName;
            subj.assignedCo = this.assignedCoArray;
            subj.assignedBlooms = this.assignedBloomsArray;
            subj.editQuestionDetails = true;
            subj.overallThreshold = this.noQuestionOverallThresholdPercentage;
            this.editQuestionDetails = true;
            if (this.toggleMarksUpload !== null) {
              subj.uploadMarks =
                this.toggleMarksUpload === "individual"
                  ? "individual"
                  : "aggregate";
            }
          }
        });
      }
      if (
        this.questionDetails[this.questionDetails.length - 1]
          .editQuestionDetails
      ) {
        this.publishExam = true;
      } else {
        this.publishExam = false;
      }
      this.handleOverallThreshold();
    },
    editQuestion(subject) {
      this.editQuestionDetails = false;
      this.questionsArray = [];
      if (subject.questions != undefined) {
        this.questionsArray = subject.questions;
      }
      this.uploadMarksManuallyStatus =
        this.uploadMarksManuallyStatus === true ? true : false;
    },
    uploadMarksManually(subject) {
      this.uploadMarksManuallyStatus = true;
      this.marksUpload(subject);
    },
    cancelQuestion(subject) {
      if (subject.questions === undefined) {
        this.questionsArray = [];
        this.questionSet = [];
        this.toggleCancelQuestionDialog = false;
      } else {
        this.questionsArray = subject.questions;
        this.questionSet = subject.questions;
        this.toggleCancelQuestionDialog = false;
      }
    },
    async marksCalculation(subject) {
      let total;
      this.subjectDetails.map((sub) => {
        if (subject.subjectId === sub.subjectId) {
          total = sub.totalMarks;
          this.tempTotalMarks = sub.totalMarks;
        }
      });
      if (this.overallPercentageStatus === true) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            if (Number(que.marks) > Number(total)) {
              showStatus(
                `Marks Should Be Less Than ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
              que.marks = "";
            } else {
              this.indivitualThresholdAvg =
                (que.marks / 100) * this.overallThresholdPercentage;
              que["indivitualThreshold"] = this.indivitualThresholdAvg;
            }
          });
        }
      }
      if (this.test) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            this.indivitualThresholdAvg = (que.marks / 100) * this.test;
            que["indivitualThreshold"] = this.indivitualThresholdAvg;
          });
        }
      }
    },
    async handleOverallThreshold() {
      this.overallPercentageStatus = true;
    },
    async handleIndivitualThreshold() {
      this.indivitualThresholdStatus = true;
    },
    async uploadQuestionManually() {
      this.uploadQuestionManuallyStatus = true;
      if (this.nbaCriteria === true) {
        if (
          this.nbaAttainmentObject.typeOfExam === "cie" &&
          this.nbaAttainmentObject.thresholdType === "overallThreshold"
        ) {
          this.overallPercentageStatus = true;
        } else if (
          this.nbaAttainmentObject.typeOfExam === "see" &&
          this.nbaAttainmentObject.thresholdType === "individualThreshold"
        ) {
          this.overallPercentageStatus = true;
        } else if (
          this.nbaAttainmentObject.typeOfExam === "see" &&
          this.nbaAttainmentObject.thresholdType === "overallThreshold"
        ) {
          this.overallPercentageStatus = true;
        }
      }
      // else {
      //   this.overallPercentageStatus = true
      // }
    },
    validateDate() {
      if (this.startDate > this.endDate) {
        showStatus(
          "End date cannot be ahead of start date.",
          9000,
          "error",
          this
        );
      }
    },
    async saveMarks(selectedStud) {
      let pattern = /^[0-9]*(\.[0-9]+)?$|^(NA|X|ABS|NE|W|F|SNG|P|NP)$/i
      let tempCount = 0;
      if (this.toggleMarksUpload === "individual") {
        for (let index = 0; index < this.questionSet.length; index++) {
          const element = this.questionSet[index];
          if (!isNaN(element.studentMarks)) {
            if (Number(element.studentMarks) > Number(element.marks)) {
              tempCount++;
            }
          } else {
            if (!pattern.test(element.studentMarks)) {
              tempCount++;
            }
          }
        }
        if (this.graceMarks > this.maxGraceMarks) {
          tempCount++;
        }
      } else if (
        this.toggleMarksUpload === "aggregate" &&
        this.toggleQuestionUpload === "dontUploadQuestions"
      ) {
        if (!isNaN(this.obtainedMarks)) {
          if (this.obtainedMarks > this.maxMarks) {
            tempCount++;
          }
        } else {
          if (!pattern.test(this.obtainedMarks)) {
            tempCount++;
          }
        }
        if (this.graceMarks > this.maxGraceMarks) {
          tempCount++;
        }
      } else if (this.toggleMarksUpload === "aggregate") {
        if (!isNaN(this.obtainedMarks)) {
          if (this.obtainedMarks > this.maxMarks) {
            tempCount++;
          }
        } else {
          if (!pattern.test(this.obtainedMarks)) {
            tempCount++;
          }
        }
        if (this.graceMarks > this.maxGraceMarks) {
          tempCount++;
        }
      }

      if (tempCount === 0) {
        if (this.toggleMarksUpload === "individual") {
          let subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          let totalObtained = 0;
          this.uploadMarksDialog = false;
          this.questionSet.map((que, i) => {
            student.map((data) => {
              if (data.studentId === selectedStud.studentId) {
                data["uploadMarksType"] = "individual";
                data[`Q ${i + 1}`] =
                  data.studentMarks === que.studentMarks
                    ? data.studentMarks
                    : que.studentMarks;
                totalObtained += Number(data[`Q ${i + 1}`]);
                data["obtainedMarks"] = totalObtained;
                data["graceMarks"] = this.graceMarks;
                data["graceMarksSymbol"] = this.graceMarksSymbol;
              }
            });
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "individual";
              }
            });
          });
          this.show = true;
        } else if (
          this.toggleMarksUpload === "aggregate" &&
          this.toggleQuestionUpload === "dontUploadQuestions"
        ) {
          let subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          student.map((data) => {
            if (data.studentId === selectedStud.studentId) {
              data["uploadMarksType"] = "aggregate";
              data["obtainedMarks"] = this.obtainedMarks;
              data["graceMarks"] = this.graceMarks;
              data["graceMarksSymbol"] = this.graceMarksSymbol;
            }
          });

          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        } else if (this.toggleMarksUpload === "aggregate") {
          let subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          let marks = 0;
          this.questionSet.map((que, i) => {
            student.map((data) => {
              if (data.studentId === selectedStud.studentId) {
                marks = this.obtainedMarks / this.questionSet.length;
                if (i + 1 !== this.questionSet.length) {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                } else {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                }
                data["obtainedMarks"] = Number(this.obtainedMarks);
                data["graceMarks"] = this.graceMarks;
                data["graceMarksSymbol"] = this.graceMarksSymbol;
              }
            });
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        }
      }
    },
    async editStudentMarks(item) {
      this.maxQuestionMarks = 0;
      this.obtainedMarks = 0;
      this.graceMarks = 0;
      this.graceMarksSymbol = "";
      if (this.toggleMarksUpload === "individual") {
        this.questionSet.map((que, i) => {
          if (item[`Q ${i + 1}`]) {
            que["studentMarks"] = item[`Q ${i + 1}`];
          } else {
            que["studentMarks"] = 0;
          }
        });
        this.uploadMarksDialog = true;
        this.selectedStud = item;
      } else if (this.toggleMarksUpload === "aggregate") {
        this.uploadOverallMarksDialog = true;
        this.selectedStud = item;
        if (this.selectedStud.obtainedMarks) {
          this.obtainedMarks = this.selectedStud.obtainedMarks;
        } else {
          this.obtainedMarks = 0;
        }
      }
      this.graceMarks = item.graceMarks ? item.graceMarks : 0
      this.graceMarksSymbol = item.graceMarksSymbol ? item.graceMarksSymbol : ""
    },
    async marksUpload(subject) {
      this.questionsArray = [];
      this.marksHeader = [];
      let header = [];
      header = [
        // {
        //   text: "",
        //   value: "action",
        //   align: "center",
        // },
        {
          text: "RN",
          value: "rollNo",
        },
        {
          text: "Student Name",
          value: "studentName",
        },
        {
          text: "PRN",
          value: "studentPrn",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Total Obtained Marks",
          value: "obtainedMarks",
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
        },
        {
          text: "Symbol for Grace Marks",
          value: "graceMarksSymbol",
        },
      ];
      if (subject.questions) {
        subject.questions.forEach((question, i) => {
          header.push({
            text: `Q ${i + 1}`,
            value: `Q ${i + 1}`,
          });
          question["questionNumber"] = `Q ${i + 1}`;
          question["studentMarks"] = "";
          this.subjectDetails.forEach((sub, index) => {
            if (sub.subjectId === subject.subjectId) {
              subject.marksHeader = header;
            }
          });
        });
      }
    },
    async createArray() {
      const subjectIds = this.subjectDetails.map((sub) => {
        return sub.subjectId;
      });
      const allSubjectIds = this.subjectAllocation.map((sub) => {
        return sub.subjectId;
      });
      this.subjectAllocation.map((sub) => {
        if (!subjectIds.includes(sub.subjectId)) {
          this.subjectDetails.push({
            subjectName: sub.subjectName,
            subjectId: sub.subjectId,
            subjectCode: sub.subjectCode,
          });
          this.questionDetails.push({
            subjectName: sub.subjectName,
            subjectId: sub.subjectId,
          });
        }
      });
      if (this.subjectAllocation.length !== this.subjectDetails.length) {
        this.subjectDetails.map((subject, i) => {
          if (!allSubjectIds.includes(subject.subjectId)) {
            this.subjectDetails.splice(i, 1);
          }
        });
        this.questionDetails.map((question, i) => {
          if (!allSubjectIds.includes(question.subjectId)) {
            this.questionDetails.splice(i, 1);
          }
        });
      }
    },
    async gotoNext() {
      let flag = false;
      if (this.endDate === "") {
        showStatus("End date cannot be empty.", 9000, "error", this);
        flag = true;
      }
      if (this.startDate === "") {
        showStatus("Start date cannot be empty.", 9000, "error", this);
        flag = true;
      }
      if (this.examName === "") {
        showStatus("Exam Name cannot be empty.", 9000, "error", this);
        flag = true;
      }
      if (this.selectedExamType === "") {
        showStatus("Exam Type cannot be empty.", 9000, "error", this);
        flag = true;
      }

      if (!flag) {
        if (this.nbaCriteria) {
          await this.nbaDetails();
        }
        await this.createArray();
        this.e1 = 2;
      }
    },
    async uploadQuestionExcel(subject) {
      let filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === subject.subjectId
      );
      if (this.questionExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.questionExcelFile);
        formData.append(
          "subjectTotalMarks",
          Number(filteredSubject[0].totalMarks)
        );
        let url =
          process.env.VUE_APP_SERVER_URL +
          process.env.VUE_APP_API_PORT +
          "/excel/exam/parse";
        axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            response.data.questionsArray.map((d) => {
              this.totalQuestions++;
              this.questionsArray.push({
                COArray: [],
                coDialog: false,
                bloomArray: [],
                bloomDialog: false,
                number: this.questionsArray.length + 1,
                marks: d.totalMarks,
                question: d.question,
                indivitualThreshold: this.overallThresholdPercentage
                  ? (d.totalMarks / 100) * this.overallThresholdPercentage
                  : "",
              });
            });
            this.questionSet = this.questionsArray;
            this.uploadQuestionExcelDialog = false;
          })
          .catch((error) => {
            if (
              error.response.data.customErrorMessage === "marksSumExceedError"
            ) {
              this.uploadQuestionExcelDialog = false;
              this.excelCustomError = true;
              this.excelCustomErrorMessage = `Cannot upload excel. Marks sum exceeding ${filteredSubject[0].totalMarks}.`;
            }
          });
      }
      this.questionExcelFile = "";
      this.questionsLoadedViaExcel = true;
    },
    async uploadMarksExcel() {
      let subject = this.subjectToUploadMarks;
      this.studentExceedingMarksLimit = [];
      let filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === subject.subjectId
      );
      this.marksLoadedViaExcel = true;
      if (this.marksExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.marksExcelFile);
        formData.append("marksUploadType", this.toggleMarksUpload);
        formData.append("questionsUploadType", this.toggleQuestionUpload);
        let url =
          process.env.VUE_APP_SERVER_URL +
          process.env.VUE_APP_API_PORT +
          "/excel/exam/parseMarks";
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/exam/parseMarks",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (response) => {
            // this.marksUpload(subject)
            // this.saveMarksViaExcel(response.data.studentData, subject)
            // this.uploadMarksExcelDialog = false
            await this.uploadMarksManually(subject);

            if (this.toggleMarksUpload === "aggregate") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];
                if (
                  element.obtainedMarks > Number(filteredSubject[0].totalMarks)
                ) {
                  this.studentExceedingMarksLimit.push(element.studentName);
                }
              }
            } else if (this.toggleMarksUpload === "individual") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];

                for (const [key, value] of Object.entries(element)) {
                  if (key.split(" ")[0] === "Q") {
                    let filteredQuestion = this.questionSet.filter(
                      (item) => item.questionNumber === key
                    )[0];

                    if (value > filteredQuestion.marks) {
                      if (
                        this.studentExceedingMarksLimit.indexOf(
                          element.studentName
                        ) === -1
                      ) {
                        this.studentExceedingMarksLimit.push(
                          element.studentName
                        );
                      }
                    }
                  }
                }
              }
            }
            this.uploadMarksExcelDialog = false;
            if (this.studentExceedingMarksLimit.length === 0) {
              this.saveMarksViaExcel(response.data.studentData, subject);
            } else {
              this.excelCustomError = true;
              this.excelCustomErrorMessage =
                "Cannot upload excel. Please recheck the marks of following students.";
            }
          });
      }
      this.marksExcelFile = "";
      this.marksLoadedViaExcel = false;
      this.uploadMarksManuallyStatus = true;
    },
    saveMarksViaExcel(studentData, sub) {
      studentData.map((st) => {
        if (st.studentPrn) {
          st.studentPrn = st.studentPrn + "";
        }
      });
      sub.studentData.map((stud) => {
        studentData.map((data, j) => {
          let marks;
          if (stud.studentPrn === data.studentPrn) {
            if (
              this.toggleQuestionUpload === "dontUploadQuestions" &&
              this.toggleMarksUpload === "aggregate"
            ) {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "aggregate" });
              Object.assign(sub, { uploadMarksType: "aggregate" });
            } else if (this.toggleMarksUpload === "individual") {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "individual" });
              Object.assign(sub, { uploadMarksType: "individual" });
            } else if (this.toggleMarksUpload === "aggregate") {
              this.questionSet.map((ques, i) => {
                if (data.obtainedMarks) {
                  data["obtainedMarks"] = data.obtainedMarks;
                  marks = data.obtainedMarks / this.questionSet.length;
                  if (i + 1 !== this.questionSet.length) {
                    data["uploadMarksType"] = "aggregate";
                    data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                  } else {
                    data["uploadMarksType"] = "aggregate";
                    data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                  }
                }
              });
              Object.assign(stud, data);
              Object.assign(sub, { uploadMarksType: "aggregate" });
            }
          }
        });
      });
    },
    gotoExams() {
      this.$router.push("activityExams");
    },
    toggleUploadMarksViaExcel(subject) {
      this.subjectToUploadMarks = subject;
      this.uploadMarksExcelDialog = true;

      if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "individual"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375313604________questionwise_individual_marks_sample.xlsx?alt=media&token=551218f8-7512-4b55-a4a1-32121f8748e8";
      } else if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375235224________questionwise_aggregate_marks_sample.xlsx?alt=media&token=57bb5196-ca79-40fb-9b2a-7d286be2ed7a";
      } else if (
        this.toggleQuestionUpload === "dontUploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669374075360________noquestions_overall_marks_sample.xlsx?alt=media&token=df16ebbc-5480-4375-a143-4eb893cfae6e";
      }
    },
    handleUploadQuestionDialog(subject) {
      this.uploadQuestionExcelDialog = true;
      this.subjectToUploadQuestions = subject;
    },
    validateQuestionMarks(question) {
      this.maxQuestionMarks = 0;
      this.maxQuestionMarks = question.marks;
    },
    async generateMarksSampleExcel() {
      let subject = this.subjectToUploadMarks;
      this.excelGenerated = true;
      subject["toggleMarksUpload"] = this.toggleMarksUpload;
      subject["toggleQuestionUpload"] = this.toggleQuestionUpload;

      try {
        const pdfBlob =
          await this.examStudentRepositoryInstance.generateStudentMarksExcel(
            subject
          );
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Marks Sample.xlsx");
        document.body.appendChild(link);
        link.click();
        this.excelGenerated = false;
      } catch (err) {
        console.error("error", err);
        showStatus("Error generating sample excel.", 9000, "error", this);
        this.excelGenerated = false;
      }
    },
  },
};
</script>
<style scoped src='./activityCreateExam.css'></style>
<style>
.custom-table th,
.custom-table td {
  border: 1px solid #dddddd !important;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2 !important;
}

.custom-table tr {
  border-bottom: 1px solid #dddddd !important;
}

.custom-table td {
  border-right: 1px solid #dddddd !important;
}
.quickTestCard {
  display: flex;
}
.navTab {
  margin: 0 10px;
  border-radius: 5px;
  color: #050991 !important;
  border: 1px solid #050991;
  width: 220px;
  padding: 10px;
  text-transform: capitalize;
}
.navTabActive {
  background-color: #050991;
  border-bottom: none;
  color: white !important;
}
.navTab1 {
  color: #050991 !important;
  border: 1px solid #050991;
  font-weight: 400;
  font-size: small;
  width: 200px;
  height: 42px;
  padding: 2px;
  text-transform: capitalize;
}
.navTab2 {
  margin: 0 4px;
  border-radius: 5px;
  color: #050991 !important;
  border: 1px solid #050991;
  width: 97px;
  height: 45px;
  padding: 4px;
  text-transform: capitalize;
}
.navTabActive2 {
  background-color: #050991;
  border-bottom: none;
  color: white !important;
}
.navTabActive1 {
  background-color: #050991;
  border-bottom: none;
  color: white !important;
}
.borderRadiusLeft {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.borderRadiusRight {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.rounded {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom-checkbox-label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
}
.center-container {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-line {
  border-top: 0.5px dotted #aaaaaa;
  margin: 0px;
  padding: 0;
  height: 1px;
  width: 100%;
}
.clickable-text {
  color: #2196f3;
  cursor: pointer;
  text-decoration: underline;
  color: #707070;
}
.icon-with-border {
  border: 1px solid #000;
  padding: 4px;
  border-radius: 4px;
}
.centered-tabs {
  display: flex;
  justify-content: center;
}
.selected-background {
  background: #d9d9d9;
}

.clickable-div {
  cursor: pointer;
}
.selected-background {
  background: #d9d9d9;
  transition: background 0.3s ease; 
}

.clickable-div {
  cursor: pointer !important;
  border-radius: 3px;
  padding: 3px;
}

.clickable-div:hover {
  background: #d9d9d9;
}
.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid #dddddd;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tr {
  border-bottom: 1px solid #dddddd;
}

.custom-table td {
  border-right: 1px solid #dddddd;
}
.download-sample-excel {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  color: #FF4F1F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.custom-radio {
  color: rgba(5, 13, 146, 1);
}
.dialog-content {
  padding-bottom: 0;
}

.sectionBackgrond {
  background-color: #9205050D;
}

.paddingWithBorder {
  padding: 5px;
  border-radius: 5px;
  border: 0.1px solid #FF4F1F;
  color: #FF4F1F;
  margin-left: 8px;
  cursor: pointer;
}
</style>
