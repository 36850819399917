<template src='./activityEditSemesterBatches.html'></template>
<script>
import inputContainer from '../../Components/inputContainer'
import SemesterRepository from '../../Repository/Semester'
import showStatus from '../../NetworkManager/showStatus'
import { v4 as uuidv4 } from 'uuid'
import SemesterUserRepository from "../../Repository/SemesterUser";
import UserRepository from "../../Repository/User";

export default {
  name: 'activityEditSemesterBatches',
  props: [
    'PropsSelectedSemester',
    'prop_selectedDepartment',
    'prop_selectedCourseYear',
    'prop_selectedDivision',
    'tree'
  ],
  components: {
    inputContainer
  },
  data() {
    return {
      assignedCourseYearForSubAdmin: [],
      batchesOfDivision: [],
      availableStudentData: [],
      studentData: [],
      deptNameArr: [],
      courseYearNameArr: [],
      divisionNameArr: [],
      assignedDepartment: [],
      semestersAllData: [],
      selectedBatchData: {},
      selectedDept: '',
      selectedBatchIndex: null,
      newBatchName: '',
      addBatch: false,
      showDivisionStudentDialog: false,
      deleteBatchFromSubject: false,
      selectedInstitute: '',
      selectedCourseYear: '',
      selectedDivision: '',
      loading: false,
      selectedSemester: '',
      availStudentsSearch: '',
      assignedStudentsSearch: '',
      assignedStudentForBatch: [],
      removeBatchFromStudent: [],
      items: [],
      selectedForAvailStudents: [],
      availableStudents: [],
      headers: [
        {
          text: "Student PRN",
          value: "collegePRNNo",
        },
        {
          text: "Student Name",
          value: "fullName",
        },
        {
          text: "Roll Number",
          value: "rollNumber",
        },
      ],
      selection: [],
      selectionType: 'leaf',
      tempBatchCount: 1,
      tableItems: [],
      headers: [
        {
          text: 'Roll Number',
          value: 'rollNumber'
        },
        {
          text: 'Full Name',
          value: 'fullName'
        },


      ]
    }
  },

  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Semester Batches')
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.PropsSelectedSemester
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []

    this.items = this.tree
    console.log('this.PropsSelectedSemester', this.PropsSelectedSemester);
    console.log('this.items', this.items);
    this.loading = false
    this.fetchDetails()
    this.fetchSemDetails()
  },
  methods: {
    gotoSemestersManagment() {
      this.$router.push({
        name: 'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
    openDeleteBatchDialog(data, i) {
      this.deleteBatchFromSubject = true
      this.selectedBatchData = data
      this.selectedBatchIndex = i

    },
    async removeBatchesFromDivision() {
      let obj = {
        instituteId: this.selectedInstitute,
        semId: this.PropsSelectedSemester.semId,
        department: this.selectedDept,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision,
        batchName: this.selectedBatchData.batchName,
        batchId: this.selectedBatchData.batchId
      }

      try {
        this.batchesOfDivision = []
        let info = await this.semesterRepositoryInstance.removeBatchesDetailsForDivision(obj)
        let getBatchesData = await this.semesterRepositoryInstance.getBatchesDetails(obj)
        this.batchesOfDivision = getBatchesData.batchesDetails
        if (this.assignedStudentForBatch.length > 0) {
          this.assignedStudentForBatch.map((stu) => {
            stu.batch = ''
          })
          let updatedSemUserData =
            await this.semesterUserRepositoryInstance.updateSemesterUser({
              bulkDataArray: this.assignedStudentForBatch,
            });

        }
        await this.semesterUserRepositoryInstance.removeBatchFromSubjectsAndTimetable(obj);
        this.deleteBatchFromSubject = false
      } catch (error) {
        console.log('error', error);
        this.deleteBatchFromSubject = false

      }
      this.selectedBatchData = {}
      this.selectedBatchIndex = null
    },
    filterStudentForSelectedBatch() {
      this.assignedStudentForBatch = []
      this.studentData.map((stu) => {
        if (stu.batch === this.selectedBatchData.batchName) {
          this.assignedStudentForBatch.push(stu)
        }
      })

    },
    async removeFromStudent() {
      this.removeBatchFromStudent.map((stu) => {
        stu.batch = ''
      })
      try {
        let updatedSemUserData =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.removeBatchFromStudent,
          });



        await this.getStudentForDivision()
        this.filterStudentForSelectedBatch()
        this.removeBatchFromStudent = []
      } catch (error) {
        console.log('error', error);
      }
    },
    async addBatchToStudents() {
      this.removeBatchFromStudent = []
      let studentWithBatch = []
      this.selectedForAvailStudents.map((stu) => {
        stu.batch = this.selectedBatchData.batchName
      })
      try {
        let updatedSemUserData =
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.selectedForAvailStudents,
          });
        await this.getStudentForDivision()
        this.filterStudentForSelectedBatch()
      } catch (error) {
        console.log('error', error);
      }
      this.showDivisionStudentDialog = false
      this.selectedForAvailStudents = []
    },
    batchSelected(data, i) {
      this.selectedBatchData = data
      this.selectedBatchIndex = i
      this.filterStudentForSelectedBatch()
    },
    addStudentDialogOpen() {
      this.showDivisionStudentDialog = true;
      this.selectedForAvailStudents = []
      this.availableStudentData = []
      // this.availableStudentData
      this.studentData.map((stu) => {
        if (stu.batch === '') {
          this.availableStudentData.push(stu)
        }
      })
    },
    async addBatchToDivision() {
      let obj = {
        instituteId: this.selectedInstitute,
        semId: this.PropsSelectedSemester.semId,
        department: this.selectedDept,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision,
        object: {
          batchName: this.newBatchName,
          batchId: uuidv4(),
        }
      }
      try {
        this.batchesOfDivision = []
        let info = await this.semesterRepositoryInstance.addBatchesDetailsForDivision(obj)
        let getBatchesData = await this.semesterRepositoryInstance.getBatchesDetails(obj)
        this.batchesOfDivision = getBatchesData.batchesDetails
        this.newBatchName = ''
        this.addBatch = false
      } catch (error) {
        console.log('error', error);
        this.newBatchName = ''
        this.addBatch = false
      }

    },
    openDialogCreateBatches() {
      this.addBatch = true
      this.newBatchName = ''
    },
    deptChange() {
      this.courseYearNameArr = []
      this.selectedCourseYear = ''
      this.divisionNameArr = []
      this.selectedDivision = ''
      this.selectedBatchData = {}
      this.selectedBatchIndex = null

      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.map((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDept) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYearNameArr = allowedCourseYearForSelectedDepartment
      }else {
        this.semestersAllData.map((sem) => {
        if (sem.department === this.selectedDept) {
          if (!this.courseYearNameArr.includes(sem.courseYear)) {
            this.courseYearNameArr.push(sem.courseYear)
          }
        }
      })
      }

    },
    courseYearChange() {
      this.divisionNameArr = []
      this.selectedDivision = ''
      this.selectedBatchData = {}
      this.selectedBatchIndex = null
      this.semestersAllData.map((sem) => {
        if (sem.department === this.selectedDept && sem.courseYear === this.selectedCourseYear) {
          if (!this.divisionNameArr.includes(sem.division)) {
            this.divisionNameArr.push(sem.division)
          }
        }
      })
    },
    async getStudentForDivision() {
      let obj = {
        instituteId: this.selectedInstitute,
        semId: this.PropsSelectedSemester.semId,
        department: this.selectedDept,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision
      }
      try {
        let student = await this.semesterUserRepositoryInstance.getStudentsOfADivision(obj)
        let uIds = []
        for (let i = 0; i < student.length; i++) {
          uIds.push(student[i].uId);
        }
        // console.log("getSemisterUsers", this.getSemisterUsers);
        const userObject = {
          uIds: uIds,
        };
        let getFullNamesOfUsers =
          await this.userRepositoryInstance.getFullNameOfUsers(userObject);
        getFullNamesOfUsers.map((data) => {
          student.map((stu) => {
            if (stu.uId === data.uId) {
              stu.fullName = data.fullName
            }
          })
        })
        this.studentData = student
      } catch (error) {
        console.log('error', error);
      }
    },
    async divisionChange() {
      this.studentData = []
      this.selectedBatchData = {}
      this.selectedBatchIndex = null

      this.batchesOfDivision = []
      // this.batchesOfDivision
      this.semestersAllData.map((sem) => {
        if (sem.semName === this.PropsSelectedSemester.semName && sem.department === this.selectedDept && sem.courseYear === this.selectedCourseYear && sem.division === this.selectedDivision) {
          console.log('sem', sem);
          this.batchesOfDivision = sem.batchesOfDivision
        }

      })
      await this.getStudentForDivision()

    },
    async fetchSemDetails() {

      try {
        let semDetails = await this.semesterRepositoryInstance.getClassbySemesters({
          instituteId: this.selectedInstitute
        });
        console.log('semDetails', semDetails);
        this.semestersAllData = semDetails.semesters

      } catch (error) {
        console.log('error', error);
      }


      this.semestersAllData.map((sem) => {
        if (sem.semName === this.PropsSelectedSemester.semName) {
          this.semIdForSelectedSem = sem.semId
          if (!this.deptNameArr.includes(sem.department)) {
            this.deptNameArr.push(sem.department)
          }
        }
      })
      //sub admin department access control
      let subAdminAssignedDepartmentWiseLecture = [];
      if (this.assignedDepartment.length > 0) {
        this.deptNameArr.map((data) => {
          if (this.assignedDepartment.includes(data)) {
            subAdminAssignedDepartmentWiseLecture.push(data);
          }
        });
        this.deptNameArr = subAdminAssignedDepartmentWiseLecture;
      }
      console.log('this.deptNameArr', this.deptNameArr);
    },

    async fetchDetails() {
      this.loading = true
      console.log(this.selection)
      this.tableItems = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      for (let i = 0; i < this.selection.length; i++) {
        const item = {
          department: this.selection[i].department,
          courseYear: this.selection[i].courseYear,
          division: this.selection[i].name,
          batches: ''
        }

        this.tableItems.push(item)

        objToPush.department = item.department
        objToPush.courseYear = item.courseYear
        objToPush.division = item.division

        try {
          item.batches = await this.semesterRepositoryInstance.getNumberOfBatchesOfADivisionInASemester(objToPush)
          console.log(item.batches)
          item.batches = item.batches.toString()
        } catch (err) {
          console.log(err)
        }
      }
      this.loading = false
      // console.log(this.tableItems)
    },

    async setNumberOfBatches() {
      this.loading = true
      console.log(this.selection)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      for (let i = 0; i < this.selection.length; i++) {
        objToPush.department = this.selection[i].department
        objToPush.courseYear = this.selection[i].courseYear
        objToPush.division = this.selection[i].name
        objToPush.numberOfBatches = parseInt(this.tempBatchCount)

        try {
          const numberOfBatches = await this.semesterRepositoryInstance.updateNumberOfBatchesOfADivisionOfASemester(objToPush)
          showStatus('Batch count updated successfully!', 500, 'success', this)
          const indexOfTableItem = this.tableItems.findIndex((item) => {
            return item.department === this.selection[i].department &&
              item.courseYear === this.selection[i].courseYear &&
              item.division === this.selection[i].name
          })

          if (indexOfTableItem > -1) {
            this.tableItems[indexOfTableItem].batches = numberOfBatches
          }
        } catch (err) {
          showStatus('Cannot connect to server!', 1500, 'error', this)
          console.log(err)
        }
      }
      this.loading = false
      // console.log(this.tableItems)
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: 'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
  },
  destroyed() {
    console.log('Destroyed')
  }
}
</script>
<style src='./activityEditSemesterBatches.css' scoped></style>
