<template src="./activityFacultyProfile.html"></template>

<script>
import VueApexCharts from "vue-apexcharts";
import SemesterRepository from '../../Repository/Semester';
import SemesterUserRepository from '../../Repository/SemesterUser'
import UserRepository from '../../Repository/User';
import TimetableRepository from '../../Repository/Timetable'
import SubjectsRepository from '../../Repository/Subject'
import apiV3 from "../../NetworkManager/apiV3";
import ExamSubjectRepository from "../../Repository/ExamSubject";
import TopicRepository from '../../Repository/Topic'
import LectureRepository from '../../Repository/Lecture'


export default {
    name: 'activityFacultyProfile',
    components: {
        apexcharts: VueApexCharts
    },
    data() {
        return {
            facultyDetailsLoader: false,
            examLoader: false,
            lectureLoader: false,
            topicLoader: false,
            absolute: true,
            arr: [],
            lectureObject: {},
            // totalTopicsPlanned: 0,
            totalTopicsImplemented: 0,
            totalTopics: 0,
            ordinaryLectureCount: 0,
            remedialLectureCount: 0,
            cancelledLectureCount: 0,
            nonConductedLectureCount: 0,
            conductedLectureCount: 0,
            lecturesList: [],
            topicsObject: {},
            lectureDetails: [],
            topics: [],
            lectureSubjectNameArray: [],
            averagePassing: 0,
            totalStudentsAppeared: 0,
            uniqueExams: [],
            averageStudent: [],
            totalExamCount: 0,
            passStudentCount: 0,
            examNameArray: [],
            averagePerformaceArray: [],
            totalOutOf: 0,
            marksObtained: 0,
            subjectWiseExams: [],
            totalExamConducted: 0,
            allExams: [],
            subjectExam: {},
            allowedStudentsForExam: [],
            examAnalayticsSubject: '',
            selectedExamAnalyticsSubjectId: '',
            selectedLetureStatisticsSubjectId: '',
            letureStatisticsSubject: '',
            subjectNameArray: [],
            subjectData: [],
            subjectIds: [],
            dataLoaded: false,
            facultyEmailAddress: '',
            facultyuserName: '',
            facultyContactNumber: '',
            facultyPostalAddress: '',
            facultyLoginId: '',
            facultyPersonalDetails: {},
            selectedFacultyUId: '',
            facultyObjects: [],
            facultyNameData: [],
            fullNamesOfFaculty: [],
            facultyIdArray: [],
            semesterData: [],
            semesterNames: [],
            semestersList: [],
            selectedSemesterName: '',
            selectedSemesterId: '',
            selectedFacultyName: '',
            instituteAllotmentTableHeader: ['Department ', 'Course Year', 'Division', 'Subject'],
            fromDate: '',
            toDate: '',
            checkbox: false,
            allLectureDetailHeader: [
                { text: 'Time', value: 'time', align: 'middle' },
                { text: 'Date', value: 'date', align: 'middle' },
                { text: 'Department', value: 'department', align: 'middle' },
                { text: 'Course Year', value: 'courseYear', align: 'middle' },
                { text: 'Division', value: 'division', align: 'middle' },
                { text: 'Conducted', value: 'conducted', align: 'middle' },
            ],
            allLectureDetailData: [],
            newAllLectureDetailData: [],
            allLectureDetailDataFilter: [],

            instituteAllotmentTableData: [],
            barSeries: [{
                name: '',
                data: []
            }],
            barOptions: {
                dataLabels: {
                    enabled: false
                },
                noData: {
                    text: "Topics not found.",
                    align: "center",
                    verticalAlign: "middle",
                    style: {
                        color: "red",
                        fontSize: "16px"
                    }
                },
                colors: ["#050992"],
                chart: {
                    type: "bar",
                    zoom: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "25%",
                        endingShape: "rounded",
                        borderRadius: 10,
                    }
                },
                yaxis: {
                    title: {
                        text: "No. of Topics",
                        style: {
                            color: '#8A8A9E',
                            fontSize: '11px'
                        }
                    }
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: true
                    }
                },
                toolbar: {
                    export: {
                        csv: {
                            filename: 'Planning and Feedback'
                        },
                        svg: {
                            filename: 'Planning and Feedback',
                        },
                        png: {
                            filename: 'Planning and Feedback',
                        }
                    }
                },
            },
            donutSeries: [],
            donutOptions: {
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                },
                noData: {
                    text: "Lectures not found.",
                    style: {
                        color: "red",
                        fontSize: "22px"
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    position: 'left',
                    formatter: function (donutSeries, opts) {
                        return [donutSeries, " - ", opts.w.globals.series[opts.seriesIndex]]
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            size: '50%'
                        }
                    }
                },
                labels: ['Conducted', 'Cancelled', 'Non Conducted'],
                colors: ['#050D92', '#ff0000', '#cecff0'],
                chart: {
                    type: "donut",
                    zoom: {
                        enabled: false
                    }
                },
            },
            lineSeries: [
                {
                    name: "Average Performance",
                    data: []
                },
                {
                    name: "Average Passing",
                    data: []
                }
            ],
            lineOptions: {
                markers: {
                    size: 3,
                },
                noData: {
                    text: "Exams not found.",
                    align: "center",
                    verticalAlign: "middle",
                    style: {
                        color: "red",
                        fontSize: "22px"
                    }
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: true
                    }
                },
                colors: ['#059292', '#050D92'],
                chart: {
                    type: "line",
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        export: {
                            csv: {
                                filename: 'Exam Analytics'
                            },
                            svg: {
                                filename: 'Exam Analytics',
                            },
                            png: {
                                filename: 'Exam Analytics',
                            }
                        }
                    },
                },
                stroke: {
                    width: 2
                }
            },
        }
    },
    async created() {
        this.$store.commit('liveData/set_selectedActivityName', 'Search Faculty')
        this.semesterRepositoryInstance = new SemesterRepository(this);
        this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
        this.userRepositoryInstance = new UserRepository(this)
        this.timetableRepositoryInstance = new TimetableRepository(this)
        this.subjectsRepositoryInstance = new SubjectsRepository(this)
        this.examSubjectRepositoryInstance = new ExamSubjectRepository(this);
        this.topicRepositoryInstance = new TopicRepository(this)
        this.lectureRepositoryInstance = new LectureRepository(this)


        this.getSemesterNames()
    },
    methods: {
        async getSemesterNames() {
            this.userData = this.$store.getters["user/get_userData"];
            this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
            this.semesterData = await this.semesterRepositoryInstance.getSemesterNames(
                {
                    instituteId: this.selectedInstitute,
                    uId: this.userData.uId
                }
            );
            this.semesterData.map((s) => {
                this.semesterNames.push(s.semName)
            })
        },

        selectSemester() {
            this.dataLoaded = false
            this.selectedFacultyName = ''

            this.semesterData.map((s) => {
                if (s.semName === this.selectedSemesterName) {
                    this.selectedSemesterId = s.semId
                }
            })
            this.getFacultyName()
        },

        async getFacultyName() {
            this.barSeries[0].data = []
            this.fullNamesOfFaculty = []
            this.facultyObjects = []
            this.facultyNameData = []
            this.facultyIdArray = []
            this.facultyObjects = await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute({
                instituteId: this.selectedInstitute,
                semId: this.selectedSemesterId
            })
            this.facultyObjects.map((faculty) => {
                this.facultyIdArray.push(faculty.uId)
            })
            this.facultyNameData = await this.userRepositoryInstance.getFullNameOfUsers({ uIds: this.facultyIdArray })
            this.facultyNameData.map((fac) => {
                this.fullNamesOfFaculty.push(fac.fullName)
            })
        },

        async getSelectedFacultyDetails() {
            this.examLoader = false
            this.topicLoader = false
            this.lectureLoader = false
            this.facultyDetailsLoader = false
            this.subjectIds = []
            this.subjectData = []
            this.subjectNameArray = []
            this.lectureSubjectNameArray = []
            this.instituteAllotmentTableData = []
            this.facultyNameData.map((item) => {
                if (item.fullName === this.selectedFacultyName) {
                    this.selectedFacultyUId = item.uId
                }
            })
            const facultyProfessionalDetails = await this.timetableRepositoryInstance.getAllocationsOfAFaculty({
                instituteId: this.selectedInstitute,
                semId: this.selectedSemesterId,
                uId: this.selectedFacultyUId
            })
            this.facultyPersonalDetails = await this.userRepositoryInstance.getUserProfile({ uId: this.selectedFacultyUId })
            this.facultyPersonalDetails.userName !== '' ? this.facultyuserName = this.facultyPersonalDetails.userName : this.facultyuserName = '- -'
            this.facultyPersonalDetails.email !== '' ? this.facultyEmailAddress = this.facultyPersonalDetails.email : this.facultyEmailAddress = '- -'
            this.facultyPersonalDetails.mobile !== '' ? this.facultyContactNumber = this.facultyPersonalDetails.mobile : this.facultyContactNumber = '- -'
            this.facultyProfilePictureUrl = this.facultyPersonalDetails.profilePicture
            facultyProfessionalDetails.map((faculty) => {
                this.subjectIds.push(faculty.subjectId)
            })
            this.subjectData = await this.subjectsRepositoryInstance.getSubjects({
                instituteId: this.selectedInstitute,
                semId: this.selectedSemesterId,
                subjectIds: this.subjectIds
            })
            this.subjectData.map((sub) => {
                this.subjectNameArray.push(sub.subjectName)
            })

            facultyProfessionalDetails.map((faculty) => {
                this.subjectData.map((subb) => {
                    if (subb.subjectId === faculty.subjectId) {
                        this.instituteAllotmentTableData.push(
                            {
                                allocatedDepartment: faculty.department,
                                allocatedCourseYear: faculty.courseYear,
                                allocatedSubject: subb.subjectName,
                                allocatedDivision: faculty.division,
                                subjectId: faculty.subjectId
                            }
                        )
                        this.lectureSubjectNameArray.push(subb.subjectName + ' | ' + faculty.division)
                    }
                })
            })
            this.facultyDetailsLoader = true
            this.dataLoaded = true

            this.letureStatisticsSubject = this.lectureSubjectNameArray[0]
            this.examAnalayticsSubject = this.subjectNameArray[0]
            this.donutSeries = []
            this.getTopicStatistics()
            this.getLectureStatisticsDetail()
            this.getExamStatistics()
        },
        async getTopicStatistics() {
            this.topicLoader = false
            this.totalTopics = 0
            this.totalTopicsImplemented = 0
            this.allLectureDetailDataFilter = []
            this.allLectureDetailData = []
            this.barOptions = {
                ...this.barOptions,
                xaxis: { categories: [] }
            }
            this.lecturesList = []
            this.barSeries[0].data = []
            this.donutSeries = []
            this.topics = []
            this.conductedLectureCount = 0
            this.nonConductedLectureCount = 0
            this.cancelledLectureCount = 0

            this.instituteAllotmentTableData.map((data) => {
                if (this.letureStatisticsSubject === data.allocatedSubject + ' | ' + data.allocatedDivision) {
                    this.topicsObject = {
                        instituteId: this.selectedInstitute,
                        semId: this.selectedSemesterId,
                        department: data.allocatedDepartment,
                        courseYear: data.allocatedCourseYear,
                        division: data.allocatedDivision,
                        subjectId: data.subjectId
                    }
                }
            })
            try {
                this.topics = await this.topicRepositoryInstance.getTopicsOfASubjectOfAFaculty(this.topicsObject)
                this.topics.map((topic) => {

                    this.totalTopics += 1
                    if (topic.isImplemented) {
                        this.totalTopicsImplemented += 1
                    }
                })
                this.arr = ['Total', 'Implemented']
                this.barOptions = {
                    ...this.barOptions,
                    xaxis: { categories: this.arr }
                }
                this.barSeries[0].data.push(this.totalTopics, this.totalTopicsImplemented)
                this.topicLoader = true
            } catch (error) {
                this.topicLoader = true
                this.barSeries[0].data = []
                console.log(error);
            }
        },
        async getLectureStatisticsDetail() {
            this.lectureLoader = false
            this.nonConductedLectureCount = 0
            this.conductedLectureCount = 0
            this.cancelledLectureCount = 0
            this.instituteAllotmentTableData.map((data) => {
                if (this.letureStatisticsSubject === data.allocatedSubject + ' | ' + data.allocatedDivision) {
                    this.lectureObject = {
                        instituteId: this.selectedInstitute,
                        semId: this.selectedSemesterId,
                        subjectId: data.subjectId,
                        uId: this.selectedFacultyUId
                    }
                }
            })

            try {
                this.lectureDetails = await this.lectureRepositoryInstance.getLecturesOfAFacultyOfASubject(this.lectureObject)

                this.lectureDetails.map((lec) => {
                    this.allLectureDetailData.push({
                        time: lec.dateTime.slice(11, 16),
                        date: lec.dateTime.slice(0, 10),
                        department: lec.department ? lec.department : '- -',
                        courseYear: lec.courseYear ? lec.courseYear : '- -',
                        division: lec.division ? lec.division : '- -',
                        conducted: lec.isConducted ? 'Conducted' : null,
                    })
                    if (lec.isConducted === true) {
                        this.conductedLectureCount += 1
                    } else if (lec.status === 6) {
                        this.cancelledLectureCount += 1
                    } else {
                        this.nonConductedLectureCount += 1
                    }
                })
                this.donutSeries.push(this.conductedLectureCount, this.cancelledLectureCount, this.nonConductedLectureCount)
                this.lectureLoader = true
            } catch (error) {
                this.lectureLoader = true
                this.donutSeries = []
                console.log(error);
            }
        },
        async getExamStatistics() {
            this.examLoader = false
            this.uniqueExams = []
            this.marksObtained = 0
            this.totalOutOf = 0
            this.passStudentCount = 0
            this.allowedStudentsForExam = []
            this.subjectExam = {}
            this.allExams = []
            this.examNameArray = []
            this.lineSeries[0].data = []
            this.lineSeries[1].data = []
            this.subjectWiseExams = []
            this.totalExamConducted = 0
            this.subjectData.map((sub) => {
                if (sub.subjectName === this.examAnalayticsSubject) {
                    this.selectedExamAnalyticsSubjectId = sub.subjectId
                }
            })
            try {
                this.subjectExam = await apiV3.getRequest("/exam/all", {
                    category: "quick_exam",
                    semId: this.selectedSemesterId,
                    subjectId: this.selectedExamAnalyticsSubjectId,
                });
                this.subjectExam.exams.map((res) => {
                    this.totalExamConducted += 1
                    res.allowedStudents.map((std) => {
                        if (this.allowedStudentsForExam.indexOf(std) === -1) {
                            this.allowedStudentsForExam.push(std)
                        }
                    })
                })
                this.allExams = await apiV3.postRequest("/exam/getResultsByAllowedStudents", { allowedStudents: this.allowedStudentsForExam, });
                this.allExams.data.map((exam) => {
                    this.subjectExam.exams.map((subEx) => {
                        if (exam.examId === subEx._id) {
                            this.subjectWiseExams.push({ ...exam, examName: subEx.name })
                        }
                    })
                })
                this.subjectWiseExams.map((e) => {
                    if (this.uniqueExams.indexOf(e.examId) === -1) {
                        this.uniqueExams.push(e.examId)
                    }
                })
                this.totalStudentsAppeared = this.subjectWiseExams.length
                this.uniqueExams.map((ue) => {
                    this.marksObtained = 0
                    this.totalOutOf = 0
                    this.passStudentCount = 0
                    this.subjectWiseExams.map((subExam) => {
                        if (this.examNameArray.indexOf(subExam.examName) === -1) {
                            this.examNameArray.push(subExam.examName)
                        }
                        if (ue === subExam.examId) {
                            this.marksObtained += parseInt(subExam.totalMarksObtained)
                            this.totalOutOf += parseInt(subExam.totalOutOf)

                            if (subExam.status === 'pass') {
                                this.passStudentCount += 1
                            }
                        }
                    })
                    this.averagePassing = ((this.passStudentCount / this.totalStudentsAppeared) * 100).toFixed(1)
                    this.averagePerformace = ((this.marksObtained / this.totalOutOf) * 100).toFixed(1)
                    this.lineSeries[0].data.push(this.averagePerformace)
                    this.lineSeries[1].data.push(this.averagePassing)
                })
                this.lineOptions = {
                    ...this.lineOptions,
                    xaxis: { categories: this.examNameArray }
                }
                this.examLoader = true
            } catch (error) {
                this.examLoader = true
                this.lineSeries[0].data = []
                this.lineSeries[1].data = []
                console.log(error);
            }
        },
        handleDateFilter() {
            if (this.toDate < this.fromDate) {
                alert("Please enter valid 'To Date'")
            } else {
                this.newAllLectureDetailData = this.allLectureDetailData
                this.allLectureDetailDataFilter = []
                this.newAllLectureDetailData.map((lec) => {
                    if (lec.date >= this.fromDate && lec.date <= this.toDate) {
                        this.allLectureDetailDataFilter.push({
                            time: lec.time,
                            date: lec.date,
                            department: lec.department ? lec.department : '- -',
                            courseYear: lec.courseYear ? lec.courseYear : '- -',
                            division: lec.division ? lec.division : '- -',
                            conducted: lec.isConducted ? 'Conducted' : null
                        })
                    }
                })
            }
        }
    }
}
</script>

<style scoped src="./activityFacultyProfile.css">

</style>