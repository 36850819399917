<template src='./activityManageSMS.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import SMSRepository from '../../Repository/SMS'
import UserRepository from '../../Repository/User'
import { convertFromYYYYMMDDToDate } from '../../Services/Utils/DateTimeFormatter'
export default {
  name: 'activityManageSMS',
  components: {
    inputContainer
  },
  data () {
    return {
      loadingSMS: false,
      selectedDate: '',
      sms: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'SMS',
          value: 'sms',
          align: 'center'
        },
        {
          text: 'Phone Number',
          value: 'phoneNumber'
        },
        {
          text: 'Delivered On',
          value: 'deliveryTime'
        }
      ]
    }
  },
  created () {
    const date = new Date()
    this.$store.commit('liveData/set_selectedActivityName', 'Manage SMS')
    this.selectedDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.smsRepositoryInstance = new SMSRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.sms = []
      this.loadingSMS = true

      try {
        this.sms = await this.smsRepositoryInstance.getSMSForDate({
          instituteId: this.selectedInstitute,
          dateOfSmsNeeded: this.convertToISOString(convertFromYYYYMMDDToDate(this.selectedDate))
        })
        console.log(this.sms)
      } catch (err) {
        console.log(err)
        this.sms = []
      }

      for (let i = 0; i < this.sms.length; i++) {
        this.sms[i].deliveryTime = new Date(parseInt(this.sms[i].deliveryTime))
      }

      this.loadingSMS = false

      const batchSize = 100

      const promises = []
      for (let i = 0; i < this.sms.length; i += batchSize) {
        promises.push(this.getFullNameOfUser(i, i + batchSize < this.sms.length ? i + batchSize : this.sms.length))
      }

      await Promise.allSettled(promises)
      this.sms = this.sms.slice()
    },
    async getFullNameOfUser (firstIndex, lastIndex) {
      console.log(firstIndex, lastIndex)
      try {
        const objToPush = {
          uIds: []
        }

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.sms[i].uId)
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(objToPush)

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find((elem) => elem.uId === this.sms[i].uId)
          this.sms[i].name = user ? user.fullName : ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    goToManageSMSUsers () {
      this.$router.push('/activitySMSUsers')
    },
    convertToISOString (date) {
      try {
        const convertedDate = new Date(date.valueOf())
        // IST is 5.30 hours ahead of UTC
        convertedDate.setHours(5, 30, 0, 0)
        return convertedDate.toISOString()
      } catch (err) {
        console.log(err)
        return undefined
      }
    }
  }
}
</script>

<style src='./activityManageSMS.css'>
</style>
