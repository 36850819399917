<template>
    <v-card class="margin:10px 10px">
        <v-card-title v-if="discussion && discussion.title">
            {{ discussion.title }}
        </v-card-title>
        <v-card-text style="color: #000">
            <v-row justify="center" align="center">
                <v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin: unset; font-size: 14px; color: #313131">
                        Created By
                    </p>
                    <p style="font-weight: bold; font-size: 18px; color: #313131">
                        {{ discussion.postedByFullname }} | {{
                            new
                                                Date(discussion.startDate).toLocaleString()
                        }}
                    </p>
                </v-col>
                <v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin: unset; font-size: 14px; color: #313131">
                        Total Participant
                    </p>
                    <p style="font-weight: bold; font-size: 18px; color: #313131">
                        {{ discussion.totalParticipants }}
                    </p>
                </v-col>
                <v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin: unset; font-size: 14px; color: #313131">
                        Total Responses
                    </p>
                    <p style="font-weight: bold; font-size: 18px; color: #313131">
                        {{ discussion.totalResponses }}
                    </p>
                </v-col><v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin: unset; font-size: 14px; color: #313131">
                        Open till
                    </p>
                    <p style="font-weight: bold; font-size: 18px; color: #313131">
                        {{ new Date(discussion.endDate).toLocaleString() }}
                    </p>
                </v-col>
            </v-row>
            <br />
            <p>{{ discussion.description }}</p>
            <br />
            <DiscussionDownloadList :attachedFiles="discussion.attachedFiles" :link="discussion.externalLink"
                :mediaLibrarySelects="discussion.mediaLibrarySelects" :isMobileView="true" />
            <br>
            <inputContainer style="height: unset; padding: 20px">
                <v-row justify="center" align="center">
                    <v-col :cols="isMobileView ? 12 : 6">
                        <v-card>
                            <v-card-text>
                                <span style="color: #313131">
                                    My Total Responses/Comments :</span>
                                <span style="color: #00128c; font-weight: bold; font-size: 19px">{{
                                    discussion.totalResponses
                                }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="isMobileView ? 12 : 6">
                        <v-card>
                            <v-btn block color="#FF4F1F" style="
                    height: 50px;
                    color: #fff;
                    text-transform: capitalize;
                    font-size: 19px;
                  " @click="showAddDialog = true"><v-icon style="margin-right: 5px">mdi-plus</v-icon>Add New
                                Response</v-btn>
                        </v-card>
                    </v-col>
                </v-row>
            </inputContainer>
            <br />
        </v-card-text>
    </v-card>
</template>
<script>
// import inputContainer from '../inputContainer.vue';
import moment from "moment"
import inputContainer from '../inputContainer.vue';
import DiscussionDownloadList from "./DiscussionDownloadList.vue";

export default {
    name: 'DiscussionListCard',
    components: { inputContainer, DiscussionDownloadList },
    props: {
        discussion: Object
    },
    data() {
        return {
            formattedStartDate: "",
            formattedEndDate: "",
            isMobileView: false
        }
    },
    created() {
        this.formattedStartDate = moment(this.discussion.publishDate).format("DD MMMM YYYY")
        console.log(this.formattedStartDate);
    },
    mounted() {
        this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    }
}
</script>
<style scoped>

</style>
