<template src="./activityAdmissionUser.html"></template>
<script>
import UserRepository from '../../Repository/User'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import AdmissionUserRepository from '../../Repository/AdmissionUser'
import SemesterUserRepository from '../../Repository/SemesterUser'
import semesterRepository from '../../Repository/Semester'
import MailSenderRepository from '../../Repository/EmailSender'
import auth from "@/Services/Firebase/auth";
var firestorage = null;

export default {
  name: 'activityAdmissionUser',
  components: {
    inputContainer
  },
  props: ['prop_user', 'prop_admission'],
  data() {
    return {
      senderMail: '',
      senderMailPassword: '',
      displayName: '',
      subjectDescription: '',
      subjectWise: true,
      profileImageUrl: '',
      brandingLogo: '',
      instituteStudentBrandingName: '',
      instituteStudentLogoLink: '',
      brandingName: '',
      firstName: '',
      imageButton: false,
      admissionFormActivity: {},
      admissionList: [],
      extraFormFields: [],
      divisionList: [],
      subjectsLists: [],
      selectedDivision: {},
      userObject: {},
      selectedAdmission: '',
      showToStudents: false,
      loading: false,
      divisionDialog: false,
      user: {},
      image: null,
      userAuths: [],
      fullName: '',
      uploadTask: '',
      imageName: '',
      subjects: ['Accounts', 'Maths', 'Eco', 'OCM', 'SP', 'German', 'French']
    }
  },
  created() {
    console.log("props", this.prop_user);
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new semesterRepository(this)
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.MailSenderRepositoryInstance = new MailSenderRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Admission Module')
    this.userData = this.$store.getters['user/get_userData']
    this.brandingName = this.$store.getters['liveData/get_brandingName']
    this.instituteStudentBrandingName = this.$store.getters['liveData/get_instituteStudentBrandingName']
    this.brandingLogo = this.$store.getters['liveData/get_brandingLogo']
    this.selectedInstitute = this.$store.getters[
      'liveData/get_admissionInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'liveData/get_admissionSemester'
    ]
    this.instituteStudentLogoLink = this.$store.getters['liveData/get_instituteStudentLogoLink']
    this.senderMail = this.$store.getters['liveData/get_senderMail']
    this.senderMailPassword = this.$store.getters['liveData/get_senderMailPassword']
    this.displayName = this.$store.getters['liveData/get_displayName']
    this.subjectDescription = this.$store.getters['liveData/get_subjectDescription']
    this.admissionList.push(this.prop_admission)
    this.selectedAdmission = this.prop_admission
    this.extraFormFields = this.prop_user.extraFormFields
    this.fetchData()
    this.sendWelcomeMail()
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        'state_changed',
        sp => {
          console.log(Math.floor((sp.bytesTransferred / sp.totalBytes) * 100))
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("this.payInstallmentDetails.paymentImage-->", downloadURL)
            this.user.image = downloadURL;
          })

        }
      )
    }
  },
  methods: {
    download(file) {
      window.location.href = file;
    },
    async sendWelcomeMail() {
      try {
        let objToPush = {
          senderMail: this.senderMail,
          senderMailPassword: this.senderMailPassword,
          displayName: this.displayName,
          subjectDescription: this.subjectDescription,
          brandingLogo: this.instituteStudentLogoLink,
          brandingName: this.instituteStudentBrandingName,
          admissionName: this.selectedAdmission.admissionName,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          emailId: this.user.emailId,
        }
        console.log('objToPush', objToPush);
        const res = await this.MailSenderRepositoryInstance.sendWelcomeMail(
          objToPush
        )
        console.log('MAIL RESPONSE', res)
      } catch (e) {
        console.log('err', e);
      }
    },
    async sendWhatsapp() {
      let mobileNo = this.user.mobileNumber
      let msg = `Hello ${this.user.firstName}+${this.user.lastName}%0aCongratulations! Your admission%0afor ${this.brandingName} has been confirmed!%0a %0aWe have sent further information%0a about how to get started on your %0aregistered email address.%0aWe hope you have a smooth onboarding process %0aand assure you growth, learning and insight!%0a%0a%0a Thanks and Regards,%0a${this.brandingName}`
      let response = await fetch(`http://wts.vision360solutions.co.in/api/sendText?token=6204a22c7f6a4eb2f0b0e3e7&phone=${mobileNo}&message=${msg}`)
      console.log('response', response);
    },
    async onFacultyLogoSelect(file) {
      await this.uploadFacultyLogo(file)
    },
    async uploadFacultyLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        if (size > 400) {
          this.imageButton = true;
          return
        }
        this.imageButton = false;
        this.imageName = file.name
        this.uploadTask = firestorage.ref('admissionImage/' + file.name).put(file);
      } else {
        this.imageButton = false;
        this.user.image = "";
      }
    },

    async fetchData() {
      const res = await this.admissionUserRepositoryInstance.getAdmissionDataForEdit({
        instituteId: this.selectedInstitute,
        semId: this.selectedAdmission.semId,
        admissionId: this.selectedAdmission.admissionId
      });
      if (res.admissionFormActivity.length) {
        this.admissionFormActivity = res.admissionFormActivity[0].formActivity
      }
      this.user = this.prop_user
      console.log('fetch data', this.user);
      this.loading = false
      try {
        this.userAuths = await this.userRepositoryInstance.getUserAuthenticationByEmail({ email: this.user.emailId }
        )
      } catch (err) {
        console.log(err)
        this.userAuths = []
      }
    },

    parseDate(dateOfBirth) {
      const parsedDate = new Date(dateOfBirth)
      return (
        parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getUTCDay()).slice(-2)
      )
    },

    removeFromArray(item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },

    dateConverter(fullDate) {
      console.log('dateString', fullDate);
      let date = new Date()
      const dateString = `${date.getDate(fullDate)}/${date.getMonth(fullDate)}/${date.getYear(fullDate)}`
      console.log('dateString', dateString);
      return dateString
    },

    //  dateConverter(dateYear) {
    //    let date = new Date(dateYear)
    //     const dateString = date.toISOString()
    //     return dateString
    //       .substring(0, 10)
    //       .split('-')
    //       .reverse()
    //       .join('/')
    //   },
    backToAdmissionHome() {
      this.$router.push({
        name: 'activityAdmissionNewUsers',
        params: {
          PropsSelectedAdmission: this.prop_admission
        }
      });
    },
    async addUserDetails() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedAdmission.semId,
        firstName: this.user.firstName,
        middleName: this.user.middleName,
        lastName: this.user.lastName,
        email: this.user.emailId,
        mobileNumber: this.user.mobileNumber,
        gender: this.user.gender,
        dob: this.user.dob,
        previousschoolName: this.user.previousschoolName,
        previousclassName: this.user.previousclassName,
        previouspercentage: this.user.previouspercentage,
        currentschoolName: this.user.currentschoolName,
        currentclassName: this.user.currentclassName,
      }
      try {
        const res = await this.admissionUserRepositoryInstance.addUserDetails(
          objToPush
        )
        this.userAuths = await this.userRepositoryInstance.getUserAuthenticationByEmail(
          {
            email: this.user.emailId
          }
        )
      } catch (err) {
        console.log(err)
        alert('Could not connect to server!')
      }
    },

    async getAlldiv() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedAdmission.semId
      }
      try {
        const res = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        console.log("This is my response", res)
        this.divisionList = res;

        // for(let i=0;i>=res.length;i++){
        //   this.divisionList.push(res[i].division)
        //   console.log("This is my div", res[i].division)
        // }
      }
      catch (e) {
        console.log(e)
        alert("Server Error")
      }
    },


    async createSemesterUser() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedAdmission.semId,
        uId: this.userAuths[0].uId,
        department: this.user.batches[0].department,
        courseYear: this.user.batches[0].courseYear,
        division: this.selectedDivision.division,
        batch: '',
        rollNumber: '',
        examSeatNumber: '',
        subjects: this.user.batches.map(batch => {
          return batch.subjectId
        })
      }

      try {
        const res = await this.semesterUserRepositoryInstance.createSemesterUser(
          objToPush
        )
        this.sendWhatsapp()
        alert('Confirmed')
        this.$router.push({
          name: 'activityAdmissionNewUsers',
          params: {
            PropsSelectedAdmission: this.prop_admission
          }
        });
      } catch (err) {
        console.log(err)
        alert('Could not connect to server!')
      }
    },

    async AddPaymentStatus(payments) {
      this.user.payments.forEach(admissionPayment => {
        if (admissionPayment.dueDate == payments.dueDate) {
          admissionPayment.status = 'paid'
        }
      });
      this.updateAdmissionUserPayment()
    },
    async updateAdmissionUserPayment() {
      let objToPush = {
        admissionUserId: this.user.admissionUserId,
        payments: this.user.payments
      }
      try {
        const res = await this.admissionUserRepositoryInstance.updateAdmissionUserPayment(
          objToPush
        )
      } catch (err) {
        console.log(err)
        alert('Could not connect to server!')
      }
    }
  }
}
</script>
<style src="./activityAdmissionUser.css" scoped></style>
