import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Timetable'

class Timetable {
  constructor (context) {
    this.context = context
  }
  async generateExcelTimetable(data) {
    console.log('front end');
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelTimetable, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async createTimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlot
  }
  
  async createSlotsInBulk (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSlotsInBulk, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async createDummyTimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createDummyTimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlot
  }

  async getTimetableSlotsForTimeAndForARoom (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableSlotsForTimeAndForARoom, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getTimetableSlotsForTimeAndForAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableSlotsForTimeAndForAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getTimetableOfADivisionInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }

  async getTimetableSlotsForCourceYearForGroupOfSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableSlotsForCourceYearForGroupOfSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }

  async getLastUpdatedTimeOfTimetable (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLastUpdatedTimeOfTimetable, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.updatedTime
  }

  async getFacultiesFreeInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesFreeInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.availableFaculties
  }

  async getLocationsFreeInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLocationsFreeInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.availableLocations
  }

  async updateFacultyInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.updateFacultyInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.uId
  }

  async updateRoomInATimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.updateRoomInATimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.roomId
  }

  async deleteTimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deleteTimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteTimetableSlotsInBulk (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.deleteTimetableSlotsInBulk, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deletedummyTimetableSlot (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.deleteRequest(ApiNames.deletedummyTimetableSlot, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getAllocationsOfAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableSlotsOfAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }

  async getTimetableSlotsOfAFacultyOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableSlotsOfAFacultyOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }
}

export default Timetable
