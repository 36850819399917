activityCourseAssignmentChecking
<template src="./activityCourseAssignmentChecking.html"></template>

<script>
import { database } from 'firebase';
import auth from '../../Services/Firebase/auth';
import SubjectsRepository from "../../Repository/Subject";
import CourseRepository from "../../Repository/courses";
import { spacesUrl } from "../../NetworkManager";
export default {
    name: "activityCourseAssignmentChecking",
    props: ["prop_subSection", 'prop_sections', 'prop_assignmentUser', "prop_user", "prop_selectedSectionIndex", "prop_selectedsubSectionIndex", "prop_selectedStudentIndex"],
    data() {
        return {
            checkbox: true,
            isSelecting: false,
            user: [],
            subSection: [],
            checkedAssignmentFile: [],
            uploadAdminsideAssignment: '',
            assignmentFeedback: '',
            checkbox: null,
            markChecked: '',
            progressUpload: 0,
            sections: [],
            studentSections: [],
            studentSubsection: {}
        }
    },
    watch: {
        uploadAdminsideAssignment: function () {
            this.uploadAdminsideAssignment.on('state_changed', sp => {
                this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
            },
                null,
                () => {
                    this.uploadAdminsideAssignment.snapshot.ref.getDownloadURL().then(downloadURL => {
                        this.uploadEnd = true
                        this.studentSubsection.checkedAssignmentFileUrl = downloadURL
                    })
                })
        }
    },
    created() {
        this.selectedSubject = this.$store.getters['liveData/get_selectedSubject']
        this.CourseRepositoryInstance = new CourseRepository(this);
        const firebase = auth.getFirebaseObject()
        this.firestorage = firebase.storage()
        this.$store.commit("liveData/set_selectedActivityName", "Courses");
        this.sections = this.prop_sections
        this.subSection = this.prop_subSection
        this.user = this.prop_assignmentUser
        this.getUserData()
    },
    methods: {
        getUserData() {
            this.studentSubsection = {}
            this.studentSections = []
            this.studentSections = this.user.sections
            this.user.sections.map((obj) => {
                if (obj.sectionTitle === this.sections[this.prop_selectedSectionIndex].sectionTitle) {
                    obj.subSections.map((obj1) => {
                        if (obj1.subsectionTitle === this.subSection.subsectionTitle) {
                            this.studentSubsection = obj1
                        }
                    })
                }
            })
            console.log('this.studentSubsection', this.studentSubsection);
        },
        async onButtonClick() {
            this.handleUploadCheckedAssignment(this.checkedAssignmentFile)
        },
        handleUploadCheckedAssignment() {
            this.fileName = this.checkedAssignmentFile.name
            this.uploading = true
            this.uploadAdminsideAssignment = this.firestorage.ref('checkedAssignment/' + new Date().valueOf().toString() + '________' + this.checkedAssignmentFile.name).put(this.checkedAssignmentFile)
        },
        async submitCheckedAssignment() {
            this.studentSections.map((obj) => {
                if (obj.sectionTitle === this.sections[this.prop_selectedSectionIndex].sectionTitle) {
                    obj.subSections.map((obj1) => {
                        if (obj1.subsectionTitle === this.subSection.subsectionTitle) {
                            obj1 = this.studentSubsection
                        }
                    })
                }
            })
            const objToPush = {
                "instituteId": this.selectedSubject.instituteId,
                "semId": this.selectedSubject.semId,
                "department": this.selectedSubject.department,
                "courseYear": this.selectedSubject.courseYear,
                "courseId": this.selectedSubject.subjectId,
                "uId": this.user.uId,
                "sections": this.studentSections
            }
            const res = await this.CourseRepositoryInstance.createStudentCourse(objToPush)
            console.log('res', res);
            this.goBack()
        },
        goBack() {
            this.$router.push({
                name: "activityCourseSubjectAnalysis",
                params: {
                    prop_subSection: this.prop_subSection,
                    prop_user: this.prop_user,
                    prop_sections: this.prop_sections,
                    prop_selectedSectionIndex: this.prop_selectedSectionIndex,
                    prop_selectedsubSectionIndex: this.prop_selectedsubSectionIndex
                }
            });
        },
    }
}

</script>

<style scoped src="./activityCourseAssignmentChecking.css"></style>
