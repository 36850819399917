<template src="./activityCreateTest.html"></template>
<script>
// import TimetableRepository from "../../Repository/timetable";
// import SubjectsRepository from "../../Repository/Subjects";
import { optionEnabled, subjectTypes } from "../../Constants/Utils/Statics";
import ExamRepository from "../../Repository/Exam";
import ExamSubjectRepository from "../../Repository/ExamSubject";
import ExamTimetableRepository from "../../Repository/ExamTimetable";
import UserRepository from "../../Repository/User";
import SemesterUserRepository from "../../Repository/SemesterUser";
import TopicsRepository from "../../Repository/Topic";
import {
  convertFromYYYYMMDDToDate,
  convertToISTTime,
} from "../../Services/Utils/DateTimeFormatter";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import XLSX from "xlsx";
import showStatus from "../../NetworkManager/showStatus";
import apiV3 from "../../NetworkManager/apiV3.js";
import UploadToDigitalOceanRepository from '../../Repository/uploadToDigitalOcean'
export default {
  name: "activityCreateTest",
  props: [
    "prop_selectedTest",
    "prop_subjectId",
    "prop_subjectName",
    "prop_selectedCourse",
    "prop_selectedDivision",
    "prop_selectedSemester",
    "prop_selectedDepartment",
    "prop_selectedInstitute",
    "prop_type",
    "prop_groupInfo"
  ],
  data() {
    return {
      examName: "",
      editExamIndex: "",
      topicDisplayName: "",
      selectedFile: null,
      selectedSubject: {},
      tempQuestion: {},
      examSubjects: [],
      indexToAdd: "",
      assignTopic: false,
      questions: [],
      selectedExam: null,
      checkbox: true,
      selected: [],
      optionEnabled: optionEnabled,
      selectAll: false,
      addingQuestion: false,
      listOfTopics: [],
      selectedTopics: [],
      steps: 1,
      isSelecting: false,
      defaultButtonText: "Import File",
      startDate: "",
      modal1: false,
      modal2: false,
      timeOfTest: 0,
      userData: null,
      selectedInstitute: "",
      selectedSemester: "",
      subjects: [],
      subjectTypes: subjectTypes,
      applicableUsers: [],
      selectedStudents: [],
      selectAllStudent: false,
      addingOption: false,
      currentUploadProgress: -1,
      headers: [
        {
          text: "Department | CourseYear | Division",
          value: "departmentCourseYearDivision",
          width: "30%",
        },
        {
          text: "Subject",
          value: "subjectName",
          width: "20%",
        },
        {
          text: "Lecture Type",
          value: "lectureType",
          width: "20%",
        },
        {
          text: "",
          value: "actions",
          width: "30%",
        },
      ],
      passingPercent: 0,
      isSavingExam: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "|",
            "undo",
            "redo"
          ],
          shouldNotGroupWhenFull: true
        }
      },
      description: "",
      solutionFileName: '',
      solutionFileLink: '',
      quetionImageLink: '',
      solutionImageLink: '',
      isSolutionFileUploaded: false,
      uploadSolutionImage: null,
      questionSolutionText: '',
      isreAttemptAllowed: false,
      solutionFile: '',
      questionImage: '',
      solutionImage: '',
      hovered: false,
      openDeleteDialogue: false,
      deleteIndex: null,
      imageVisible: false,
      solImageVisible: false,
    };
  },
  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
  },
  created() {
    this.editExamIndex = -1;
    this.userData = this.$store.getters["user/get_userData"];
    this.$store.commit("liveData/set_selectedActivityName", "Quick Test");
    this.uploadToDigitalOceanInstance = new UploadToDigitalOceanRepository(this)
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.examRepositoryInstance = new ExamRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.topicsRepositoryInstance = new TopicsRepository(this);
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this);
    this.examTimetableRepositoryInstance = new ExamTimetableRepository(this);
    // console.log(this.selectedSemester);
    this.selectedExam = this.$store.getters["liveData/get_selectedExam"];
    // console.log("this is selectedExam", this.selectedExam);
    this.initializeExamSubject();
    if (this.prop_selectedTest) {
      this.examName = this.prop_selectedTest.name
        ? this.prop_selectedTest.name
        : "";
      this.startDate = this.prop_selectedTest.startDate
        ? this.prop_selectedTest.startDate
        : "";
      this.timeOfTest = this.prop_selectedTest.duration
        ? this.prop_selectedTest.duration / 60
        : "";
      this.fetchExamSubjects();
    } else {
      this.getFullNamesOfInitialTest();
      //  let tempObject['applicableUsers'] = await this.getNamesOfUsers()
      //  (this.getApplicableUsers())
    }
    this.fetchSubjectTopic();
    // Call API to fetch subjects
  },
  methods: {
    deleteQuestion() {
      this.questions.splice(this.deleteIndex, 1);
      this.openDeleteDialogue = false;
    },
    async fetchExamSubjects() {
      const objToPush = {
        instituteId: this.selectedExam.instituteId,
        semId: this.selectedExam.semId,
        examId: this.selectedExam.examId,
      };
      // console.log("objToPush", objToPush);
      const topicObj = {
        topicName: "",
        topicId: "",
      };
      try {
        this.examSubjects = [];
        this.examSubjects =
          await this.examSubjectRepositoryInstance.getSubjectsForAnExam(
            objToPush
          );
        // console.log("this is examSubjects", this.examSubjects);
        for (let i = 0; i < this.examSubjects[0].questions.length; i++) {
          if (this.examSubjects[0].listOfTopics[i].topicId === "") {
            this.examSubjects[0].listOfTopics[i] = topicObj;
          }
        }
        this.questions = this.examSubjects[0].questions;
        // console.log("this is questions", this.questions);
      } catch (err) {
        console.log(err);
        this.examSubjects = [];
      }
      this.getNamesOfUsers(this.examSubjects[0]);
    },
    async fetchSubjectTopic() {
      const objToPush = {
        instituteId: this.prop_selectedInstitute,
        semId: this.prop_selectedSemester,
        subjectId: this.prop_subjectId,
      };
      // console.log("this is ibject for list of topics", objToPush);
      try {
        const listOfTopics =
          await this.topicsRepositoryInstance.getTopicsOfASubject(objToPush);
        const topicIdToTopicMapping = {};
        for (const topic of listOfTopics) {
          topicIdToTopicMapping[topic.topicId] = topic;
        }
        this.listOfTopics = Object.values(topicIdToTopicMapping);
        // console.log("this is listOfTopics", listOfTopics);
      } catch (err) {
        console.log(err);
      }
    },
    async getFullNamesOfInitialTest() {
      // console.log(
      //   "getFullNamesOfInitialTestgetFullNamesOfInitialTestgetFullNamesOfInitialTest"
      // );
      const tempObject = {
        applicableUsers: [],
      };
      tempObject.applicableUsers = await this.getApplicableUsers();
      // console.log("this is tempObject", tempObject);
      this.getNamesOfUsers(tempObject);
    },
    async getNamesOfUsers(selectedExamSubject) {
      this.applicableUsers = [];
      // console.log("Hello", selectedExamSubject);
      // let promises = [];
      // selectedExamSubject.applicableUsers.forEach((user) => {
      //   promises.push(
      //     this.userRepositoryInstance.getFullNameOfUser({ uId: user })
      //   );
      // });
      // promises = await Promise.allSettled(promises);
      // console.log(promises);
      // for (let i = 0; i < promises.length; i++) {
      //   this.applicableUsers[i] = {
      //     name: promises[i].value,
      //     uId: selectedExamSubject.applicableUsers[i],
      //   };
      // }
      // this.applicableUsers = this.applicableUsers.slice();
      const studentListArray1 = await this.userRepositoryInstance.getUsers({ uIds: selectedExamSubject.applicableUsers });
      const studentListArray = await this.semesterUserRepositoryInstance.getMultipleSemesterUsers({
        instituteId: this.prop_selectedInstitute,
        semId: this.prop_selectedSemester,
        uIds: selectedExamSubject.applicableUsers
      });

      const studentMap = new Map(studentListArray1.map(student => [student.uId, student]));

      studentListArray.forEach(student => {
        const studentInfo = studentMap.get(student.uId);
        if (studentInfo) {
          student.name = [studentInfo.firstName, studentInfo.middleName, studentInfo.lastName].filter(Boolean).join(" ");
          student.collegePRNNo = studentInfo.collegePRNNo;
        }
      });

      this.applicableUsers = studentListArray;
      this.loading = false;
      // this.applicableUsers = this.applicableUsers ? this.applicableUsers : this.getApplicableUsers()
    },
    async getDetailsOfSubject(index) {
      try {
        const objToPush = {
          instituteId: this.prop_selectedInstitute,
          semId: this.prop_selectedSemester,
          subjectId: this.subjects[index].subjectId,
        };
        const subject =
          await this.subjectsRepositoryInstance.getDetailsOfASubject(objToPush);
        // console.log(subject)
        this.$store.commit("mappings/add_subjectsMapping", subject);
        this.subjects[index].subjectName = subject.subjectName;
        this.subjects[index].lectureType =
          this.subjectTypes[subject.subjectType];
      } catch (err) {
        console.log(err);
      }
    },
    initializeExamSubject() {
      this.examSubjects = [
        {
          applicableUsers: [],
          criteria: {},
          examId: "",
          instituteId: "",
          isOnline: false,
          isPublished: false,
          listOfTopics: [
            {
              topicName: "",
              topicId: null,
            },
          ],
          numberOfQuestionsToShow: "",
          questions: [],
          semId: "",
          shuffle: "false",
          status: -1,
          subjectId: "",
          totalObtainableMarks: "",
        },
      ];
    },
    addQuestionStep1() {
      // console.log("this is selectedExam", this.selectedExamSubject);
      this.solutionImage = ''
      this.questionImage = ''
      this.tempQuestion = {
        questionNumber: this.questions.length + 1,
        question: "",
        type: "mcq",
        marks: 0,
        options: [],
        answer: "",
        quetionImageLink: "",
        questionSolutionImageLink: "",
        questionSolutionText: ""
      };
      this.addingQuestion = true;
    },
    async editQuestion(index) {
      this.tempQuestion = {
        questionNumber: index,
        question: this.questions[index].question,
        type: "mcq",
        marks: this.questions[index].marks,
        options: [...this.questions[index].options],
        answer: this.questions[index].answer.toString(),
        quetionImageLink: this.questions[index].quetionImageLink,
        questionSolutionImageLink: this.questions[index].questionSolutionImageLink,
        questionSolutionText: this.questions[index].questionSolutionText
      };
      for (let i = 0; i < this.tempQuestion.options.length; i++) {
        this.tempQuestion.options[i] = this.tempQuestion.options[i].toString();
      }
      // console.log("this is question", this.questions[index]);
      this.editExamIndex = index;
      // this.tempQuestion = this.questions[index]
      this.addingQuestion = true;
    },
    async saveQuestion() {
      // console.log("this is temp question", this.tempQuestion);
      const objToPush = {
        topicId: "",
        topicName: "",
      };
      if (this.editExamIndex !== -1) {
        // console.log("this is index to edit exams", this.editExamIndex);
        // console.log("this is temp queston", this.tempQuestion);
        // this.examSubjects[0].listOfTopics[this.examSubjects[0].listOfTopics[this.editExamIndex]] = objToPush
        this.examSubjects[0].questions[this.editExamIndex] = this.tempQuestion;
        this.editExamIndex = -1;
      } else {
        this.examSubjects[0].listOfTopics[
          this.examSubjects[0].listOfTopics.length
        ] = objToPush;
        this.examSubjects[0].questions[this.examSubjects[0].questions.length] =
          this.tempQuestion;
      }
      // console.log(
      //   "this is examSubjects questions",
      //   this.examSubjects[0].questions
      // );
      this.questions = [];
      this.questions = this.examSubjects[0].questions;
      // console.log(
      //   "this is examSubjects",
      //   this.examSubjects[0].listOfTopics[
      //     this.examSubjects[0].listOfTopics.length - 1
      //   ]
      // );
      this.addingQuestion = false;
    },
    onButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          // console.log("selected");
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    gotoNext() {
      this.steps++;
      const liId = "li" + this.steps;
      document.getElementById(liId).className = "active";
      this.convertMaths();
    },
    gotoBack() {
      const liId = "li" + this.steps;
      document.getElementById(liId).className = "";
      this.steps--;
      this.convertMaths();
    },
    convertMaths() {
      /* setTimeout(function() { */
      /*   MathJax.typeset(); */
      /* }, 600); */
    },
    async getApplicableUsers() {
      let semesterUsers
      const subjectObj = {
        instituteId: this.prop_selectedInstitute,
        semId: this.prop_selectedSemester,
        department: this.prop_selectedDepartment,
        courseYear: this.prop_selectedCourse,
        division: this.prop_selectedDivision,
        subjectId: this.prop_subjectId,
      };
      // console.log("subjectObj", subjectObj);
      if (this.prop_type === "Division" || this.prop_type === "Batch") {
      semesterUsers =
        await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfADivision(
          subjectObj
        );
      } else if (this.prop_type === "Group") {
        subjectObj.groupId = this.prop_groupInfo.groupId,
        subjectObj.groupName = this.prop_groupInfo.groupName
        semesterUsers =
          await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfAGroup(
            subjectObj
          );
      }
      // console.log("this is semesteruasaser", semesterUsers);
      return semesterUsers
        .map((semesterUser) => semesterUser.uId)
        .filter((uId) => uId);
    },
    async addTopic(index) {
      const objToPush = {
        topicId: this.selectedTopics[0].topicId,
        topicName: this.selectedTopics[0].topicName,
      };
      // console.log(objToPush);
      this.examSubjects[0].listOfTopics[this.indexToAdd] = objToPush;
      // console.log("finally this is this", this.examSubjects[0]);
      this.assignTopic = false;
      this.selectedTopics = [];
      // console.log(this.examSubjects[0].listOfTopics[index])
    },
    selectAllStudents(e) {
      this.selectedStudents = [];
      // console.log(this.selectAllStudent);
      if (this.selectAllStudent) {
        // console.log("this is applicable users", this.applicableUsers);
        this.applicableUsers.forEach((object) => {
          // console.log(object.uId);
          this.selectedStudents.push(object.uId);
        });
      }
    },
    async saveAsDraft() {
      if (this.prop_selectedTest) {
        await this.updateExam();
        this.gotoQuickTest();
      } else {
        await this.createExam();
        this.gotoQuickTest();
      }
    },
    async updateExam() {
      const criteria = {
        department: this.selectedSubject.department,
        courseYear: this.selectedSubject.courseYear,
        division: this.selectedSubject.division,
      };
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        name: this.examName,
        examId: this.prop_selectedTest.examId,
        description: " ",
        type: "Quick Test",
        startDate: convertToISTTime(
          convertFromYYYYMMDDToDate(this.startDate)
        ).toISOString(),
        endDate: convertToISTTime(
          convertFromYYYYMMDDToDate(this.startDate)
        ).toISOString(),
        criteria: criteria,
        resultLink: "",
      };
      // console.log("this is object to update test to test the level", objToPush);
      try {
        await this.examRepositoryInstance.update(objToPush);
        await this.updateSubject();
        await this.updateExamTimeTable();
      } catch (err) {
        console.log(err);
      }
    },
    async publishExam() {
      try {
        this.isSavingExam = true;
        const data = {
          name: this.examName,
          description: this.description,
          solutionFileUrl: this.solutionFileLink.url,
          isreAttemptAllowed: this.isreAttemptAllowed,
          subjectName: this.prop_subjectName,
          durationInMins: parseInt(this.timeOfTest),
          subjectId: this.prop_subjectId,
          semId: this.prop_selectedSemester,
          // facultyId: this.userData.uId,
          passingPercent: parseFloat(this.passingPercent),
          dateTimeUTC: new Date(this.startDate).toISOString(),
          allowedStudents: [...this.selectedStudents],
          status: "published",
          category: "quick_exam",
          questions: [...this.questions],
        };
        let data2;

        if (this.prop_type === "Group") {
          data2 = {
            ...data,
            assignedGroupForSubject: {
              groupName: this.prop_groupInfo.groupName,
              groupId: this.prop_groupInfo.groupId
            }
          };
          // console.log('this.prop_type', data2)
        } else {
          data2 = {
            ...data,
            division: this.prop_selectedDivision
          };
        }
        await apiV3.postRequest("/exam/create", data2);
        setTimeout(() => this.gotoQuickTest(), 1200);
        showStatus("Saved successfully", 1200, "success", this);
      } catch (e) {
        console.error(e);
        if (e.response.status === 409) {
          showStatus(e.response.data.message, 3000, "error", this);
          return;
        }
        showStatus("An error occured, try later", 3000, "error", this);
      } finally {
        this.isSavingExam = false;
      }
      /* let selectedExamId = ""; */
      /* if (this.prop_selectedTest) { */
      /*   selectedExamId = this.prop_selectedTest.examId; */
      /* } else { */
      /*   selectedExamId = await this.createExam(); */
      /* } */
      /* const objToPush = { */
      /*   instituteId: this.selectedInstitute, */
      /*   semId: this.selectedSemester.semId, */
      /*   examId: selectedExamId */
      /* }; */
      /* console.log("this is to publishExam", objToPush); */
      /* try { */
      /*   await this.examRepositoryInstance.publishExam(objToPush); */
      /*   this.gotoQuickTest(); */
      /* } catch (err) { */
      /*   console.log(err); */
      /* } */
    },
    upperCase(queType) {
      return queType.toUpperCase();
    },
    async createExamTimeTable(createExamObject) {
      try {
        // console.log("we are inside createExamTimeTable", createExamObject);
        const objToPush = {
          instituteId: this.prop_selectedInstitute,
          semId: this.prop_selectedSemester,
          examId: createExamObject.examId,
          startTime: "",
          duration: this.timeOfTest * 60,
          subjectId: this.prop_subjectId,
          facultiesAssigned: [this.userData.uId],
          allocatedStudents: this.selectedStudents,
          location: "",
          date: convertToISTTime(
            convertFromYYYYMMDDToDate(this.startDate)
          ).toISOString(),
        };
        // console.log("this is timetbale object push", objToPush);
        await this.examTimetableRepositoryInstance.createExamTimetable(
          objToPush
        );
      } catch (err) {
        console.log(err);
        showStatus("Could not update", 1000, "error", this);
      }
    },
    async updateExamTimeTable() {
      try {
        const objToPush = {
          instituteId: this.prop_selectedInstitute,
          semId: this.prop_selectedSemester,
          examId: this.prop_selectedTest.examId,
          startTime: "",
          duration: this.timeOfTest * 60,
          subjectId: this.prop_subjectId,
          facultiesAssigned: [this.userData.uId],
          allocatedStudents: this.selectedStudents,
          location: "",
          date: convertToISTTime(
            convertFromYYYYMMDDToDate(this.startDate)
          ).toISOString(),
        };
        // console.log("this is timetbale object push", objToPush);
        await this.examTimetableRepositoryInstance.updateExamTimetableForASubject(
          objToPush
        );
      } catch (err) {
        console.log(err);
        showStatus("Could not update", 1000, "error", this);
      }
    },
    async addSubject(createExamObject) {
      const objToPush = {
        instituteId: this.prop_selectedInstitute,
        semId: this.prop_selectedSemester,
        examId: createExamObject.examId,
        criteria: {
          department: this.prop_selectedDepartment,
          courseYear: this.prop_selectedCourse,
          division: this.prop_selectedDivision,
        },
        subjectId: this.prop_subjectId,
        totalMarks: 0,
        isOnline: false,
        listOfTopics: this.examSubjects[0].listOfTopics,
        questions: this.examSubjects[0].questions,
        numberOfQuestionsToShow: this.examSubjects[0].questions.length,
        totalObtainableMarks: await this.gettotalObtainableMarks(),
        shuffle: false,
      };
      // console.log("This is an object to be pushed");
      // console.log(objToPush, this.selectedSubject);
      try {
        await this.examSubjectRepositoryInstance.createExamSubject(objToPush);
      } catch (err) {
        console.log(err);
      }
    },
    totalMarks() {
      if (this.questions.length > 0) {
        return this.questions.reduce((sum, question) => sum + question.marks, 0);
      } else return 0
    },
    async updateSubject() {
      const objToPush = {
        instituteId: this.prop_selectedInstitute,
        semId: this.prop_selectedSemester,
        examId: this.prop_selectedTest.examId,
        criteria: {
          department: this.prop_selectedDepartment,
          courseYear: this.prop_selectedCourse,
          division: this.prop_selectedDivision,
        },
        subjectId: this.prop_subjectId,
        totalMarks: 0,
        isOnline: false,
        listOfTopics: this.examSubjects[0].listOfTopics,
        questions: this.examSubjects[0].questions,
        numberOfQuestionsToShow: this.examSubjects[0].questions.length,
        totalObtainableMarks: await this.gettotalObtainableMarks(),
        shuffle: false,
        applicableUsers: this.selectedStudents,
      };
      // console.log("This is an object to be pushed");
      // console.log(objToPush, this.selectedSubject);
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(objToPush);
      } catch (err) {
        console.log(err);
      }
    },
    async addInBulkaddQuestionStep1(obj) {
      this.files = [];
      this.tempQuestion = {
        questionNumber: this.questions.length + 1,
        question: obj.question,
        marks: obj.marks,
        type: "mcq",
        options: [...obj.options],
        answer: obj.answer,
        quetionImageLink: obj.quetionImageLink,
        questionSolutionImageLink: obj.questionSolutionImageLink,
        questionSolutionText: obj.questionSolutionText
      };
      // this.addingQuestion = true
      this.downloadURL = [];
      this.images = [];
      this.others = [];
      this.uploadCount = 0;
      await this.saveQuestion();
      // await this.addQuestion()
    },
    async addInBulk(jsonObject) {
      for (var i = 0; i < jsonObject.length; i++) {
        var obj = jsonObject[i];
        // console.log(obj);
        await this.addInBulkaddQuestionStep1(obj);
      }
      this.gotoNext();
      // dfdf
    },
    async onFileChanged() {
      // this.selectedFile = e.target.files[0];
      // var formData = new FormData();
      // var imagefile = document.querySelector("#sampleFile");
      // formData.append("sampleFile", imagefile.files[0]);
      // console.log(">> formData >> ", formData);
      // axios
      //   .post("https://xlsprocessapp.herokuapp.com/upload", formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data"
      //     }
      //   })
      //   .then(
      //     response => {
      //       console.log(response.data);
      //       this.addInBulk(response.data);
      //     },
      //     error => {
      //       console.log(error);
      //     }
      //   )
      //   .then(() => {
      //     this.isSelecting = false;
      //   });
      // do something
      this.isSelecting = true;
      const self = this;
      //Reference the FileUpload element.
      var fileUpload = document.getElementById("fileUpload");

      //Validate whether File is valid Excel file.
      // var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
      // if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof FileReader !== "undefined") {
          var reader = new FileReader();
          //For Browsers other than IE.
          if (reader.readAsBinaryString) {
            reader.onload = function (e) {
              self.ProcessExcel(e.target.result);
            };
            reader.readAsBinaryString(fileUpload.files[0]);
          } else {
            //For IE Browser.
            reader.onload = function (e) {
              var data = "";
              var bytes = new Uint8Array(e.target.result);
              for (var i = 0; i < bytes.byteLength; i++) {
                data += String.fromCharCode(bytes[i]);
              }
              self.ProcessExcel(data);
            };
            reader.readAsArrayBuffer(fileUpload.files[0]);
          }
        } else {
          alert("This browser does not support HTML5.");
        }
      // } else {
      //   alert("Please upload a valid Excel file.");
      // }
      this.isSelecting = false;
    },

    async ProcessExcel(data) {
      //Read the Excel File data.
      var workbook = XLSX.read(data, {
        type: "binary"
      });

      // console.log(workbook.Sheets[workbook.SheetNames[0]]);
      const range = workbook.Sheets[workbook.SheetNames[0]]["!ref"];
      const values = workbook.Sheets[workbook.SheetNames[0]];

      // console.log("workbook", workbook);

      const arr = range.split(":")[1].split("");
      // console.log('arr12', arr)
      arr.splice(0, 1);
      const endIndex = parseInt(arr.join(""));
      // console.log("endIndex", endIndex);

      const parsedData = [];

      for (let i = 2; i < endIndex + 1; i++) {
        const questionKey = "A" + i;
        const option1Key = "B" + i;
        const option2Key = "C" + i;
        const option3Key = "D" + i;
        const option4Key = "E" + i;
        const option5Key = "F" + i;
        const option6Key = "G" + i;
        const marksKey = "H" + i;
        /* const negativeMarksKey = "G" + i; */
        const answerKey = "J" + i;
        const question = values[questionKey]?.v;
        const option1 = values[option1Key]?.v;
        const option2 = values[option2Key]?.v;
        const option3 = values[option3Key]?.v;
        const option4 = values[option4Key]?.v;
        const option5 = values[option5Key]?.v;
        const option6 = values[option6Key]?.v;
        // const marks = values[marksKey].v;
        /* const negativeMarks = values[negativeMarksKey].v; */
        const answer = values[answerKey].v;
        const obj = {
          question: question,
          options: [],
          /* NegativeMarks: negativeMarks, */
          marks:
            typeof values[marksKey].v === "number" ? values[marksKey].v : 0,
          answer: answer
        };
        const options = [option1, option2, option3, option4, option5, option6];

        for (const option of options) {
          if (option) {
            obj.options.push(option);
          }
        }
        parsedData.push(obj);
      }
      await this.addInBulk(parsedData);
      this.showUploadResultDialog = false;
      this.file = null;
      this.isSelecting = false;
      // console.log("parsedData", parsedData);
    },
    gotoQuickTest() {
      this.$router.push({
        name: "activityQuickTest",
      });
    },
    async gettotalObtainableMarks() {
      let total = 0;
      for (let i = 0; i < this.examSubjects[0].questions.length; i++) {
        // console.log(
        //   "this is temp",
        //   parseInt(this.examSubjects[0].questions[i].mark.plus)
        // );
        total =
          parseInt(total) +
          parseInt(this.examSubjects[0].questions[i].mark.plus);
        // console.log("this is total marks", total);
      }
      return total;
    },
    async handleAddSolutionFile(e) {
      try {
        if (e) {
          this.isSolutionFileUploaded = true
          this.currentUploadProgress = -1
          const link = await this.uploadToDigitalOceanInstance.upload([e], this.userData.uId);
          this.solutionFileLink = link[0];
          this.currentUploadProgress = 100
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleAddQuestionImageForQuiz(e) {
      try {
        if (e) {
          this.isSolutionFileUploaded = true
          this.currentUploadProgress = -1
          const link = await this.uploadToDigitalOceanInstance.upload([e], this.userData.uId);
          this.tempQuestion.quetionImageLink = link[0].url;
          this.imageVisible = true
          this.currentUploadProgress = 100
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleAddSolutionImageForQuiz(e) {
      try {
        if (e) {
          this.isSolutionFileUploaded = true
          this.currentUploadProgress = -1
          const link = await this.uploadToDigitalOceanInstance.upload([e], this.userData.uId);
          this.tempQuestion.questionSolutionImageLink = link[0].url;
          this.solImageVisible = true
          this.currentUploadProgress = 100
          // console.log('this.tempQuestion', this.tempQuestion)
        }
      } catch (error) {
        console.log('error', error)
      }
    },
  },
};
</script>
<style src="./activityCreateTest.css"></style>