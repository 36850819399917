<template>
  <div>
  <router-view class='main'></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}

</script>
<style scoped>
</style>
