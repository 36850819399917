import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Exam'

class Exam {
  constructor (context) {
    this.context = context
  }

  async createExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.createExam, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getExamById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exam
  }

  async getAllExams (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getAllExams, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }

  async getExamsOfACriteria (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamsOfACriteria, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }

  async update (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.update, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async publishExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.publishExam, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getDetailsOfAnExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getDetailsOfAnExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exam
  }

  async getAnalysisOfAnExamForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getAnalysisOfAnExamForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examAnalysis
  }

  async getExams (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const examApiKey = this.context.$store.getters['instituteData/get_examApiKey']
    const apiKey = examApiKey
    networkManager.setHeader({ apiKey })
    const response = await networkManager.postRequest(ApiNames.getExams, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default Exam
