<template src="./feedbackSubmitted.html"></template>

<script>
export default {
    name: "feedbackSubmitted",
    props: ["prop_subSection", "prop_selectedUser", 'prop_sections', "prop_user", "prop_selectedSectionIndex", "prop_selectedsubSectionIndex", "prop_selectedStudentIndex"],
    data() {
        return {
            subSection: {},
            user: [],
            userSection: {},
            userSubsection: {},
            feedbackArr: []
        }
    },
    created() {
        this.subSection = this.prop_subSection
        this.user = this.prop_selectedUser
        console.log('this.user2', this.user);
        this.getStudentData()
    },
    methods: {
        getStudentData() {
            this.userSection = this.user.sections[this.prop_selectedSectionIndex]
            this.userSubsection = this.userSection.subSections[this.prop_selectedsubSectionIndex]
            this.feedbackArr = this.userSubsection.feedbackArr
        },
        goBack(subject) {
            this.$router.push({
                name: "activityCourseAnalysis",
                params: {
                    prop_subSection: this.subSection,
                    prop_user: this.prop_user,
                    prop_sections: this.prop_sections,
                    prop_selectedUser: this.user,
                    prop_selectedSectionIndex: this.prop_selectedSectionIndex,
                    prop_selectedsubSectionIndex: this.prop_selectedsubSectionIndex,
                    prop_selectedStudentIndex: this.selectedStudentIndex
                }
            });
        },
    }
}

</script>
<style scoped src="./feedbackSubmitted.css">

</style>