import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Subject";

class Subject {
  constructor(context) {
    this.context = context;
  }

  async createSubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createSubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async bulkCreateSubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.bulkCreateSubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSubjectByIds(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSubjectByIds,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSubjectExamRules(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectExamRules,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSubjectsOfMultiDepartment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfMultiDepartment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async getSubjectsOfADepartment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfADepartment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async getSubjectName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjectName;
  }

  async getSubjectsOfSemesters(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSubjectsOfSemesters,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async getSubjectsOfSemestersWithoutCourseYear(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSubjectsOfSemestersWithoutCourseYear,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSubjectsOfAInstituteInASemester(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfAInstitute,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }

  async getSubjectsOfACourseYearInASemester(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfACourseYear,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }

  async getSubjectsOnlyByIds(data) {
    console.log('data11111', data)
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOnlyByIds,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }

  async getSubjectsOfACourseYearForAdmission(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfACourseYearForAdmission,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }

  async getFacultiesTeachingASubjectOfACourseYearInASemester(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getFacultiesTeachingASubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.faculties;
  }

  async getSubjectsOfAInstituteAndSemId(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfAInstituteAndSemId,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }
  async getDetailsOfASubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getDetailsOfASubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subject;
  }
  async getSubjectDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subject;
  }
  async getSubjectsOfAInstituteByInstituteId(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfAInstituteByInstituteId,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async updateGroupName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateGroupName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.groupName;
  }
  async updateGroupNameOfSubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateGroupNameOfSubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async updateSubjectName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateSubjectName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async updateMultipleSubjectDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateMultipleSubjectDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async updateSubjectCredits(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateSubjectCredits,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.credits;
  }

  async updateSubjectCourseImage(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateCourseImage,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.url;
  }

  async updateSubjectCoordinators(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateSubjectCoordinators,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjectCoordinators;
  }

  async updateFacultiesTeachingASubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateFacultiesTeachingASubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.faculties;
  }

  async updateSubjectType(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateSubjectType,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjectType;
  }

  async updateCompetenceAndPrequisite(data) {
    this.context.$store.commit("liveData/set_loading", true);
    await networkManager.putRequest(
      ApiNames.updateCompetenceAndPrequisite,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return;
  }

  async deleteSubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.deleteRequest(
      ApiNames.deleteSubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjectType;
  }

  async getSubjectDetailsOfACourseYearWithFacultyData(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectDetailsOfACourseYearWithFacultyData,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }
  async getSubjects(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjects,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }
  async getSubjectsMethodPost(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSubjects,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }
  async getSubjectsOfAInstituteAndSem(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfAInstituteAndSem,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }
}

export default Subject;
