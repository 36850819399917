export default {
  createTopic: '/topic/createTopic',
  getTopicsOfADivisionOfASubject: '/topic/getTopicsOfADivisionOfASubject',
  getTopicRatingForASubject: '/topic/getTopicRatingForASubject',
  getTopicsOfASubject: '/topic/getTopicsOfASubject',
  getTopicsOfMultiSubject: '/topic/getTopicsOfMultiSubject',
  updateTopicRating: '/topic/updateTopicRating',
  updateTopic: '/topic/updateTopic',
  markAsImplemented: '/topic/markAsImplemented',
  markAsUnimplemented: '/topic/markAsUnimplemented',
  deleteTopic: '/topic/deleteTopic',
  getTopicsOfAStudent: '/topic/getTopicsOfAStudent',
  getTopicsOfASubject: '/topic/getTopicsOfASubject',
  generateTopicReport: '/excel/topic/generateTopicReport',
  getTopicsOfASubjectOfAFaculty: '/topic/getTopicsOfASubjectOfAFaculty',
}
