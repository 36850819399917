<template src='./activityDirections.html'>
</template>
<script>
export default {
  name: 'activityDirections',
  data () {
    return {
      isCorporateInstitute:false,
      allowedModuleLiveData: []
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', '')
    this.isCorporateInstitute = this.$store.getters['liveData/get_isCorporateInstitute']
    const isSubAdmin = this.$store.getters['liveData/get_isSubAdmin'].isSubAdmin
    this.allowedModuleLiveData = this.$store.getters['liveData/get_allowedModule']
    let indexArr = []
   this.allowedModuleLiveData.map((data,i) => {
      if(data.isAccess === true) {
         indexArr.push(i)
      }
    })
    // console.log('indexArr', indexArr);
    // console.log('this.isCorporateInstitute##', this.isCorporateInstitute);
    if(isSubAdmin === true) {
     if (indexArr[0] === 0) {
       this.gotoTimeline()
     }
     if (indexArr[0] === 1) {
       this.gotoInstitutesManagment()
     }
     if (indexArr[0] === 2) {
       this.gotoSemestersManagment()
     }
     if (indexArr[0] === 3) {
       this.gotoManageUsers()
     }
     if (indexArr[0] === 4) {
       this.gotoDashboard()
     }
     if (indexArr[0] === 5) {
       this.gotoExams()
     }
     if (indexArr[0] === 6) {
       this.gotoAttendanceAnalysis()
     }
     if (indexArr[0] === 7) {
       this.gotoCumulativeAttendance()
     }
     if (indexArr[0] === 8) {
       this.gotoTopicFeedbackAnalysis()
     }
     if (indexArr[0] === 9) {
       this.gotoTopicFeedbackAnalysis()
     }
     if (indexArr[0] === 10) {
       this.gotoSyllabusTracking()
     }
     if (indexArr[0] === 11) {
       this.gotoManageAssignments()
     }
     if (indexArr[0] === 12) {
       this.gotoManageStudyMaterial()
     }
     if (indexArr[0] === 13) {
       this.gotoAdmission()
     }

     if (indexArr[0] === 14) {
       this.gotoStudentProfile()
     }
     if (indexArr[0] === 15) {
       this.gotoFacultyProfile()
     }
     if (indexArr[0] === 16) {
       this.gotoQuickTest()
     }
     if (indexArr[0] === 17) {
       this.gotoFeedbackForm()
     }
     if (indexArr[0] === 18) {
       this.gotoCourses()
     }
     if (indexArr[0] === 19) {
       this.gotoMediaLibrary()
     }
    }else {
      if (this.isCorporateInstitute === true) {
        this.$store.dispatch('liveData/set_selectedActivity', 33)
        this.$router.push('/activityCourses')
      }else {
        this.$router.push('/activityTimeline')
      }
    }

  },
  methods: {
    gotoMyProfile() {
      this.$store.commit('liveData/set_selectedActivity', 1)
      this.$router.push('/activityProfile')
    },
    gotoInstituteBasicDetails() {
      this.$store.dispatch('liveData/set_selectedActivity', 2)
      this.$router.push('/activityInstitutesBasicDetails')
    },
    gotoReferenceAcademicDetails() {
      this.$store.dispatch('liveData/set_selectedActivity', 3)
      this.$router.push('/activityReferenceAcademicDetails')
    },
    gotoReferenceInfrastructure() {
      this.$store.dispatch('liveData/set_selectedActivity', 4)
      this.$router.push('/activityReferenceInfrastructure')
    },
    gotoReferenceShiftDetails() {
      this.$store.dispatch('liveData/set_selectedActivity', 5)
      this.$router.push('/activityReferenceShiftDetails')
    },
    gotoManageRoles() {
      this.$store.dispatch('liveData/set_selectedActivity', 6)
      this.$router.push('/activityManageRoles')
    },
    gotoViewSemesters() {
      this.$store.dispatch('liveData/set_selectedActivity', 7)
      this.$router.push('/activityViewSemesters')
    },
    gotoCreateSemester() {
      this.$store.dispatch('liveData/set_selectedActivity', 8)
      this.$router.push('/activityCreateSemester')
    },
    gotoDashboard() {
      this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push('/activityDashboard')
    },
    gotoExams() {
      this.$store.dispatch('liveData/set_selectedActivity', 10)
      this.$router.push('/activityExams1')
    },
    gotoAttendanceAnalysis() {
      this.$store.dispatch('liveData/set_selectedActivity', 11)
      this.$router.push('/activityAttendanceAnalysis')
    },
    gotoArrangeLecture() {
      this.$store.dispatch('liveData/set_selectedActivity', 12)
      this.$router.push('/activityArrangeLecture')
    },
    gotoArrangeLectureBySubject() {
      this.$store.dispatch('liveData/set_selectedActivity', 29)
      this.$router.push('/activityArrangeLectureBySubject')
    },
    gotoManageHoliday() {
      this.$store.dispatch('liveData/set_selectedActivity', 13)
      this.$router.push('/activityManageHoliday')
    },
    gotoSyllabusTracking() {
      this.$store.dispatch('liveData/set_selectedActivity', 14)
      this.$router.push('/activitySyllabusTracking')
    },
    gotoQuickTest() {
      this.$store.dispatch('liveData/set_selectedActivity', 24)
      this.$router.push('/activityQuickTest')
    },
    gotoManageAssignments() {
      this.$store.dispatch('liveData/set_selectedActivity', 29)
      this.$router.push('/activityManageAssignments')
    },
    gotoManageStudyMaterial() {
      this.$store.dispatch('liveData/set_selectedActivity', 25)
      this.$router.push('/activityManageStudyMaterial')
    },
    gotoNotifications() {
      this.show = false
      this.$store.dispatch('liveData/set_selectedActivity', 15)
      this.$router.push('/activityNotifications')
    },
    gotoManageUsers() {
      this.show = false
      this.$store.dispatch('liveData/set_selectedActivity', 16)
      this.$router.push('/activityManageUsers')
    },
    gotoTimeline() {
      this.$store.commit('liveData/set_selectedActivity', 17)
      this.$router.push('/activityTimeline')
    },
    gotoManageSMS() {
      this.$store.commit('liveData/set_selectedActivity', 18)
      this.$router.push('/activityManageSMS')
    },
    gotoManageTopicFeedbackTemplates() {
      this.$store.commit('liveData/set_selectedActivity', 19)
      this.$router.push('/activityManageTopicFeedbackTemplates')
    },
    gotoTopicFeedbackAnalysis() {
      this.$store.commit('liveData/set_selectedActivity', 20)
      this.$router.push('/activityTopicFeedbackAnalysis')
    },
    gotoNBAModule() {
      this.$store.dispatch('liveData/set_selectedActivity', 21)
      this.$router.push('/activityNBAModule')
    },
    gotoNBAextended() {
      this.$store.dispatch('liveData/set_selectedActivity', 22)
      this.$router.push('/activityNBAextended')
    },
    gotoAdmission() {
      this.$store.dispatch('liveData/set_selectedActivity', 23)
      this.$router.push('/activityAdmission')
    },
    gotoInstitutesManagment() {
      this.$store.dispatch('liveData/set_selectedActivity', 26)
      this.$router.push('/activityInstitutesManagment')
    },
    gotoCumulativeAttendance() {
      this.$store.dispatch('liveData/set_selectedActivity', 31)
      this.$router.push('/activityCumulativeAttendance')
    },
    gotoSemestersManagment() {
      this.$store.dispatch('liveData/set_selectedActivity', 27)
      this.$router.push('/activitySemestersManagment')
    },
    gotoStudentProfile() {
      this.$store.dispatch('liveData/set_selectedActivity', 28)
      this.$router.push('/activityStudentProfile')
    },
    gotoFacultyProfile() {
      this.$store.dispatch('liveData/set_selectedActivity', 30)
      this.$router.push('/activityFacultyProfile')
    },
    gotoMediaLibrary() {
      this.$store.dispatch('liveData/set_selectedActivity', 32)
      this.$router.push('/activityMediaLibrary')
    },
    gotoCourses() {
      this.$store.dispatch('liveData/set_selectedActivity', 33)
      this.$router.push('/activityCourses')
    },
    gotoactivityEditSemesterDates() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterDates')
    },
    gotoactivityEditSemesterHolidays() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterHoliday')
    },
    gotoactivityEditSemesterTimetable() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterTimetable')
    },
    gotoactivityEditSemesterShifts() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterShifts')
    },
    gotoactivityEditSemesterBatches() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterBatches')
    },
    gotoactivityEditSemesterUsers() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterUsers')
    },
    gotoactivityEditSemesterClassteacher() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterClassteacher')
    },
    gotoactivityEditFacultyAllocation() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditFacultyAllocation')
    },
    gotoactivityEditStudentsRegistration() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditStudentsRegistration')
    },
    gotoactivityEditSemesterSubjects() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterSubjects')
    },
    gotoactivityEditSemesterSubjects() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterSubjects')
    },
    gotoactivityManageHoliday() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityManageHoliday')
    },
    gotoactivityInstitutesManagment() {
      this.$store.dispatch('liveData/set_selectedActivity',)
      this.$router.push('/activityInstitutesManagment')
    },
    gotoReferenceDetails() {
      this.$store.dispatch('liveData/set_selectedActivity',)
      this.$router.push('/activityReferenceDetails')
    },
    gotoFeedbackForm() {
      this.$store.dispatch('liveData/set_selectedActivity', 34)
      this.$router.push('/activityFBForm')
    },

  }
}
</script>
<style src='./activityDirections.css' scoped>
</style>
