<template src="./examSettingsOptions.html"></template>

<script>
import ExamSettingsRepository from "../../../Repository/ExamSettings";

// Global Components
import Select from "../../../Components/styleGuide/InputsAndControls/Select.vue";
import TextField from "../../../Components/styleGuide/InputsAndControls/TextField.vue";
import TextInputArea from "../../../Components/styleGuide/InputsAndControls/Textarea.vue";
import Button from "../../../Components/styleGuide/Buttons/Button.vue";
import showStatus from "../../../NetworkManager/showStatus";
import DeleteDialog from "../../../Components/styleGuide/Dialogs/Delete.vue";
import SoloIcon from "../../../Components/styleGuide/Buttons/Icons/SoloIcon.vue";

export default {
  name: "ExamSettingsOptions",
  props: [
    "examSettingsObject",
    "subjectDetails",
    "batchDetails",
    "prop_ExamCategory",
    "ruleNumber",
  ],
  components: {
    Select,
    TextField,
    TextInputArea,
    Button,
    DeleteDialog,
    SoloIcon,
  },
  data() {
    return {
      selectedCourseYearObject: {},
      saveExamSettingsLoader: false,
      displaySubjectDetailsTable: false,
      selectedPassingPercentageType: "",
      selectedCieSeePreference: "",
      cieSeePreference: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      passingOptions: [
        {
          text: "Combined passing for formative and Summative exams",
          value: "combined",
        },
        {
          text: "Separate passing for formative and Summative exams",
          value: "separate",
        },
        {
          text: "Only Formative Exams",
          value: "formativeOnly",
        },
        {
          text: "Only Summative Exams",
          value: "summativeOnly",
        },
      ],
      // passClasses: [],
      specialStatuses: [],
      selectedPassingPreference: "",
      systemPreference: [
        {
          text: "Absolute",
          value: "absolute",
        },
        {
          text: "Relative",
          value: "relative",
        },
        {
          text: "Graded Subject",
          value: "gradedSubject",
        },
        // {
        //   text: "Relative With Standard Deviation",
        //   value: "relativeWithSD",
        // },
        {
          text: "Percentage (%)",
          value: "percentage",
        },
      ],
      selectedSystemPreference: "",
      passingPercentageTypes: [
        {
          text: "Overall",
          value: "overall",
        },
        {
          text: "Individual Course Wise",
          value: "individual",
        },
      ],
      gradingSystemOptions: [
        {
          text: "Absolute",
          value: "absolute",
        },
        {
          text: "Relative",
          value: "relative",
        },
      ],
      examCategory: 1,
      ruleName: "",
      ruleDescription: "",
      showGradeTable: false,
      currentResultMethod: null,
      formatForGradedSubject: "marks",
      gradedSubjectFormats: [
        {
          text: "Accept Marks",
          value: "marks",
        },
        {
          text: "Accept Letters",
          value: "letters",
        },
      ],
      marksRangeForGradedSubjects: [],
      letterRangeForGradedSubjects: [],
      combinedPassingPercentage: 0,
      ruleId: "",
      formativeOnlyPassingPercentage: 0,
      summativeOnlyPassingPercentage: 0,
      passingPreference: "",
      formativePassingPercentage: 0,
      summativePassingPercentage: 0,
      copyRuleDialog: false,
      currentExamCategory: "",
      headers: [
        {
          text: "Number",
          value: "ruleNumber",
        },
        {
          text: "Name",
          value: "ruleName",
        },
      ],
      expanded: [],
      instituteId: "",
      absoluteGradeDetails: [],
      relativeGradeDetails: [],
      systemOptionChangeDialog: false,
      previousSystemPreferenceSelection: "",
      passingOptionChangeDialog: false,
      defaultAbsoluteObject: {
        gradeName: "",
        gradePoints: 0,
        gradeRange: {
          from: 0,
          to: 0,
        },
        gradeRemark: "",
      },
      defaultRelativeObject: {
        gradeName: "",
        gradePoints: 0,
        gradeRemark: "",
      },
      defaultLetterGradeObject: {
        grade: "",
        letters: [""],
        remark: "",
      },
      defaultMarksGradeObject: {
        gradeName: "",
        gradeRange: {
          from: 0,
          to: 0,
        },
        gradeRemark: "",
      },
      defaultSpecialStatusObject: {
        letter: "",
        grade: "",
      }
    };
  },
  created() {
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.setData();
  },
  methods: {
    setData() {
      // this.examCategory = this.
      this.instituteId = this.examSettingsObject.instituteId;
      this.ruleId = this.examSettingsObject.ruleId;
      this.ruleName = this.examSettingsObject.ruleName;
      this.ruleDescription = this.examSettingsObject.ruleDescription;
      if (this.examSettingsObject.systemPreference) {
        this.selectedSystemPreference =
          this.examSettingsObject.systemPreference;
        this.handleSystemPreference(this.selectedSystemPreference, false);
        if (this.examSettingsObject.gradeDetails) {
          if (this.selectedSystemPreference === "absolute") {
            this.absoluteGradeDetails = this.examSettingsObject.gradeDetails;
          } else if (this.selectedSystemPreference === "relative") {
            this.relativeGradeDetails = this.examSettingsObject.gradeDetails;
          }
        }
        if (this.examSettingsObject.systemPreference === "gradedSubject") {
          if (this.examSettingsObject.formatForGradedSubject === "marks") {
            this.marksRangeForGradedSubjects =
              this.examSettingsObject.marksRangeForGradedSubjects;
          } else {
            this.letterRangeForGradedSubjects =
              this.examSettingsObject.letterRangeForGradedSubjects;
          }
        }
      }
      if (this.examSettingsObject.passingPreference) {
        this.passingPreference = this.examSettingsObject.passingPreference;
        if (this.examSettingsObject.passingPreference === "combined") {
          this.combinedPassingPercentage =
            this.examSettingsObject.combinedPassingPercentage;
        } else if (this.examSettingsObject.passingPreference === "separate") {
          this.formativePassingPercentage =
            this.examSettingsObject.formativePassingPercentage;
          this.summativePassingPercentage =
            this.examSettingsObject.summativePassingPercentage;
        } else if (
          this.examSettingsObject.passingPreference === "formativeOnly"
        ) {
          this.formativeOnlyPassingPercentage =
            this.examSettingsObject.formativeOnlyPassingPercentage;
        } else if (
          this.examSettingsObject.passingPreference === "summativeOnly"
        ) {
          this.summativeOnlyPassingPercentage =
            this.examSettingsObject.summativeOnlyPassingPercentage;
        }
      }

      // if (this.examSettingsObject?.passClasses?.length > 0) {
      //   this.passClasses = this.examSettingsObject.passClasses;
      // }
      if (this.examSettingsObject?.specialStatuses?.length > 0) {
        this.specialStatuses = this.examSettingsObject.specialStatuses;
      }
    },
    checkForDefaultValue() {
      let requiredObject = this.defaultAbsoluteObject
      if(this.systemPreference === "relative"){
        requiredObject = this.defaultRelativeObject
      } else if (this.systemPreference === "gradedSubject") {
        if (this.formatForGradedSubject === "marks") {
          requiredObject = this.defaultMarksGradeObject
        } else if (this.formatForGradedSubject === "letters") {
          requiredObject = this.defaultLetterGradeObject
        }
      }
      // Check if the array has only one element
      if (arr.length !== 1) {
        return false;
      }

      // Check if the element is deeply equal to the requiredObject
      const isObjectEqual =
        JSON.stringify(arr[0]) === JSON.stringify(requiredObject);

      return isObjectEqual;
    },
    handleSystemPreference(value) {
      if (value !== "percentage") {
        this.showGradeTable = true;
        if (value === "absolute") {
          this.currentResultMethod = 0;
        } else if (value === "relative") {
          this.currentResultMethod = 1;
        } else if (value === "gradedSubject") {
          this.currentResultMethod = 2;
          this.showGradeTable = false;
        } else {
          this.currentResultMethod = null;
        }
      } else {
        this.showGradeTable = false;
      }
    },
    checkForSystemPreferenceChange(value) {
      // let ifDefaultValue = this.checkForDefaultValue()
      if (this.selectedSystemPreference !== value) {
        let isDataPresent = false;
        this.previousSystemPreferenceSelection = this.selectedSystemPreference;
        this.currentSystemPreferenceSelection = value;

        let l1 = this.absoluteGradeDetails.length;
        let l2 = this.relativeGradeDetails.length;
        let l3 = this.marksRangeForGradedSubjects.length;
        let l4 = this.letterRangeForGradedSubjects.length;

        if (l1 > 0 || l2 > 0 || l3 > 0 || l4 > 0) {
          isDataPresent = true;
        }

        if (isDataPresent) {
          this.systemOptionChangeDialog = true;
        } else {
          this.handleSystemPreference(value);
        }
      }
    },
    confirmGradeChange() {
      this.absoluteGradeDetails = [];
      this.relativeGradeDetails = [];
      this.marksRangeForGradedSubjects = [];
      this.letterRangeForGradedSubjects = [];
      this.selectedSystemPreference = this.currentSystemPreferenceSelection;
      this.handleSystemPreference(this.currentSystemPreferenceSelection);
    },
    cancelGradeRange() {
      this.selectedSystemPreference = this.previousSystemPreferenceSelection;
    },
    checkForPassingPreferenceChange(value) {
      if (this.passingPreference !== value) {
        this.previousPassingPreferenceSelection = this.passingPreference;
        this.currentPassingPreferenceSelection = value;

        if (
          this.combinedPassingPercentage > 0 ||
          this.formativePassingPercentage > 0 ||
          this.summativePassingPercentage > 0 ||
          this.formativeOnlyPassingPercentage > 0 ||
          this.summativeOnlyPassingPercentage > 0
        ) {
          this.passingOptionChangeDialog = true;
        }
      }
    },
    confirmPassingPreference() {
      this.combinedPassingPercentage = 0;
      this.formativePassingPercentage = 0;
      this.summativePassingPercentage = 0;
      this.formativeOnlyPassingPercentage = 0;
      this.summativeOnlyPassingPercentage = 0;
      this.passingPreference = this.currentPassingPreferenceSelection;
    },
    cancelPassingPreference() {
      this.passingPreference = this.previousPassingPreferenceSelection;
    },
    // addPassClass() {
    //   let objToPush = {
    //     from: 0,
    //     to: 0,
    //     class: "",
    //   };
    //   this.passClasses.push(objToPush);
    // },
    // deletePassClass(i) {
    //   if (i > -1) {
    //     this.passClasses.splice(i, 1);
    //   }
    // },
    addSpecialStatus() {
      let obj = {
        letter: "",
        grade: "",
      }
      this.specialStatuses.push(obj);
    },
    deleteSpecialStatus(i) {
      if (i > -1) {
        this.specialStatuses.splice(i, 1);
      }
    },
    addMarksRangeForGradedSubjects() {
      let obj = {
        gradeName: "",
        gradeRange: {
          from: 0,
          to: 0,
        },
        gradeRemark: "",
      }
      this.marksRangeForGradedSubjects.push(obj);
    },
    deleteMarksRangeForGradedSubjects(i) {
      if (i > -1) {
        this.marksRangeForGradedSubjects.splice(i, 1);
      }
    },
    addLetterRangeForGradedSubjects() {
      let obj = {
        grade: "",
        letters: [""],
        remark: "",
      }
      this.letterRangeForGradedSubjects.push(obj);
    },
    addLetter(index) {
      this.letterRangeForGradedSubjects[index].letters.push("");
    },
    deleteLetter(parentIndex, childIndex) {
      if (parentIndex > -1 && childIndex > -1) {
        this.letterRangeForGradedSubjects[parentIndex].letters.splice(
          childIndex,
          1
        );
      }
    },
    deleteLetterRangeForGradedSubjects(i) {
      if (i > -1) {
        this.letterRangeForGradedSubjects.splice(i, 1);
      }
    },
    updateLetter(item, index, newValue) {
      this.$set(item.letters, index, newValue);
    },
    async saveExamSettings() {
      this.saveExamSettingsLoader = true;

      let objToPush = {
        instituteId: this.instituteId,
        ruleNumber: this.ruleNumber,
        ruleDescription: this.ruleDescription,
        ruleName: this.ruleName,
        examCategory: this.prop_ExamCategory,
        ruleId: this.ruleId,
        passingPreference: this.passingPreference,
        systemPreference: this.selectedSystemPreference,
      };
      if (this.selectedSystemPreference === "gradedSubject") {
        objToPush["formatForGradedSubject"] = this.formatForGradedSubject;

        if (this.formatForGradedSubject === "marks") {
          objToPush["marksRangeForGradedSubjects"] =
            this.marksRangeForGradedSubjects;
        } else {
          objToPush["letterRangeForGradedSubjects"] =
            this.letterRangeForGradedSubjects;
        }
      } else if (this.selectedSystemPreference === "absolute") {
        objToPush["gradeDetails"] = this.absoluteGradeDetails;
      } else if (this.selectedSystemPreference === "relative") {
        objToPush["gradeDetails"] = this.relativeGradeDetails;
      }

      if (this.passingPreference === "combined") {
        objToPush["combinedPassingPercentage"] = this.combinedPassingPercentage;
      }
      if (this.passingPreference === "separate") {
        objToPush["formativePassingPercentage"] =
          this.formativePassingPercentage;
        objToPush["summativePassingPercentage"] =
          this.summativePassingPercentage;
      }
      if (this.passingPreference === "formativeOnly") {
        objToPush["formativeOnlyPassingPercentage"] =
          this.formativeOnlyPassingPercentage;
      }
      if (this.passingPreference === "summativeOnly") {
        objToPush["summativeOnlyPassingPercentage"] =
          this.summativeOnlyPassingPercentage;
      }
      if (this.specialStatuses.length > 0) {
        objToPush["specialStatuses"] = this.specialStatuses;
      }
      // if (this.passClasses.length > 0) {
      //   objToPush["passClasses"] = this.passClasses;
      // }
      try {
        await this.examSettingsRepositoryInstance.createExamSettings(objToPush);
        // await this.getExamSettings();
        showStatus("Exam Settings saved successfully.", 2000, "success", this);
        this.$emit("ruleUpdate"); //emit an event(function) in parent component.
      } catch (error) {
        console.error(error);
        showStatus("Error saving Exam Settings.", 9000, "error", this);
      }
      this.saveExamSettingsLoader = false;
    },
    addGrade() {
      if (this.selectedSystemPreference === "absolute") {
        let obj = {
        gradeName: "",
        gradePoints: 0,
        gradeRange: {
          from: 0,
          to: 0,
        },
        gradeRemark: "",
      }
        this.absoluteGradeDetails.push(obj);
      } else {
        let obj = {
        gradeName: "",
        gradePoints: 0,
        gradeRemark: "",
      }
        this.relativeGradeDetails.push(obj);
      }
    },
    deleteGrade(i) {
      if (i > -1) {
        if (this.selectedSystemPreference === "absolute") {
          this.absoluteGradeDetails.splice(i, 1);
        } else {
          this.relativeGradeDetails.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style src="./examSettingsOptions.css"></style>