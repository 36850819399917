import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ExamSubject'

class ExamSubject {
  constructor (context) {
    this.context = context
  }

  async createExamSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.createExamSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getExamSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examSubject
  }

  async getSubjectsForAnExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getSubjectsForAnExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examSubjects
  }
  async getExamsForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamsForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examSubjects
  }
  async updateExamSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.updateExamSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteExamSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.deleteExamSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default ExamSubject
