<script>
import { Pie } from 'vue-chartjs'
export default {
  extends: Pie,
  props: ['attendanceDistribution'],
  data () {
    return {
      chartOptions: {
        hoverBorderWidth: 20
      },
      chartData: {
        hoverBackgroundColor: 'red',
        hoverBorderWidth: 10,
        // options here
        labels: [],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: [
              'rgba(54, 162, 235, 0.8)',
              'rgba(255, 99, 132, 0.8)',
              'rgba(255, 206, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 159, 64, 0.8)',
              'rgba(54, 162, 235, 0.8)',
              'rgba(255, 99, 132, 0.8)',
              'rgba(255, 206, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 159, 64, 0.8)'
            ],
            // values here
            data: []
          }
        ]
      }
    }
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.chartData.labels = Object.keys(this.attendanceDistribution)
    this.chartData.datasets[0].data = Object.values(this.attendanceDistribution)
    this.renderChart(this.chartData, {
      borderWidth: '10px',
      hoverBackgroundColor: 'red',
      hoverBorderWidth: '10px',
      legend: {
        position: 'bottom'
      }
    })
  }
}
</script>
