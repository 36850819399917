<template src='./activityEditSemesterHolidays.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import SemesterRepository from '../../Repository/Semester'
import LectureRepository from '../../Repository/Lecture'
import { convertDate, convertFromYYYYMMDDToDate, convertLocalToUTC } from '../../Services/Utils/DateTimeFormatter'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityEditSemesterHolidays',
  data() {
    return {
      lecturesData: [],
      selectedHolidayForDelete: {},
      headersForLecture: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course ',
          value: 'subjectName'
        },
        {
          text: 'Faculty',
          value: 'fullName'
        },
        {
          text: 'Date',
          value: 'dateTime'
        }
      ],
      cancleLectureForSelectedDate: '',
      selectedHolidayData: '',
      loading: false,
      deleteAndResheduleDialog: false,
      deleteHolidayDialog: false,
      rescheduleDialog: false,
      scheduleDialog: false,
      selectedSemester: '',
      items: [],
      selection: [],
      selectionType: 'leaf',
      tempDate: '',
      tempTitle: '',
      tempStatus: '',
      tableItems: [],
      statusList: ['Confirm', 'Non Confirm'],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Status',
          value: 'confirmed'
        },
        {
          text: 'Lecture Cancel',
          value: 'cancleLecture'
        },
        {
          text: 'Delete Holiday',
          value: 'actions'
        }
      ]
    }
  },
  props: [
    'PropsSelectedSemester',
    'prop_selectedDepartment',
    'tree'
  ],
  components: {
    inputContainer,
    smallButton
  },
  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Semester Holidays')
    this.loading = true
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.PropsSelectedSemester
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.LectureRepositoryInstance = new LectureRepository(this)
    this.items = this.tree
    if (this.prop_selectedDepartment) {
      this.items
        .filter((item) => item.name === this.prop_selectedDepartment)
        .forEach((item) => {
          this.selection.push(item)
        })
    }
    this.loading = false
    this.fetchDetails()
  },
  methods: {
    async updateHolidaysStaus(arr) {
      try {
        for (let i = 0; i < this.selection.length; i++) {
          const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              semName: this.selectedSemester.semName,
              department: this.selection[i].name,
              holidays: arr
            }
            await this.semesterRepositoryInstance.updateHolidaysOfADepartmentOfASemester(objToPush)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getLectureForDay(item) {
      try {
        const objToPush = {

        }
        let arr = []
        if (this.selection.length > 0) {

          this.selection.map((item) => {
            arr.push(item.name)
          })
        }
        let date = item['date'].split("T")[0]
        objToPush.fromDate = `${date}T00:00:00.000Z`
        objToPush.toDate = `${date}T23:59:59.000Z`
        objToPush.department = arr
        objToPush.instituteId = this.selectedInstitute
        objToPush.semId = this.selectedSemester.semId
        objToPush.type = 'Dept_All'
        let lecData = await this.LectureRepositoryInstance.getOverAllLectureForDates(objToPush)
        if (lecData?.lecture.length > 0) {
          lecData.lecture.map((lec) => {
            lec.fullName = '-'
            lec.roomName = '-'
            lec.subjectName = '-'
            if (lecData?.facultyDetails?.fullNames.length > 0) {

              lecData?.facultyDetails.fullNames.map((fac) => {
                if (fac.uId === lec.uId) {
                  lec.fullName = fac.fullName
                }
              })
            }
            if (lecData?.roomDetails?.roomName.length > 0) {

              lecData.roomDetails.roomName.map((room) => {
                if (room.roomId === lec.roomId) {
                  lec.roomName = room.roomName
                }
              })
            }
            if (lecData?.subjectDetails?.subjects.length > 0) {

              lecData?.subjectDetails.subjects.map((sub) => {
                if (sub.subjectId === lec.subjectId) {
                  lec.subjectName = sub.subjectName
                  lec.subjectType = sub.subjectType
                }
              })
            }
          })
        }
        this.lecturesData = lecData?.lecture
      } catch (error) {
        console.log(error)
      }
    },
    async openDialogForCancel(item,i, type) {
      this.selectedHolidayData = item
      this.selectedHolidayData['index'] = i
      if(type=== 1) {
        
        await this.getLectureForDay(item)
      }
    },
    async rescheduleLecForDay() {
      this.rescheduleDialog = false
      // this.selectedHolidayData['cancleLecture'] = false
      let number = this.selectedHolidayData['index']
      this.tableItems[number]['cancleLecture'] = false
      let obj = { ...this.selectedHolidayData }
      obj['status'] = 0
      delete obj.startDate
      delete obj.endDate
      delete obj.cancleLecture
      await this.handelCancleLecture(obj)
      let updateHolidays = []
      this.tableItems.map((item) => {
        updateHolidays.push({
          cancleLecture: item.cancleLecture,
          confirmed: item.confirmed,
          date: item.date,
          department: item.department,
          title: item.title
        })
      })
     await this.updateHolidaysStaus(updateHolidays)
     await this.fetchDetails()

    },
    async reCancelLec() {
      this.scheduleDialog = false
      // this.selectedHolidayData['cancleLecture'] = false
      let number = this.selectedHolidayData['index']
      this.tableItems[number]['cancleLecture'] = true
      let obj = { ...this.selectedHolidayData }
      obj['status'] = 6
      obj['reason'] = obj.title
      delete obj.startDate
      delete obj.endDate
      delete obj.cancleLecture
      await this.handelCancleLecture(obj)
      let updateHolidays = []
      this.tableItems.map((item) => {
        updateHolidays.push({
          cancleLecture: item.cancleLecture,
          confirmed: item.confirmed,
          date: item.date,
          department: item.department,
          title: item.title
        })
      })
     await this.updateHolidaysStaus(updateHolidays)
     await this.fetchDetails()

    },
    async handelCancleLecture(obj) {
      try {
        let dateValue = obj.date;
        dateValue = dateValue.split('T')[0]
        const startOfDay = `${dateValue}T00:00:00.000Z`
        const endOfDay = `${dateValue}T23:59:59.000Z`
        let arr = []
        if (this.selection.length > 0) {

          this.selection.map((item) => {
            arr.push(item.name)
          })
        }
        obj.instituteId = this.selectedInstitute
        obj.semId = this.selectedSemester.semId
        obj.department = arr
        obj.startDate = startOfDay
        obj.endDate = endOfDay
        await this.LectureRepositoryInstance.updateStatusOfLectureForGivenTime(obj)
      } catch (error) {
        console.log('error', error);
      }
    },
    async fetchDetails() {
      this.loading = true
      this.tableItems = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      }
      for (let i = 0; i < this.selection.length; i++) {
        objToPush.department = this.selection[i].name
        const holidays = await this.semesterRepositoryInstance.getHolidaysOfADepartmentOfASemester(objToPush)
        for (let j = 0; j < holidays.length; j++) {
          holidays[j].department = this.selection[i].name
          holidays[j].date = holidays[j].date
          holidays[j].confirmed = holidays[j].confirmed ? 'Confirm' : 'Non Confirm'
          this.tableItems.push(holidays[j])
        }
      }
      this.loading = false
    },
    async deleteHoliday(holiday) {
      this.loading = true
      const holidaysOfADepartment = this.tableItems.filter((holidayObject) => {
        return holidayObject.department === holiday.department &&
          holidayObject.title !== holiday.title &&
          holidayObject.date !== holiday.date
      }).map((holidayObject) => {
        return {
          title: holidayObject.title,
          date: holidayObject.date,
          confirmed: holidayObject.confirmed
        }
      })
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          semName: this.selectedSemester.semName,
          department: holiday.department,
          holidays: holidaysOfADepartment
        }
        await this.semesterRepositoryInstance.updateHolidaysOfADepartmentOfASemester(objToPush)
        showStatus('Holiday successfully deleted!', 1500, 'success', this)
        const indexOfRemovedHoliday = this.tableItems.findIndex((holidayObject) => {
          return holidayObject.department === holiday.department &&
            holidayObject.title === holiday.title &&
            holidayObject.date === holiday.date
        })
        if (indexOfRemovedHoliday > -1) {
          this.tableItems = arrayOps.removeFromArray(this.tableItems, indexOfRemovedHoliday)
        }
      } catch (err) {
        showStatus('Could not connect to server!', 1500, 'error', this)
        console.log(err)
      }
      this.loading = false
    },
    async addHoliday() {
      this.loading = true
      for (let i = 0; i < this.selection.length; i++) {
        const holidaysOfADepartment = this.tableItems.filter((holidayObject) => holidayObject.department === this.selection[i].name)
          .map((holidayObject) => {
            return {
              title: holidayObject.title,
              date: holidayObject.date,
              confirmed: holidayObject.confirmed === 'Confirm',
              cancleLecture: holidayObject.cancleLecture ? holidayObject.cancleLecture : false
            }
          })
        try {
          const holiday = {
            title: this.tempTitle,
            date: `${this.tempDate}T10:00:00`,
            confirmed: this.tempStatus === 'Confirm',
            cancleLecture: this.cancleLectureForSelectedDate === 'true' ? true : false,
          }

          holidaysOfADepartment.push(holiday)
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            semName: this.selectedSemester.semName,
            department: this.selection[i].name,
            holidays: holidaysOfADepartment
          }
          await this.semesterRepositoryInstance.updateHolidaysOfADepartmentOfASemester(objToPush)
          if (this.cancleLectureForSelectedDate === 'true') {
            holiday.status = 6
            holiday.reason = this.tempTitle
            holiday['date2'] = this.tempDate
            this.handelCancleLecture(holiday)
          }
          showStatus('Holiday successfully added!', 1500, 'success', this)
          await this.fetchDetails()

          this.cancleLectureForSelectedDate = false
        } catch (err) {
          showStatus('Could not connect to server!', 1500, 'error', this)
          console.log(err)
        }
        this.tempTitle = ''
        this.tempDate = ''
        this.tempStatus = ''
        this.cancleLectureForSelectedDate = ''
      }
      this.loading = false
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: 'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
  },
  destroyed() {
    console.log('Destroyed')
  }
}
</script>
<style src='./activityEditSemesterHolidays.css' scoped></style>
