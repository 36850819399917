<template>
<div style="box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); padding: 2px 4px 4px 4px; border-radius: 5px; cursor: pointer; margin-right: 5px;" @click='click(object)'><center><v-icon small color="black">{{icon}}</v-icon></center></div>
<!-- <div></div> -->
</template>
<script>
export default {
  name: 'smallButton',
  props: [
    'icon',
    'click',
    'object'
  ]
}
</script>
<style scoped>
</style>
