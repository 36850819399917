import { render, staticRenderFns } from "./examStudentMarks.html?vue&type=template&id=aa5d38d8&external"
import script from "./examStudentMarks.vue?vue&type=script&lang=js"
export * from "./examStudentMarks.vue?vue&type=script&lang=js"
import style0 from "./examStudentMarks.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports