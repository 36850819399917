<template src="./activityWelcome.html"></template>
<script>
import auth from "../../Services/Firebase/auth";
import UserRepository from "../../Repository/User";
import UserAuthentication from "../../Repository/UserAuthentication";
import showStatus from "../../NetworkManager/showStatus";
import { ROLES } from "../../Constants";
import SendEmailAlert from "../../Repository/SendEmailAlert";

export default {
  name: "activityWelcome",
  data() {
    return {
      signInWithPhone: false,
      phoneAuthStep: 0, // 0 -> enter phoneNum, 1 -> enter otp
      phoneNum: null,
      phoneOtp: null,
      isSendingRequest: false,
      username: "",
      password: "",
      usernameRules: [(v) => !!v || "Username is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      confirmationResultFunc: null,
      verificationCode: null,
      resetPassword: false,
      currentPassword: "",
      showCurrentPassword: false,
      newPassword: "",
      showNewPassword: false,
      confirmPassword: "",
      showConfirmPassword: false,
      confirmPasswordRules: [
        (v) => v === this.newPassword || "New passwords do not match",
      ],
      showLoginPassword: false,
      uId: "",
      forgotPassword: false,
      email: "",
      currentLocation: "",
      //Thanks stackoverflow: https://stackoverflow.com/a/59598452
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },

  methods: {
    /* loginType -> google | phone | username */
    login(loginType) {
      switch (loginType) {
        case "microsoft":
          auth.loginWithMicrosoft();
          break;
        case "google":
          auth.login();
          break;
        case "phone":
          this.confirmOtp();
          break;
        case "username":
          this.loginWithUserNameAndPassword();
          break;
        default:
          throw new Error("Unhandled Case in login");
      }
    },
    async loginWithUserNameAndPassword() {
      try {
        let fcmToken = "";
        this.isSendingRequest = true;
        this.userRepositoryInstance = new UserRepository(this);

        /********************** Login Email Alert **********************/ /*

        this.sendEmailAlertInstance = new SendEmailAlert(this);
        this.UserAuthenticationInstance = new UserAuthentication(this);

        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

        const response = await fetch(url);
        const data = await response.json();

        if (data.display_name) {
          this.currentLocation = data.display_name;
        } else {
          console.error("No address found");
        }

        // for email
        let userEmail = await this.UserAuthenticationInstance.getUserAuthenticationByUid({uId : loginResponse.uId})

        // for fullName
        let userFullName =  await this.userRepositoryInstance.getFullNameOfUser({uId : loginResponse.uId})

        let loginFirebaseResponse;
        loginFirebaseResponse = await auth.getEmailBodyFromFirebase(0);

        const loginEmail = await this.sendEmailAlertInstance.loginWithEmailAlert({
          username: this.username.toLowerCase().trim(),
          currentDate: new Date().toLocaleDateString(),
          currentTime: new Date().toLocaleTimeString(),
          currentBrowser: navigator.userAgent,
          currentLocation: this.currentLocation,
          firebaseData: loginFirebaseResponse,
          userEmail: userEmail[0].email,
          userFullName: userFullName,
        });

        */ /********************** Login Email Alert **********************/

        // login
        const loginResponse = await this.userRepositoryInstance.loginWithUserNameAndPassword({
            userName: this.username.toLowerCase().trim(),
            password: this.password.trim(),
            registrationToken: fcmToken,
            role: ROLES.ADMIN,
            role1: ROLES.SUB_ADMIN,
            portal: 2
          });
          console.log('loginResponse', loginResponse);
        if (loginResponse.uId === undefined) {
          if (loginResponse.status === 200 && loginResponse.message === 'User not allowed') {
            alert("You don't have access to this portal");
          } else {
            alert("Wrong password");
          }
        } else {
          if (loginResponse.isPasswordReset !== 0) {
            this.resetPassword = true;
            this.username = "";
            this.password = "";
            this.uId = loginResponse.uId;
          } else {
            // commit user to store
            this.$store.commit("user/set_userData", {
              uId: loginResponse.uId,
              userName: this.username.toLowerCase().trim(),
            });
            // save to localStorage
            window.localStorage.setItem(
              "userKey",
              JSON.stringify({ uId: loginResponse.uId })
            );
            window.localStorage.setItem( "token", JSON.stringify({ token: loginResponse.token }));
            // redirect to institute
            showStatus("Login Successful!", 1000, "success", this);

            // router push
            this.$router.push("/activitySelectInstituteNSemester");
          }
        }
      } catch (error) {
        console.error(`Error logging with username and password: ${error}`);
        if (error.response.status === 401) {
          alert("You don't have access to this portal");
        }
        if (error.response.status === 404) {
          alert("Error occurred, please check your credentials and retry");
        }
      } finally {
        this.isSendingRequest = false;
      }
    },
    async passwordUpdate() {
      try {
        this.isSendingRequest = true;
        const response = await this.userRepositoryInstance.passwordUpdate({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          uId: this.uId,
        });
        if (response.error) {
          throw new Error(response.message);
        }
        this.resetPassword = false;
        alert("Password updated successfully");
      } catch (e) {
        if (e.message === "Current password does not match") {
          alert("Current password does not match");
        } else {
          alert("An error occured");
          this.resetPassword = false;
        }
        console.error(`Error resetting password: ${e}`);
      } finally {
        this.isSendingRequest = false;
      }
    },
    togglePhoneLogin() {
      this.signInWithPhone = !this.signInWithPhone;
    },
    async sendOtp() {
      try {
        this.isSendingRequest = true;
        const firebase = auth.getFirebaseObject();
        firebase.auth().useDeviceLanguage();
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha",
          {
            size: "invisible",
          }
        );
        const phoneNumber = `+91${this.phoneNum}`;
        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier);
        this.confirmationResultFunc = confirmationResult;
        this.verificationCode = confirmationResult.verificationId;
        this.phoneAuthStep = 1;
      } catch (error) {
        console.error(`Error sending otp: ${error}`);
      } finally {
        this.isSendingRequest = false;
      }
    },
    async confirmOtp() {
      try {
        this.isSendingRequest = true;
        const firebase = auth.getFirebaseObject();
        console.log('this.verificationCode,', this.verificationCode, this.phoneOtp);
        const credential = firebase.auth.PhoneAuthProvider.credential(
          this.verificationCode,
          this.phoneOtp
        );
        await firebase.auth().signInWithCredential(credential);
        // after this, the firebase auth listener automatically
        // detects the user and redirects to setup activity
        // check in created method below
        //8007077676
      } catch (error) {
        console.error(`Error confirming otp: ${error}`);
      } finally {
        this.isSendingRequest = false;
      }
    },
    async onForgotPassword() {
      try {
        this.isSendingRequest = true;
        //call API
        const data = { userName: this.email };
        this.userRepositoryInstance = new UserRepository(this);
        await this.userRepositoryInstance.forgotPassword(data);
        alert("Check your email");
        this.forgotPassword = false;
      } catch (e) {
        console.error(`Error in forgot password: ${e}`);
        alert("An error occured");
      } finally {
        this.isSendingRequest = false;
      }
    },
    handleOnChange(value) {
      this.phoneOtp = value;
    },
    getLoginHeaderImage() {
      if (process.env.VUE_APP_LOGINPAGE_LOGO) {
        return require(`../../Assets/Images/${process.env.VUE_APP_LOGINPAGE_LOGO}`);
      } else return "";
    },
  },

  created() {
    if (window.innerWidth < 1200) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    const self = this;
    if (window.innerWidth < window.innerHeight) {
      this.$store.commit("liveData/set_isMobileView", true);
      this.$store.commit("liveData/set_selectedActivity", 2);
    }
    const user = window.localStorage.getItem("userKey");
    if (user) {
      const { uId } = JSON.parse(user);
      self.$store.commit("user/set_userData", {
        uId,
      });
      self.$router.push("/activitySetup");
      return;
    }
    if (auth.user()) {
      self.$router.push("/activitySetup");
    }
  },
};
</script>
<style src="./activityWelcome.css"></style>
