<template src="./normalCourse.html"></template>
<script>
import basicDetails from "./basicDetails/basicDetails";
import assessmentPlan from "./assessmentPlan/assessmentPlan.vue";
import sessionPlan from "./sessionPlan/sessionPlan";
import syllabus from "./obs/syllabus";
import showStatus from "../../../NetworkManager/showStatus";
import CourseFileRepository from "../../../Repository/CourseFile";

export default {
  name: "activityNormalCourse",
  components: {
    basicDetails,
    syllabus,
    assessmentPlan,
    sessionPlan
    // examsBasicDetails,
    // examSubjectDetails,
    // examForm,
    // hallTicket,
    // examQuestionDetails,
    // examMarks,
  },
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "tree",
    "prop_subject",
  ],
  created() {
    this.selectedCourseYear = this.prop_selectedCourseYear,
    this.selectedSemester = this.PropsSelectedSemester,
    this.selectedDepartment = this.prop_selectedDepartment;
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.courseFileRepositoryInstance  = new CourseFileRepository(this);
  },
  data() {
    return {
      tab: 0,
      secondaryColor: "#FF4F1F",
      black: "#000000",
      backgroundColor: "#E7E7FFCF",
      selectedCourseYear: "",
      selectedSemester: "",
      selectedDepartment: "",
      downloadLoader: false
    };
  },
  methods: {
    async downloadCourseFile(){
      this.downloadLoader = true;
      try {
        const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department : this.selectedDepartment,
        courseYear : this.selectedCourseYear,
        subjectId:  this.prop_subject.subjectId,
        };
        const res = await this.courseFileRepositoryInstance.generateCourseFile(objToPush)
        if(res.status === 200){
          window.open(res.data.pdfUrl);
          showStatus(
            "Course File downloaded successfully.",
            2000,
            "success",
            this
          );
        }
      } catch (error) {
          console.log(error)
      }
      this.downloadLoader =false;
    },
    goBack() {
      // this.$router.push({
      // name: "activityEditSemesterSubjects1",
      // params: {
      //     PropsSelectedSemester: this.selectedSemester,
      //     tree: this.tree,
      //     prop_SelectedDepartment: this.selectedDepartment,
      //     prop_SelectedCourseYear: this.selectedCourseYear,
      // }})prop_selectedCourseYear
      this.$router.push({
        name: "activityEditSemesterSubjects1",
        params: {
          tejas:'tejasss',
          PropsSelectedSemester: this.selectedSemester,
          prop_selectedDepartment: this.prop_selectedDepartment,
          prop_selectedCourseYear:this.prop_selectedCourseYear,
          prop_tree: this.tree,
        },
      });
    },
  },
};
</script>

<style scoped src="./normalCourse.css">
</style>