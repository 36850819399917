<template src='./activityAttendanceAnalysis.html'></template>

<script>
import inputContainer from '../../Components/inputContainer'
import { subjectTypes } from '../../Constants/Utils/Statics'
// import showStatus from '../../NetworkManager/showStatus'
import SubjectRepository from '../../Repository/Subject'
import TimetableRepository from '../../Repository/Timetable'
import LectureRepository from '../../Repository/Lecture'
import SemesterRepository from '../../Repository/Semester'
import UserRepository from '../../Repository/User'
import SemesterUserRepository from '../../Repository/SemesterUser'
import LectureUnblockRepository from '../../Repository/LectureUnblock'
import ExcelJS from 'exceljs'
import {
  convertUTCDateToLocalDate, timeStringConverter
} from "../../Services/Utils/DateTimeFormatter";
export default {
  name: 'activityAttendanceAnalysis',
  components: {
    inputContainer
  },
  data() {
    return {
      dataFetched: false,
      isNaralkar: false,
      selectedGroup: '',
      selectedSubjectCard: {},
      assignedDepartment: [],
      groupOfSubject: [],
      allLecturesData: [],
      facultiesNameWithUid: [],
      semesters: [],
      semestersList: [],
      selectedSemesterName: '',
      stepOneCompleted: false,
      departments: [],
      selectedDepartment: '',
      selectedDate: '',
      selectedFaculty: '',
      faculties: [],
      facultyNames: [],
      allocations: [],
      assignedCourseYearForSubAdmin: [],
      // occupiedSlots: [],
      subjectsMapping: {},
      selectedAllocation: {
        name: ''
      },
      totalStudents: 0,
      totalLectures: 0,
      selectedLimit: 75,
      defaulterStudentsPercent: 0,
      defaulterStudentsCount: 0,
      nondefaulterStudentsPercent: 0,
      nondefaulterStudentsCount: 0,
      search: '',
      isSubAdmin: false,
      studentsList: [],
      headers: [
        {
          text: '',
          value: 'status'
        },
        {
          text: 'Roll Number',
          value: 'rollNumber'
        },
        {
          text: 'Name',
          value: 'fullName'
        },
        // {
        //   text: 'Status',
        //   value: 'status'
        // },
        {
          text: 'Total Attended',
          value: 'totalAttended'
        },
        {
          text: 'Total Conducted',
          value: 'totalConducted'
        },
        {
          text: 'Percentage',
          value: 'percentage'
        }
      ],
      selectedSubject: {},
      allReqForSem: [],
      userData: {},
      subjectTypes: subjectTypes,
      allLecData: [],
      allSubjectData: [],
      lectureUnblockDialog: false,
      loadingForTopic: false,
      allsubjectObj: {},
      lecObj: {},
      headersForLecBlock: [
        {
          text: "Faculty Name",
          value: "facultyName",
          width: "160px"
        },
        {
          text: "Requeste Time",
          value: "reqTime",
          width: "160px"
        },
        {
          text: "List of missed attendance lectures",
          value: "lectureDetails",
          width: "180px"

        },
        {
          text: "Subject",
          value: "subjectName"
        },
        {
          text: "Division",
          value: "division"
        },
        // {
        //   text: "Total Request Count",
        //   value: "count"
        // },
        {
          text: "Action",
          value: "action"
        },
        {
          text: "Enabled On",
          value: "enableT"
        },
      ],
    }
  },
  created() {
    if (this.selectedInstitute === "a2459a84-980b-4f07-82da-7f1f45616c39" || this.selectedInstitute === "5d22724a-a9f5-4f6b-9e1f-dec36dcf0a4f") {
      this.isNaralkar = true
    }
    this.userData = this.$store.getters['user/get_userData']
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    this.isSubAdmin = this.$store.getters['liveData/get_isSubAdmin'].isSubAdmin
    this.$store.commit('liveData/set_selectedActivityName', 'Attendance Analysis')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    // this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.LectureUnblockRepositoryInstance = new LectureUnblockRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.fetchData()
  },
  methods: {
    convertToIST(utcDateStr) {
      // Create a Date object from the UTC date string
      const utcDate = new Date(utcDateStr);

      // IST is 5 hours and 30 minutes ahead of UTC
      const istOffset = 5.5 * 60 * 60 * 1000;

      // Calculate the IST date and time
      const istDate = new Date(utcDate.getTime() + istOffset);

      // Extract the date and time components
      let hours = istDate.getUTCHours();
      const minutes = istDate.getUTCMinutes();
      const day = istDate.getUTCDate();
      const month = istDate.getUTCMonth() + 1; // Months are zero-based
      const year = istDate.getUTCFullYear();

      // Determine AM or PM
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // The hour '0' should be '12'

      // Format the minutes to be two digits
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

      // Construct the formatted date and time string
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${formattedMinutes} ${ampm}`;

      return formattedDateTime;
    },
    async getLecByids(ids) {
      this.lecObj = {}
      try {
        let data = await this.lectureRepositoryInstance.getLectureByIds({
          lectureIds: ids
        })
        if (data.result && data.result.length > 0) {
          data.result.map((item) => {
            if (!this.lecObj[item.lectureId]) {
              this.lecObj[item.lectureId] = item
            }
          })
        }
        // console.log('this.lecObj', this.lecObj);
      } catch (error) {
        console.error(error)
      }
    },
    async getSubjectNameByids(ids) {
      this.allsubjectObj = {}
      try {
        const data = await this.subjectRepositoryInstance.getSubjectByIds({
          subjectIds: ids
        })
        if (data.result && data.result.length > 0) {
          data.result.map((item) => {
            if (!this.allsubjectObj[item.subjectId]) {
              this.allsubjectObj[item.subjectId] = item
            }
          })
        }
        // console.log('this.allsubjectObj', this.allsubjectObj);
      } catch (error) {
        console.error(error)
      }

    },
    async getAllLectureUnblockReq() {
      this.loadingForTopic = true
      try {
        const data = await this.LectureUnblockRepositoryInstance.getAllSemesterRequestToUnblockLec({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        })
        this.allReqForSem = data.result ? data.result : []
        await this.getAllLecDetailsByIds()
        // console.log(' this.facultyNames', this.facultyNames);
        this.finalLectureBlockData = []
        if (this.allReqForSem.length > 0) {
          this.allReqForSem.map((item) => {
            if (this.facultiesNameWithUid.length > 0) {
              this.facultiesNameWithUid.map((fac) => {
                if (fac.uId === item.uId) {
                  item.facultyName = fac.fullName
                }
              })
            }
            item.subjectName = this.allsubjectObj[item.subjectId].subjectName
            let ids = []
            // console.log('item.lectureIds', item.lectureIds);
            if (item.lectureIds && item.lectureIds.length > 0) {
              item.lectureIds.map((id) => {
                // console.log('this.lecObj[id]', this.lecObj[id]);
                ids.push(this.convertToIST(this.lecObj[id].dateTime))
              })
            }
            item.lectureDetails = ids
            item.reqTime = this.convertToIST(item.requestTime)
            if (item.enableTime) {

              item.enableT = this.convertToIST(item.enableTime)
            }
          })
        }
        this.loadingForTopic = false

      } catch (error) {
        console.error(error)
        this.loadingForTopic = false

      }
    },
    async enableSignleReq(item) {
      // console.log('item', item);
      let obj = {

      }
      obj.isEnable = true
      obj.enableTime = (new Date()).toISOString()
      obj.objectId = item._id
      obj.instituteId = item.instituteId
      // console.log('obj', obj);
      try {
        await this.LectureUnblockRepositoryInstance.updateSingleRequest(obj)
        await this.getAllLectureUnblockReq()
        await this.getAllLecDetailsByIds()

      } catch (error) {
        console.error(error)
      }
    },
    async getAllLecDetailsByIds() {
      let ids = []
      let subIds = []
      if (this.allReqForSem.length > 0) {
        this.allReqForSem.map((item) => {
          ids.push(...item.lectureIds)
          subIds.push(item.subjectId)
        })
      }
      // console.log('ids', ids);
      await this.getSubjectNameByids(subIds)
      await this.getLecByids(ids)
    },
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(objToPush)
        this.semesters.reverse()
        this.semestersList = this.semesters.map((semester) => semester.semName)

        if (this.semestersList.length > 0) {
          this.selectedSemesterName = this.semestersList[0]
          this.selectSemester(this.semestersList[0])
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
    },
    async downloadTemplate() {
      // console.log("this.selectedSubject", this.selectedSubject)
      this.allLecturesData.map((info) => {
        const startTimeForLec = convertUTCDateToLocalDate(new Date(info.startTime))
        const endTimeForLec = convertUTCDateToLocalDate(new Date(info.endTime))
        info.startTime1 = timeStringConverter(startTimeForLec)
        info.endTime1 = timeStringConverter(endTimeForLec)
      })
      this.allLecturesData.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
      const obj = {
        subjectDetails: this.selectedSubject,
        studentDetails: this.studentsList,
        lectureDetails: this.allLecturesData,
        uId: this.userData.uId,
        type: 'Division'
      }
      try {
        const pdfBlob =
          await this.lectureRepositoryInstance.generateAttendanceSheetExcel(
            obj
          )
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }

    },
    async downloadTemplateForGroup() {
      // console.log("this.selectedSubject", this.selectedSubject)
      this.allLecturesData.map((info) => {
        const startTimeForLec = convertUTCDateToLocalDate(new Date(info.startTime))
        const endTimeForLec = convertUTCDateToLocalDate(new Date(info.endTime))
        info.startTime1 = timeStringConverter(startTimeForLec)
        info.endTime1 = timeStringConverter(endTimeForLec)
      })
      this.allLecturesData.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
      const obj = {
        subjectDetails: this.selectedSubject,
        studentDetails: this.studentsList,
        lectureDetails: this.allLecturesData,
        uId: this.userData.uId,
        type: 'Group',
        groupName: this.selectedGroup
      }
      try {
        const pdfBlob =
          await this.lectureRepositoryInstance.generateAttendanceSheetExcel(
            obj
          )
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }

    },
    async selectSemester() {
      this.selectedSemester = this.getSemesterObjectOfSelectedSemester(this.selectedSemesterName)
      // this.setupDataForActivity()
      await this.getAllFacultyOfSem();

    },
    async getAllFacultyOfSem() {

      this.dataFetched = false;
      this.faculties = [];
      let facultyId = []
      this.selectedFaculty = "";
      try {
        this.faculties =
          await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        // console.log('this.faculties', this.faculties);
        if (this.faculties.length > 0) {
          this.faculties.map((data) => {
            facultyId.push(data.uId)
          })
          this.facultiesNameWithUid = []
          this.facultiesNameWithUid = await this.userRepositoryInstance.getFullNameOfUsers({
            uIds: facultyId,
          });
          this.facultyNames = []
          this.facultiesNameWithUid.map((fac) => {
            this.facultyNames.push(fac.fullName)
          })
        }
      } catch (error) {
        console.log('error', error);
        this.facultyNames = []
      }

    },
    getSemesterObjectOfSelectedSemester(semName) {
      const index = this.semestersList.indexOf(semName)
      return this.semesters[index]
    },
    async setupDataForActivity() {
      this.dataFetched = false
      this.stepOneCompleted = false
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      // console.log(objToPush)
      try {
        this.departments = []
        const divs = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        divs.forEach((div) => {
          if (!this.departments.includes(div.department)) {
            this.departments.push(div.department)
          }
        })
        let subAdminAssignedDepartmentWiseLecture = []
        if (this.assignedDepartment.length > 0) {
          this.departments.map((data) => {
            if (this.assignedDepartment.includes(data)) {
              subAdminAssignedDepartmentWiseLecture.push(data)
            }
          })
          this.departments = subAdminAssignedDepartmentWiseLecture
        }
      } catch (err) {
        console.log(err)
      }
    },
    async selectDate() {
      this.dataFetched = false
      this.selectedDepartment = ''
      this.selectedFaculty = ''
      this.faculties = []
      this.facultyNames = []
      // console.log(new Date(this.selectedDate))
    },
    async selectDepartment() {
      this.dataFetched = false
      this.stepOneCompleted = false
      this.faculties = []
      this.facultyNames = []
      this.selectedFaculty = ''
      this.faculties = await this.semesterUserRepositoryInstance.getFacultiesOfADepartment({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment
      })
      let promises = []
      promises = this.faculties.map((faculty) => {
        return this.userRepositoryInstance.getFullNameOfUser({ uId: faculty.uId })
      })
      const allSettledPromises = await Promise.allSettled(promises)
      this.facultyNames = []
      for (let i = 0; i < allSettledPromises.length; i++) {
        if (allSettledPromises[i].value) {
          // console.log(allSettledPromises[i].value)
          this.facultyNames.push(allSettledPromises[i].value)
        }
      }
      // console.log(this.facultyNames)
    },
    async selectFaculty() {
      this.dataFetched = false
      this.stepOneCompleted = false
      // console.log(this.facultiesNameWithUid[this.facultyNames.indexOf(this.selectedFaculty)].uId)
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.facultiesNameWithUid[this.facultyNames.indexOf(this.selectedFaculty)].uId
      }
      try {
        const res = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(objToPush)
        // console.log(res)
        this.allocations = res
        // console.log('this.allocations', this.allocations);
        // console.log('this.isSubAdmin', this.isSubAdmin);
        // console.log('this.assignedDepartment', this.assignedDepartment);
        // console.log('this.assignedCourseYearForSubAdmin', this.assignedCourseYearForSubAdmin);
        if (this.isSubAdmin === true) {
          if (this.assignedDepartment.length > 0 || this.assignedCourseYearForSubAdmin.length > 0) {
            let filterArr = []
            if (this.assignedCourseYearForSubAdmin.length > 0) {
              if (this.allocations.length > 0) {
                this.allocations.map((allocation) => {
                  if (this.assignedCourseYearForSubAdmin.includes(allocation.courseYear + '_' + allocation.department)) {
                    filterArr.push(allocation)
                  }
                })
              }
            }
            if (this.assignedCourseYearForSubAdmin.length === 0 && this.assignedDepartment.length > 0) {
              if (this.allocations.length > 0) {
                this.allocations.map((allocation) => {
                  if (this.assignedDepartment.includes(allocation.department)) {
                    filterArr.push(allocation)
                  }
                })
              }
            }
            this.allocations = filterArr
          }

        }
      } catch (err) {
        console.log(err)
        this.allocations = []
      }

      try {

        let promises = []
        promises = this.allocations.map((allocation) => {
          return this.getSubjectDetails(allocation.subjectId)
        })
        const allSettledPromises = await Promise.allSettled(promises)
        for (let i = 0; i < allSettledPromises.length; i++) {
          if (allSettledPromises[i].value) {
            // console.log(allSettledPromises[i])
            this.subjectsMapping[allSettledPromises[i].value.subjectId] = (allSettledPromises[i].value)
          }
        }
        // console.log(this.subjectsMapping, allSettledPromises)
        this.dataFetched = true
      } catch (err) {
        console.log(err)
      }
    },
    async getSubjectDetails(subjectId) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectId: subjectId
      }
      try {
        const response = await this.subjectRepositoryInstance.getDetailsOfASubject(objToPush)
        // console.log('response', response);
        return {
          subjectId: subjectId,
          subjectName: response.subjectName,
          subjectType: response.subjectType,
          groupForSubject: response.groupForSubject !== undefined ? response.groupForSubject : []
        }
      } catch (err) {
        console.log(err)
        return err
      }
    },
    async selectAllocation(allocation) {
      // console.log('allocation', allocation);
      this.selectedAllocation = {
        name: allocation.subjectId + '_' + allocation.department + '_' + allocation.courseYear + '_' + allocation.division + '_' + allocation.batch,
        department: allocation.department,
        courseYear: allocation.courseYear,
        division: allocation.division,
        batch: allocation.batch,
        subjectId: allocation.subjectId,
      }
      this.groupOfSubject = []
      if (this.subjectsMapping[allocation.subjectId].groupForSubject.length > 0) {
        this.subjectsMapping[allocation.subjectId].groupForSubject.map((data) => {
          this.groupOfSubject.push(data.groupName)
        })
        this.selectedGroup = this.groupOfSubject[0]
      }
      // console.log('this.groupOfSubject', this.groupOfSubject);
      this.stepOneCompleted = false
      this.selectedSubject = allocation
      this.selectedSubject.subjectName = this.subjectsMapping[allocation.subjectId].subjectName
      // console.log('this.subjectsMapping', this.subjectsMapping);
      // console.log('this.selectedSubject', this.selectedSubject);
      await this.fetchData2()
    },
    async groupNameChange() {
      await this.fetchData2()
    },
    async fetchData2() {

      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          division: this.selectedSubject.division,
          batch: this.selectedSubject.batch,
          subjectId: this.selectedSubject.subjectId,
          uId: this.selectedSubject.uId,
          type: 'Admin_Division'
        }
        if (this.selectedGroup !== '') {
          let id = ''
          this.subjectsMapping[this.selectedSubject.subjectId].groupForSubject.map((data) => {
            if (data.groupName === this.selectedGroup) {
              id = data.groupId
            }
            this.groupOfSubject.push(data.groupName)
          })
          objToPush.type = 'Admin_Group'
          objToPush.groupName = this.selectedGroup
          objToPush.groupId = id
        }
        let info = await this.lectureRepositoryInstance.getAttendanceAnalysisForADivisionForASubject(objToPush)
        // console.log('info', info);
        this.studentsList = info.attendanceAnalysis
        this.studentsList.sort((a, b) => a.rollNumber - b.rollNumber)
        this.allLecturesData = info.lecture
        this.totalLectures = this.studentsList.length > 0 ? this.studentsList[0].totalConducted : 0
        for (let i = 0; i < this.studentsList.length; i++) {
          this.studentsList[i].percentage =
            this.studentsList[i].totalConducted > 0
              ? (
                (this.studentsList[i].totalAttended /
                  this.studentsList[i].totalConducted) *
                100
              ).toFixed(2)
              : 0

          // promises.push(this.getNameOfStudent(i))
        }
      } catch (err) {
        console.log(err)
        this.studentsList = []
        this.totalLectures = 0
      }
      // console.log(this.studentsList)

      const promises = []

      this.totalStudents = this.studentsList.length
      this.defaulterStudentsCount = 0
      this.nondefaulterStudentsCount = 0

      // for (let i = 0; i < this.studentsList.length; i++) {
      //   this.studentsList[i].percentage = this.studentsList[i].totalConducted > 0
      //     ? ((this.studentsList[i].totalAttended / this.studentsList[i].totalConducted) * 100).toFixed(2) : 0

      //   promises.push(this.getNameOfStudent(i))
      // }

      this.changeLimit()

      await Promise.allSettled(promises)

      this.studentsList = this.studentsList.slice()
      this.stepOneCompleted = true
    },
    async getNameOfStudent(studentIndex) {
      // console.log(studentIndex)
      try {
        if (!this.studentsList[studentIndex].name) {
          this.studentsList[studentIndex].name = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.studentsList[studentIndex].uId })
        }
      } catch (err) {
        console.log(err)
        this.studentsList[studentIndex].name = ''
      }
    },
    changeLimit() {
      if (this.selectedLimit === '' || this.selectedLimit < 0) {
        this.selectedLimit = 1
      }
      if (this.selectedLimit > 100) {
        this.selectedLimit = 100
      }
      if (isNaN(this.selectedLimit)) {
        this.selectedLimit = 0
      }
      this.defaulterStudentsCount = 0
      this.nondefaulterStudentsCount = 0
      for (let i = 0; i < this.studentsList.length; i++) {
        if (parseInt(this.studentsList[i].percentage) < parseInt(this.selectedLimit)) {
          this.studentsList[i].status = 'Defaulter'
          this.defaulterStudentsCount++
        } else {
          this.studentsList[i].status = 'Non-Defaulter'
          this.nondefaulterStudentsCount++
        }
      }
      this.defaulterStudentsPercent = ((this.defaulterStudentsCount / this.totalStudents) * 100).toFixed(0)
      this.nondefaulterStudentsPercent = ((this.nondefaulterStudentsCount / this.totalStudents) * 100).toFixed(0)
    },
    addStyleToExcelCell(cell) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFBF80' }
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
      cell.font = {
        name: 'Calibri',
        family: 4,
        size: 12,
        bold: true
      };
    },

    addBorderToExcelCell(cell) {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    },
    async downloadPdf() {
      // console.log('this.selectedSubject', this.selectedSubject, this.selectedGroup)
      try {
        let fullNames = []
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
        const dateForExcel = new Date().toISOString().split('T')
        // console.log('dateForExcel', dateForExcel);
        // const firstRow = worksheet.addRow(['', '', '09.00AM - 10.00AM']);
        // this.addStyleToExcelCell(worksheet.getCell(1, 3))
        // console.log('firstRow', firstRow);
        worksheet.getCell(2, 1).value = 'Department'
        worksheet.getCell(3, 1).value = 'CourseYear'
        worksheet.getCell(4, 1).value = 'Subject'
        if (this.selectedSubject.division !== '') {
          worksheet.getCell(5, 1).value = 'Division'
          worksheet.getCell(5, 2).value = this.selectedSubject.division
        }
        if (this.selectedSubject.division === '' && this.selectedGroup !== "") {
          worksheet.getCell(5, 1).value = 'Group'
          worksheet.getCell(5, 2).value = this.selectedSubject.groupName
        }
        if (this.selectedSubject.batch !== '') {
          worksheet.getCell(5, 1).value = 'Batch'
          worksheet.getCell(5, 2).value = this.selectedSubject.batch
        }
        worksheet.getCell(7, 1).value = 'Name'
        worksheet.getCell(7, 2).value = 'Roll No'
        worksheet.getCell(7, 3).value = 'Date'
        worksheet.getCell(6, 3).value = '09.00AM - 10.00AM'
        worksheet.getCell(2, 2).value = this.selectedSubject.department
        worksheet.getCell(3, 2).value = this.selectedSubject.courseYear
        worksheet.getCell(4, 2).value = this.selectedSubject.subjectName
        // const SecondRow = worksheet.addRow(['Name', 'Roll No', dateForExcel[0]]);
        this.addStyleToExcelCell(worksheet.getCell(2, 1))
        this.addStyleToExcelCell(worksheet.getCell(3, 1))
        this.addStyleToExcelCell(worksheet.getCell(4, 1))
        this.addStyleToExcelCell(worksheet.getCell(5, 1))
        this.addStyleToExcelCell(worksheet.getCell(6, 3))
        this.addStyleToExcelCell(worksheet.getCell(7, 1))
        this.addStyleToExcelCell(worksheet.getCell(7, 2))
        this.addStyleToExcelCell(worksheet.getCell(7, 3))

        this.addBorderToExcelCell(worksheet.getCell(2, 2))
        this.addBorderToExcelCell(worksheet.getCell(3, 2))
        this.addBorderToExcelCell(worksheet.getCell(4, 2))
        this.addBorderToExcelCell(worksheet.getCell(5, 2))
        const columnArray = []
        for (let i = 0; i < worksheet.columnCount; i++) {
          columnArray.push({ width: 20 })
        }
        worksheet.columns = columnArray
        // console.log('SecondRow', SecondRow);
        let data = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.selectedSubject.subjectId
        })
        if (this.selectedSubject.division !== '') {
          const dummyArrForFilter = data.filter((info) => {
            if (info.division === this.selectedSubject.division) {
              return info
            }
          })
          data = dummyArrForFilter
          // console.log('dummyArrForFilter', dummyArrForFilter);
        }
        if (this.selectedSubject.batch !== '') {
          const dummyArrForFilter = data.filter((info) => {
            if (info.division === this.selectedSubject.division && info.batch === this.selectedSubject.batch) {
              return info
            }
          })
          data = dummyArrForFilter
          // console.log('dummyArrForFilter', dummyArrForFilter);
        }
        if (this.selectedSubject.division === '' && this.selectedGroup !== "") {
          const dummyArr = []
          data.map((info) => {
            info.assignedGroupForSubject.map((sub) => {
              if (sub.groupName === this.selectedGroup) {
                dummyArr.push(info)
              }
            })
          })
          data = dummyArr
          // console.log('dummyArr', dummyArr);
        }
        const idsArr = []
        if (data.length > 0) {
          data.map((user) => {
            idsArr.push(user.uId)
          })
          fullNames = await this.userRepositoryInstance.getFullNameOfUsers({ uIds: idsArr })
          // console.log('fullNames', fullNames);
        }
        data.map((user, i) => {
          fullNames.map((name) => {
            if (name.uId === user.uId) {
              user.fullName = name.fullName
            }
          })
          const name = user.fullName !== undefined && user.fullName !== '' ? user.fullName : '-'
          const rollNumber = user.rollNumber !== undefined && user.rollNumber !== '' ? user.rollNumber : '-'
          // worksheet.addRow([name, rollNumber]);
          worksheet.getCell(8 + i, 1).value = name
          worksheet.getCell(8 + i, 2).value = rollNumber
        })
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.selectedSubject.subjectName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    }
  }
}
</script>

<style src='./activityAttendanceAnalysis.css'></style>
