<template src='./activityNBAModule.html'>
</template>

<script>
// import TimetableRepository from '../../Repository/timetable'
// import SubjectsRepository from '../../Repository/Subjects'
import NBARepository from '../../Repository/NBA'
import { subjectTypes } from '../../Constants/Utils/Statics'
import inputContainer from '../../Components/inputContainer'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'activityNBAModule',
  components: {
    inputContainer
  },
  data () {
    return {
      flagMission: true,
      flagPO: true,
      addingCO: false,
      dataLoaded: false,
      tempheading: '',
      editingCO: false,
      tempdescription: '',
      NBAObject: {},
      itemIndex: 0,
      editingVision: false,
      itemIndexForMission: 0,
      addingMission: false,
      editingMission: false,
      tempMission: '',
      user: {
        fruitCollection: []
      },
      userData: null,
      selectedInstitute: '',
      selectedSemester: '',
      subjects: [],
      subjectTypes: subjectTypes
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'NBA Module')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.NBARepositoryInstance = new NBARepository(this)
    // this.timetableRepositoryInstance = new TimetableRepository(this)
    // this.subjectsRepositoryInstance = new SubjectsRepository(this)
    console.log(this.selectedSemester)
    // Call API to fetch subjects
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        //   semId: this.selectedSemester.semId,
        //   uId: this.userData.uId
        }
        console.log('this is the object to push', objToPush)
        this.NBAObject = await this.NBARepositoryInstance.getNBA(objToPush)
        console.log('this is the nbaObject', this.NBAObject)
        if (this.NBAObject.instituteProgramOutcomes) {} else {
          throw console.error('No data found!')
        }
        if (this.NBAObject.instituteProgramOutcomes.length === 0) {
          // const tempObj = {
          //   id: '',
          //   heading: '',
          //   description: ''
          // }
          // this.NBAObject.instituteProgramOutcomes.push(tempObj)
          this.flagPO = false
        }
        if (this.NBAObject.instituteMission.length === 0) {
          this.NBAObject.instituteMission.push('')
          this.flagMission = false
        }
        if (this.NBAObject.instituteVision === '') {
          this.NBAObject.instituteVision = ''
        }
        this.dataLoaded = true
        // this.subjects = subjectObjects
      } catch (err) {
        console.log(err)
        this.NBAObject = {}
        this.NBAObject.instituteProgramOutcomes = []
        this.NBAObject.instituteMission = []
        this.NBAObject.instituteVision = ''
        this.flagPO = false
        this.flagMission = false
      }
    },
    async addMission () {
      if (!this.flagMission) {
        this.NBAObject.instituteMission.splice(0, 1)
        this.flagMission = true
      }
      if (!this.flagPO) {
        this.NBAObject.instituteProgramOutcomes.splice(0, 1)
        // this.flagPO = true
      }
      console.log(this.NBAObject)
      this.NBAObject.instituteMission.push(this.tempMission)
      this.tempMission = ''
      this.updateNBA()
      this.addingMission = false
    },
    async editMission (index) {
      this.itemIndexForMission = index
      this.editingMission = true
    },
    async deleteMission (index) {
      this.NBAObject.instituteMission.splice(index, 1)
      this.updateNBA()
    },
    async editItem (item) {
      this.itemIndex = item
      console.log('this is in edit', this.NBAObject.instituteProgramOutcomes[this.itemIndex])
      this.editingCO = true
    },
    async addPO () {
      if (!this.flagMission) {
        this.NBAObject.instituteMission.splice(0, 1)
        // this.flagMission = true
      }
      if (!this.flagPO) {
        this.NBAObject.instituteProgramOutcomes.splice(0, 1)
        this.flagPO = true
      }
      const objToPush = {
        poId: uuidv4(),
        heading: this.tempheading,
        description: this.tempdescription
      }
      this.NBAObject.instituteProgramOutcomes.push(objToPush)
      this.tempheading = ''
      this.tempdescription = ''
      this.addingCO = false
      this.updateNBA()
    },
    async deleteItem (index) {
      this.NBAObject.instituteProgramOutcomes.splice(index, 1)
      this.updateNBA()
    },
    async updateNBA () {
      console.log('this to update NBA', this.NBAObject)
      await this.NBARepositoryInstance.updateNBA(Object.assign({ instituteId: this.selectedInstitute }, this.NBAObject))
      this.fetchData()
    }
  }
}
</script>

<style src='./activityNBAModule.css'>
</style>
