import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Notification'

class Notification {
  constructor (context) {
    this.context = context
  }

  async getAllNotifications (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getAllNotifications, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.result
  }

  async getNotificationsForADay (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getNotificationsForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.result
  }
}

export default Notification
