<template src="./activitySemestersManagment.html"></template>
<script>
import inputContainer from '../../Components/inputContainer'
import UserRepository from '../../Repository/User'
import SemesterRepository from '../../Repository/Semester'
import SubjectRepository from '../../Repository/Subject'
import smallButton from '../../Components/smallButton'
import { AcademicManagement } from '../../Services/Utils/allowedModule'
import { convertDate } from '../../Services/Utils/DateTimeFormatter'
import arrayOps from "../../Services/Utils/arrayOps";

// Global Components
import CustomSelect from "../../Components/styleGuide/InputsAndControls/Select.vue";
export default {
  name: 'activitySemestersManagment',
  components: {
    inputContainer,
    smallButton,
    CustomSelect
  },
  props: ['prop_semName'],

  data() {
    return {
      allDataArr: [],
      assignedDepartment: [],
      datedialog: false,
      isSubAdmin: false,
      accessibleModule: [],
      accessIndex: [],
      dialogForCard: false,
      dialogTitle: '',
      dialogInfo: [],
      holidaydialog: false,
      shiftdialog: false,
      batchdialog: false,
      studentdialog: false,
      classdialog: false,
      subjectdialog: false,
      coursedialog: false,
      regdialog: false,
      timedialog: false,
      userData: '',
      selectedOperationRank: 0,
      selectedInstitute: '',
      selectedSemester: '',
      search: '',
      search1: '',
      search2: '',
      semesters: [],
      semestersList: [],
      headers1: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'HOD',
          value: 'hod'
        }
      ],
      headers2: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Subjects',
          value: 'subjects'
        }
      ],
      headers: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Class Teacher',
          value: 'classTeacher'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'End Date',
          value: 'endDate'
        },
        {
          text: 'Holidays',
          value: 'holidays'
        },
        {
          text: 'Shift',
          value: 'shift'
        },
        {
          text: 'Batches',
          value: 'batches'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      semesterDepartments: [],
      assignedCourseYearForSubAdmin: [],
      semesterCourseYears: [],
      semesterDivisions: [],
      selectedDepartment: '',
      selectedCourseYears: '',
      selectedDivisions: '',
      editingPermission: false,
      statusForSem: true,
      divisionOperations: [
        'Select Start Date/End Date',
        'Allocate Shift',
        'Set Batch Count',
        'Assign Classteachers',
        'Register Students to Subjects',
        'Define Timetable'
      ],
      courseYearOperations: ['Add Subjects'],
      departmentOperations: ['Add Students/Faculties', 'Assign HOD'],
      facultyIdToFacultyNameMapping: {}
    }
  },
  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Academic Management')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    //subAdmin access control code
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment']?.accessibleDepartment || []
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []
    // console.log('this.assignedCourseYearForSubAdmin', this.assignedCourseYearForSubAdmin);
    const isSubAdmin = this.$store.getters['liveData/get_isSubAdmin']
    this.isSubAdmin = isSubAdmin?.isSubAdmin ?? false
    if (this.isSubAdmin === true) {
      const allowedModel = this.$store.getters['liveData/get_allowedModule']
      this.accessibleModule = allowedModel
      //2 element represent Academic Management in allowed modules store file.
      this.accessibleModule[2].subModule.map((data, index) => {
        if (data.isAccessForSub === true) {
          this.accessIndex.push(index)
        }
      })
    } else {
      if (this.isSubAdmin === false) {
        AcademicManagement.map((data, index) => {
          this.accessIndex.push(index)
        })
      }
    }

    this.allDataArr = AcademicManagement

    this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.selectedOperationToSelectedOperationRankMapping = {
      'Select Start Date/End Date': 1,
      'Enter Holidays': 2,
      'Allocate Shift': 3,
      'Set Batch Count': 4,
      'Add Students/Faculties': 5,
      'Assign HOD': 6,
      'Assign Classteachers': 7,
      'Add Subjects': 8,
      'Allocate Faculties to Subjects': 9,
      'Register Students to Subjects': 10,
      'Define Timetable': 11
    }
    this.fetchData()
  },
  methods: {
    async updateStatus() {
      try {
        await this.semesterRepositoryInstance.updateSemStatus({
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId,
          isSemStatusActive: this.statusForSem
        })
        this.fetchData()
      } catch (err) {
        console.error('err', err);
      }

    },

    openDialog(data) {
      this.dialogForCard = true
      this.dialogTitle = data.cardTitle
      this.dialogInfo = data.cardDescription
    },
    handleFunctionCall(functionName) {
      this[functionName]()
    },
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        }
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        )
        let tempSems = this.semesters.map(semester => semester.semName)
        this.semestersList = arrayOps.sortArray(tempSems)?.result || []
        if (this.semestersList.length > 0) {
          if (this.selectedSemester == '' || this.selectedSemester == undefined || this.selectedSemester === null) {
            if (this.prop_semName !== '' && this.prop_semName !== undefined) {
              this.selectedSemester = this.prop_semName
              this.$store.commit("liveData/set_editingSemester", this.prop_semName);
            } else {

              this.$store.commit("liveData/set_editingSemester", this.semesters[0]);
              this.selectedSemester = this.semestersList[0]
              this.semesters.map((info) => {
                if(info.semName === this.selectedSemester){
                  
                  this.statusForSem = info.isSemStatusActive
                  // console.log('this.statusForSem', this.statusForSem);
                }
              })
              // console.log(' this.semesters',  this.semesters);
            }
          }
          this.getEditingPermissionOfASemester()
        }
      } catch (err) {
        console.log(err)
        this.semesters = []
        this.semestersList = []
      }
      try {
        let objForCourseYear = {}
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId
        }
        this.divisionList = await this.semesterRepositoryInstance.getDivisionsOfASemester(objToPush)
        // console.log('this.divisionList1', this.divisionList);
        let filterDepartmentForSubAdmin = []
        if (this.assignedDepartment?.length !== 0) {
          this.divisionList.map((info) => {
            if (this.assignedDepartment?.includes(info.department)) {
              filterDepartmentForSubAdmin.push(info)
            }
          })
          let filteredByCourseYear = []
          if (this.assignedCourseYearForSubAdmin?.length > 0) {
            this.assignedCourseYearForSubAdmin.map((cy) => {
              let [course, department1] = cy.split('_')
              filterDepartmentForSubAdmin.map((dept) => {
                if (dept.department === department1 && dept.courseYear === course) {
                  filteredByCourseYear.push(dept)
                }
              })
            })
            filterDepartmentForSubAdmin = filteredByCourseYear
          }
          this.divisionList = filterDepartmentForSubAdmin
        }
        this.constructAcademicDataTree()
      } catch (err) {
        console.error(err)
      }
    },

    async constructAcademicDataTree() {
      this.academicDataTree = {}

      this.divisionList.forEach((sem) => {
        if (!this.academicDataTree[sem.department]) {
          this.academicDataTree[sem.department] = {}
        }
        if (!this.academicDataTree[sem.department][sem.courseYear]) {
          this.academicDataTree[sem.department][sem.courseYear] = []
        }
        this.academicDataTree[sem.department][sem.courseYear].push(sem.division)
      })

      let idForDisplay = 0
      this.departments = []
      this.courseYears = []
      this.divisions = []

      Object.keys(this.academicDataTree).forEach((department, index) => {
        // if(){
        this.departments.push({
          name: department,
          department: department,
          id: index
        })

        const departmentObject = {
          id: idForDisplay,
          name: department,
          children: []
        }

        const courseYearsOfADepartment = {
          id: idForDisplay,
          name: department,
          department: department,
          children: []
        }

        idForDisplay++

        Object.keys(this.academicDataTree[department]).forEach((courseYear) => {
          const divisionsOfACourseYear = {
            id: idForDisplay,
            name: courseYear,
            children: []
          }

          const courseYearObject = {
            id: idForDisplay,
            name: courseYear,
            department: department,
            courseYear: courseYear
          }

          idForDisplay++

          this.academicDataTree[department][courseYear].forEach((division) => {
            divisionsOfACourseYear.children.push({
              id: idForDisplay++,
              department: department,
              courseYear: courseYear,
              name: division
            })
          })

          departmentObject.children.push(divisionsOfACourseYear)
          courseYearsOfADepartment.children.push(courseYearObject)
        })

        this.divisions.push(departmentObject)
        this.courseYears.push(courseYearsOfADepartment)
        // }
      })

      this.loading = false
      this.selectedOperation = this.prop_selectedOperation || ''
      this.selectedDepartment = this.prop_selectedDepartment || ''
      this.selectedCourseYear = this.prop_selectedCourseYear || ''
      this.selectedDivision = this.prop_selectedDivision || ''
      // this.selectOperation()
      this.loading = true
    },

    selectSemester() {
      let semester = {}
      this.semesters.map((sem) => {
        // console.log('sem123', sem);
        if (sem.semName === this.selectedSemester) {
          semester = sem
          this.statusForSem = sem.isSemStatusActive
          // console.log('this.statusForSem', this.statusForSem);
        }
      })

      // console.log('semester', semester);
      this.$store.commit("liveData/set_editingSemester", semester);
      this.fetchData()
      this.getDepartmentDataForSemesters()
      this.getCourseYearDataForSemesters()
      this.getDivisionsForSemesters()
    },
    async getEditingPermissionOfASemester() {
      this.semesterCourseYears = []
      this.semesterDivisions = []
      this.editingPermission = false
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId
        }
        this.editingPermission = await this.semesterRepositoryInstance.getEditingPermissionOfASemester(
          objToPush
        )
      } catch (err) {
        console.error(err)
        this.editingPermission = false
      }
    },
    async getDepartmentDataForSemesters() { },
    async getCourseYearDataForSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId
        }
        this.semesterCourseYears = []
        const subjectsOfAnInstitute = await this.subjectRepositoryInstance.getSubjectsOfAInstituteInASemester(
          objToPush
        )

        const departmentCourseYearToSubjectsMapping = {}

        subjectsOfAnInstitute.forEach(subjectObject => {
          if (
            !departmentCourseYearToSubjectsMapping[
            subjectObject.department + subjectObject.courseYear
            ]
          ) {
            departmentCourseYearToSubjectsMapping[
              subjectObject.department + subjectObject.courseYear
            ] = {
              department: subjectObject.department,
              courseYear: subjectObject.courseYear,
              subjects: []
            }
          }

          departmentCourseYearToSubjectsMapping[
            subjectObject.department + subjectObject.courseYear
          ].subjects.push(subjectObject)
        })

        this.semesterCourseYears = Object.values(
          departmentCourseYearToSubjectsMapping
        )
      } catch (err) {
        console.error(err)
        this.semesterCourseYears = []
      }
    },
    async getDivisionsForSemesters() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.getSemesterObjectOfSelectedSemester().semId
        }
        this.semesterDivisions = []
        this.semesterDivisions = await this.semesterRepositoryInstance.getDivisionsOfASemester(
          objToPush
        )
        for (let i = 0; i < this.semesterDivisions.length; i++) {
          if (this.semesterDivisions[i].classTeacher) {
            this.facultyIdToFacultyNameMapping[
              this.semesterDivisions[i].classTeacher
            ] =
              (await this.userRepositoryInstance.getFullNameOfUser({
                uId: this.semesterDivisions[i].classTeacher
              })) || ''
          }
        }
        // TODO: shift name of shift id
        // for (let i = 0; i < this.semesterDivisions.length; i++) {
        //   if (this.semesterDivisions[i].classTeacher) {
        //     this.facultyIdToFacultyNameMapping[this.semesterDivisions[i].classTeacher] = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.semesterDivisions[i].classTeacher }) || ''
        //   }
        // }
      } catch (err) {
        console.error(err)
        this.semesterDivisions = []
      }
    },
    gotoEditSemesterDivision(operation, item) {
      // console.log(operation, item)

      this.selectedDepartment = item.department
      this.selectedCourseYear = item.courseYear
      this.selectedDivision = item.division
      // this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push({
        name: 'activityEditSemester',
        params: {
          prop_selectedOperation: operation,
          prop_selectedDepartment: item.department,
          prop_selectedCourseYear: item.courseYear,
          prop_selectedDivision: item.division
        }
      })
    },
    gotoEditSemesterCourseYear() { },
    gotoEditSemesterDepartment() { },
    gotoEditSemester() {
      // this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push('/activityEditSemester')
      // this.$router.push({
      //   name: 'activityEditSemester',
      //   params: {
      //     PropsSelectedSemester: this.PropsSelectedSemester
      //   }
      // })
    },
    gotoactivityEditSemesterDates() {
      // console.log("selectedSemester", this.selectedSemester)
      // console.log("selectedDepartment", this.selectedDepartment)
      // console.log("selectedCourseYear", this.selectedCourseYear)
      // console.log("selectedDivision", this.selectedDivision)
      // console.log("divisions", this.divisions)

      this.$router.push({
        name: 'activityEditSemesterDates',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions
        }
      })
    },
    gotoactivityEditSemesterHolidays() {
      this.$router.push({
        name: 'activityEditSemesterHolidays',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,

          tree: this.departments
        }
      })
    },
    async gotoactivityEditSemesterDept() {
      const semester = this.getSemesterObjectOfSelectedSemester()
      // console.log('this.selectedSemester', this.getSemesterObjectOfSelectedSemester());
      this.$router.push({
        name: 'activityDepartmentManagement',
        params: {
          PropsSelectedSemester: semester,
          tree: this.departments
        }
      })
    },
    async gotoactivitySubjectGroup() {
      // console.log('this.selectedSemester', this.getSemesterObjectOfSelectedSemester());
      this.$router.push({
        name: 'activitySubjectGroup',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          tree: this.courseYears
        }
      })
    },
    convertDate(time) {
      return convertDate(time)
    },
    getSemesterObjectOfSelectedSemester() {
      let myData = {}
      this.semesters.map((obj) => {
        if (obj.semName === this.selectedSemester) {
          myData = obj
        }
      })
      return myData
    },
    gotoCreateSemester() {
      this.$router.push('/activityCreateSemester')
    },
    gotoactivityEditSemesterShifts() {
      this.$router.push({
        name: 'activityEditSemesterShifts',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions

        }
      })
    },
    gotoactivityEditSemesterBatches() {

      this.$router.push({
        name: 'activityEditSemesterBatches',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions

        }
      })
    },
    gotoactivityEditSemesterUsers() {
      this.$router.push({
        name: 'activityEditSemesterUsers',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions
        }
      })
    },

    gotoactivityEditSemesterTimetable() {
      this.$router.push({
        name: 'activityEditSemesterTimetable',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions
        }
      })
    },
    gotoactivityEditSemesterClassteacher() {
      this.$router.push({
        name: 'activityEditSemesterClassteacher',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions
        }
      })
    },
    gotoactivityEditStudentsRegistration() {
      this.$router.push({
        name: 'activityEditStudentsRegistration',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions
        }
      })
    },
    gotoactivityEditSemesterSubjects() {
      this.$router.push({
        name: 'activityEditSemesterSubjects1',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_tree: this.courseYears
        }
      })
    },
    gotoactivityEditFacultyAllocation() {
      this.$router.push({
        name: 'activityEditFacultyAllocation',
        params: {
          PropsSelectedSemester: this.getSemesterObjectOfSelectedSemester(),
          prop_SelectedDepartment: this.selectedDepartment,
          prop_SelectedCourseYear: this.selectedCourseYear,
          prop_SelectedDivision: this.selectedDivision,
          tree: this.divisions
        }
      })
    },

  }
}
</script>
<style scoped src="./activitySemestersManagment.css"></style>