<template src="./ExamRule.html"></template>

<script>
export default {
  name: "ExamRule",
  props: ["prop_rule"],
  data() {
    return {
      formativePassingPercentage: 0,
      ruleName: "",
      ruleDescription: "",
      selectedSystemPreference: "",
      gradeDetails: [],
      passingPreference: "",
      combinedPassingPercentage: 0,
      summativePassingPercentage: 0,
      specialStatuses: [],
      passingPreferenceText: "",
      formativeOnlyPassingPercentage: 0,
      summativeOnlyPassingPercentage: 0,
      gradeDetailsTableHeader: [
        {
          text: "Grade Name",
          value: "gradeName",
          align: "center",
        },
        {
          text: "Grade Range",
          value: "gradeRange",
          align: "center",
        },
        {
          text: "Grade Points",
          value: "gradePoints",
          align: "center",
        },
        {
          text: "Grade Remark",
          value: "gradeRemark",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.setData();
  },
  watch: {
    // Watch for changes to prop_rule and trigger setData when it changes
    prop_rule: {
      handler(newVal) {
        this.setData(); // Call setData when prop_rule is updated
      },
      deep: true, // Deep watch to detect changes inside objects
      immediate: true,  // Make sure it runs even on first render
    },
  },
  methods: {
    setData() {
      if (this.prop_rule) {
        this.ruleName = this.prop_rule.ruleName || "";
        this.ruleDescription = this.prop_rule.ruleDescription || "";
        if (this.prop_rule.systemPreference !== "") {
          this.selectedSystemPreference = this.prop_rule.systemPreference || "";
          if (this.prop_rule.gradeDetails) {
            this.gradeDetails = this.prop_rule.gradeDetails || [];
          }
        }
        if (this.prop_rule.passingPreference !== "") {
          this.passingPreference = this.prop_rule.passingPreference || "";
          if (this.prop_rule.passingPreference === "combined") {
            this.passingPreferenceText =
              "Combined passing for formative and Summative exams";
            this.combinedPassingPercentage =
              this.prop_rule.combinedPassingPercentage || 0;
          } else if (this.prop_rule.passingPreference === "separate") {
            this.passingPreferenceText =
              "Separate passing for formative and Summative Exams";

            this.formativePassingPercentage =
              this.prop_rule.formativePassingPercentage || 0;
            this.summativePassingPercentage =
              this.prop_rule.summativePassingPercentage || 0;
          } else if (this.passingPreference === "formativeOnly") {
            this.passingPreferenceText = "Only Formative Exam Passing";
            this.formativeOnlyPassingPercentage =
              this.prop_rule.formativeOnlyPassingPercentage || 0;
          } else if (this.passingPreference === "summativeOnly") {
            this.passingPreferenceText = "Only Summative Exam Passing";
            this.summativeOnlyPassingPercentage =
              this.prop_rule.summativeOnlyPassingPercentage || 0;
          }
        }

        if (this.prop_rule?.specialStatuses?.length > 0) {
          this.specialStatuses = this.prop_rule.specialStatuses || [];
        }
      }
    },
  },
};
</script>
<style src="./ExamRule.css" scoped></style>