import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/AcademicReference'

class AcademicReference {
  constructor (context) {
    this.context = context
  }

  async addDivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.postRequest(ApiNames.addDivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.division
  }
  async getAcademicReference (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.getRequest(ApiNames.getAcademicReference, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getDepartments (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.getRequest(ApiNames.getDepartments, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.departments
  }

  async getCourseYears (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.getRequest(ApiNames.getCourseYears, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.courseYears
  }
  async getCourseYearsForDepartments (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.getRequest(ApiNames.getCourseYearsForDepartments, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.courseYears
  }

  async getDivisions (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.getRequest(ApiNames.getDivisions, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.divisions
  }

  async getDivisionsOfAnInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    const response = await networkManager.getRequest(ApiNames.getDivisionsOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.divisions
  }

  async removeDepartment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    await networkManager.deleteRequest(ApiNames.removeDepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async removeCourseYear (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    await networkManager.deleteRequest(ApiNames.removeCourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async removeDivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    console.log(data)
    await networkManager.deleteRequest(ApiNames.removeDivision, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default AcademicReference
