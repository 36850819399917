import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/UserAuthentication";

class UserAuthentication {
  constructor(context) {
    this.context = context;
  }



  async loginPasswordByUserName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.loginPasswordByUserName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async updateUserAuthentication(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateUserAuthentication,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async getAllSubAdminOfInstitute(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllSubAdminOfInstitute,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.userAuthentications;
  }
  async getUserAuthenticationByUid(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getUserAuthenticationByUid,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.userAuthentications;
  }
  async getUserAuthenticationByUidAndUserName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getUserAuthenticationByUidAndUserName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.userAuthentications;
  }
  async getUserAuthenticationsByUIds(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getUserAuthenticationsByUIds,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.userAuthentications;
  }

}

export default UserAuthentication;
