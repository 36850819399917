<!-- Documentation: https://studiumtech.atlassian.net/wiki/spaces/LMS1/pages/180420631/Text+Field -->

<template>
  <div :style="[widthStyle, parentStyle]">
    <div v-if="showCustomLabel" :style="labelStyle">
      {{ label }}
    </div>
    <v-text-field
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :dense="dense"
      :solo="solo"
      :hide-details="hideDetails"
      :flat="flat"
      :value="transformedValue"
      :disabled="disabled"
      @input="onInput"
      @change="$emit('change', $event)"
      :background-color="computedBackgroundColor"
      :class="[customClass, textAlignClass]"
      v-bind="$attrs"
    >
      <slot></slot>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "VTextField",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "#E1E2F8",
    },
    disabledStateColor: {
      type: String,
      default: "#F0F0F0", // Default color when disabled
    },
    customClass: {
      type: String,
      default: "",
    },
    soloLabelFontSize: {
      type: [Number, String],
      default: 16, // in pixels(px)
    },
    width: {
      type: [String, Number],
      default: "100%", // Default width
    },
    parentStyle: {
      type: Object,
      default: () => ({}), // Custom styles for parent div
    },
    capitalLettersOnly: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: "left", // Default alignment
      validator: (value) => ["left", "right", "center"].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false, // Disable the text field
    },
  },
  computed: {
    showCustomLabel() {
      return this.solo && this.label !== "";
    },
    labelStyle() {
      return {
        fontSize: this.formatSizeToPx(this.soloLabelFontSize),
      };
    },
    widthStyle() {
      return typeof this.width === "number" ? { width: `${this.width}px` } : { width: this.width };
    },
    transformedValue() {
      let value =
        typeof this.value === "string" ? this.value.trim() : this.value;
      if (this.capitalLettersOnly && typeof value === "string") {
        value = value.toUpperCase().replace(/[^A-Z]/g, ""); // Filter out non-letters
      }
      return value;
    },
    textAlignClass() {
      return `text-align-${this.textAlign}`;
    },
    computedBackgroundColor() {
      return this.disabled ? this.disabledStateColor : this.backgroundColor;
    },
  },
  methods: {
    formatSizeToPx(value) {
      if (typeof value === "number") {
        return `${value}px`;
      } else if (typeof value === "string") {
        return value.includes("px") ? value : `${value}px`;
      }
      return "";
    },
    onInput(value) {
      if (this.capitalLettersOnly) {
        value = value.toUpperCase().replace(/[^A-Z]/g, "");
      }
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
/* Use ::v-deep to access the internal input element of v-text-field */
::v-deep .v-input__control input {
  text-align: left;
}

/* Dynamically apply alignment using the textAlign prop */
::v-deep .text-align-left .v-input__control input {
  text-align: left;
}

::v-deep .text-align-right .v-input__control input {
  text-align: right;
}

::v-deep .text-align-center .v-input__control input {
  text-align: center;
}
</style>
