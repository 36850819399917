<template src="./activityEditExam.html"></template>
<script>
import ExamRepository from "../../../Repository/Exam";
import ExamStudentRepository from "../../../Repository/ExamStudent";
import SemesterRepository from "../../../Repository/Semester";
import SubjectsRepository from "../../../Repository/Subject";
import showStatus from "../../../NetworkManager/showStatus";
import inputContainer from "../../../Components/inputContainer";
import VueApexCharts from "vue-apexcharts";
import apiV3 from "../../../NetworkManager/apiV3";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserAuthenticationRepository from "../../../Repository/UserAuthentication";
import nbaRepository from "../../../Repository/NBA";
import UserRepository from "../../../Repository/User";
import InfrastructureReferenceRepository from "../../../Repository/InfrastructureReference";
import { subjectTypes } from "../../../Constants/Utils/Statics";
import auth from "../../../Services/Firebase/auth";
import ExcelJS from "exceljs";
import { v4 } from "uuid"
import {
  generateJsonFromExcel,
  generateFromJson,
  createExcelThroughJson,
  createExcelThroughJsonForMultipleSheets
} from "../../../utils/excel";

import axios from "axios";

export default {
  name: "activityCreateExam",
  components: {
    inputContainer,
    apexcharts: VueApexCharts,
  },
  props: ["prop_exam"],

  data() {
    return {
      notSelectedStudentList: [],
      selectedStudentList: [],
      updatedSubjectThresholdArray: [],
      subjectThresholdArray: [],
      subjectThreshold: [],
      nbaAttainmentObject: {},
      subjectMark: [],
      updatedAllocatedSubjects: [],
      allSubjectDetails: [],
      subjectNameArray: [],
      subjectIdArray: [],
      departmentNameArray: [],
      courseYearArray: [],
      examDescription: "",
      selectedExamType: "",
      examEndDate: "",
      examStartDate: "",
      examDepartmentName: "",
      examCourseYear: "",
      examName: "",
      overallThresholdPercentage: 0,
      allSubjects: false,
      sub: "",
      nbaCriteria: false,
      e1: 1,
      limits: [0, 100],
      selectedExam: {},
      isBasicDetailEditable: false,
      isSubjectDetailEditable: false,
      uploadQuestionsDialog: false,
      uploadMarksDialog: false,
      thresholdType: null,
      examType: "",
      userData: null,
      examName: "",
      examDescription: "",
      criteria: [],
      startDate: "",
      endDate: "",
      isOnline: "",
      examTypes: ["CIE(Internal)", "SEE(External)"],
      runningSemesters: [],
      modal1: false,
      modal2: false,
      loading: true,
      emptyName: false,
      emptyDescription: false,
      noCriteria: false,
      noStartDate: false,
      invalidStartDate: false,
      noEndDate: false,
      invalidEndDate: false,
      checkbox: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialogExcel: false,
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedDivision: "",
      departments: [],
      courseYears: [],
      divisions: [],
      selectedInstitute: "",
      selectedSemester: {},
      uploadMarksDialog: false,
      uploadMarksManuallyStatus: false,
      uploadQuestionManuallyStatus: false,
      overallPercentageStatus: false,
      indivitualThresholdStatus: false,
      headers: [
        {
          text: "Ques No.s",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Question", value: "Question" },
        { text: "Marks", value: "Marks" },
        { text: "Mapped COs", value: "MappedCOs" },
      ],
      // donut chart
      donutOptions: {
        chart: {
          type: "donut",
          zoom: {
            enabled: false,
          },
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        noData: {
          text: "No Data.",
          style: {
            color: "red",
            fontSize: "22px",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          fontSize: "14px",
          position: "right",
          formatter: function (donutSeries, opts) {
            return [
              donutSeries,
              " - ",
              opts.w.globals.series[opts.seriesIndex],
            ];
          },
          markers: {
            width: 25,
            height: 25,
            radius: 20,
          },
          itemMargin: {
            vertical: 5,
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              size: "55%",
            },
          },
        },
        labels: [
          "0 % - 10 %",
          "10 % - 20 %",
          "20 % - 30 %",
          "30 % - 40 %",
          "40 % - 50 %",
          "50 % - 60 %",
          "60 % - 70 %",
          "70 % - 80 %",
          "80 % - 90 %",
          "90 % - 100 %",
        ],
        colors: [
          "#FA3333",
          "#FA8733",
          "#FAB533",
          "#07603F",
          "#0DA5A5",
          "#11BC7D",
          "#060E9A",
          "#720E9A",
          "#3361FA",
          "#B4B6EA",
        ],
        series: [],
      },
      // Bottom Dialog Box Table
      bloomsDonutOptions: {
        chart: {
          type: "donut",
          zoom: {
            enabled: false,
          },
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        noData: {
          text: "No Data.",
          style: {
            color: "red",
            fontSize: "22px",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          fontSize: "14px",
          position: "right",
          formatter: function (donutSeries, opts) {
            return [
              donutSeries,
              " - ",
              opts.w.globals.series[opts.seriesIndex],
              "%",
            ];
          },
          markers: {
            width: 25,
            height: 25,
            radius: 20,
          },
          itemMargin: {
            vertical: 5,
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              size: "55%",
            },
          },
        },
        labels: [
          "Create (C)",
          "Evaluate (E)",
          "Analyse (A)",
          "Apply (Ap)",
          "Understand (U)",
          "Remember (R)",
        ],
        colors: [
          "#FA3333",
          "#FA8733",
          "#07603F",
          "#0DA5A5",
          "#060E9A",
          "#720E9A",
        ],
        series: [],
      },
      grandTotalOfObtainedMarks: 0,
      selectedBloomSubjectName: "all",
      bloomsDataObject: {
        "Create (C)": 0,
        "Evaluate (E)": 0,
        "Analyse (A)": 0,
        "Apply (Ap)": 0,
        "Understand (U)": 0,
        "Remember (R)": 0,
      },
      subjectNames: [],
      subjectAllocation: [],
      subjectDetails: [],
      studentList: [],
      studentArray: [],
      studentNames: [],
      applicableStudents: [],
      allStudents: [],
      facultyList: [],
      facultyArray: [],
      facultyNames: [],
      allFaculty: [],
      roomData: [],
      roomNames: [],
      allowedRoom: "",
      allRoom: [],
      roomName: "",
      subjects: [],
      subjectNames: [],
      allSubject: [],
      applicableStudents: [],
      duration: "",
      totalMarks: "",
      passingMarks: "",
      tempSelectedRoom: {},
      editingRoom: false,
      roomAllocation: [],
      selectedStudent: [],
      selectedStudentArray: [],
      selectedFaculty: [],
      selectedFacultyArray: [],
      selectedApplicablestudents: [],
      openPanel: undefined,
      editSubjectDetails: false,
      editQuestionDetails: false,
      editStatus: false,
      deleteDialog: false,
      uploadMarksDialog: false,
      addNewRoomDialog: false,
      allStudentList: {},
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selectedDate: "",
      selectedTime: "",
      dateModal: false,
      timeModal: false,
      indexValue: "",
      roomAllocationObject: {},
      show: false,
      studentHeaders: [
        {
          text: "Roll No",
          align: "start",
          sortable: false,
          value: "rollNo",
        },
        { text: "Student Name", value: "fullName" },
        { text: "PRN", value: "studentPrn" },
      ],
      facultyHeaders: [
        {
          text: "Faculty Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "email", value: "email" },
      ],
      studentData: [],
      selected: [],
      series: [44, 55, 41, 17, 15],

      headerSubject: [
        {
          text: "RN",
          value: "rollNo",
        },
        { text: "Name", value: "Name" },
        { text: "Overall", value: "overall" },
      ],
      studentDialog: false,
      search: "",
      updatedRoom: "",
      deleteSubject: {},
      questionObject: [],
      totalQuestions: 0,
      questionsArray: [],
      questionSet: [],
      COFromNba: [],
      selectedCOS: [],
      marksHeader: [],
      studentData: [],
      selected: [],
      studentMarks: "",
      queTitle: "",
      selectedStud: {},
      questionWiseMarks: [],
      indivitualMarks: "",
      questionsSet: [],
      questionDetails: [],
      courseOutcomeData: [],
      series: [44, 55, 41, 17, 15],
      nbaObject: {},
      test: "",
      applicableHeader: [
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "PRN",
          value: "prn",
        },
      ],
      studentHeaders: [
        {
          text: "Roll No",
          align: "start",
          sortable: false,
          value: "rollNo",
        },
        { text: "Student Name", value: "fullName" },
        { text: "PRN", value: "studentPrn" },
      ],
      facultyHeaders: [
        {
          text: "Faculty Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "email", value: "email" },
      ],
      dataLoaded: false,
      examDataLoaded: false,
      rangeVal: [0, 100],
      analysisV3: [],
      attendanceDistribution: {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0,
      },
      subjectTypes: subjectTypes,
      subjectIds: [],
      analysisTableItems: [],
      analysisTableHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Total",
          value: "total",
        },
      ],
      failedCount: 0,
      failingLimit: 40,
      tempLimit: 40,
      subjectIdToSubjectMapping: {},
      namesMapping: {},
      isPassingThresholdEditable: true,
      passingThreshold: "40",
      search: "",
      totalPercentage: 0,
      totalNumberOfStudents: 0,
      averagePerformance: 0,
      donutDataLoaded: false,
      selectedSubjectName: "",
      tempTotalMarks: "",
      bloomDialog: false,
      grandTotal: 0,
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)",
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)",
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)",
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)",
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)",
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)",
        },
      ],
      tableHeaders: [],
      isAnalysisVisible: false,
      subjectDataLoaded: false,
      toggleQuestionUpload: null,
      assignedCoArray: [],
      questionDataLoaded: false,
      overallMarksTableHeader: [
        {
          text: "",
          value: "action",
          align: "center",
        },
        {
          text: "RN",
          value: "rollNo",
        },
        {
          text: "Student Name",
          value: "studentName",
        },
        {
          text: "PRN",
          value: "studentPrn",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Total Obtained Marks",
          value: "obtainedMarks",
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
        },
        {
          text: "Symbol for grace marks",
          value: "graceMarksSymbol",
        },
      ],
      uploadOverallMarksDialog: false,
      obtainedMarks: 0,
      noQuestionOverallThresholdPercentage: 0,
      toggleMarksUpload: null,
      updateQuestionDetailsLoader: false,
      updateSubjectDetailsLoader: false,
      noMarks: false,
      cancelQuestionDialog: false,
      noQuestions: false,
      toggleCancelQuestionDialog: false,
      questionSaveAsDraft: false,
      uploadQuestionExcelDialog: false,
      questionExcelFile: "",
      questionsLoadedViaExcel: false,
      uploadMarksExcelDialog: false,
      marksExcelFile: "",
      marksLoadedViaExcel: false,
      marksSampleExcelLink: "",
      subjectDetailsLoaded: false,
      currentDate: "",
      saveSubjectDetails: false,
      publishExam: false,
      maxMarks: 0,
      maxQuestionMarks: 0,
      inputRules: {
        maxGraceMarks: (value) =>
          Number(value) <= this.maxGraceMarks ||
          `Grace Marks cannot be more than ${this.maxGraceMarks}`,
        specialCharactersOnly: (value) => {
          if (value) {
            const pattern = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
            return pattern.test(value) || "Only special characters allowed.";
          }
        },
        numberOnly: (value) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || "Please Enter Only Numbers.";
        },
        onlySpecificStrings: (value) => {
          // if (isNaN(value)) {
          //   if (value !== "NA" || value !== "F" || value !== "ABS" || value !== "W" || value !== "NE" || value !== "X" || value !== "SNG") {
          //     return "Only NA, F, ABS, W, NE, X, SNG  are allowed.";
          //   }
          // }
          if (value) {
            const pattern = /^[0-9]*(\.[0-9]+)?$|^(NA|X|ABS|NE|W|F|SNG|P|NP)$/i;
            return (
              pattern.test(value) ||
              "Only NA, F, ABS, W, NE, X, SNG, P, NP  are allowed."
            );
          }
        },
        numberCounter: (value) => value.length <= 2 || "Max 2 numbers",
        valueLimit: (value) => {
          if (!isNaN(value)) {
            if (Number(value) > this.maxMarks) {
              return `Marks cannot be more than ${this.maxMarks}`;
            }
          }
        },
        maxQuestionMarks: (value) =>
          Number(value) <= this.maxQuestionMarks ||
          `Marks cannot be more than ${this.maxQuestionMarks}`,
        passingMarksLimit: (value) =>
          Number(value) <= this.totalMarks ||
          `Marks cannot be more than ${this.totalMarks}`,
      },
      subjectDetailsAsDraftLoader: false,
      assignedBloomsArray: [],
      totalBloomCount: 0,
      saveChangesBtnDisable: true,
      dialogWarning: false,
      questionDetailsSectionLoader: false,
      studResponse: [],
      nbaData: {},
      attainmentLevelObject: {},
      beStudents: 0,
      meStudents: 0,
      eeStudents: 0,
      beStudentPercentage: 0,
      eeStudentPercentage: 0,
      meStudentPercentage: 0,
      overallAttainmentLoader: false,
      selectedAttainmentSubject: {},
      overallAttainmentSubjects: [],
      levelAttainmentQuestionArray: [],
      teal: "#008080",
      red: "#f61919",
      targetsOfInstitute: {},
      instituteType: "",
      basicDetailsLoader: false,
      coWiseAttainment: [],
      coWiseAttainmentLoader: true,
      attainmentCalculationType: "",
      instituteCieSee: "",
      questionDetailSaveError: false,
      questionPanelError: "",
      excelCustomError: false,
      excelCustomErrorMessage: "",
      studentExceedingMarksLimit: [],
      currentExam: {},
      attemptToShow: "",
      attemptColor: "",
      attemptBackgroundColor: "",
      attemptNumber: 1,
      reExam: false,
      subjectToUploadMarks: {},
      subjectToUploadQuestions: {},
      allCourseYearStudents: [],
      allCourseYearStudentDetails: [],
      examFormTableHeaders: [
        {
          text: "Sr.no",
          value: "srNo",
        },
        {
          text: "Name",
          value: "studentName",
        },
        {
          text: "PRN",
          value: "studentPrn",
        },
        {
          text: "Exam form status",
          value: "examFormStatus",
        },
      ],
      examFormTableData: [],
      courseYearDataLoader: false,
      examFormPublished: false,
      examFormTableSearch: "",
      maxCapacityOfOfTheRoom: 0,
      emailNotificationSentModal: false,
      emailSentNotificationArray: [],
      tempGlobalThreshold: 0,
      currentUserAuthentication: {},
      accessForInvigilation: true,
      accessForExamForm: true,
      accessForBasicDetails: true,
      accessForQuestionDetails: true,
      accessForQuestionDetails: true,
      accessForAnalysis: true,
      accessForMarks: true,
      graceMarksSymbol: "",
      maxGraceMarks: 5,
      graceMarks: 0,
      studentFilter: "all",
      downloadFilteredStudentsLoader: false,
      backgroundColor:'#E7E7FF',
      tabs: 0,
      tabs1: 0,
      tabs2: 0,
      secondaryTabItems: ['Question Paper'], //, 'Rubrics', 'Model Answers'
      createdSets: [{
        no: 1,
        setName: 'set 1'
      }],
      setsObjectForQuestions: {
        'set 1': []
      },
      headers5 : [],
      questionsArray1: [],
      coNames:[],
      bloomsNames: [],
      questionDialogue: false,
      orQuestionDialogue: false,
      subQuestionDialogue: false,
      headers4: [
        {
          text: 'Sections',
          value: 'sectionNames',
          sortable: false,
          width: "11%",
          align: "center"
        },
        {
          text: "Qn No",
          value: "questionNumber",
          sortable: false,
          width: "7%",
          align: "center"
        },
        {
          text: 'Sub Qn No',
          value: 'subQnNo',
          sortable: false,
          width: "8%",
          align: "center"
        },
        {
          text: 'Question Statements and Section Names   ',
          value: 'question',
          sortable: false,
          width: "40%",
          align: "center"
        },
        {
          text: "Marks",
          value: "questionMarks",
          width: "7%",
          align: "center"
        },
        {
          text: 'Assign Marks',
          value: 'assignMarks',
          sortable: false,
          width: "10%",
          align: "left"
        }
      ],
      headers1: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "9%",
          align: "center"
        },
        {
          text: "PRN No",
          value: "studentPrn",
          width: "9%",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'studentName',
          width: "20%",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
          width: "10%",
          align: "center"
        },
        {
          text: "Symbol for grace marks",
          value: "graceMarksSymbol",
          width: "10%",
          align: "center"
        },
        {
          text: "Assign Total Marks",
          value: "obtainedMarks",
          sortable: false,
          width: "14%",
          align: "center"
        }
      ],
      headers2: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "auto",
          align: "center"
        },
        {
          text: "PRN No",
          value: "studentPrn",
          width: "auto",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'studentName',
          width: "auto",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Assign Section Wise Marks",
          value: "allSections",
          sortable: false,
          width: "auto",
          align: "center"
          // subheaders: [{ text: "Section Name", value: "sectionName" }]
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
          width: "10%",
          align: "center"
        },
        {
          text: "Symbol for grace marks",
          value: "graceMarksSymbol",
          width: "10%",
          align: "center"
        },
        {
          text: 'Total Marks',
          value: 'obtainedMarks',
          sortable: false,
          width: "14%",
          align: "center"
        }
      ],
      headers3: [
        {
          text: 'Sr No',
          value: 'srNo',
          width: "9%",
          align: "center"
        },
        {
          text: "PRN No",
          value: "studentPrn",
          width: "9%",
          align: "center"
        },
        {
          text: 'Student Name',
          value: 'studentName',
          width: "20%",
          align: "center"
        },
        {
          text: 'Set attempted',
          value: 'sets',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Obtained Marks",
          value: "obtainedMarks",
          sortable: false,
          width: "14%",
          align: "center"
          // subheaders: [
          //   { text: 'Section 1', value: 'section1Marks', align: 'center' },
          //   { text: 'Section 2', value: 'section2Marks', align: 'center' }
          // ]
        }
      ],
      allQuestions: [],
      allSections: [],
      black: '#000000',
      selectedRadio: null,
      createdQuestions: null,
      sectionsOfSelectedSet: [],
      questionWiseMarksDialog: false,
      openQuestionsPreview: false,
      uploadOverallMarksViaExcel:false,
      schema : {},
      questionMarksExcelFile: null,
      excelErrors: [],
      uploadSectionMarksViaExcel: false,
      uploadQuestionViseMarksViaExcel : false,
      coNumbers : []
    };
    
  },
  async created() {
    for (let i = 0; i < this.allBlooms.length; i++) {
      this.bloomsNames.push(this.allBlooms[i].name)
    }
    this.editRoomIndex = -1;
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.instituteType = this.$store.getters["liveData/get_instituteType"];
    console.log('this.instituteType', this.instituteType)
    this.attainmentCalculationType =
      this.$store.getters["liveData/get_attainmentCalculationType"];
    this.UserAuthenticationRepositoryInstance =
      new UserAuthenticationRepository(this);
    this.instituteCieSee = this.$store.getters["liveData/get_instituteCieSee"];

    this.nbaRepositoryInstance = new nbaRepository(this);
    this.examRepositoryInstance = new ExamRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.examStudentRepositoryInstance = new ExamStudentRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.selectedExamStore = this.$store.getters["liveData/get_selectedExam"];
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.infrastructureReferenceRepositoryInstance =
      new InfrastructureReferenceRepository(this);
    this.getUserAuthentication();
    await this.getExam();
    const timestamp = new Date();
    this.currentDate = timestamp.toISOString().split("T")[0];
  },
  methods: {
    rowClass(item) {
      if (item.sectionNames) {
        return "sectionBackgrond"
      }
    },
    setActiveSet(set) {
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray
      this.activeSet = set;
      this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName]
    },
    saveSets() {
      this.isSetNameEmpty = false
      this.setsToCreate.forEach(set => {
        if (set.setName.trim() === '') this.isSetNameEmpty = true
      })
      if (!this.isSetNameEmpty) {
        this.createSetDialog = false
        this.createdSets.push(...this.setsToCreate)
      }
      for (let i = 0; i < this.setsToCreate.length; i++) {
        this.setsObjectForQuestions[this.setsToCreate[i].setName] = []
      }
    },
    addSection() {
      let sectionNames = ++this.sectionNo
      for (let i = this.questionsArray1?.length - 1; i >= 0; i--) {
        if (this.questionsArray1[i]?.sectionNames) {
          sectionNames = Number(this.questionsArray1[i].sectionNames[this.questionsArray1[i].sectionNames.length - 1]) + 1
        }
      }
      this.questionNo = 0
      const quesObject = {
        sectionNames: 'Section' + sectionNames,
        question: '',
        sectionId: v4(),
        questionMarks: null,
        cos:[],
        blooms: []
      }
      this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    sortByNumericValueInAscendingOrder(array, fieldToSortBy) {
      array.sort((a, b) => a[fieldToSortBy] - b[fieldToSortBy]);
      return array
    },
    addAnyTypeQuestion() {
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        questionForSection: this.questionItem,
        anyCount: null,
        outOfCount: null,
        questionId: v4(),
        question: 'Solve any_out of',
        questionMarks: null,
        cos: this.coNumbers,
        blooms:[]
      }
      let foundSec = false
      let breaked = false
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.sectionNames === this.questionItem) {
          foundSec = true
        } else if (this.questionsArray1[i].sectionNames && this.questionsArray1[i].sectionNames !== this.questionItem && foundSec) {
          this.questionsArray1.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray1.push(quesObject)
      }
      // this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    dummy(item, index) {
      if (item.outOfCount && item.anyCount && item.questionMarks) {
        this.addSubquestionsOfAny(item.outOfCount, item.anyCount, item.questionMarks, index)
      }
    },
    addSubquestionsOfAny(outOfCount, anyCount, questionMarks, index) {
      const qMarks = questionMarks / anyCount;
      const quesObject = {
        subQnNo: 'Any ' + anyCount,
        anyQuestionId: v4(),
        // questionNumberAny: 'Any ' + anyCount
        questionNumberAny: 'Any ' + anyCount,
        // questionNumber: 'Any ' + anyCount
      }
      this.questionsArray1.splice(index, 0, quesObject)
      index++

      for (let i = index; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.questionNo) {
          break
        } else if ((this.questionsArray1[i].anyQuestionId) || (this.questionsArray1[i].anySubQuestionId)) {
          this.questionsArray1.splice(i, 1)
          i--
        }
      }

      // this.questionsArray.push(quesObject)
      let subQueCount = 0
      for (let i = 0; i < outOfCount; i++) {
        // index++
        const obj = {
          question: '',
          anySubQuestionId: v4(),
          anyCount: anyCount,
          subQnNo: 'Q' + this.questionNo + '.' + ++subQueCount,
          anyQue: true,
          questionMarks: qMarks,
          cos: [],
          blooms: []
        }
        this.questionsArray1.splice(index, 0, obj)
        this.resetQueNo()
      }
    },
    addNormalQuestion() {
      this.subQuestionNo = 0
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        questionForSection: this.questionItem,
        question: '',
        questionId: v4(),
        questionMarks: null,
        cos:[],
        blooms: []
      }
      let foundSec = false
      let breaked = false
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.sectionNames === this.questionItem) {
          foundSec = true
        } else if (this.questionsArray1[i].sectionNames && this.questionsArray1[i].sectionNames !== this.questionItem && foundSec) {
          this.questionsArray1.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray1.push(quesObject)
      }
      // for (let i = 0; i < this.allQuestionObjectArray.length; i++) {
      //   if (this.allQuestionObjectArray[i][this.questionItem]) {
      //     this.allQuestionObjectArray[i][this.questionItem].push(quesObject)
      //   }
      // }
      // this.arrayOfQuestionsToUpdate = []
      // for (let j = 0; j < this.allQuestionObjectArray.length; j++) {

      // }
      // const sectionNames = Object.keys(this.allQuestionObjectArray)
      // for (let i = 0; i < sectionNames.length; i++) {
      //   this.questionsArray1.push()
      // }
      // this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    addOrQuestion(nextIndex) {
      this.subQuestionNo = 0
      const quesObject = {
        questionNumber: 'Q' + ++this.questionNo,
        question: '',
        questionId: v4(),
        questionMarks: null,
        cos: [],
        blooms: []
      }
      // quesObject.questionNumber = this.questionsArray1[this.questionItemIndex] ? 'Q' + (parseInt(this.questionsArray1[this.questionItemIndex - 2]?.questionNumber[this.questionsArray1[this.questionItemIndex - 2].questionNumber.length - 1]) + 1) : 'Q' + ++this.questionNo
      // this.questionsArray1.splice(this.questionItemIndex, 0, quesObject)
      // for (let i = this.questionItemIndex + 1; i < this.questionsArray1.length; i++) {
      //   const previousQuestion = this.questionsArray1[i - 1];
      //   const currentQuestion = this.questionsArray1[i];

      //   if (previousQuestion?.questionNumber === 'OR') {
      //     this.questionNo = parseInt(this.questionsArray1[i - 2]?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   } else {
      //     this.questionNo = parseInt(previousQuestion?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   }
      // }
      // this.questionsArray1.splice(this.questionItemIndex + 1, 0, quesObject)
      // this.questionsArray1.push(quesObject)
      if (nextIndex) this.questionsArray1.splice(nextIndex + 1, 0, quesObject)
      else this.questionsArray1.push(quesObject)
      this.resetQueNo()
    },
    addInOrQuestion() {
      const quesObject = {
        questionNumber: 'OR',
        questionId: v4()
      }
      // let foundQue = false
      // let breaked = false
      // for (let i = 0; i < this.questionsArray1.length; i++) {
      //   if (this.questionsArray1[i]?.questionNo === this.questionItem) {
      //     console.log('else if condition1')
      //     foundQue = true
      //   } else if (this.questionsArray1[i].questionNo && this.questionsArray1[i].questionNo !== this.questionItem && foundQue) {
      //     console.log('this.questionsArray1[i]?.sectionNames', this.questionsArray1[i])
      //     this.questionsArray1.splice(i, 0, quesObject)
      //     breaked = true
      //     break
      //   }
      // }
      // if (!breaked) {
      //   this.questionsArray1.push(quesObject)
      // }
      let nextIndex = null
      let isQuestionFound = false
      for (let i = this.questionItemIndex; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i].questionNumber) {
          this.questionsArray1.splice(i, 0, quesObject)
          isQuestionFound = true
          nextIndex = i
          break
        } else if (this.questionsArray1[i].sectionNames) {
          this.questionsArray1.splice(i - 1, 0, quesObject)
          isQuestionFound = true
          nextIndex = i
          break
        }
      }
      if (!isQuestionFound) {
        this.questionsArray1.push(quesObject)
      }

      // this.questionsArray1.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo()
      // this.questionsArray1.push(quesObject)
      this.addOrQuestion(nextIndex)
    },
    addSubQuestion() {
      const quesObject = {
        question: '',
        subQuestionId: v4(),
        subQnNo: 'Q' + this.questionNo + '.' + ++this.subQuestionNo,
        questionMarks: null,
        cos: [],
        blooms: []
      }
      let foundQn = false
      let breaked = false
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.questionNo === this.questionItem && i === this.questionItemIndex - 1) {
          foundQn = true
        } else if ((this.questionsArray1[i].questionNo && this.questionsArray1[i].questionNo !== this.questionItem && foundQn) || (this.questionsArray1[i].questionNumber && foundQn)) {
          this.questionsArray1.splice(i, 0, quesObject)
          breaked = true
          break
        }
      }
      if (!breaked) {
        this.questionsArray1.push(quesObject)
      }
      // this.questionsArray1.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo()
    },
    addOrSubQuestion() {
      const quesObject = {
        subQnNo: 'OR',
        subQuestionId: v4()
      }
      this.questionsArray1.splice(this.subQnIndex, 0, quesObject)
      const quesObject2 = {
        question: '',
        subQuestionId: v4(),
        subQnNo: 'Q' + this.questionNo + '.' + ++this.subQuestionNo,
        questionMarks: null,
        cos: [],
        blooms: []
      }
      this.questionsArray1.splice(this.subQnIndex + 1, 0, quesObject2)
      // this.questionsArray1.push(quesObject)

      // this.addSubQuestion()
      this.resetQueNo()
    },
    resetQueNo() {
      let secNo = 1
      let qnNo = 1
      let sQnNo = 1
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i].sectionNames) {
          qnNo = 1
          sQnNo = 1
          this.questionsArray1[i].questionNo = 'Section' + secNo
          this.questionsArray1[i].sectionNames = 'Section' + secNo
          secNo++
        } else if (this.questionsArray1[i].questionNumber && this.questionsArray1[i].questionNumber !== 'OR') {
          sQnNo = 1
          this.questionsArray1[i].questionNo = 'Q' + qnNo
          this.questionsArray1[i].questionNumber = 'Q' + qnNo
          qnNo++
        } else if (this.questionsArray1[i].subQnNo && this.questionsArray1[i].subQnNo !== 'OR' && !this.questionsArray1[i].questionNumberAny) {
          this.questionsArray1[i].subQnNo = 'Q' + (qnNo - 1) + '.' + sQnNo
          sQnNo++
        }
      }
    },
    handleRadio3(subject){
      const sets = [];
      for (let i = 0; i < this.createdQuestions?.questions.length; i++) {
        if (this.createdQuestions?.questions[i].questionType === 'Set') {
          sets.push({
            questionId: this.createdQuestions.questions[i].questionId,
            setName: this.createdQuestions.questions[i].questionStatement
          })
        }
      }
      let index = this.questionDetails.indexOf(subject);
      this.questionDetails[index].studentData.map((eachStudent)=>{
        eachStudent['sets'] = sets
      })
    },
    handleRadio2(subject) {
      this.allSections = []
      const sets = [];
      let index = this.questionDetails.indexOf(subject);
      for (let i = 0; i < this.createdQuestions?.questions.length; i++) {
        if (this.createdQuestions?.questions[i].questionType === 'Set') {
          for (let j = 0; j < this.createdQuestions.questions[i].question.length; j++) {
            this.allSections.push({ questionId: this.createdQuestions.questions[i].question[j].questionId, sectionName: this.createdQuestions.questions[i].question[j].questionStatement, obtainedMarks:null, questionMarks: this.createdQuestions.questions[i].question[j].questionMarks })
          }
          sets.push({
            questionId: this.createdQuestions.questions[i].questionId,
            setName: this.createdQuestions.questions[i].questionStatement
          })
        }
      }
      this.questionDetails[index].studentData.forEach((eachStudent) => {
        eachStudent['allSections'] = this.allSections;
        eachStudent['sets'] = sets;
      });
      // for (let i = 0; i < this.students.length; i++) {
      //   this.students[i].allSections = this.allSections
      // }
    },
    async fetchAssignmentQuestions(sub) {
      // if (this.exam !== null) {
      //   const assignmentQuestions = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.getExamSubjectQuestions({
      //     instituteId: this.selectedInstitute,
      //     examId: this.exam.exam.examId,
      //     subjectId: this.prop_selectedSubject.subjectId
      //   })
      // }
      this.createdQuestions = sub
      if (this.createdQuestions?.questions?.length > 0) {
        this.activeSet = {
          no: 1,
          setName: 'set 1'
        }
        this.setActiveSet(this.activeSet)
        this.createdSets = []
        this.setsObjectForQuestions = {}
        for(let i = 0; i < this.createdQuestions?.questions?.length; i++) {
          if (this.createdQuestions.questions[i].questionType === 'Set') {
            const questionStatement = this.createdQuestions.questions[i].questionStatement;
            this.createdSets.push({
              no: i + 1,
              setName: questionStatement
            })
            if (!Object.prototype.hasOwnProperty.call(this.setsObjectForQuestions, questionStatement)) {
              this.setsObjectForQuestions[questionStatement] = [];
            }
            this.setsObjectForQuestions[questionStatement].push(...this.getQuestionsOfSelectedSet({ setName: questionStatement, forShowingQuestions: true }, null));
          }
        }
        this.questionsArray1 = this.setsObjectForQuestions['set 1']
      }
    },
    finalSetQuestions(sub) {
      this.allQuestions = []
      this.setsObjectForQuestions['set 1'] = this.questionsArray1
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        this.questionsArray1 = this.setsObjectForQuestions[sets[i]]
        let isBreaked = false
        for (let j = 0; j < this.questionsArray.length; j++) {
          if (this.questionsArray[j]?.question === '' || this.questionsArray[j]?.questionMarks === '') {
            isBreaked = true
            showStatus("Enter question statement or marks in " + (this.questionsArray[j].sectionNames ? this.questionsArray[j].sectionNames : (this.questionsArray[j].questionNumber ? this.questionsArray[j].questionNumber : this.questionsArray[j].subQnNo)), 5000, "error", this);
            break
          }
        }
        if (isBreaked) {
          this.questionsValidationSuccess = false
          break
        } else {
          this.questionsValidationSuccess = true
          this.validateSectionWiseMarks(sets[i],sub)
        }
       }
       if (this.questionsValidationSuccess) {
        this.saveQuestions(sub);
      }
    },
    deleteQuestion1(index) {
      if (index >= 0 && index < this.questionsArray1.length) {
        if (this.questionsArray1[index].sectionNames) {
          this.questionsArray1.splice(index, 1);
          for (let i = index; i < this.questionsArray1.length; i++) {
            if (this.questionsArray1[i].sectionNames) {
              break;
            } else {
              this.questionsArray1.splice(i, 1);
              i--;
            }
          }
        } else if (this.questionsArray1[index].questionNumber) {
          this.questionsArray1.splice(index, 1);
          for (let i = index; i < this.questionsArray1.length; i++) {
            if (this.questionsArray1[i].questionNumber || this.questionsArray1[i].sectionNames) {
              break;
            } else {
              // const qnNo = this.questionsArray[i].questionNumber
              this.questionsArray1.splice(i, 1);
              // this.questionsArray[i].questionNumber = qnNo
              i--;
            }
          }
        } else if (this.questionsArray1[index].subQnNo) {
          this.questionsArray1.splice(index, 1);
          for (let i = index; i < this.questionsArray1.length; i++) {
            if (this.questionsArray1[i].subQnNo) {
              break;
            } else {
              this.questionsArray1.splice(i, 1);
              i--;
            }
          }
        }
      }
      this.resetQueNo()
    },
    prepareQuestionsArray(allQuestions) {
      const nestedObject = {
        allQuestions: []
      };
      let currentSet;
      let currentSection;
      let currentQuestion;
      let currentSubQuestion;
      let orType = false;
      let NumberOfQuestionsToSolve = 0;
      // console.log('nestedObject', nestedObject
      allQuestions.forEach((item, index) => {
    
        if(item.setName){
          currentSet = {
            questionId: v4(),
            questionType: 'Set',
            questionMarks: item.Marks,
            questionStatement: item.setName,
            cos : [],
            blooms : [],
            question: []
          };
          nestedObject.allQuestions.push(currentSet);
        }else if(item.Section){
          currentSection = {
            questionId: v4(),
            questionType: 'Section',
            questionMarks: item.Marks,
            questionStatement: item.Section,
            questionNumber: item["Section No."],
            cos: item.Cos,
            blooms: item.Blooms,
            question: []
          }
          currentSet.question.push(currentSection);
        }else if(item.Question){
          currentQuestion = {
          questionId: v4(),
          questionType: 'Question',
          questionMarks: item.Marks,
          questionStatement: item.Question,
          questionNumber: item['Question No.'],
          cos: item.Cos,
          blooms: item.Blooms,
          question: []
          }
          if(orType == true){
            currentQuestion['questionType'] = 'OrQuestion',
            orType = false;
          }
          if(NumberOfQuestionsToSolve !== 0){
            
            currentQuestion['NumberOfQuestionsToSolve'] = NumberOfQuestionsToSolve,
            NumberOfQuestionsToSolve = 0;
          }
          currentSection.question.push(currentQuestion);
        }else if(item['Sub-Question']){
          
          currentSubQuestion = {
            questionType: 'SubQuestion',
            questionMarks: item.Marks,
            questionStatement: item['Sub-Question'],
            questionNumber: item['Sub-Question No.'],
            cos: item.Cos,
            blooms: item.Blooms,
            // question: []
          };
          if(orType == true){
            currentQuestion['questionType'] = 'OrSubQuestion',
            orType = false;
          }
          currentQuestion.question.push(currentSubQuestion)
        }else if(item['Question No.'] == 'OR'){
            orType = true;
        } else if (item['Question No.']?.split(' ')[0] == 'Any'){
          NumberOfQuestionsToSolve = item['Question No.'].split(' ')[1];
          currentQuestion['NumberOfQuestionsToSolve'] = NumberOfQuestionsToSolve
        }
      })
      
      return [nestedObject]
    },
    // async finalQuestionsSave(sub) {
      
    //   let tempArr = this.prepareQuestionsArray(this.allQuestions)
    //   try {
    //     const objToPass = {
    //       subjectId: this.prop_selectedSubject?.subjectId,
    //       examId: this.exam?.exam?.examId,
    //       instituteId: this.selectedInstitute,
    //       marks: this.exam?.exam?.totalMarks,
    //       allquestionsArray1: tempArr
    //     }
    //     console.log("final object to pass",objToPass)
    //     // const data = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.parseExcel(objToPass);
    //     // if (data) showStatus("Questions successfully added in exam.", 2000, 'success', this);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    validateSectionWiseMarks(set) {

      let totalSectionMarks = 0
      if (this.questionsArray1.length > 0) {
        for (let i = 0; i < this.questionsArray1.length; i++) {
          if (this.questionsArray1[i]?.sectionNames) {
            totalSectionMarks += Number(this.questionsArray1[i].questionMarks)
          }
        }
        if (totalSectionMarks !== this.tempTotalMarks) {
          showStatus("Section marks should be equal to the total marks of assignment", 5000, "error", this);
          this.questionsValidationSuccess = false
        } else {
          this.validateQuestionUnderSectionWiseMarks(set)
        }
      } else {
        showStatus("Questions are not added yet", 5000, "error", this);
        this.questionsValidationSuccess = false
      }
    },
    validateQuestionUnderSectionWiseMarks(set) {
   
      let totalQuestionUnderSectionMarks = 0
      let sectionMarks = 0
      let areQuestionPresent = false
      let checkNext = true
      for (let i = 0; i < this.questionsArray1.length; i++) {
        if (this.questionsArray1[i]?.sectionNames) {
          if (totalQuestionUnderSectionMarks !== sectionMarks && areQuestionPresent) {
            showStatus(`Total questions under ${this.questionsArray1[i].sectionNames} should be equal to the marks assigned to it`, 5000, "error", this);
            checkNext = false
            this.questionsValidationSuccess = false
            break
          }
          sectionMarks = Number(this.questionsArray1[i].questionMarks)
          totalQuestionUnderSectionMarks = 0
          areQuestionPresent = false
        } else if (this.questionsArray1[i]?.questionNumber && this.questionsArray1[i]?.questionNumber !== 'OR') {
          totalQuestionUnderSectionMarks += Number(this.questionsArray1[i].questionMarks)
          areQuestionPresent = true
        } else if (this.questionsArray1[i]?.questionNumber && this.questionsArray1[i]?.questionNumber === 'OR') {
          totalQuestionUnderSectionMarks -= Number(this.questionsArray1[i + 1].questionMarks)
        }
      }
      if (totalQuestionUnderSectionMarks !== sectionMarks && areQuestionPresent) {
        checkNext = false
        showStatus(`Total questions under section should be equal to the marks assigned to it`, 5000, "error", this);
        checkNext = false
        this.questionsValidationSuccess = false
      }
      if (checkNext) {
        this.validateSubQUestionUnderQuestionUnderSectionWiseMarks(set)
      }
      },
    // validateSubQUestionUnderQuestionUnderSectionWiseMarks(set) {
    //   console.log("validate section 3")
    //   let totalSubQuestionUnderQuestionUnderSectionMarks = 0
    //   let questionMarks = 0
    //   let areSubQuestionsAvailable = false
    //   let saveQuestions = true
    //   for (let i = 0; i < this.questionsArray1.length; i++) {
    //     if (this.questionsArray1[i]?.questionNumber && this.questionsArray1[i]?.questionNumber !== 'OR') {
    //       if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
    //         showStatus(`Total sub questions marks under ${this.questionsArray1[i].question} should be equal to the marks assigned to it`, 5000, "error", this);
    //         saveQuestions = false
    //         this.questionsValidationSuccess = false
    //         break
    //       }
    //       questionMarks = Number(this.questionsArray1[i].questionMarks)
    //       console.log('this.questionsArray1[i]', this.questionsArray1[i])
    //       totalSubQuestionUnderQuestionUnderSectionMarks = 0
    //       areSubQuestionsAvailable = false
    //     } else if (this.questionsArray1[i]?.subQnNo && this.questionsArray1[i]?.subQnNo !== 'OR' && !this.questionsArray1[i]?.questionNumberAny && !this.questionsArray1[i]?.anySubQuestionId && !this.questionsArray1[i].anyCount) {
    //       console.log("herer 1", totalSubQuestionUnderQuestionUnderSectionMarks, this.questionsArray1[i])
    //       totalSubQuestionUnderQuestionUnderSectionMarks += Number(this.questionsArray1[i].questionMarks)
    //       areSubQuestionsAvailable = true
    //       console.log('areSubQuestionsAvailable', this.questionsArray1[i])
    //       } else if (this.questionsArray1[i]?.subQnNo && this.questionsArray1[i]?.subQnNo === 'OR') {
    //       totalSubQuestionUnderQuestionUnderSectionMarks -= Number(this.questionsArray1[i + 1].questionMarks)
    //       console.log("herer 2", totalSubQuestionUnderQuestionUnderSectionMarks)
    //     }
    //   }
    //   console.log('totalSubQuestionUnderQuestionUnderSectionMarks', totalSubQuestionUnderQuestionUnderSectionMarks, questionMarks, areSubQuestionsAvailable)
    //   if (Number(totalSubQuestionUnderQuestionUnderSectionMarks) !== Number(questionMarks) && areSubQuestionsAvailable) {
    //     saveQuestions = false
    //     showStatus(`Total sub questions marks under question should be equal to the marks assigned to it`, 5000, "error", this);
    //     saveQuestions = false
    //     this.questionsValidationSuccess = false
    //   }
    //   console.log("save questions", saveQuestions)
    //   if (saveQuestions) {
    //     this.saveQuestions1(set)
    //   }
    //   console.log("validate section 4")
    //   console.log("validate section 6")
    // },
    validateSubQUestionUnderQuestionUnderSectionWiseMarks(set) {
      let totalSubQuestionUnderQuestionUnderSectionMarks = 0
      let questionMarks = 0
      let areSubQuestionsAvailable = false
      let saveQuestions = true
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.questionNumber && this.questionsArray[i]?.questionNumber !== 'OR') {
          if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
            showStatus(`Total sub questions marks under ${this.questionsArray[i].question} should be equal to the marks assigned to it`, 5000, "error", this);
            saveQuestions = false
            this.questionsValidationSuccess = false
            break
          }
          questionMarks = (this.questionsArray[i].questionMarks - '')
          totalSubQuestionUnderQuestionUnderSectionMarks = 0
          areSubQuestionsAvailable = false
        } else if (this.questionsArray[i]?.subQnNo && this.questionsArray[i]?.subQnNo !== 'OR' && !this.questionsArray[i]?.questionNumberAny && !this.questionsArray[i]?.anySubQuestionId && !this.questionsArray[i].anyStatement) {
          totalSubQuestionUnderQuestionUnderSectionMarks += (this.questionsArray[i].questionMarks - '')
          areSubQuestionsAvailable = true
        } else if (this.questionsArray[i]?.subQnNo && this.questionsArray[i]?.subQnNo === 'OR') {
          totalSubQuestionUnderQuestionUnderSectionMarks -= (this.questionsArray[i + 1].questionMarks - '')
        }
      }
      if (totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks && areSubQuestionsAvailable) {
        saveQuestions = false
        showStatus(`Total sub questions marks under question should be equal to the marks assigned to it`, 5000, "error", this);
        saveQuestions = false
        this.questionsValidationSuccess = false
      }
      if (saveQuestions) {
        this.saveQuestions1(set)
      }
    },
    resetQuestionForSubject(){
      this.questionsArray1 = [];
    },
    saveQuestions1(set) {
      this.questionToGiveMarks = this.questionsArray1
      this.allQuestions.push({
        setName: set
      })
   
      for (let i = 0; i < this.questionsArray1.length; i++) {
       
        if (this.questionsArray1[i].sectionNames) {
          
          this.allQuestions.push({
            'Section No.': this.questionsArray1[i].sectionNames,
            Section: this.questionsArray1[i].question,
            Marks: this.questionsArray1[i].questionMarks,
            Cos: this.questionsArray1[i].cos,
            Blooms: this.questionsArray1[i].blooms
          })
          continue
        }
        if (this.questionsArray1[i].questionNumber && this.questionsArray1[i].questionNumber !== 'OR') {
         
          this.allQuestions.push({
            'Question No.': this.questionsArray1[i].questionNumber,
            Question: this.questionsArray1[i].question,
            Marks: this.questionsArray1[i].questionMarks,
            Cos: this.questionsArray1[i].cos,
            Blooms: this.questionsArray1[i].blooms
          })
          continue
        }
        if (this.questionsArray1[i].questionNumber && this.questionsArray1[i].questionNumber === 'OR') {
         
          this.allQuestions.push({
            'Question No.': 'OR'
          })
          continue
        }
        if (this.questionsArray1[i].subQnNo && this.questionsArray1[i].subQnNo !== 'OR' && !this.questionsArray1[i].questionNumberAny) {
        
          this.allQuestions.push({
            'Sub-Question No.': this.questionsArray1[i].subQnNo,
            'Sub-Question': this.questionsArray1[i].question,
            Marks: this.questionsArray1[i].questionMarks,
            Cos: this.questionsArray1[i].cos,
            Blooms: this.questionsArray1[i].blooms
          })
          continue
        }
        if (this.questionsArray1[i].subQnNo && this.questionsArray1[i].subQnNo === 'OR') {
         
          this.allQuestions.push({
            'Sub-Question No.': 'OR'
          })
          continue
        }
        
        if (this.questionsArray1[i].questionNumberAny !== undefined) {
          
          this.allQuestions.push({
            'Question No.': this.questionsArray1[i].questionNumberAny
          })
          continue
        }
      }
      
    },
    fieldBackgroundColors(item) {
      if (item.questionNumber === 'OR' || item.subQnNo === 'OR' || item.questionNumberAny) {
      } else {
        return "#9205050D"
      }
    },
    getSetSections(selectedSet) {
      this.sectionsOfSelectedSet = []
      for (let i = 0; i < this.setsObjectForQuestions[selectedSet.setName].length; i++) {
        if (this.setsObjectForQuestions[selectedSet.setName][i].sectionNames) {
          this.sectionsOfSelectedSet.push(this.setsObjectForQuestions[selectedSet.setName][i].question)
        }
      }
    },
    async updateMarksOfAStudent(item, limit) {
      if (Number(item.obtainedMarks) > limit) {
        showStatus("Invalid marks entered", 8000, "error", this);
        item.obtainedMarks = 0;
      }
      if (item.obtainedMarks !== "") {
            item["answers"] =  [{ questionId: item.selectedSet?.questionId, obtainedMarks: Number(item.obtainedMarks) }]
            // try {
          //   const objToPush = {
          //     instituteId: this.selectedInstitute,
          //     semId: this.selectedSemester.semId,
          //     uId: item.uId,
          //     examId: this.exam.exam.examId,
          //     attemptNumber: item.attemptNumber,
          //     obtainedMarks: item.obtainedMarks,
          //     status: 'Submitted',
          //     subjectId: this.prop_selectedSubject.subjectId,
          //     answers: [{ questionId: item.selectedSet?.questionId, obtainedMarks: item.obtainedMarks }]
          //   };
          //   if (item.status !== 'Submitted') {
          //     objToPush.submittedOn = item?.submittedOn ? new Date(item?.submittedOn).toISOString() : new Date().toISOString();
          //   }
          //   // await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
          //   //   objToPush
          //   // );
          //   this.students[this.students.indexOf(item)].obtainedMarks = item.obtainedMarks
          //   this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          //   showStatus("Marks updated successfully", 2000, 'success', this);
          // } catch (err) {
          //   console.error(err);
          //   showStatus("Error updating marks", 1000, "error", this);
          // }
        }
    },
    handleRadio1(subject,i){
      if (this.toggleQuestionUpload !== 'uploadQuestions') {
        if (this.headers1[3].text === 'Set attempted') {
          this.headers1.splice(3, 1);
        }
        console.log("headers 1", this.headers1);
      }
      const sets = [];
      for (let i = 0; i < this.createdQuestions.questions.length; i++) {
      if (this.createdQuestions.questions[i].questionType === 'Set') {
        sets.push({
          questionId: this.createdQuestions.questions[i].questionId,
          setName: this.createdQuestions.questions[i].questionStatement
        })
        }
      }
      let index = this.questionDetails.indexOf(subject);
      this.questionDetails[index].studentData.map((eachStudent)=>{
        eachStudent['sets'] = sets
      })
    },
    displayedValue(item, data) {
      
      if (item[data.sectionName]) return item[data.sectionName]
      else if (item && item.answers && data) {
        for (let i = 0; i < item.answers.length; i++) {
          if (item.answers[i].questionId === data.questionId) {
            return item.answers[i]?.obtainedMarks;
          }
        }
      }
    },
    async sectionWiseStudentMarks(item, secName) {
      const answersArray = []
      let obtainedMarks = 0;
      let incorrectFlag = false;
      for (let i = 0; i < this.allSections.length; i++) {
        if (item[this.allSections[i].sectionName] ) {
          if(item[this.allSections[i].sectionName] > Number(secName.questionMarks)){
            incorrectFlag = true;
            item[this.allSections[i].sectionName] = 0;
          }
          answersArray.push({
            questionId: this.allSections[i].questionId,
            obtainedMarks: Number(item[this.allSections[i].sectionName])
          })
          obtainedMarks += Number(item[this.allSections[i].sectionName])
        }
      }
      if(incorrectFlag == true){
        showStatus("Given Section Marks can't be greater than Total assignment Marks", 1000, "error", this);
          item['obtainedMarks'] = obtainedMarks;  
          item['answers'] = answersArray;
      }
      else if (obtainedMarks > item.total ) {
        showStatus("Given Section Marks can't be greater than Total assignment Marks", 1000, "error", this);
      } 
      else {
        try {
          item['obtainedMarks'] = obtainedMarks;  
          item['answers'] = answersArray;
          // const objToPush = {
          //   instituteId: this.selectedInstitute,
          //   semId: this.selectedSemester.semId,
          //   uId: item.uId,
          //   examId: this.exam.exam.examId,
          //   attemptNumber: item.attemptNumber,
          //   obtainedMarks: obtainedMarks,
          //   status: 'Submitted',
          //   subjectId: this.prop_selectedSubject.subjectId,
          //   answers: answersArray
          // };
          // await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
          //   objToPush
          // );
          // this.students[this.students.indexOf(item)].obtainedMarks = item.obtainedMarks
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      }
      // }
    
    },
    getQuestionsOfSelectedSet(set, item) {
      this.studentData = item
      let anyQuestionFound = false
      let setQuestions = [];
      for (let i = 0; i < this.createdQuestions.questions.length; i++) {
        if (this.createdQuestions.questions[i].questionType === 'Set' && this.createdQuestions.questions[i].questionStatement === set.setName) {
          setQuestions = this.createdQuestions.questions[i].question;
        }
      }
      this.seperatedSetQuestions = []

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          questionMarks: setQuestions[i].questionMarks,
          cos: setQuestions[i].cos,
          blooms: setQuestions[i].blooms
        })
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question
         
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === 'OrQuestion') {
              anyQuestionFound = false
              this.seperatedSetQuestions.push({
                questionNumber: 'OR'
              })
            }
            anyQuestionFound = false
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 'Solve any (' + sectionQuestion[j].NumberOfQuestionsToSolve + ') out of ('+sectionQuestion[j]?.question?.length+') ' : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              solveAnyCount: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 0 : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              questionMarks: sectionQuestion[j]?.questionMarks,
              cos: sectionQuestion[j]?.cos,
              blooms: sectionQuestion[j]?.blooms
            })
            if (sectionQuestion[j].NumberOfQuestionsToSolve) {
              anyQuestionFound = true
              this.seperatedSetQuestions.push({
                subQnNo: 'Any ' + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true
              })
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === 'OrSubQuestion') {
                  this.seperatedSetQuestions.push({
                    subQnNo: 'OR',
                    anyStatement: false
                  })
                }
                if (anyQuestionFound) {
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    cos: subQuestion[k].cos,
                    blooms: subQuestion[k].blooms
                  })
                } else {
                  anyQuestionFound = false
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    cos: subQuestion[k].cos,
                    blooms: subQuestion[k].blooms
                  })
                }
              }
            }
          }
        }
      }
      // this.questionWiseMarksDialog = true
      
      if (!set.forShowingQuestions && this.studentData?.answers) {
        const studentDataMap = new Map(this.studentData?.answers.map(data => [data.questionId, data]));
        for (const setQuestion of this.seperatedSetQuestions) {
          const studentDataForQuestion = studentDataMap.get(setQuestion.questionId);
          if (studentDataForQuestion) {
            setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
          }
        }
        this.questionWiseMarksDialog = true
      } else return this.seperatedSetQuestions
    },
    // validateGivenSectionWiseMarks() {
    //   let totalSectionMarks = 0
    //   if (this.seperatedSetQuestions.length > 0) {
    //     for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
    //       if (this.seperatedSetQuestions[i]?.sectionNames) {
    //         totalSectionMarks += parseInt(this.seperatedSetQuestions[i]?.assignMarks)
    //       }
    //     }
    //     if (totalSectionMarks > this.exam.exam.totalMarks) {
    //       showStatus("Section marks should be equal or less to the total marks of assignment", 5000, "error", this);
    //       this.questionsValidationSuccess = false
    //     } else {
    //       this.validateGivenQuestionUnderSectionWiseMarks()
    //     }
    //   } else {
    //     showStatus("Questions are not added yet", 5000, "error", this);
    //     this.questionsValidationSuccess = false
    //   }
    // },
    async saveQuestionWiseMarks(itm) {
      let marksIncorrect = false
      let answers=[];
      let obtainedMarks = 0;
      try {
        const objToPush = {
          // instituteId: this.selectedInstitute,
          // semId: this.selectedSemester.semId,
          // uId: this.studentData.uId,
          // examId: this.exam.exam.examId,
          // attemptNumber: this.studentData.attemptNumber,
          // obtainedMarks: null,
          // status: 'Submitted',
          // subjectId: this.prop_selectedSubject.subjectId,
          answers: []
        };
        for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
          if (this.seperatedSetQuestions[i].assignMarks) {
            if (Number(this.seperatedSetQuestions[i].assignMarks) > Number(this.seperatedSetQuestions[i].questionMarks)) {
              marksIncorrect = true
              showStatus(`Given Marks can not be greater than question marks`, 5000, "error", this);
            } else {
              answers.push({
                questionId: this.seperatedSetQuestions[i]?.questionId,
                obtainedMarks: Number(this.seperatedSetQuestions[i].assignMarks)
              })
              obtainedMarks += this.seperatedSetQuestions[i].sectionNames ? Number(this.seperatedSetQuestions[i].assignMarks) : 0
            }
          }
        }
        if (!marksIncorrect) {
          this.studentData['answers'] = answers;
          this.studentData['obtainedMarks'] = obtainedMarks;
          // await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
          //   objToPush
          // );
          // this.students[this.students.indexOf(this.studentData)].obtainedMarks = this.studentData.obtainedMarks
          // showStatus("Marks updated successfully", 2000, 'success', this);
          this.questionWiseMarksDialog = false
        }
      } catch (err) {
        console.error(err);
        showStatus("Error updating marks", 1000, "error", this);
      }
    },
    async showQuestions(sub) {
      await this.fetchAssignmentQuestions(sub)
      if (this.createdQuestions !== null) {
        this.openQuestionsPreview = true
      } else {
        showStatus("Questions are not added yet for this assignment", 9000, "warning", this);
      }
    },
    sortByAlphaNumericValue(array, fieldToSortBy) {
      const newArray = [...array];
      newArray.sort((a, b) => {
        if ( 
          a[fieldToSortBy] === null ||
          b[fieldToSortBy] === null ||
          a[fieldToSortBy] === undefined ||
          b[fieldToSortBy] === undefined
        ) {
          // If either fieldToSortBy is null, move the element with null fieldToSortBy to the end.
          if (a[fieldToSortBy] === null || a[fieldToSortBy] === undefined)
            return 1;
          if (b[fieldToSortBy] === null || b[fieldToSortBy] === undefined)
            return -1;
        }
        return a[fieldToSortBy].localeCompare(b[fieldToSortBy]); // Compare non-null fieldToSortBy strings
      });
      return newArray;
    },
    downloadOverallMarksReport(students) {
      let sortedStudents = this.sortByAlphaNumericValue(students, 'studentPrn')
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });
        for (let j = 0; j < sortedStudents?.length; j++) {
          const objToPush = {}
          objToPush.PRN = sortedStudents[j]?.studentPrn
          objToPush.Name = sortedStudents[j]?.studentName
          objToPush[`Overall_Marks(${this.tempTotalMarks})`] = sortedStudents[j]?.obtainedMarks === 0 ? '' : sortedStudents[j]?.obtainedMarks
          data[i][sets[i]].push(objToPush);
        }
      }
      createExcelThroughJsonForMultipleSheets(data, this.currentExam.name + '_Marks')
    },
    downloadSectionMarksReport(students) {
      let sortedStudents = this.sortByAlphaNumericValue(students, 'studentPrn')
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });

        for (let k = 0; k < sortedStudents.length; k++) {
          const objToPush = {
            PRN: sortedStudents[k].studentPrn,
            Name: sortedStudents[k].studentName
          };

          let sectionName = '';
          for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < sortedStudents[k].answers?.length; y++) {
                if (sortedStudents[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }
          }

          data[i][sets[i]].push(objToPush);
        }
      }
      createExcelThroughJsonForMultipleSheets(data, this.currentExam.name + '_Marks')
    },
    downloadQuestionMarksReport(students) {
      let sortedStudents = this.sortByAlphaNumericValue(students, 'studentPrn')
      const data = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });

        for (let k = 0; k < sortedStudents.length; k++) {
          const objToPush = {
            PRN: sortedStudents[k].studentPrn,
            Name: sortedStudents[k].studentName
          };

          let sectionName = '';
          for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
            let qNo = '';

            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < sortedStudents[k].answers?.length; y++) {
                if (sortedStudents[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }

            if (this.setsObjectForQuestions[sets[i]][j].questionNumber && this.setsObjectForQuestions[sets[i]][j].questionNumber !== 'OR') {
              qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < students[k].answers?.length; y++) {
                if (students[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}_${qNo}(${questionMark})`] = colValue;
            }

            if (this.setsObjectForQuestions[sets[i]][j].subQnNo && this.setsObjectForQuestions[sets[i]][j].subQnNo !== 'OR' && (!this.setsObjectForQuestions[sets[i]][j].anyStatement || (this.setsObjectForQuestions[sets[i]][j].anyStatement && this.setsObjectForQuestions[sets[i]][j].question))) {
              const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = '';

              for (let y = 0; y < sortedStudents[k].answers?.length; y++) {
                if (sortedStudents[k].answers[y].questionId === this.setsObjectForQuestions[sets[i]][j].questionId) {
                  colValue = Number(sortedStudents[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}(${questionMark})`] = colValue;
            }
          }

          data[i][sets[i]].push(objToPush);
        }
      }
      createExcelThroughJsonForMultipleSheets(data, this.currentExam.name + '_Marks')
    },
    async startUploadingOverallMarksExcel(students) {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentPrn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentName'
          },
          [`Overall_Marks(${this.tempTotalMarks})`]: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'overall_marks'
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = students.find(s => s.studentPrn === data.studentPrn);
              // console.log('datadata', data)
              if (student) {
                if (this.tempTotalMarks < data.overall_marks) {
                  this.excelErrors.push('Marks given to ' + student.studentName + ' should not be greater than ' + this.tempTotalMarks)
                } else {
                  student['obtainedMarks'] = data.overall_marks
                  student['answers'] = [];
                  // objectsArrayToUpdateMarks.push({
                  //   uId: student.uId,
                  //   instituteId: this.selectedInstitute,
                  //   semId: this.selectedSemester.semId,
                  //   examId: this.exam.exam.examId,
                  //   status: 'Submitted',
                  //   submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  //   subjectId: this.prop_selectedSubject.subjectId,
                  //   attemptNumber: student.attemptNumber,
                  //   totalMarksObtained: data.overall_marks,
                  //   : [],
                  //   marksGivenTo: data,
                  //   setName: sheet
                  // });
                }
              }
            }
          }

          if (this.excelErrors.length === 0) {
            // await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
            //   bulkDataArray: objectsArrayToUpdateMarks
            // });
            this.uploadOverallMarksViaExcel = false
            this.questionMarksExcelFile = null
            // this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
          // this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    async startUploadingSectionMarksExcel(students) {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentPrn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentName'
          }
        }
        for (let k = 0; k < students.length; k++) {
          data[i][sets[i]].push({ PRN: students[k].studentPrn, Name: students[k].studentName })
        }
        let sectionName = '';
        for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
          if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
            sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: sectionName
            }
            data[i][sets[i]].push({
              [sectionName]: ''
            });
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = students.find(s => s.studentPrn === data.studentPrn);

              if (student) {
                objectsArrayToUpdateMarks.push({
                  uId: student.studentId,
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  // examId: this.exam.exam.examId,
                  status: 'Submitted',
                  // submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  // subjectId: this.prop_selectedSubject.subjectId,
                  attemptNumber: student.attemptNumber,
                  obtainedMarks: 0,
                  answers: [],
                  marksGivenTo: data,
                  setName: sheet
                });
              }
            }
          }

          for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
            const marksGivenToKeys = Object.keys(objectsArrayToUpdateMarks[i].marksGivenTo)
            for (let j = 0; j < marksGivenToKeys.length; j++) {
              const sectionAndQuestion = marksGivenToKeys[j].split('_')
              if (sectionAndQuestion.length === 1) {
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                  ) {
                    objectsArrayToUpdateMarks[i].obtainedMarks += objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    });
                  } else if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]) {
                    this.excelErrors.push('Given Marks of ' + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]] + ' are greater than its assigned marks')
                    // showStatus("Given Marks of " + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + " are greater than its assigned marks", 5000, 'error', this);
                  }
                }
              }
            }
          }
          if (this.excelErrors.length === 0) {
            objectsArrayToUpdateMarks.forEach(updateObject =>{
              const matchingStudent = students.find(student => student.studentId === updateObject.uId);
              if(matchingStudent){
                matchingStudent.obtainedMarks = updateObject.obtainedMarks;
                matchingStudent.answers = updateObject.answers
              }
            })
            // await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
            //   bulkDataArray: objectsArrayToUpdateMarks
            // });
            this.uploadSectionMarksViaExcel = false
            this.questionMarksExcelFile = null
            // this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    async startUploadingQuestionMarksExcel(students) {
      this.schema = {}
      this.loading = true
      const data = []
      this.excelErrors = []
      const sets = Object.keys(this.setsObjectForQuestions)
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] })
        this.schema[sets[i]] = {
          PRN: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentPrn'
          },
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'studentName'
          }
        }
        for (let k = 0; k < students.length; k++) {
          data[i][sets[i]].push({ PRN: students[k].PRN_No, Name:students[k].name })
        }
        let sectionName = '';
        for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
          let qNo = '';

          if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
            sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: sectionName
            }
            data[i][sets[i]].push({
              [sectionName]: ''
            });
          }

          if (this.setsObjectForQuestions[sets[i]][j].questionNumber && this.setsObjectForQuestions[sets[i]][j].questionNumber !== 'OR') {
            qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}_${qNo}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: `${sectionName}_${qNo}`
            }
            data[i][sets[i]].push({
              [`${sectionName}_${qNo}`]: ''
            });
          }

          if (this.setsObjectForQuestions[sets[i]][j].subQnNo && this.setsObjectForQuestions[sets[i]][j].subQnNo !== 'OR' && (!this.setsObjectForQuestions[sets[i]][j].anyStatement || (this.setsObjectForQuestions[sets[i]][j].anyStatement && this.setsObjectForQuestions[sets[i]][j].question))) {
            const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}(${questionMark})`] = {
              validation: {
                type: 'textLength'
              },
              variableName: `${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}`
            }
            data[i][sets[i]].push({
              [`${sectionName}_${this.setsObjectForQuestions[sets[i]][j].subQnNo}`]: ''
            });
          }
        }
      }
      const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const excelSheets = Object.keys(res.data);

          for (const sheet of excelSheets) {
            for (const data of res.data[sheet]) {
              const student = students.find(s => s.studentPrn === data.studentPrn);

              if (student) {
                objectsArrayToUpdateMarks.push({
                  uId: student.studentId,
                  instituteId: this.selectedInstitute,
                  semId: this.selectedSemester.semId,
                  // examId: this.exam.exam.examId,
                  // status: 'Submitted',
                  // submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                  // subjectId: this.prop_selectedSubject.subjectId,
                  // attemptNumber: student.attemptNumber,
                  obtainedMarks: 0,
                  answers: [],
                  marksGivenTo: data,
                  setName: sheet
                });
              }
            }
          }

          for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
            const marksGivenToKeys = Object.keys(objectsArrayToUpdateMarks[i].marksGivenTo)
            for (let j = 0; j < marksGivenToKeys.length; j++) {
              const sectionAndQuestion = marksGivenToKeys[j].split('_')
              if (sectionAndQuestion.length === 1) {
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                  ) {
                    objectsArrayToUpdateMarks[i].obtainedMarks += objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                    });
                  } else if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]) {
                    this.excelErrors.push('Given Marks of ' + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]] + ' are greater than its assigned marks')
                    // showStatus("Given Marks of " + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + " are greater than its assigned marks", 5000, 'error', this);
                  }
                }
              } else {
                let secName = ''
                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                  if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames) secName = this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames
                  if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                      obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]
                    });
                  } else if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                    this.excelErrors.push("Given Marks of " + secName + "-" + (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber ? this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber : this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')] + " are greater than its assigned marks");
                  }
                }
              }
            }
          }
          if (this.excelErrors.length === 0) {
            // await this.assignmentUserRepositoryInstance.bulkUpdateMarksObtainedForAnAssignment({
            //   bulkDataArray: objectsArrayToUpdateMarks
            // });
            objectsArrayToUpdateMarks.forEach(updateObject =>{
              const matchingStudent = students.find(student => student.studentId === updateObject.uId);
              if(matchingStudent){
                matchingStudent.obtainedMarks = updateObject.obtainedMarks;
                matchingStudent.answers = updateObject.answers
              }
            })
            this.uploadQuestionViseMarksViaExcel = false
            this.questionMarksExcelFile = null
            // this.StudentMarks()
            showStatus("Marks updated successfully", 2000, 'success', this);
          }
          // this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
          // this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          // showStatus("Marks updated successfully", 2000, 'success', this);
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors
      }
    },
    addStyleToExcelCell(cell) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFBF80" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        bold: true,
      };
    },

    addBorderToExcelCell(cell) {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    },
    async downloadExcelForStudentList(data) {
      if (this.allCourseYearStudents.length > 0) {
        this.allCourseYearStudents.map((info) => {
          if (this.applicableStudents.length > 0) {
            this.applicableStudents.map((data) => {
              if (data.uId === info.uId) {
                data.examSeatNumber = info.examSeatNumber;
                data.rollNumber = info.rollNumber;
              }
            });
          }
        });
      }
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      try {
        worksheet.getCell(2, 1).value = "Department";
        worksheet.getCell(3, 1).value = "CourseYear";
        worksheet.getCell(4, 1).value = "Subject";
        worksheet.getCell(5, 1).value = "Exam Name";

        this.addStyleToExcelCell(worksheet.getCell(2, 1));
        this.addStyleToExcelCell(worksheet.getCell(3, 1));
        this.addStyleToExcelCell(worksheet.getCell(4, 1));
        this.addStyleToExcelCell(worksheet.getCell(5, 1));

        worksheet.getCell(2, 2).value = this.prop_exam.criteria.department;
        worksheet.getCell(3, 2).value = this.prop_exam.criteria.courseYear;
        worksheet.getCell(4, 2).value = data.subjectName;
        worksheet.getCell(5, 2).value = this.prop_exam.name;
        this.addBorderToExcelCell(worksheet.getCell(2, 2));
        this.addBorderToExcelCell(worksheet.getCell(3, 2));
        this.addBorderToExcelCell(worksheet.getCell(4, 2));
        this.addBorderToExcelCell(worksheet.getCell(5, 2));

        let arr = ["Sr.No", "Student Name", "Exam Seat Number", "Roll Number"];
        arr.map((info, i) => {
          worksheet.getCell(7, i + 1).value = info;
          this.addStyleToExcelCell(worksheet.getCell(7, i + 1));
        });
        if (this.applicableStudents.length > 0) {
          this.applicableStudents.map((info, i) => {
            worksheet.getCell(8 + i, 2).value = info.fullName;
            worksheet.getCell(8 + i, 3).value =
              info.examSeatNumber === undefined || info.examSeatNumber === ""
                ? "-"
                : info.examSeatNumber;
            worksheet.getCell(8 + i, 4).value =
              info.rollNumber === undefined || info.rollNumber === ""
                ? "-"
                : info.rollNumber;
            // worksheet.getCell(8+i, 3).value = info.prn === undefined || info.prn === '' ? '-' : info.prn
            worksheet.getCell(8 + i, 1).value = i + 1;
            this.addBorderToExcelCell(worksheet.getCell(8 + i, 2));
            this.addBorderToExcelCell(worksheet.getCell(8 + i, 1));
            this.addBorderToExcelCell(worksheet.getCell(8 + i, 3));
            this.addBorderToExcelCell(worksheet.getCell(8 + i, 4));
          });
        }
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `ExamStudentList.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },
    async getStudentsOfACourseYear() {
      try {
        this.allCourseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.currentExam.criteria.department,
            courseYear: this.currentExam.criteria.courseYear,
          });
        const studentUIds = this.allCourseYearStudents.map((item) => item.uId);
        this.allCourseYearStudentDetails =
          await this.userRepositoryInstance.getUsers({ uIds: studentUIds });
        for (
          let index = 0;
          index < this.allCourseYearStudentDetails.length;
          index++
        ) {
          const element = this.allCourseYearStudentDetails[index];
          this.examFormTableData.push({
            studentUId: element.uId,
            studentName:
              element.firstName + element.middleName + element.lastName,
            studentPrn: element.collegePRNNo,
            srNo: index + 1,
            examFormStatus: this.currentExam.studentsAllowedToFillExamForm
              ? this.currentExam.studentsAllowedToFillExamForm.includes(
                element.uId
              )
              : true,
          });
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    downloadFilteredStudents() {
      this.downloadFilteredStudentsLoader = true;
      let studentDataLength = this.analysisTableItems.length;
      let headerLength = this.tableHeaders.length;

      let array = [];
      for (let i = 0; i < studentDataLength; i++) {
        const student = this.analysisTableItems[i];

        let objToPush = {};
        objToPush["StudentName"] = student.name;
        objToPush["TotalMarks"] = student.total;
        for (let j = 2; j < headerLength; j++) {
          const subject = this.tableHeaders[j];

          objToPush[`${subject.text}`] = student[subject.value];
        }

        array.push(objToPush);
      }
      createExcelThroughJson(array, "dummy excel");
      this.downloadFilteredStudentsLoader = false;
    },
    studentListOpen() {
      let uIdsOfApplicable = [];
      this.applicableStudents.map((data) => {
        uIdsOfApplicable.push(data.uId);
      });
      this.notSelectedStudentList = [];
      this.selectedStudentList = [];
      this.studentNames.map((stu) => {
        if (!uIdsOfApplicable.includes(stu.uId)) {
          this.notSelectedStudentList.push(stu);
        } else {
          this.selectedStudentList.push(stu);
        }
      });
      // this.studentNames.map((stu) => {
      //     if (uIdsOfApplicable.includes(stu.uId)) {
      //       this.selectedStudentList.push(stu)
      //     }
      // })
      this.studentDialog = true;
    },
    async getUserAuthentication() {
      try {
        const objToPush = {
          uId: this.userData.uId,
        };
        const result =
          await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(
            objToPush
          );

        this.currentUserAuthentication = result.filter(
          (item) => item.userName === this.userData.userName
        )[0];

        // check if current user is sub-admin
        const isSubAdmin = this.currentUserAuthentication.roles.includes(3);
        if (isSubAdmin) {
          const examAccessControls =
            this.currentUserAuthentication.allowedModule[5].subModule;
          this.accessForInvigilation = examAccessControls[3].isAccessForSub;
          this.accessForExamForm = examAccessControls[2].isAccessForSub;
          this.accessForQuestionDetails = examAccessControls[5].isAccessForSub;
          this.accessForMarks = examAccessControls[6].isAccessForSub;
          this.accessForAnalysis = examAccessControls[7].isAccessForSub;
          this.accessForBasicDetails = false;
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    gotoExams() {
      this.$router.push("activityExams");
    },
    async getExam() {
      try {
        this.selectedExam = await apiV3.getRequest("/manageExam/getExam", {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: this.selectedExamStore.examId,
          attemptNumber: this.prop_exam.attemptNumber
            ? this.prop_exam.attemptNumber
            : 1,
        });
        this.currentExam = this.selectedExam.data[0];
        if (this.currentExam.attemptNumber === 2) {
          this.attemptToShow = `${this.currentExam.attemptNumber}nd`;
          this.attemptColor = "#4EBD32";
          this.attemptBackgroundColor = "#4EBD3226";
        } else if (this.currentExam.attemptNumber === 3) {
          this.attemptToShow = `${this.currentExam.attemptNumber}rd`;
          this.attemptColor = "#FFB800";
          this.attemptBackgroundColor = "#FFC32933";
        } else if (this.currentExam.attemptNumber === 4) {
          this.attemptToShow = `${this.currentExam.attemptNumber}th`;
          this.attemptColor = "#FF9052";
          this.attemptBackgroundColor = "#FF7F4833";
        } else if (this.currentExam.attemptNumber === 5) {
          this.attemptToShow = `${this.currentExam.attemptNumber}th`;
          this.attemptColor = "#FF4F1F";
          this.attemptBackgroundColor = "#FF4F1F33";
        } else if (this.currentExam.attemptNumber === 6) {
          this.attemptToShow = `${this.currentExam.attemptNumber}th`;
          this.attemptColor = "#FF0F0F";
          this.attemptBackgroundColor = "#FF101033";
        } else if (this.currentExam.attemptNumber === 7) {
          this.attemptToShow = `${this.currentExam.attemptNumber}th`;
          this.attemptColor = "#D30C0C";
          this.attemptBackgroundColor = "#C8101033";
        }
        this.allSubjectDetails =
          await this.subjectsRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.currentExam.criteria.department,
              courseYear: this.currentExam.criteria.courseYear,
            }
          );
        await this.fetchData();
      } catch (error) {
        console.error(error);
        this.selectedExam = {};
        this.allSubjectDetails = [];
      }
    },
    async fetchData() {
      this.subjectNameArray = [];
      this.subjectAllocation = [];
      let tempSeeSubjectAllocation = [];
      let tempCieSubjectAllocation = [];
      let tempCieIndividualSubjectAllocation = [];
      let tempSubjectAllocation = [];
      this.examName = this.currentExam.name;
      this.examDepartmentName = this.currentExam.criteria.department;
      this.examCourseYear = this.currentExam.criteria.courseYear;
      this.examStartDate = this.currentExam.startDate
        ? this.currentExam.startDate.slice(0, 10)
        : null;
      this.examEndDate = this.currentExam.endDate
        ? this.currentExam.endDate.slice(0, 10)
        : null;
      this.selectedExamType = this.currentExam.type;
      this.examDescription = this.currentExam.description;
      this.subjectDetails = this.selectedExam.subjectDetails;
      this.questionDetails = this.selectedExam.questionDetails;
      this.nbaAttainmentObject = this.currentExam.nbaAttainment;
      this.attemptNumber = this.currentExam.attemptNumber;
      this.reExam = this.currentExam.reExam;
      if (this.currentExam.isForNBA) {
        const temp = await auth.getTempThresholdFromFirebase(
          this.selectedInstitute
        );
        if (temp) {
          this.tempGlobalThreshold = temp;
          this.thresholdType = "overallThreshold";
        } else {
          this.tempGlobalThreshold = 0;
        }
        this.overallThresholdPercentage = this.tempGlobalThreshold;

        this.nbaCriteria = true;
        if (this.instituteType !== "Tier-1") {
          this.thresholdType = this.currentExam.nbaAttainment.thresholdType;
          this.examType = this.currentExam.nbaAttainment.typeOfExam;
          if (this.attainmentCalculationType === "Threshold") {
            if (
              this.examType === "see" &&
              this.thresholdType === "individualThreshold"
            ) {
              this.subjectThresholdArray =
                this.currentExam.nbaAttainment.thresholdPercentage;
              this.subjectAllocation = this.subjectThresholdArray;
            } else if (
              this.examType === "see" &&
              this.thresholdType === "overallThreshold"
            ) {
              this.overallThresholdPercentage = Number(
                this.currentExam.nbaAttainment.thresholdPercentage
              );
              tempSeeSubjectAllocation = this.currentExam.applicableSubjects;
            } else if (
              this.examType === "cie" &&
              this.thresholdType === "overallThreshold"
            ) {
              this.overallThresholdPercentage = Number(
                this.currentExam.nbaAttainment.thresholdPercentage
              );
              tempCieSubjectAllocation = this.currentExam.applicableSubjects;
            } else if (
              this.examType === "cie" &&
              this.thresholdType === "individualThreshold"
            ) {
              tempCieIndividualSubjectAllocation =
                this.currentExam.applicableSubjects;
            }
          } else {
            if (this.examType === "cie") {
              tempCieSubjectAllocation = this.currentExam.applicableSubjects;
            } else if (this.examType === "see") {
              tempSeeSubjectAllocation = this.currentExam.applicableSubjects;
            }
          }
        } else {
          tempSubjectAllocation = this.currentExam.applicableSubjects;
        }
      } else {
        tempSubjectAllocation = this.currentExam.applicableSubjects;
      }
      await this.getStudentsOfACourseYear();
      this.examFormPublished = this.currentExam.examFormPublished;
      // if (this.currentExam.examFormPublished) {
      //   for (
      //   let index = 0;
      //   index < this.allCourseYearStudentDetails.length;
      //   index++
      // ) {
      //   const element = this.allCourseYearStudentDetails[index];

      //   this.examFormTableData.push({
      //     studentUId: element.uId,
      //     studentName:
      //       element.firstName + element.middleName + element.lastName,
      //     studentPrn: element.collegePRNNo,
      //     srNo: index + 1,
      //     examFormStatus: this.currentExam.studentsAllowedToFillExamForm.includes(element.uId),
      //     // studentRollNo:
      //   });
      // }
      // console.log('studentsAllowedToFillExamForm', this.currentExam.studentsAllowedToFillExamForm);
      // }
      let res = [];

      try {
        res = await this.semesterRepositoryInstance.getDivisionsOfASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        });
      } catch (error) {
        console.error(error);
        res = [];
      }

      res.map((r) => {
        if (this.departmentNameArray.indexOf(r.department) === -1) {
          this.departmentNameArray.push(r.department);
        }
        if (this.courseYearArray.indexOf(r.courseYear) === -1) {
          this.courseYearArray.push(r.courseYear);
        }
      });
      this.allSubjectDetails.map((sub) => {
        this.subjectNameArray.push({
          subjectName: sub.subjectName,
          subjectId: sub.subjectId,
        });
        if (this.attainmentCalculationType === "Threshold") {
          if (tempSubjectAllocation) {
            if (tempSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId,
              });
            }
          }
          if (
            this.examType === "see" &&
            this.thresholdType === "overallThreshold"
          ) {
            if (tempSeeSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId,
              });
            }
          }
          if (
            this.examType === "cie" &&
            this.thresholdType === "overallThreshold"
          ) {
            if (tempCieSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId,
              });
            }
          }
          if (
            this.examType === "cie" &&
            this.thresholdType === "individualThreshold"
          ) {
            if (tempCieIndividualSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId,
              });
            }
          }
        } else {
          if (this.examType === "cie") {
            if (tempCieSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId,
              });
            }
          } else if (this.examType === "see") {
            if (tempSeeSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId,
              });
            }
          }
        }
      });
      if (
        this.examType === "see" &&
        this.thresholdType === "individualThreshold"
      ) {
        this.subjectNameArray.map((subject) => {
          this.subjectAllocation.map((sub) => {
            if (subject.subjectId === sub.subjectId) {
              subject.subjectThreshold = sub.subjectThreshold
                ? sub.subjectThreshold
                : "";
            }
          });
        });
      }

      if (this.subjectDetails) {
        let result = this.subjectDetails.every(this.checkSubject); //refer checkSubject function
        result
          ? (this.saveSubjectDetails = true)
          : (this.saveSubjectDetails = false);
      }

      if (this.questionDetails) {
        let result = this.questionDetails.every(this.checkQuestion); //refer checkQuestion function
        result ? (this.publishExam = true) : (this.publishExam = false);
      }

      if (this.subjectAllocation.length === this.subjectNameArray.length) {
        this.allSubjects = true;
      }
      this.updatedAllocatedSubjects = this.subjectAllocation.map(
        (sub) => sub.subjectId
      );
      this.examDataLoaded = true;
    },
    editBasicDetails() {
      this.isBasicDetailEditable = true;
    },
    async updateBasicDetails() {
      this.basicDetailsLoader = true;
      this.isBasicDetailEditable = false;
      if (this.attainmentCalculationType === "Threshold") {
        if (
          this.selectedExamType === "CIE(Internal)" &&
          this.thresholdType === "individualThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "cie",
            thresholdType: "individualThreshold",
          };
        } else if (
          this.selectedExamType === "CIE(Internal)" &&
          this.thresholdType === "overallThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "cie",
            thresholdType: "overallThreshold",
            thresholdPercentage: this.overallThresholdPercentage,
          };
        } else if (
          this.selectedExamType === "SEE(External)" &&
          this.thresholdType === "individualThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "see",
            thresholdType: "individualThreshold",
            thresholdPercentage: this.subjectThresholdArray,
          };
        } else if (
          this.selectedExamType === "SEE(External)" &&
          this.thresholdType === "overallThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "see",
            thresholdType: "overallThreshold",
            thresholdPercentage: this.overallThresholdPercentage,
          };
        }
      } else {
        if (this.selectedExamType === "SEE(External)") {
          this.nbaAttainmentObject = {
            typeOfExam: "see",
            thresholdType: null,
          };
        } else {
          this.nbaAttainmentObject = {
            typeOfExam: "cie",
            thresholdType: null,
          };
        }
      }
      let studentIds = [];
      this.examFormTableData.map((item) => {
        if (item.examFormStatus === true) studentIds.push(item.studentUId);
      });
      let updatedBasicDetails = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.currentExam.examId,
        attemptNumber: this.currentExam.attemptNumber,
        reExam: this.reExam,
        // name: this.examName,
        // criteria: {
        //   department: this.examDepartmentName,
        //   courseYear: this.examCourseYear
        // },
        // type: this.selectedExamType,
        // applicableSubjects: this.updatedAllocatedSubjects,
        isForNBA: this.nbaCriteria,
        questionDetails: this.questionDetails,
        examFormPublished: this.examFormPublished,
      };
      if (this.examFormPublished) {
        updatedBasicDetails.studentsAllowedToFillExamForm = studentIds;
      }
      if (this.reExam) {
        updatedBasicDetails.description = this.examDescription;
        updatedBasicDetails.startDate = this.examStartDate;
        updatedBasicDetails.endDate = this.examEndDate;
      }
      if (this.nbaCriteria) {
        updatedBasicDetails.nbaAttainment = this.nbaAttainmentObject;
      }
      try {
        await apiV3.postRequest(
          "/manageExam/updateBasicDetails",
          updatedBasicDetails
        );
        showStatus(
          "Basic Details Updated Successfully.",
          5000,
          "success",
          this
        );
        this.basicDetailsLoader = false;
      } catch (error) {
        console.error(error);
        showStatus(
          "Error saving basic details. Please try after some time.",
          5000,
          "success",
          this
        );
      }
      this.basicDetailsLoader = false;
    },
    async saveAsDraft() {
      if (this.nbaCriteria) {
        await this.nbaDetails();
      }
      await this.createArray();

      let noErrors = true;
      if (!this.examName) {
        this.emptyName = true;
        noErrors = false;
      }
      if (!this.examDescription) {
        this.emptyDescription = true;
        noErrors = false;
      }
      if (this.selectedDepartment === "") {
        this.noCriteria = true;
        noErrors = false;
      }
      if (this.startDate) {
        if (
          new Date(this.startDate) ==
          new Date(new Date().toLocaleString().split(",")[0])
        ) {
          this.invalidStartDate = true;
          noErrors = false;
        }
      } else {
        this.noStartDate = true;
        noErrors = false;
      }
      if (this.endDate) {
        if (this.startDate) {
          if (!(new Date(this.startDate) < new Date(this.endDate))) {
            this.invalidEndDate = true;
            noErrors = false;
          }
        }
      } else {
        this.noEndDate = true;
        noErrors = false;
      }
      if (noErrors === false) {
        showStatus("Please Enter Valid Data", 5000, "error", this);
      }
      if (noErrors) {
        const criteria = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear
            ? this.selectedCourseYear
            : undefined,
          division: "All",
        };
        this.subjectAllocation.forEach((sub) => {
          this.subjectObject.push(sub.subjectId);
        });
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          name: this.examName,
          description: this.examDescription ? this.examDescription : "",
          type: this.selectedExamType ? this.selectedExamType : "",
          startDate: this.startDate,
          // startDate: this.startDate ? convertToISTTime(
          //   convertFromYYYYMMDDToDate(this.startDate)
          // ).toISOString() : "",
          // endDate: this.endDate ? convertToISTTime(
          //   convertFromYYYYMMDDToDate(this.endDate)
          // ).toISOString() : "",
          endDate: this.endDate,
          criteria: criteria ? criteria : "",
          isForNBA: this.nbaCriteria,
          applicableSubjects: this.subjectObject ? this.subjectObject : [],
          subjectDetails: this.subjectDetails ? this.subjectDetails : [],
          questionDetails: this.questionDetails ? this.questionDetails : [],
          examStatus: "Draft",
          attemptNumber: this.attemptNumber,
          reExam: this.reExam,
          allFaculties: this.facultyNames,
        };
        if (this.nbaCriteria) {
          objToPush.nbaAttainment = this.nbaAttainmentObject
            ? this.nbaAttainmentObject
            : "";
        }
        try {
          const res = await apiV3.postRequest(
            "/manageExam/saveAsDraft",
            objToPush
          );
          showStatus("Exam Saved as a draft", 5000, "success", this);
          if (res.emailToBeSent && res.emailToBeSent.length > 0) {
            this.emailSentNotificationArray = res.emailToBeSent;
            this.emailNotificationSentModal = true;
          } else {
            this.$router.push("./activityExams");
          }
          // this.$router.push("./activityExams");
        } catch (err) {
          console.error(err);
          showStatus("Error while saving as a draft", 9000, "error", this);
        }
      }
    },
    handleNotificationClose() {
      this.emailNotificationSentModal = false;
      this.$router.push("./activityExams");
    },
    handleSubejcts() {
      this.updatedAllocatedSubjects = [];
      this.subjectThresholdArray = [];
      this.subjectAllocation.map((sub) => {
        this.updatedAllocatedSubjects.push(sub.subjectId);
        this.subjectThresholdArray.push(sub);
      });
      this.subjectAllocation.length === this.subjectNameArray.length
        ? (this.allSubjects = true)
        : (this.allSubjects = false);
      this.subjectDetails = this.subjectAllocation.filter(
        (subject) => this.subjectDetails.indexOf(subject.subjectId) === -1
      );
    },
    handleAllSubejcts() {
      if (this.allSubjects === false) {
        this.subjectAllocation = [];
      } else {
        this.subjectAllocation = this.subjectNameArray;
      }
    },
    handleIndividualThreshold() {
      this.subjectThresholdArray = [];
      this.subjectAllocation.map((sub) => {
        this.subjectThresholdArray.push(sub);
      });
    },
    handleIndividualThresholdTextField(subjectId, subjectThreshold) {
      this.subjectNameArray.map((sub) => {
        if (sub.subjectId === subjectId) {
          sub.subjectThreshold = subjectThreshold;
        }
      });
    },
    handleSubjectDetails() {
      this.subjectIds = this.subjectDetails.map((sub) => {
        return sub.subjectId;
      });
      this.subjectAllocation.map((sub) => {
        if (!this.subjectIds.includes(sub.subjectId)) {
          this.subjectDetails.push(sub);
        }
      });
      this.startDate = this.examStartDate;
      this.endDate = this.examEndDate;
    },
    // async updateSubjectDetails(status) {
    //   status === "publish"
    //     ? (this.updateSubjectDetailsLoader = true)
    //     : (this.subjectDetailsAsDraftLoader = true);
    //   try {
    //     const update = {
    //       instituteId: this.selectedInstitute,
    //       semId: this.selectedSemester.semId,
    //       data: this.currentExam,
    //       examId: this.currentExam.examId,
    //       subjectDetails: this.subjectDetails,
    //       questionDetails: this.questionDetails,
    //       allFaculties: this.facultyNames,
    //     };
    //     const res = await apiV3.putRequest(
    //       "/manageExam/updateSubjectDetails",
    //       update
    //     );
    //     this.updateSubjectDetailsLoader = false;
    //     status === "publish"
    //       ? (this.updateSubjectDetailsLoader = false)
    //       : (this.subjectDetailsAsDraftLoader = false);
    //     if (res.emailToBeSent && res.emailToBeSent.length > 0) {
    //       this.emailSentNotificationArray = res.emailToBeSent;
    //       this.emailNotificationSentModal = true;
    //     }
    //     showStatus(
    //       "Subject Details Updated Successfully",
    //       5000,
    //       "success",
    //       this
    //     );
    //   } catch (e) {
    //     console.error(e);
    //     this.updateSubjectDetailsLoader = false;
    //     status === "publish"
    //       ? (this.updateSubjectDetailsLoader = false)
    //       : (this.subjectDetailsAsDraftLoader = false);
    //     showStatus(
    //       "Error updating subject Details. Please try after some time",
    //       9000,
    //       "error",
    //       this
    //     );
    //   }
    // },
    async updateSubjectDetails(status) {
      status === "publish"
        ? (this.updateSubjectDetailsLoad = true)
        : (this.subjectDetailsAsDraftLoader = true);
      try {
        const updatePromises = this.subjectDetails.map(async (subject) => {
          const update = {
            data: {
              name: this.currentExam.name,
              startDate: this.currentExam.startDate,
              endDate: this.currentExam.endDate,
              type: this.currentExam.type
            },
            // examId: this.currentExam.examId,
            subjectId: subject.subjectId, // Include subjectId for the specific subject
            subjectDetails: subject, // Send only the current subject details
            questionDetails: this.questionDetails.filter(que => que.subjectId === subject.subjectId) || [],
            allFaculties: this.facultyNames,
          };
          return apiV3.putRequest(`/manageExam/updateSubjectDetails`, update);
        });

        const results = await Promise.all(updatePromises);

        // Handle responses for each subject
        results.forEach((res, index) => {
          const subject = this.subjectDetails[index];
          if (res.emailToBeSent && res.emailToBeSent.length > 0) {
            this.emailSentNotificationArray = res.emailToBeSent;
            this.emailNotificationSentModal = true;
          }
          showStatus(
            `Subject Details Updated Successfully`,
            5000,
            "success",
            this
          );
        });

        this.updateSubjectDetailsLoader = false;
        status === "publish"
          ? (this.updateSubjectDetailsLoader = false)
          : (this.subjectDetailsAsDraftLoader = false);
      } catch (e) {
        console.error(e);
        this.updateSubjectDetailsLoader = false;
        status === "publish"
          ? (this.updateSubjectDetailsLoader = false)
          : (this.subjectDetailsAsDraftLoader = false);
        showStatus(
          "Error updating subject Details. Please try after some time",
          9000,
          "error",
          this
        );
      }
    },

    onSubjectExpansionPanelClick(e, sub) {
      if (
        !e.currentTarget.classList.contains("v-expansion-panel-header--active")
      ) {
        this.subjectPanel(sub);
      }
    },
    async subjectPanel(subject) {
      if(this.reExam){
        if(this.applicableHeader.length > 2) {
          this.applicableHeader.splice(2)
        }
        this.applicableHeader.push(
        {
          text: "Internal",
          value: "internal",
        },
        {
          text: "External",
          value: "external",
        },
        )
      }
      this.subjectDetailsLoaded = false;
      await this.getStudents(subject);
      await this.getFaculties();
      await this.getRoomName(subject);
      this.subjectDetailsLoaded = true;


    },
    async getStudents(subject) {
      this.studentArray = [];
      try {
        // const objOfStudent = {
        //   instituteId: this.selectedInstitute,
        //   semId: this.selectedSemester.semId,
        //   subjectId: subject.subjectId,
        // };
        // this.studentList =
        //   await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
        //     objOfStudent
        //   );
        const studentIds = this.examFormTableData.map((item) => {
          if (item.examFormStatus) return item.studentUId;
        });
        this.studentList = this.allCourseYearStudents.filter(
          (item) =>
            item.subjects.includes(subject.subjectId) &&
            studentIds.includes(item.uId)
        );
        this.studentList.forEach((student) => {
          this.studentArray.push(student.uId);
        });
        // const objForFullName = {
        //   uIds: this.studentArray,
        // };
        // this.studentNames =
        //   await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);

        // this.studResponse = await this.userRepositoryInstance.getUsers(
        //   objForFullName
        // );

        const studentFullNames = [];
        this.allCourseYearStudentDetails.map((stu) => {
          if (this.studentArray.includes(stu.uId)) {
            let objToPush = {
              fullName: (
                stu.firstName +
                " " +
                stu.middleName +
                " " +
                stu.lastName
              ).trim(),
              uId: stu.uId,
              prn: stu.collegePRNNo,
            }
            if (this.reExam) {
              let internal = false
              let external = false
              if(subject.reExamStudentDetails) {
                const result = subject.reExamStudentDetails.filter(item => item.uId === stu.uId)[0]
                if(result) {
                  internal = result.internal
                  external = result.external
                }
              }
              objToPush['internal'] = internal
              objToPush['external'] = external
            }
            studentFullNames.push(objToPush);
          }
        });
        this.studentNames = studentFullNames;
        this.applicableStudents = studentFullNames;
        this.allStudents = this.applicableStudents;

        // this.applicableStudents = this.studentNames
        // this.allStudents = this.applicableStudents
      } catch (e) {
        this.studentNames = [];
        this.studentArray = [];
        this.studentList = [];
        console.error(e);
      }
    },
    async getFaculties() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        };
        this.facultyList =
          await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute(
            objToPush
          );
        Array.isArray(this.facultyList)
          ? this.facultyList.forEach((faculty) => {
            this.facultyArray.push(faculty.uId);
          })
          : null;
        const objForFullName = {
          uIds: this.facultyArray,
        };
        this.facultyNames =
          await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);
        this.allFaculty = this.facultyNames;
      } catch (err) {
        this.facultyList = [];
        this.facultyNames = [];
        this.facultyArray = [];
        console.error(err);
      }
    },
    async getRoomName(subject) {
      try {
        this.roomNames = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.examDepartmentName,
        };
        this.roomData =
          await this.infrastructureReferenceRepositoryInstance.getAllRoomsOfAInstituteAndDepartment(
            objToPush
          );
        this.roomData.map((room) => {
          this.roomNames.push({
            roomName: room.roomName,
            roomId: room.roomId,
            batchSize: room.batchSize,
          });
        });
        this.allRoom = this.roomNames;

        this.subjectDetails.map((room) => {
          if (subject.subjectId === room.subjectId) {
            Array.isArray(room.roomAllocation)
              ? room.roomAllocation.map((selected) => {
                this.roomNames.map((name) => {
                  if (selected.roomName === name.roomId) {
                    this.updatedRoom = name.roomName;
                  }
                });
              })
              : null;
          }
        });
      } catch (err) {
        console.error(err);
        this.roomData = [];
        this.locations = [];
      }
      if (this.roomNames.length === 0) {
        showStatus(
          `No rooms are added yet for this department.`,
          9000,
          "error",
          this
        );
      }
    },
    async getSubjects() {
      try {
        this.subjects = [];
        this.subjects =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
            }
          );
        this.subjects.forEach((sub) => {
          this.subjectNames.push({
            subjectName: sub.subjectName,
            subjectId: sub.subjectId,
          });
          this.allSubject.push(sub);
        });
      } catch (err) {
        this.subjects = [];
        console.error(err);
      }
    },
    async tempAddRoom(subject) {
      if (subject.roomStatus === true) {
        this.editRoomIndex = -1;
        this.selectedStudent = [];
        this.selectedFaculty = [];
        let roomDta = subject.roomNames;
        roomDta.forEach((room, i) => {
          if (room.roomId === this.allowedRoom) {
            roomDta.splice(room, 1);
          }
        });
        subject.roomNames = roomDta;
        subject.facultyNames = subject.facultyNames.filter(
          (item) => !this.selectedFacultyArray.includes(item.uId)
        );
        subject.applicableStudents = subject.applicableStudents.filter(
          (item) => !this.selectedStudentArray.includes(item.uId)
        );
      } else {
        subject["applicableStudents"] = this.applicableStudents;
        subject["facultyNames"] = this.allFaculty;
        subject["roomNames"] = this.allRoom;
      }
      this.allowedRoom = "";
      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.editingRoom = false;
      this.tempSelectedRoom = {
        roomNumber: this.roomAllocation.length + 1,
        roomName: "",
        appliedStudents: [],
        appliedFaculties: [],
      };
      this.allStudentList = subject;
      this.addNewRoomDialog = true;
    },
    handleRoomSelection() {
      const result = this.roomNames.filter(
        (item) => item.roomId === this.allowedRoom
      )[0];
      this.roomName = result.roomName;
      this.maxCapacityOfOfTheRoom = Number(result.batchSize);
    },
    async saveTempRoom(subject) {
      this.selectedStudentArray = [];
      this.selectedFacultyArray = [];
      // this.roomNames.forEach((room) => {
      //   if (room.roomId === this.allowedRoom) {
      //     this.roomName = room.roomName;
      //   }
      // });
      if (this.selectedStudent.length <= this.maxCapacityOfOfTheRoom) {
        this.tempSelectedRoom.roomName = this.allowedRoom;
        this.selectedStudent.map((stud) => {
          this.selectedStudentArray.push(stud.uId);
        });
        this.tempSelectedRoom.appliedStudents = this.selectedStudentArray;
        this.selectedFaculty.map((faculty) => {
          this.selectedFacultyArray.push(faculty.uId);
        });
        this.tempSelectedRoom.appliedFaculties = this.selectedFacultyArray;
        if (this.editRoomIndex !== -1) {
          this.roomAllocation[this.editRoomIndex] = this.tempSelectedRoom;
          this.editRoomIndex = -1;
        } else {
          if (this.allowedRoom !== "") {
            this.roomAllocation.push(this.tempSelectedRoom);
            subject["roomStatus"] = true;
          }
        }
        this.addNewRoomDialog = false;
      } else {
        showStatus(
          `Maximum student limit of the room is exceeded.`,
          9000,
          "error",
          this
        );
      }
    },
    async editSubject(subject) {
      this.dialogWarning = true;
      this.saveChangesBtnDisable = false;
      this.applicableStudents = [];
      this.selectedApplicablestudents = [];
      subject.editSubjectDetails = false;
      this.studentNames.forEach((obj) => {
        subject.totalStudents.forEach((object) => {
          if (object === obj.uId) {
            if(subject.reExamStudentDetails) {
                const result = subject.reExamStudentDetails.filter(item => item.uId === obj.uId)[0]
                if(result) {
                  obj['internal'] = result.internal
                  obj['external'] = result.external
                }
              }
            this.applicableStudents.push(obj);
          }
        });
      });
      this.selectedApplicablestudents = subject.totalStudents;
      this.selectedDate = subject.selectedDate;
      this.selectedTime = subject.selectedTime;
      this.duration = subject.duration;
      this.totalMarks = subject.totalMarks;
      this.passingMarks = subject.passingMarks;
      this.roomAllocation = subject.roomAllocation;
      if (subject.roomAllocation) {
        subject.roomAllocation.forEach((selected) => {
          this.selectedFacultyArray = selected.appliedFaculties;
          this.selectedStudentArray = selected.appliedStudents;
        });
      }
    },
    async saveSubject(subject) {
      let error = false;
      if (Number(this.passingMarks) > Number(this.totalMarks)) {
        error = true;
      } else {
        error = false;
      }

      if (!error) {
        try {
          this.selectedApplicablestudents = [];
          const tempArray = []
          this.applicableStudents.forEach((obj) => {
            this.selectedApplicablestudents.push(obj.uId);
            if (!tempArray.find((item) => item.uId === obj.uId)) {
              tempArray.push({
                uId: obj.uId,
                internal: obj.internal,
                external: obj.external
              })
            }
          });

          this.subjectDetails.forEach((subj) => {
            if (subj.subjectId === subject.subjectId) {
              subj.subjectId = subject.subjectId;
              subj.subjectName = subject.subjectName;
              subj.totalStudents = this.selectedApplicablestudents;
              subj.selectedDate = this.selectedDate;
              subj.selectedTime = this.selectedTime;
              subj.duration = this.duration;
              subj.totalMarks = this.totalMarks;
              subj.passingMarks = this.passingMarks;
              subj.roomAllocation = this.roomAllocation;
              subj.editSubjectDetails = true;
              if (this.reExam) {
                subj.reExamStudentDetails = tempArray;
              }

              let array1 = [];
              let newStudentIds = [];
              let deletedStudents = [];
              let indexToDelete = [];
              let selectedSubject = {};
              this.questionDetails.map((subject) => {
                if (subj.subjectId === subject.subjectId) {
                  selectedSubject = subject;
                  if (subject.studentData) {
                    subject.studentData.map((stud) => {
                      array1.push(stud.studentId);
                    });
                  }

                  subj.totalStudents.map((ele) => {
                    if (!array1.includes(ele)) {
                      newStudentIds.push(ele);
                    }
                  });
                  array1.map((ele) => {
                    if (!subj.totalStudents.includes(ele)) {
                      deletedStudents.push(ele);
                    }
                  });
                }
              });
              if (deletedStudents.length > 0) {
                deletedStudents.map((ele) => {
                  selectedSubject.studentData.map((stud, i) => {
                    if (ele === stud.studentId) {
                      indexToDelete.push(i);
                    }
                  });
                });
                for (var i = indexToDelete.length - 1; i >= 0; i--)
                  selectedSubject.studentData.splice(indexToDelete[i], 1);
              }
              if (newStudentIds.length > 0) {
                this.studentNames.map((eachStudent, i) => {
                  this.subjectDetails.map((stud, index) => {
                    if (stud.subjectId === selectedSubject.subjectId) {
                      if (newStudentIds) {
                        newStudentIds.map((obj) => {
                          if (obj === eachStudent.uId) {
                            const temp = {
                              instituteId: this.selectedInstitute,
                              semId: this.selectedSemester.semId,
                              studentName: eachStudent.fullName,
                              studentId: eachStudent.uId,
                              total: stud.totalMarks,
                              subjectName: stud.subjectName,
                              subjectId: stud.subjectId,
                              studentPrn: eachStudent.prn,
                            };
                            if (selectedSubject.studentData) {
                              selectedSubject.studentData.push(temp);
                            }
                          }
                        });
                      }
                    }
                  });
                });
              }
            }
          });
          this.editSubjectDetails = true;
          this.selectedApplicablestudents = [];
          this.selectedDate = "";
          this.selectedTime = "";
          this.duration = "";
          this.totalMarks = "";
          this.passingMarks = "";
          this.roomAllocation = [];
          this.applicableStudents = [];
        } catch (e) {
          console.error(e);
        }
        let result = this.subjectDetails.every(this.checkSubject); //refer checkSubject function
        result
          ? (this.saveSubjectDetails = true)
          : (this.saveSubjectDetails = false);
        this.saveChangesBtnDisable = true;
      }
    },
    checkSubject(sub) {
      if (sub.totalMarks && sub.passingMarks) {
        return true;
      } else {
        return false;
      }
    },
    checkQuestion(que) {
      if (que.editQuestionDetails) {
        return true;
      } else {
        return false;
      }
    },
    async editRoom(index, subject) {
      subject.roomNames = this.allRoom;
      subject.applicableStudents = this.applicableStudents;
      subject.facultyNames = this.allFaculty;

      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.studentNames.map((stud) => {
        this.roomAllocation[index].appliedStudents.map((obj) => {
          if (stud.uId === obj) {
            this.selectedStudent.push(stud);
          }
        });
      });
      this.facultyNames.map((faculty) => {
        this.roomAllocation[index].appliedFaculties.map((obj) => {
          if (faculty.uId === obj) {
            this.selectedFaculty.push(faculty);
          }
        });
      });
      this.tempSelectedRoom = {
        roomNumber: index,
        roomName: this.roomAllocation[index].allowedRoom,
        appliedStudents: this.roomAllocation[index].appliedStudents,
        appliedFaculties: this.roomAllocation[index].appliedFaculties,
      };
      this.editRoomIndex = index;
      this.allStudentList = subject;
      this.addNewRoomDialog = true;
    },
    async openDeleteDailog(index, roomAllocation, subject) {
      this.indexValue = index;
      this.roomAllocationObject = roomAllocation;
      this.deleteSubject = subject;
      this.deleteDialog = true;
    },
    async deleteRoom(indexValue, roomAllocation, deleteSubject) {
      let subjectIndex = this.subjectDetails.indexOf(deleteSubject);
      this.allRoom.forEach((room) => {
        if (room.roomId === roomAllocation[indexValue].roomName) {
          roomAllocation[indexValue].roomName = "";
          deleteSubject.roomNames.push(room);
          // deleteSubject['roomNames'] = deleteSubject.roomNames
        }
      });
      //faculty
      this.allFaculty.forEach((faculty) => {
        roomAllocation[indexValue].appliedFaculties.forEach((selected, i) => {
          if (faculty.uId === selected) {
            this.selectedFacultyArray.map((element) => {
              if (element === selected) {
                this.selectedFacultyArray.splice(element, 1);
              }
            });
            if (!deleteSubject.facultyNames.includes(faculty)) {
              deleteSubject.facultyNames.push(faculty);
            }
          }
        });
      });
      //students
      this.allStudents.forEach((student) => {
        roomAllocation[indexValue].appliedStudents.forEach((selected, i) => {
          if (student.uId === selected) {
            this.selectedStudentArray.map((element) => {
              if (element === selected) {
                this.selectedStudentArray.splice(element, 1);
              }
            });
            if (!deleteSubject.applicableStudents.includes(student)) {
              deleteSubject.applicableStudents.push(student);
            }
          }
        });
      });
      this.subjectDetails[subjectIndex] = deleteSubject;
      roomAllocation.splice(indexValue, 1);
      this.deleteDialog = false;
    },
    //question Details
    // async updateQuestionDetails(status) {
    //   this.updateQuestionDetailsLoader = true;
    //   try {
    //     const update = {
    //       instituteId: this.selectedInstitute,
    //       semId: this.selectedSemester.semId,
    //       examId: this.currentExam.examId,
    //       questionDetails: this.questionDetails,
    //       examStatus: status,
    //       attemptNumber: this.currentExam.attemptNumber,
    //     };
    //     await apiV3.putRequest("/manageExam/updateAssessmentDetails", update);

    //     this.updateQuestionDetailsLoader = false;
    //     if (update.examStatus === "Draft") {
    //       showStatus("Exam Published Successfully", 5000, "success", this);
    //       this.$router.push("./activityExams");
    //     } else if (update.examStatus === "Published") {
    //       showStatus(
    //         "Question Details Updated Successfully",
    //         5000,
    //         "success",
    //         this
    //       );
    //     }
    //   } catch (e) {
    //     console.error(e);
    //     this.updateQuestionDetailsLoader = false;
    //     showStatus(
    //       "Error updating question details. Please Try after some time",
    //       9000,
    //       "error",
    //       this
    //     );
    //   }
    // },
    async updateQuestionDetails(status) {
      this.updateQuestionDetailsLoader = status === "publish";
      try {
        const updatePromises = this.questionDetails.map(async (question) => {
          let studentDataModified = question.studentData?.map((individualStudentData)=>{
            const { studentName, studentPrn, studentId, subjectId, obtainedMarks, total, graceMarks, graceMarksSymbol, instituteId, semId, answers, ...rest} = individualStudentData
            return { studentName , studentPrn, studentId, subjectId, obtainedMarks, total, graceMarks, graceMarksSymbol, instituteId, semId, answers}
          })
          question.studentData  = studentDataModified
          const update = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            examId: this.currentExam.examId,
            questionDetails: question, // Send only the current question details
            examStatus: status,
            attemptNumber: this.currentExam.attemptNumber,
          };
          return apiV3.putRequest(
            "/manageExam/updateAssessmentDetails",
            update
          );
        });

        const results = await Promise.all(updatePromises);

        // Handle responses for each question
        results.forEach((res, index) => {
          const question = this.questionDetails[index];
          if (res.emailToBeSent && res.emailToBeSent.length > 0) {
            this.emailSentNotificationArray = res.emailToBeSent;
            this.emailNotificationSentModal = true;
          }
          showStatus(
            `Questions Updated Successfully`,
            5000,
            "success",
            this
          );
        });

        this.updateQuestionDetailsLoader = false;
        if (status === "publish") {
          showStatus("Exam Published Successfully", 5000, "success", this);
          this.$router.push("./activityExams");
        } else if (status === "published") {
          showStatus(
            "Question Details Updated Successfully",
            5000,
            "success",
            this
          );
        }
      } catch (e) {
        console.error(e);
        this.updateQuestionDetailsLoader = false;
        showStatus(
          "Error updating question details. Please Try after some time",
          9000,
          "error",
          this
        );
      }
    },
    async questionDetailsAsDraft() {
      this.questionSaveAsDraft = true;
      try {
        this.questionDetails.studentData.map((studentDataInd)=>{
          const { studentName, studentPrn, subjectId} = studentDataInd
        })
        const update = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: this.currentExam.examId,
          questionDetails: this.questionDetails,
          attemptNumber: this.currentExam.attemptNumber,
          // examStatus: this.currentExam.examStatus,
        };
        // await apiV3.putRequest("/manageExam/updateAssessmentDetails", update);
        this.questionSaveAsDraft = false;
        showStatus(
          "Question Details Updated Successfully",
          5000,
          "success",
          this
        );
        this.$router.push("./activityExams");
      } catch (e) {
        console.error(e);
        this.questionSaveAsDraft = false;
        showStatus(
          "Error updating question details. Please Try after some time",
          9000,
          "error",
          this
        );
      }
    },

    async handleQuestionDetails() {
      this.questionDetailsSectionLoader = true;
      await this.getExam();
      const questionIds = this.questionDetails.map((que) => {
        return que.subjectId;
      });
      this.subjectAllocation.map((sub) => {
        if (!questionIds.includes(sub.subjectId)) {
          this.questionDetails.push(sub);
        }
      });
      this.questionDetailsSectionLoader = false;
      if(this.currentExam.isForNBA === true) {
        this.headers5 =  [
          {
            text: 'Sections',
            value: 'sectionNames',
            sortable: false,
            width: "10%",
            align: "center"
          },
          {
            text: "Qn No",
            value: "questionNumber",
            sortable: false,
            width: "100px",
            align: "center"
          },
          {
            text: 'Sub Qn No',
            value: 'subQnNo',
            sortable: false,
            width: "100px",
            align: "center"
          },
          {
            text: 'Question Statements and Section Names   ',
            value: 'question',
            sortable: false,
            width: "300px",
            align: "center"
          },
          {
            text: "Marks",
            value: "questionMarks",
            width: "100px",
            align: "center"
          }
        ]
        if(this.instituteType === 'Tier-1'){
          this.headers5.push({
            text: 'CLO’s mapped',
            value: 'cos',
            sortable: false,
            width: "13%",
            align: "center"
          },
          {
            text: 'Blooms Mapped',
            value: 'blooms',
            sortable: false,
            width: "15%",
            align: "left"
          },
          {
            text: '',
            value: 'delete',
            sortable: false,
            width: "5%",
            align: "left"
          }
         )
        }else{
          this.headers5.push(
            {
            text: 'CO’s mapped',
            value: 'cos',
            sortable: false,
            width: "13%",
            align: "center"
          },
          {
            text: 'Blooms Mapped',
            value: 'blooms',
            sortable: false,
            width: "15%",
            align: "left"
          },
          {
            text: '',
            value: 'delete',
            sortable: false,
            width: "5%",
            align: "left"
          })
        }
      }else{
        this.headers5 =  [
        {
          text: 'Sections',
          value: 'sectionNames',
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: "Qn No",
          value: "questionNumber",
          sortable: false,
          width: "10%",
          align: "center"
        },
        {
          text: 'Sub Qn No',
          value: 'subQnNo',
          sortable: false,
          width: "11%",
          align: "center"
        },
        {
          text: 'Question Statements and Section Names   ',
          value: 'question',
          sortable: false,
          width: "30%",
          align: "center"
        },
        {
          text: "Marks",
          value: "questionMarks",
          width: "9%",
          align: "center"
        },
        {
          text: '',
          value: 'delete',
          sortable: false,
          width: "5%",
          align: "left"
        }
      ]
      }
   },  
    handleExPan(event, subject, i) {
      // if panel is open
      if (
        !event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        this.questionPanel(subject, i);

      }
    },
    async questionPanel(subject, i) {
      this.selectedRadio = null;  
      this.fetchAssignmentQuestions(subject);
      this.questionDetailSaveError = false;
      if (!subject.editQuestionDetails) {
        this.editQuestionDetails = false;
      }
      this.maxMarks = 0;
      this.tempTotalMarks = 0;
      this.subjectDetails.map((sub) => {
        if (sub.subjectId === subject.subjectId) {
          if (sub.totalMarks) {
            this.tempTotalMarks = Number(sub.totalMarks);
            this.maxMarks = Number(sub.totalMarks);
          }
          if (!sub.totalMarks || !sub.passingMarks || !sub.totalStudents) {
            this.questionDetailSaveError = true;
            this.questionPanelError = `Subject details are not saved yet for ${subject.subjectName}. This will lead to errors while saving assessment details.`;
          } else {
            this.questionDetailSaveError = false;
          }
        }
      });
      this.noMarks = false;
      this.noQuestions = false;
      this.toggleQuestionUpload = null;
      this.toggleMarksUpload = null;
      this.noQuestionOverallThresholdPercentage = this
        .overallThresholdPercentage
        ? this.overallThresholdPercentage
        : 0;
      this.questionDataLoaded = false;
      this.assignedCoArray = [];
      this.assignedBloomsArray = [];
      this.allBlooms.map((bloom) => {
        this.assignedBloomsArray.push(bloom.shortCode);
      });
      await this.getStudents(subject);
      this.test = this.overallThresholdPercentage;

      if (!subject.uploadQuestions) {
        this.noQuestions = true;
      } else {
        this.noQuestions = false;
        await this.uploadQuestionManually(subject);
      }

      if (subject.studentData) {
        this.show = true;
        if (!subject.uploadMarksType) {
          this.noMarks = true;
        } else {
          this.uploadMarksManually(subject);
        }
      } else {
        this.noMarks = true;
      }
      let index = this.questionDetails.indexOf(subject);
      if (subject.editQuestionDetails === true) { //database
        this.overallThresholdPercentage = subject.overallThreshold;
        this.noQuestionOverallThresholdPercentage = subject.overallThreshold;
        this.questionSet = subject.questions;
        this.totalQuestions = subject.totalQuestions;

        this.studentData = subject.studentData;

        this.assignedCoArray = subject.assignedCo;
        this.assignedBloomsArray = subject.assignedBlooms;
        this.editQuestionDetails = subject.editQuestionDetails;
        if (subject.uploadQuestions === (undefined || null)) {
          this.noQuestions = true;
        } else if (subject.uploadQuestions) {
          this.toggleQuestionUpload = "uploadQuestions";
        } else {
          this.toggleQuestionUpload = "dontUploadQuestions";
          this.assignedCoArray = subject.assignedCo;
          this.assignedBloomsArray = subject.assignedBlooms;
        }

        if (subject.uploadMarksType === undefined) {
          this.noMarks = true;
        } else if (subject.uploadMarksType === "aggregate") {
          this.toggleMarksUpload = "aggregate";
          this.uploadMarksManually(subject);
        } else if (subject.uploadMarksType === "individual") {
          this.toggleMarksUpload = "individual";
          this.uploadMarksManually(subject);
        }
        this.getCourseOutcome(subject);
      } else { //first time
        // this.overallThreshold = ''
        this.questionsArray = [];
        this.questionSet = [];
        this.totalQuestions = 0;
        this.uploadQuestionManuallyStatus = false;
        // this.editQuestionDetails = false
        // this.noQuestionOverallThresholdPercentage =
        this.uploadMarksManuallyStatus = false;
        if (this.nbaCriteria === true) {
          if (Array.isArray(this.nbaAttainmentObject.thresholdPercentage)) {
            this.nbaAttainmentObject.thresholdPercentage.map((obj, i) => {
              if (obj.subjectId === subject.subjectId) {
                this.overallThresholdPercentage = obj.subjectThreshold;
                this.noQuestionOverallThresholdPercentage =
                  obj.subjectThreshold;
              }
            });
          } else {
            this.overallThresholdPercentage = this.overallThresholdPercentage
              ? this.overallThresholdPercentage
              : 0;
          }
        } else {
          this.overallThresholdPercentage = this.overallThresholdPercentage
            ? this.overallThresholdPercentage
            : 0;
        }

        let tempStudentData = [];

        this.questionDetails[index].studentData = [];
        this.studentNames.map((eachStudent, i) => {
          this.subjectDetails.map((stud, index) => {
            if (stud.subjectId === subject.subjectId) {
              if (stud.totalStudents) {
                stud.totalStudents.map((obj) => {
                  if (obj === eachStudent.uId) {
                    const temp = {
                      instituteId: this.selectedInstitute,
                      semId: this.selectedSemester.semId,
                      studentName: eachStudent.fullName,
                      studentId: eachStudent.uId,
                      total: stud.totalMarks,
                      subjectName: stud.subjectName,
                      subjectId: stud.subjectId,
                      studentPrn: eachStudent.prn,
                      graceMarks: 0,
                      graceMarksSymbol : ''
                    };
                    tempStudentData.push(temp);
                  }
                });
              }
            }
          });
        });
        this.studentData = tempStudentData;
        await this.getCourseOutcome(subject);
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.assignedCoArray.push(nba.CONumber);
          });
        }
        this.assignData(index, this.studentData);
      }
      if (this.tempGlobalThreshold > 0) {
        this.overallThresholdPercentage = this.tempGlobalThreshold;
        this.noQuestionOverallThresholdPercentage = this.tempGlobalThreshold;
      }
      // await this.uploadQuestionManually(subject)
      // await this.getStudents(subject);
      if (this.overallThresholdPercentage) {
        this.overallPercentageStatus = true;
      }
      this.questionDataLoaded = true;
    },
    async getLevelsOfInstitute(subjectId) {
      this.attainmentLevelObject = {};
      const nbaData = await this.nbaRepositoryInstance.getLevelsOfInstitute({
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.selectedSemester.semId,
        subjectId: subjectId,
      });
      if (this.instituteType === "Tier-1") {
        this.attainmentLevelObject = nbaData.data[0].attainmentLevels;
        this.getTargetsOfAnInstitute();
        this.calculateSubjectWiseOverallLevelAttainmentAnalysis();
        this.calculateQuestionWiseLevelAttainment();
        this.calculateCoWiseLevelAttainment();
      }
    },
    async getTargetsOfAnInstitute() {
      this.targetsOfInstitute = {};
      const response = await this.nbaRepositoryInstance.getTargetsOfInstitute({
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.selectedSemester.semId,
      });
      this.targetsOfInstitute = response.data[0].targets;
    },
    async getCourseOutcome(subject) {
      try {
        this.courseOutcomeData = [];
        this.COFromNba = [];
        // this.assignedCoArray = []
        this.nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: subject.subjectId,
        });
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.courseOutcomeData.push({
              Id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName,
              coText : this.instituteType==='Tier-1'? `CLO ${nba.CONumber} - ${nba.COName}` : `CO ${nba.CONumber} - ${nba.COName}`
            });
            this.sortByNumericValueInAscendingOrder(this.courseOutcomeData, 'coNumber')
            this.coNames.push(nba.COName);
            this.coNumbers.push(nba.CONumber);
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    editQuestion(subject) {
      this.editQuestionDetails = false;
      this.questionsArray = [];
      if (subject.questions != undefined) {
        this.questionsArray = subject.questions;
      }
      this.uploadMarksManuallyStatus = false;
    },
    assignData(index, studentData) {
      this.questionDetails[index].studentData = studentData;
    },
    uploadMarksManually(subject) {
      this.uploadMarksManuallyStatus = true;
      this.marksUpload(subject);
    },
    async marksUpload(subject) {
      this.questionsArray = [];
      this.marksHeader = [];
      let header = [];
      header = [
        {
          text: "RN",
          value: "rollNo",
          align: "center",
        },
        {
          text: "Student Name",
          value: "studentName",
        },
        {
          text: "PRN",
          value: "studentPrn",
          align: "center",
        },
        {
          text: "Total",
          value: "total",
          align: "center",
        },
        {
          text: "Total Obtained Marks",
          value: "obtainedMarks",
          align: "center",
        },
        {
          text: "Grace Marks",
          value: "graceMarks",
        },
        {
          text: "Symbol for grace marks",
          value: "graceMarksSymbol",
        },
      ];
      if (subject.questions) {
        subject.questions.forEach((question, i) => {
          header.push({
            text: `Q ${i + 1}`,
            value: `Q ${i + 1}`,
            align: "center",
          });
          question["questionNumber"] = `Q ${i + 1}`;
          question["studentMarks"] = "";
        });
        this.subjectDetails.forEach((sub, index) => {
          if (sub.subjectId === subject.subjectId) {
            subject.marksHeader = header;
          }
        });
      }
    },
    async uploadQuestionManually(sub) {
      this.uploadQuestionManuallyStatus = true;
      if (this.nbaCriteria === true) {
        this.overallPercentageStatus = true;
        this.indivitualThresholdStatus = true;
      }
      this.questionsArray = sub.questions ? sub.questions : [];
      this.questionSet = this.questionsArray;
      this.overallThresholdPercentage = this.overallThresholdPercentage
        ? this.overallThresholdPercentage
        : 0;
      this.totalQuestions = sub.totalQuestions ? Number(sub.totalQuestions) : 0;
      // this.editQuestionDetails = true
    },
    addNewQuestion() {
      const tempQuestion = {
        number: this.questionSet ? this.questionSet.length + 1 : 1,
        marks: "",
        indivitualThreshold: "",
        question: "",
        COArray: [],
        coDialog: false,
        bloomArray: [],
        bloomDialog: false,
      };
      this.questionsArray = [...this.questionsArray, tempQuestion];
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },
    deleteQuestion(index) {
      const temp = [...this.questionSet];
      temp.splice(index, 1);
      this.questionsArray = temp;
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },

    async marksCalculation(subject) {
      let total;
      if (this.overallPercentageStatus === true) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            if (Number(que.marks) > Number(total)) {
              que.marks = "";
              showStatus(
                `Marks Should less Less Than ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
            } else {
              this.indivitualThresholdAvg =
                (que.marks / 100) * this.overallThresholdPercentage;
              que["indivitualThreshold"] = this.indivitualThresholdAvg;
            }
          });
        }
      }
      if (this.test) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            this.indivitualThresholdAvg = (que.marks / 100) * this.test;
            que["indivitualThreshold"] = this.indivitualThresholdAvg;
          });
        }
      }
    },
    async saveQuestions(subject) {
      
      if (this.toggleQuestionUpload === "uploadQuestions") {
        let add = 0;
        let tempQuestionArr = this.prepareQuestionsArray(this.allQuestions);
        this.questionSet = tempQuestionArr[0].allQuestions;
        // this.questionSet.map((que) => {
        //   add += Number(que.marks);
        // });
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            if (add <= this.tempTotalMarks) {
              subj.uploadQuestions = true;
              subj.subjectId = subject.subjectId;
              subj.subjectName = subject.subjectName;
              subj.overallThreshold = this.overallThresholdPercentage
                ? this.overallThresholdPercentage
                : this.test;
              subj.totalQuestions = this.totalQuestions;
              subj.questions = this.questionSet;
              subj.editQuestionDetails = true;
              this.editQuestionDetails = true;
            } else {
              this.editQuestionDetails = false;
              showStatus(
                `Total of Marks should be Less than  ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
            }
          }
        });
        this.uploadQuestionManuallyStatus = true;
      } else if (this.toggleQuestionUpload === "dontUploadQuestions") {
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            subj.uploadQuestions = false;
            subj.subjectId = subject.subjectId;
            subj.subjectName = subject.subjectName;
            subj.assignedCo = this.assignedCoArray;
            subj.assignedBlooms = this.assignedBloomsArray;
            subj.editQuestionDetails = true;
            subj.overallThreshold = this.noQuestionOverallThresholdPercentage;
            this.editQuestionDetails = true;
          }
        });
      }
      // if (this.questionDetails[this.questionDetails.length - 1].editQuestionDetails) {
      //   this.publishExam = true
      // } else {
      //   this.publishExam = false
      // }
      let result = this.questionDetails.every(this.checkQuestion); //refer checkQuestion function
      result ? (this.publishExam = true) : (this.publishExam = false);

      if (subject.uploadMarksType) {
        this.uploadMarksManually(subject);
      }
      this.handleOverallThreshold();
    },
    cancelQuestion(subject) {
      if (subject.questions === undefined) {
        this.questionsArray = [];
        this.questionSet = [];
        this.toggleCancelQuestionDialog = false;
      } else {
        this.questionsArray = subject.questions;
        this.questionSet = subject.questions;
        this.toggleCancelQuestionDialog = false;
      }
    },
    async saveMarks(selectedStud) {
      let pattern = /^(NA|X|ABS|SNG|F|W|P|NP)$/i;
      let tempCount = 0;
      if (this.toggleMarksUpload === "individual") {
        for (let index = 0; index < this.questionSet.length; index++) {
          const element = this.questionSet[index];
          if (!isNaN(element.studentMarks)) {
            if (Number(element.studentMarks) > Number(element.marks)) {
              tempCount++;
            }
          } else {
            if (!pattern.test(element.studentMarks)) {
              tempCount++;
            }
          }
          if (this.graceMarks > this.maxGraceMarks) {
            tempCount++;
          }
        }
      } else if (
        this.toggleMarksUpload === "aggregate" &&
        this.toggleQuestionUpload === "dontUploadQuestions"
      ) {
        if (!isNaN(this.obtainedMarks)) {
          if (this.obtainedMarks > this.maxMarks) {
            tempCount++;
          }
        } else {
          if (!pattern.test(this.obtainedMarks)) {
            tempCount++;
          }
        }
        if (this.graceMarks > this.maxGraceMarks) {
          tempCount++;
        }
      } else if (this.toggleMarksUpload === "aggregate") {
        if (!isNaN(this.obtainedMarks)) {
          if (this.obtainedMarks > this.maxMarks) {
            tempCount++;
          }
        } else {
          if (!pattern.test(this.obtainedMarks)) {
            tempCount++;
          }
        }
        if (this.graceMarks > this.maxGraceMarks) {
          tempCount++;
        }
      }

      if (tempCount === 0) {
        if (this.toggleMarksUpload === "individual") {
          let subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          let totalObtained = 0;
          this.uploadMarksDialog = false;
          this.questionSet.map((que, i) => {
            student.map((data) => {
              if (data.studentId === selectedStud.studentId) {
                data["uploadMarksType"] = "individual";
                data[`Q ${i + 1}`] =
                  data.studentMarks === que.studentMarks
                    ? data.studentMarks
                    : que.studentMarks;
                if (data[`Q ${i + 1}`] !== "") {
                  totalObtained += Number(data[`Q ${i + 1}`]);
                }
                data["obtainedMarks"] = totalObtained;
                data["graceMarks"] = this.graceMarks;
                data["graceMarksSymbol"] = this.graceMarksSymbol;
              }
              data.studentMarks = "";
            });
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "individual";
              }
            });
          });
          this.show = true;
        } else if (
          this.toggleMarksUpload === "aggregate" &&
          this.toggleQuestionUpload === "dontUploadQuestions"
        ) {
          let subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          student.map((data) => {
            if (data.studentId === selectedStud.studentId) {
              data["uploadMarksType"] = "aggregate";
              data["obtainedMarks"] = this.obtainedMarks;
              data["graceMarks"] = this.graceMarks;
              data["graceMarksSymbol"] = this.graceMarksSymbol;
            }
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        } else if (this.toggleMarksUpload === "aggregate") {
          let subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          let marks = 0;
          student.map((data) => {
            if (data.studentId === selectedStud.studentId) {
              this.questionSet.map((que, i) => {
                marks = this.obtainedMarks / this.questionSet.length;
                if (i + 1 !== this.questionSet.length) {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                } else {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                }
              });
              data["obtainedMarks"] = Number(this.obtainedMarks);
              data["graceMarks"] = this.graceMarks;
              data["graceMarksSymbol"] = this.graceMarksSymbol;
            }
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        }
      }
    },
    async editStudentMarks(item) {
      this.graceMarks = 0;
      this.graceMarksSymbol = "";
      this.obtainedMarks = 0;
      if (item) {
        this.obtainedMarks = item.obtainedMarks;
      }
      if (this.toggleMarksUpload === "individual") {
        this.questionSet.map((que, i) => {
          if (item[`Q ${i + 1}`]) {
            que["studentMarks"] = item[`Q ${i + 1}`];
          } else {
            que["studentMarks"] = "";
          }
        });
        this.uploadMarksDialog = true;
        this.selectedStud = item;
      } else if (this.toggleMarksUpload === "aggregate") {
        this.uploadOverallMarksDialog = true;
        this.selectedStud = item;
        if (this.selectedStud.obtainedMarks) {
          this.obtainedMarks = this.selectedStud.obtainedMarks;
        } else {
          this.obtainedMarks = 0;
        }
      }
      this.graceMarks = item.graceMarks ? item.graceMarks : 0;
      this.graceMarksSymbol = item.graceMarksSymbol
        ? item.graceMarksSymbol
        : "";
    },
    async cancelRoom() {
      this.tempSelectedRoom = {};
      this.addNewRoomDialog = false;
    },
    async handleOverallThreshold() {
      this.overallPercentageStatus = true;
    },
    async handleIndivitualThreshold() {
      this.indivitualThresholdStatus = true;
    },
    //analysis
    async examApi() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.currentExam.examId,
      };
      try {
        this.analysisV3 = await apiV3.getRequest(
          "/manageExam/examAnalysis",
          objToPush
        );
        if (!this.analysisV3.questionDetails) {
          this.analysisV3.questionDetails = [];
        }
        if (!this.analysisV3.bloomsData) {
          this.analysisV3.bloomsData = [];
          this.bloomsDataObject = {
            "Create (C)": 0,
            "Evaluate (E)": 0,
            "Analyse (A)": 0,
            "Apply (Ap)": 0,
            "Understand (U)": 0,
            "Remember (R)": 0,
          };
        }
      } catch (err) {
        console.error(err);
        this.analysisV3 = [];
        this.passingThreshold = 0;
      }
      this.subjectNames = [{ subjectName: "All", subjectId: "all" }];
      await this.analysisV3.questionDetails.map((data) => {
        data.examDetails.map((e, i) => {
          if (
            !this.subjectNames.find((item) => item.subjectId === e.subjectId)
          ) {
            this.subjectNames.push({
              subjectName: e.subjectName,
              subjectId: e.subjectId,
            });
          }
          if (
            !this.overallAttainmentSubjects.find(
              (item) => item.subjectId === e.subjectId
            )
          ) {
            this.overallAttainmentSubjects.push({
              subjectName: e.subjectName,
              subjectId: e.subjectId,
            });
          }
        });
      });
      this.selectedSubjectName = this.subjectNames[0].subjectId;
      this.selectedBloomSubjectName = this.subjectNames[0].subjectId;
      if (this.instituteType === "Tier-1") {
        this.selectedAttainmentSubject =
          this.overallAttainmentSubjects[0].subjectId;
        await this.getLevelsOfInstitute(this.selectedAttainmentSubject);
      }
      this.examAnalysisV3();
      this.handleBloomsAnalysis();
      this.recalculateDefaulters();
    },
    async examAnalysisV3() {
      this.averagePerformance = 0;
      this.analysisTableItems = [];
      this.failedCount = 0;
      this.grandTotalOfObtainedMarks = 0;
      this.attendanceDistribution = {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0,
      };
      this.tableHeaders = [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Total",
          value: "total",
        },
      ];
      this.grandTotal = 0;
      let pattern = /^(NA|X|ABS|SNG|F|W|P|NP)$/i;
      this.analysisV3.questionDetails.map((data) => {
        let sumOfTotalObtained = 0;
        let sumOfTotal = 0;
        let naCount = 0;
        let objToPush = {
          name: data.stduentName,
        };
        data.examDetails.map((e, i) => {
          if (!this.tableHeaders.find((item) => item.value === e.subjectId)) {
            this.tableHeaders.push({ text: e.subjectName, value: e.subjectId });
          }
          if (e.obtainedMarks >= 0 && e.obtainedMarks !== null) {
            sumOfTotal += Number(e.total);
            sumOfTotalObtained += Number(e.obtainedMarks) || 0;
            let percentage;
            let tempPer;
            tempPer = Math.round((e.obtainedMarks / e.total) * 100);
            if (isNaN(tempPer)) {
              percentage = 0;
            } else {
              percentage = tempPer;
            }
            if (this.instituteType === "Tier-1") {
              if (
                percentage >=
                this.attainmentLevelObject.exceedsExpectations.lowerLimit &&
                percentage <=
                this.attainmentLevelObject.exceedsExpectations.upperLimit
              ) {
                objToPush[e.subjectId] =
                  e.obtainedMarks + ` (${percentage} %) (EE)` || 0;
              } else if (
                percentage >=
                this.attainmentLevelObject.meetsExpectations.lowerLimit &&
                percentage <=
                this.attainmentLevelObject.meetsExpectations.upperLimit
              ) {
                objToPush[e.subjectId] =
                  e.obtainedMarks + ` (${percentage} %) (ME)` || 0;
              } else if (
                percentage >=
                this.attainmentLevelObject.belowExpectations.lowerLimit &&
                percentage <=
                this.attainmentLevelObject.belowExpectations.upperLimit
              ) {
                objToPush[e.subjectId] =
                  e.obtainedMarks + ` (${percentage} %) (BE)` || 0;
              }
            } else {
              objToPush[e.subjectId] =
                e.obtainedMarks + ` (${percentage} %)` || 0;
            }
          } else if (!pattern.test(this.obtainedMarks)) {
            objToPush[e.subjectId] = e.obtainedMarks;
            naCount++;
          }
        });
        this.grandTotal += sumOfTotal;
        this.grandTotalOfObtainedMarks += sumOfTotalObtained;

        if (sumOfTotal > 0) {
          let percentage;
          let tempPer;
          tempPer = Math.round((sumOfTotalObtained / sumOfTotal) * 100);
          if (isNaN(tempPer)) {
            percentage = 0;
          } else {
            percentage = tempPer;
          }
          if (this.instituteType === "Tier-1") {
            if (
              percentage >=
              this.attainmentLevelObject.exceedsExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.exceedsExpectations.upperLimit
            ) {
              objToPush["total"] = percentage + " % (EE)";
            } else if (
              percentage >=
              this.attainmentLevelObject.meetsExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.meetsExpectations.upperLimit
            ) {
              objToPush["total"] = percentage + " % (ME)";
            } else if (
              percentage >=
              this.attainmentLevelObject.belowExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.belowExpectations.upperLimit
            ) {
              objToPush["total"] = percentage + " % (BE)";
            }
          } else {
            objToPush["total"] = percentage + " %";
          }
        }
        if (naCount === this.tableHeaders.length - 2) {
          objToPush["total"] = "-";
        }
        this.analysisTableItems.push(objToPush);

        let percentage = 0;
        percentage = Math.round((sumOfTotalObtained / sumOfTotal) * 100);

        if (percentage > 0 && percentage <= 10) {
          this.attendanceDistribution["0 % - 10 %"]++;
        }
        if (percentage > 10 && percentage <= 20) {
          this.attendanceDistribution["10 % - 20 %"]++;
        }
        if (percentage > 20 && percentage <= 30) {
          this.attendanceDistribution["20 % - 30 %"]++;
        }
        if (percentage > 30 && percentage <= 40) {
          this.attendanceDistribution["30 % - 40 %"]++;
        }
        if (percentage > 40 && percentage <= 50) {
          this.attendanceDistribution["40 % - 50 %"]++;
        }
        if (percentage > 50 && percentage <= 60) {
          this.attendanceDistribution["50 % - 60 %"]++;
        }
        if (percentage > 60 && percentage <= 70) {
          this.attendanceDistribution["60 % - 70 %"]++;
        }
        if (percentage > 70 && percentage <= 80) {
          this.attendanceDistribution["70 % - 80 %"]++;
        }
        if (percentage > 80 && percentage <= 90) {
          this.attendanceDistribution["80 % - 90 %"]++;
        }
        if (percentage > 90 && percentage <= 100) {
          this.attendanceDistribution["90 % - 100 %"]++;
        }
        if (percentage < this.failingLimit) {
          this.failedCount++;
        }
      });
      let tempAvg = (this.grandTotalOfObtainedMarks / this.grandTotal) * 100;
      if (isNaN(tempAvg)) {
        this.averagePerformance = 0;
      } else {
        this.averagePerformance = tempAvg.toFixed(2);
      }
      // this.averagePerformance = (
      //   (this.grandTotalOfObtainedMarks / this.grandTotal) *
      //   100
      // ).toFixed(2);

      let valueArray = [];
      for (const [key, value] of Object.entries(this.attendanceDistribution)) {
        valueArray.push(Number(`${value}`));
      }
      this.donutOptions = {
        ...this.donutOptions,
        series: valueArray,
      };

      this.analysisTableItemsCopy = this.analysisTableItems;
      this.tableHeadersCopy = this.tableHeaders;
      this.dataLoaded = true;
    },
    handleSubjectWiseAnalysis() {
      this.dataLoaded = false;
      this.attendanceDistribution = {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0,
      };
      if (this.selectedSubjectName === "all") {
        this.dataLoaded = false;
        this.examAnalysisV3();
      }
      let percentage = 0;
      this.analysisV3.questionDetails.map((data) => {
        data.examDetails.map((exam) => {
          if (this.selectedSubjectName === exam.subjectId) {
            percentage = Math.round((exam.obtainedMarks / exam.total) * 100);
            if (percentage > 0 && percentage <= 10) {
              this.attendanceDistribution["0 % - 10 %"]++;
            }
            if (percentage > 10 && percentage <= 20) {
              this.attendanceDistribution["10 % - 20 %"]++;
            }
            if (percentage > 20 && percentage <= 30) {
              this.attendanceDistribution["20 % - 30 %"]++;
            }
            if (percentage > 30 && percentage <= 40) {
              this.attendanceDistribution["30 % - 40 %"]++;
            }
            if (percentage > 40 && percentage <= 50) {
              this.attendanceDistribution["40 % - 50 %"]++;
            }
            if (percentage > 50 && percentage <= 60) {
              this.attendanceDistribution["50 % - 60 %"]++;
            }
            if (percentage > 60 && percentage <= 70) {
              this.attendanceDistribution["60 % - 70 %"]++;
            }
            if (percentage > 70 && percentage <= 80) {
              this.attendanceDistribution["70 % - 80 %"]++;
            }
            if (percentage > 80 && percentage <= 90) {
              this.attendanceDistribution["80 % - 90 %"]++;
            }
            if (percentage > 90 && percentage <= 100) {
              this.attendanceDistribution["90 % - 100 %"]++;
            }
          }
        });
      });
      let subjectValueArray = [];
      for (const [key, value] of Object.entries(this.attendanceDistribution)) {
        subjectValueArray.push(Number(`${value}`));
      }
      this.donutOptions = {
        ...this.donutOptions,
        series: subjectValueArray,
      };
      this.dataLoaded = true;
    },

    editPassingThreshold() {
      this.isPassingThresholdEditable = !this.isPassingThresholdEditable;
    },
    showFailedStudents() {
      this.studentFilter = "failed";
      this.rangeVal = [0, Number(this.passingThreshold) - 1];
      this.applyLimit();
    },
    applyLimit() {
      this.analysisTableItems = [];
      for (let i = 0; i < this.analysisTableItemsCopy.length; i++) {
        const total = Number(
          this.analysisTableItemsCopy[i].total
            .split("%")
            .join("")
            .split(" ")
            .join("")
        );
        if (total < Number(this.passingThreshold)) {
          this.analysisTableItems.push(
            Object.assign(this.analysisTableItemsCopy[i])
          );
        }
      }
    },
    showAllStudents() {
      this.studentFilter = "all";
      this.analysisTableItems = [];
      this.analysisTableItems = [...this.analysisTableItemsCopy];
    },
    recalculateDefaulters() {
      this.failedCount = 0;
      for (let i = 0; i < this.analysisTableItemsCopy.length; i++) {
        if (this.analysisTableItemsCopy[i].total) {
          const total = Number(
            this.analysisTableItemsCopy[i].total
              .split("%")
              .join("")
              .split(" ")
              .join("")
          );
          if (total < Number(this.passingThreshold)) {
            this.failedCount++;
          }
        }
      }
    },
    handleBloomsAnalysis() {
      this.bloomsDataObject = {
        "Create (C)": 0,
        "Evaluate (E)": 0,
        "Analyse (A)": 0,
        "Apply (Ap)": 0,
        "Understand (U)": 0,
        "Remember (R)": 0,
      };
      let bloomsData = {};
      this.totalBloomCount = 0;
      this.analysisV3.bloomsData.map((data) => {
        if (data.uploadQuestions) {
          data.questions.map((que) => {
            if (que.bloomArray) {
              que.bloomArray.map((bl) => {
                this.totalBloomCount++;
                if (bl.shortCode === "A") {
                  this.bloomsDataObject["Analyse (A)"]++;
                }
                if (bl.shortCode === "E") {
                  this.bloomsDataObject["Evaluate (E)"]++;
                }
                if (bl.shortCode === "C") {
                  this.bloomsDataObject["Create (C)"]++;
                }
                if (bl.shortCode === "Ap") {
                  this.bloomsDataObject["Apply (Ap)"]++;
                }
                if (bl.shortCode === "U") {
                  this.bloomsDataObject["Understand (U)"]++;
                }
                if (bl.shortCode === "R") {
                  this.bloomsDataObject["Remember (R)"]++;
                }
              });
            } else {
              this.bloomsDataObject = {
                "Create (C)": 0,
                "Evaluate (E)": 0,
                "Analyse (A)": 0,
                "Apply (Ap)": 0,
                "Understand (U)": 0,
                "Remember (R)": 0,
              };
            }
          });
        } else if (!data.uploadQuestions) {
          if (data.blooms) {
            if (data.blooms.length > 0) {
              data.blooms.map((bl) => {
                this.totalBloomCount++;
                if (bl === "A") {
                  this.bloomsDataObject["Analyse (A)"]++;
                }
                if (bl === "E") {
                  this.bloomsDataObject["Evaluate (E)"]++;
                }
                if (bl === "C") {
                  this.bloomsDataObject["Create (C)"]++;
                }
                if (bl === "Ap") {
                  this.bloomsDataObject["Apply (Ap)"]++;
                }
                if (bl === "U") {
                  this.bloomsDataObject["Understand (U)"]++;
                }
                if (bl === "R") {
                  this.bloomsDataObject["Remember (R)"]++;
                }
              });
            } else {
              this.bloomsDataObject = {
                "Create (C)": 0,
                "Evaluate (E)": 0,
                "Analyse (A)": 0,
                "Apply (Ap)": 0,
                "Understand (U)": 0,
                "Remember (R)": 0,
              };
            }
          }
        }
      });
      if (this.totalBloomCount > 0) {
        for (const [key, value] of Object.entries(this.bloomsDataObject)) {
          bloomsData[key] = Math.round((value / this.totalBloomCount) * 100);
        }
      }
      this.bloomsDonutOptions = {
        ...this.bloomsDonutOptions,
        series: Object.values(bloomsData),
      };
    },
    handleSubjectWiseBloomsAnalysis() {
      this.totalBloomCount = 0;
      this.bloomsDataObject = {
        "Create (C)": 0,
        "Evaluate (E)": 0,
        "Analyse (A)": 0,
        "Apply (Ap)": 0,
        "Understand (U)": 0,
        "Remember (R)": 0,
      };
      if (this.selectedBloomSubjectName === "all") {
        this.handleBloomsAnalysis();
        this.examAnalysisV3();
      } else {
        this.analysisV3.bloomsData.map((subject) => {
          if (subject.subjectId === this.selectedBloomSubjectName) {
            if (subject.uploadQuestions) {
              subject.questions.map((que) => {
                if (que.subjectId === this.selectedBloomSubjectName) {
                  // if (que.bloomArray) {
                  que.bloomArray.map((bl) => {
                    this.totalBloomCount++;
                    if (bl.shortCode === "A") {
                      this.bloomsDataObject["Analyse (A)"]++;
                    }
                    if (bl.shortCode === "E") {
                      this.bloomsDataObject["Evaluate (E)"]++;
                    }
                    if (bl.shortCode === "C") {
                      this.bloomsDataObject["Create (C)"]++;
                    }
                    if (bl.shortCode === "Ap") {
                      this.bloomsDataObject["Apply (Ap)"]++;
                    }
                    if (bl.shortCode === "U") {
                      this.bloomsDataObject["Understand (U)"]++;
                    }
                    if (bl.shortCode === "R") {
                      this.bloomsDataObject["Remember (R)"]++;
                    }
                  });
                } else {
                }
              });
            } else {
              if (subject.blooms) {
                subject.blooms.map((bl) => {
                  this.totalBloomCount++;
                  if (bl === "A") {
                    this.bloomsDataObject["Analyse (A)"]++;
                  }
                  if (bl === "E") {
                    this.bloomsDataObject["Evaluate (E)"]++;
                  }
                  if (bl === "C") {
                    this.bloomsDataObject["Create (C)"]++;
                  }
                  if (bl === "Ap") {
                    this.bloomsDataObject["Apply (Ap)"]++;
                  }
                  if (bl === "U") {
                    this.bloomsDataObject["Understand (U)"]++;
                  }
                  if (bl === "R") {
                    this.bloomsDataObject["Remember (R)"]++;
                  }
                });
              } else {
                this.bloomsDataObject = {
                  "Create (C)": 0,
                  "Evaluate (E)": 0,
                  "Analyse (A)": 0,
                  "Apply (Ap)": 0,
                  "Understand (U)": 0,
                  "Remember (R)": 0,
                };
              }
            }
          }
        });
        this.handleSubjectWiseAnalysis();
      }
      for (const [key, value] of Object.entries(this.bloomsDataObject)) {
        this.bloomsDataObject[key] = Math.round(
          (value / this.totalBloomCount) * 100
        );
      }

      this.bloomsDonutOptions = {
        ...this.bloomsDonutOptions,
        series: Object.values(this.bloomsDataObject),
      };
    },
    calculateSubjectWiseOverallLevelAttainmentAnalysis() {
      let percentage = 0;
      this.analysisV3.questionDetails.map((data) => {
        data.examDetails.map((exam) => {
          if (this.selectedAttainmentSubject === exam.subjectId) {
            percentage = Math.round((exam.obtainedMarks / exam.total) * 100);
            if (
              percentage >=
              this.attainmentLevelObject.exceedsExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.exceedsExpectations.upperLimit
            ) {
              this.eeStudents++;
            } else if (
              percentage >=
              this.attainmentLevelObject.meetsExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.meetsExpectations.upperLimit
            ) {
              this.meStudents++;
            } else if (
              percentage >=
              this.attainmentLevelObject.belowExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.belowExpectations.upperLimit
            ) {
              this.beStudents++;
            }
          }
        });
        this.eeStudentPercentage =
          Math.round(
            (this.eeStudents / this.analysisV3.questionDetails.length) *
            100 *
            100
          ) / 100;
        this.meStudentPercentage =
          Math.round(
            (this.meStudents / this.analysisV3.questionDetails.length) *
            100 *
            100
          ) / 100;
        this.beStudentPercentage =
          Math.round(
            (this.beStudents / this.analysisV3.questionDetails.length) *
            100 *
            100
          ) / 100;
      });
    },
    calculateQuestionWiseLevelAttainment() {
      this.levelAttainmentQuestionArray = [];
      this.questionDetails.map((subject) => {
        if (subject.subjectId === this.selectedAttainmentSubject) {
          if (subject.uploadQuestions) {
            this.levelAttainmentQuestionArray = subject.questions;
          }
        }
      });
      if (this.levelAttainmentQuestionArray.length > 0) {
        this.levelAttainmentQuestionArray.map((question, qi) => {
          question["attainmentObject"] = {
            beStudents: 0,
            meStudents: 0,
            eeStudents: 0,
          };
          this.analysisV3.questionDetails.map((data) => {
            if (data.subjectId === this.selectedAttainmentSubject) {
              data.examDetails.map((que, j) => {
                let percentage = 0;
                for (const [key, value] of Object.entries(que)) {
                  if (key.split(" ")[0] === "Q") {
                    if (key === `Q ${qi + 1}`) {
                      percentage = Math.round(
                        (Number(value) /
                          Number(this.levelAttainmentQuestionArray[qi].marks)) *
                        100
                      );
                      if (
                        percentage >=
                        this.attainmentLevelObject.exceedsExpectations
                          .lowerLimit &&
                        percentage <=
                        this.attainmentLevelObject.exceedsExpectations
                          .upperLimit
                      ) {
                        question.attainmentObject.eeStudents++;
                      } else if (
                        percentage >=
                        this.attainmentLevelObject.meetsExpectations
                          .lowerLimit &&
                        percentage <=
                        this.attainmentLevelObject.meetsExpectations
                          .upperLimit
                      ) {
                        question.attainmentObject.meStudents++;
                      } else if (
                        percentage >=
                        this.attainmentLevelObject.belowExpectations
                          .lowerLimit &&
                        percentage <=
                        this.attainmentLevelObject.belowExpectations
                          .upperLimit
                      ) {
                        question.attainmentObject.beStudents++;
                      }
                    }
                  }
                }
              });
            }
          });

          let sum = 0;
          for (const [key, value] of Object.entries(
            question.attainmentObject
          )) {
            sum += value;

            question.attainmentObject["beStudentPercentage"] =
              (question.attainmentObject.beStudents / sum) * 100;
            question.attainmentObject["meStudentPercentage"] =
              (question.attainmentObject.meStudents / sum) * 100;
            question.attainmentObject["eeStudentPercentage"] =
              (question.attainmentObject.eeStudents / sum) * 100;
          }
        });
      }
    },
    calculateCoWiseLevelAttainment() {
      this.overallAttainmentLoader = false;
      this.coWiseAttainmentLoader = true;
      this.questionDetails.forEach(async (subject) => {
        if (subject.subjectId === this.selectedAttainmentSubject) {
          if (subject.uploadQuestions) {
            this.coWiseAttainment = [];
            await subject.questions.forEach(async (question) => {
              await question.COArray.forEach((co) => {
                if (
                  !this.coWiseAttainment.find(
                    (item) => item.coNumber === co.coNumber
                  )
                ) {
                  this.coWiseAttainment.push({ coNumber: co.coNumber });
                }
              });
            });
            this.coWiseAttainment.forEach(async (co) => {
              co["attainmentObject"] = {
                beStudents: 0,
                meStudents: 0,
                eeStudents: 0,
              };

              subject.studentData.forEach((stud) => {
                let sum = 0;
                let queSum = 0;
                subject.questions.forEach((que) => {
                  for (const [key] of Object.entries(stud)) {
                    if (key.split(" ")[0] === "Q") {
                      if (que.questionNumber === key) {
                        que.COArray.forEach((queCo) => {
                          if (queCo.coNumber === co.coNumber) {
                            queSum += Number(que.marks);
                            sum += Number(stud[que.questionNumber]);
                          }
                        });
                      }
                    }
                  }
                });
                let percentage = (sum / queSum) * 100;
                if (
                  percentage >=
                  this.attainmentLevelObject.exceedsExpectations.lowerLimit &&
                  percentage <=
                  this.attainmentLevelObject.exceedsExpectations.upperLimit
                ) {
                  co.attainmentObject.eeStudents++;
                } else if (
                  percentage >=
                  this.attainmentLevelObject.meetsExpectations.lowerLimit &&
                  percentage <=
                  this.attainmentLevelObject.meetsExpectations.upperLimit
                ) {
                  co.attainmentObject.meStudents++;
                } else if (
                  percentage >=
                  this.attainmentLevelObject.belowExpectations.lowerLimit &&
                  percentage <=
                  this.attainmentLevelObject.belowExpectations.upperLimit
                ) {
                  co.attainmentObject.beStudents++;
                }
              });
              let studentSum = 0;
              for (const [, value] of Object.entries(co.attainmentObject)) {
                studentSum += value;

                co.attainmentObject["beStudentPercentage"] =
                  Math.round(
                    (co.attainmentObject.beStudents / studentSum) * 100 * 100
                  ) / 100;
                co.attainmentObject["meStudentPercentage"] =
                  Math.round(
                    (co.attainmentObject.meStudents / studentSum) * 100 * 100
                  ) / 100;
                co.attainmentObject["eeStudentPercentage"] =
                  Math.round(
                    (co.attainmentObject.eeStudents / studentSum) * 100 * 100
                  ) / 100;
              }
            });

            this.overallAttainmentLoader = true;
            this.coWiseAttainmentLoader = false;
          } else {
            this.coWiseAttainment = [];
            subject.assignedCo.map((co) => {
              this.coWiseAttainment.push({ coNumber: co });
            });
            this.coWiseAttainment.forEach(async (co) => {
              co["attainmentObject"] = {
                beStudents: 0,
                meStudents: 0,
                eeStudents: 0,
              };
              subject.studentData.forEach((stud) => {
                let percentage =
                  (stud.obtainedMarks / Number(stud.total)) * 100;
                if (
                  percentage >=
                  this.attainmentLevelObject.exceedsExpectations.lowerLimit &&
                  percentage <=
                  this.attainmentLevelObject.exceedsExpectations.upperLimit
                ) {
                  co.attainmentObject.eeStudents++;
                } else if (
                  percentage >=
                  this.attainmentLevelObject.meetsExpectations.lowerLimit &&
                  percentage <=
                  this.attainmentLevelObject.meetsExpectations.upperLimit
                ) {
                  co.attainmentObject.meStudents++;
                } else if (
                  percentage >=
                  this.attainmentLevelObject.belowExpectations.lowerLimit &&
                  percentage <=
                  this.attainmentLevelObject.belowExpectations.upperLimit
                ) {
                  co.attainmentObject.beStudents++;
                }
              });
              let studentSum = 0;
              for (const [, value] of Object.entries(co.attainmentObject)) {
                studentSum += value;

                co.attainmentObject["beStudentPercentage"] =
                  Math.round(
                    (co.attainmentObject.beStudents / studentSum) * 100 * 100
                  ) / 100;
                co.attainmentObject["meStudentPercentage"] =
                  Math.round(
                    (co.attainmentObject.meStudents / studentSum) * 100 * 100
                  ) / 100;
                co.attainmentObject["eeStudentPercentage"] =
                  Math.round(
                    (co.attainmentObject.eeStudents / studentSum) * 100 * 100
                  ) / 100;
              }
            });
            this.coWiseAttainmentLoader = false;
            this.overallAttainmentLoader = true;
          }
        }
      });
    },
    showAnalysis() {
      this.isAnalysisVisible = true;
      this.examApi();
    },
    hideAnalysis() {
      this.isAnalysisVisible = false;
    },
    // Handle excels
    async uploadQuestionExcel() {
      let subject = this.subjectToUploadQuestions;
      let filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === subject.subjectId
      );
      if (this.questionExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.questionExcelFile);
        formData.append(
          "subjectTotalMarks",
          Number(filteredSubject[0].totalMarks)
        );
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/exam/parse",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            response.data.questionsArray.map((d) => {
              this.totalQuestions++;
              this.questionsArray.push({
                COArray: [],
                coDialog: false,
                bloomArray: [],
                bloomDialog: false,
                number: this.questionsArray.length + 1,
                marks: d.totalMarks,
                question: d.question,
                indivitualThreshold: this.overallThresholdPercentage
                  ? (d.totalMarks / 100) * this.overallThresholdPercentage
                  : "",
              });
            });
            this.questionSet = this.questionsArray;
            this.uploadQuestionExcelDialog = false;
            this.excelCustomError = false;
          })
          .catch((error) => {
            if (
              error.response.data.customErrorMessage === "marksSumExceedError"
            ) {
              this.uploadQuestionExcelDialog = false;
              this.excelCustomError = true;
              this.excelCustomErrorMessage = `Cannot upload excel. Marks sum exceeding ${filteredSubject[0].totalMarks}.`;
            }
          });
      }

      this.questionExcelFile = "";
      this.questionsLoadedViaExcel = true;
    },
    handleUploadQuestionExcelDialog(subject) {
      this.uploadQuestionExcelDialog = true;
      this.subjectToUploadQuestions = subject;
    },
    async uploadMarksExcel() {
      let subject = this.subjectToUploadMarks;
      this.studentExceedingMarksLimit = [];
      let filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === subject.subjectId
      );
      this.marksLoadedViaExcel = true;
      if (this.marksExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.marksExcelFile);
        formData.append("marksUploadType", this.toggleMarksUpload);
        formData.append("questionsUploadType", this.toggleQuestionUpload);
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/exam/parseMarks",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (response) => {
            await this.uploadMarksManually(subject);

            if (this.toggleMarksUpload === "aggregate") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];
                if (
                  element.obtainedMarks > Number(filteredSubject[0].totalMarks)
                ) {
                  this.studentExceedingMarksLimit.push(element.studentName);
                }
              }
            } else if (this.toggleMarksUpload === "individual") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];

                for (const [key, value] of Object.entries(element)) {
                  if (key.split(" ")[0] === "Q") {
                    let filteredQuestion = this.questionSet.filter(
                      (item) => item.questionNumber === key
                    )[0];

                    if (value > filteredQuestion.marks) {
                      if (
                        this.studentExceedingMarksLimit.indexOf(
                          element.studentName
                        ) === -1
                      ) {
                        this.studentExceedingMarksLimit.push(
                          element.studentName
                        );
                      }
                    }
                  }
                }
              }
            }
            this.uploadMarksExcelDialog = false;
            if (this.studentExceedingMarksLimit.length === 0) {
              this.saveMarksViaExcel(response.data.studentData, subject);
            } else {
              this.excelCustomError = true;
              this.excelCustomErrorMessage =
                "Cannot upload excel. Please recheck the marks of following students.";
            }
          });
      }
      this.marksExcelFile = "";
      this.marksLoadedViaExcel = false;
      // this.uploadMarksManuallyStatus = true
    },
    saveMarksViaExcel(studentData, sub) {
      studentData.map((st) => {
        if (st.studentPrn) {
          st.studentPrn = st.studentPrn + "";
        }
      });
      sub.studentData.map((stud, i) => {
        studentData.map((data, j) => {
          let marks;
          let objToPush = {};
          if (stud.studentPrn === data.studentPrn) {
            if (
              this.toggleQuestionUpload === "dontUploadQuestions" &&
              this.toggleMarksUpload === "aggregate"
            ) {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "aggregate" });
              Object.assign(sub, { uploadMarksType: "aggregate" });
            } else if (this.toggleMarksUpload === "individual") {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "individual" });
              Object.assign(sub, { uploadMarksType: "individual" });
            } else if (this.toggleMarksUpload === "aggregate") {
              this.questionSet.map((ques, i) => {
                marks = data.obtainedMarks / this.questionSet.length;
                if (i + 1 !== this.questionSet.length) {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                } else {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                }
              });
              Object.assign(stud, data);
              Object.assign(sub, { uploadMarksType: "aggregate" });
            }
            // objToPush = {
            //   studentPrn: data.studentPrn,
            //   attemptNumber:
            // }
          }
        });
      });
    },
    toggleUploadMarksViaExcel(subject) {
      this.subjectToUploadMarks = subject;
      this.uploadMarksExcelDialog = true;

      if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "individual"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375313604________questionwise_individual_marks_sample.xlsx?alt=media&token=551218f8-7512-4b55-a4a1-32121f8748e8";
      } else if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375235224________questionwise_aggregate_marks_sample.xlsx?alt=media&token=57bb5196-ca79-40fb-9b2a-7d286be2ed7a";
      } else if (
        this.toggleQuestionUpload === "dontUploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669374075360________noquestions_overall_marks_sample.xlsx?alt=media&token=df16ebbc-5480-4375-a143-4eb893cfae6e";
      }
    },
    validateQuestionMarks(question) {
      this.maxQuestionMarks = 0;
      this.maxQuestionMarks = question.marks;
    },
    async generateMarksSampleExcel() {
      let subject = this.subjectToUploadMarks;
      subject["toggleMarksUpload"] = this.toggleMarksUpload;
      subject["toggleQuestionUpload"] = this.toggleQuestionUpload;
      try {
        const pdfBlob =
          await this.examStudentRepositoryInstance.generateStudentMarksExcel(
            subject
          );
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Marks Sample.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error("error", err);
      }
    },
  },
};
</script>
<style>
.custom-table th,
.custom-table td {
  border: 1px solid #dddddd !important;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2 !important;
}

.custom-table tr {
  border-bottom: 1px solid #dddddd !important;
}

.custom-table td {
  border-right: 1px solid #dddddd !important;
}
.quickTestCard {
  display: flex;
}
.navTab {
  margin: 0 10px;
  border-radius: 5px;
  color: #050991 !important;
  border: 1px solid #050991;
  width: 220px;
  padding: 10px;
  text-transform: capitalize;
}
.navTabActive {
  background-color: #050991;
  border-bottom: none;
  color: white !important;
}
.navTab1 {
  color: #050991 !important;
  border: 1px solid #050991;
  font-weight: 400;
  font-size: small;
  width: 200px;
  height: 42px;
  padding: 2px;
  text-transform: capitalize;
}
.navTab2 {
  margin: 0 4px;
  border-radius: 5px;
  color: #050991 !important;
  border: 1px solid #050991;
  width: 97px;
  height: 45px;
  padding: 4px;
  text-transform: capitalize;
}
.navTabActive2 {
  background-color: #050991;
  border-bottom: none;
  color: white !important;
}
.navTabActive1 {
  background-color: #050991;
  border-bottom: none;
  color: white !important;
}
.borderRadiusLeft {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.borderRadiusRight {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.rounded {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom-checkbox-label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
}
.center-container {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-line {
  border-top: 0.5px dotted #aaaaaa;
  margin: 0px;
  padding: 0;
  height: 1px;
  width: 100%;
}
.clickable-text {
  color: #2196f3;
  cursor: pointer;
  text-decoration: underline;
  color: #707070;
}
.icon-with-border {
  border: 1px solid #000;
  padding: 4px;
  border-radius: 4px;
}
.centered-tabs {
  display: flex;
  justify-content: center;
}
.selected-background {
  background: #d9d9d9;
}

.clickable-div {
  cursor: pointer;
}
.selected-background {
  background: #d9d9d9;
  transition: background 0.3s ease; 
}

.clickable-div {
  cursor: pointer !important;
  border-radius: 3px;
  padding: 3px;
}

.clickable-div:hover {
  background: #d9d9d9;
}
.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid #dddddd;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tr {
  border-bottom: 1px solid #dddddd;
}

.custom-table td {
  border-right: 1px solid #dddddd;
}
.download-sample-excel {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  color: #FF4F1F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.custom-radio {
  color: rgba(5, 13, 146, 1);
}
.dialog-content {
  padding-bottom: 0;
}

.sectionBackgrond {
  background-color: #9205050D;
}

.paddingWithBorder {
  padding: 5px;
  border-radius: 5px;
  border: 0.1px solid #FF4F1F;
  color: #FF4F1F;
  margin-left: 8px;
  cursor: pointer;
}
</style>
<style scoped src="./activityEditExam.css"></style>

