import Vue from "vue";
import App from "./App.vue";
import { router } from "./Router/"; // import router object
import { store } from "./Store/"; // import store (vuex) object
import Vuetify from "vuetify/lib";
import auth from "./Services/Firebase/auth";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import VueMathjax from "vue-mathjax";
import VueApexCharts from "vue-apexcharts";
import OtpInput from "@bachdgvn/vue-otp-input";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DateTimePicker from 'vuetify-datetime-picker'
import VueSimpleAlert from "vue-simple-alert";
import { BootstrapVue } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./main.css"

Vue.use(VueSimpleAlert);
Vue.use(DateTimePicker)
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueMathjax);
Vue.use(Chartkick.use(Chart));
Vue.use(Vuetify);
Vue.use(CKEditor);
Vue.component("v-otp-input", OtpInput);
Vue.use(VueSimpleAlert);
Vue.use(BootstrapVue)

const vuetify = new Vuetify({});

Vue.config.productionTip = false;

Vue.use(Vuetify)


new Vue({
  // use router object
  router,

  // use store (vuex) object
  store,

  vuetify,

  beforeCreate() {
    auth.init(this);
  },

  render: (h) => h(App),

}).$mount("#app");

// if (process.env.NODE_ENV === "production" ) {
//   require("./disabledLogs");
// }





