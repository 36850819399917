import axios from "axios";
import user from "../Store/Modules/user";
import liveData from "../Store/Modules/liveData";
import instituteData from "../Store/Modules/instituteData"

const token = JSON.parse(window.localStorage.getItem("token"))

export const serverUrl = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT;
export const socketUrl = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_SOCKET_PORT;
export const spacesUrl = `https://${process.env.VUE_APP_SPACES_NAME}.${process.env.VUE_APP_SPACES_URL}`;

const getHeaders = () => {
  const token = JSON.parse(window.localStorage.getItem("token")) || { token: "" }
  return {
    userType: "admin-LMS",
    userId: user?.state?.userData?.uId || null,
    instituteId: instituteData?.state?.selectedInstitute || null,
    instituteName: liveData?.state?.brandingName || null,
    authorization: `JWT ${token?.token}`
  };
};

export default {
  serverUrl: serverUrl,

  getRequest: async (apiName, data) => {
    const res = await axios.get(serverUrl + apiName, {
      params: data,
      headers: { ...getHeaders(), authorization: `JWT ${token?.token}` },
    });
    return res.data;
  },

  postRequest: async (apiName, data) => {
    const res = await axios.post(serverUrl + apiName, data, {
      headers: getHeaders(),
    });
    // window.console.log(res.data)
    return res.data;
  },

  putRequest: async (apiName, data) => {
    const res = await axios.put(serverUrl + apiName, data, {
      headers: getHeaders(),
    });
    // window.console.log(res.data)
    return res.data;
  },

  deleteRequest: async (apiName, data) => {
    const res = await axios.delete(serverUrl + apiName, {
      params: data,
      headers: getHeaders(),
    });
    // window.console.log(res.data)
    return res;
  },

  downloadFile: async (apiName, data) => {
    const res = await axios.post(serverUrl + apiName, data, {
      responseType: "arraybuffer",
      headers: getHeaders(),
    });
    return res;
  },

  setHeader: function (data) {
    axios.defaults.headers = data;
  },
};

// function getHeaders() {
//   //add custom headers here
//   return {
//     uId: ""
//   }
// }
