<template src="./activityFeeReceipt.html"></template>

<script>
import html2pdf from 'html2pdf.js'
// import html2pdf from 'html2pdf.js'

import Vue from "vue";
import "vuetify";
import AdmissionUserRepository from "../../Repository/AdmissionUser";
import InstituteRepository from "../../Repository/Institute";

export default {
  name: "activityFeeReceipt",
  props: ["prop_user", "selected_admission"],
  data() {
    return {
      userData: {},
      admissionDetails: {},
      subjectTotalAmount: 0,
      admissionPayment: "",
      instituteInfo: "",
      instituteStudentBrandingName: "",
      selectedInstitute: "",
      selectedSemester: "",
      isPracEduskill: false,
      basicFeesSum: 0,
      totalFeesSum: 0,
    };
  },
  async created() {

    this.instituteRepositoryInstance = new InstituteRepository(this);
    this.admissionUserRepositoryInstance = new AdmissionUserRepository(this);

    this.userData = this.prop_user;
    this.admissionDetails = this.selected_admission

    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];

    if (this.selectedInstitute === '2c5265b2-53fd-4012-b336-52107ffa4a6d') {
      this.isPracEduskill = true
    }

    this.instituteInfo =
      await this.instituteRepositoryInstance.getInstituteInformation({
        uId: this.userData.uId,
        instituteId: this.selectedInstitute,
      });

    console.log('this.instituteInfo', this.instituteInfo);

    this.instituteStudentBrandingName =
      this.$store.getters["liveData/get_instituteStudentBrandingName"];

    this.instituteStudentLogoLink =
      this.$store.getters["liveData/get_instituteStudentLogoLink"];

    this.selectedSemester =
      this.$store.getters["liveData/get_selectedSemester"];

    this.prop_user.batches.map((payment) => {
      this.subjectTotalAmount += parseInt(payment.totalFees);
    });

    const res =
      await this.admissionUserRepositoryInstance.getAdmissionDataForEdit({
        instituteId: this.prop_user.instituteId,
        semId: this.selected_admission.semId,
        admissionId: this.prop_user.admissionId,
      });
    this.admissionPayment = res.admissionPayment[0];
    this.admissionPayment.installmentDiscount;

    this.prop_user.batches.map((sub)=>{
      console.log('subjects', sub.basicFees);
      this.totalFeesSum += parseInt(sub.totalFees)
      this.basicFeesSum += parseInt(sub.basicFees)
    })
  },
  methods: {
    downloadPDF() {
      html2pdf(this.$refs.feeReceipt, {
        margin: [0, -1],
        filename: 'document.pdf',
        enableLinks: true,
        image: { type: 'jpg', quality: 0.98 },
        html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 600 },
        jsPDF: { unit: 'in', orientation: 'portrait', format: 'a4' },
      })
    },
  },
};
</script>

<style src="./activityFeeReceipt.css" scoped></style>
