<template src="./syllabus.html"></template>

<script>
import NbaRepository from "../../../../Repository/NBA";
import SyallbusRepository from "../../../../Repository/Syllabus";
import uploadToDigitalOceanRepository from "../../../../Repository/uploadToDigitalOcean";
import showStatus from "../../../../NetworkManager/showStatus";
import { createExcelThroughJson, generateJsonFromExcel, generateFromJson } from "../../../../utils/excel";

export default {
  name: "syllabus",
  components: {
    // basicDetails,
    // examsBasicDetails,
    // examSubjectDetails,
    // examForm,
    // hallTicket,
    // examQuestionDetails,
    // examMarks,
  },
  props: ["subjectId", "semId", "courseYear", "selectedDepartment"],
  activated() {
  },
  async created() {
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.uploadToDigitalOceanInstance = new uploadToDigitalOceanRepository(
      this
    );
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];

    this.syllabusInstance = new SyallbusRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    await this.getSyllabus();
    await this.fetchCos();
  },
  computed: {},
  data() {
    return {
      arrForCos: [],
      uploadSyllabusViaExcel: false,
      userData: "",
      backgroundColor: "#E7E7FFCF",
      nbaData: [],
      courseOutcomeData: [],
      selectedInstitute: "",
      fileDialog: "",
      referenceMaterialDialog: false,
      headers: [
        { text: "Unit No", value: "unitNo", width: "5%", sortable: false },
        {
          text: "Unit Name",
          value: "unitName",
          width: "11.5%",
          sortable: false,
        },
        {
          text: "Topic",
          value: "topic",
          width: "11.5%",
          sortable: false,
          cellClass: "unitClass",
        },
        {
          text: "Sub Topic",
          value: "subtopic",
          width: "11.5%",
          sortable: false,
        },
        {
          text: "Teaching Method",
          value: "teachingMethod",
          width: "13%",
          sortable: false,
        },
        {
          text: "Teaching Tools",
          value: "teachingTools",
          width: "11.5%",
          sortable: false,
        },
        {
          text: "Reference Link",
          value: "referenceLink",
          width: "1.5%",
          sortable: false,
        },
        {
          text: "Reference Material",
          value: "referneceMaterial",
          width: "15.5%",
          sortable: false,
        },
        {
          text: "CO Mapping",
          value: "coMapping",
          width: "11.5%",
          sortable: false,
        },
      ],
      syllabus: [],
      techingMethodItems: [
        "Flipped Classroom",
        "Collaborative learning",
        "Discussion",
        "Adaptive learning",
        "Case method",
        "Experiential learning",
        "Heuristic method",
        "Lectures",
        "Problem-based learning",
        "Open learning",
        "Brainstorming",
        "Demonstration",
        "Assignment",
        "Audio Tutorials",
        "Integrative",
        "Projects",
        "Other",
      ],
      selectedItem: {},
      attachedMaterialFilesToUpload: [],
      selectedFilesUrl: [],
      saveLoader: false,
      isLoading: true,
      file: null,
      isExcelDataMissing: [],
      errorDialog: false
    };
  },
  methods: {
    getCoNumberArrByStatmentArr(arr) {
      let finalArr = []
      if (arr.length > 0 && this.courseOutcomeData.length > 0) {
        arr.map((statement) => {
          this.courseOutcomeData.map((co) => {
            if (co.coText === statement) {
              finalArr.push(co.coNumber)
            }
          })
        })
      }
      return finalArr

    },
    async generateExcel() {

      let schema = {
        Sheet1: {
          "Unit Name": {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'unitName'
          },
          "Topic Name": {
            validation: {
              type: 'textLength',
            },
            variableName: 'topicName'
          },
          "Subtopic Name": {
            validation: {
              type: 'textLength',
            },
            variableName: 'subTopicName'
          },
          "Teaching Method": {
            validation: {
              type: "list",
              values: this.techingMethodItems,
            },
            variableName: 'teachingMethod'
          },
          "Teaching Tools": {
            validation: {
              type: 'textLength',
            },
            variableName: 'teachingTools'
          },
          "Co Mapping": {
            validation: {
              type: "list",
              values: this.arrForCos,
            },
            variableName: 'coMapping'
          }
        }
      }
      this.excelLink = await generateFromJson(schema);
      const link = document.createElement("a");
      link.href = this.excelLink;
      link.target = "_blank"; // Open in a new tab
      link.download = `Syllabus Excel`;
      link.click();
    },
    async handelSyllabusExcel() {
      // console.log('this.syllabus', this.syllabus);
      // this.loading = true
      let schema = {
        Sheet1: {
          "Unit Name": {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'unitName'
          },
          "Topic Name": {
            validation: {
              type: 'textLength',
            },
            variableName: 'topicName'
          },
          "Subtopic Name": {
            validation: {
              type: 'textLength',
            },
            variableName: 'subTopicName'
          },
          "Teaching Method": {
            validation: {
              type: "list",
              values: this.techingMethodItems,
            },
            variableName: 'teachingMethod'
          },
          "Teaching Tools": {
            validation: {
              type: 'textLength',
            },
            variableName: 'teachingTools'
          },
          "Co Mapping": {
            validation: {
              type: "list",
              values: this.arrForCos,
            },
            variableName: 'coMapping'
          }
        }
      }
      this.errorDialog = false
      this.uploadSyllabusViaExcel = false

      const res = await generateJsonFromExcel(this.file, schema, false);
      // console.log('res', res);
      const { data } = res;
      const { Sheet1 } = data;
      this.isExcelDataMissing = []
      Sheet1.map((obj, i) => {
        // console.log('obj', obj);
        if (obj.unitName === '' || obj.unitName === undefined || obj.unitName === null) {
          this.isExcelDataMissing.push(`Unit name missing at line ${i + 1}`)
        }
        if (obj.subTopicName !== '' && obj.topicName === undefined && obj.subTopicName !== undefined) {
          this.isExcelDataMissing.push(`Topic  name missing at line ${i + 1}`)
        }
      })
      if (this.isExcelDataMissing.length === 0) {

        Sheet1.map((obj) => {
          // console.log('obj', obj);
          if (this.syllabus.length > 0) {
            let flagForUnit = false
            this.syllabus.map((item) => {
              // console.log('item', item);
              if (item.unitName === obj.unitName) {
                flagForUnit = true
                let flagForTopic = false
                //  console.log(' item.teachingMethod',  item.teachingMethod);
                if (item.teachingMethod && Array.isArray(item.teachingMethod)) {
                  if (obj.teachingMethod !== undefined) {

                    item.teachingMethod.push(obj.teachingMethod)
                  }
                } else {
                  item.teachingMethod = []
                  if (obj.teachingMethod !== undefined) {

                    item.teachingMethod.push(obj.teachingMethod)
                  }

                }

                if (item.cos && Array.isArray(item.cos)) {
                  if (obj.coMapping) {

                    item.cos.push(obj.coMapping)
                  }
                } else {
                  item.cos = []
                  if (obj.coMapping) {

                    item.cos.push(obj.coMapping)
                  }

                }
                item.cos = this.getCoNumberArrByStatmentArr(item.cos)
                item.teachingMethod = [...new Set(item.teachingMethod)]
                item.cos = [...new Set(item.cos)]
                item.teachingTools = obj.teachingTools
                // item.cos = obj.coMapping ? obj.coMapping : []
                item.topics.map((topic) => {
                  if (topic.topicName === obj.topicName) {
                    flagForTopic = true
                    if (obj.subTopicName !== '') {
                      if (topic.subTopics) {

                        topic.subTopics.push({
                          subTopicName: obj.subTopicName
                        })
                      } else {
                        topic.subTopics = []
                        topic.subTopics.push({
                          subTopicName: obj.subTopicName
                        })
                      }
                    }
                  }

                })
                // console.log('flagForTopic', flagForTopic);
                if (flagForTopic === false) {
                  item.topics.push({
                    topicName: obj.topicName,
                    subTopics: [{
                      subTopicName: obj.subTopicName
                    }]
                  })
                }
              }

            })
            // console.log('flagForUnit', flagForUnit);
            if (flagForUnit === false) {
              this.syllabus.push({
                unitNumber: this.syllabus.length + 1,
                unitName: obj.unitName,
                teachingMethod: obj.teachingMethod ? [obj.teachingMethod] : [],
                teachingTools: obj.teachingTools,
                cos: obj.coMapping ? this.getCoNumberArrByStatmentArr([obj.coMapping]) : [],
                topics: [{
                  topicName: obj.topicName,
                  subTopics: [{
                    subTopicName: obj.subTopicName
                  }]
                }]


              })
            }

          } else {
            this.syllabus.push({
              unitNumber: 1,
              unitName: obj.unitName,
              teachingMethod: obj.teachingMethod ? [obj.teachingMethod] : [],
              teachingTools: obj.teachingTools,
              cos: obj.coMapping ? this.getCoNumberArrByStatmentArr([obj.coMapping]) : [],
              topics: [{
                topicName: obj.topicName,
                subTopics: [{
                  subTopicName: obj.subTopicName
                }]
              }]


            })

          }
        })
        let value = await this.syllabusInstance.createSyllabus({
          instituteId: this.selectedInstitute,
          subjectId: this.subjectId,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          units: this.syllabus,
        });
        await this.getSyllabus()
      } else {
        this.errorDialog = true
      }
      // this.loading = false
    },
    prepareSyallabus() {
      this.syllabus.forEach((unit) => {
        unit.teachingMethod.forEach((item, index) => {
          if (!this.techingMethodItems.includes(item)) {
            unit.otherTeachingMethod = unit.teachingMethod[index]
            unit.teachingMethod[index] = 'Other'
            unit.showOther = true;
            unit.otherIndex = index
          }
        })
        // s.teachingMethods = this.techingMethodItems
      })
    },
    async uploadFile(fileArr) {
      let urlLinkArr = [];
      try {
        if (fileArr.length > 0) {
          let data = await this.uploadToDigitalOceanInstance.upload(
            fileArr,
            this.userData.uId
          );
          return data;
        }
      } catch (error) {
        console.error("error");
        return urlLinkArr;
      }
    },
    hasAttachedFiles(item) {
      if (
        item.attachedMaterialFilesToUpload &&
        item.attachedMaterialFilesToUpload.length > 0
      ) {
        return true;
      }
      if (item.referenceMaterial?.length > 0) {
        return true;
      }
      return false;
    },
    // addUnit() {
    //   this.syllabus.push({
    //     type: "unit",
    //     unit: {
    //       unitNo: "",
    //       unitName: "",
    //       topics: [],
    //     },
    //   });
    // },

    handleOtherOption(item) {
      item.showOther = false;
      if (item.teachingMethod.includes("Other")) {
        const index = item.teachingMethod.indexOf("Other");
        item.otherIndex = index;
        item.showOther = true;
      }
    },
    addSubTopic(unitIndex, topicIndex, subTopicIndex) {
      this.syllabus[unitIndex].topics[topicIndex].subTopics.push({
        subTopicName: "",
        type: "subtopic",
      });
    },
    addTopic(unitIndex) {
      this.syllabus[unitIndex].topics.push({
        topicName: "",
        subTopics: [],
        type: "topic",
      });
    },
    addUnit(index) {
      this.syllabus.push({
        unitName: "",
        cos: [],
        topics: [],
        unitNumber: index,
      });
    },
    deleteSubTop(unitIndex, topicIndex, subTopicIndex) {
      // this.syllabus[unitIndex].topics[topicIndex].subTopics.pop();
      this.syllabus[unitIndex].topics[topicIndex].subTopics.splice(subTopicIndex, 1)
    },
    deleteTopic(unitIndex, topicIndex) {
      this.syllabus[unitIndex].topics.splice(topicIndex, 1);
    },
    deleteUnit(unitIndex) {
      this.syllabus.splice(unitIndex, 1);
    },
    async fetchCos() {
      try {
        this.nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.semId,
          subjectId: this.subjectId,
        });
      } catch (error) {
        console.error(error)
      }
      if (Array.isArray(this.nbaData.data)) {
        this.nbaData.data.forEach((nba, i) => {
          this.courseOutcomeData.push({
            Id: nba._id,
            coNumber: nba.CONumber,
            coName: nba.COName,
            coText: `CO ${nba.CONumber} - ${nba.COName}`,
          });
          this.sortByNumericValueInAscendingOrder(
            this.courseOutcomeData,
            "coNumber"
          );
        });
      }
      console.log('this.courseOutcomeData', this.courseOutcomeData);
      this.arrForCos = []
      if (this.courseOutcomeData.length > 0) {
        this.courseOutcomeData.map((item) => {

          this.arrForCos.push(item.coText)
        })
      }
    },
    deleteReferenceMaterial(item) {
      item.referenceMaterial = []
    },
    sortByNumericValueInAscendingOrder(array, fieldToSortBy) {
      array.sort((a, b) => a[fieldToSortBy] - b[fieldToSortBy]);
      return array;
    },
    openFileDialog(item) {
      // this.courseProofDialog = true;
      // if (this.courseProofFilesToUpload) {
      //   this.selectedFilesUrl = await Promise.all(
      //     this.courseProofFilesToUpload.map(async (file, index) => {
      //       return await URL.createObjectURL(file);
      //     })
      //   );
      // },
      if (item.attachedMaterialFilesToUpload) {
        this.attachedMaterialFilesToUpload = [];
        this.selectedFilesUrl = [];
        this.attachedMaterialFilesToUpload = item.attachedMaterialFilesToUpload;
        if (this.attachedMaterialFilesToUpload) {
          this.selectedFilesUrl = this.attachedMaterialFilesToUpload.map(
            (file, index) => {
              return URL.createObjectURL(file);
            }
          );
        }
      }
      if (item.referenceMaterial && item.referenceMaterial.length > 0) {
        this.selectedItem = item;
      }
      this.referenceMaterialDialog = true;
      // this.fileDialog = true;
    },
    onContentLoaded() {
      // This is called when the content has loaded successfully
      this.isLoading = false;
    },
    getFileInputLabel(item) {
      if (item.referenceMaterial) {
        let fileCount = item.referenceMaterial.length
        return `${fileCount} file${fileCount > 1 ? 's' : ''} attached`;
      }
      return 'Attach material files';
    },
    async saveSyallbus() {
      this.saveLoader = true;
      const uploadPromises = [];
      this.syllabus.forEach((s) => {
        if (s?.attachedMaterialFilesToUpload?.length > 0) {
          // Add the upload promise to the array
          const uploadPromise = this.uploadFile(
            s.attachedMaterialFilesToUpload
          ).then((res) => {
            s.referenceMaterial = res;
          });
          uploadPromises.push(uploadPromise);
        }
      });
      await Promise.all(uploadPromises);

      this.syllabus.forEach((s, index) => {
        if (s.otherIndex !== undefined) {
          s.teachingMethod[s.otherIndex] = s.otherTeachingMethod
        }
      })
      try {
        let res = await this.syllabusInstance.createSyllabus({
          instituteId: this.selectedInstitute,
          subjectId: this.subjectId,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          units: this.syllabus,
        });
        showStatus(
          "Syallbus saved successfully",
          2000,
          "success",
          this
        );
      } catch (error) {
        console.error(error);
        showStatus("Something went wrong", 2000, "error", this);
      } finally {
        this.saveLoader = false;
      }
    },
    async getSyllabus() {
      try {
        let res = await this.syllabusInstance.getSyllabus({
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
        });
        if (res.status.status === 200) {
          this.syllabus = res.result.units
          this.prepareSyallabus();
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
};
</script>

<style scoped src="./syllabus.css"></style>