<template src="./activitySemesterResults.html"></template>

<script>
import activityExamGrouping from "./activityExamGrouping/activityExamGrouping.vue";
import activityResults from "./activityResults/activityResults.vue";
export default {
  name: "activitySemesterResults",
  components: { activityExamGrouping, activityResults },
  data() {
    return {
      currentTab: 0,
      mainTabNames: ["Subjects", "Result"],
      selectedDepartment: "",
      selectedSemester: "",
      selectedCourseYear: "",
    };
  },
  created() {
    this.selectedSemester = this.$store.getters["instituteData/get_selectedExamSemester"] || "";
    this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"] || "";
    this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"] || "";
  },
  methods: {
    gotoExams() {
      this.$router.push({
        name: "activityExams1",
      });
    },
  },
};
</script>

<style src="./activitySemesterResults.css" scoped></style>