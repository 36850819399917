<template src='./activityExamConductionMonitor.html'>
</template>
<script>
import io from 'socket.io-client'
import { socketUrl } from '../../../NetworkManager/index'
import ExamTimetableRepository from '../../../Repository/ExamTimetable'
import UserRepository from '../../../Repository/User'
export default {
  name: 'activityExamConductionMonitor',
  data () {
    return {
      selectedExamSubject: {},
      duration: '',
      socket: io(socketUrl),
      examDetails: {},
      refresh: false,
      items: [],
      headers: [
        // {
        //   text: 'ID',
        //   value: 'uId'
        // },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Time Remaining',
          value: 'timeRemainingForStudent'
        },
        {
          text: 'Marks',
          value: 'marksObtained'
        },
        {
          text: 'Submitted',
          value: 'hasSubmittedExam'
        },
        {
          text: 'Actions',
          value: 'isAllowedToGiveExam'
        }
      ],
      userMappings: {},
      search: ''
    }
  },
  created () {
    this.examTimetableRepositoryInstance = new ExamTimetableRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.selectedExamSubject = this.$store.getters['liveData/get_selectedExamSubject']
    const objToPush = {
      instituteId: this.selectedExamSubject.instituteId,
      semId: this.selectedExamSubject.semId,
      examId: this.selectedExamSubject.examId
    }
    console.log(objToPush)
    this.processData()
  },
  beforeDestroy () {
    this.socket.disconnect()
  },
  methods: {
    async processData () {
      let examTimetable = []
      try {
        const objToPush = {
          instituteId: this.selectedExamSubject.instituteId,
          semId: this.selectedExamSubject.semId,
          examId: this.selectedExamSubject.examId
        }
        examTimetable = await this.examTimetableRepositoryInstance.getExamTimetableForAnExam(objToPush)
        console.log(examTimetable)
      } catch (err) {
        console.log(err)
        examTimetable = []
      }

      console.log('examTimetable', examTimetable)

      examTimetable.forEach((tt) => {
        console.log(tt.subjectId, this.selectedExamSubject.subjectId)
        if (tt.subjectId === this.selectedExamSubject.subjectId) {
          console.log(tt)
          this.duration = tt.duration
          const objToPush2 = {
            instituteId: this.selectedExamSubject.instituteId,
            semId: this.selectedExamSubject.semId,
            examId: this.selectedExamSubject.examId,
            subjectId: this.selectedExamSubject.subjectId,
            duration: this.duration,
            applicableUsers: this.selectedExamSubject.applicableUsers
          }
          console.log('objToPush2', objToPush2)
          this.socket.emit('startExamOfAFaculty', objToPush2)
          this.socket.on('startExamOfAFacultyAck', res => {
            console.log(res)
            this.getLiveData()
          })
        }
      })
    },
    getLiveData () {
      const objToPush = {
        instituteId: this.selectedExamSubject.instituteId,
        semId: this.selectedExamSubject.semId,
        examId: this.selectedExamSubject.examId,
        subjectId: this.selectedExamSubject.subjectId
      }
      console.log(objToPush)
      this.socket.emit('getLiveStatusForAnExam', objToPush)
      this.socket.on('getLiveStatusForAnExamAck', async res => {
        console.log(res)
        this.items = []
        this.examDetails = res.examDetails
        const promises = []
        for (let i = 0; i < this.examDetails.students.length; i++) {
          this.items.push({
            uId: this.examDetails.students[i].uId,
            name: '',
            isAllowedToGiveExam: this.examDetails.students[i].isAllowedToGiveExam,
            timeRemainingForStudent: this.examDetails.students[i].timeRemainingForStudent,
            hasSubmittedExam: this.examDetails.students[i].hasSubmittedExam,
            marksObtained: this.examDetails.students[i].marksObtained
          })
          promises.push(this.getNameOfAUser(this.items.length - 1))
        }

        this.refresh = true

        await Promise.allSettled(promises)
      })
    },
    async getNameOfAUser (indexOfStudent) {
      if (!this.userMappings[this.items[indexOfStudent].uId]) {
        try {
          const name = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.items[indexOfStudent].uId })
          this.userMappings[this.items[indexOfStudent].uId] = name
          this.items[indexOfStudent].name = this.userMappings[this.examDetails.students[indexOfStudent].uId]
        } catch (err) {
          console.log(err)
        }
      } else {
        this.items[indexOfStudent].name = this.userMappings[this.examDetails.students[indexOfStudent].uId]
      }
    },
    convertSecondstoTimeString (duration) {
      const hours = Math.floor(duration / 3600)
      const dur = duration % 3600
      const minutes = Math.floor(dur / 60)
      const seconds = dur % 60
      if (hours === 0) {
        return minutes + ' Minutes ' + seconds + ' Seconds'
      } else {
        return hours + ' Hrs ' + minutes + ' Mins ' + seconds + ' Secs'
      }
    },
    stopExam () {
      const objToPush = {
        instituteId: this.selectedExamSubject.instituteId,
        semId: this.selectedExamSubject.semId,
        examId: this.selectedExamSubject.examId,
        subjectId: this.selectedExamSubject.subjectId
      }
      this.socket.emit('stopExamOfAFaculty', objToPush)
      this.socket.on('stopExamOfAFacultyAck', res => {
        console.log(res)
        if (res === '1') {
          this.$router.push('/activityExamDashboard')
        } else {
          console.log('Something went wrong!')
        }
      })
    },
    blockStudent (item) {
      console.log(item, this.selectedExamSubject)
      const objToPush = {
        instituteId: this.selectedExamSubject.instituteId,
        semId: this.selectedExamSubject.semId,
        examId: this.selectedExamSubject.examId,
        subjectId: this.selectedExamSubject.subjectId,
        uId: item.uId
      }
      this.socket.emit('blockAStudentForAnExam', objToPush)
      this.socket.on('blockAStudentForAnExamAck', res => {
        console.log(res)
        if (res === '1') {
          this.getLiveData()
        } else {
          console.log('Something went wrong!')
        }
      })
    },
    unblockStudent (item) {
      console.log(item, this.selectedExamSubject)
      const objToPush = {
        instituteId: this.selectedExamSubject.instituteId,
        semId: this.selectedExamSubject.semId,
        examId: this.selectedExamSubject.examId,
        subjectId: this.selectedExamSubject.subjectId,
        uId: item.uId
      }
      this.socket.emit('allowStudentForAnExam', objToPush)
      this.socket.on('allowStudentForAnExamAck', res => {
        console.log(res)
        if (res === '1') {
          this.getLiveData()
        } else {
          console.log('Something went wrong!')
        }
      })
    }
  }
}
</script>
<style scoped src='./activityExamConductionMonitor.css'>
</style>
