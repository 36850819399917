export default {
  createExamTimetable: '/examTimetable/create',
  getExamTimetableForAnExam: '/examTimetable/getExamTimetableForAnExam',
  getExamTimetableForAFaculty: '/examTimetable/getExamTimetableForAFaculty',
  getExamTimetableForAFacultyForADay: '/examTimetable/getExamTimetableForAFacultyForADay',
  getExamTimetableForALocation: '/examTimetable/getExamTimetableForALocation',
  getExamTimetableForAStudent: '/examTimetable/getExamTimetableForAStudent',
  getExamTimetableForASubject: '/examTimetable/getExamTimetableForASubject',
  updateExamTimetableForALocation: '/examTimetable/updateExamTimetableForALocation',
  updateExamTimetableForASubject: '/examTimetable/updateExamTimetableForASubject',
  deleteExamTimetableForALocation: '/examTimetable/deleteExamTimetableForALocation',
  deleteExamTimetableForAnExam: '/examTimetable/deleteExamTimetableForAnExam',
  deleteExamTimetableForASubject: '/examTimetable/deleteExamTimetableForASubject'
}
