<template src="./activityQuickTestInsight.html"></template>
<script>
import apiV3 from "../../NetworkManager/apiV3";
import ExamSubjectRepository from "../../Repository/ExamSubject";
import ExamStudentRepository from "../../Repository/ExamStudent";
import UserRepository from "../../Repository/User";
import SemesterUserRepository from "../../Repository/SemesterUser";

export default {
  name: "activityQuickTestInsight",
  data() {
    return {
      selectedExam: {},
      graphDataLoaded: false,
      upperlimitsArray: [],
      series: [
        {
          name: "Marks Distribution",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            columnWidth: "75%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          title: {
            text: "Marks",
          },
          labels: {},
          categories: [],
        },
        yaxis: {
          title: {
            text: "Nummber of Students",
          },
        },
        fill: {
          colors: ["#050992"],
        },
      },
      datacollection: {
        labels: ["January"],
        datasets: [
          {
            label: "Data one",
            backgroundColor: "#f87979",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: [40],
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        responsive: true,
        height: 200,
      },
      isMobileView: false,
      totalStudents: 0,
      totalLectures: 0,
      selectedLimit: 75,
      defaulterStudentsPercent: 0,
      defaulterStudentsCount: 0,
      failedStudentCount: 0,
      passedStudentCount: 0,
      failedStudentPercent: 0,
      passedStudentPercent: 0,
      nondefaulterStudentsPercent: 0,
      nondefaulterStudentsCount: 0,
      search: "",
      studentsList: [],
      headers: [
        {
          text: "",
          value: "absentStatusDot",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Marks",
          value: "marks",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Answers",
          value: "answers",
        },
      ],
      selectedSubject: {},
      isAnswersDialogOpen: false,
      selectedStudent: null,
      isLoadingAnswers: false,
      studentAnswers: [],
      attemptNo: 0,
      attemptData: {},
      questions: 0,
      time: 0,
      responseLength: 0,
      timeTake: '00:00',
      count: 0
    };
  },
  created() {
    this.selectedExam = this.$store.getters["liveData/get_selectedExam"];
    this.selectedExam.totalObtainableMarks = this.selectedExam.totalMarks
      ? this.selectedExam.totalMarks
      : 100;
    this.selectedLimit = this.selectedExam.passingPercent;
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this);
    this.examStudentRepositoryInstance = new ExamStudentRepository(this);
    // if (this.prop_subject) {
    //   this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject));
    // } else {
    //   this.selectedSubject = JSON.parse(
    //     JSON.stringify(this.$store.getters["liveData/get_selectedSubject"])
    //   );
    // }
    this.$store.commit("liveData/set_selectedActivityName", "Exam Analysis");
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.fetchAnalysis();
  },
  methods: {
    async fetchAnalysis() {
      try {
        const { data } = await apiV3.getRequest(
          `/exam/analysis?examId=${this.selectedExam._id}`
        );
        let promises = [];
        this.selectedExam.allowedStudents.forEach((id) => {
          promises.push(
            this.userRepositoryInstance.getFullNameOfUser({ uId: id })
          );
        });
        promises = await Promise.allSettled(promises);
        const temp = [];
        for (let i = 0; i < promises.length; i++) {
          temp.push({
            name: promises[i].value.replace("  ", " "),
            _id: this.selectedExam.allowedStudents[i],
          });
        }
        const merged = [];
        for (let i = 0; i < temp.length; i++) {
          const allData = data.find(
            (answer) => answer.studentId === temp[i]._id
          );
          if (allData) {
            const { totalMarksObtained, status, ...rest } = allData;
            if (status === "pass") {
              this.passedStudentCount++;
            } else {
              this.failedStudentCount++;
            }
            merged.push({
              ...temp[i],
              marks: totalMarksObtained,
              status,
              ...rest,
            });
          } else {
            this.failedStudentCount++;
            merged.push({
              ...temp[i],
              status: "absent",
              marks: "-",
            });
          }
        }
        this.studentsList = merged;
        this.failedStudentPercent = (
          (this.failedStudentCount / this.selectedExam.allowedStudents.length) *
          100
        ).toFixed(0);
        this.passedStudentPercent = (
          (this.passedStudentCount / this.selectedExam.allowedStudents.length) *
          100
        ).toFixed(0);
        this.studentList = data;
        this.findCategory();
      } catch (err) {
        console.log(err);
        this.analysisData = [];
      }
    },
    async changeLimit() {
      if (this.selectedLimit === "" || this.selectedLimit < 0) {
        this.selectedLimit = 1;
      }
      if (this.selectedLimit > 100) {
        this.selectedLimit = 100;
      }
      if (isNaN(this.selectedLimit)) {
        this.selectedLimit = 0;
      }
      const selectedLimitInNumbers =
        (this.selectedLimit *
          parseInt(
            this.selectedExam.totalObtainableMarks
              ? this.selectedExam.totalObtainableMarks
              : 100
          )) /
        100;
      this.failedStudentCount = 0;
      this.passedStudentCount = 0;
      for (let i = 0; i < this.studentsList.length; i++) {
        if (
          parseInt(this.studentsList[i].marks) <
          parseInt(selectedLimitInNumbers)
        ) {
          this.studentsList[i].status = "Failed";
          this.failedStudentCount++;
        } else {
          this.studentsList[i].status = "Passed";
          this.passedStudentCount++;
        }
      }
      this.failedStudentPercent = (
        (this.failedStudentCount / this.studentsList.length) *
        100
      ).toFixed(0);
      this.passedStudentPercent = (
        (this.passedStudentCount / this.studentsList.length) *
        100
      ).toFixed(0);
    },
    async findCategory() {
      let lowerLimits = 0;
      const difference = Math.ceil(
        parseInt(this.selectedExam.totalObtainableMarks) / 5
      );
      let upperLimits = difference;
      while (lowerLimits <= this.selectedExam.totalObtainableMarks) {
        this.upperlimitsArray.push(upperLimits);
        this.series[0].data.push(0);
        this.chartOptions.xaxis.categories.push(
          lowerLimits + "-" + upperLimits
        );
        lowerLimits = upperLimits;
        upperLimits = lowerLimits + difference;
      }
      this.marksPerCategory();
    },
    async marksPerCategory() {
      for (let i = 0; i < this.studentsList.length; i++) {
        for (let j = 0; j < this.upperlimitsArray.length; j++) {
          if (this.upperlimitsArray[j] > parseInt(this.studentsList[i].marks)) {
            this.series[0].data[j]++;
            break;
          }
        }
      }
      this.graphDataLoaded = true;
    },
    async downloadPdf() {
      try {
        const pdfBlob =
          await this.lectureRepositoryInstance.generateAttendanceAnalysisExcel(
            this.prop_subject
          );
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Attendance Report.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },
    calculateAttempt(i) {
      // Assuming prop_exam is an array containing the data for each attempt.
      this.count = 0
      this.time = '00:00'
      this.attemptData = {}
      if (this.studentAnswers[i - 1]) {
        this.count = 0
        this.attemptData = this.studentAnswers[i - 1];
        // console.log('this.count', this.attemptData)
        for (let j = 0; j < this.attemptData?.answers.length; j++) {
          if (this.attemptData?.answers[j].answer === this.attemptData?.answers[j].selectedAnswer) this.count++
        }
        // console.log('attemptData1', this.attemptData)

        this.questions = this.attemptData.answers.length
      }
  },
  async showAnswers (student) {
      try {
        this.isAnswersDialogOpen = true
        this.isLoadingAnswers = true
        this.selectedStudent = student
        const answers = await apiV3.postRequest(`/exam/getAllAnswers`, {
          studentId: `${student._id}`,
          examIds: [`${this.selectedExam._id}`]
        });
        // console.log('this.studentAnswers', answers)
        const newAnsArr = []
        if (answers.length > 3) {
          for (let i = 0; i < 3; i++) {
            newAnsArr.push({ ...answers[i] })
          }
          this.studentAnswers = newAnsArr
        } else {
          this.studentAnswers = answers
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoadingAnswers = false
      }
   },
    gotoQuickTest() {
      this.$router.push({
        name: "activityQuickTest",
      });
    },
  },
};
</script>
<style src="./activityQuickTestInsight.css" scoped></style>
