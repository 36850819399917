<template src='./activityExamDashboard.html'>
</template>
<script>
import { subjectTypes } from '../../../Constants/Utils/Statics'
import ExamSubjectRepository from '../../../Repository/ExamSubject'
import ExamTimetableRepository from '../../../Repository/ExamTimetable'
import SubjectRepository from '../../../Repository/Subject'
export default {
  name: 'activityExamDashboard',
  data () {
    return {
      selectedInstitute: '',
      selectedSemester: {},
      selectedExam: {},
      examSubjects: [],
      headers: [
        {
          text: 'Exam',
          value: 'subjectName'
        },
        {
          text: 'Subject Code',
          value: 'subjectCode'
        },
        {
          text: 'Subject Type',
          value: 'subjectType'
        },
        {
          text: 'Online',
          value: 'isOnline'
        },
        {
          text: 'Students',
          value: 'applicableUsers'
        },
        {
          text: 'Questions',
          value: 'questions'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      subjectTypes: subjectTypes,
      examSubjectWiseTimetablesDistribution: {},
      loading: true,
      examTimetables: []
    }
  },
  created () {
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.examTimetableRepositoryInstance = new ExamTimetableRepository(this)
    this.fetchExamSubjects()
  },
  methods: {
    async getSubjectDetails (subject) {
      const response = await this.subjectRepositoryInstance.getDetailsOfASubject({
        instituteId: subject.instituteId,
        semId: subject.semId,
        subjectId: subject.subjectId
      })
      subject.subjectName = response.subjectName
      subject.subjectType = response.subjectType
      subject.subjectCode = response.subjectCode
      console.log(subject, response)
    },
    async fetchExamSubjects () {
      // this.selectedExam = this.exams[this.examsStrings.indexOf(this.selectedExamString)]
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId
      }
      console.log(objToPush)
      this.examSubjects = []
      try {
        this.examSubjects = await this.examSubjectRepositoryInstance.getSubjectsForAnExam(objToPush)
      } catch (err) {
        this.examSubjects = []
        console.log(err)
      }

      for (let i = 0; i < this.examSubjects.length; i++) {
        await this.getSubjectDetails(this.examSubjects[i])
      }

      this.examTimetables = []
      try {
        this.examTimetables = await this.examTimetableRepositoryInstance.getExamTimetableForAnExam(objToPush)
      } catch (err) {
        console.log(err)
      }
      console.log(this.examTimetables)
      for (let i = 0; i < this.examTimetables.length; i++) {
        const str = this.examTimetables[i].subjectId
        if (!this.examSubjectWiseTimetablesDistribution[str]) {
          this.examSubjectWiseTimetablesDistribution[str] = []
        }
        this.examSubjectWiseTimetablesDistribution[str].push(this.examTimetables[i])
      }
      console.log(this.examSubjectWiseTimetablesDistribution)
      this.loading = false
      // axios.post(apiConfig.getExamSubjects, {
      //   instituteId: this.selectedInstitute,
      //   semId: this.selectedSemester.semId,
      //   examId: this.selectedExam.examId
      // }).then(res => {
      //   console.log(res)
      //   this.examSubjects = res.data.examSubject
      //   axios.post(apiConfig.getExamTimetable, {
      //     instituteId: this.selectedInstitute,
      //     semId: this.selectedSemester.semId,
      //     examId: this.selectedExam.examId
      //   }).then(res2 => {
      //     console.log(res2)
      //     this.examTimetables = []
      //     this.examTimetables = res2.data.examTimetable
      //     for (let i = 0; i < this.examTimetables.length; i++) {
      //       const str = this.examTimetables[i].subjectCode + '_' + this.examTimetables[i].subjectName + '_' + this.examTimetables[i].subjectType
      //       if (this.examSubjectWiseTimetablesDistribution[str]) {} else {
      //         this.examSubjectWiseTimetablesDistribution[str] = []
      //       }
      //       this.examSubjectWiseTimetablesDistribution[str].push(this.examTimetables[i])
      //     }
      //     console.log(this.examSubjectWiseTimetablesDistribution)
      //     this.loading = false
      //   }).catch(err => {
      //     console.log(err)
      //     this.loading = false
      //   })
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    publishExam (item) {
      console.log(item)
    },
    startExam (item) {
      this.$store.commit('liveData/set_selectedExamSubject', item)
      this.$router.push('/activityExamConductionMonitor')
    },
    gotoExamAnalysis (item) {
      console.log(item)
      this.$router.push('/activityExamAnalysis')
    }
  }
}
</script>
<style scoped src='./activityExamDashboard.css'>
</style>
