import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/InfrastructureReference";

class InfrastructureReference {
  constructor(context) {
    this.context = context;
  }

  async createRoom(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createRoom,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.roomData;
  }

  async createEquipments(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createEquipments,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.equipments;
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createBuilding(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createBuilding,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.building;
  }

  async getBuildings(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getBuildings,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.buildings;
  }

  async getRooms(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(ApiNames.getRooms, data);
    this.context.$store.commit("liveData/set_loading", false);
    return response.rooms;
  }

  async getRoomsFromRoomInventory(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getRoomsFromRoomInventory,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.roomData;
  }

  async getRoomNames(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getRoomNames,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.roomName;
  }
  async getRoomName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getRoomName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.roomName;
  }

  async getRoomsOfMultiDepartment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getRoomsOfMultiDepartment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.rooms;
  }
  async getRoomsOfADepartment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getRoomsOfADepartment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.rooms;
  }

  async getAllRoomsOfAInstituteAndDepartment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllRoomsOfAInstituteAndDepartment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.rooms;
  }

  async getEquipmentsOfADepartment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getEquipmentsOfADepartment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.equipments;
  }

  async updateRoomName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateRoomName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.roomName;
  }

  async updateRoomDepartment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateRoomDepartment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.department;
  }

  async updateSharingDepartments(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateSharingDepartments,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.sharingDepartments;
  }

  async updateType(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(ApiNames.updateType, data);
    this.context.$store.commit("liveData/set_loading", false);
    return response.type;
  }

  async deleteRoom(data) {
    this.context.$store.commit("liveData/set_loading", true);
    await networkManager.deleteRequest(ApiNames.deleteRoom, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deleteBuilding(data) {
    this.context.$store.commit("liveData/set_loading", true);
    await networkManager.deleteRequest(ApiNames.deleteBuilding, data);
    this.context.$store.commit("liveData/set_loading", false);
  }
}

export default InfrastructureReference;
