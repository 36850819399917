<template>
  <div :style="[customStyle, tagStyle]" :class="tagClass">
    <v-icon v-if="icon" :class="icon" :style="iconStyle"></v-icon>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TextTag",
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "#000000",
    },
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
    iconColor: {
      type: String,
      default: "#000000",
    },
    width: {
      type: String,
      default: "auto",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["x-small", "small", "medium", "large", "x-large"].includes(value),
    },
  },
  computed: {
    tagStyle() {
      const sizeStyles = {
        "x-small": { padding: "2px 5px", fontSize: "10px" },
        "small": { padding: "3px 7px", fontSize: "12px" },
        "medium": { padding: "5px 10px", fontSize: "14px" },
        "large": { padding: "7px 14px", fontSize: "16px" },
        "x-large": { padding: "10px 20px", fontSize: "18px" },
      };
      return {
        color: this.textColor,
        backgroundColor: this.backgroundColor,
        width: this.width,
        display: "flex",
        alignItems: "center",
        padding: "5px 10px",
        borderRadius: this.rounded ? "50px" : "4px",
        ...sizeStyles[this.size],
      };
    },
    iconStyle() {
      return {
        color: this.iconColor,
        marginRight: this.text ? "8px" : "0",
      };
    },
    tagClass() {
      // Define your dynamic classes here. For now, it's an empty string.
      return "";
    },
  },
};
</script>

<style scoped>
/* Add any additional styling here if needed */
</style>
