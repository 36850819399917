<template src="./activityReferenceInfrastructure.html"></template>
<script>
import html2pdf from 'html2pdf.js'
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'
import { spacesUrl } from "../../NetworkManager";
import SemesterUserRepository from '../../Repository/SemesterUser'
import AdditionalFacilityReference from '../../Repository/AdditionalFacilityReference'
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'activityReferenceInfrastructure',
  components: {
    inputContainer,
    smallButton,
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      tabs: ['Dashboard', 'Data in Table View'],
      tempSelectedBuildingForDashBoardData: '',
      currentPageTable1: 1,
      currentPageSharingDepartMentRoomTable: 1,
      searchValue: '',
      currentPageTable2: 1,
      paginationItemPerPage: 5,
      tempNumberOfDepartment: '',
      tempNumberOfRooms: '',
      tempNumberOfEquipments: '',
      tempNumberOfStudents: '',
      tempNumberOfAdditionalFacility: '',
      selectedFilterRoomType1: null,
      selectedFilterRoomType2: null,
      viewRoomModal: false,
      currentViewingRoom: '',
      roomInfraDeleteModel: false,
      deletingInventoryRoomId: false,
      allRoomData: [],
      allAdditionalFacilityRoomData: [],
      summaryItemsBox: [{ title: 'Number of Departments', value: '0' }, { title: 'Number of Rooms', value: '0' }, { title: 'Number of Equipment', value: '0' }, { title: 'Number of Students', value: '0' }, { title: 'Number of Additional Facility', value: '0' }],
      tab: null,
      loading: false,
      isFacilityAvailable: false,
      AllFacility: [],
      tempRoomName: '',
      tempRoomDepartment: '',
      tempSelectedDepartment: '',
      tempSelectedDepartmentForEquipments: '',
      tempCategoryName: '',
      currentUploadProgress: 0,
      tempRoomBuilding: '',
      selectedCategory: {},
      tempRoomName: '',
      tempSelectedRoomType: '',
      MaintenanceFrequency: ['Yearly', 'Every 6 months', 'Monthly', 'Weekly'],
      tempRoomSharingDepartments: [],
      academicFacility: ["Campus", "Housing", "Value added Courses", "Industrial Visit", "Guest Lectures", "Placement Team", "Digital Board", "Multimedia Facility"],
      attachedFiles: [],
      tempSelectedFaculty: '',
      tempSelectedCategories: [],
      tempSelectedEquipments: [],
      deptFaculties: [],
      tempSelectedFacultyDesignation: '',
      tempRoomAddElibraryResourse: '',
      tempSelectedFacultyEducation: '',
      facultyEducation: ["Ph.D", "M.Sc", "M.Tech", "B.Tech", "Other"],
      tempSelectedTechnicalFacility: [],
      tempSelectedPhysicalFacility: [],
      tempSelectedAdditionalFacility: [],
      SelectedAdditionalFacility: [],
      tempSelectedsafetyMeasures: [],
      physicalFacility: ["Library", "Study Materials", "Lecture Hall", "Couching Classes", "Classrooms", "Labs", "Auditorium"],
      technicalFacility: ["Computers", "Overhead Projectors", "Power Point Projectors", "Interactive Television", "Interactive Videos", "Software packages", "Flipcharts", "Radios", "Interactive Television"],
      facultyDesignation: ["Assistant Professor", "Professor", "Lab Assistant Professor", "Lecturer", "Associate Professor", "Other"],
      tempRoomClassRoom: false,
      safetyMeasures: ["Fire Extinguisher", "Hand gloves", "First Aid", "Safety Shoes", "Other"],
      tempRoomPracticalLab: false,
      tempRoomOffice: false,
      tempRoomConferenceHall: false,
      tempRoomMeetingRoom: false,
      tempRoomSpecialRoom: false,
      regulerStartTimeModal: false,
      regulerEndTimeModal: false,
      extraEndTimeModal: false,
      selectedEquipmentsDataAvailable: false,
      extraStartTimeModal: false,
      userData: {},
      tempRoomArea: 0,
      tempBatchSize: 0,
      tempUtilizationHour: 0,
      selectedInstitute: {},
      tempSelectedBuilding: {},
      tempFinalSelectedEquipments: [],
      tempSelectedSubCategories: [],
      items: [],
      roomsData: [],
      tempCategories: [],
      tempRoomElibraryResourses: [],
      eLibraryResourses: ["E-Journals", "Databases", "E-Books", "E-ShodhSindhu", "Shodhganga"],
      regulerStartTimeOfPrejectLab: null,
      regulerEndTimeOfPrejectLab: null,
      extraStartTimeOfPrejectLab: null,
      extraEndTimeOfPrejectLab: null,
      searchValue1: '',
      searchValue2: '',
      roomTypes: ["Class Room", "Other Rooms", "Laboratory", "Hall", "E-Library", "Project Laboratory", "Others"],
      step: 1,
      headers: [
        {
          text: 'Room Name',
          value: 'roomName'
        },
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Sharing Departments',
          value: 'sharingDepartments',
          sortable: false
        },
        {
          text: 'Room Type',
          value: 'type'
        },
        {
          text: 'Equipments',
          value: 'roomEquipments'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      search: '',
      selectedRooms: [],
      selectedRoom: {},
      buildings: [],
      selectedBuilding: {},
      tempSelectedCategory: {},
      selectedBuildingId: '',
      addingRoom: false,
      addingEquipments: false,
      addingSubcategory: false,
      departments: [],
      editing: false,
      addingBuilding: false,
      isBuildingAvailable: false,
      tempBuildingName: '',
      showBuildingDeletingDialog: false,
      tempBuildingSelectedForDeleting: {},
      inputRules: {
        valueLimit: value => parseInt(value) <= 10 || `value more err`,
      },
      dashboardChartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false, // Disable the toolbar
          },
        },
        responsive: [
          {
            breakpoint: 1100, // Adjust based on your desired breakpoint
            options: {
              chart: {
                width: '100%',
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            borderRadiusApplication: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'right',
          markers: {
            width: 12,
            height: 12,
            radius: 12,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 10,
          },
        },
        colors: ['rgba(0, 105, 184, 1)', 'rgba(0, 174, 227, 1)', 'rgba(0, 166, 156, 1)', 'rgba(254, 241, 112, 1)'],
      },

      dashboardChartSeries: [
        {
          name: 'Number of Rooms',
          data: []
        }, {
          name: 'Number of equipment',
          data: []
        }, {
          name: 'Number of students',
          data: []
        }, {
          name: 'Number of additional facilities',
          data: []
        }],
      // table states
      departMentTableHeading: [],
      departMentTableData: [],
      sharingDepartmentHeading: [],
      sharingDepartmentTableData: []
    }
  },
  created() {
    // this.$store.commit(
    //   'liveData/set_selectedActivityName',
    //   'Reference Infrastructure'
    // )
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    // re setting editing room from Global state to empty 
    this.$store.commit("liveData/set_selectedEditingRoom", {
      editingRoomInventory: null
    });
    this.userData = this.$store.getters['user/get_userData']
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.additionalFacilityReferenceInstance = new AdditionalFacilityReference()
    this.fetchData()
  },
  updated() {
    if (this.addingRoom === false && this.addingEquipments === false) {
      this.clearRoomData()
      this.clearEquipmentData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      this.editing = false
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      try {
        this.departments = await this.academicReferenceRepositoryInstance.getDepartments(
          objToPush
        )
        this.buildings = await this.infrastructureReferenceRepositoryInstance.getBuildings(
          objToPush
        )
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    selectDate() {
    },
    handleSelectedFacility() {
      // Create a shallow copy of the tempSelectedAdditionalFacility array
      const selectedFacilitiesCopy = this.tempSelectedAdditionalFacility.slice()
      // Create the SelectedAdditionalFacility array with the count property initialized to 0
      this.SelectedAdditionalFacility = selectedFacilitiesCopy.map((e) => {
        let thisFacility = this.AllFacility.find((facility) => facility.AdditionalFacilityId == e.AdditionalFacilityId)
        return { ...e, count: '', available: thisFacility.available };
      });

    },
    async uploadFiles(subCategories, index) {
      if (subCategories.attachedFiles.length === 0) return [];
      const attachedFilePromises = [];
      subCategories.maintenanceImages = []
      const attachedFiles = subCategories.attachedFiles.filter(item => item.name);
      attachedFiles.forEach(attachedFile => {
        attachedFilePromises.push(
          this.getSignedUrl(attachedFile, `equipmentFiles/${this.selectedInstitute}`)
        );
      });
      const signedUrlsData = await Promise.all(attachedFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      const uploadPromises = [];
      if (signedUrls.length === attachedFiles.length) {
        attachedFiles.forEach((attachedFile, i) => {
          uploadPromises.push(this.uploadToSpaces(attachedFile, signedUrls[i], subCategories, index));
        })
        await Promise.all(uploadPromises);
        signedUrlsData.map((item, i) => {
          subCategories.maintenanceImages.push({
            url: spacesUrl + "/" + item.fileName,
            name: attachedFiles[i].name,
            mimeType: item.mimeType
          })
        });
        this.selectedCategory.subCategories[index] = subCategories
      }
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      const signedUrl = await this.infrastructureReferenceRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    async uploadToSpaces(file, signedUrl, subCategories, index) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            subCategories.currentUploadProgress = progress;
            this.selectedCategory.subCategories[index] = subCategories
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );
      return res;
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    async selectBuilding(building, mode = "facility") {
      this.loading = true
      this.selectedBuilding = building
      this.selectedBuildingId = building.buildingId
      // setting selected Building to state
      this.$store.commit("liveData/set_selectedBuilding", {
        selectedBuilding: building.buildingId,
      });
      const objToPush = {
        instituteId: this.selectedInstitute,
        buildingId: this.selectedBuildingId,
        buildingName: this.selectedBuilding.buildingName
      }
      try {
        let tempRoomData = await this.infrastructureReferenceRepositoryInstance.getRoomsFromRoomInventory(objToPush)

        this.allRoomData = tempRoomData.filter((data) => !data?.isAdditionalFacility)
        this.allAdditionalFacilityRoomData = tempRoomData.filter((data) => data?.isAdditionalFacility)

        if (mode === 'facility') {
          return this.allRoomData
        } else if (mode === 'additional') {
          return this.allAdditionalFacilityRoomData
        } else {
          console.error("invalid Mode")
        }

      } catch (err) {
        console.error(err)
        this.allRoomData = []
      }
      this.loading = false
    },
    async createRoom(status) {
      this.getAllFacility()
      let isValidated = true
      if (this.tempRoomName === '') {
        alert("please fill Room Name")
        return
      } else {
        const objToPush = {
          buildingId: this.tempSelectedBuilding.buildingId,
          instituteId: this.tempSelectedBuilding.instituteId,
          roomName: this.tempRoomName,
          department: this.tempSelectedDepartment,
          sharingDepartments: this.tempRoomSharingDepartments,
          type: this.tempSelectedRoomType,
        }
        if (status === 'next') {
          objToPush.status = 'draft'
        } else {
          objToPush.status = status
        }
        if (this.tempSelectedEquipments.length > 0) {
          objToPush.roomEquipments = this.tempSelectedEquipments
        }
        if (this.tempSelectedFaculty) {
          objToPush.faculty = this.tempSelectedFaculty
        }
        if (this.tempSelectedFacultyDesignation) {
          objToPush.facultyDesignation = this.tempSelectedFacultyDesignation
        }
        if (this.tempSelectedFacultyEducation) {
          objToPush.facultyEducation = this.tempSelectedFacultyEducation
        }
        if (this.tempRoomArea) {
          objToPush.roomArea = this.tempRoomArea
        }
        if (this.tempUtilizationHour) {
          objToPush.utilizationHour = this.tempUtilizationHour
        }
        if (this.tempBatchSize) {
          objToPush.batchSize = this.tempBatchSize
        }
        if (this.tempSelectedsafetyMeasures.length > 0) {
          objToPush.safetyMeasures = this.tempSelectedsafetyMeasures
        }
        if (this.regulerStartTimeOfPrejectLab) {
          objToPush.regulerStartTimeOfPrejectLab = this.regulerStartTimeOfPrejectLab
        }
        if (this.regulerEndTimeOfPrejectLab) {
          objToPush.regulerEndTimeOfPrejectLab = this.regulerEndTimeOfPrejectLab
        }
        if (this.extraStartTimeOfPrejectLab) {
          objToPush.extraStartTimeOfPrejectLab = this.extraStartTimeOfPrejectLab
        }
        if (this.extraEndTimeOfPrejectLab) {
          objToPush.extraEndTimeOfPrejectLab = this.extraEndTimeOfPrejectLab
        }
        if (this.tempRoomElibraryResourses.length > 0) {
          objToPush.elibraryResourses = this.tempRoomElibraryResourses
        }
        if (this.SelectedAdditionalFacility.length > 0) {
          let afterFilter = this.SelectedAdditionalFacility.map((e) => {
            if (e.count > e.available) {
              isValidated = false
            }

            delete e.available;

            return e
          })
          objToPush.additionalFacility = afterFilter

        } else {
          objToPush.additionalFacility = []
        }

        if (!isValidated) {
          return console.error("facility count is not  valid")
        }
        if (isValidated) {
          try {
            const room = await this.infrastructureReferenceRepositoryInstance.createRoom(
              objToPush
            )
          } catch (e) {
            console.error('e', e);
          }
        }

        if (status !== 'next') {
          this.addingRoom = false
          this.selectBuilding(this.selectedBuilding)
        }
      }

    },
    addLibraryResourses() {
      this.eLibraryResourses.push(this.tempRoomAddElibraryResourse)
      this.tempRoomAddElibraryResourse = ''
    },
    addCategories() {
      this.selectedCategory.categoryName = this.tempCategoryName
      this.selectedCategory.subCategories = [{ subCategoryName: "" }]
      this.tempCategories.push({ categoryName: this.tempCategoryName })
      this.addingSubcategory = true
      this.tempCategoryName = ''
    },
    updateSubCategories(category) {
      this.selectedCategory = category
      this.addingSubcategory = true
    },
    addSubCategories() {
      let subCategories = this.selectedCategory.subCategories
      this.selectedCategory.subCategories = []
      subCategories.push({ subCategoryName: "", maintenanceFrequency: this.MaintenanceFrequency[0] })
      this.selectedCategory.subCategories = subCategories
    },
    async getEquipments() {
      const objToPush = {
        buildingId: this.tempSelectedBuilding.buildingId,
        instituteId: this.tempSelectedBuilding.instituteId,
        department: this.tempSelectedDepartment,
      }
      try {
        const categories = await this.infrastructureReferenceRepositoryInstance.getEquipmentsOfADepartment(
          objToPush
        )
        this.tempCategories = categories
      } catch (e) {
        console.error('e', e);
        this.tempCategories = []
      }
      this.getFacultiesOfDepartment()
    },
    async createEquipments() {
      const objToPush = {
        buildingId: this.tempSelectedBuilding.buildingId,
        instituteId: this.tempSelectedBuilding.instituteId,
        department: this.tempSelectedDepartment,
        ...this.selectedCategory
      }
      const categories = await this.infrastructureReferenceRepositoryInstance.createEquipments(
        objToPush
      )
      this.addingEquipments === false
      this.selectBuilding(this.selectedBuilding)
    },
    startAddingRoom() {
      if (this.tempSelectedBuilding.hasOwnProperty('buildingId')) {
        this.addingRoom = true
      } else {
        alert("Select Building First")
      }
    },
    startAddingEquipments() {
      if (this.tempSelectedBuilding.hasOwnProperty('buildingId')) {
        this.addingEquipments = true
      } else {
        alert("Select Building First")
      }
    },
    clearEquipmentData() {
      this.tempCategories = []
      this.selectedCategory = {}
      this.tempSelectedDepartment = ""
      this.addingSubcategory = false
    },
    clearRoomData() {
      this.tempRoomName = ""
      this.tempSelectedFaculty = ""
      this.tempSelectedDepartment = ""
      this.tempSelectedFacultyDesignation = ""
      this.regulerStartTimeOfPrejectLab = ""
      this.regulerEndTimeOfPrejectLab = ""
      this.extraStartTimeOfPrejectLab = ""
      this.tempSelectedFacultyEducation = ""
      this.tempRoomArea = ""
      this.tempUtilizationHour = ""
      this.tempBatchSize = ""
      this.tempRoomSharingDepartments = []
      this.tempRoomElibraryResourses = []
      this.tempSelectedsafetyMeasures = []
      this.tempSelectedAdditionalFacility = []
      this.SelectedAdditionalFacility = []
      this.tempSelectedEquipments = []
      this.extraEndTimeOfPrejectLab = ''
      this.tempSelectedRoomType = ""
      this.addingRoom = false
      this.step = 1
    },
    goToPreviousSection() {
      this.step--
    },
    goToNextSection() {
      this.step++
      this.createRoom('next')
    },
    selectEquipmentForRoom() {
      if (this.tempSelectedEquipments.filter(obj => obj.categoryName === this.tempSelectedCategory.categoryName).length > 0) {
        this.tempSelectedEquipments.map((obj) => {
          if (obj.categoryName === this.tempSelectedCategory.categoryName) {
            obj.subCategories = this.tempSelectedSubCategories
          }
        })
      } else {
        this.tempSelectedEquipments.push({
          categoryName: this.tempSelectedCategory.categoryName,
          subCategories: this.tempSelectedSubCategories
        })
      }
      this.tempSelectedCategory = {}
      this.tempSelectedSubCategories = []
      this.selectedEquipmentsDataAvailable = true
    },
    async getFacultiesOfDepartment() {
      try {
        const faculties = await this.semesterUserRepositoryInstance.getAllFacultiesOfADepartment({
          instituteId: this.tempSelectedBuilding.instituteId,
          department: this.tempSelectedDepartment,
        })
        this.deptFaculties = faculties
      } catch (e) {
        console.error('e', e);
        this.deptFaculties = []
      }
    },

    finalSelectedEquipments() {
      this.selectedEquipmentsDataAvailable = false
      this.tempSelectedCategory = {}
      this.tempSelectedSubCategories = []
      this.tempFinalSelectedEquipments = []
      this.tempSelectedEquipments.map((obj) => {
        this.tempFinalSelectedEquipments.push(obj)
      })
      this.selectedEquipmentsDataAvailable = true
    },
    selectDepartment() {
      this.loading = true
      this.getEquipments()
      this.loading = false
    },
    startEditingRoom(room) {
      this.getAllFacility()
      this.selectedRoom = room
      this.tempSelectedBuilding.buildingId = this.selectedRoom.buildingId
      this.tempSelectedBuilding.instituteId = this.selectedRoom.instituteId
      this.tempRoomName = this.selectedRoom.roomName
      this.tempSelectedDepartment = this.selectedRoom.department
      this.tempRoomSharingDepartments = this.selectedRoom.sharingDepartments
      this.tempSelectedRoomType = this.selectedRoom.type
      this.tempSelectedEquipments = this.selectedRoom.roomEquipments
      this.tempSelectedFaculty = this.selectedRoom.faculty
      this.tempSelectedFacultyDesignation = this.selectedRoom.facultyDesignation
      this.tempSelectedFacultyEducation = this.selectedRoom.facultyEducation
      this.roomArea = this.selectedRoom.tempRoomArea
      this.tempUtilizationHour = this.selectedRoom.utilizationHour
      this.tempBatchSize = this.selectedRoom.batchSize
      this.tempSelectedsafetyMeasures = this.selectedRoom.safetyMeasures
      this.regulerStartTimeOfPrejectLab = this.selectedRoom.regulerStartTimeOfPrejectLab
      this.regulerEndTimeOfPrejectLab = this.selectedRoom.regulerEndTimeOfPrejectLab
      this.extraStartTimeOfPrejectLab = this.selectedRoom.extraStartTimeOfPrejectLab
      this.extraEndTimeOfPrejectLab = this.selectedRoom.extraEndTimeOfPrejectLab
      this.tempRoomElibraryResourses = this.selectedRoom.elibraryResourses
      this.tempSelectedAdditionalFacility = this.selectedRoom.additionalFacility ? this.selectedRoom.additionalFacility.map((e) => {
        return { AdditionalFacilityId: e.AdditionalFacilityId, facilityName: e.facilityName };
      }) : [];

      this.SelectedAdditionalFacility = this.selectedRoom.additionalFacility ? this.selectedRoom.additionalFacility.map((e) => {
        let availableFacility = this.AllFacility.find((f) => f.AdditionalFacilityId === e.AdditionalFacilityId);

        return { ...e, available: availableFacility ? availableFacility.available : false };
      }) : [];

      this.selectedEquipmentsDataAvailable = true
      this.addingRoom = true
      this.getEquipments()
    },
    async deleteRoom(room) {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        roomId: room.roomId,
        roomName: room.roomName,
        buildingId: this.selectedBuilding.buildingId
      }
      try {
        await this.infrastructureReferenceRepositoryInstance.deleteRoom(
          objToPush
        )
        showStatus('Room deleted successfully.', 1000, 'success', this)
        const roomIndex = this.selectedRooms.findIndex(
          roomObject => roomObject.roomId === room.roomId
        )
        if (roomIndex > -1) {
          this.selectedRooms = arrayOps.removeFromArray(
            this.selectedRooms,
            roomIndex
          )
        }
      } catch (err) {
        showStatus('Failed to delete room.', 1000, 'error', this)
        console.error(err)
      }
      this.selectedRooms = []
      this.selectBuilding(this.selectedBuilding)
      this.loading = false
    },
    async addingRoomStep1() {
      this.tempRoomName = ''
      this.tempRoomDepartment = ''
      this.tempRoomSpecialRoom = false
      this.tempRoomMeetingRoom = false
      this.tempRoomConferenceHall = false
      this.tempRoomOffice = false
      this.tempRoomPracticalLab = false
      this.tempRoomClassRoom = false
      this.addingRoom = true
    },
    async removeMultipleRooms() {
      for (let i = 0; i < this.selectedRooms.length; i++) {
        await this.deleteRoom(this.selectedRooms[i])
      }
      this.selectedRooms = []
    },
    async addBuilding() {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        buildingName: this.tempBuildingName
      }
      try {
        const building = await this.infrastructureReferenceRepositoryInstance.createBuilding(
          objToPush
        )
        this.buildings.push(building)
        this.tempBuildingName = ''
        this.addingBuilding = false
        this.selectBuilding(building)
        showStatus('Building created successfully.', 1000, 'success', this)
      } catch (err) {
        showStatus('Failed to create building.', 1000, 'error', this)
        console.error(err)
      }
      this.loading = false
    },
    async startDeletingBuilding(building) {
      this.tempBuildingSelectedForDeleting = building
      this.showBuildingDeletingDialog = true
    },
    async deleteBuilding(building) {
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute,
        buildingId: building.buildingId,
        buildingName: building.buildingName
      }
      try {
        await this.infrastructureReferenceRepositoryInstance.deleteBuilding(
          objToPush
        )
        const buildingIndex = this.buildings.findIndex(
          buildingObject => buildingObject.buildingId === building.buildingId
        )
        if (buildingIndex > -1) {
          this.buildings = arrayOps.removeFromArray(
            this.buildings,
            buildingIndex
          )
        }
        this.tempBuildingName = ''
        this.addingBuilding = false
        this.showBuildingDeletingDialog = false
        showStatus('Building deleted successfully.', 1000, 'success', this)
      } catch (err) {
        showStatus('Failed to delete building.', 1000, 'error', this)
        console.error(err)
      }
      this.loading = false
    },
    gotoactivityInstitutesManagment() {
      this.$router.push('/activityInstitutesManagment')
    },
    gotoAdditionalFacility() {
      this.$router.push('/activityAdditionalFacility')
    },
    async getAllFacility() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute
        };
        const response = await this.additionalFacilityReferenceInstance.getAllFacility(objToPush);

        const countResponse = await this.additionalFacilityReferenceInstance.getAllUsedFacilityCount(objToPush);

        let facility = response.data
        let additionalFacilityData = countResponse.data
        let finalResult = []
        for (let i = 0; i < facility.length; i++) {
          let fac = facility[i];
          let data = additionalFacilityData.find(
            (e) => e.AdditionalFacilityId === fac.AdditionalFacilityId
          );
          if (data) {
            let available = fac.quantity - data.totalCount;
            finalResult.push({ ...fac, available: available });
          } else {
            finalResult.push({ ...fac, available: fac.quantity });
          }
        }
        this.AllFacility = finalResult
      } catch (error) {
        console.log("errOnGettingData", error);
      }
    },
    addFacility() {
      if (!this.selectedBuildingId) {
        // alert('Please select a building')
        showStatus('Please select a building', 1000, 'warning', this)
        return
      }
      this.$router.push('/addFacility')
    },
    addAdditionalFacilityFacility() {
      if (!this.selectedBuildingId) {
        // alert('Please select a building')
        showStatus('Please select a building', 1000, 'warning', this)
        return
      }
      this.$router.push('/addAdditionalFacility')
    },

    async filterTable1() {
      let dummyRoomData = await this.selectBuilding(this.selectedBuilding, "facility")
      let afterFilter = dummyRoomData.filter((data) => this.selectedFilterRoomType1 ? data.roomType === this.selectedFilterRoomType1 : true).map(
        (data) => {
          let tempRooms = data.rooms.filter((room) => this.searchValue1 ? room.roomName.toLowerCase().startsWith(this.searchValue1.toLowerCase()) : true)
          return { ...data, rooms: tempRooms }
        })
      //  setting table data after Filter
      this.allRoomData = afterFilter
    },
    async filterTable2() {
      let dummyRoomData = await this.selectBuilding(this.selectedBuilding, "additional")
      let afterFilter = dummyRoomData.filter((data) => this.selectedFilterRoomType2 ? data.roomType === this.selectedFilterRoomType2 : true).map(
        (data) => {
          let tempRooms = data.rooms.filter((room) => this.searchValue2 ? room.roomName.toLowerCase().startsWith(this.searchValue2.toLowerCase()) : true)
          return { ...data, rooms: tempRooms }
        })

      //  setting table data after Filter
      this.allAdditionalFacilityRoomData = afterFilter
    },



    removeFilter1() {
      this.selectedFilterRoomType1 = null
      this.selectBuilding(this.selectedBuilding)
    },
    removeFilter2() {
      this.selectedFilterRoomType2 = null
      this.selectBuilding(this.selectedBuilding)
    },

    editRoomByCategory(roomInventoryId) {
      this.$store.commit("liveData/set_selectedEditingRoom", {
        editingRoomInventory: this.allRoomData.find((data) => data.roomInventoryId === roomInventoryId),
      });
      this.addFacility()
    },
    editAdditionalRoomByCategory(roomInventoryId) {
      this.$store.commit("liveData/set_selectedEditingRoom", {
        editingRoomInventory: this.allAdditionalFacilityRoomData.find((data) => data.roomInventoryId === roomInventoryId),
      });
      this.addAdditionalFacilityFacility()
    },
    toggleRoomDeleting(roomInventoryId) {
      if (!roomInventoryId) {
        return console.error("roomInventoryId required")
      }
      this.roomInfraDeleteModel = true
      this.deletingInventoryRoomId = roomInventoryId ?? ''
    },
    async deleteRoomByCategory() {
      let roomInventoryId = this.deletingInventoryRoomId
      if (!roomInventoryId) {
        return console.error("roomInventoryId required")
      }
      try {
        await this.infrastructureReferenceRepositoryInstance.deleteRoom(
          { roomInventoryId: roomInventoryId }
        )
        showStatus('Room deleted successfully.', 1000, 'success', this)
        this.selectBuilding(this.selectedBuilding)
      } catch (err) {
        showStatus('Failed to delete room.', 1000, 'error', this)
        console.error(err)
      } finally {
        this.roomInfraDeleteModel = false
        this.deletingInventoryRoomId = ''
      }

    },
    handleRoomEdit(roomData, room) {

      this.currentViewingRoom = { roomData, infraRoom: room }
      this.viewRoomModal = true
    },
    downloadPDF() {
      html2pdf(this.$refs.roomsData, {
        filename: 'roomDetails.pdf',
        enableLinks: true,
        image: { type: 'jpg', quality: 0.98 },
        html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 600 },
        jsPDF: { unit: 'in', orientation: 'portrait', format: 'a4' },
      })
    },



    async getBuildingSummeryValues(tempRoomData) {

      if (!tempRoomData || !Array.isArray(tempRoomData)) {
        return
      }
      try {
        const tempRoomsCount = tempRoomData.reduce((count, data) => {
          if (!data?.isAdditionalFacility) {
            return count + (data.rooms || []).length;
          }
          return count;
        }, 0);


        const tempAdditionalFacilityCount = tempRoomData.reduce((count, data) => {
          if (data?.isAdditionalFacility) {
            return count + (data.rooms || []).length;
          }
          return count;
        }, 0);


        let totalStudentsCount = 0;
        let totalEquipmentsCount = 0;

        tempRoomData?.forEach((data) => {
          totalStudentsCount += data.rooms.reduce(
            (acc, room) => acc + parseInt(room?.batchSize ?? 0),
            0
          );
        });
        totalEquipmentsCount = tempRoomData
          ?.flatMap((data) => data.rooms || [])
          .reduce((count, room) => {
            const hardwareCount = (room.hardware || []).reduce(
              (acc, hardware) => acc + parseInt(hardware.hardwareQuantity || 0),
              0
            );
            const softwareCount = (room.software || []).reduce(
              (acc, software) => acc + parseInt(software.softwareLicenseQuantity || 0),
              0
            );
            return count + hardwareCount + softwareCount;
          }, 0);


        this.summaryItemsBox = [
          { title: 'Number of Departments', value: this.departments?.length ?? 0 },
          { title: 'Number of Rooms', value: tempRoomsCount ?? 0 },
          { title: 'Number of Equipment', value: totalEquipmentsCount ?? 0 },
          { title: 'Number of Students', value: totalStudentsCount ?? 0 },
          { title: 'Number of Additional Facility', value: tempAdditionalFacilityCount ?? 0 },
        ];
      } catch (err) {
        console.error(err);
      }
    },
    async getBuildingChartAndDepartmentTableValues(tempRoomData) {

      if (!tempRoomData || !Array.isArray(tempRoomData)) {
        return
      }
      try {
        // presetValues for chart = 
        let tempRoomsCounts = []
        let tempAdditionalFacilityCounts = []
        let tempStudentsCounts = []
        let equipmentsCounts = []


        let tempDepartments = this.departments
        let tempRooms = tempRoomData.filter((data) => !data?.isAdditionalFacility);
        let tempAdditionalFacility = tempRoomData.filter((data) => data?.isAdditionalFacility)


        // roomCount
        tempRoomsCounts = tempDepartments.map((department) => {
          let totalRoomCount = 0;
          tempRooms.forEach((element) => {
            if (Array.isArray(element.rooms)) {
              totalRoomCount += element.rooms.reduce((count, room) => {
                return count + (room.department === department ? 1 : 0);
              }, 0);
            }
          });
          return totalRoomCount;
        });

        // // additionalFacilityCount
        tempAdditionalFacilityCounts = tempDepartments.map((department) => {
          let totalRoomCount = 0;
          tempAdditionalFacility.forEach((element) => {
            if (Array.isArray(element.rooms)) {
              totalRoomCount += element.rooms.reduce((count, room) => {
                return count + (room.department === department ? 1 : 0);
              }, 0);
            }
          });
          return totalRoomCount;
        });

        // // studentsCount
        tempStudentsCounts = tempDepartments.map((department) => {
          let studentsCount = 0;
          studentsCount += tempRoomData
            ?.flatMap((data) => data.rooms || []).filter((room) => room.department === department).reduce(
              (acc, room) => acc + parseInt(room?.batchSize ?? 0),
              0
            )
          return studentsCount;
        });

        // // equipmentsCount
        equipmentsCounts = tempDepartments.map((department) => {
          let studentsCount = 0;
          studentsCount += tempRoomData
            ?.flatMap((data) => data.rooms || []).filter((room) => room.department === department).reduce((count, room) => {
              const hardwareCount = (room.hardware || []).reduce(
                (acc, hardware) => acc + parseInt(hardware.hardwareQuantity || 0),
                0
              );
              const softwareCount = (room.software || []).reduce(
                (acc, software) => acc + parseInt(software.softwareLicenseQuantity || 0),
                0
              );
              return count + hardwareCount + softwareCount;
            }, 0)
          return studentsCount;
        });


        // setting chart data
        this.dashboardChartSeries = [
          {
            name: 'Number of Rooms',
            data: tempRoomsCounts
          }, {
            name: 'Number of equipment',
            data: equipmentsCounts
          }, {
            name: 'Number of students',
            data: tempStudentsCounts
          }, {
            name: 'Number of additional facilities',
            data: tempAdditionalFacilityCounts,
          }]
        this.dashboardChartOptions = {
          ...this.dashboardChartOptions, xaxis: {
            categories: this.departments
          }
        }



        // setting values for the table

        let dummyTableValues = ['Number of Rooms', 'Number of Equipment', 'Number of students', 'Number of additional facilities'].map((sideHeader, index) => {
          let total = 0;
          let tempObj = {};

          switch (index) {
            case 0:
              total = tempRoomsCounts.reduce((a, b) => a + b, 0) || 0;
              tempObj = { sideHeader, values: tempRoomsCounts, total };
              break;
            case 1:
              total = equipmentsCounts.reduce((a, b) => a + b, 0) || 0;
              tempObj = { sideHeader, values: equipmentsCounts, total };
              break;
            case 2:
              total = tempStudentsCounts.reduce((a, b) => a + b, 0) || 0;
              tempObj = { sideHeader, values: tempStudentsCounts, total };
              break;
            case 3:
              total = tempAdditionalFacilityCounts.reduce((a, b) => a + b, 0) || 0;
              tempObj = { sideHeader, values: tempAdditionalFacilityCounts, total };
              break;
            default:
              break;
          }

          return tempObj;
        });

        this.departMentTableHeading = ['Description', ...this.departments, 'Total']
        this.departMentTableData = dummyTableValues ?? []



        let tempRoomWrapperObj = tempRoomData.filter((obj) => (Array.isArray(obj.sharingDepartments) && obj.sharingDepartments.length))
        let tempTableData = []
        tempRoomWrapperObj.forEach((element) => {
          let rooms = element.rooms ?? []
          rooms.forEach((room) => {
            let sharingDepartMents = this.departments?.map((department) => element.sharingDepartments?.includes(department) ? true : false)
            let tempRoomObj = { roomName: room.roomName, sharingDepartMents, }
            tempTableData.push(tempRoomObj)
          })
        }
        )
        this.sharingDepartmentHeading = ['Room Name', ...this.departments]
        this.sharingDepartmentTableData = tempTableData ?? []
      } catch (err) {
        console.error(err);
      }
    },
    // gettingBuildingBasedValues
    async getBuildingBasedValues() {
      const objToPush = {
        instituteId: this.tempSelectedBuildingForDashBoardData.instituteId,
        buildingId: this.tempSelectedBuildingForDashBoardData.buildingId,
        buildingName: this.tempSelectedBuildingForDashBoardData.buildingName,
      };

      try {
        let tempRoomData = await this.infrastructureReferenceRepositoryInstance.getRoomsFromRoomInventory(
          objToPush
        );
        await this.getBuildingSummeryValues(tempRoomData)
        await this.getBuildingChartAndDepartmentTableValues(tempRoomData)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>
<style scoped src="./activityReferenceInfrastructure.css"></style>
