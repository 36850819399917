<template src='./activityInvigilatorDashboard.html'></template>

<script>
import ExamRepository from "../../../Repository/Exam";
import SemesterRepository from "../../../Repository/Semester";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import InstituteRepository from "../../../Repository/Institute";
import inputContainer from "../../../Components/inputContainer";
import NbaRepository from "../../../Repository/NBA";
import apiV3 from "../../../NetworkManager/apiV3";
import showStatus from "../../../NetworkManager/showStatus";
import SubjectRepository from "../../../Repository/Subject";
import UserRepository from "../../../Repository/User";
import moment from "moment";
import html2pdf from "html2pdf.js";
// import { sortByDate } from "../../../Services/Utils/arrayOps"
import {
  courseYearNumbersAbbr
} from "../../../Constants/Utils/Statics";
// import { PDFDocument, StandardFonts, degrees, rgb } from 'pdf-lib';

export default {
  name: "activityInvigilatorDashboard",
  components: {
    inputContainer,
  },
  props: ["PropExamInfo", "prop_pageNumber"],
  data() {
    return {
      hallTicketFormat: {},
      previewFormat: false,
      finalStudentInfoDetails: [],
      instituteInfo: {},
      num: 3,
      endDate: "",
      startDate: "",
      date: "",
      menu2: false,
      isStudentDataAvailable: false,
      e1: 1,
      e2: 0,
      subjectDetails: [],
      examSeatTable: [],
      studentHeader: [
        {
          text: "Sr.No",
          align: "start",
          width: "100px",
          value: "index",
        },
        {
          text: "Full name",
          align: "start",
          width: "200px",
          value: "firstName",
        },
        {
          text: "Last name",
          align: "start",
          width: "200px",
          value: "lastName",
        },
        {
          text: "PRN",
          align: "start",
          width: "100px",
          value: "collegePRNNo",
        },
      ],
      dataLoader: false,
      downloadPDFLoader: false,
      currentSemester: "",
      currentCourseYear: "",
      currentDepartment: "",
      tableHeaders: [
        {
          text: "Sr No.",
          value: "srNo",
        },
        {
          text: "Student Name",
          value: "name",
        },
        {
          text: "Fees paid status",
          value: "feeStatus",
          sortable: false,
        },
        {
          text: "Approval status",
          value: "approvalStatus",
          sortable: false,
        },
        {
          text: "Details",
          value: "details",
          align: "center",
        },
      ],
      tableItems: [],
      uniqueStudentUIdsOfCurrentExam: [],
      studentExamFormDetails: [],
      examFormStudentName: "",
      statusEditable: true,
      subjectInfo: [],
      studentDetailsDialog: false,
      currentStudentForExamFormDetails: {},
      examFormLoader: false,
      examFormInstructions: "",
      examDetailsLength: 0,
      errorMessages: [],
      errorMessageSnackbar: false,
      hallTicketFormatPresent: false,
      examFormFormatPresent: false,
      checkAllFeeStatus: false,
      checkAllApprovalStatus: false,
      currentSemesterName: '',
      officialSignaturePresent: false,
      collegeStampUrl: "",
      collegeStampName: "",
      isStmiras: false,
      modifiedSemesterName: "",
      isMima: false,
      modifiedExamDate: ""
    };
  },
  async created() {
    this.dataLoader = true;
    this.$store.commit("liveData/set_selectedActivityName", "Exam Details");
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    const sem = this.$store.getters["instituteData/get_selectedExamSemester"];
    this.userData = this.$store.getters["user/get_userData"];
    this.examRepositoryInstance = new ExamRepository(this);
    this.InstituteRepositoryInstance = new InstituteRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.SemesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.UserRepositoryInstance = new UserRepository(this);
    this.subjectDetails = this.PropExamInfo.subjectDetails;
    this.startDate = this.PropExamInfo.startDate;
    this.currentCourseYear = this.PropExamInfo.criteria.courseYear;
    this.currentDepartment = this.PropExamInfo.criteria.department;
    this.currentSemester = sem.semName;
    this.endDate = this.PropExamInfo.endDate;
    this.date = this.startDate;
    this.modifiedExamDate = moment(this.PropExamInfo.startDate).format('DD-MM-YYYY')
    await this.getSemesterNames();
    this.getUIdsOfStudentsInAnExam();
    this.validateErrors();
    if (this.selectedInstitute === "6604982c-cc08-4e00-81fb-a375a10b72e4") {
      this.isStmiras = true
    }
    if (this.selectedInstitute === "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b") {
      this.isMima = true
    }
    await this.getBatches()
    // this.filterByDate()
    if (this.prop_pageNumber) {
      this.e2 = this.prop_pageNumber;
    }
  },
  methods: {
    gotoExams() {
      this.$router.push("activityExams1");
    },
    validateErrors() {
      for (let i = 0; i < this.PropExamInfo.subjectDetails.length; i++) {
        const element = this.PropExamInfo.subjectDetails[i];
        if (!element.roomStatus) {
          this.errorMessages.push(
            `Room is not present for ${element.subjectName}.`
          );
        }
        if (element.selectedDate === "") {
          this.errorMessages.push(
            `Date is not present for ${element.subjectName}.`
          );
        }
        if (element.selectedTime === "") {
          this.errorMessages.push(
            `Time is not present for ${element.subjectName}.`
          );
        }
        if (element.applicableStudents) {
          this.errorMessages.push(
            `Students are not present for ${element.subjectName}.`
          );
        }
      }
      if (this.errorMessages.length > 0) {
        this.errorMessageSnackbar = true;
      }
    },
    async getSubjectDetails(subjectIds) {
      this.subjectInfo = await this.subjectRepositoryInstance.getSubjects({
        instituteId: this.selectedInstitute,
        semId: this.PropExamInfo.semId,
        subjectIds: subjectIds,
      });
    },
    async getSemesterNames() {
      const sems = await this.semesterRepositoryInstance.getSemestersBySemId({
        instituteId: this.selectedInstitute,
        semId: this.PropExamInfo.semId,
      })
      this.currentSemesterName = sems[0].semName ? sems[0].semName : '-'
    },
    async getUIdsOfStudentsInAnExam() {
      const subjectIds = this.PropExamInfo.applicableSubjects;
      this.uniqueStudentUIdsOfCurrentExam = [];
      if (this.PropExamInfo.examFormPublished) {
        this.uniqueStudentUIdsOfCurrentExam = this.PropExamInfo.studentsAllowedToFillExamForm !== null
          ? this.PropExamInfo.studentsAllowedToFillExamForm
          : [];
      } else {
        for (let j = 0; j < this.PropExamInfo.subjectDetails.length; j++) {
          const subject = this.PropExamInfo.subjectDetails[j];
          if (subject.totalStudents) {
            for (let k = 0; k < subject.totalStudents.length; k++) {
              const student = subject.totalStudents[k];
              if (this.uniqueStudentUIdsOfCurrentExam.indexOf(student) === -1) {
                this.uniqueStudentUIdsOfCurrentExam.push(student);
              }
            }
          }
        }
      }
      await this.getSubjectDetails(subjectIds);
      await this.getStudentsDetails();
    },
    handleTableState() {
      this.statusEditable = !this.statusEditable;
    },
    handleFeeStatus() {
      this.studentExamFormDetails.map((student) => {
        student.feeStatus = this.checkAllFeeStatus;
      });
    },
    checkForAllFeeStatus() {
      let tempCheckCount = 0;
      this.studentExamFormDetails.map((item) => {
        if (item.feeStatus) tempCheckCount++;
      });
      if (tempCheckCount === this.studentExamFormDetails.length) {
        this.checkAllFeeStatus = true;
      } else {
        this.checkAllFeeStatus = false;
      }
    },
    checkForAllApprovalStatus() {
      let tempCheckCount = 0;
      this.studentExamFormDetails.map((item) => {
        if (item.approvalStatus) tempCheckCount++;
      });
      if (tempCheckCount === this.studentExamFormDetails.length) {
        this.checkAllApprovalStatus = true;
      } else {
        this.checkAllApprovalStatus = false;
      }
    },
    handleApprovalStatus() {
      this.studentExamFormDetails.map((student) => {
        student.approvalStatus = this.checkAllApprovalStatus;
      });
    },
    convertToAMPM(time) {
      if (time) {
        let hours = parseInt(time.substr(0, 2));
        let minutes = time.substr(3, 2);
        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12; // Convert midnight (0) to 12

        let formattedTime = hours + ":" + minutes + " " + ampm;
        return formattedTime;
      } else {
        return "-";
      }
    },

    examSeatValue(data, student) {
      if (data === "PRN number") {
        return student.prnNo;
      }
      if (data === "Exam seat number") {
        return student.examNo;
      }
      if (data === "Roll Number") {
        return student.rollNo;
      }
      if (data === "ABC ID") {
        return student.abcIdNumber;
      }
      if (data === "Gr. No.") {
        return student.grNumber;
      }
      if (data === "PUN code") {
        return "-";
      }
      if (data === "College code") {
        return "-";
      }
      if (data === "Center code") {
        return "-";
      }
      if (data === "Semester") {
        return student.semester;
      }
      // stu.rollNo = semData.rollNumber
      //         stu.examNo = semData.examSeatNumber
    },
    async getBatches() {
      const data = await this.nbaRepositoryInstance.getBatchesOfInstitute(
        {
          lmsInstituteId: this.selectedInstitute,
        }
      )
      console.log('data', data);
    },
    tableValueSetUp(data, index, arr) {
      if (data === "Department") {
        return arr.department;
      }
      if (data === "Course Year") {
        return arr.courseYear;
      }
      if (data === "Type of Exam") {
        return arr.typeOfExam;
      }
      if (data === "Semester") {

        return arr.semName;
      }
      if (data === "Subject Code") {
        return arr.subjectCode !== undefined ? arr.subjectCode : "-";
      }
      if (data === "Subject Name") {
        return arr.subjectName;
      }
      if (data === "Exam Day") {
        if (arr.date) {
          const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          let date1 = new Date(arr.date);
          let dayName = daysOfWeek[date1.getDay()];
          return dayName;
        } else {
          // this.errorMessages.push('Missing Subject Exam Date.')
          return "-";
        }
      }
      if (data === "Exam Date") {
        if (arr.date) {
          return arr.date;
        } else {
          return "-";
        }
      }
      if (data === "Exam Time") {
        return (
          this.convertToAMPM(arr.startTime) +
          " - " +
          this.convertToAMPM(arr.endTime)
        );
      }
    },
    getDayOfTheDate(date) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let date1 = new Date(date);
      let dayName = daysOfWeek[date1.getDay()];
      return dayName;
    },
    removeDuplicates(arr, property) {
      return arr.filter((element, index, self) => {
        // Find the index of the first occurrence of the element
        const firstIndex = self.findIndex((e) => e["uId"] === element["uId"]);

        // Filter out the element if it is not the first occurrence
        return index === firstIndex;
      });
    },
    async prepareDataForHallTicket() {
      try {
        this.instituteInfo =
          await this.InstituteRepositoryInstance.getInstituteInformation({
            instituteId: this.selectedInstitute,
          });
        if (this.instituteInfo.examFormFormat) {
          this.examFormFormatPresent = true;
        }
        if (this.instituteInfo.hallTicketFormat) {
          this.hallTicketFormatPresent = true;
          this.hallTicketFormat = this.instituteInfo.hallTicketFormat
          if (this.hallTicketFormat.signaturePhoto.length > 0) {
            this.officialSignaturePresent = true
          }
          if (this.hallTicketFormat.isCollageStampNeeded) {
            this.collegeStampUrl = this.hallTicketFormat.collageStampPhoto[0].url
            this.collegeStampName = this.hallTicketFormat.collageStampPhoto[0].name
          }
          // let temp = []
          if (!Array.isArray(this.hallTicketFormat.signatureName)) {
            this.hallTicketFormat.signatureName = [this.hallTicketFormat.signatureName]
          }
        } else {
          this.hallTicketFormat = {}
        }
        this.examFormInstructions = this.instituteInfo.examFormFormat
          ? this.instituteInfo.examFormFormat.formInstructions
          : "";
        // this.hallTicketFormat = this.instituteInfo.hallTicketFormat
        //   ? this.instituteInfo.hallTicketFormat
        //   : {};
        if (this.hallTicketFormat.examDetails) {
          this.examDetailsLength = this.hallTicketFormat.examDetails.length;
        }

        this.examSeatTable = [];
        if (this.hallTicketFormat.studentDetails) {
          if (this.hallTicketFormat.studentDetails.length > 0) {
            this.hallTicketFormat.studentDetails.map((data) => {
              if (
                data !== "Student photo" &&
                data !== "Full Name" &&
                data !== "Mother's Name"
              ) {
                this.examSeatTable.push(data);
              }
            });
          }
        }

        let studentListForExam = [];
        let totalStudentListForExam = [];
        let finalStudentInfo = [];
        this.PropExamInfo.subjectDetails.map((sub) => {
          totalStudentListForExam = [
            ...totalStudentListForExam,
            ...sub.totalStudentsDetails,
          ];
          // sub.totalStudentsDetails
          if (sub.totalStudentsDetails) {
            sub.totalStudentsDetails.map((stu) => {
              if (!studentListForExam.includes(stu.uId)) {
                studentListForExam.push(stu.uId);
              }
            });
          }
        });
        let studentSemData = [];
        if (studentListForExam.length > 0) {
          studentSemData =
            await this.SemesterUserRepositoryInstance.getExamSeatNumberAndRollNumberOfMultipleSemUser(
              {
                instituteId: this.selectedInstitute,
                semId: this.PropExamInfo.semId,
                uIds: studentListForExam,
              }
            );
        }
        studentListForExam.map((stu) => {
          totalStudentListForExam.map((details) => {
            // console.log('details>>>>>', details);
            if (stu === details.uId) {
              let name = details.firstName;
              if (details.middleName !== "") {
                name = name + " " + details.middleName;
              }
              name = name + " " + details.lastName;
              let obj = {
                fullName: name,
                profilePhoto: details.profilePicture,
                prnNo: details.collegePRNNo ? details.collegePRNNo : "-",
                motherName: details.motherName ? details.motherName : "-",
                examDetails: [],
                uId: details.uId,
                abcIdNumber: details.abcIdNumber,
                grNumber: details.grNumber,
                semester: this.currentSemesterName
              };
              finalStudentInfo.push(obj);
            }
          });
        });
        const uniqueArray = this.removeDuplicates(finalStudentInfo, "name");
        if (this.PropExamInfo.subjectDetails.length > 0) {
          const [initial,] = this.currentCourseYear.split(' ')
          const [prefix, middle , postfix] = this.currentSemesterName.split(' ')
          const tempSemNum = middle.split('/')
          this.modifiedSemesterName = `${prefix} ${ tempSemNum[courseYearNumbersAbbr[initial]-1]} ${postfix}`
          this.PropExamInfo.subjectDetails.map((sub) => {
            uniqueArray.map((stu) => {
              if (sub.totalStudents) {
                if (sub.totalStudents.includes(stu.uId)) {
                  let tempType = ''
                  if (sub.reExamStudentDetails) {
                    let result = sub.reExamStudentDetails.filter(item => item.uId === stu.uId)[0]
                    if (result) {
                      if (result.external && result.internal) {
                        tempType = 'CIE(Internal) & SEE(External)'
                      } else if (result.internal) {
                        tempType = 'CIE(Internal)'
                      } else if (result.external) {
                        tempType = 'SEE(External)'
                      }
                    }
                  } else {
                    tempType = this.PropExamInfo.type
                  }
                  let obj = {
                    subjectName: sub.subjectName,
                    startTime: sub.selectedTime,
                    endTime: sub.endExamTime,
                    date: sub.selectedDate ? moment(sub.selectedDate).format('DD-MM-YYYY') : '',
                    subjectCode: sub.subjectCode,
                    typeOfExam: tempType,
                    semName: this.isStmiras ? this.modifiedSemesterName : this.currentSemesterName,
                    department: this.PropExamInfo.criteria.department,
                    courseYear: this.PropExamInfo.criteria.courseYear,
                    examDay: this.getDayOfTheDate(sub.selectedDate),
                    timeFrame: this.convertToAMPM(sub.selectedTime) +
                      " - " +
                      this.convertToAMPM(sub.endExamTime),

                  };
                  stu.examDetails.push(obj);
                }
              }
              this.sortByDate(stu.examDetails, 'date', 'asc')
            });
          });
        }
        uniqueArray.map((stu) => {

          studentSemData.map((semData) => {
            if (stu.uId === semData.uId) {
              stu.rollNo = semData.rollNumber;
              stu.examNo =
                semData.examSeatNumber === "" ||
                  semData.examSeatNumber === undefined
                  ? "-"
                  : semData.examSeatNumber;
            }
          });
        });
        //   console.log('courseYearNumbers', courseYearNumbersAbbr);
        // console.log('this.currentCourseYear', this.currentCourseYear);
        // console.log('this.currentSemester', this.currentSemester);
        // const courseYearNumber = this.selectedBatch.batch.filter(item => item.courseYear === this.selectedCourseYear)[0].courseYearNumber
        this.sortByNumericValueInAscendingOrder(uniqueArray, 'rollNo') // Pass the array you want to sort and the field name by which the array will be sorted
        this.finalStudentInfoDetails = [];
        this.finalStudentInfoDetails = uniqueArray
        this.dataLoader = false;
      } catch (error) {
        console.error(error);
      }
    },
    sortByDate(array, fieldToSortBy, order) {
      function compareDates(a, b) {
        const dateA = new Date(a[fieldToSortBy].split('-').reverse().join('-'));
        const dateB = new Date(b[fieldToSortBy].split('-').reverse().join('-'));
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      }
      return array.sort(compareDates);
    },
    sortByAlphaNumericValue(array, fieldToSortBy) {
      array.sort((a, b) => {
        if (a[fieldToSortBy] === null || b[fieldToSortBy] === null || a[fieldToSortBy] === undefined || b[fieldToSortBy] === undefined) {
          // If either fieldToSortBy is null, move the element with null fieldToSortBy to the end.
          if (a[fieldToSortBy] === null || a[fieldToSortBy] === undefined) return 1;
          if (b[fieldToSortBy] === null || b[fieldToSortBy] === undefined) return -1;
        }
        return a[fieldToSortBy].localeCompare(b[fieldToSortBy]); // Compare non-null fieldToSortBy strings
      })
      return array
    },
    sortByNumericValueInAscendingOrder(array, fieldToSortBy) {
      array.sort((a, b) => a[fieldToSortBy] - b[fieldToSortBy]);
      return array
    },
    async generateStudentHallticket() {
      this.downloadPDFLoader = true;
      this.instituteInfo['examName'] = this.PropExamInfo.name
      this.instituteInfo['date'] = this.modifiedExamDate;

      // const [initial, ] = this.currentCourseYear.split(' ')
      // const [prefix, , postfix] = this.currentSemesterName.split(' ')
      // const modifiedSemesterName = `${prefix} ${courseYearNumbersAbbr[initial]} ${postfix}`
      // this.finalStudentInfoDetails.map((student) => {
      //   student.examDetails.map((subject) => {
      //     subject.typeOfExam = this.PropExamInfo.type
      //     subject.semName = this.isStmiras ? this.modifiedSemesterName : this.currentSemesterName;
      //     subject.examDay = this.getDayOfTheDate(subject.date);
      //     subject.type = this.PropExamInfo.type;
      //     subject.semester = this.getDayOfTheDate(subject.date);
      //     subject.timeFrame =
      //       this.convertToAMPM(subject.startTime) +
      //       " - " +
      //       this.convertToAMPM(subject.endTime);
      //     subject.department = this.PropExamInfo.criteria.department,
      //       subject.courseYear = this.PropExamInfo.criteria.courseYear
      //   });
      // });
      let objToPush = {
        instituteData: { ...this.instituteInfo, isMima: this.isMima },
        studentData: this.finalStudentInfoDetails,
      };
      console.log('objToPush', objToPush);
      try {
        const result = await apiV3.postRequest(
          "/manageExam/generateStudentHallTicket",
          objToPush
        );
        if (result) {
          window.open(result.data.pdfUrl);
        }
      } catch (error) {
        showStatus("Error generating student hall tickets.", 9000, "error", this);
        console.log('error', error);
      }
      this.downloadPDFLoader = false;
    },
    filterByDate() {
      if (this.date !== "") {
        let dummyInfo = this.PropExamInfo.subjectDetails;
        let filterArr = [];
        dummyInfo.map((data) => {
          if (data.selectedDate === this.date) {
            filterArr.push(data);
          }
        });
        this.subjectDetails = filterArr;
      }
      // this.fetchData();
    },
    formatDateToYYYYMMDD(inputDate) {
      // Split the input date string by hyphens
      const parts = inputDate.split("-");
      // Create a new Date object with the parsed year, month (subtract 1 as months are 0-indexed), and day
      const date = new Date(parts[2], parts[1] - 1, parts[0]);
      // Extract the year, month, and day from the Date object
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month (0-indexed) and pad with '0' if necessary
      const day = String(date.getDate()).padStart(2, "0"); // Pad day with '0' if necessary

      // Combine the components into the desired format
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    async fetchData() {
      this.getStudentsDetails();

      // await this.roomAllocationGetAllData()
    },
    downloadPDF() {
      html2pdf(this.$refs.examRoom, {
        margin: [0, -1],
        filename: "document.pdf",
        enableLinks: true,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { useCORS: true, windowWidth: 1300, windowHeight: 700 },
        jsPDF: { unit: "in", orientation: "portrait", format: "a4" },
      });
    },
    downloadPDFHallTicket() {
      html2pdf(this.$refs.hallTicket, {
        margin: [0, 0],
        filename: "hallTicket.pdf",
        enableLinks: true,
        image: { type: "jpeg", quality: 1, maxHeight: 600, maxWidth: 800 },
        html2canvas: { useCORS: true, windowWidth: 1300, windowHeight: 700 },
        jsPDF: {
          unit: "in",
          orientation: "portrait",
          format: "a4",
          compress: true,
          minify: { forcePDFJS: true },
        },
      });
    },

    async getStudentsDetails() {
      if (this.uniqueStudentUIdsOfCurrentExam.length > 0) {

        try {
          const res = await this.UserRepositoryInstance.getUsers({
            uIds: this.uniqueStudentUIdsOfCurrentExam,
          });
          console.log('res', res);
          this.studentExamFormDetails = [];

          for (let i = 0; i < this.uniqueStudentUIdsOfCurrentExam.length; i++) {
            const uId = this.uniqueStudentUIdsOfCurrentExam[i];

            const instituteId = this.selectedInstitute;
            const semId = this.currentSemester;
            const courseYear = this.currentCourseYear;
            const department = this.currentDepartment;
            const studentPersonalDetails = res.filter(
              (item) => item.uId === uId
            )[0];
            // let studentApprovals = {}
            // let tempInfo =
            const studentApprovals = this.PropExamInfo?.examFormDetails?.filter(
              (item) => item.uId === uId
            )[0];
            let fullName = ""
            let email = ""
            let contactNumber = ""
            if (studentPersonalDetails) {
              fullName =
                studentPersonalDetails.firstName +
                " " +
                studentPersonalDetails.middleName +
                " " +
                studentPersonalDetails.lastName;
              email = studentPersonalDetails.email
                ? studentPersonalDetails.email
                : "-";
              contactNumber =
                studentPersonalDetails.mobile !== 0
                  ? studentPersonalDetails.mobile
                  : "-";
            }

            const studentSubjects = [];
            for (let i = 0; i < this.subjectDetails.length; i++) {
              const element = this.subjectDetails[i];

              const subjectDetail = this.subjectInfo.filter(
                (item) => item.subjectId === element.subjectId
              )[0];
              const currentExamSubjectDetail =
                this.PropExamInfo.subjectDetails.filter(
                  (item) => item.subjectId === element.subjectId
                )[0];
              let objToPush = {};
              // if(currentExamSubjectDetail.totalStudents) {
              //   if (currentExamSubjectDetail.totalStudents.includes(uId)) {
              objToPush["subjectName"] = subjectDetail ? subjectDetail.subjectName : '-';
              objToPush["subjectCode"] = subjectDetail ? subjectDetail.subjectCode : "-";
              // }
              studentSubjects.push(objToPush);
              // }
            }

            let objToPush = {
              name: fullName,
              uId,
              email,
              contactNumber,
              courseYear,
              department,
              semId,
              instituteId,
              studentSubjects,
              feeStatus: studentApprovals ? studentApprovals.feeStatus : false,
              approvalStatus: studentApprovals
                ? studentApprovals.approvalStatus
                : false,
            };
            this.studentExamFormDetails.push(objToPush);
          }

          this.subjectDetails.map((e) => {
            e["totalStudentsDetails"] = res;
          });
          let array = [...this.subjectDetails];
          this.roomAllocationGetAllData(array);
        } catch (err) {
          console.error(err);
        }
      } else {
        showStatus("No students are selected yet for the exam form or in subject details.", 5000, "error", this);
        this.dataLoader = false;
      }
    },
    async updateExamFormDetails() {
      this.examFormLoader = true;
      let studentArray = [];
      for (let index = 0; index < this.studentExamFormDetails.length; index++) {
        const student = this.studentExamFormDetails[index];
        studentArray.push({
          uId: student.uId,
          feeStatus: student.feeStatus,
          approvalStatus: student.approvalStatus,
        });
      }
      let objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.PropExamInfo.semId,
        examId: this.PropExamInfo.examId,
        attemptNumber: this.PropExamInfo.attemptNumber,
        examFormDetails: studentArray,
        examFormPublished: this.PropExamInfo.examFormPublished,
        isForNBA: this.PropExamInfo.isForNBA,
        questionDetails: this.PropExamInfo.questionDetails,
      };
      if (this.PropExamInfo.isForNBA) {
        objToPush.nbaAttainment = this.PropExamInfo.nbaAttainment;
      }
      if (this.PropExamInfo.examFormPublished) {
        objToPush.studentsAllowedToFillExamForm =
          this.PropExamInfo.studentsAllowedToFillExamForm;
      }
      // if(this.PropExamInfo.examFormDetails) {
      //   objToPush.examFormDetails = this.PropExamInfo.examFormDetails
      // }
      this.statusEditable = true;
      try {
        await apiV3.postRequest("/manageExam/updateBasicDetails", objToPush);
        showStatus("Details Updated Successfully.", 5000, "success", this);
      } catch (error) {
        showStatus("Error updating details.", 9000, "error", this);
      }
      this.examFormLoader = false;
    },
    handleStudentDetailDialog(student) {
      this.studentDetailsDialog = true;
      this.currentStudentForExamFormDetails = student;
    },
    async roomAllocationGetAllData(item) {
      for (let i = 0; i < item.length; i++) {
        const subDetails = item[i];
        if (subDetails.roomAllocation) {
          subDetails.roomAllocation.forEach(async (roomAllocation) => {
            subDetails.roomNames.map((roomInfo) => {
              if (roomAllocation.roomName === roomInfo.roomId) {
                roomAllocation.roomWithName = roomInfo.roomName;
              }
            });

            let facultyWithNames = [];
            roomAllocation.appliedFaculties.map((facultyUid) => {
              facultyWithNames.push(facultyUid);
            });

            try {
              const res = await this.UserRepositoryInstance.getUsers({
                uIds: facultyWithNames,
              });
              roomAllocation.facultyWithNamesForUse = res;
            } catch (err) {
              console.error(err);
            }

            roomAllocation.studentWithPrn = [];

            let i = 0;
            await subDetails.totalStudentsDetails.map((allStu) => {
              if (roomAllocation.appliedStudents.includes(allStu.uId)) {
                allStu.index = i + 1;
                roomAllocation.studentWithPrn.push(allStu);
              }
            });
          });
        }
        if (subDetails.selectedDate && subDetails.selectedTime) {
          let oldDateObj = subDetails.selectedDate;
          let totalDuration = Number(subDetails.duration);

          let newDateObj = new Date(
            ...oldDateObj.split("-").reverse(),
            ...subDetails.selectedTime.split(":"),
            0
          );
          let time = moment(newDateObj).add(totalDuration, "m").toDate();
          let endTime = time.toString().split(" ")[4].toString().slice(0, 5);
          subDetails.endExamTime = endTime;
        }
      }
      setTimeout(() => {
        this.isStudentDataAvailable = true;
      }, 500);
      await this.prepareDataForHallTicket();
    },
  },
};
</script>

<style scoped src='./activityInvigilatorDashboard.css'></style>