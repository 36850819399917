<template src="./activityHome.html"></template>
<script>
import auth from '../../Services/Firebase/auth'
import state from '../../Store/Modules/liveData'
import NotificationRepository from '../../Repository/Notification'
import NetworkManager from '../../NetworkManager'
import InstituteAPIs from '../../ApiNames/Institute'
import PortalFooter from '../../Components/PortalFooter'
import { AcademicManagement, allowedModule } from '../../Services/Utils/allowedModule'
// import { sanityClient } from '../../utils/SanityClient'
// import { sanityClient } from '../../utils/SanityClient'
import VuePortableText from '@bizurd/vue-portable-text'
// import Shepherd from 'shepherd.js';
import showStatus from '../../NetworkManager/showStatus'
import UserRepository from '../../Repository/User'

export default {
  name: 'activityHome',
  components: {
    PortalFooter,
    VuePortableText
  },
  data() {
    return {
      guideFilterArray: [],
      allowedModelNames: [],
      activityNumber: null,
      currentRouterName: '',
      selectedActivity: 1,
      userData: null,
      user: null,
      logoutDialog: false,
      state: state,
      show: false,
      selectedInstitute: '',
      formLink: '',
      brandingLogo: '',
      brandingName: '',
      isFormModalOpen: false,
      selectedSemester: {},
      allowedModuleLiveData: [],
      finalNavBarDetails: [],
      //name taken from  allowedModule file
      navBarDetails: [
        {
          id: 1,
          // name: 'My Timeline',
          logo: 'mdi-animation',
          logoForClick: 'mdi-animation-outline',
          activity: 'gotoTimeline',
          hasListItem: false
        },
        {
          id: 2,
          // name: 'Institute Management',
          logo: 'mdi-city-variant',
          logoForClick: 'mdi-city-variant-outline',
          activity: 'gotoInstitutesManagment',
          hasListItem: false
        },
        {
          id: 3,
          // name: 'Academic Management',
          logo: 'mdi-cog',
          logoForClick: 'mdi-cog-outline',
          activity: 'gotoSemestersManagment',
          hasListItem: false
        },
        {
          id: 4,
          // name: 'Manage Users',
          logo: 'mdi-account-multiple',
          logoForClick: 'mdi-account-multiple-outline',
          activity: 'gotoManageUsers',
          hasListItem: false
        },
        {
          id: 5,
          // name: 'Lectures Dashboard',
          logo: 'mdi-human-male-board',
          logoForClick: 'mdi-human-male-board',
          activity: 'gotoDashboard',
          hasListItem: false
        },
        {
          id: 6,
          // name: 'Manage Exams',
          logo: 'mdi-fountain-pen',
          logoForClick: 'mdi-fountain-pen',
          activity: 'gotoExams',
          hasListItem: false
        },
        {
          id: 7,
          // name: 'Attendance Analysis',
          logo: 'mdi-account-clock-outline',
          logoForClick: 'mdi-account-clock-outline',
          activity: 'gotoAttendanceAnalysis',
          hasListItem: false
        },

        {
          id: 8,
          // name: 'Cumulative Attendance',
          logo: 'mdi-file-chart-outline',
          logoForClick: 'mdi-file-chart-outline',
          activity: 'gotoCumulativeAttendance',
          hasListItem: false
        },
        {
          id: 9,
          // name: 'Feedback Analysis',
          logo: 'mdi-message-star-outline',
          logoForClick: 'mdi-message-star-outline',
          activity: 'gotoTopicFeedbackAnalysis',
          hasListItem: false
        },
        {
          id: 10,
          // name: 'Syllabus Tracker',
          logo: 'mdi-book-open-page-variant',
          logoForClick: 'mdi-book-open-page-variant-outline',
          activity: 'gotoSyllabusTracking',
          hasListItem: false
        },
        {
          id: 11,
          // name: 'Manage assignments',
          logo: 'mdi-clipboard-text-outline',
          logoForClick: 'mdi-clipboard-text-outline',
          activity: 'gotoManageAssignments',
          hasListItem: false
        },
        {
          id: 12,
          // name: 'Manage Study Material',
          logo: 'mdi-bookshelf',
          logoForClick: 'mdi-bookshelf',
          activity: 'gotoManageStudyMaterial',
          hasListItem: false
        },
        {
          id: 13,
          // name: 'Admission Module',
          logo: 'mdi-account-plus-outline',
          logoForClick: 'mdi-account-plus-outline',
          activity: 'gotoAdmission',
          hasListItem: false
        },


        {
          id: 14,
          // name: 'Search Students',
          logo: 'mdi-account-search-outline',
          logoForClick: 'mdi-account-search-outline',
          activity: 'gotoStudentProfile',
          hasListItem: false
        },
        {
          id: 15,
          // name: 'Search Faculties',
          logo: 'mdi-magnify',
          logoForClick: 'mdi-magnify',
          activity: 'gotoFacultyProfile',
          hasListItem: false
        },
        {
          id: 16,
          // name: 'Manage Quick Test',
          logo: 'mdi-note-edit',
          logoForClick: 'mdi-note-edit',
          activity: 'gotoQuickTest',
          hasListItem: false
        },
        {
          id: 17,
          // name: 'Feedback Form',
          logo: 'mdi-form-select',
          logoForClick: 'mdi-form-select',
          activity: 'gotoFeedbackForm',
          hasListItem: false
        },
        {
          id: 18,
          // name: 'Courses',
          logo: 'mdi-book-outline',
          logoForClick: 'mdi-book-outline',
          activity: 'gotoCourses',
          hasListItem: false
        },
        {
          id: 19,
          // name: 'Media Library',
          logo: 'mdi-folder-multiple-image',
          logoForClick: 'mdi-folder-multiple-image',
          activity: 'gotoMediaLibrary',
          hasListItem: false
        },
        {
          id: 20,
          // name: 'Media Library',
          logo: 'mdi-human-male-board',
          logoForClick: 'mdi-human-male-board',
          activity: 'gotoUniversityEvents',
          hasListItem: false
        },

      ],
      currentRoute: "",
      imagesArray: [],
      videosArray: [],
      pdfsArray: [],
      studiumSaysArr: [],
      expertGuidelinesArr: [],
      fbDialog: false,
      fbDialogRichText: false,
      fbSelectedContent: {},
      fbSelectedContentType: '',
      fbContentBtnVisible: false,
      loadingSanityData: false,
      floatingButtonVisible: false,
      noSanityContent: false,
      selectedInstituteName: '',
      showAddPostDialog: false,
      userInfo: {},
      fullName: '',
      email: '',
      phone: '',
      userName: '',
      pageURL: '',
      userDetails: {}
    }
  },
  created() {
    this.userRepositoryInstance = new UserRepository(this)
    this.allowedModelNames = allowedModule
    console.log('allowedModule.length', allowedModule.length);
    this.notificationRepositoryInstance = new NotificationRepository(this)
    this.user = this.$store.getters['user/get_user']
    const isSubAdmin = this.$store.getters['liveData/get_isSubAdmin'].isSubAdmin
    console.log('isSubAdmin', isSubAdmin);
    this.navBarDetails.map((data,i) => {
      data.name = allowedModule[i]
    })
    if (isSubAdmin === true) {
      this.allowedModuleLiveData = this.$store.getters['liveData/get_allowedModule']
      console.log('this.allowedModuleLiveData', this.allowedModuleLiveData);
      this.allowedModuleLiveData.map((data, i) => {
        if (data.isAccess === true) {
          this.finalNavBarDetails.push(this.navBarDetails[i])
        }
      })
    } else {
      this.finalNavBarDetails = this.navBarDetails
    }
    console.log('this.finalNavBarDetails', this.finalNavBarDetails);
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedInstituteName = this.$store.getters[
      'instituteData/get_instituteName'
    ]
    this.fetchBrandingDetails(this.selectedInstitute)
    this.userInformation()
  },
  watch: {
    $route(to, from) {
      this.fbContentBtnVisible = false;
    },
    '$route'() {
      this.pageURL = window.location.href;
      this.pageName = this.pageURL.substring(this.pageURL.lastIndexOf('/') + 1);
    }
  },
  methods: {
    async fetchSanityData() {
      this.currentRoute = this.$route.path;
      this.loadingSanityData = true;
      if (this.fbContentBtnVisible === true) {
        this.fbContentBtnVisible = false;
        this.loadingSanityData = false
      } else {
        try {
          const query = `*[_type == 'lmsAdmin' && routeTitle == '${this.currentRoute}']{
            'images': Images[]{'url':file.asset->url,'title':title},
            'videos': Videos[]{'url':file.asset->url,'title':title},
            'pdfs': Pdfs[]{'url':file.asset->url,'title':title},
            'studiumSays':studiumSays[],
            'expertGuidelines':expertGuidelines[],
          }`;
          // TEMPORARILY COMMENTED

          // const data = await sanityClient.fetch(query)
          if (data.length === 0) {
            this.loadingSanityData = false;
            this.noSanityContent = true;
            showStatus("No Information Available", 9000, "error", this);
          } else {
            const { images, pdfs, videos, studiumSays, expertGuidelines } = data[0];
            this.imagesArray = [];
            this.imagesArray = images;
            this.pdfsArray = pdfs;
            this.videosArray = videos;
            this.studiumSaysArr = studiumSays;
            this.expertGuidelinesArr = expertGuidelines;
            this.fbContentBtnVisible = !this.fbContentBtnVisible;
            this.loadingSanityData = false
          }
        } catch (error) {
          console.error("error fetching sanity", error)
        }
      }
    },
    openFbDialog(content, type) {
      this.fbSelectedContent = content;
      this.fbSelectedContentType = type;
      this.fbDialog = true
    },
    closeFbDialog() {
      this.fbSelectedContent = {}
      this.fbDialog = false
    },
    setActivity(mod) {
      this.activityNumber = mod.id
      // `this.${mod.activity}()`
    },
    handleFunctionCall(functionName) {
      this[functionName]()
    },
    activity() {
      this.$tours['activityTimeline'].start()
    },
    async fetchBrandingDetails(instituteId) {
      try {
        const data = await NetworkManager.getRequest(
          InstituteAPIs.getInstituteBranding,
          { instituteId }
        )

        this.brandingName = data.instituteBrandingName
        this.brandingLogo = data.instituteLogoLink
      } catch (error) {
        console.log({ error })
      }
    },
    async fetchData() {
      // Call API to get todays notifications
      const objToPush = {
        instituteId: '',
        semId: '',
        uId: ''
      }
      try {
        this.notifications = await this.notificationRepositoryInstance.getTodaysNotifications(
          objToPush
        )
      } catch (err) {
        this.notifications = []
        console.log(err)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    gotoMyProfile() {
      this.$store.commit('liveData/set_selectedActivity', 1)
      this.$router.push('/activityProfile')
    },
    gotoInstituteBasicDetails() {
      this.$store.dispatch('liveData/set_selectedActivity', 2)
      this.$router.push('/activityInstitutesBasicDetails')
    },
    gotoReferenceAcademicDetails() {
      this.$store.dispatch('liveData/set_selectedActivity', 3)
      this.$router.push('/activityReferenceAcademicDetails')
    },
    gotoReferenceInfrastructure() {
      this.$store.dispatch('liveData/set_selectedActivity', 4)
      this.$router.push('/activityReferenceInfrastructure')
    },
    gotoReferenceShiftDetails() {
      this.$store.dispatch('liveData/set_selectedActivity', 5)
      this.$router.push('/activityReferenceShiftDetails')
    },
    gotoManageRoles() {
      this.$store.dispatch('liveData/set_selectedActivity', 6)
      this.$router.push('/activityManageRoles')
    },
    gotoViewSemesters() {
      this.$store.dispatch('liveData/set_selectedActivity', 7)
      this.$router.push('/activityViewSemesters')
    },
    gotoCreateSemester() {
      this.$store.dispatch('liveData/set_selectedActivity', 8)
      this.$router.push('/activityCreateSemester')
    },
    gotoDashboard() {
      this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push('/activityDashboard')
    },
    gotoExams() {
      this.$store.dispatch('liveData/set_selectedActivity', 10)
      this.$router.push('/activityExams1')
    },
    gotoAttendanceAnalysis() {
      this.$store.dispatch('liveData/set_selectedActivity', 11)
      this.$router.push('/activityAttendanceAnalysis')
    },
    gotoArrangeLecture() {
      this.$store.dispatch('liveData/set_selectedActivity', 12)
      this.$router.push('/activityArrangeLecture')
    },
    gotoArrangeLectureBySubject() {
      this.$store.dispatch('liveData/set_selectedActivity', 29)
      this.$router.push('/activityArrangeLectureBySubject')
    },
    gotoManageHoliday() {
      this.$store.dispatch('liveData/set_selectedActivity', 13)
      this.$router.push('/activityManageHoliday')
    },
    gotoSyllabusTracking() {
      this.$store.dispatch('liveData/set_selectedActivity', 14)
      this.$router.push('/activitySyllabusTracking')
    },
    gotoQuickTest() {
      this.$store.dispatch('liveData/set_selectedActivity', 24)
      this.$router.push('/activityQuickTest')
    },
    gotoManageAssignments() {
      this.$store.dispatch('liveData/set_selectedActivity', 29)
      this.$router.push('/activityManageAssignments')
    },
    gotoManageStudyMaterial() {
      this.$store.dispatch('liveData/set_selectedActivity', 25)
      this.$router.push('/activityManageStudyMaterial')
    },
    gotoNotifications() {
      this.show = false
      this.$store.dispatch('liveData/set_selectedActivity', 15)
      this.$router.push('/activityNotifications')
    },
    gotoManageUsers() {
      this.show = false
      this.$store.dispatch('liveData/set_selectedActivity', 16)
      this.$router.push('/activityManageUsers')
    },
    gotoTimeline() {
      this.$store.commit('liveData/set_selectedActivity', 17)
      this.$router.push('/activityTimeline')
    },
    gotoManageSMS() {
      this.$store.commit('liveData/set_selectedActivity', 18)
      this.$router.push('/activityManageSMS')
    },
    gotoManageTopicFeedbackTemplates() {
      this.$store.commit('liveData/set_selectedActivity', 19)
      this.$router.push('/activityManageTopicFeedbackTemplates')
    },
    gotoTopicFeedbackAnalysis() {
      this.$store.commit('liveData/set_selectedActivity', 20)
      this.$router.push('/activityTopicFeedbackAnalysis')
    },
    gotoNBAModule() {
      this.$store.dispatch('liveData/set_selectedActivity', 21)
      this.$router.push('/activityNBAModule')
    },
    gotoNBAextended() {
      this.$store.dispatch('liveData/set_selectedActivity', 22)
      this.$router.push('/activityNBAextended')
    },
    gotoAdmission() {
      this.$store.dispatch('liveData/set_selectedActivity', 23)
      this.$router.push('/activityAdmission')
    },
    gotoInstitutesManagment() {
      this.$store.dispatch('liveData/set_selectedActivity', 26)
      this.$router.push('/activityInstitutesManagment')
    },
    gotoCumulativeAttendance() {
      this.$store.dispatch('liveData/set_selectedActivity', 31)
      this.$router.push('/activityCumulativeAttendance')
    },
    gotoSemestersManagment() {
      this.$store.dispatch('liveData/set_selectedActivity', 27)
      this.$router.push('/activitySemestersManagment')
    },
    gotoStudentProfile() {
      this.$store.dispatch('liveData/set_selectedActivity', 28)
      this.$router.push('/activityStudentProfile')
    },
    gotoFacultyProfile() {
      this.$store.dispatch('liveData/set_selectedActivity', 30)
      this.$router.push('/activityFacultyProfile')
    },
    gotoMediaLibrary() {
      this.$store.dispatch('liveData/set_selectedActivity', 32)
      this.$router.push('/activityMediaLibrary')
    },
    gotoCourses() {
      this.$store.dispatch('liveData/set_selectedActivity', 33)
      this.$router.push('/activityCourses')
    },
    gotoUniversityEvents() {
      this.$store.dispatch('liveData/set_selectedActivity', 34)
      this.$router.push('/activityUniversityEvents')
    },
    // gotoCoursesRevamped(){
    //   this.$store.dispatch('liveData/set_selectedActivity', 33)
    //   this.$router.push('/activityCourses2')
    // },
    gotoactivityEditSemesterDates() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterDates')
    },
    gotoactivityEditSemesterHolidays() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterHoliday')
    },
    gotoactivityEditSemesterTimetable() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterTimetable')
    },
    gotoactivityEditSemesterShifts() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterShifts')
    },
    gotoactivityEditSemesterBatches() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterBatches')
    },
    gotoactivityEditSemesterUsers() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterUsers')
    },
    gotoactivityEditSemesterClassteacher() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterClassteacher')
    },
    gotoactivityEditFacultyAllocation() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditFacultyAllocation')
    },
    gotoactivityEditStudentsRegistration() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditStudentsRegistration')
    },
    gotoactivityEditSemesterSubjects() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterSubjects')
    },
    gotoactivityEditSemesterSubjects() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterSubjects')
    },
    gotoactivityEditSemesterSubjects1() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityEditSemesterSubjects1')
    },
    gotoactivityManageHoliday() {
      this.$store.dispatch('liveData/set_selectedActivity')
      this.$router.push('/activityManageHoliday')
    },
    gotoactivityInstitutesManagment() {
      this.$store.dispatch('liveData/set_selectedActivity',)
      this.$router.push('/activityInstitutesManagment')
    },
    gotoReferenceDetails() {
      this.$store.dispatch('liveData/set_selectedActivity',)
      this.$router.push('/activityReferenceDetails')
    },
    gotoChangeInstitute() {
      this.$router.push('/activitySelectInstituteNSemester')
    },
    // gotoMediaLibrary() {
    //   this.$store.dispatch('liveData/set_selectedActivity',)
    //   this.$router.push('/activityMediaLibrary')
    // },
    // gotoCourses() {
    //   this.$store.dispatch('liveData/set_selectedActivity')
    //   this.$router.push('/activityCourses')
    // },
    // gotoReferenceDetails() {
    //   this.$store.dispatch('liveData/set_selectedActivity',)
    //   this.$router.push('/activityTakeAttendance')
    // },
    gotoFeedbackForm() {
      this.$store.dispatch('liveData/set_selectedActivity', 34)
      this.$router.push('/activityFBForm')
    },

    // openFeedbackForm() {
    //   this.isFormModalOpen = true
    //   const FORM_VIEWER = new URL('https://feedback-form-studium.vercel.app/admin')
    //   FORM_VIEWER.searchParams.set('institute_id', this.selectedInstitute)
    //   FORM_VIEWER.searchParams.set('u_id', this.userData.uId)
    //   this.formLink = FORM_VIEWER
    // },

    openCourses() {
      this.$router.push('/activityCourses', 28)
    },

    openFeedbackForm() {
      this.isFormModalOpen = true
      const FORM_VIEWER = new URL('https://feedback-form-studium.vercel.app/admin')
      FORM_VIEWER.searchParams.set('institute_id', this.selectedInstitute)
      FORM_VIEWER.searchParams.set('u_id', this.userData.uId)
      this.formLink = FORM_VIEWER
    },

    // openCourses() {
    //   this.$router.push('/activityCourses', 28)
    // },

    logout() {
      this.logoutDialog = false
      this.$store.commit('user/reset')
      this.$store.commit('instituteData/reset')
      this.$store.commit('liveData/reset')
      zsResetWebForm('114490000001358156')
      const user = window.localStorage.getItem("userKey")
      const userDetails = window.localStorage.getItem("userDetails")
      if (user && userDetails) {
        window.localStorage.removeItem('userKey')
        window.localStorage.removeItem('userDetails')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    },
    async userInformation() {
      try {
        let objToPush = { uId: this.userData.uId };
        const [userInfo, emailsAndPhones] = await Promise.all([
          this.userRepositoryInstance.getUserProfile(objToPush),
          this.userRepositoryInstance.getUserAuthentication(objToPush)
        ]);

        const { userName = '-', firstName = '-', middleName = '-', lastName = '-' } = userInfo;
        const { emails, phones } = emailsAndPhones;
        const email = emails && emails.length > 0 ? emails[0] : '-';
        const phone = phones && phones.length > 0 ? phones[0] : '-';

        this.userInfo = userInfo;
        this.userName = userName;
        this.fullName = firstName + ' ' + middleName + ' ' + lastName;

        this.email = email;
        this.phone = phone;

        this.userDetails = {
          userName: this.userName,
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          brandingName: this.brandingName,
        };
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>
<style scoped src="./activityHome.css"></style>
