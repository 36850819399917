export default {
getAssignmentsOfADivisionOfASubject: '/assignment/getAssignmentsOfADivisionOfASubject',
getAssignmentById: '/assignment/getAssignmentById',
getAssignmentsByAFaculty: '/assignment/getAssignmentsByAFaculty',
getAssignmentsByAFacultyForASubject: '/assignment/getAssignmentsByAFacultyForASubject',
getAssignmentsByAFacultyForASubjectForAGroup: '/assignment/getAssignmentsByAFacultyForASubjectForAGroup',
getAssignmentsBySubject: '/assignment/getAssignmentsBySubject',
createAssignment: '/assignment/createAssignment',
updateAssignment: '/assignment/updateAssignment',
getAssignmentsByAFacultyForASubjectForADivision: '/assignment/getAssignmentsByAFacultyForASubjectForADivision',
generateAssignmentReport: '/excel/assignment/generateAssignmentReport',
deleteAssignment: '/assignment/deleteAssignment'
}