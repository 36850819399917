<template src="./examForm.html"></template>

<script>
import SubjectRepository from "../../../Repository/Subject";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import UserAuthenticationRepository from "../../../Repository/UserAuthentication";
import ExamFormAndHallTicketRepository from "../../../Repository/ExamFormAndHallTicket";
import InstituteRepository from "../../../Repository/Institute";
import showStatus from "../../../NetworkManager/showStatus";
import apiV3 from "../../../NetworkManager/apiV3";
import {
  generateJsonFromExcel,
  createExcelThroughJsonAndSchema,
} from "../../../utils/excel";
import auth from "../../../Services/Firebase/auth";
import { courseYearNumbersAbbr } from "../../../Constants/Utils/Statics";
export default {
  name: "examForm",
  data() {
    return {
      primaryColor: "#050D92",
      secondaryColor: "#FF4F1F",
      progressIndicator: 12,
      examTabs: ["Student List", "Exam Form"],
      tabs: "",
      headers: [
        { text: "Sr. No", value: "srNo", align: "center" },
        { text: "PRN", value: "collegePRNNo", align: "center" },
        { text: "Roll. No", value: "rollNumber", align: "center" },
        { text: "Student Name", value: "studentName", align: "center" },
        {
          text: "Accept Exam Form",
          value: "isExamFormAccepted",
          align: "center",
          sortable: false,
        },
        {
          text: "Remarks",
          value: "examFormRemark",
          align: "center",
          width: "20%",
        },
        { text: "View Exam Form", value: "viewExamForm", align: "center" },
      ],
      headersStudent: [
        { text: "Year/sem", value: "semester", align: "center" },
        { text: "Subject Code", value: "subjectCode", align: "center" },
        { text: "Subject Name", value: "subjectName", align: "center" },
      ],
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedInstitute: "",
      selectedSemester: null,
      examId: null,
      courseYearStudents: [],
      search: "",
      examFormTableData: [],
      remarksItem: ["Exam fee not paid", "college fee not paid", "others"],
      examFormDialog: false,
      selectedStudent: {},
      emailIdsOfStudents: [],
      exam: {},
      subjects: [],
      subjectsData: [],
      fetchedData: [],
      instituteInfo: {},
      examTabDependecies: {},
      // examFormAndHallTicketInstance: false,
      dowloadLoader: false,
      acceptAllExamFormCheckbox: false,
      loading: true,
      isSubjectsDataAvailable: true,
      examFormExcelFile: null,
      schema: {},
      uploadExamFormTableViaExcel: false,
      examFormSubjectsSortOn: "subjectCode",
      isStmiras: false,
      isRiims: false,
    };
  },
  activated() {
    // Your logic here
    let newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      if (value["examFormTab"] === true) {
        flag = true;
        value["examFormTab"] = false;
      }
    });
    if (flag === true) {
      this.initialize();
    }
  },
  async created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.userAuthenticationInstance = new UserAuthenticationRepository(this);
    this.examFormAndHallTicketInstance = new ExamFormAndHallTicketRepository(
      this
    );
    this.InstituteRepositoryInstance = new InstituteRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getStudentsOfACourseYear();
      await this.getUsersDetails();
      await this.getUsersEmailId();
      await this.getStudentsExamForm();
      await this.getExam();
      await this.fetchSubjects();
      await this.getSubjectData();
      await this.getInstituteCustomizationFromFirebase();
      if (this.selectedInstitute === "6604982c-cc08-4e00-81fb-a375a10b72e4") {
        this.isStmiras = true;
      }
      if (this.selectedInstitute === "2939ed29-4649-4043-8498-f4eb3b0eac9e") {
        this.isRiims = true;
      }
      if (this.selectedInstitute === "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b") {
        this.isMima = true;
      }
      this.prepareExamFormTable();
      this.calculateProgressIndicator();
      await this.temp();
      this.checkAllSelected();
      this.examTabDependecies =
        this.$store.getters["liveData/get_examTabDependencies"];
      // state['tab3'].isTriggered = true;
      this.handleData();
      this.loading = false;
    },
    async getStudentsOfACourseYear() {
      try {
        this.courseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          });
      } catch (error) {
        this.courseYearStudents = [];
      }
    },
    async getUsersDetails() {
      let studentIds = this.courseYearStudents.map((student) => student.uId);
      const data = await this.userRepositoryInstance.getUsers({
        uIds: studentIds,
      });
      this.userDetails = data;
    },
    async getUsersEmailId() {
      // emailIdsOfStudents
      let studentIds = this.courseYearStudents.map((student) => student.uId);
      let allEmails =
        await this.userAuthenticationInstance.getUserAuthenticationsByUIds({
          uIds: studentIds,
        });
      let filteredEmails = [];
      allEmails.forEach((item, index) => {
        const { uId } = item;
        filteredEmails[uId] = item;
      });
      this.emailIdsOfStudents = Object.values(filteredEmails);
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId,
        };
        let res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async fetchSubjects() {
      if (this.exam.subjectIds.length > 0) {
        const res = await this.subjectRepositoryInstance.getSubjects({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: this.exam.subjectIds,
        });
        this.subjects = res;
      } else {
        this.isSubjectsDataAvailable = false;
      }
    },
    checkAllSelected() {
      this.acceptAllExamFormCheckbox = this.examFormTableData.every(
        (item) => item.isExamFormAccepted
      );
    },
    async getSubjectData() {
      let response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds,
          }
        );
      if (response.result.length === 0) {
        this.isSubjectsDataAvailable = false;
        return;
      }
      // let subjectsData = response.result
      let temp = [];
      let modifiedSemesterName = this.selectedSemester.semName;
      let romanNumerals = [
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ];
      if (this.isStmiras === true) {
        const [initial] = this.selectedCourseYear?.split(" ");
        const [prefix, middle, postfix] =
          this.selectedSemester.semName?.split(" ");
        const tempSemNum = middle?.split("/");
        modifiedSemesterName = `${prefix} ${
          tempSemNum[courseYearNumbersAbbr[initial] - 1]
        } ${postfix}`;
      }
      if (this.isRiims === true) {
        const [initial] = this.selectedCourseYear?.split(" ");
        const [prefix, middle, postfix] =
          this.selectedSemester.semName?.split(" ");
        const tempSemNum = middle?.split("/");
        let convertedRomanNumber =
          romanNumerals[
            parseInt(tempSemNum[courseYearNumbersAbbr[initial] - 1]) - 1
          ];
        modifiedSemesterName = `${prefix} ${convertedRomanNumber} ${postfix}`;
      }
      this.subjects.forEach((subject) => {
        let data = response.result.find(
          (item) => subject.subjectId == item.subjectId
        );
        temp.push({
          subjectCode: subject.subjectCode,
          subjectName: subject.subjectName,
          allocatedStudents: data.allocatedStudents,
          semester: modifiedSemesterName,
          credits: subject.credits,
        });
      });
      this.subjectsData = this.sortByAlphaNumericValue(
        temp,
        this.examFormSubjectsSortOn
      );
    },
    async getInstituteCustomizationFromFirebase() {
      const result = await auth.getInstituteCustomizationFromFirebase(
        this.selectedInstitute
      );
      if (result.examFormSubjectsSortOn) {
        this.examFormSubjectsSortOn = result.examFormSubjectsSortOn;
      }
    },
    sortByAlphaNumericValue(array, fieldToSortBy) {
      const newArray = [...array];
      newArray.sort((a, b) => {
        if (
          a[fieldToSortBy] === null ||
          b[fieldToSortBy] === null ||
          a[fieldToSortBy] === undefined ||
          b[fieldToSortBy] === undefined
        ) {
          // If either fieldToSortBy is null, move the element with null fieldToSortBy to the end.
          if (a[fieldToSortBy] === null || a[fieldToSortBy] === undefined)
            return 1;
          if (b[fieldToSortBy] === null || b[fieldToSortBy] === undefined)
            return -1;
        }
        return a[fieldToSortBy].localeCompare(b[fieldToSortBy]); // Compare non-null fieldToSortBy strings
      });
      return newArray;
    },
    // sortSubjects(){
    //   this.subjectsData.sort((a, b) => {
    //   const subjectNameA = a.subjectCode.toLowerCase();
    //   const subjectNameB = b.subjectCode.toLowerCase();
    //   if (subjectNameA < subjectNameB) {
    //     return -1;
    //   }
    //   if (subjectNameA > subjectNameB) {
    //     return 1;
    //   }
    //   return 0;
    // });
    //   },
    prepareExamFormTable() {
      let temp = [];
      this.courseYearStudents.forEach((student, index) => {
        let userDetails = this.userDetails.find(
          (user) => student.uId === user.uId
        );
        let userEmail = this.emailIdsOfStudents.find(
          (user) => student.uId === user.uId
        )
          ? this.emailIdsOfStudents.find((user) => student.uId === user.uId)
              .email
          : "";
        let examFormDataForStudent = this.fetchedData.find(
          (item) => item.uId === student.uId
        );
        let subData = this.subjectsData.filter((subject) =>
          subject.allocatedStudents?.includes(student.uId)
        );
        let modifiedSemesterName = this.selectedSemester.semName;
        let romanNumerals = [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ];
        if (this.isStmiras === true) {
          const [initial] = this.selectedCourseYear?.split(" ");
          const [prefix, middle, postfix] =
            this.selectedSemester.semName?.split(" ");
          const tempSemNum = middle?.split("/");
          modifiedSemesterName = `${prefix} ${
            tempSemNum[courseYearNumbersAbbr[initial] - 1]
          } ${postfix}`;
        }
        if (this.isRiims === true) {
          const [initial] = this.selectedCourseYear?.split(" ");
          const [prefix, middle, postfix] =
            this.selectedSemester.semName?.split(" ");
          const tempSemNum = middle?.split("/");
          let convertedRomanNumber =
            romanNumerals[
              parseInt(tempSemNum[courseYearNumbersAbbr[initial] - 1]) - 1
            ];
          modifiedSemesterName = `${prefix} ${convertedRomanNumber} ${postfix}`;
        }
        temp.push({
          rollNumber: student.rollNumber,
          examSeatNumber: student.examSeatNumber,
          collegePRNNo: userDetails.collegePRNNo,
          studentName: userDetails.firstName + " " + userDetails.lastName,
          isExamFormAccepted: examFormDataForStudent
            ? examFormDataForStudent.isExamFormAccepted
            : true,
          examFormRemark: examFormDataForStudent
            ? examFormDataForStudent.examFormRemark
            : null,
          contactNumber: userDetails.mobile,
          userEmail: userEmail,
          uId: student.uId,
          gender: userDetails.gender,
          category: userDetails.category,
          isChanged: examFormDataForStudent?.isExamFormAccepted ? false : true,
          subjects: subData,
          semester: modifiedSemesterName,
        });
      });
      // this.sortByNumericValueInAscendingOrder(uniqueArray, 'rollNo') // Pass the array you want to sort and the field name by which the array will be sorted

      this.examFormTableData = this.sortByAlphaNumericValue(temp, "rollNumber");
    },
    handleExamFormDialog(item) {
      this.examFormDialog = true;
      // item.subjectsData = this.subjectsData.filter((subject) =>
      //   subject.allocatedStudents.includes(item.uId)
      // );
      this.selectedStudent = item;
    },
    async saveAndPublishForm() {
      let changedItems = this.examFormTableData
        .filter((item) => item.isChanged === true)
        .map(({ uId, isExamFormAccepted, examFormRemark }) => ({
          uId,
          isExamFormAccepted,
          examFormRemark,
          examId: this.examId,
          semId: this.selectedSemester.semId,
          instituteId: this.selectedInstitute,
          courseYear: this.selectedCourseYear,
        }));
      try {
        let res =
          await this.examFormAndHallTicketInstance.createExamFormAndHallTicket(
            changedItems
          );
        if (res.status.status === 200) {
          showStatus("Successfully saved.", 2000, "success", this);
          this.examFormTableData.forEach((item) => {
            let present = changedItems.find(
              (changedItem) => changedItem.uId === item.uId
            );
            if (present) {
              item.isChanged = false;
            }
          });
        }
      } catch (error) {
        // console.error("Error",error)
        showStatus("Something went wrong", 2000, "error", this);
      }
      //herer\
      for (let key in this.examTabDependecies["examFormTab"]) {
        // this.examTabDependecies['tab3'][key] = Math.random();
        this.examTabDependecies["examFormTab"][key] = true;
      }
    },
    async getStudentsExamForm() {
      let studentIds = this.courseYearStudents.map((student) => student.uId);
      try {
        let res =
          await this.examFormAndHallTicketInstance.getExamFormAndHallTicket({
            instituteId: this.selectedInstitute,
            examId: this.examId,
            uIds: studentIds,
          });
        // if(res)
        this.fetchedData = res.result;
      } catch (error) {
        console.error(error);
      }
    },
    calculateProgressIndicator() {
      this.progressIndicator =
        (this.examFormTableData.filter((item) => item.isExamFormAccepted)
          .length /
          this.examFormTableData.length) *
        100;
    },

    async temp() {
      //get insti info
      this.instituteInfo =
        await this.InstituteRepositoryInstance.getInstituteInformation({
          instituteId: this.selectedInstitute,
        }); //get exam info
      //get stundet info
    },
    prepareExamFormToDownload() {
      const tempBasicDetailFields = this.instituteInfo.examFormFormat
        ? this.instituteInfo.examFormFormat.basicDetailsFields
        : [];
      const customPersonalDetailFields = [];
      for (let i = 0; i < tempBasicDetailFields?.length; i++) {
        const element = tempBasicDetailFields[i];
        const objToPush = {};
        if (element === "Name") {
          objToPush.text = "Name";
          objToPush.value = "studentName";
        } else if (element === "Email-Id") {
          objToPush.text = "Email-Id";
          objToPush.value = "userEmail";
        } else if (element === "Contact Number") {
          objToPush.text = "Contact Number";
          objToPush.value = "contactNumber";
        } else if (element === "Category") {
          objToPush.text = "Category";
          objToPush.value = "category";
        } else if (element === "Gender") {
          objToPush.text = "Gender";
          objToPush.value = "gender";
        } else if (element === "Roll Number") {
          objToPush.text = "Roll Number";
          objToPush.value = "rollNumber";
        } else if (element === "PRN") {
          objToPush.text = "PRN";
          objToPush.value = "collegePRNNo";
        } else if (element === "Batch") {
          objToPush.text = "Batch";
          objToPush.value = "batch";
        } else if (element === "Semester") {
          objToPush.text = "Semester";
          objToPush.value = "semester";
        } else if (element === "Exam Seat Number") {
          objToPush.text = "Exam Seat Number";
          objToPush.value = "examSeatNumber";
        }
        customPersonalDetailFields.push(objToPush);
      }
      this.instituteInfo.examFormFormat["customPersonalDetailFields"] =
        customPersonalDetailFields;
      this.examFormTableData.forEach((item) => {
        item.subjects = this.subjectsData.filter((subject) =>
          subject.allocatedStudents.includes(item.uId)
        );
      });
      // this.selectedStudent = item;
    },
    async downloadExamForm(downloadType) {
      // this.handleData()
      this.dowloadLoader = true;
      this.prepareExamFormToDownload();
      // let studentObj = {...this.selectedStudent, semester : this.selectedSemester.semName};
      const arrayToSend =
        downloadType === "single"
          ? [this.selectedStudent]
          : this.examFormTableData;
      const newArray = arrayToSend.map((item) => {
        const newItem = { ...item }; // Create a shallow copy of the item object
        if (newItem.subjects) {
          newItem.subjects = newItem.subjects.map((subject) => {
            const newSubject = { ...subject }; // Create a shallow copy of the subject object
            delete newSubject.allocatedStudents;
            return newSubject;
          });
        }
        return newItem;
      });
      const objToPush = {
        instituteData: { ...this.instituteInfo, isRiims: this.isRiims, isMima: this.isMima },
        studentData: newArray,
        examData: { name: this.exam.name, department: this.exam.department },
      };
      try {
        const result = await apiV3.postRequest(
          "/manageExam/generateStudentExamForm",
          objToPush
        );
        this.downloadFileFromBuffer(result.data.pdfUrl.data); // pass the buffer of the file
        showStatus("Exam form downloaded successfully.", 2000, "success", this);
      } catch (error) {
        console.error("error", error);
      }
      this.dowloadLoader = false;
    },
    downloadFileFromBuffer(resultByte) {
      var bytes = new Uint8Array(resultByte); // pass your byte response to this constructor
      var blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `examForm.pdf`; 
      link.click();
      showStatus("Error downloading exam form .", 1000, "success", this);
    },
    handleData() {
      const tempFields = this.instituteInfo.examFormFormat
        ? this.instituteInfo.examFormFormat.formFields
        : [];
        this.instituteInfo.examFormFormat["customExamFormFields"] = []
      for (let i = 0; i < tempFields?.length; i++) {
        const element = tempFields[i];
        const objToPush = {};
        if (element === "Program Name") {
          objToPush.text = "Program Name";
          objToPush.value = this.exam.criteria
            ? this.exam.criteria.department
            : this.exam.department;
        } else if (element === "PUNCODE") {
          objToPush.text = "PUNCODE";
          objToPush.value = this.instituteInfo.puncode
            ? this.instituteInfo.puncode
            : "-";
        } else if (element === "Exam Name") {
          objToPush.text = "Exam Name";
          objToPush.value = this.exam.title;
        } else if (element === "Date") {
          objToPush.text = "Date";
          objToPush.value = this.exam.startDate;
        }
        this.instituteInfo.examFormFormat.customExamFormFields.push(objToPush);
      }
      this.instituteInfo.examFormFormat["customSubjectFields"] = [];
      const tempSubFields = this.instituteInfo.examFormFormat
        ? this.instituteInfo.examFormFormat.subjectDetailFields
        : [];
      for (let i = 0; i < tempSubFields?.length; i++) {
        const element = tempSubFields[i];
        const objToPush = {};
        if (element === "Subject Name") {
          objToPush.text = "Course Name";
          objToPush.value = "subjectName";
        } else if (element === "Subject Code") {
          objToPush.text = "Course Code";
          objToPush.value = "subjectCode";
        } else if (element === "CIE (Internal)") {
          objToPush.text = this.isRiims ? "CCE (Internal)" : "CIE (Internal)";
          objToPush.value = "";
        } else if (element === "SEE (External)") {
          objToPush.text = this.isRiims ? "End Semester Exam (ESE)" : "SEE (External)";
          objToPush.value = "";
        }
        this.instituteInfo.examFormFormat?.customSubjectFields.push(objToPush);
      }
      // this.examFormTableData
    },
    goToNextPage() {
      this.$emit("nextButtonClick");
    },
    selectAllExamForm() {
      const acceptValue = this.acceptAllExamFormCheckbox;
      this.examFormTableData.forEach((item) => {
        if (item.isExamFormAccepted !== acceptValue) {
          item.isExamFormAccepted = acceptValue;
          item.isChanged = true;
        }
      });
      this.calculateProgressIndicator();
    },
    getSchema() {
      this.schema = {
        Sheet1: {
          PRN: {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "collegePRNNo",
          },
          "Roll Number": {
            validation: {
              type: "textLength",
              // required: true
            },
            variableName: "rollNumber",
          },
          "Student Name": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "studentName",
          },
          "Accept Exam Form": {
            validation: {
              type: "list",
              values: ["TRUE", "FALSE"],
            },
            variableName: "isExamFormAccepted",
          },
          Remarks: {
            validation: {
              type: "list",
              values: this.remarksItem,
              // required: true,
            },
            variableName: "examFormRemark",
          },
        },
      };
    },
    async downloadExamFormExcelSample() {
      let array = this.examFormTableData.map((item) => {
        return [
          item.rollNumber,
          item.collegePRNNo,
          item.studentName,
          item.isExamFormAccepted,
          item.examFormRemark,
        ];
      });
      this.getSchema();
      createExcelThroughJsonAndSchema(array, this.schema, "Exam Form Details");
    },
    async uploadExamFormExcel() {
      this.getSchema();
      const res = await generateJsonFromExcel(
        this.examFormExcelFile,
        this.schema,
        false
      );
      const { errors, data } = res;
      this.examFormTableData = data["Sheet1"];
      this.uploadExamFormTableViaExcel = false;
    },
  },
};
</script>

<style src="./examForm.css"></style>