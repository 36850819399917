import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/AssignmentUser'

class AssignmentUser {
  constructor (context) {
    this.context = context
  }

  async createAssignmentUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createAssignmentUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignmentUser
  }

  async bulkUpdateMarksObtainedForAnAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.bulkUpdateMarksObtainedForAnAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async updateUserAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateUserAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.result;
  }

  async bulkUpdateUserAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.bulkUpdateUserAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSubmittedAssignmentsOfAnUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubmittedAssignmentsOfAnUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignmentUsers
  }
  async updateQuestionsMarksOfAnUserForAnAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateQuestionsMarksOfAnUserForAnAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.updateMarksObtained;
  }
  async updateQuestionsMarksOfAllAssignmentUsers(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateQuestionsMarksOfAllAssignmentUsers,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.updateMarksObtained;
  }
  async assignmentAnalysis (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.assignmentAnalysis, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAssignmentUsersOfAnAssignmentForADivision(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAssignmentUsersOfAnAssignmentForADivision,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.assignmentUsers;
  }

  async getAssignmentUsersOfAnAssignmentForAGroup(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAssignmentUsersOfAnAssignmentForAGroup,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.assignmentUsers;
  }

  async getSubmittedCountOfAnAssignmentForADivision(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubmittedCountOfAnAssignmentForADivision,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.submittedCount;
  }

  async getSubmittedCountOfAnAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubmittedCountOfAnAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.submittedCount;
  }

  async updateMarksObtainedOfAnUserForAnAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateMarksObtainedOfAnUserForAnAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.updateMarksObtained;
  }

  async updateRemarkOfAnUserForAnAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateRemarkOfAnUserForAnAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async checkAssignment(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.checkAssignment,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default AssignmentUser;
