<template src="./activityQuizResult.html"></template>
<script>
// import SubjectsRepository from "../../Repository/Subjects";
import CourseRepository from "../../../Repository/courses"

export default {
    name: "activityQuizResult",
    props: ["prop_subject","prop_subSectionId","prop_uId","prop_selectedSemester"],
    data() {
        return {
            isLoading: false,
            subSections: [],
            subSection: [],
            selectedSubsection: [],
            studentAns: '',
            secondCount: 0,
            counter: 0,
            total: 0,
            obtainedMarks: 0,
            attemptNo: 0,
            correct: '',
            wrong: '',
            count: 0,
            timeTaken: 0,
            T: 0,
            time: 0,
            totalMarks: 0,
            attemptNames: [],
            studentMarks: [],
            timerCount: this.durationOfquiz * 60000
        }
    },
    created() {
        this.CourseRepositoryInstance = new CourseRepository(this)
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.isLoading = true
            try {
                const objToPush = {
                    instituteId: this.prop_subject.instituteId,
                    semId: this.prop_subject.semId,
                    department: this.prop_subject.department,
                    courseYear: this.prop_subject.courseYear,
                    subjectId: this.prop_subject.subjectId,
                    courseName: this.prop_subject.courseName,
                    uId: this.prop_uId
                }
                const { data } = await this.CourseRepositoryInstance.getSyncCoursesDataOfSingleStudent(objToPush);
                if (data.isCourseFound) {
                    this.courseDetails = data.courseDetails;
                    this.sectionCompletedCount = data.sectionCompletedCount;
                    this.totalCredit = data.totalCredit;
                    this.totalCreditsRecieved = data.totalCreditsRecieved;
                    this.subsectionCompletedCount = data.subsectionCompletedCount;
                    this.progressPercentage = data.progressPercentage;
                    this.studentCourse = data.studentCourse;
                    this.subsectinsCount = data.subsectinsCount;
                    this.isCourseCompleted = this.sectionCompletedCount === this.courseDetails.sections.length
                    this.editCourseSubsections = JSON.parse(JSON.stringify(this.courseDetails));

                    let tempCourseCompleted = true;
                    for (let i = 0; i < this.courseDetails.sections.length; i++) {
                        if (!this.courseDetails.sections[i].isSectionCompleted) {
                            tempCourseCompleted = false;
                            break;
                        }
                    }
                    this.isCourseCompleted = tempCourseCompleted
                }
                for (let i = 0; i < this.courseDetails.sections.length; i++) {
                    if (this.courseDetails.sections[i].subSections && Array.isArray(this.courseDetails.sections[i].subSections)) {
                        for(let j = 0;j < this.courseDetails.sections[i].subSections.length;j++) {
                            if ( this.courseDetails.sections[i].subSections[j].subSectionId == this.prop_subSectionId) {
                                this.selectedSubsection = this.courseDetails.sections[i].subSections[j];
                                break;
                            }
                        }
                    }
                }
                
                this.durationOfquiz = this.selectedSubsection.durationOfquiz
                this.selectedSubsection.studentAnswers.map((obj, i) => {
                    this.attemptNames.push({ name: `Attempt No ${i + 1}`, number: i })
                })
                this.courseSections = this.$store.getters["liveData/get_courseSections"]
                this.getTotalMarks()
            } catch (err) {
                console.error(err);
                this.showContentNotFoundModal = true
            } finally {
                
                this.isLoading = false
            }
        },
        calculateAttempt(i) {
            if (this.selectedSubsection.studentAnswers[i - 1]) {
                this.getTimeTaken(i - 1)
                this.getStudentMarks(i - 1)
                this.tempMarks(i - 1)
                this.getData(i - 1)
                this.correctAns(i - 1)
            } else {
                alert('Attempt Not Given')
                this.studentMarks = []
                this.obtainedMarks = 0
                this.count = 0
                this.time = 0
            }
        },
        getTimeTaken(i) {
            this.timeTaken = this.selectedSubsection.studentAnswers[i].timeTaken
            var minutes = Math.floor(this.timeTaken / 60000);
            var seconds = ((this.timeTaken % 60000) / 1000).toFixed(0);
            this.time = minutes + ":" + (seconds < 10 ? '0' : '') + seconds
        },
        tempMarks(i) {
            this.selectedSubsection.studentAnswers[i].answers.map((obj) => {
                this.total += parseInt(obj.marks)
            })
        },
        getTotalMarks() {
        //    console.log(' this.selectedSubsection.questions',  this.selectedSubsection.questions);
            this.selectedSubsection.questions.map((obj) => {
                // console.log('obj.marks', obj.marks);
                if(obj.marks !== null && obj.marks !== undefined && obj.marks !== '') {
                    
                    this.totalMarks += parseInt(obj.marks)
                }
            })
        },
        getData(i) {
            this.studentMarks = this.selectedSubsection.studentAnswers[i].answers
        },
        goBack() {
            this.$router.push({
                name: "activityCourseAnalysisRevamped",
                params: {
                    prop_subject : this.prop_subject,
                    prop_selectedSemester: this.prop_selectedSemester
                }
            });
        },
        getStudentMarks(i) {
            this.obtainedMarks = this.selectedSubsection.studentAnswers[i].studentMarks
        },
        correctAns(i) {
            this.count = 0
            this.selectedSubsection.studentAnswers[i].answers.map((obj) => {
                if (obj.studentTempAnswers === obj.answer) {
                    this.count += 1
                }
            })
        }
    }
}
</script>
<style scoped src="./activityQuizResult.css">

</style>
