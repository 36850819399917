<template>
    <v-card style="margin:15px 0px">
        <v-card-text style="color:#000">
            <v-row justify="start" align="center">

                <v-col align="start">
                    <p style="margin:0; font-weight:700; color:#000; font-size:17px">{{ discussion.postedByFullname }}
                    </p>
                    <p style="font-weight: unset;margin:0">{{ formattedStartDate }}
                    </p>
                </v-col>
                <v-col align="end">
                    <v-btn icon class="ml-2 text-sm-right" @click="deleteDiscussion(discussion)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <br>
            <inputContainer
                style="text-align:center; height:unset; padding:20px; color:#00128C; font-weight:bold; font-size:18px;text-align: left;">
                {{ discussion.title }}
            </inputContainer>
            <br>
            <p>{{ discussion.description }}</p>
            <br>
            <v-row justify="center" align="center">
                <v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin:unset; font-size:14px; color:#313131">Total Participant</p>
                    <p style="font-weight:bold; font-size:18px; color:#00128C">{{ discussion.totalParticipants }}</p>
                </v-col>
                <v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin:unset; font-size:14px; color:#313131">Total Responses</p>
                    <p style="font-weight:bold; font-size:18px; color:#00128C">{{ discussion.totalResponses }}</p>
                </v-col><v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin:unset; font-size:14px; color:#313131">{{ ended ? "Ended At" : "Open till" }}</p>
                    <p style="font-weight:bold; font-size:18px; color:#00128C">{{
                        formattedEndDate
                    }} </p>
                </v-col>
            </v-row>
            <br>
            <v-btn block elevation="2" style="color:#FF4F1F; padding:25px 0" @click="onClickGo">Go To Discussion</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
// import inputContainer from '../inputContainer.vue';
import moment from "moment"
import inputContainer from '../inputContainer.vue';

export default {
    name: 'DiscussionListCard',
    components: { inputContainer },
    props: {
        discussion: Object,
        isMobileView: Boolean,
        deleteDiscussion: Function
    },
    data() {
        return {
            formattedStartDate: "",
            formattedEndDate: "",
            ended: false
        }
    },
    created() {
        this.formattedStartDate = moment(this.discussion.startDate).format("DD/MM/YYYY LT")
        this.formattedEndDate = moment(this.discussion.endDate).format("DD/MM/YYYY LT")
        this.ended = new Date(this.discussion.endDate) < new Date()
    },
    methods: {
        onClickGo() {
            this.$router.push({ name: "activityDiscussion", query: { discussionId: this.discussion.discussionId } })
        }
    }
}
</script>
<style scoped></style>
