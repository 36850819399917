import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Corporate'

class Corporate {
  constructor (context) {
    this.context = context
  }
  
  async deleteSubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.deleteSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  
  async changeSubjectName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.changeSubjectName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }


}

export default Corporate
