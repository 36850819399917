
import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/UserLogger'

class UserLogger {
  constructor (context) {
    this.context = context
  }

  async createUserDocumentForDate (data) {
    // console.log('abs')
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createUserDocumentForDate, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSpecificUserLogs (data) {
    // console.log('1');
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSpecificUserLogs, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
 


}

export default UserLogger