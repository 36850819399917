<template src='./activityDefineExamSubjects.html'>
</template>
<script>
import { subjectTypes } from '../../../Constants/Utils/Statics'
import ExamRepository from '../../../Repository/Exam'
import ExamSubjectRepository from '../../../Repository/ExamSubject'
import ExamTimetableRepository from '../../../Repository/ExamTimetable'
import SubjectRepository from '../../../Repository/Subject'
import showStatus from '../../../NetworkManager/showStatus'
export default {
  name: 'activityDefineExamSubjects',
  data () {
    return {
      selectedExam: null,
      examSubjects: [],
      examTimetables: [],
      examSubjectWiseTimetablesDistribution: {},
      subjectTypes: subjectTypes,
      loading: true,
      confirmPublish: false
    }
  },
  created () {
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.examRepositoryInstance = new ExamRepository(this)
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.examTimetableRepositoryInstance = new ExamTimetableRepository(this)
    this.fetchExamSubjects()
  },
  methods: {
    async fetchExamSubjects () {
      const objToPush = {
        instituteId: this.selectedExam.instituteId,
        semId: this.selectedExam.semId,
        examId: this.selectedExam.examId
      }
      console.log(objToPush)

      try {
        this.examSubjects = await this.examSubjectRepositoryInstance.getSubjectsForAnExam(objToPush)
      } catch (err) {
        console.log(err)
        this.examSubjects = []
      }

      try {
        this.examTimetables = await this.examTimetableRepositoryInstance.getExamTimetableForAnExam(objToPush)
      } catch (err) {
        console.log(err)
        this.examTimetables = []
      }
      console.log(this.examTimetables)
      for (let i = 0; i < this.examSubjects.length; i++) {
        await this.getSubjectDetails(this.examSubjects[i])
      }
      for (let i = 0; i < this.examTimetables.length; i++) {
        const str = this.examTimetables[i].subjectId
        console.log(str)
        // const str = this.examTimetables[i].subjectCode + '_' + this.examTimetables[i].subjectName + '_' + this.examTimetables[i].subjectType
        if (!this.examSubjectWiseTimetablesDistribution[str]) {
          this.examSubjectWiseTimetablesDistribution[str] = []
        }
        this.examSubjectWiseTimetablesDistribution[str].push(this.examTimetables[i])
      }
      console.log(this.examSubjectWiseTimetablesDistribution)
      this.loading = false
      // axios.post(apiConfig.getExamSubjects).then(res2 => {
      //   console.log(res2)
      //   this.examSubjects = res2.data.examSubject
      //   axios.post(apiConfig.getExamTimetable, {
      //     instituteId: this.selectedExam.instituteId,
      //     semId: this.selectedExam.semId,
      //     examId: this.selectedExam.examId
      //   }).then(res => {
      //     console.log(res)
      //   }).catch(err => {
      //     console.log(err)
      //     this.loading = false
      //   })
      //   console.log(res2)
      // }).catch(err => {
      //   console.log(err)
      //   this.loading = false
      // })
      console.log('this is examSubjects at end', this.examSubjects)
    },
    async getSubjectDetails (subject) {
      const response = await this.subjectRepositoryInstance.getDetailsOfASubject({
        instituteId: subject.instituteId,
        semId: subject.semId,
        subjectId: subject.subjectId
      })
      subject.subjectName = response.subjectName
      subject.subjectType = response.subjectType
      console.log(subject, response)
    },
    startEditingRooms (sub, tts) {
      this.$store.commit('liveData/set_selectedExamTimetable', tts)
      this.$store.commit('liveData/set_selectedExamSubject', sub)
      this.$router.push('/activityEditExamRooms')
    },
    startEditing (sub) {
      console.log('this is startediting subject', sub)
      this.$store.commit('liveData/set_selectedExamSubject', sub)
      this.$router.push('/activityEditExamSubjects')
    },
    goBack () {
      this.$router.go(-1)
    },
    gotoEditExamSubjectQuestions () {
      this.$router.push('activityEditExamSubjectQuestions')
    },
    startPublishing () {
      const tempArr = Object.values(this.examSubjectWiseTimetablesDistribution).filter((elem) => elem.length > 0)
      if (tempArr.length === this.examSubjects.length) {
        this.confirmPublish = true
      } else {
        window.alert('Timetable should be allocated to all exam subjects.')
      }
    },
    async publishExam () {
      const objToPush = {
        instituteId: this.selectedExam.instituteId,
        semId: this.selectedExam.semId,
        examId: this.selectedExam.examId
      }
      console.log(objToPush)
      try {
        await this.examRepositoryInstance.publishExam(objToPush)
        this.$router.push('/activityExams')
      } catch (err) {
        console.log(err)
        showStatus('Error publishing exam', 1000, 'error', this)
      }
      // axios.post(apiConfig.publishExam, objToPush).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Something went wrong while publishing exam. Please try later.')
      // })
    }
  }
}
</script>
<style scoped src='./activityDefineExamSubjects.css'>
</style>
