<template>
  <!-- <div class="circle-wrapper">
    <div :class="circleClass" :style="circleStyles"></div>
    <p v-if="text" class="circle-text" :style="{ fontSize: textSize }">{{ text }}</p>
  </div> -->
  <div class="circle-container" :class="wrapperClass">
    <p v-if="textPosition === 'top'" class="circle-text" :style="{ fontSize: textSize }">{{ text }}</p>
    <div :class="circleClass" :style="circleStyles"></div>
    <p v-if="textPosition === 'bottom'" class="circle-text" :style="{ fontSize: textSize }">{{ text }}</p>
    <p v-if="textPosition === 'left'" class="circle-text side-text" :style="{ fontSize: textSize }">{{ text }}</p>
    <p v-if="textPosition === 'right'" class="circle-text side-text" :style="{ fontSize: textSize }">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'CircleComponent',
  props: {
    backgroundColor: {
      type: String,
      default: '#3498db', // Default background color (blue)
    },
    borderColor: {
      type: String,
      default: '#2980b9', // Default border color (darker blue)
    },
    size: {
      type: String,
      default: '100px', // Default size (diameter)
    },
    borderWidth: {
      type: String,
      default: '1px', // Default border width
    },
    text: {
      type: String,
      default: '', // Default text (empty)
    },
    textSize: {
      type: String,
      default: '14px', // Default text size
    },
    textPosition: {
      type: String,
      default: 'right', // Default text position
      validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value),
    },
  },
  computed: {
    circleStyles() {
      return {
        backgroundColor: this.backgroundColor,
        borderColor: this.borderColor,
        borderWidth: this.borderWidth,
        width: this.size,
        height: this.size,
        borderStyle: 'solid',
      };
    },
    circleClass() {
      return 'circle';
    },
    wrapperClass() {
      if (this.textPosition === 'top' || this.textPosition === 'bottom') {
        return 'vertical-wrapper';
      } else {
        return 'horizontal-wrapper';
      }
    },
  },
  created() {}
};
</script>

<style scoped>
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-wrapper {
  flex-direction: column;
}

.horizontal-wrapper {
  flex-direction: row;
}

.circle {
  border-radius: 50%;
  display: inline-block;
}

.circle-text {
  margin: 10px;
  color: #333; /* Default text color */
}

.side-text {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
