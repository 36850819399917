<template src="./activityCourseSubsectionFeedback.html"></template>
<script>
import CourseRepository from '../../../Repository/courses';
export default {
    name: 'activityCourseSubsectionFeedback',
    props: ["prop_subject","prop_subSectionId","prop_uId","prop_selectedSemester","prop_userName"],
    data(){
        return {
            selectedSubsection : {},
            courseDetails:{},
        }
    },
    async created() {
        this.$store.commit("liveData/set_selectedActivityName", this.prop_subject.courseName);
        this.CourseRepositoryInstance = new CourseRepository(this)
        this.fetchData();
    },
    methods : {
        async fetchData() {
            this.isLoading = true
            try {
                const objToPush = {
                    instituteId: this.prop_subject.instituteId,
                    semId: this.prop_subject.semId,
                    department: this.prop_subject.department,
                    courseYear: this.prop_subject.courseYear,
                    subjectId: this.prop_subject.subjectId,
                    courseName: this.prop_subject.courseName,
                    uId: this.prop_uId
                }
                const { data } = await this.CourseRepositoryInstance.getSyncCoursesDataOfSingleStudent(objToPush);
                if (data.isCourseFound) {
                    this.courseDetails = data.courseDetails;
                    this.sectionCompletedCount = data.sectionCompletedCount;
                    this.totalCredit = data.totalCredit;
                    this.totalCreditsRecieved = data.totalCreditsRecieved;
                    this.subsectionCompletedCount = data.subsectionCompletedCount;
                    this.progressPercentage = data.progressPercentage;
                    this.studentCourse = data.studentCourse;
                    this.subsectinsCount = data.subsectinsCount;
                    this.isCourseCompleted = this.sectionCompletedCount === this.courseDetails.sections.length
                    this.editCourseSubsections = JSON.parse(JSON.stringify(this.courseDetails));

                    let tempCourseCompleted = true;
                    for (let i = 0; i < this.courseDetails.sections.length; i++) {
                        if (!this.courseDetails.sections[i].isSectionCompleted) {
                            tempCourseCompleted = false;
                            break;
                        }
                    }
                    this.isCourseCompleted = tempCourseCompleted
                }
                for (let i = 0; i < this.courseDetails.sections.length; i++) {
                    if (this.courseDetails.sections[i].subSections && Array.isArray(this.courseDetails.sections[i].subSections)) {
                        for(let j = 0;j < this.courseDetails.sections[i].subSections.length;j++) {
                            if ( this.courseDetails.sections[i].subSections[j].subSectionId == this.prop_subSectionId) {
                                this.selectedSubsection = this.courseDetails.sections[i].subSections[j];
                                break;
                            }
                        }
                    }
                }
                this.adminAssignmentFeedback = this.selectedSubsection.assignmentFeedback
            } catch (err) {
                console.log(err);
                this.showContentNotFoundModal = true
            } finally {
                
                this.isLoading = false
            }
        },
        goBack() {
            this.$router.push({
                name: "activityCourseAnalysisRevamped",
                params: {
                    prop_subject : this.prop_subject,
                    prop_selectedSemester: this.prop_selectedSemester
                }
            });
        },
    }
}
</script>
<style src="./activityCourseSubsectionFeedback.css" scoped></style>