<template src="./activityUniversityEvents.html"></template>

<script>
import universityEvents from "../../Repository/universityEvents";
import inputContainer from '../../Components/inputContainer'
import { convertUTCDateToLocalDate, formatToSlashSepearated } from "../../Services/Utils/DateTimeFormatter";
import auth from '../../Services/Firebase/auth'

export default {
    name: "activityUniversityEvents",
    components: {
        inputContainer,
    },
    data() {
        return {
            loadTable: false,
            editing: false,
            loader: null,
            eventId: "",
            uploadTask: "",
            loading: false,
            checkbox: true,
            OpenDialogBox: false,
            openUpdateDialogueBox: false,
            showRecording: false,
            files: [],
            tempTitle: "",
            dateMenu: "",
            attachFile: [],
            relativeInstitutes: [],
            data: [],
            tempDescription: "",
            tempLink: "",
            currentUploadProgress: 0,
            tempDate: '',
            tempTime: '',
            search: '',
            AttatchFile: "",
            uploadCount: 0,
            downloadURLs: [],
            selectedItem: {},
            filesAccepted: [],
            events: [],
            userData: {},
            items: [
                { title: "New Video", icon: "mdi-play-box-multiple" },
                { title: "New PDF", icon: "mdi-file-pdf-box" },
                { title: "New Image", icon: "mdi-image" },
                { title: "New Excel", icon: "mdi-file-excel" },
                { title: "New Doc", icon: "mdi-file-document" },
                { title: "New Audio", icon: "mdi-multimedia" },
            ],
            // options: [
            //   "Newest to Oldest",
            //   "Oldest to Newest",
            //   "Videos Only",
            //   "PDF's Only",
            //   "Images Only",
            // ],
            headers: [
                {
                    text: 'Title',
                    align: 'left',
                    value: 'title',
                    class: "DataTable"
                },
                {
                    text: 'Description',
                    align: 'left',
                    value: 'description',
                },
                {
                    text: 'Date',
                    align: 'left',
                    value: 'date',
                },
                {
                    text: 'Time',
                    align: 'left',
                    value: 'time',
                },
                {
                    text: 'Action',
                    align: 'left',
                    value: 'action',
                }
            ],
            convertedDate: '',
            convertedTime: '',
        };
    },

    async created() {
        this.$store.commit('liveData/set_selectedActivityName', 'University Events')
        this.userData = this.$store.getters["user/get_userData"];
        this.universityEventsInstance = new universityEvents(this);
        this.selectedInstitute = this.$store.getters['liveData/get_admissionInstitute']
        this.relativeInstitutes = this.$store.getters['liveData/get_relativeInstitutes']
        this.getData()
    },
    methods: {
        async getData() {
            const objToPush = {
                instituteId: this.selectedInstitute
            }
            this.events = await this.universityEventsInstance.getUniversityEvent(objToPush)
        },
        async updateUniversityEvent() {
            const localDateString = `${this.tempDate} ${this.tempTime}`;
            const localDate = new Date(localDateString);
            let currentUtcDate = new Date(localDate).toISOString()
            const objToPush = {
                title: this.tempTitle,
                description: this.tempDescription,
                dateTime: currentUtcDate,
                applicableInstitutes: this.relativeInstitutes,
                eventId: this.eventId
            }
            await this.universityEventsInstance.updateUniversityEvent(objToPush)
            this.OpenDialogBox = false
            this.getData()
        },
        async deleteEvent(obj) {
            const objToPush = {
                eventId: obj.eventId
            }
            await this.universityEventsInstance.deleteEvent(objToPush)
            this.getData()
        },
        convertUTCDateToLocalDate(dateTime) {
            let date = new Date(dateTime)
            return convertUTCDateToLocalDate(dateTime)
        },
        convertUtcDate(dateTime) {
            return formatToSlashSepearated(dateTime)
        },
        gotoCreateEvent() {
            this.editing = false
            this.OpenDialogBox = true
        },
        cancelCreating() {
            this.OpenDialogBox = false
            this.tempDescription = ''
            this.tempTitle = ''
            this.tempDate = ''
            this.tempTime = ''
        },
        async createEvent() {
            if (this.tempDate === '') {
                alert("Please select a date.")
                return
            } else if (this.tempTime === '') {
                alert('Please select Time')
                return
            } else {
                const localDateString = `${this.tempDate} ${this.tempTime}`;
                const localDate = new Date(localDateString);
                let currentUtcDate = new Date(localDate).toISOString()
                let objToPush = {
                    title: this.tempTitle,
                    description: this.tempDescription,
                    dateTime: currentUtcDate,
                    applicableInstitutes: this.relativeInstitutes,
                    createdBy: this.userData.uId
                }
                await this.universityEventsInstance.createUniversityEvent(objToPush)
                this.getData()
                this.OpenDialogBox = false
                this.tempTitle = ''
                this.tempDescription = ''
                this.tempDate = ''
                this.tempTime = ''
            }
        },
        gotoEventCheck(obj) {
            this.$router.push({
                name: 'activityTakeEventAttendace',
                params: {
                    prop_Event: obj
                }
            })
        },
        editEvent(obj) {
            this.eventId = obj.eventId
            this.editing = true
            this.tempTitle = obj.title
            this.tempDescription = obj.description
            // this.tempDate = this.convertUtcDate(obj.dateTime)
            // this.tempTime = this.convertUTCDateToLocalDate(obj.dateTime)

            this.convertedDate = this.convertUtcDate(obj.dateTime)
            this.convertedTime = this.convertUTCDateToLocalDate(obj.dateTime)

            const date = new Date(this.convertedDate);
            date.setDate(date.getDate() + 1);

            this.tempDate = date.toISOString().split('T')[0];

            const convertedTimeParts = this.convertedTime.split(' ')[0].split(':');
            this.tempTime = `${convertedTimeParts[0].padStart(2, '0')}:${convertedTimeParts[1]}`;

            this.OpenDialogBox = true
        }
    },
};
</script>

<style src="./activityUniversityEvents.css" scoped></style>
