<template>
  <v-dialog
    v-model="internalValue"
    :persistent="persistent"
    :max-width="maxWidth"
    :width="width"
  >
    <v-card :style="cardStyle">
      <!-- Dialog Title -->
      <v-card-title :style="titleStyle">
        <slot>{{ title }}</slot>
      </v-card-title>

      <!-- Dialog Content -->
      <v-card-text :style="contentStyle">
        <slot>
          {{ content }}
        </slot>
      </v-card-text>

      <!-- Dialog Actions (Cancel and Confirm Buttons) -->
      <v-card-actions :style="cardActionStyle">
        <Button
          flat
          :textColor="white"
          :color="secondary"
          iconFontSize="16px"
          @click="confirm"
        >
          Confirm
        </Button>
        <Button
          text
          :textColor="secondary"
          :color="secondary"
          iconFontSize="16px"
          @click="cancel"
        >
          Cancel
        </Button>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import Button from "../Buttons/Button.vue";
import { theme } from "../../../Constants/Utils/Theme";
export default {
  name: "DeleteDialog",
  data(){
    return {
      white: theme.white,
      secondary: theme.secondary,
      black: theme.black,
      error: theme.error
    }
  },
  components: {
    Button,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Deletion",
    },
    content: {
      type: String,
      default: "Are you sure you want to delete this item? This action is permanent and cannot be undone.",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    confirmText: {
      type: String,
      default: "Confirm",
    },
    maxWidth: {
      type: String,
      default: "40%",
    },
    width: {
      type: String,
      default: "45%",
    },
    persistent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    contentStyle(){ 
      return {
        color: this.black,
        fontSize: "16px",
        margin: "1% 0",
        padding: "0"
      }
    },
    titleStyle() {
      return {
        color: this.error,
        fontSize: "20px",
        margin: "0",
        padding: "0"
      }
    },
    cardActionStyle() {
      return {
        margin: "3% 0 0 0",
        padding: "0"
      }
    },
    cardStyle() {
      return {
        padding: "3% 4%"
      }
    },
  },
  created() {},
  methods: {
    cancel() {
      this.$emit("cancel");
      this.internalValue = false;
    },
    confirm() {
      this.$emit("confirm");
      this.internalValue = false;
    },
  },
};
</script>
  
  <style scoped>
/* Add custom styles if necessary */
.text_black {
  color: #3a3a3a;
}
</style>
  