import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ValueAddedActivity'

class ValueAddedActivity {
  constructor (context) {
    this.context = context
  }

  async createValueAddedActivity (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createValueAddedActivity, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getValueAddedActivityAll (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getValueAddedActivityAll, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.activity
  }

  async getValueAddedActivity (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getValueAddedActivity, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.activity
  }

  async getAllValueAddedActivityOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllValueAddedActivityOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.activity
  }


  async updateValueAddedActivity (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateValueAddedActivity, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async deleteValueAddedActivity (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteValueAddedActivity, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }


}

export default ValueAddedActivity
