import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/SMS'

class SMS {
  constructor (context) {
    this.context = context
  }

  async sendSMS (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.sendSMS, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getSMSForDate (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getSMSForDate, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.smses
  }

  async addSMSUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.addSMSUser, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getSmsUsersOfAnInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getSmsUsersOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.smsUsers
  }

  async deleteSmsUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.deleteSmsUser, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default SMS
