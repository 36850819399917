import { render, staticRenderFns } from "./activityEditFacultyAllocation.html?vue&type=template&id=329edcb0&scoped=true&external"
import script from "./activityEditFacultyAllocation.vue?vue&type=script&lang=js"
export * from "./activityEditFacultyAllocation.vue?vue&type=script&lang=js"
import style0 from "./activityEditFacultyAllocation.css?vue&type=style&index=0&id=329edcb0&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329edcb0",
  null
  
)

export default component.exports