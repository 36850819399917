export default async function (arrayToUpdate, nameField, userRepositoryInstance) {
  const uIdToNameMapping = {}
  const batchSize = 100

  const arrayOfUids = arrayToUpdate.map((elem) => elem.uId)

  const promises = []
  for (let i = 0; i < arrayOfUids.length; i += batchSize) {
    const lastIndex = i + batchSize < arrayOfUids.length ? i + batchSize : arrayOfUids.length

    const uIds = []
    for (let j = i; j < lastIndex; j++) {
      uIds.push(arrayOfUids[j])
    }

    promises.push(getFullNameOfUsers(uIds, uIdToNameMapping, userRepositoryInstance))
  }

  await Promise.allSettled(promises)

  for (let i = 0; i < arrayToUpdate.length; i++) {
    arrayToUpdate[i][nameField] = uIdToNameMapping[arrayToUpdate[i].uId] || ''
  }
  console.log('after getting all names', uIdToNameMapping)
  return arrayToUpdate
}

async function getFullNameOfUsers (uIds, uIdToNameMapping, userRepositoryInstance) {
  try {
    const objToPush = {
      uIds: uIds
    }

    const fullNames = await userRepositoryInstance.getFullNameOfUsers(objToPush)
    fullNames.forEach((obj) => {
      uIdToNameMapping[obj.uId] = obj.fullName
    })
  } catch (err) {
    console.log(err)
  }
}
