<template src="./activityEditExamSubjects.html"> </template>
<script>
import auth from "../../../Services/Firebase/auth";
import {
  subjectTypes,
  questionTypesTemp,
  optionEnabled
} from "../../../Constants/Utils/Statics";
import showStatus from "../../../NetworkManager/showStatus";
import ExamSubjectRepository from "../../../Repository/ExamSubject";
import UserRepository from "../../../Repository/User";
import axios from "axios";

var firestorage = null;
export default {
  name: "activityEditExamSubjects",
  components: {
  },
  data() {
    return {
      openTextEditor: false,
      isDownloading: false,
      defaultButtonText: "Upload File",
      selectedFile: null,
      isSelecting: false,
      loading2: false,
      loading: true,
      selectedExam: {},
      selectedExamSubject: {},
      showQuestions: false,
      showApplicableUsers: false,
      applicableUsers: [],
      userMappings: {},
      headers: [
        // {
        //   text: 'uId',
        //   value: 'uId'
        // },
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Remove",
          value: "remove"
        }
      ],
      search: "",
      headers2: [
        {
          text: "Ques. No.",
          value: "questionNumber"
        },
        {
          text: "Question",
          value: "question"
        },
        {
          text: "Type",
          value: "type"
        },
        {
          text: "Marks",
          value: "mark"
        },
        {
          text: "Negative Marks",
          value: "nmark"
        },
        {
          text: "Options",
          value: "options"
        },
        {
          text: "Answer",
          value: "answer"
        },
        {
          text: "Images",
          value: "imageLink"
        },
        {
          text: "Edit",
          value: "edit"
        },
        {
          text: "Remove",
          value: "remove"
        }
      ],
      refresh: true,
      selectedQuestion: {},
      showEditQuestion: false,
      files: [],
      addingQuestion: false,
      tempQuestion: {},
      optionEnabled: optionEnabled,
      subjectTypes: subjectTypes,
      questionTypes: questionTypesTemp,
      addingOption: false,
      tempOption: "",
      downloadURL: [],
      images: [],
      others: [],
      uploadCount: 0,
      fileName: "",
      uploading: false,
      uploadTask: "",
      uploadEnd: false,
      progressUpload: 0,
      selectedUserToRemove: {},
      confirmRemoveUser: false
    };
  },
  mounted() {
    const wiris = document.createElement("script");
    wiris.setAttribute(
      "src",
      "https://polyfill.io/v3/polyfill.min.js?features=es6"
    );
    document.head.appendChild(wiris);
    const mathjax = document.createElement("script");
    mathjax.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js"
    );
    mathjax.setAttribute("id", "MathJax-script");
    document.head.appendChild(mathjax);
    // this.createFieldBox()
  },
  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    }
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            console.log("hello", downloadURL);
            this.images.push(downloadURL);
            // if (this.uploadTask.type === 'image') {
            //   this.images.push(downloadURL)
            //   console.log(downloadURL, 'Hello')
            // } else {
            //   this.others.push(downloadURL)
            // }
            this.downloadURL.push(downloadURL);
            this.uploadCount++;
            if (this.uploadCount === this.files.length) {
              if (this.showEditQuestion) {
                this.editQuestion();
              } else {
                this.addQuestion();
              }
            }
          });
        }
      );
    }
  },
  created() {
    this.loading = true;
    this.selectedExam = this.$store.getters["liveData/get_selectedExam"];
    this.selectedExamSubject = this.$store.getters[
      "liveData/get_selectedExamSubject"
    ];
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    const firebase = auth.getFirebaseObject();
    firestorage = firebase.storage();
    console.log(this.selectedExamSubject);
    this.getNamesOfUsers();
    console.log(this.applicableUsers);
  },
  methods: {
    createFieldBox() {
      setTimeout(function() {
        ClassicEditor.create(document.querySelector("#mathEquation"), {
          plugins: [
            EssentialsPlugin,
            ParagraphPlugin,
            BoldPlugin,
            ItalicPlugin,
            MathType,
            LinkPlugin
          ],
          toolbar: ["MathType"],
          placeholder: "Question Statement"
        })
          .then(editor => {
            console.log("Editor was initialized", editor);
            let mathButton = document.querySelector("#mathEquation");
            if (btnSend) {
              mathButton.setAttribute("v-model", "tempQuestion.question");
              console.log("attrivute setted");
            }
          })
          .catch(error => {
            console.error(error.stack);
          });
        // this.openTextEditor = true
        console.log("yo");
      }, 500);
    },
    async getNamesOfUsers() {
      console.log("Hello");
      let promises = [];
      this.selectedExamSubject.applicableUsers.forEach(user => {
        promises.push(
          this.userRepositoryInstance.getFullNameOfUser({ uId: user })
        );
      });
      promises = await Promise.allSettled(promises);
      console.log(promises);
      for (let i = 0; i < promises.length; i++) {
        this.applicableUsers[i] = {
          name: promises[i].value,
          uId: this.selectedExamSubject.applicableUsers[i]
        };
      }
      this.applicableUsers = this.applicableUsers.slice();
      this.loading = false;
      // for (let i = 0; i < this.selectedExamSubject.applicableUsers.length; i++) {
      // const name = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.selectedExamSubject.applicableUsers[i] })
      // this.applicableUsers[i] = {
      //   uId: this.selectedExamSubject.applicableUsers[i],
      //   name: name
      // }
      // }
    },
    removeStudentStep1(stud) {
      this.selectedUserToRemove = stud;
      this.confirmRemoveUser = true;
    },
    async removeStudent(stud) {
      this.loading2 = true;
      this.confirmRemoveUser = false;
      const tempObj = JSON.stringify(this.selectedExamSubject);
      const tempObj2 = JSON.parse(tempObj);
      tempObj2.applicableUsers = this.remove_array_element(
        tempObj2.applicableUsers,
        tempObj2.applicableUsers.indexOf(stud.uId)
      );
      // console.log(tempObj, tempObj2)
      // Call APi to update examSubject
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2);
        this.selectedExamSubject = tempObj2;
        this.applicableUsers = this.remove_array_element(
          this.applicableUsers,
          this.applicableUsers.indexOf(stud)
        );
      } catch (err) {
        console.log(err);
        showStatus("Could not remove student. Try again.", 1000, "error", this);
      }
      this.loading2 = false;
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
    },
    async removeQuestion(que) {
      const tempObj = JSON.stringify(this.selectedExamSubject);
      const tempObj2 = JSON.parse(tempObj);
      tempObj2.questions = this.remove_array_element(
        tempObj2.questions,
        que.questionNumber - 1
      );
      tempObj2.totalObtainableMarks = 0;
      tempObj2.numberOfQuestionsToShow = tempObj2.questions.length;
      for (let i = 0; i < tempObj2.questions.length; i++) {
        tempObj2.questions[i].questionNumber = i + 1;
        tempObj2.totalObtainableMarks += parseInt(
          tempObj2.questions[i].mark.plus
        );
        delete tempObj2.questions[i].mark.elm;
        delete tempObj2.questions[i].mark.isRootInsert;
      }

      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2);
        this.selectedExamSubject = JSON.parse(JSON.stringify(tempObj2));
      } catch (err) {
        console.log(err);
        showStatus(
          "Could not remove question. Try again.",
          1000,
          "error",
          this
        );
      }
      // Call APi to update examSubject
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
    },
    editQuestionStep1(que) {
      this.selectedQuestion = JSON.parse(JSON.stringify(que));
      this.showEditQuestion = true;
      this.files = [];
    },
    addQuestionStep1() {
      this.files = [];
      this.tempQuestion = {
        questionNumber: this.selectedExamSubject.questions.length + 1,
        question: "",
        type: "",
        mark: {
          plus: 0,
          minus: 0
        },
        options: [],
        answer: [],
        imageLink: []
      };
      this.addingQuestion = true;
    },
    filesChanged(e) {
      console.log(e);
    },
    uploadFiles() {
      this.downloadURL = [];
      this.images = [];
      this.others = [];
      this.uploadCount = 0;
      console.log(this.files);
      if (this.files.length === 0) {
        if (this.showEditQuestion) {
          this.editQuestion();
        } else {
          this.addQuestion();
        }
      } else {
        Array.from(Array(this.files.length).keys()).map(x => {
          this.upload(this.files[x]);
        });
      }
    },
    async editQuestion() {
      console.log("editing question");
      const tempObj = JSON.stringify(this.selectedExamSubject);
      const tempObj2 = JSON.parse(tempObj);
      this.refresh = false;
      // tempObj2.imageLink = this.images
      this.selectedQuestion.imageLink = this.images;
      tempObj2.questions[this.selectedQuestion.questionNumber - 1] = JSON.parse(
        JSON.stringify(this.selectedQuestion)
      );
      tempObj2.totalObtainableMarks = 0;
      tempObj2.numberOfQuestionsToShow = tempObj2.questions.length;
      for (let i = 0; i < tempObj2.questions.length; i++) {
        tempObj2.questions[i].questionNumber = i + 1;
        tempObj2.totalObtainableMarks += parseInt(
          tempObj2.questions[i].mark.plus
        );
        delete tempObj2.questions[i].mark.elm;
        delete tempObj2.questions[i].mark.isRootInsert;
      }
      console.log(this.images, tempObj2);
      // Call APi to update examSubject
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2);
        this.selectedExamSubject = JSON.parse(JSON.stringify(tempObj2));
        this.showEditQuestion = false;
        this.refresh = true;
      } catch (err) {
        console.log(err);
      }
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
      this.convertMaths();
    },
    async addQuestion() {
      console.log("adding Question");
      const tempObj = JSON.stringify(this.selectedExamSubject);
      const tempObj2 = JSON.parse(tempObj);
      this.refresh = false;
      // this.selectedExamSubject.imageLink = this.images
      this.tempQuestion.imageLink = this.images;
      tempObj2.questions.push(this.tempQuestion);
      // tempObj2.questions[this.selectedQuestion.questionNumber - 1] = JSON.parse(JSON.stringify(this.selectedQuestion))
      tempObj2.totalObtainableMarks = 0;
      tempObj2.numberOfQuestionsToShow = tempObj2.questions.length;
      for (let i = 0; i < tempObj2.questions.length; i++) {
        tempObj2.questions[i].questionNumber = i + 1;
        tempObj2.totalObtainableMarks += parseInt(
          tempObj2.questions[i].mark.plus
        );
        delete tempObj2.questions[i].mark.elm;
        delete tempObj2.questions[i].mark.isRootInsert;
      }
      console.log(tempObj2);
      // Call APi to update examSubject
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(tempObj2);
        this.selectedExamSubject = JSON.parse(JSON.stringify(tempObj2));
        this.addingQuestion = false;
        this.refresh = true;
      } catch (err) {
        console.log(err);
      }
      // axios.post(apiConfig.updateExamSubject, tempObj2).then(res => {
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
      console.log("Finishes");
      this.convertMaths();
    },
    upload(file) {
      console.log("uploading");
      this.fileName = file.name;
      this.uploading = true;
      if (file.type.split("/")[0] === "image") {
        if (this.showEditQuestion) {
          this.uploadTask = firestorage
            .ref(
              "exams/" +
                this.selectedExam.examId +
                "/" +
                this.selectedQuestion.questionNumber +
                "/" +
                file.name
            )
            .put(file);
        } else {
          this.uploadTask = firestorage
            .ref(
              "exams/" +
                this.selectedExam.examId +
                "/" +
                this.tempQuestion.questionNumber +
                "/" +
                file.name
            )
            .put(file);
        }
      } else {
        // this.uploadTask = firestorage.ref('posts/' + this.userData.id + '/' + new Date().valueOf().toString() + '________' + file.name).put(file)
        window.alert("Only Image Files are supported.");
        this.uploadCount++;
      }
    },
    remove_array_element(array, n) {
      if (n > -1) {
        array.splice(n, 1);
      }
      return array;
    },
    async saveChangesInExamSubject() {
      try {
        await this.examSubjectRepositoryInstance.updateExamSubject(
          this.selectedExamSubject
        );
        showStatus("Changes saved successfully.", 1000, "success", this);
      } catch (err) {
        console.log(err);
      }
      // Call APi to update examSubject
      // axios.post(apiConfig.updateExamSubject, this.selectedExamSubject).then(res => {
      //   console.log(res)
      //   window.alert('Changes saved successfully.')
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
    },
    async addInBulkaddQuestionStep1(obj) {
      this.files = [];
      this.tempQuestion = {
        questionNumber: this.selectedExamSubject.questions.length + 1,
        question: obj.Question,
        type: "Single Answer MCQ",
        mark: {
          plus: obj.PositiveMarks,
          minus: obj.NegativeMarks
        },
        options: [obj.Option1, obj.Option2, obj.Option3, obj.Option4],
        answer: obj.CorrectOption,
        imageLink: []
      };
      // this.addingQuestion = true
      this.downloadURL = [];
      this.images = [];
      this.others = [];
      this.uploadCount = 0;
      await this.addQuestion();
    },
    async addInBulk(jsonObject) {
      for (var i = 0; i < jsonObject.length; i++) {
        var obj = jsonObject[i];
        console.log(obj);
        await this.addInBulkaddQuestionStep1(obj);
      }
      // dfdf
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          console.log("selected");
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      var formData = new FormData();
      var imagefile = document.querySelector("#sampleFile");
      formData.append("sampleFile", imagefile.files[0]);
      console.log(">> formData >> ", formData);
      axios
        .post(
          "https://cors-anywhere.herokuapp.com/https://xlsprocessapp.herokuapp.com/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(
          response => {
            console.log(response.data);
            this.addInBulk(response.data);
          },
          error => {
            console.log(error);
          }
        )
        .then(() => {
          this.isSelecting = false;
        });
      // do something
    },
    downloadSample() {
      this.isDownloading = true;
      axios({
        url:
          "https://cors-anywhere.herokuapp.com/https://xlsprocessapp.herokuapp.com/file",
        method: "GET",
        responseType: "blob"
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Sample_Questionnaire.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
        this.isDownloading = false;
      });
    },
    convertMaths() {
      setTimeout(function() {
        MathJax.typeset();
      }, 500);
      // var math = MathJax.Hub.getAllJax('mathdiv')[0]
      // MathJax.Hub.Queue(['Text', math, s])
    },
    onEditorFocus() {
      console.log("Focused");
      // document.getElementsByClassName('dialogue1').blur()
      // document.getElementsByClassName('dialogue2').blur()
    }
  }
};
</script>
<style scoped src="./activityEditExamSubjects.css"></style>
