<template src='./activityTopicFeedbackActionTemplate.html'>

</template>

<script>
import arrayOps from '../../Services/Utils/arrayOps'
import inputContainer from '../../Components/inputContainer'
import showStatus from '../../NetworkManager/showStatus'

import { convertDate } from '../../Services/Utils/DateTimeFormatter'
import TopicFeedbackActionTemplateRepository from '../../Repository/TopicFeedbackActionTemplate'

export default {
  name: 'activityTopicFeedbackActionTemplate',
  components: {
    inputContainer
  },
  data () {
    return {
      userData: {},
      selectedInstitute: '',
      selectedSemester: {},
      topicFeedbackActionTemplates: [],
      showAddActionDialog: false,
      isEditing: false,
      tempActionName: '',
      tempOption: '',
      tempOptions: [],
      tempTopicFeedbackActionTemplate: {},
      headersForTopicFeedbackActionTemplates: [
        {
          text: 'Action Template Name',
          value: 'topicFeedbackActionTemplateName'
        },
        {
          text: 'Created on',
          value: 'dateOfCreation'
        },
        {
          text: 'Options',
          value: 'actions'
        }
      ],
      headersForTopicFeedbackTemplateActions: [
        {
          text: 'Action',
          value: 'question'
        },
        {
          text: 'Options',
          value: 'options'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  created () {
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.topicFeedbackActionTemplateRepositoryInstance = new TopicFeedbackActionTemplateRepository(this)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.topicFeedbackActionTemplates = []
      try {
        this.topicFeedbackActionTemplates = await this.topicFeedbackActionTemplateRepositoryInstance.getTopicFeedbackActionTemplatesOfAnInstitute({
          instituteId: this.selectedInstitute
        })
      } catch (err) {
        console.log(err)
      }
    },
    openAddActionDialogForViewing (item) {
      this.tempAction = item
      this.tempOption = ''
      this.tempOptions = item.actions
      this.showAddActionDialog = true
      this.isEditing = false
    },
    openAddActionDialogForCreating () {
      this.tempAction = ''
      this.tempOption = ''
      this.tempOptions = []
      this.showAddActionDialog = true
      this.isEditing = true
    },
    closeAddActionDialog () {
      this.tempAction = ''
      this.tempOption = ''
      this.tempOptions = []
      this.showAddActionDialog = false
      this.isEditing = true
    },
    addOption () {
      this.tempOptions.push({
        actionId: this.tempOptions.length + 1,
        action: this.tempOption
      })
    },
    async saveAction () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        createdBy: this.userData.uId,
        topicFeedbackActionTemplateName: this.tempActionName,
        actions: this.tempOptions
      }
      try {
        await this.topicFeedbackActionTemplateRepositoryInstance.createTopicFeedbackActionTemplate(objToPush)
        this.fetchData()
      } catch (err) {
        console.log(err)
        showStatus('Could not create template', 1000, 'error', this)
      }
    },
    deleteOption (option) {
      const index = this.tempOptions.findIndex((elem) => elem.action === option.action)
      if (index > -1) {
        this.tempOptions = arrayOps.removeFromArray(this.tempOptions, index)
      }
    },
    convertDate (item) {
      return convertDate(item)
    }
  }
}
</script>

<style src='./activityTopicFeedbackActionTemplate.css'>

</style>
