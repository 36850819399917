<template src='./activityEditExamRooms.html'>
</template>
<script>
import { subjectTypes } from '../../../Constants/Utils/Statics'
import SemesterUserRepository from '../../../Repository/SemesterUser'
import UserRepository from '../../../Repository/User'
import ExamTimetableRepository from '../../../Repository/ExamTimetable'
import showStatus from '../../../NetworkManager/showStatus'
export default {
  name: 'activityEditExamRooms',
  data () {
    return {
      selectedExam: {},
      selectedExamSubject: {},
      selectedExamTimetable: {},
      loading: true,
      subjectTypes: subjectTypes,
      editingExamTimetable: false,
      tempSelectedExamTimetable: {},
      tempAllocatedStudents: [],
      tempAllocatedFaculties: [],
      userNamesMapping: {},
      indexOfSelectedExamTimetable: -1,
      subjectSelectedTT: false,
      examDates: [],
      modal2: false,
      search1: '',
      search2: '',
      search3: '',
      search4: '',
      headers3: [
        // {
        //   text: 'ID',
        //   value: 'uId'
        // },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Add',
          value: 'add'
        }
      ],
      headers4: [
        // {
        //   text: 'ID',
        //   value: 'uId'
        // },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Remove',
          value: 'remove'
        }
      ],
      headers5: [
        // {
        //   text: 'ID',
        //   value: 'uId'
        // },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Add',
          value: 'add'
        }
      ],
      headers6: [
        // {
        //   text: 'ID',
        //   value: 'uId'
        // },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Remove',
          value: 'remove'
        }
      ],
      allStudents2: [],
      allStudents: [],
      allFaculties: [],
      facultiesMapping: {},
      tempRoom: {},
      tempI: -1,
      confirmDelete: false
    }
  },
  created () {
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.selectedExamSubject = this.$store.getters['liveData/get_selectedExamSubject']
    this.selectedExamTimetable = this.$store.getters['liveData/get_selectedExamTimetable'] ? this.$store.getters['liveData/get_selectedExamTimetable'] : []
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.examTimetableRepositoryInstance = new ExamTimetableRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    const startDate = new Date(this.selectedExam.startDate)
    const endDate = new Date(this.selectedExam.endDate)
    this.examDates = []
    console.log(this.selectedExam.startDate)
    this.examDates.push(('0' + startDate.getDate()).slice(-2) + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + startDate.getFullYear())
      let date = new Date()
      let start = startDate
    for (let i = 0; i < (parseInt(endDate - startDate) / 86400000); i++) {
      date = new Date(date)
      start = date
      date.setDate(start.getDate() + 1)
      this.examDates.push(('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear())
    }
    this.examDates.push(('0' + endDate.getDate()).slice(-2) + '/' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '/' + endDate.getFullYear())
    this.allStudents = []
    console.log('examDates',this.examDates);
    this.allStudents = JSON.parse(JSON.stringify(this.selectedExamSubject.applicableUsers))
    this.allStudents2 = []
    for (let i = 0; i < this.selectedExamTimetable.length; i++) {
      for (let j = 0; j < this.selectedExamTimetable[i].allocatedStudents.length; j++) {
        const user = this.selectedExamTimetable[i].allocatedStudents[j]
        this.allStudents = this.remove_array_element(this.allStudents, this.allStudents.indexOf(user))
      }
    }
    this.fetchData()
    console.log(this.selectedExam, this.selectedExamSubject, this.selectedExamTimetable)
  },
  methods: {
    async fetchData () {
      let promises = []
      this.allStudents.forEach((student) => {
        promises.push(this.getNameOfUser(student))
      })
      promises = await Promise.allSettled(promises)
      for (let i = 0; i < promises.length; i++) {
        this.allStudents2[i] = {
          name: promises[i].value,
          uId: this.allStudents[i]
        }
      }
      // for (let i = 0; i < this.allStudents.length; i++) {
      //   this.allStudents2[i] = {
      //     uId: this.allStudents[i],
      //     name: await this.getNameOfUser(this.allStudents[i])
      //   }
      // }
      let facultyList = []

      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        facultyList = await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute(objToPush)
      } catch (err) {
        console.log(err)
      }

      this.allFaculties = []
      this.facultiesMapping = {}

      let promises2 = []
      facultyList.forEach((faculty) => {
        promises2.push(this.getNameOfUser(faculty.uId))
      })
      promises2 = await Promise.allSettled(promises2)
      for (let i = 0; i < promises2.length; i++) {
        this.allFaculties[i] = {
          name: promises2[i].value,
          uId: facultyList[i].uId
        }
        this.facultiesMapping[facultyList[i].uId] = promises2[i].value
      }
      // for (let i = 0; i < facultyList.length; i++) {
      //   this.allFaculties[i] = {
      //     uId: facultyList[i].uId,
      //     name: await this.getNameOfUser(facultyList[i].uId)
      //   }
      //   this.facultiesMapping[facultyList[i].uId] = this.allFaculties[i].name
      // }
      console.log(this.allFaculties)
      if (this.selectedExamTimetable.length === 0) {
        this.startAddingRoom()
      }
      this.loading = false
    },
    async getNameOfUser (uId) {
      let name = ''
      try {
        if (this.userNamesMapping[uId]) {
          name = this.userNamesMapping[uId]
        } else {
          name = await this.userRepositoryInstance.getFullNameOfUser({ uId: uId })
          this.userNamesMapping[uId] = name
        }
      } catch (err) {
        console.log(err)
        name = ''
      }
      return name
    },
    goBack () {
      this.$router.go(-1)
    },
    tConvert (time) {
      time = time.split('_').join(':')
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
      if (time.length > 1) { // If time format correct
        time = time.slice(1) // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
        time[0] = +time[0] % 12 || 12 // Adjust hours
      }
      return time.join('') // return adjusted time or original string
    },
    convertSecondstoTimeString (duration) {
      const hours = Math.floor(duration / 3600)
      const dur = duration % 3600
      const minutes = Math.floor(dur / 60)
      const seconds = dur % 60
      if (hours === 0) {
        return minutes + ' Minutes ' + seconds + ' Seconds'
      } else {
        return hours + ' Hrs ' + minutes + ' Mins ' + seconds + ' Secs'
      }
    },
    async deleteRoom (item, i) {
      console.log(item, i)
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: this.selectedExam.examId,
          roomId: this.selectedExamTimetable.roomId
        }
        await this.examTimetableRepositoryInstance.deleteExamTimetableForALocation(objToPush)
        this.selectedExamTimetable = this.remove_array_element(this.selectedExamTimetable, i)
        this.confirmDelete = false
      } catch (err) {
        console.log(err)
        showStatus('Could not delete location', 1000, 'error', this)
      }
      // axios.post(apiConfig.deleteExamTimetable, item).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Could not connect to server. Please try later.')
      // })
    },
    async startEditingRoom (item, i) {
      this.loading = true
      this.editingExamTimetable = true
      this.tempSelectedExamTimetable = JSON.parse(JSON.stringify(item))
      this.tempSelectedExamTimetable.duration = (parseInt(this.tempSelectedExamTimetable.duration) / 60).toString()
      this.tempAllocatedStudents = []
      this.tempAllocatedFaculties = []
      // for (let i = 0; i < this.tempSelectedExamTimetable.allocatedStudents.length; i++) {
      //   this.tempAllocatedStudents.push({
      //     uId: this.tempSelectedExamTimetable.allocatedStudents[i],
      //     name: this.tempSelectedExamTimetable.allocatedStudents[i]
      //   })
      // }
      let promises = []
      this.tempSelectedExamTimetable.allocatedStudents.forEach((student) => {
        promises.push(this.getNameOfUser(student))
      })
      promises = await Promise.allSettled(promises)
      for (let i = 0; i < promises.length; i++) {
        this.tempAllocatedStudents[i] = {
          name: promises[i].value,
          uId: this.tempSelectedExamTimetable.allocatedStudents[i]
        }
      }
      for (let i = 0; i < this.tempSelectedExamTimetable.facultiesAssigned.length; i++) {
        this.tempAllocatedFaculties.push({
          uId: this.tempSelectedExamTimetable.facultiesAssigned[i],
          name: this.facultiesMapping[this.tempSelectedExamTimetable.facultiesAssigned[i]]
        })
      }
      this.indexOfSelectedExamTimetable = i
      console.log(this.indexOfSelectedExamTimetable)
      this.loading = false
      this.subjectSelectedTT = true
    },
    addStudentInTimetable (item) {
      this.allStudents2 = this.remove_array_element(this.allStudents2, this.allStudents2.indexOf(item))
      this.tempAllocatedStudents.push(item)
      this.tempSelectedExamTimetable.allocatedStudents.push(item.uId)
    },
    removeStudentFromTimetable (item) {
      this.tempAllocatedStudents = this.remove_array_element(this.tempAllocatedStudents, this.tempAllocatedStudents.indexOf(item))
      this.tempSelectedExamTimetable.allocatedStudents = this.remove_array_element(this.tempSelectedExamTimetable.allocatedStudents, this.tempSelectedExamTimetable.allocatedStudents.indexOf(item.uId))
      this.allStudents2.push(item)
    },
    addAllStudents () {
      if (this.allStudents2.length !== 0) {
        this.addStudentInTimetable(this.allStudents2[0])
        this.addAllStudents()
      }
    },
    removeFacultyFromTimetable (item) {
      this.tempAllocatedFaculties = this.remove_array_element(this.tempAllocatedFaculties, this.tempAllocatedFaculties.indexOf(item))
      this.tempSelectedExamTimetable.facultiesAssigned = this.remove_array_element(this.tempSelectedExamTimetable.facultiesAssigned, this.tempSelectedExamTimetable.facultiesAssigned.indexOf(item.uId))
      console.log(this.allFaculties.indexOf(item))
      this.allFaculties.push(item)
    },
    addFacultyInTimetable (item) {
      this.allFaculties = this.remove_array_element(this.allFaculties, this.allFaculties.indexOf(item))
      if (this.tempSelectedExamTimetable.facultiesAssigned.indexOf(item.uId) === -1) {
        this.tempAllocatedFaculties.push(item)
        this.tempSelectedExamTimetable.facultiesAssigned.push(item.uId)
      }
    },
    startAddingRoom () {
      this.editingExamTimetable = false
      this.tempSelectedExamTimetable = {
        instituteId: this.selectedExam.instituteId,
        semId: this.selectedExam.semId,
        examId: this.selectedExam.examId,
        date: '',
        startTime: '',
        duration: 60,
        // subjectCode: this.selectedExamSubject.subjectCode,
        // subjectName: this.selectedExamSubject.subjectName,
        // subjectType: this.selectedExamSubject.subjectType,
        subjectId: this.selectedExamSubject.subjectId,
        facultiesAssigned: [],
        allocatedStudents: [],
        location: ''
      }
      this.tempAllocatedStudents = []
      this.tempAllocatedFaculties = []
      this.subjectSelectedTT = true
    },
    async addRoom () {
      if (this.editingExamTimetable) {
        const objToPush = JSON.parse(JSON.stringify(this.tempSelectedExamTimetable))
        console.log(objToPush)
        objToPush.duration = parseInt(objToPush.duration) * 60
        objToPush.date = new Date(objToPush.date.split('/').reverse().join('-')).toISOString()
        try {
          await this.examTimetableRepositoryInstance.updateExamTimetableForALocation(objToPush)
          // this.examTimetables.push(JSON.parse(JSON.stringify(this.tempSelectedExamTimetable)))
          // this.selectedExamTimetable[this.indexOfSelectedExamTimetable] = JSON.parse(JSON.stringify(this.tempSelectedExamTimetable))
          this.tempSelectedExamTimetable = {
            instituteId: this.selectedExam.instituteId,
            semId: this.selectedExam.semId,
            examId: this.selectedExam.examId,
            date: '',
            startTime: '',
            duration: 60,
            // subjectCode: this.selectedExamSubject.subjectCode,
            // subjectName: this.selectedExamSubject.subjectName,
            // subjectType: this.selectedExamSubject.subjectType,
            subjectId: this.selectedExamSubject.subjectId,
            facultiesAssigned: [],
            allocatedStudents: [],
            location: ''
          }
          this.tempAllocatedStudents = []
          this.tempAllocatedFaculties = []
          this.selectedExamTimetable[this.indexOfSelectedExamTimetable] = (JSON.parse(JSON.stringify(objToPush)))
          this.subjectSelectedTT = false
        } catch (err) {
          console.log(err)
          showStatus('Could not update', 1000, 'error', this)
        }
        // axios.post(apiConfig.updateExamTimetable, objToPush).then(res => {
        //   console.log(res)
        // }).catch(err => {
        //   console.log(err)
        //   window.alert('Could not connect to server. Please try later.')
        // })
      } else {
        const objToPush = JSON.parse(JSON.stringify(this.tempSelectedExamTimetable))
        objToPush.duration = parseInt(objToPush.duration) * 60
        objToPush.date = new Date(objToPush.date.split('/').reverse().join('-')).toISOString()
        console.log(objToPush)
        // Call APi to create exam timetable
        try {
          await this.examTimetableRepositoryInstance.createExamTimetable(objToPush)
          this.selectedExamTimetable.push(JSON.parse(JSON.stringify(objToPush)))
          this.tempSelectedExamTimetable = {
            instituteId: this.selectedExam.instituteId,
            semId: this.selectedExam.semId,
            examId: this.selectedExam.examId,
            date: '',
            startTime: '',
            duration: 60,
            // subjectCode: this.selectedExamSubject.subjectCode,
            // subjectName: this.selectedExamSubject.subjectName,
            // subjectType: this.selectedExamSubject.subjectType,
            subjectId: this.selectedExamSubject.subjectId,
            facultiesAssigned: [],
            allocatedStudents: [],
            location: ''
          }
          this.tempAllocatedStudents = []
          this.tempAllocatedFaculties = []
          this.subjectSelectedTT = false
        } catch (err) {
          console.log(err)
          showStatus('Could not update', 1000, 'error', this)
        }
        // axios.post(apiConfig.createExamTimetable, objToPush).then(res => {
        //   console.log(res)
        // }).catch(err => {
        //   console.log(err)
        //   window.alert('Could not connect to server. Please try later.')
        // })
      }
    },
    cancelAddingRoom () {
      if (this.editingExamTimetable) {
        this.tempSelectedExamTimetable = {
          instituteId: this.selectedExam.instituteId,
          semId: this.selectedExam.semId,
          examId: this.selectedExam.examId,
          date: '',
          startTime: '',
          duration: 60,
          // subjectCode: this.selectedExamSubject.subjectCode,
          // subjectName: this.selectedExamSubject.subjectName,
          // subjectType: this.selectedExamSubject.subjectType,
          subjectId: this.selectedExamSubject.subjectId,
          facultiesAssigned: [],
          allocatedStudents: [],
          location: ''
        }
        this.tempAllocatedStudents = []
        this.tempAllocatedFaculties = []
        this.indexOfSelectedExamTimetable = -1
        this.subjectSelectedTT = false
      } else {
        this.subjectSelectedTT = false
      }
    },
    remove_array_element (array, n) {
      if (n > -1) {
        array.splice(n, 1)
      }
      return array
    }
  }
}
</script>
<style scoped src='./activityEditExamRooms.css'>
</style>
