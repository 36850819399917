<template src="./activityManageExamsRevamped.html"></template>

<script>
import examsBasicDetails from "./examsBasicDetails/examsBasicDetails";
import examSubjectDetails from "./examSubjectDetails/examSubjectDetails";
import examForm from "./examForm/examForm";
import NewExamRepository from "../../Repository/NewExam";
import hallTicket from "./hallTicket/hallTicket.vue";
import examQuestionDetails from "./examQuestionDetails/examQuestionDetails";
import examMarks from "./examMarks/examMarks";
import { examCategories, getExamCategoryObject } from "../../Constants/Utils/Statics";
import TextTag from "../../Components/styleGuide/TextTag.vue";

export default {
  name: "activityManageExamsRevamped",
  components: {
    examsBasicDetails,
    examSubjectDetails,
    examForm,
    hallTicket,
    examQuestionDetails,
    examMarks,
    TextTag,
  },
  props: ["prop_tabNumber", "prop_examCategory", "prop_exam"],
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      downArrow: "mdi-chevron-down",
      upArrow: "mdi-arrow-up",
      examTypes: ["Exam"], //'Theory', 'Online'
      examType: "",
      mainTabNames: [
        {
          text: "Basic Details",
          disabled: false,
        },
        {
          text: "Subject Details",
          disabled: false,
        },
        {
          text: "Exam Form",
          disabled: true,
        },
        {
          text: "Hall Ticket",
          disabled: true,
        },
        {
          text: "Question & Answers",
          disabled: false,
        },
        {
          text: "Student Marks",
          isHidden: false,
        },
      ],
      components: ["examsBasicDetails", "examSubjectDetails", "examForm"],
      currentTab: 0,
      tabs: 0,
      selectedInstitute: "",
      selectedSemester: "",
      selectedDepartment: "",
      selectedCourseYear: "",
      examName: null,
      examFormat: null,
      showExamForm: false,
      showExamHallTicket: false,
      // showExamForm:false,
      currentExamCategory: examCategories[0].value,
      isRegularExam: true,
      examTagDetails: {}
    };
  },
  async created() {
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    if (this.prop_tabNumber) this.currentTab = this.prop_tabNumber;
    if (this.prop_examCategory) {
      this.currentExamCategory = this.prop_examCategory;
    }
    this.isRegularExam = this.prop_exam?.isRegularExam ?? true;
    this.createMap();
  },
  computed: {
    currentTabComponent() {
      return this.components[this.currentTab];
    },
  },
  methods: {
    goToExams() {
      const objToPush = {
        prop_department: this.department,
        prop_course: this.course,
        prop_selectedSubject: this.selectedSubject,
        prop_typeDivGrpBatch: this.typeDivGrpBatch,
        prop_selectedDivision: this.selectedDivision,
        prop_selectedBatch: this.selectedBatch,
        prop_selectedGroup: this.selectedGroup,
        prop_selectedSemester: this.selectedSemester,
        prop_selectedFaculty: this.selectedFaculty,
      };
      //   if (exam) {
      //     objToPush['prop_exam'] = exam
      //   }
      this.$store.commit("liveData/set_selectedExam", null);
      this.$router.push({
        name: "activityExams1",
      });
    },
    setCurrentTab(tabIndex) {
      this.currentTab = tabIndex;
    },
    // handleTabs() {
    //   this.getExamTabDependencies()
    // },
    // getExamTabDependencies(){
    //   let dep = this.examTabDependecies =
    //   this.$store.getters["liveData/get_examTabDependencies"];
    //   console.log('dep', dep);
    // },
    createMap() {
      let examTabDependencies = {
        basicDetailsTab: {
          subjectDetailsTab: false,
          questionAndAnswersTab: false,
          studentMarksTab: false,
          examFormTab: false,
          hallTicketTab: false,
        },
        subjectDetailsTab: {
          questionAndAnswersTab: false,
          studentMarksTab: false,
          examFormTab: false,
          hallTicketTab: false,
          questionAndAnswersTab: false,
          studentMarksTab: false,
        },
        invigilationTab: {},
        controlAccesTab: {},
        examFormTab: {
          hallTicketTab: false,
        },
        hallTicketTab: {},
        questionAndAnswersTab: {},
        studentMarksTab: {},
      };
      this.$store.commit(
        "liveData/set_examTabDependencies",
        examTabDependencies
      );
    },
    handleNextButtonClick() {
      // Increment the currentTab value to move to the next tab
      this.currentTab += 1;
    },
    updateExamName(newExamName) {
      this.examName = newExamName;
    },
    handleExtraOptions(param) {
      let objToPush = {};
      Object.entries(param).forEach(([key, value], index) => {
        if (key === "examForm") {
          objToPush = {
            text: "Exam Form",
            disabled: !param.examForm,
          };
        } else {
          objToPush = {
            text: "Hall Ticket",
            disabled: !param.hallticket,
          };
        }
        this.$set(this.mainTabNames, index + 2, objToPush);
      });
    },
    examDetails(data) {
      let { examAttemptNumber, isRegularExam } = data  
      let temp = getExamCategoryObject(data.examCategory)
      this.examTagDetails = {...temp}
      if(!isRegularExam) {
        this.examTagDetails['examAttemptNumber'] = examAttemptNumber - 1
      }
    },
    updateExamFormat(newExamFormat) {
      this.examFormat = newExamFormat;
    },
    updateExamFormCheckbox(showExamForm) {
      this.showExamForm = showExamForm;
    },
    updateHallTicketCheckbox(showExamHallTicket) {
      this.showExamHallTicket = showExamHallTicket;
    },
  },
};
</script>

<style src="./activityManageExamsRevamped.css"></style>
