<template src='./activityTakeEventAttendace.html'></template>

<script>
import UserRepository from '../../Repository/User'
import LectureRepository from '../../Repository/Lecture'
import TopicRepository from '../../Repository/Topic'
import SemesterUserRepository from '../../Repository/SemesterUser'
import OnlineLectureRepository from '../../Repository/OnlineLecture'
import showStatus from '../../NetworkManager/showStatus'
import getNamesOfUsers from '../../Services/Utils/getNamesOfUsers'
import ZoomMeetingRepository from '../../Repository/zoomMeeting'
import apiV3 from '../../NetworkManager/apiV3'
import { v4 as uuidv4 } from 'uuid';
import { createExcel, createExcelThroughJson, createExcel1 } from '../../utils/excel';
import QrcodeVue from 'qrcode.vue'
import universityEvents from "../../Repository/universityEvents";
import instituteUser from "../../Repository/InstituteUser";
import Institutes from "../../Repository/Institute";
import auth from '../../Services/Firebase/auth'
import {
  convertDate,
  formatToSlashSepearated,
  timeStringConverter,
  convertFromYYYYMMDDToDate, convertUTCDateToLocalDate,
  convertTime,
} from "../../Services/Utils/DateTimeFormatter";
export default {
  name: 'activityTakeEventAttendace',
  props: [
    'prop_Event'
  ],
  data() {
    return {
      qrUpdateIntervalId: '',
      qrGetUpdateDataIntervalId: '',
      qrCodeData: '',
      qrCodeSize: 500,
      showQrCode: false,
      lectureForGroup: false,
      markAttendance: false,
      progressBar: false,
      progressBarCompleted: false,
      attendance: [],
      selection: [],
      totalConductedLectures: 0,
      topicsOfLecture: [],
      showTopicsOfLecture: false,
      dialog: false,
      search: '',
      loading: false,
      event: {},
      attData: {},
      keys: [],
      // headers: [
      //   {
      //     text: 'Name',
      //     value: 'fullName'
      //   },
      // ],
      headers: [
        {
          text: 'Name',
          value: 'fullName'
        },
        {
          text: 'Roll No',
          value: 'rollNumber'
        },
        {
          text: 'Email ID',
          value: 'userName'
        },
        {
          text: 'Attended Status',
          value: 'attendedStatus'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Department',
          value: 'department'
        }
      ],


      headers2: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Duration',
          value: 'duration'
        }
      ],
      headersMobile: [
        {
          text: 'Roll Number',
          value: 'rollNumber',
          width: '100px'
        },
        {
          text: 'Name',
          value: 'name'
        }
      ],
      lectureDuration: 0,
      showOnlineAttendance: false,
      isMobileView: false,
      onlineStudents: [],
      users: [],
      studentsMapping: {},
      presentStudentsList: [],
      institutes: [],
      proxyStudentDetails: [],
      threshold: 0,
      ex3: { label: 'Set Limit', color: 'red' },
      isLoadingData: false,
      showAttendance: false,
      loadTable: false,
      proxyDialog: false,

    }
  },
  components: {
    QrcodeVue
  },
  async created() {
    this.updateAttendanceData()
    this.$store.commit('liveData/set_selectedActivityName', 'Mark Event Attendance')
    this.keys = Object.values(this.attData);
    this.event = this.prop_Event
    // console.log('this.event', this.event);
    this.userRepositoryInstance = new UserRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.topicRepositoryInstance = new TopicRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this)
    this.zoomMeetingInstance = new ZoomMeetingRepository(this)
    this.universityEventsInstance = new universityEvents(this);
    this.InstituteUserInstance = new instituteUser(this);
    this.InstituteInstance = new Institutes(this);
    this.$store.commit("liveData/set_proxyData", []);
    this.$store.commit("liveData/set_studentAttendance", []);

    this.userData = this.$store.getters['user/get_userData']
    await this.getInstitutesName(this.prop_Event.applicableInstitutes)
    this.getData(this.prop_Event.applicableInstitutes)
  },
  methods: {
    async eventBackupData() {
      let data = await auth.scriptForBackCollection()
      // console.log('data', data);
      let keys = Object.keys(data)
      // console.log('keys', keys);
      // console.log('data[keys[1]]', data[keys[0]]);
      for (let i = 0; i < keys.length; i++) {

        await auth.createBackupDocumentForLec({
          eventId: keys[i],
          allScanData: data[keys[i]],
        })
      }
    },
    downloadExcelData(tableId) {
      // createExcel(tableId)
      const filteredArray = [
        ...this.selection.map(item => ({
          name: item.fullName,
          courseYear: item.courseYear,
          rollNo: item.rollNumber,
          userName: item.userName,
          division: item.division,
          department: item.department
        }))
      ];
      createExcelThroughJson(filteredArray, "eventAttendanceData", this.prop_Event.title, this.prop_Event.description);
    },
    async addDataToBAckUp(keys) {
      await auth.createBackupDocumentForLec({
        eventId: this.event.eventId,
        allScanData: keys,
      })
    },
    async closeQrCode() {
      this.proxyDialog = false;
      const time = (new Date()).toISOString()
      let todayDate = time.split('T')[0]
      this.proxyStudentDetails = []
      let uIdForProxy = []
      this.selection = []
      let uniqueArr = []
      
      let dataOfScan = await auth.getEventAttendanceFirebase('qrCodeEventAttendance', this.event.eventId);
      let allKeys = Object.values(dataOfScan)
      if (allKeys.length > 0) {
        allKeys.map((data) => {
          if (data.status === 5 && todayDate === data.timeOfScan.substring(0, 10)) {
            if (!uIdForProxy.includes(data.uId)) {
              this.proxyStudentDetails.push(data)
              uIdForProxy.push(data.uId)
            }
          }
        })
      }
      await this.addDataToBAckUp(allKeys)
      this.attData = await auth.getEventAttendanceFirebase('EventAttendanceBackup', this.event.eventId);
      if (this.attData.length > 0) {
        this.attData.map((data) => {
          if (data.status === 1 || data.status === 2) {
            if (!uniqueArr.includes(data.uId)) {
              this.selection.push(data)
              uniqueArr.push(data.uId)
            }
          }
        })
      }
      clearInterval(this.qrUpdateIntervalId)

      await this.updateUniversityEvent()
      this.showQrCode = false
      if (this.proxyStudentDetails.length > 0) {
        this.proxyDialog = true;
      }
    },
    markAbsentForProxy() {
      const dummy = []
      let arrOfProxyUid = []
      this.proxyStudentDetails.map((data) => {
        arrOfProxyUid = [...arrOfProxyUid, ...data.uIds]
      })
      this.selection.map((stu) => {
        if (!arrOfProxyUid.includes(stu.uId)) {
          dummy.push(stu)
        }
      })
      this.selection = dummy
      this.proxyDialog = false
    },

    async getInstitutesName(applicableInstitutes) {
      this.loadTable = true
      const objToPush = {
        instituteIds: applicableInstitutes
      }
      this.institutes = await this.InstituteInstance.getInstitutesNames(objToPush)
    },
    async getData(applicableInstitutes) {
      this.$store.commit("liveData/set_studentAttendance", []);

      this.loadTable = true
      const objToPush = {
        instituteIds: applicableInstitutes
      }

      // this.selection = this.$store.getters['liveData/get_studentAttendance']
      this.selection = []
      this.attData = await auth.getEventAttendanceFirebase('EventAttendanceBackup', this.prop_Event.eventId);
      // console.log('this.attData', this.attData);
      // this.keys = Object.values(this.attData);
      if (this.attData.length > 0) {
        this.attData.map((data) => {
          if (data.status === 1 || data.status === 2) {
            this.selection.push(data)
          }
        })
      }
      // this.selection = this.keys;
      let unique = []
      let checkArr = []
      this.selection.forEach(item => {
        item.attendedStatus = 'Present';
        item.eventTitle = this.prop_Event.title;
        item.dateOfEvent = this.convertUtcDate(this.prop_Event.dateTime)
        if (!unique.includes(item.userName)) {

          unique.push(item.userName)
          checkArr.push(item)
        }
      });

      this.selection = checkArr
      // this.users = await this.InstituteUserInstance.getMultiInstituteUsers(objToPush)
      // this.selection = this.users.filter(user => this.prop_Event?.present.some(p => p.uId === user.uId));
      this.loadTable = false
    },
    async updateUniversityEvent() {
      let present = this.selection.map((e) => { return { instituteId: e.instituteId, uId: e.uId } })
      let uIdArr = []
      let arr = []
      if (present.length > 0) {
        present.map((data) => {
          if (!uIdArr.includes(data.uId)) {
            arr.push(data)
            uIdArr.push(data.uId)
          }
        })
      }
      const objToPush = {
        present: arr,
        eventId: this.event.eventId
      }
      this.events = await this.universityEventsInstance.updateUniversityEvent(objToPush)
      this.loadTable = false
    },
    updateQRData() {
      const tokenForQr = uuidv4()
      this.$store.commit('liveData/set_tokenForQr', tokenForQr)
      const data = {
        eventId: this.event.eventId,
        applicableInstitutes: this.event.applicableInstitutes,
        token: tokenForQr
      }
      this.qrCodeData = JSON.stringify(data)
      // console.log('this.qrCodeData', this.qrCodeData);
    },
    async generateQR() {
      this.$store.commit("liveData/set_studentAttendance", []);
      this.$store.commit("liveData/set_presentStudentArr", []);
      let value1 = this.$store.getters['liveData/get_presentStudentArr']
      // console.log('value1', value1);
      this.$store.commit("liveData/set_presentStudentArr", []);
      this.$store.commit("liveData/set_studentAttendance", this.selection);
      this.$store.commit("liveData/set_proxyData", []);
      let arrForId = []
      this.selection.map((info) => {
        arrForId.push(info.uId)
      })
      this.$store.commit("liveData/set_presentStudentArr", arrForId);
      // console.log('arrForId', arrForId);
      try {
        const tokenForQr = uuidv4()
        this.$store.commit('liveData/set_tokenForQr', tokenForQr)
        const data = {
          eventId: this.event.eventId,
          applicableInstitutes: this.event.applicableInstitutes,
          token: tokenForQr
        }
        this.qrCodeData = JSON.stringify(data)
        this.showQrCode = true
        this.qrUpdateIntervalId = setInterval(this.updateQRData, 10000);
        // setInterval(this.updateAttendanceData, 1500);

        auth.getEventAttendance({
          eventId: this.event.eventId,
          applicableInstitutes: this.event.applicableInstitutes
        })
        // this.updateAttendanceData()
      } catch (err) {
        showStatus('An unknown error occured, try later or contact admin', 6000, 'error', this)
        console.error("Error:", err)
      }
    },
    async updateAttendanceData() {
      this.attData = await auth.getEventAttendanceFirebase('EventAttendanceBackup', this.prop_Event.eventId);
      // this.keys = Object.values(this.attData);
      //  console.log(' this.attData',  this.attData);
      let unique = []
      this.selection = []
      let checkArr = []
      // let uniqueArr = []
      if (this.attData.length > 0) {
        this.attData.map((data) => {
          if (data.status === 1 || data.status === 2) {
            this.selection.push(data)
          }
        })
      }
      this.selection.forEach(item => {
        item.attendedStatus = 'Present';
        item.eventTitle = this.prop_Event.title;
        item.dateOfEvent = this.convertUtcDate(this.prop_Event.dateTime)
        if (!unique.includes(item.userName)) {

          unique.push(item.userName)
          checkArr.push(item)
        }
      });
      this.selection = checkArr
    },
    async fetchSemesterUsers() {
      this.isLoadingData = true
      if (!this.lectureForGroup) {

        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture.semId,
          department: this.prop_lecture.department,
          courseYear: this.prop_lecture.courseYear,
          division: this.prop_lecture.division,
          batch: this.prop_lecture.batch || '',
          subjectId: this.prop_lecture.subjectId,
          uId: this.userData.uId
        }

        try {

          if (this.prop_lecture.multiDivision && this.prop_lecture.multiDivision.length > 0) {
            objToPush.divisions = this.prop_lecture.multiDivision
            this.attendance = await this.semesterUserRepositoryInstance.getSemesterUsersForMultipleDivision(objToPush)
          } else {
            this.attendance = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfADivision(objToPush)
          }
          this.attendance.sort((a, b) => a.rollNumber - b.rollNumber)
          this.attendance.forEach(student => {
            this.studentsMapping[student.uId] = student
          })
        } catch (err) {
          // console.error(err)
          console.error(err)
          this.attendance = []
        }
      } else {
        // getSemesterUsersOfASubject
        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture.semId,
          subjectId: this.prop_lecture.subjectId,
        }
        try {
          this.attendance = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(objToPush)
          this.attendance.sort((a, b) => a.rollNumber - b.rollNumber)
          let filterStudent = []
          this.attendance.map((stu) => {
            if (stu.assignedGroupForSubject.length > 0) {
              stu.assignedGroupForSubject.map((grp) => {
                if (grp.groupName === this.prop_lecture.groupForSubject[0].groupName && grp.groupId === this.prop_lecture.groupForSubject[0].groupId) {
                  filterStudent.push(stu)
                }
              })
            }
          })
          this.attendance = filterStudent
          this.attendance.sort((a, b) => a.rollNumber - b.rollNumber)
          this.attendance.forEach(student => {
            this.studentsMapping[student.uId] = student
          })

        } catch (error) {
          console.error('error', error);
          this.attendance = []
        }
      }

      this.checkAndLoadPreviousAttendance(this.prop_lecture.present)
      await Promise.all([
        this.getConductedLecturesCountOfASubject(),
        this.getTotalAttendedLecturesForUsers(),
        this.addEntryForSemesterUsers()
      ])

      this.attendance = this.attendance.slice()
      this.isLoadingData = false
    },
    async addEntryForSemesterUsers() {
      await getNamesOfUsers(this.attendance, 'name', this.userRepositoryInstance)
    },
    async getFullNameOfUser(indexOfSemesterUserInAttendance) {
      try {
        if (this.attendance[indexOfSemesterUserInAttendance].uId) {
          this.attendance[indexOfSemesterUserInAttendance].name = await this.userRepositoryInstance.getFullNameOfUser({ uId: this.attendance[indexOfSemesterUserInAttendance].uId })
        }
      } catch (err) {
        console.error(err)
        this.attendance[indexOfSemesterUserInAttendance].name = ''
      }
    },
    async getTotalAttendedLecturesForUsers() {
      this.showAttendance = false
      try {
        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture.semId,
          department: this.prop_lecture.department,
          courseYear: this.prop_lecture.courseYear,
          division: this.prop_lecture.division,
          batch: this.prop_lecture.batch || '',
          subjectId: this.prop_lecture.subjectId,
          uId: this.prop_lecture.uId
        }
        const studentsList = await this.lectureRepositoryInstance.getTotalAttendedLecturesForADivisionForSubject(objToPush)
        for (let i = 0; i < this.attendance.length; i++) {
          this.attendance[i].totalAttendedLectures = studentsList[this.attendance[i].uId] || 0
          if (this.totalConductedLectures > 0) {
            this.attendance[i].percentage = ((parseInt(this.attendance[i].totalAttendedLectures) / this.totalConductedLectures) * 100).toFixed(2)
          } else {
            this.attendance[i].percentage = 0
          }
        }
      } catch (err) {
        console.error(err)
        this.showAttendance = true
      }
      this.showAttendance = true
    },
    async getConductedLecturesCountOfASubject() {
      try {
        const objToPush = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture.semId,
          department: this.prop_lecture.department,
          courseYear: this.prop_lecture.courseYear,
          division: this.prop_lecture.division,
          batch: this.prop_lecture.batch || '',
          subjectId: this.prop_lecture.subjectId,
          uId: this.userData.uId
        }
        this.totalConductedLectures = await this.lectureRepositoryInstance.getConductedLecturesCountOfASubject(objToPush)
      } catch (err) {
        console.error(err)
        this.totalConductedLectures = 0
      }
    },
    checkAndLoadPreviousAttendance(presentStudentsList) {
      const semesterUserObjectsOfPresentStudents = this.attendance.filter((studentObject) => presentStudentsList.includes(studentObject.uId))
      this.selection = semesterUserObjectsOfPresentStudents
    },
    async submitAttendance() {
      const objToPush = JSON.parse(JSON.stringify(this.prop_lecture))
      objToPush.present = []
      objToPush.absent = []
      this.attendance.forEach((student) => {
        if (this.selection.includes(student)) {
          objToPush.present.push(student.uId)
        } else {
          objToPush.absent.push(student.uId)
        }
      })
      try {
        const response = await this.lectureRepositoryInstance.saveAttendance(objToPush)
        // showStatus('Attendance saved successfully', 1000, 'success', this)
        await this.fetchTopicsOfALecture()
      } catch (err) {
        console.error(err)
      }
      // auth.deleteDocumentFromFirebase(this.prop_lecture.lectureId)
      this.$store.commit('liveData/set_tokenForQr', '')
      this.$store.commit("liveData/set_studentAttendance", []);
      this.$store.commit("liveData/set_proxyData", []);
    },
    async fetchTopicsOfALecture() {
      const objToPush = {
        instituteId: this.prop_lecture.instituteId,
        semId: this.prop_lecture.semId,
        lectureId: this.prop_lecture.lectureId
      }
      try {
        const response = await this.topicRepositoryInstance.getTopicOfALecture(objToPush)
        this.showTopicsOfLecture = true
        this.topicsOfLecture = response
      } catch (err) {
        console.error(err)
      }
    },
    async handleOnClickTopic(topic) {
      this.loading = true
      try {
        const objToPush = {
          instituteId: topic.instituteId,
          semId: topic.semId,
          topicId: topic.topicId,
          lectureId: topic.lectureId
        }
        if (topic.isImplemented) {
          await this.topicRepositoryInstance.markAsUnimplemented(objToPush)
        } else {
          await this.topicRepositoryInstance.markAsImplemented(objToPush)
        }
        topic.isImplemented = !topic.isImplemented
        this.loading = false
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    },
    async getOnlineLectureAttendance() {
      this.loading = true
      this.showOnlineAttendance = false
      try {
        const res = await apiV3.getRequest('/meeting/attendance', {
          lectureId: this.prop_lecture.lectureId
        })
        this.lectureDuration = Math.round(res.totalDuration / 60)
        this.threshold = this.lectureDuration * 0.8
        this.presentStudentsList = res.attendanceList.filter(entry => entry.moderator === false)
        const tempArr = []
        this.attendance.forEach(stud => {
          const students = this.presentStudentsList.filter(stud2 => stud2.ext_user_id === stud.uId)
          if (students.length > 0) {
            const studentDuration = students.reduce((accu, elem) => accu > elem.duration ? accu : elem.duration, 0)
            const student = {
              uId: students[0].ext_user_id,
              duration: Math.round(studentDuration / 60)
            }
            if (student) {
              tempArr.push(student)
            }
          }
        })
        this.presentStudentsList = tempArr
        this.processStudents()
      } catch (err) {
        this.loading = false
        if (err.response.status === 404) {
          showStatus('If lecture was conducted, data is processing....', 6000, 'info', this)
          return
        }
        showStatus('An unknown error occured, try later or contact admin', 6000, 'error', this)
        console.error(err)
      }
    },
    applyLimit(limit) {
      this.processStudents()
    },
    processStudents() {
      this.onlineStudents = []
      this.presentStudentsList.forEach(student => {
        if (student.duration >= Math.round(this.threshold)) {
          const studObj = this.studentsMapping[student.uId]
          if (studObj) {
            this.onlineStudents.push({
              uId: student.uId,
              duration: student.duration,
              name: studObj.name
            })
          }
        }
      })
      this.showOnlineAttendance = true
      this.loading = false
    },
    async saveOnlineLectureAttendance() {
      this.checkAndLoadPreviousAttendance(this.onlineStudents.map(stud => { return stud.uId }))
      this.showOnlineAttendance = false
    },
    async gotoback() {
      this.$router.push({
        name: "activityUniversityEvents",
      });
    },
    convertToDDMMYYYY(dateString) {
      return convertDate(dateString);
    },
    convertUTCtoLocalTimeAMPM(utcDateString) {
      const utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const result = formattedHours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
      return result;
    },
    convertUtcDate(dateTime) {
      return formatToSlashSepearated(dateTime)
    },
  }
}
</script>

<style scoped src='./activityTakeEventAttendace.css'></style>
