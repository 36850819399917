export default {
    getAllFeedbacksByInstituteId: '/feedbackWithNBAAttainment/getAllFeedbacksByInstituteID',
    getAllFeedbacksOfObtainedStudents: '/feedbackWithNBAAttainment/getAllFeedbackForObtainedStudent',
    getFeedbackDetailsByFeedbackId: '/feedbackWithNBAAttainment/getFeedbackByFeedbackID',
    createFeedbackForm: '/feedbackWithNBAAttainment/create',
    updateFeedbackForm: '/feedbackWithNBAAttainment/update',
    updateFormToPublish: '/feedbackWithNBAAttainment/publish',
    generateDemoExcel: '/excel/feedback/generateDemoExcel',
    parseExcel: '/excel/feedback/parse',
    parseExcelWithoutNBA: '/excel/feedback/parseWithoutNBA',
    parseExcelBackdated: '/excel/feedback/parseBackdated',
    generateFeedbackResponseExcel: '/excel/feedback/generateFeedbackResponseExcel',
    deleteForm: '/feedbackWithNBAAttainment/delete'
  }
  