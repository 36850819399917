import { render, staticRenderFns } from "./syllabus.html?vue&type=template&id=610d17be&scoped=true&external"
import script from "./syllabus.vue?vue&type=script&lang=js"
export * from "./syllabus.vue?vue&type=script&lang=js"
import style0 from "./syllabus.css?vue&type=style&index=0&id=610d17be&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610d17be",
  null
  
)

export default component.exports