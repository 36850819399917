export default {
  createTimetableSlot: '/timetable/createSlot',
  createSlotsInBulk: '/timetable/createSlotsInBulk',
  createDummyTimetableSlot: '/timetable/createDummyTimetableSlot',
  getTimetableSlotsForTimeAndForAFaculty: '/timetable/getTimetableSlotsForTimeAndForAFaculty',
  getTimetableSlotsForTimeAndForARoom: '/timetable/getTimetableSlotsForTimeAndForARoom',
  getTimetableOfADivision: '/timetable/getTimetableSlotsOfADivision',
  getTimetableSlotsForCourceYearForGroupOfSubject: '/timetable/getTimetableSlotsForCourceYearForGroupOfSubject',
  getLastUpdatedTimeOfTimetable: '/timetable/getLastUpdatedTimeOfTimetable',
  getFacultiesFreeInATimetableSlot: '/timetable/getFacultiesFreeInATimetableSlot',
  getLocationsFreeInATimetableSlot: '/timetable/getLocationsFreeInATimetableSlot',
  updateFacultyInATimetableSlot: '/timetable/updateFaculty',
  updateRoomInATimetableSlot: '/timetable/updateRoom',
  deleteTimetableSlot: '/timetable/deleteSlot',
  deleteTimetableSlotsInBulk: '/timetable/deleteTimetableSlotsInBulk',
  deletedummyTimetableSlot: '/timetable/deleteTimetableSlot',
  getTimetableSlotsOfAFaculty: '/timetable/getTimetableSlotsOfAFaculty',
  getTimetableSlotsOfAFacultyOfASubject: '/timetable/getTimetableSlotsOfAFacultyOfASubject',
  generateExcelTimetable: '/excel/timetable/generateExcelTimetable',
}
