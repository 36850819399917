const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const formatDateTimeToDate = (dateTime) => {
  return dateTime.substr(0, 10);
};

export function getDay(date) {
  const D = new Date(
    date.split("/")[2],
    parseInt(date.split("/")[1]) - 1,
    date.split("/")[0]
  );
  // console.log(D, date.split('/')[0], (parseInt(date.split('/')[1]) - 1), date.split('/')[2])
  return days[D.getDay()];
}

export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date).toLocaleTimeString("en-US");
  console.log("newDate", newDate);
  return newDate;
}
export function convertLocalToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

export function convertDateTime(date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  const hours = ("0" + d.getHours()).slice(-2);
  const minutes = ("0" + d.getMinutes()).slice(-2);

  // return [year, month, day].join('-')
  return (
    [day, month, year].join("-") +
    " " +
    timeStringConverter(hours + "_" + minutes)
  );
}

export function convertDate(date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  // return [year, month, day].join('-')
  return [day, month, year].join("-");
}

export function convertTime(date) {
  const d = new Date(date);
  const hours = ("0" + d.getHours()).slice(-2);
  const minutes = ("0" + d.getMinutes()).slice(-2);

  return timeStringConverter([hours, minutes].join("_"));
}

export function formatToSlashSepearated(date) {
  const d = new Date(date).toLocaleDateString().split("/");

  return [d[0], d[1], d[2]].join("/");
}

export function convertFromYYYYMMDDToDate(dateString, timeString) {
  const dateArray = dateString.split("-").map((date) => parseInt(date));
  dateArray[1] -= 1;
  // console.log(dateArray)
  return new Date(...dateArray);
}

export function convertToISTTime(date) {
  const currentOffset = date.getTimezoneOffset();

  // IST offset UTC +5:30
  const ISTOffset = 330;

  const ISTTime = new Date(
    date.getTime() + (ISTOffset + currentOffset) * 60000
  );

  // console.log('ISTTime', ISTTime)

  return ISTTime;
}

// export function formatToDashSeparated(date) {
//   let dateSplit = date.split("/");
//   let day = dateSplit[0].length === 1 ? `0${dateSplit[0]}` : dateSplit[0];
//   let month = dateSplit[1].length === 1 ? `0${dateSplit[1]}` : dateSplit[1];
//   let year = dateSplit[2];
//   return [year, month, day].join("-");
// }

export function timeStringConverter(time) {
  time = time.split("_").join(":");
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export function dateTimeStringConverter(dateString) {
  const objToReturn = {
    timeString: "",
    day: "",
    dayOfWeek: "",
    month: "",
    year: "",
    hours: "",
    minutes: "",
    period: "",
  };

  try {
    // Standardize separators to hyphens for Date parsing
    dateString = dateString.replace(/\//g, "-");

    let date;
    if (
      dateString.includes("T") ||
      dateString.includes("Z") ||
      dateString.includes("+")
    ) {
      // Parse as full date-time string
      date = new Date(dateString);
    } else {
      // Handle date-only formats
      date = new Date(dateString);

      if (isNaN(date)) {
        // Attempt manual parsing for date-only strings
        const regex = /(\d{4})-(\d{2})-(\d{2})|(\d{2})-(\d{2})-(\d{4})/;
        const match = dateString.match(regex);

        if (match) {
          if (match[1]) {
            // Format: YYYY-MM-DD
            date = new Date(`${match[1]}-${match[2]}-${match[3]}`);
          } else if (match[4]) {
            // Format: DD-MM-YYYY
            date = new Date(`${match[6]}-${match[5]}-${match[4]}`);
          }
        }
      }
    }

    // Validate the date
    if (isNaN(date)) {
      throw new Error("Invalid date format");
    }

    // Extract date components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Get day of the week as a string
    const dayOfWeek = days[date.getDay()];

    if (
      dateString.includes("T") ||
      dateString.includes("Z") ||
      dateString.includes("+")
    ) {
      // Time component
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;

      objToReturn.timeString = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
      objToReturn.hours = String(hours).padStart(2, "0");
      objToReturn.minutes = minutes;
      objToReturn.period = ampm;
    } else {
      // Date-only format
      objToReturn.timeString = `${day}-${month}-${year}`;
    }

    objToReturn.day = day;
    objToReturn.month = month;
    objToReturn.year = year;
    objToReturn.dayOfWeek = dayOfWeek;
  } catch (error) {
    console.error("Error formatting date:", error);
  }

  return objToReturn;
}

// Example usage:
// console.log(dateTimeStringConverter("2024-05-24")); // '24-05-2024'
// console.log(dateTimeStringConverter("2024/05/24")); // '24-05-2024'
// console.log(dateTimeStringConverter("24-05-2024")); // '24-05-2024'
// console.log(dateTimeStringConverter("2024-11-08T02:59:00Z")); // '08-11-2024 02:59 AM'
// console.log(dateTimeStringConverter("2024/10/23T06:17:32+00:00")); // '23-10-2024 06:17 AM'
// console.log(dateTimeStringConverter("Invalid Date String")); // 'Invalid Date'

export function timeStringConverterV2(time) {
  const objToReturn = {
    timeString: "",
    hours: "",
    minutes: "",
    period: "",
  };
  // return time?.join('') // return adjusted time or original string
  try {
    // Check if the input is valid and not empty
    if (!time || typeof time !== "string") {
      throw new Error("Invalid input: Time must be a non-empty string.");
    }
    let hours, minutes;
    // Check if input is in UTC timestamp format or ISO format (with milliseconds)
    const utcMatch = time.match(
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|[+-]\d{2}:\d{2})?$/
    );
    if (utcMatch) {
      // Convert the UTC timestamp to local time
      const date = new Date(time);
      hours = date.getHours();
      minutes = date.getMinutes();
    } else {
      // Normalize the input by replacing "_", "-", and "/" with ":"
      time = time.replace(/[_\-\/]/g, ":");
      // Check for valid time patterns and extract components
      const timeMatch = time.match(
        /^([01]?\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/
      ) || [time];
      if (timeMatch.length > 1) {
        // If time format is correct
        // Remove full match and retain components
        time = timeMatch.slice(1);
        // Validate hours and minutes
        hours = +time[0];
        minutes = +time[2];
        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
          throw new Error(
            "Invalid input: Hours must be 0-23 and minutes must be 0-59."
          );
        }
      } else {
        throw new Error("Invalid input: Time format not recognized.");
      }
    }
    // Determine AM/PM
    const period = hours < 12 ? " AM" : " PM";
    // Convert to 12-hour format
    const displayHours = hours % 12 || 12;
    // Return adjusted time in "hh:mm AM/PM" format
    objToReturn.timeString = `${displayHours}:${String(minutes).padStart(
      2,
      "0"
    )}${period}`;
    objToReturn.hours = displayHours;
    objToReturn.minutes = String(minutes).padStart(2, "0");
    objToReturn.period = period;
    return objToReturn;
  } catch (error) {
    console.error(error.message);
    return "Invalid input";
  }
  // Example usage:
  // console.log(timeStringConverterV2("14:15"));                   // Output: "2:15 PM"
  // console.log(timeStringConverterV2("02:30"));                   // Output: "2:30 AM"
  // console.log(timeStringConverterV2("12_45"));                   // Output: "12:45 PM"
  // console.log(timeStringConverterV2("05-20"));                   // Output: "5:20 AM"
  // console.log(timeStringConverterV2("09/10"));                   // Output: "9:10 AM"
  // console.log(timeStringConverterV2("2024-10-23T06:17:32Z"));    // Output: "6:17 AM" (or local equivalent)
  // console.log(timeStringConverterV2("2024-10-23T06:17:32+00:00")); // Output: "6:17 AM" (or local equivalent)
  // console.log(timeStringConverterV2("2024-10-23T01:30:00.000Z")); // Output: "1:30 AM"
  // console.log(timeStringConverterV2("132:30"));                  // Output: "Invalid input"
  // console.log(timeStringConverterV2("random"));                  // Output: "Invalid input"
}
