export default {
  addDivision: '/academicReference/createAcademicReferenceForADivision',
  getDepartments: '/academicReference/getDepartments',
  getAcademicReference: '/academicReference/getAcademicReference',
  getCourseYears: '/academicReference/getCourseYears',
  getDivisions: '/academicReference/getDivisions',
  getDivisionsOfAnInstitute: '/academicReference/getDivisionsOfAnInstitute',
  getCourseYearsForDepartments: '/academicReference/getCourseYearsForDepartments',
  removeDepartment: '/academicReference/deleteAcademicReferenceForADepartment',
  removeCourseYear: '/academicReference/deleteAcademicReferenceForACourseYear',
  removeDivision: '/academicReference/deleteAcademicReferenceForADivision'
}
