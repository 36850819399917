<template src="./activityCourseAnalysis.html"></template>

<script>
import UserRepository from '../../Repository/User'
import SubjectsRepository from '../../Repository/Subject';
import ApexCharts from "apexcharts";
import Vue from "vue";
import { Vuetify, VApp, VCard, Vslidegroup, Vrating } from "vuetify";
import auth from '../../Services/Firebase/auth';
import CourseRepository from '../../Repository/courses';
// import { mdiStar } from '@mdi/js'

export default {
  name: "activityCourseAnalysis",
  props: ["prop_subject", "prop_tab", "prop_selectedsubSectionIndex", "prop_selectedSectionIndex", "prop_selectedSemester"],
  data() {
    return {
      subSectionNamesFromAdmin: [],
      subSectionNamesForStudent: [],
      series: [],
      courseDetails: [],
      userFilteredData: [],
      feedbackUsers: [],
      finalDate: '',
      courseRating: 0,
      countRating1: 0,
      countRating2: 0,
      countRating3: 0,
      countRating4: 0,
      countRating5: 0,
      totalStudentsRated: 0,
      starRating1: 0,
      starRating2: 0,
      starRating3: 0,
      starRating4: 0,
      starRating5: 0,
      selectedType: '',
      starRatingSort: '',
      completedSubsectionCount: 0,
      sectionCompletedCount: 0,
      isCourseCompletedCount: 0,
      ongoingCourseCount: 0,
      certifiedStudentCount: 0,
      creditsInPercentage: 0,
      totalCreditsRecieved: 0,
      progressPercentage: 0,
      subsectinsCount: 0,
      completedSubsection: 0,
      totalSubsections: 0,
      user: [],
      sections: [],
      section: [],
      subSections: [],
      subSection: [],
      stars: ["All", "1", "2", "3", "4", "5"],
      studentSubSections: [],
      filteredSection: [],
      filteredSubsection: [],
      chartOptions: {
        chart: {
          type: 'donut',
          width: 400
        },
        labels: ["Completed", "Ongoing"]
      },
      headers: [
        { text: 'Roll No.', value: 'rollNo', },
        { text: 'Student Name', value: 'name' },
        { text: 'Progress', value: 'progressPercentage' },
        { text: 'Status', value: 'status' },
        { text: 'Contact Number', value: 'mobile' },
        { text: 'Email ID', value: 'email' },
        { text: 'Action', value: 'actions' }
      ],
      tabs: this.prop_tab ? this.prop_tab : 0,
      model: 1,
      item: ['Completed', 'Ongoing'],
      isSection: 'false'
    }
  },
  created() {
    this.selectedSubject = this.$store.getters['liveData/get_selectedSubject']
    this.selectedInstitute = this.$store.getters['liveData/get_admissionInstitute']
    this.userRepositoryInstance = new UserRepository(this)
    this.subjectRepositoryInstance = new SubjectsRepository(this)
    this.courseName = this.selectedSubject.subjectName
    this.subject = this.prop_subject;
    this.$store.commit("liveData/set_selectedActivityName", this.selectedSubject.subjectName);
    this.CourseRepositoryInstance = new CourseRepository(this)
    this.fetchData()
  },
  methods: {
    fake() {
    },
    goBack(subject) {
      this.$router.push({
        name: "activityCourses",
        params: {
          prop_subject: subject,
          prop_selectedDepartment: subject.department,
          prop_selectedCourseYear: subject.courseYear,
          prop_selectedSemester: this.prop_selectedSemester
        }
      });
    },
    async fetchData() {
      const objToPush = {
        "instituteId": this.subject.instituteId,
        "semId": this.subject.semId,
        "department": this.subject.department,
        "courseYear": this.subject.courseYear,
        "subjectId": this.subject.subjectId,
        "courseName": this.subject.subjectName,
        "courseImage": this.subject.courseImage,
      }
      this.courseDetails = await this.CourseRepositoryInstance.getSingleCourse(objToPush)
      console.log('this.courseDetails', this.courseDetails);
      this.courseDetails.map((obj) => {
        this.dateCreated = obj.createdAt
        var dArr = this.dateCreated.split("T");
        this.tempDate = (dArr[0]);
        var date = this.tempDate.split("-");
        this.finalDate = (date[2] + "/" + date[1] + "/" + date[0]);
      })
      this.sections = this.courseDetails[0].sections
      this.subSectionNamesFromAdmin = []
      this.courseDetails.map((course) => {
        let totalCourseCredit = 0
        course.sections.forEach((courseSection) => {
          this.courseSection = courseSection
          let sectionTotalCredit = 0
          this.subsectinsCount += parseInt(courseSection.subSections.length)

          this.totalSubsections += courseSection.subSections.length  //********//
          courseSection.subSections.map((courseSubsection) => {
            this.subSectionNamesFromAdmin.push(courseSubsection.subsectionTitle)
            sectionTotalCredit += parseInt(courseSubsection.subsectionCredit) ? parseInt(courseSubsection.subsectionCredit) : 0
          })
          totalCourseCredit += parseInt(sectionTotalCredit) ? parseInt(sectionTotalCredit) : 0
        })
        this.totalCourseCredit = totalCourseCredit
      })
      this.getStudentsData()
    },
    getsubSectionData(section, i) {
      this.subSections = []
      this.isSection = true
      this.subSections = section.subSections
      this.selectedSectionIndex = i
    },
    gotoactivityCourseSubjectAnalysis(subSection, i) {
      this.$router.push({
        name: "activityCourseSubjectAnalysis",
        params: {
          prop_subSection: subSection,
          prop_sections: this.sections,
          prop_user: this.user,
          prop_selectedSectionIndex: this.selectedSectionIndex,
          prop_selectedsubSectionIndex: i
        }
      });
    },
    async getStudentsData() {
      const uIds = []
      this.subject.studentsList.map((student) => {
        uIds.push(student.uId)
        this.countRating1 += parseInt(student.courseRating) === 1 ? 1 : 0
        this.countRating2 += parseInt(student.courseRating) === 2 ? 1 : 0
        this.countRating3 += parseInt(student.courseRating) === 3 ? 1 : 0
        this.countRating4 += parseInt(student.courseRating) === 4 ? 1 : 0
        this.countRating5 += parseInt(student.courseRating) === 5 ? 1 : 0
        this.totalStudentsRated += parseInt(student.courseRating) !== "" ? 1 : 0
        this.starRating1 = (this.countRating1 / this.totalStudentsRated) * 100
        this.starRating2 = (this.countRating2 / this.totalStudentsRated) * 100
        this.starRating3 = (this.countRating3 / this.totalStudentsRated) * 100
        this.starRating4 = (this.countRating4 / this.totalStudentsRated) * 100
        this.starRating5 = (this.countRating5 / this.totalStudentsRated) * 100
      })
      try {
        this.user = await this.userRepositoryInstance.getUsers({ uIds: uIds })
        this.user.map((obj) => {
          obj.completedSubsection = 0
          obj.completedSubsections = 0
          this.subject.studentsList.map((obj1) => {
            console.log('obj1', obj1);
            this.subSectionNamesForStudent = []
            if (obj1.isCourseCompleted) {
              obj.status = "Completed"
            } else { obj.status = "Ongoing" }
            if (obj.uId === obj1.uId) {
              obj.isCourseCompleted = false
              obj.name = `${obj.firstName} ${obj.lastName}`
              obj.courseRating = obj1.courseRating
              obj.isCertified = obj1.isCertified
              obj.sections = obj1.sections
              obj.feedbackDescription = obj1.feedbackDescription
              obj.isFeedbackGiven = obj1.isFeedbackGiven
              obj.courseName = this.courseName
              let sectionCompletedCount = 0
              let completedSubsections = 0
              let totalCreditRecieved = 0
              obj1.sections.forEach((section) => {
                section.subSections.forEach((data) => {
                  if (data.isSubsectionCompleted) {
                    this.subSectionNamesForStudent.push(data.subsectionTitle)
                  }
                })
                console.log('111')
                this.courseDetails[0].sections.map((sectionObj) => {
                  console.log('sectionObj._id', sectionObj._id);
                  console.log('sectionObj._id', section.sectionId);
                  if (sectionObj._id === section.sectionId) {
                    section.isSectionCompleted = false
                    let subsectionCompletedCount = 0
                    let studentCreditRecieved = 0
                    sectionObj.subSections.map((subsectionObj) => {
                      section.subSections.forEach((subSection) => {
                        if (subsectionObj.subSectionId && subSection.subSectionId && subsectionObj.subSectionId === subSection.subSectionId) {
                          subsectionCompletedCount += subSection.isSubsectionCompleted === true ? 1 : 0
                            studentCreditRecieved += parseInt(subSection.subsectionStudentCredit) ? parseInt(subSection.subsectionStudentCredit) : 0
                        }
                        // else {
                        //   if (subsectionObj.subsectionTitle === subSection.subsectionTitle) {
                        //     subsectionCompletedCount += subSection.isSubsectionCompleted === true ? 1 : 0
                        //     studentCreditRecieved += parseInt(subSection.subsectionStudentCredit) ? parseInt(subSection.subsectionStudentCredit) : 0
                        //   }
                        // } 
                      })
                    })
                    totalCreditRecieved += parseInt(studentCreditRecieved) ? parseInt(studentCreditRecieved) : 0
                    section.isSectionCompleted = subsectionCompletedCount === section.subSections.length ? true : false
                    sectionCompletedCount += section.isSectionCompleted === true ? 1 : 0
                    obj.completedSubsections += subsectionCompletedCount
                  }
                })
              })
              obj.creditsInPercentage = Math.ceil((parseInt(totalCreditRecieved) / parseInt(this.totalCourseCredit)) * 100)
              obj.progressPercentage = Math.ceil((obj.completedSubsections / this.totalSubsections) * 100)
                 if (obj.progressPercentage > 100) {
                  obj.progressPercentage = 100
                 }
              obj.isCourseCompleted = sectionCompletedCount === obj1.sections.length ? true : false
            }
          })
          this.isCourseCompletedCount += obj.isCourseCompleted === true ? 1 : 0
          this.ongoingCourseCount += obj.isCourseCompleted === false ? 1 : 0
          this.certifiedStudentCount += obj.isCertified === true ? 1 : 0
        })
        this.series.push(this.isCourseCompletedCount, this.ongoingCourseCount)
        this.userFilteredData = this.user
        this.feedbackUsers = this.user
      } catch (e) {
        console.log('e', e);
      }
    },
    filterStudentData() {
      this.userFilteredData = []
      this.user.map((obj) => {
        if (this.selectedType === 'Completed') {
          if (obj.isCourseCompleted === true) {
            this.userFilteredData.push(obj)
          }
        }
        if (this.selectedType === 'Ongoing') {
          if (obj.isCourseCompleted === false) {
            this.userFilteredData.push(obj)
          }
        }
        if (this.selectedType === 'Certified') {
          if (obj.isCertified === true) {
            this.userFilteredData.push(obj)
          }
        }
      })
    },
    sortByStarRating() {
      this.feedbackUsers = []
      this.user.map((obj) => {
        if (this.starRatingSort === 'All') {
          this.feedbackUsers.push(obj)
        }
        if (this.starRatingSort === '1') {
          if (obj.courseRating === 1) {
            this.feedbackUsers.push(obj)
          }
        }
        if (this.starRatingSort === '2') {
          if (obj.courseRating === 2) {
            this.feedbackUsers.push(obj)
          }
        }
        if (this.starRatingSort === '3') {
          if (obj.courseRating === 3) {
            this.feedbackUsers.push(obj)
          }
        }
        if (this.starRatingSort === '4') {
          if (obj.courseRating === 4) {
            this.feedbackUsers.push(obj)
          }
        }
        if (this.starRatingSort === '5') {
          if (obj.courseRating === 5) {
            this.feedbackUsers.push(obj)
          }
        }

      })
    },
    goToCourseMaterial(user) {
      this.$router.push({
        name: "activityCourseMaterial",
        params: {
          prop_subject: this.subject,
          prop_sections: this.sections,
          prop_user: user,
        }
      });
    },
    async generateStudentsReport(users) {
      console.log('users', users);
      this.excelGenerated = true
      try {
        const pdfBlob =
          await this.CourseRepositoryInstance.generateStudentCourseReport(users)
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.courseName}-course-report.xlsx`)
        document.body.appendChild(link)
        link.click()
        this.excelGenerated = false
      } catch (err) {
        console.log('error', err)
        showStatus("Error generating sample excel.", 9000, "error", this);
        this.excelGenerated = false
      }
    },
    async generateFeedbackReport(users) {
      this.excelGenerated = true
      try {
        const pdfBlob =
          await this.CourseRepositoryInstance.generateStudentFeedbackReport(users)
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.courseName}-course-feedback.xlsx`)
        document.body.appendChild(link)
        link.click()
        this.excelGenerated = false
      } catch (err) {
        console.log('error', err)
        showStatus("Error generating sample excel.", 9000, "error", this);
        this.excelGenerated = false
      }
    }
  }
}
</script>

<style scoped src="./activityCourseAnalysis.css"></style>
