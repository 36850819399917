export default {
  removeStudentFromLecture: '/lectures/removeStudentFromLecture',
  markAbsentFromReportDate: '/lectures/markAbsentFromReportDate',
  getAllLectureOfValueAddedActivity: '/lectures/getAllLectureOfValueAddedActivity',
  getLecture: '/lectures/getLectureById',
  arrangeALecture: '/lectures/arrangeLectures',
  createMultipleLectures: '/lectures/createMultipleLectures',
  updateMultipleLectureDocument: '/lectures/updateMultipleLectureDocument',
  updateStatusOfLectureForGivenTime: '/lectures/updateStatusOfLectureForGivenTime',
  createLecturesFromATimetableDay: '/lectures/createLecturesFromATimetableDay',
  getLecturesOfADepartmentForADay: '/lectures/getLecturesOfADepartment',
  getLectureCountOfStudentPresentBeforeDate: '/lectures/getLectureCountOfStudentPresentBeforeDate',
  getOverAllLectureForDates: '/lectures/getOverAllLectureForDates',
  facultyLectureClashDetection: '/lectures/facultyLectureClashDetection',
  roomClashDetection: '/lectures/roomClashDetection',
  getLectureByIds: '/lectures/getLectureByIds',
  getRealtimeSchedule: '/lectures/getDepartmentRealtimeSchedule',
  getRealtimeScheduleForAdmin: '/lectures/getRealtimeScheduleForAdmin',
  getLecturesOfADivisionForADay: '/lectures/getLecturesOfADivisionForADay',
  getGroupedLecturesOfDay: '/lectures/getGroupedLecturesOfDay',
  getLecturesForADayForAStudent: '/lectures/getLecturesForADayForAStudent',
  getAttendanceAnalysisForAStudent: '/lectures/getAttendanceAnalysisForAStudent',
  getLecturesForADay: '/lectures/getLecturesForADayForAFaculty',
  getLectureDetailsOfLecturesForATopic: '/lectures/getLectureDetailsOfLecturesForATopic',
  declareHoliday: '/lectures/declareAHolidayForGivenDates',
  cancelLecture: '/lectures/cancelALecture',
  getTotalAttendedLecturesForADivisionForSubject: '/lectures/getTotalAttendedLecturesForADivisionForSubject',
  getAttendanceAnalysisForADivisionForASubject: '/lectures/getAttendanceAnalysisForADivisionForASubject',
  // getLecturesOfACourseYear: '/lectures/getLecturesOfACourseYear',
  getConductedLecturesCountOfASubject: '/lectures/getConductedLecturesCountOfASubject',
  getConductedLecturesOfASubject: '/lectures/getConductedLecturesOfASubject',
  getAttendanceAnalysisForADivision: '/lectures/getAttendanceAnalysisForADivision',
  getAttendanceAnalysisForABatchOfADivision: '/lectures/getAttendanceAnalysisForABatchOfADivision',
  getAttendanceAnalysisForABatch: '/lectures/getAttendanceAnalysisForABatch',
  getLecturesForADayForAFaculty: '/lectures/getLecturesForADayForAFaculty',
  generateAttendanceAnalysisExcel: '/excel/lecture/attendance',
  generateLectureDashboardExcel: '/excel/lecture/generateLectureDashboardExcel',
  getLecturesBySubject: '/lectures/getLecturesBySubject',
  generateAttendanceSheetExcel: '/excel/lecture/attendanceTemplate',
  generateExcelAttendanceAnalysisDivisionWise: '/excel/lecture/generateExcelAttendanceAnalysisDivisionWise',
  generateExcelSubjectWise: '/excel/lecture/generateExcelSubjectWise',
  getLecturesOfAFacultyOfASubject: '/lectures/getLecturesOfAFacultyOfASubject',
  getLecturesOfAFacultyForASem: '/lectures/getLecturesOfAFacultyForASem',
  findAllClashOfLecOnSemLevel: '/lectures/findAllClashOfLecOnSemLevel',
  saveAttendance: '/lectures/markAttendance',
  getConductedLecturesCountOfASubject: '/lectures/getConductedLecturesCountOfASubject',
  deleteLecture: '/lectures/deleteLecture',
  cancelALecture: '/lectures/cancelALecture',
  getAllLecturesOfMultipleInstitutesPerDayByUId: '/lectures/getAllLecturesOfMultipleInstitutesPerDayByUId',
  getLecturesOfACourseYear: '/lectures/getLecturesOfACourseYear',
  
}
