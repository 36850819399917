<template src='./activityEditStudentsRegistration.html'></template>

<script>
import inputContainer from "../../Components/inputContainer";
import arrayOps from "../../Services/Utils/arrayOps";
import { subjectTypes } from "../../Constants/Utils/Statics";
import UserRepository from "../../Repository/User";
import SubjectRepository from "../../Repository/Subject";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SemesterRepository from "../../Repository/Semester";
import showStatus from "../../NetworkManager/showStatus";
import SubjectsRepository from "../../Repository/Subject";
import UserLoggerRepository from "../../Repository/UserLogger";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import axios from "axios";
import {
  generateJsonFromExcel,
  generateFromJson,
  createExcelThroughJson,
} from "../../utils/excel";

export default {
  name: "activityEditStudentsRegistration",
  components: {
    inputContainer,
  },
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_selectedDivision",
    "tree",
    "prop_isCorporate",
    "prop_dataOfDept",
  ],
  data() {
    return {
      selectedInstitute: "",
      userData: {},
      selectedSemester: {},
      tabs: 0,
      mainTabNames: ["Mapping", "History"],
      panel: [0, 1],
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedDivision: "",
      selectedDivisions: [],
      selectedSubject: "",
      searchForMapping: "",
      allCourseYears: [],
      allSubject: [],
      allDivisions: [],
      allDepartments: [],
      allSemData: [],
      allSubjectOfCourseYear: [],
      studentArrForMapping: [],
      studentArrForMapping2: [],
      isForTableEdit: false,
      // allSubjectForSingleStudent:false,
      excelMappingUploadDialog: false,
      errorDialogOpen: false,
      showActivityDialog: false,
      updateMappingFile: [],
      validRollNumber: [],
      allActivityArr: [],
      errorArr: [],
      userLogs: [],
      activityNamesArr: [],
      activityNamesArr2: [],
      initialSubjectMappingCount: {},
      finalSubjectMappingCount: {},
      searchName: "",
      finalDataForApi: [],
      finalMappingCountArr: [],
      confirmMappingDialog: false,
      loaderForMappingTable: false,
      dataForActivityShow: {},
      headerForLogs: [
        { text: "Name", value: "fullName" },
        { text: "Date", value: "date" },
        { text: "Model", value: "model" },
        { text: "Activity", value: "activity" },
      ],
      headerForMapping: [
        { text: "Roll Number", value: "rollNumber", width: "100px" },
        {
          text: "Full Name",
          value: "fullName",
          sortable: false,
          width: "100px",
        },
      ],
    };
  },

  async created() {
    if (this.prop_isCorporate && this.prop_isCorporate === true) {
      this.$store.commit(
        "liveData/set_selectedActivityName",
        "Candidate Registration"
      );
    } else {
      this.$store.commit(
        "liveData/set_selectedActivityName",
        "Students Registration"
      );
    }
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedSemester = this.PropsSelectedSemester;

    this.userRepositoryInstance = new UserRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.activityLoggerInstance = new ActivityLoggerRepository(this);
    this.UserLoggerInstance = new UserLoggerRepository(this);
    this.getSemData();
  },
  methods: {
    isSelectAllChanged(item, i) {
      if (this.studentArrForMapping.length > 0) {
        this.studentArrForMapping.map((stu) => {
          // stu[item.value] = item.isSelectAll
          this.allSubjectOfCourseYear.map((sub) => {
            stu[sub.subjectId] = item.isSelectAll;
          });
        });
      }
      this.headerForMapping.map((hed) => {
        hed.mappedCount = this.calculateTotalStudentMappedForTable(hed.value);
        hed.unMappedCount = this.calculateTotalStudentUnMappedForTable(
          hed.value
        );
      });
      this.$set(this.headerForMapping, i, item);
    },
    searchFacultyAdd(i) {
      if (i !== "") {
        const searchTerm = i;
        const regex = new RegExp(searchTerm, "i"); // "i" for case-insensitive search
        let newArrAfterSearch = [];
        this.activityNamesArr2.map((activity) => {
          if (regex.test(activity.activity)) {
            newArrAfterSearch.push(activity);
          }
        });
        this.activityNamesArr = [];

        this.activityNamesArr = newArrAfterSearch;
      } else {
        this.activityNamesArr = this.activityNamesArr;
      }
      // return this.labels.filter(label => regex.test(label.name));
    },
    chooseClass(value) {
      let color = "";
      let data = value.split("-")[0];
      if (data === "Added") {
        color = "added";
      }
      if (data === "Removed") {
        color = "removed";
      }
      return color;
    },
    convertUTCtoLocalTimeWithAMPM(utcDateString) {
      const utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const result =
        formattedHours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
      return result;
    },
    showActivity(data, item) {
      this.showActivityDialog = true;
      this.activityNamesArr = data;
      this.activityNamesArr.reverse();
      this.activityNamesArr2 = data;
      this.activityNamesArr2.reverse();
      this.dataForActivityShow = item;
    },
    async getAllLogsForUser() {
      try {
        let data = await this.UserLoggerInstance.getSpecificUserLogs({
          instituteId: this.selectedInstitute,
          uId: this.userData.uId,
        });
        if (data?.userLogs.length > 0) {
          data.userLogs.map((logs) => {
            logs.fullName = this.userData.firstName;
            logs.model = "Subject Mapping";
          });
        }
        this.userLogs = data?.userLogs;
      } catch (err) {
        console.error("err", err);
      }
    },
    tabChanged() {
      if (this.tabs === 0) {
        this.getAllLogsForUser();
      }
    },
    calculateTotalStudentMapped(id) {
      let count = 0;
      if (this.allStudent.length > 0) {
        this.allStudent.map((stu) => {
          if (stu.subjects.includes(id)) {
            count++;
          }
        });
      }
      return count;
    },
    calculateTotalStudentMappedForTable(id) {
      let count = 0;
      if (this.studentArrForMapping.length > 0) {
        this.studentArrForMapping.map((stu) => {
          if (stu[id] === true) {
            count++;
          }
        });
      }
      return count;
    },
    calculateTotalStudentUnMappedForTable(id) {
      let count = 0;
      if (this.studentArrForMapping.length > 0) {
        this.studentArrForMapping.map((stu) => {
          if (stu[id] === false) {
            count++;
          }
        });
      }
      return count;
    },
    calculateUnmappedStudent(id) {
      let count = 0;
      if (this.studentArrForMapping.length > 0) {
        this.studentArrForMapping.map((stu) => {
          if (!stu.subjects.includes(id)) {
            count++;
          }
        });
      }
      return count;
    },
    applyAllForSubject(item, i) {
      if (this.studentArrForMapping.length > 0) {
        this.studentArrForMapping.map((stu) => {
          if (stu[item.value] !== item.isApplyAll) {
            stu.isChanged = true;
          }
          stu[item.value] = item.isApplyAll;
        });
      }
      item.mappedCount = this.calculateTotalStudentMappedForTable(item.value);
      item.unMappedCount = this.calculateTotalStudentUnMappedForTable(
        item.value
      );
      this.$set(this.headerForMapping, i, item);
    },
    getUidByRollNumber(roll) {
      let uId = "";
      this.allStudent.map((stu) => {
        if (stu.rollNumber === roll) {
          uId = stu.uId;
        }
      });
      return uId;
    },
    removeDuplicateValues(arr) {
      const uniqueValuesMap = new Map();
      arr.forEach((obj) => {
        const key = Object.keys(obj)[0];
        const value = obj[key][0];
        if (!uniqueValuesMap.has(key) || !uniqueValuesMap.get(key).has(value)) {
          if (!uniqueValuesMap.has(key)) {
            uniqueValuesMap.set(key, new Set());
          }
          uniqueValuesMap.get(key).add(value);
        } else {
          // Remove the element with duplicate value
          arr.splice(arr.indexOf(obj), 1);
        }
      });
      return arr;
    },
    validateParsedData(data) {
      this.errorArr = [];
      const rollNumbers = {};

      const map = new Map();
      for (let i = 0; i < data.length; i++) {
        if (map.has(data[i].RollNumber)) {
          this.errorArr.push({ [data[i].RollNumber]: [i] });
          this.errorArr.push({
            [data[i].RollNumber]: [map.get(data[i].RollNumber)],
          });
        } else {
          map.set(data[i].RollNumber, i);
        }
      }
      const uniqueErrorArr = this.removeDuplicateValues(this.errorArr);
      this.errorArr = uniqueErrorArr;
      let temp = this.errorArr;
      this.errorArr.sort((a, b) => {
        let keyA = Object.keys(a)[0];
        let keyB = Object.keys(b)[0];
        return a[keyA][0] - b[keyB][0];
      });
      this.errorArr = [];
      for (let i = 0; i < temp.length; i++) {
        const rollNumber = Object.keys(temp[i])[0];
        const indexes = temp[i][rollNumber].join(", ");
        const sentence = `Roll Number ${rollNumber} is repeated at line ${
          Number(indexes) + 2
        }`;
        this.errorArr.push(sentence);
      }
    },
    sortByKey(array, key) {
      return array.sort((a, b) => a[key][0] - b[key][0]);
    },
    async parseExcelForMapping() {
      this.loaderForMappingTable = true;
      this.errorDialogOpen = false;
      this.excelMappingUploadDialog = false;
      const schema1 = {
        Sheet1: {
          FullName: {
            validation: {
              type: "textLength",
            },
            variableName: "FullName",
          },
          RollNumber: {
            validation: {
              type: "textLength",
            },
            variableName: "RollNumber",
          },
        },
      };
      if (this.allSubjectOfCourseYear.length > 0) {
        this.allSubjectOfCourseYear.map((sub) => {
          schema1.Sheet1[`${sub.subjectName}(${sub.subjectCode})`] = {
            validation: {
              type: "textLength",
            },
            variableName: `${sub.subjectName}(${sub.subjectCode})`,
          };
        });
      }
      let res = await generateJsonFromExcel(
        this.updateMappingFile,
        schema1,
        false
      );
      const { errors, data } = res;
      this.validateParsedData(data?.Sheet1);
      let mappingArr = [];

      if (this.errorArr.length === 0) {
        if (data?.Sheet1.length > 0) {
          data?.Sheet1.map((data) => {
            let obj = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              uId: this.getUidByRollNumber(data.RollNumber),
              fullName: data.FullName,
              subjects: [],
            };
            let keys = Object.keys(data);
            let tempKeys = [];
            for (let i = 0; i < keys.length; i++) {
              if (keys[i] !== "FullName" && keys[i] !== "RollNumber") {
                tempKeys.push(this.getSubjectIdByName(keys[i]));
              }
            }
            let prev = this.studentArrForMapping.find(
              (obj) =>
                this.getUidByRollNumber(obj.rollNumber) ===
                this.getUidByRollNumber(data.RollNumber)
            );
            for (const key in prev) {
              if (
                typeof prev[key] === "boolean" &&
                key !== "isChanged" &&
                !tempKeys.includes(key)
              ) {
                if (prev[key]) {
                  obj.subjects.push(key);
                }
              }
            }

            if (keys.length > 0) {
              keys.map((key) => {
                if (key !== "FullName" && key !== "RollNumber") {
                  if (data[key].toLowerCase() === "yes") {
                    let id = this.getSubjectIdByName(key);
                    obj.subjects.push(id);
                  }
                }
              });
            }
            if (obj.uId && obj.uId !== "") {
              mappingArr.push(obj);
            }
          });
        }
        this.findOutActionPerformed(mappingArr, 2);
        try {
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: mappingArr,
          });
          await this.saveLogs();
          // await this.getStudentForDivision();
          await this.subjectChanged();
          this.loaderForMappingTable = false;
        } catch (err) {
          console.error("err", err);
          this.loaderForMappingTable = false;
        }
      } else {
        this.errorDialogOpen = true;
        this.loaderForMappingTable = false;
      }
    },
    generateExcelForMapping() {
      let arr = [];
      if (this.studentArrForMapping.length > 0) {
        this.studentArrForMapping.map((stu) => {
          if (
            stu.rollNumber &&
            stu.rollNumber !== "" &&
            stu.rollNumber !== "-"
          ) {
            let obj = {
              FullName: stu.fullName,
              RollNumber: stu.rollNumber,
            };
            if (this.allSubjectOfCourseYear.length > 0) {
              this.allSubjectOfCourseYear.map((sub) => {
                obj[`${sub.subjectName}(${sub.subjectCode})`] = "No";
                if (stu.subjects.includes(sub.subjectId)) {
                  obj[`${sub.subjectName}(${sub.subjectCode})`] = "Yes";
                }
              });
            }

            arr.push(obj);
          }
        });
      }
      arr.sort((a, b) => {
        return a.RollNumber.localeCompare(b.RollNumber);
      });
      createExcelThroughJson(
        arr,
        `Student Mapping${this.selectedDepartment}_${this.selectedCourseYear}_${this.selectedDivision}`
      );
    },
    openExcelDialog() {
      this.excelMappingUploadDialog = true;
    },
    getSubjectNameById(id) {
      let name = "";
      this.allSubjectOfCourseYear.map((sub) => {
        if (sub.subjectId === id) {
          name = sub.subjectName;
        }
      });
      return name;
    },
    getSubjectIdByName(name) {
      let id = "";
      let subjectNameAndCode = name.split('(')
      this.allSubjectOfCourseYear.map((sub) => {
        if (sub.subjectName === subjectNameAndCode[0] && sub.subjectCode === subjectNameAndCode[1].slice(0, -1)) {
          id = sub.subjectId;
        }
      });
      return id;
    },
    findOutActionPerformed(data, type) {
      this.allActivityArr = [];
      let actionTrackerForAdded = [];
      let actionTrackerForRemoved = [];
      this.allStudent.map((info) => {
        data.map((student) => {
          if (student.uId === info.uId) {
            if (student.subjects.length > info.subjects.length) {
              //subject added
              if (student.subjects.length > 0) {
                student.subjects.map((subId) => {
                  if (!info.subjects.includes(subId)) {
                    let subName = this.getSubjectNameById(subId);
                    let sentence = `Added- ${student.fullName} for subject ${subName}`;
                    if (type === 2) {
                      sentence = sentence + " " + "by excel";
                    }

                    actionTrackerForAdded.push(sentence);
                  }
                });
              }
            } else {
              if (info.subjects.length > 0) {
                info.subjects.map((subId) => {
                  if (!student.subjects.includes(subId)) {
                    let subName = this.getSubjectNameById(subId);
                    let sentence = `Removed- ${student.fullName} for subject ${subName}`;
                    if (type === 2) {
                      sentence = sentence + " " + "by excel";
                    }
                    actionTrackerForRemoved.push(sentence);
                  }
                });
              }
            }
          }
        });
      });
      let arrForActivity = [
        ...actionTrackerForAdded,
        ...actionTrackerForRemoved,
      ];
      if (arrForActivity.length > 0) {
        arrForActivity.map((activity) => {
          let obj = {
            time: new Date().toISOString(),
            activity: activity,
          };
          this.allActivityArr.push(obj);
        });
      }
    },
    confirmMapping() {
      let finalArr = [];
      if (this.studentArrForMapping.length > 0) {
        this.studentArrForMapping.map((stu) => {
          if (stu.isChanged === true) {
            stu.subjects = [];
            let arr = Object.keys(stu);
            if (arr.length > 0) {
              arr.map((key) => {
                let keyArr = [
                  "collegePRNNo",
                  "email",
                  "fullName",
                  "instituteId",
                  "rollNumber",
                  "semId",
                  "subjects",
                  "uId",
                  "isChanged",
                ];
                if (!keyArr.includes(key)) {
                  if (stu[key] == true) {
                    stu.subjects.push(key);
                  }
                }
              });
            }
            finalArr.push(stu);
          }
        });
      }
      this.findOutActionPerformed(this.studentArrForMapping, 1);
      this.finalDataForApi = finalArr;
      this.calculateMappingForSubject();
      this.calculateFinalMappingForSubject();
      this.calculateMappingChanged();
      this.confirmMappingDialog = true;
    },
    async saveAllocation() {
      this.confirmMappingDialog = false;
      this.loaderForMappingTable = true;
      this.isForTableEdit = false;
      try {
        if (this.finalDataForApi.length > 0) {
          await this.semesterUserRepositoryInstance.updateSemesterUser({
            bulkDataArray: this.finalDataForApi,
          });
          // this.allActivityArr
          await this.saveLogs();
          // await this.getStudentForDivision();
          await this.subjectChanged();
        }
        this.loaderForMappingTable = false;
      } catch (err) {
        console.error("err", err);
        this.loaderForMappingTable = false;
      }
    },
    async saveLogs() {
      let date = new Date().toISOString();
      let newDate = `${date.split("T")[0]}T12:00:00`;
      try {
        await this.UserLoggerInstance.createUserDocumentForDate({
          instituteId: this.selectedInstitute,
          uId: this.userData.uId,
          date: newDate,
          activitySubjectMapping: this.allActivityArr,
        });
      } catch (err) {
        console.error("err", err);
      }
    },
    applyAllSubjectToSingleStudent(item) {
      if (item.allSubjectForSingleStudent) {
        for (const key in item) {
          if (typeof item[key] === "boolean") {
            item[key] = true;
          }
        }
        for (let i = 2; i < this.headerForMapping.length; i++) {
          this.headerForMapping[i].mappedCount++;
          this.headerForMapping[i].unMappedCount--;
        }
      } else {
        for (const key in item) {
          if (typeof item[key] === "boolean") {
            item[key] = false;
          }
        }
        for (let i = 2; i < this.headerForMapping.length; i++) {
          this.headerForMapping[i].mappedCount--;
          this.headerForMapping[i].unMappedCount++;
        }
        item.isChanged = true;
      }
    },
    checkBoxClick(item, value, id) {
      value["isChanged"] = true;
      this.headerForMapping.map((hed) => {
        hed["isApplyAll"] = false;
        if (hed.value === id) {
          hed.mappedCount = this.calculateTotalStudentMappedForTable(id);
          hed.unMappedCount = this.calculateTotalStudentUnMappedForTable(id);
        }
      });
    },
   async subjectChanged() {
    this.loaderForMappingTable = true
     await this.getStudentForDivision();
      this.studentArrForMapping = this.studentArrForMapping2;
      // this.studentArrForMapping = []
      let arrForMapping = this.studentArrForMapping2;
      this.headerForMapping = [
        { text: "Roll Number", value: "rollNumber", width: "100px" },
        {
          text: "Full Name",
          value: "fullName",
          sortable: false,
          width: "100px",
        },
      ];
      this.allSubjectOfCourseYear.map((sub) => {
        if (sub.subjectName === this.selectedSubject) {
          this.headerForMapping.push({
            text: sub.subjectName,
            value: sub.subjectId,
            isApplyAll: false,
            isSelectAll: false,
            mappedCount: this.calculateTotalStudentMapped(sub.subjectId),
            unMappedCount: this.calculateUnmappedStudent(sub.subjectId),
            width: "200px",
            sortable: false,
          });
        }
        if (this.selectedSubject === "All") {
          this.headerForMapping.push({
            text: sub.subjectName,
            value: sub.subjectId,
            isApplyAll: false,
            isSelectAll: false,
            mappedCount: this.calculateTotalStudentMapped(sub.subjectId),
            unMappedCount: this.calculateUnmappedStudent(sub.subjectId),
            width: "200px",
            sortable: false,
          });
        }
      });
      let reArr = [];
      arrForMapping.map((stu) => {
        let obj = {
          collegePRNNo: stu.collegePRNNo,
          email: stu.email,
          fullName: stu.fullName,
          instituteId: stu.instituteId,
          rollNumber: stu.rollNumber,
          semId: stu.semId,
          subjects: stu.subjects,
          uId: stu.uId,
          isChanged: false,
        };
        this.allSubjectOfCourseYear.map((sub) => {
          obj[sub.subjectId] = false;
          if (stu.subjects.includes(sub.subjectId)) {
            obj[sub.subjectId] = true;
          }
        });
        reArr.push(obj);
      });
      this.studentArrForMapping = reArr;
    this.loaderForMappingTable = false

    },
    async getFullNameOfStudent(arr) {
      try {
        let fullNames = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: arr,
        });

        if (fullNames.length > 0 && this.allStudent.length > 0) {
          this.allStudent.map((stu) => {
            fullNames.map((full) => {
              if (stu.uId === full.uId) {
                full["subjects"] = stu.subjects;
                full["rollNumber"] = stu.rollNumber;
                full["instituteId"] = stu.instituteId;
                full["semId"] = stu.semId;
              }
            });
          });
        }
        this.studentArrForMapping = fullNames;
        this.studentArrForMapping2 = fullNames;
      } catch (err) {
        console.error("err", err);
      }
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: "activitySemestersManagment",
        params: {
          prop_semName: this.selectedSemester.semName,
        },
      });
    },
    async getSemData() {
      try {
        this.allSemData =
          await this.semesterRepositoryInstance.getSemestersBySemId({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
        if (this.allSemData.length > 0) {
          this.allSemData.map((info) => {
            if (!this.allDepartments.includes(info.department)) {
              this.allDepartments.push(info.department);
            }
          });
        }
      } catch (err) {
        console.error("err", err);
      }
    },
    departmentChanged() {
      if (this.allSemData.length > 0) {
        this.allSemData.map((sem) => {
          if (sem.department === this.selectedDepartment) {
            this.allCourseYears.push(sem.courseYear);
          }
        });
      }
      this.selectedCourseYear = "";
      this.selectedDivision = "";
      this.selectedSubject = "";
    },
    async courseYearChanged() {
      this.selectedDivision = "";
      this.selectedSubject = "";

      let allSubjectForDept =
        await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          }
        );
      this.allSubjectOfCourseYear = allSubjectForDept;
      this.allDivisions = [];
      this.allSemData.map((sem) => {
        if (
          sem.department === this.selectedDepartment &&
          sem.courseYear === this.selectedCourseYear
        ) {
          this.allDivisions.push(sem.division);
        }
      });
      this.allSubject = ["All"];
      if (allSubjectForDept.length > 0) {
        allSubjectForDept.map((sub) => {
          if (
            this.selectedDepartment === sub.department &&
            this.selectedCourseYear === sub.courseYear
          ) {
            this.allSubject.push(sub.subjectName);
          }
        });
      }
    },
    calculateMappingForSubject() {
      this.initialSubjectMappingCount = {};
      if (this.allSubjectOfCourseYear.length > 0) {
        this.allSubjectOfCourseYear.map((sub) => {
          this.initialSubjectMappingCount[sub.subjectName] = 0;
          if (this.allStudent.length > 0) {
            this.allStudent.map((stu) => {
              if (stu.subjects.includes(sub.subjectId)) {
                this.initialSubjectMappingCount[sub.subjectName]++;
              }
            });
          }
        });
      }
    },
    calculateFinalMappingForSubject() {
      this.finalSubjectMappingCount = {};
      if (this.allSubjectOfCourseYear.length > 0) {
        this.allSubjectOfCourseYear.map((sub) => {
          this.finalSubjectMappingCount[sub.subjectName] = 0;
          if (this.studentArrForMapping.length > 0) {
            this.studentArrForMapping.map((stu) => {
              if (stu.subjects.includes(sub.subjectId)) {
                this.finalSubjectMappingCount[sub.subjectName]++;
              }
            });
          }
        });
      }
    },
    calculateMappingChanged() {
      this.finalMappingCountArr = [];
      if (this.allSubjectOfCourseYear.length > 0) {
        this.allSubjectOfCourseYear.map((sub) => {
          let initial = this.initialSubjectMappingCount[sub.subjectName];
          let final = this.finalSubjectMappingCount[sub.subjectName];
          if (initial !== final) {
            if (initial > final) {
              let count = initial - final;
              let sentence = `${sub.subjectName}:- ${count} student removed`;
              this.finalMappingCountArr.push(sentence);
            } else {
              let count = final - initial;
              let sentence = `${sub.subjectName}:- ${count} student added`;
              this.finalMappingCountArr.push(sentence);
            }
          }
        });
      }
    },
    divisionChanged() {
      this.selectedSubject = "";
    },
    async getStudentForDivision() {
      let obj = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        division: this.selectedDivision,
      };
      try {
        let arr = [];
        this.allStudent = [];
        this.validRollNumber = [];
        this.allStudent =
          await this.semesterUserRepositoryInstance.getStudentsOfADivision(obj);
        if (this.allStudent.length > 0) {
          this.allStudent.map((stu) => {
            arr.push(stu.uId);
            if (
              stu.rollNumber &&
              stu.rollNumber !== "" &&
              stu.rollNumber !== "-"
            ) {
              this.validRollNumber.push(stu.rollNumber);
            }
          });
        }
        await this.getFullNameOfStudent(arr);
      } catch (err) {
        console.error("err", err);
      }
    },
  },
};
</script>

<!-- <style>
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(-n + 2) {
  position: -webkit-sticky;
  /* position: sticky; */
  left: 0;
  background-color: #f2f3fa;
  color: black;
  z-index: 1;
}

.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(-n + 1) {
  position: -webkit-sticky;
  position: sticky;
  /* left; */
  background-color: #172abc;
  color: black;
  z-index: 1;
}
</style> -->
<style>
.styled-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1),
.styled-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
  position: sticky;
  background-color: #f2f3fa;
  color: black;
  z-index: 1;
}
.styled-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1) {
  left: 0;
}
.styled-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
  left: 100px;
}

/* .styled-table > .v-data-table__wrapper > table > thead > tr > th :nth-child(1){
  color: black;
  background-color: yellow;
} */
</style>
<!-- <style>
.styled-table > .v-data-table__wrapper {
  position: sticky;
  top: 100px;
  z-index: 1000; /* Ensure it's above other content */
}

.styled-table > .v-data-table__wrapper > table > thead > tr > th {
  position: sticky;
  top: 0;
  background-color: #f2f3fa;
  color: black;
  z-index: 1;
}

.styled-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1),
.styled-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #f2f3fa;
  color: black;
}

.styled-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
  left: 100px;
}
</style> -->
<style src='./activityEditStudentsRegistration.css' scoped></style>
