<template>
    <div id="nbaFlagWrapper">
        <div class="nbaTitle">ACC</div>
        <!-- <div class="nbaSubTitle">(Accreditation)</div> -->
        <v-icon class="nbaFlagIcon">mdi-label-variant</v-icon>
    </div>
</template>
<script>
export default {
    name: 'AccreditationTag'
}
</script>
<style scoped>
#nbaFlagWrapper {
    background-color: #E1E2F8;
    padding: 4px 10px;
    width: max-content;
    border-radius: 3px;
    display: flex;
    align-content: center;
}

.nbaFlagIcon {
    color: #050D92;
    font-size: 18px;
}

.nbaTitle {
    color: #050D92;
    font-weight: 600;
    font-size: 14px;
}
</style>
    