<template src='./activityExamUploadResult.html'>
</template>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.13.5/xlsx.full.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.13.5/jszip.js"></script>
<script>
import { subjectTypes } from '../../../Constants/Utils/Statics'
import ExamSubjectRepository from '../../../Repository/ExamSubject'
import ExamTimetableRepository from '../../../Repository/ExamTimetable'
import SubjectRepository from '../../../Repository/Subject'
import InstituteUserRepository from '../../../Repository/InstituteUser'
import ExamStudentRepository from '../../../Repository/ExamStudent'
import UserRepository from '../../../Repository/User'
import showStatus from '../../../NetworkManager/showStatus'
import inputContainer from '../../../Components/inputContainer'
import Vue from "vue";
import {
  Vuetify,
  VApp,
  Vtextfield,
  VCard,
  Vslidegroup,
  Vcheckbox,
  Vprogresscircular
  /* other imports ... */
} from "vuetify";
import XLSX from 'xlsx'
export default {
  name: 'activityExamUploadResult',
   components: {
    inputContainer
  },
  data () {
    return {
      progressBar: false,
      progressBarCompleted: false,
      selectedInstitute: '',
      showUploadStudentResultDialog:false,
      selectedSemester: {},
      updatingMarksOfSubject: {},
      selectedExam: {},
      examSubjects: [],
      ApplicableStudents: [],
      uIdToPrnMapping: {},
      uIdToPrnMapping2: {},
       studentsHeaders: [
        {
          text: 'Students Name',
          value: 'applicableUsers'
        },
        {
          text: 'marks',
          value: 'totalObtainedMarks'
        }
      ],
      headers: [
        {
          text: 'Exam',
          value: 'subjectName'
        },
        {
          text: 'Subject Code',
          value: 'subjectCode'
        },
        {
          text: 'Subject Type',
          value: 'subjectType'
        },
        {
          text: 'Online',
          value: 'isOnline'
        },
        {
          text: 'Students',
          value: 'applicableUsers'
        },
        {
          text: 'Questions',
          value: 'questions'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      subjectTypes: subjectTypes,
      examSubjectWiseTimetablesDistribution: {},
      loading: true,
      examTimetables: [],
      showUploadResultDialog: false,
      file: null,
      selectedExamSubject: {}
    }
  },
  created () {
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.selectedExam = this.$store.getters['liveData/get_selectedExam']
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this)
    this.examTimetableRepositoryInstance = new ExamTimetableRepository(this)
    this.examStudentRepositoryInstance = new ExamStudentRepository(this)
    this.userRepositoryInstance = new UserRepository(this)

    this.fetchExamSubjects()
  },
  methods: {
    async getSubjectDetails (subject) {
      const response = await this.subjectRepositoryInstance.getDetailsOfASubject({
        instituteId: subject.instituteId,
        semId: subject.semId,
        subjectId: subject.subjectId
      })
      subject.subjectName = response.subjectName
      subject.subjectType = response.subjectType
      subject.subjectCode = response.subjectCode
      console.log(subject, response)
    },
    async fetchExamSubjects () {
      // this.selectedExam = this.exams[this.examsStrings.indexOf(this.selectedExamString)]
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId
      }
      console.log(objToPush)
      this.examSubjects = []
      try {
        this.examSubjects = await this.examSubjectRepositoryInstance.getSubjectsForAnExam(objToPush)
      } catch (err) {
        this.examSubjects = []
        console.log(err)
      }

      for (let i = 0; i < this.examSubjects.length; i++) {
        await this.getSubjectDetails(this.examSubjects[i])
      }

      this.examTimetables = []
      try {
        this.examTimetables = await this.examTimetableRepositoryInstance.getExamTimetableForAnExam(objToPush)
      } catch (err) {
        console.log(err)
      }
      console.log(this.examTimetables)
      for (let i = 0; i < this.examTimetables.length; i++) {
        const str = this.examTimetables[i].subjectId
        if (!this.examSubjectWiseTimetablesDistribution[str]) {
          this.examSubjectWiseTimetablesDistribution[str] = []
        }
        this.examSubjectWiseTimetablesDistribution[str].push(this.examTimetables[i])
      }
      console.log(this.examSubjectWiseTimetablesDistribution)
      this.loading = false
    },
    async getInstituteProvidedId (uId) {
      const res = await this.instituteUserRepositoryInstance.getInstituteProvidedId({
        instituteId: this.selectedInstitute,
        uId: uId
      })
      return {
        instituteProvidedId: res,
        uId: uId
      }
    },
    async uploadStudentResult(item){
      
      this.ApplicableStudents = []
      let promises = []
      item.applicableUsers.forEach(user => {
        promises.push(
          this.userRepositoryInstance.getFullNameOfUser({ uId: user })
        )
      })
      promises = await Promise.allSettled(promises)
      for (let i = 0; i < promises.length; i++) {
        this.ApplicableStudents[i] = {
          name: promises[i].value,
          uId: item.applicableUsers[i],
          instituteId: item.instituteId,
          examId: item.examId,
          semId: item.semId,
          subjectId: item.subjectId,
          totalObtainedMarks:0
        }
      }
    setTimeout(() => {
      this.showUploadStudentResultDialog = true
    }, 500);
    },
    async updateStudentsResult(){
           this.progressBar = true
         

    this.ApplicableStudents.map(async (obj)=>{
      await this.examStudentRepositoryInstance.updateExamStudent(obj)
    })
    this.progressBar = false
    this.showUploadStudentResultDialog = false
    showStatus('Exam Created Successfully', 200, 'success', this)
    },
    async uploadExamResult (item) {
      console.log(item)
      this.selectedExamSubject = item
      const listOfPromises = []
      for (let i = 0; i < item.applicableUsers.length; i++) {
        let uId = item.applicableUsers[i]
        if (this.uIdToPrnMapping[uId]) {} else {
          listOfPromises.push(this.getInstituteProvidedId(uId))
        }
      }
      const instituteProvidedIds = await Promise.allSettled(listOfPromises)
      console.log(instituteProvidedIds)
      instituteProvidedIds.forEach(result => {
        this.uIdToPrnMapping[result.value.uId] = result.value.instituteProvidedId
        this.uIdToPrnMapping2[result.value.instituteProvidedId] = result.value.uId
      })
      console.log(this.uIdToPrnMapping2)
      this.showUploadResultDialog = true
    },
    Upload() {
      let self = this
      //Reference the FileUpload element.
      var fileUpload = document.getElementById("fileUpload");

      //Validate whether File is valid Excel file.
      var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof (FileReader) != "undefined") {
          var reader = new FileReader();
          //For Browsers other than IE.
          if (reader.readAsBinaryString) {
            reader.onload = function (e) {
              self.ProcessExcel(e.target.result);
            };
            reader.readAsBinaryString(fileUpload.files[0]);
          } else {
            //For IE Browser.
            reader.onload = function (e) {
              var data = "";
              var bytes = new Uint8Array(e.target.result);
              for (var i = 0; i < bytes.byteLength; i++) {
                data += String.fromCharCode(bytes[i]);
              }
              self.ProcessExcel(data);
            };
            reader.readAsArrayBuffer(fileUpload.files[0]);
          }
        } else {
          alert("This browser does not support HTML5.");
        }
      } else {
        alert("Please upload a valid Excel file.");
      }
    },
    async ProcessExcel(data) {
      //Read the Excel File data.
      var workbook = XLSX.read(data, {
        type: 'binary'
      });

      console.log(workbook.Sheets[workbook.SheetNames[0]])
      const range = workbook.Sheets[workbook.SheetNames[0]]['!ref']
      const values = workbook.Sheets[workbook.SheetNames[0]]

      const arr = range.split(':')[1].split('')
      arr.splice(0,1)
      const endIndex = parseInt(arr.join(''))
      console.log(endIndex)

      const parsedData = []

      for (let i = 2; i < endIndex + 1; i++) {
        const prnKey = 'A' + i
        const marksKey = 'B' + i
        const uId = this.uIdToPrnMapping2[(values[prnKey].v).toString()]
        const obj = {
          uId: uId,
          marks: typeof(values[marksKey].v) === 'number' ? values[marksKey].v : 0
        }
        if (uId) {
          parsedData.push(obj)
        }
      }
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.selectedExam.examId,
        subjectId: this.selectedExamSubject.subjectId,
        parsedData: parsedData
      }
      await this.examStudentRepositoryInstance.updateExamStudentMarksByExcel(objToPush)
      window.alert('Marks updated successfully!')
      this.showUploadResultDialog = false
      this.file = null
      console.log(objToPush)
    }
  }
}
</script>
<style scoped src='./activityExamUploadResult.css'>
</style>
