import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/AdditionalFacility";

class AdditionalFacilityReference {
  constructor(context) {
    this.context = context;
  }

  async saveFacility(data) {
    console.log(data);
    const response = await networkManager.postRequest(
      ApiNames.saveFacility,
      data
    );
    return response;
  }
  async getAllFacility(data) {
    console.log(data);
    const response = await networkManager.postRequest(
      ApiNames.getAllFacility,
      data
    );
    return response;
  }
  async editFacility(data) {
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.editFacility,
      data
    );
    return response;
  }
  async deleteFacility(data) {
    const response = await networkManager.postRequest(
      ApiNames.deleteFacility,
      data
    );
    return response;
  }
  async getAllUsedFacilityCount(data) {
    console.log(data);
    const response = await networkManager.postRequest(
      ApiNames.getAllUsedFacilityCount,
      data
    );
    return response;
  }
}

export default AdditionalFacilityReference;
