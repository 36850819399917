import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/universityEvents'

class mediaLibrary {
  constructor(context) {
    this.context = context
  }

  async createUniversityEvent(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createUniversityEvent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateUniversityEvent(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateUniversityEvent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.events
  }

  async getUniversityEvent(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getUniversityEvent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.events
  }
  

  async deleteEvent(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.deleteEvent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.events
  }


}

export default mediaLibrary
