<template src="./activityManageUsers.html"></template>

<script>
import UserRepository from '../../Repository/User'
import UserAuthRepository from '../../Repository/UserAuthentication'
import InstituteUserRepository from '../../Repository/InstituteUser'
import inputContainer from '../../Components/inputContainer'
import arrayOps from '../../Services/Utils/arrayOps'
import numOps from '../../Services/Utils/numOps'
import showStatus from '../../NetworkManager/showStatus'
import axios from 'axios'
import MailSenderRepository from '../../Repository/EmailSender'
import departmentDetailsRepository from '../../Repository/DepartmentDetails';
import { generateJsonFromExcel, generateFromJson, createExcelThroughJson, createExcelThroughJsonAndSchema } from "../../utils/excel"
import InstituteRepository from '../../Repository/Institute'
import UserAuthenticationRepository from "../../Repository/UserAuthentication";
import auth from '../../Services/Firebase/auth'
import { v4 as uuidv4 } from 'uuid'

import { ROLES } from "../../Constants"


export default {
  name: 'activityManageUsers',
  components: {
    inputContainer
  },
  data() {
    return {
      tempMotherName: '',
      uploadExcelLoading: false,
      isCorporateInstitute: false,
      isCandidate: '',
      checkUserNameChanged: '',
      isInstituteForNaacOrNBA: false,
      alertFromExcel: '',
      showAlertForUserExcel: false,
      usersUploadExcelLoading: false,
      allErrorArray: [],
      departments: [],
      emailsArrayToDelete: [],
      tempDepartment: '',
      isSubAdminSelected: '',
      isSubAdmin: 'false',
      tempSetPassword: '',
      tempUserName: '',
      senderMail: '',
      emailBody: '',
      senderMailPassword: '',
      displayName: '',
      subjectDescription: '',
      instituteStudentBrandingName: '',
      instituteStudentLogoLink: '',
      file: null,
      updateUsersFile: null,
      role: '',
      showUploadUsersDialog: false,
      loading: false,
      createUserLoading: false,
      search: '',
      addingParent: false,
      prevUserName: '',
      editingParent: false,
      addUserDialog: false,
      editUserDialog: false,
      addUserStepNumber: 1,
      selectedUser: '',
      tempFirstName: '',
      tempInstituteProvidedId: '',
      tempMiddleName: '',
      excelLink: '',
      tempLastName: '',
      tempEmails: [],
      tempPhones: [],
      parents: [],
      parentsEdited: [],
      tempEmail: '',
      userName: '',
      tempPhone: '',
      addingEmail: false,
      addingPhone: false,
      isTeaching: false,
      tempGender: '',
      tempDesignation: '',
      tempgrNumber: '',
      tempAbcIdNumber: '',
      tempDateOfBirth: '',
      tempStudentId: '',
      tempBloodGroup: '',
      genders: ['Male', 'Female', 'Other'],
      ExcelErr: [],
      designationDetails: [],
      bloodGroups: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'fullName',
          filterable: true
        },
        {
          text: 'Institute Id',
          sortable: true,
          value: 'instituteProvidedId',
          filterable: true
        },
        {
          text: 'Actions',
          value: 'action',
          align: 'center'
        }
      ],
      users: [],
      percentage: 0,
      updateUsersDialog: false,
      userDetails: [],
      excelDataLoader: false,
      allUIds: [],
      userAuthentications: [],
      excelValidationErrors: [],
      updateUsersLoader: false,
      existingEmails: [],
      subAdmin: ''
    }
  },
  created() {
    this.userRepositoryInstance = new UserRepository(this)
    this.UserAuthenticationRepositoryInstance =
      new UserAuthenticationRepository(this);
    this.departmentDetailsRepositoryInstance = new departmentDetailsRepository(this)
    this.UserAuthRepositoryInstance = new UserAuthRepository(this)
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this)
    this.MailSenderRepositoryInstance = new MailSenderRepository(this)
    this.instituteRepositoryInstance = new InstituteRepository(this)

    this.$store.commit('liveData/set_selectedActivityName', 'Manage Users')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.isCorporateInstitute = this.$store.getters['liveData/get_isCorporateInstitute']
    // this.isCorporateInstitute = true
    this.senderMail = this.$store.getters['liveData/get_senderMail']
    this.senderMailPassword = this.$store.getters['liveData/get_senderMailPassword']
    this.isInstituteForNaacOrNBA = this.$store.getters['liveData/get_isInstituteForNaacOrNBA']
    this.emailBody = this.$store.getters['liveData/get_emailBody']
    this.displayName = this.$store.getters['liveData/get_displayName']
    this.subjectDescription = this.$store.getters['liveData/get_subjectDescription']
    this.instituteStudentBrandingName = this.$store.getters['liveData/get_instituteStudentBrandingName']
    this.instituteStudentLogoLink = this.$store.getters['liveData/get_instituteStudentLogoLink']
    this.getDepartments()
    this.fetchData()
    this.getDesignationsOfInstitute()

  },
  methods: {
    updatePerscetage() {
      const per = this.$store.getters['liveData/get_percentage']
      this.percentage = numOps.roundOff(per.percentage)
    },
    async handleGenerateExcel() {
      let tempDepartments = [...this.departments, '-']
      this.schema = {
        Sheet1: {
          'User Role': {
            validation: {
              type: 'list',
              values: ['STUDENT', 'FACULTY'],
              required: true
            },
            variableName: 'roles'
          },
          'Core Department': {
            validation: {
              type: 'list',
              values: tempDepartments,
            },
            variableName: 'department'
          },
          'Faculty Type': {
            validation: {
              type: 'list',
              values: ['Teaching', 'Non-Teaching'],
            },
            variableName: 'facultyType'
          },
          'Designation': {
            validation: {
              type: 'list',
              values: this.designationDetails,
            },
            variableName: 'designation'
          },
          'First Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'firstName'
          },
          'Middle Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'middleName'
          },
          'Last Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'lastName'
          },
          'Gender': {
            validation: {
              type: 'list',
              values: ['Male', 'Female'],
              required: true
            },
            variableName: 'facultyType'
          },
          'GR Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'grNumber'
          },
          'abcId Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'abcIdNumber'
          },
          'College PRN': {
            validation: {
              type: 'textLength',
            },
            variableName: 'facultyType'
          },
          'Email id': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'facultyType'
          },
          'Mobile number': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'facultyType'
          },
          'Dob': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'facultyType'
          },
          'User Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'facultyType'
          },
          'Parents Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'facultyType'
          },
          'Mother Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'facultyType'
          },
          'Parents Email': {
            validation: {
              type: 'textLength',
            },
            variableName: 'facultyType'
          },
          'Parents Number': {
            validation: {
              type: 'textLength',
            },
          },
        }
      }
      this.excelLink = await generateFromJson(this.schema)
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = this.excelLink;
      link.target = '_blank'; // Open in a new tab

      // Trigger the download
      link.click();
    },
    async getDesignationsOfInstitute() {
      this.designationDetails = []
      try {
        let data = await this.instituteRepositoryInstance.getDesignationsOfInstitute({
          instituteId: this.selectedInstitute
        })
        this.designationDetails = data.map(e => e.name)
      } catch (err) {
        console.error('err', err);
      }
    },
    async getDepartments() {
      this.allDepartmentDetails = []
      try {
        let deptData = await this.departmentDetailsRepositoryInstance.getDepartmentDetailsForInstitute({
          instituteId: this.selectedInstitute
        })
        if (deptData.length > 0) {
          this.departments = await deptData.map(e => e.departmentName)
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    generateRandomPass() {
      this.tempSetPassword = '';
      let sampleArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'f', 'e', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'x', 'y', 'z', 'Y', 'Q', 'W', 'M', 'O', 'U', 'F', 'R', 'T', 'I']
      let leng
      leng = sampleArr.length
      let pass = '';
      for (let i = 0; i <= 6; i++) {
        let x = Math.floor(Math.random() * 35);
        pass += sampleArr[x]
      }
      this.tempSetPassword = pass
    },
    async fetchData() {
      this.users = []
      this.loading = true
      const objToPush = {
        instituteId: this.selectedInstitute
      }

      this.allUIds = []
      try {
        let res = await this.instituteUserRepositoryInstance.getUsersOfAnInstitute(
          objToPush
        )
        const instituteUsers = res.instituteUsers
        const userAuthData = res.userAuthData
        for (let i = 0; i < instituteUsers.length; i++) {
          this.allUIds.push(instituteUsers[i].uId)
          // instituteUsers[i]['isSubAdmin'] = false
          for (let y = 0; y < res.userAuthData.length; y++) {
            if (res.userAuthData[y].uId === instituteUsers[i].uId && (res.userAuthData[y].userName !== undefined || res.userAuthData[y].userName !== null) && res.userAuthData[y].userName !== "") {
              // instituteUsers[i]['isSubAdmin'] = true
              instituteUsers[i]['roles'] = res.userAuthData[y].roles
              instituteUsers[i]['userName'] = res.userAuthData[y].userName
            }
          }
          this.users.push({
            fullName: '',
            instituteProvidedId: instituteUsers[i].instituteProvidedId,
            uId: instituteUsers[i].uId,
            roles: instituteUsers[i].roles,
            userName: instituteUsers[i].userName
            // isSubAdmin: instituteUsers[i].isSubAdmin
          })
        }
      } catch (err) {
        console.error(err)
      }
      const batchSize = 1000
      if (this.allUIds.length > 0) {
        await this.getUsersData(this.allUIds)
        await this.getAllUserAuthentications()
      }
      const promises = []
      for (let i = 0; i < this.users.length; i += batchSize) {
        promises.push(
          this.getFullNameOfUser(
            i,
            i + batchSize < this.users.length
              ? i + batchSize
              : this.users.length
          )
        )
      }
      await Promise.allSettled(promises)
      this.loading = false
    },
    async openUploadUsersDialog() {
      this.showUploadUsersDialog = true
    },
    async uploadExcel() {
      this.usersUploadExcelLoading = true
      this.loading = true
      const instututeData = {
        instituteId: this.selectedInstitute
      }
      auth.getPercentageOfAddedUsers(instututeData)
      const schema1 = {
        Sheet1: {
          'User Role': {
            validation: {
              type: 'list',
              values: ['STUDENT', 'FACULTY'],
              required: true
            },
            variableName: 'roles'
          },
          'Core Department': {
            validation: {
              type: 'list',
              values: this.departments,
            },
            variableName: 'department'
          },
          'Faculty Type': {
            validation: {
              type: 'list',
              values: ['Teaching', 'Non-Teaching'],
            },
            variableName: 'isTeaching'
          },
          'Designation': {
            validation: {
              type: 'list',
              values: this.designationDetails,
            },
            variableName: 'designation'
          },
          'First Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'firstName'
          },
          'Middle Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'middleName'
          },
          'Last Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'lastName'
          },
          'Gender': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'gender'
          },
          'GR Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'grNumber'
          },
          'abcId Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'abcId'
          },
          'College PRN': {
            validation: {
              type: 'textLength',
            },
            variableName: 'instituteProvidedId'
          },
          'Email id': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'email'
          },
          'Mobile number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'mobile'
          },
          'Dob': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'dateOfBirth'
          },
          'User Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'userName'
          },
          'Parents Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsName'
          },
          'Mother Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'motherName'
          },
          'Parents Email': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsEmailId'
          },
          'Parents Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsMobile'
          },
        }
      }
      this.ExcelErr = []
      let res = await generateJsonFromExcel(this.file, schema1, false)
      const { errors, data } = res
      if (Array.isArray(errors) && errors.length > 0) {
        this.loading = false
        this.usersUploadExcelLoading = false
        this.ExcelErr = errors
        return
      } else if (Array.isArray(data.Sheet1) && data.Sheet1.length > 0) {
        let res = await this.validateUsersData(data.Sheet1)
        this.ExcelErr = [...errors, ...res]
      }
      if (!this.ExcelErr.length > 0) {
        const objToPush = {
          users: data.Sheet1,
          instituteId: this.selectedInstitute
        }
        let temp = setInterval(() => {
          this.updatePerscetage()
        }, 100);
        try {
          const rsult = await this.userRepositoryInstance.addMultipleUsersOnBothSides(
            objToPush
          )
        } catch (error) {
          console.error('error', error);
        }
        clearInterval(temp)
        this.loading = false
        this.usersUploadExcelLoading = false
        this.showUploadUsersDialog = false
        this.fetchData()
      }
    },
    validateUsersData(arr) {
      let err = []
      arr.map((e, i) => {
        if (e.roles === 'FACULTY') {
          if (e.department === undefined || e.department === '') {
            err.push(`Code Department cannot be empty for Faculty ${e.firstName} ${e.lastName}`)
          }
          if (e.isTeaching === undefined || e.isTeaching === '') {
            err.push(`Faculty Type cannot be empty for Faculty ${e.firstName} ${e.lastName}`)
          }
          if (e.designation === undefined || e.designation === '') {
            err.push(`designation cannot be empty for Faculty ${e.firstName} ${e.lastName}`)
          }
        }
      })
      return err
    },
    async getFullNameOfUser(firstIndex, lastIndex) {
      try {
        const objToPush = {
          uIds: []
        }

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.users[i].uId)
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        )
        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(elem => elem.uId === this.users[i].uId)
          this.users[i].fullName = user ? user.fullName : ''
        }
      } catch (err) {
        console.error(err)
      }
    },
    async startEditingDetailsOfUser(item) {
      this.emailsArrayToDelete = []
      this.editUserDialog = true
      // this.addUserDialog = true
      // let resForAuth
      // if(item.userName) {
      //   console.log('item', item)
      //   resForAuth =
      //       await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUidAndUserName(
      //         {
      //           uId: item.uId,
      //           userName: item.userName
      //         }
      //       );
      //       console.log('resForAuth', resForAuth)
      //     }
      // if(resForAuth) {
      //   item = resForAuth[0]
      //   this.selectedUser = resForAuth[0]
      // }
      // else 
      this.selectedUser = item
      if (item?.roles?.includes(3)) {
        this.isSubAdmin = 'true'
        this.isSubAdminSelected = 'true'
      } else {
        this.isSubAdmin = 'false'
        this.isSubAdminSelected = 'false'
      }
      if (item?.roles?.includes(0)) this.role = 'STUDENT'
      if (item?.roles?.includes(1)) {
        this.role = 'FACULTY'
      }
      // this.isSubAdmin = item.isSubAdmin ? 'true' : 'false'
      // this.isSubAdminSelected = item.isSubAdmin ? 'true' : 'false'
      this.getDataOfUserToEdit()
      this.getParentsOfStudent()
    },
    async getParentsOfStudent() {
      try {
        this.parents = await this.userRepositoryInstance.getParentsOfAUser({
          uId: this.selectedUser.uId
        })
      } catch (err) {
        this.parents = []
        console.error(err)
      }

      for (const parent of this.parents) {
        try {
          const emailsAndPhones = await this.userRepositoryInstance.getUserAuthentication(
            { uId: parent.uId }
          )
          if (emailsAndPhones.userName !== undefined && emailsAndPhones.userName !== null && emailsAndPhones.userName !== "") {
            this.userName = emailsAndPhones.userName;
            this.tempUserName = emailsAndPhones.userName;
          }
          if (emailsAndPhones.emails) {
            emailsAndPhones.emails.forEach(email => {
              if (parent.emails) {
              } else {
                parent.emails = []
              }
              parent.emails.push(email)
            })
          }
          if (emailsAndPhones.phones) {
            emailsAndPhones.phones.forEach(phone => {
              if (parent.phones) {
              } else {
                parent.phones = []
              }
              parent.phones.push(phone)
            })
          }
        } catch (err) {
          console.error(err)
        }
      }
      this.parentsEdited = JSON.parse(JSON.stringify(this.parents))
    },
    async getDataOfUserToEdit() {
      const objToPush = {
        uId: this.selectedUser.uId
      }
      try {
        const user = await this.userRepositoryInstance.getUserProfile(
          objToPush
        )
        this.tempFirstName = user.firstName
        this.tempMotherName = user.motherName
        this.tempInstituteProvidedId = this.selectedUser.instituteProvidedId
        this.tempMiddleName = user.middleName
        this.tempLastName = user.lastName
        this.tempDateOfBirth = user.dateOfBirth ? this.parseDate(user.dateOfBirth) : ''
        this.isTeaching = user.isTeaching
        this.tempDepartment = user.department
        this.tempDesignation = user.designation
        this.tempGender = user.gender
        this.tempgrNumber = user.grNumber
        this.tempAbcIdNumber = user.abcIdNumber
        this.selectedUser.emails = []
        this.selectedUser.phones = []
        const emailsAndPhones = await this.userRepositoryInstance.getUserAuthentication(
          objToPush
        )
        if (emailsAndPhones?.role && this.role === '') {
          this.role = emailsAndPhones.role[0]
        }
        if (emailsAndPhones.userName !== undefined && emailsAndPhones.userName !== null && emailsAndPhones.userName !== "") {
          this.tempUserName = emailsAndPhones.userName;
          this.prevUserName = emailsAndPhones.userName;
          this.checkUserNameChanged = emailsAndPhones.userName;
        }
        if (emailsAndPhones.emails) {
          emailsAndPhones.emails.forEach(email => {
            this.tempEmails.push(email)
            this.selectedUser.emails.push(email)
          })
        }
        if (emailsAndPhones.phones) {
          emailsAndPhones.phones.forEach(phone => {
            this.tempPhones.push(phone)
            this.selectedUser.phones.push(phone)
          })
        }
      } catch (err) {
        console.error(err)
      }
    },
    async getUsersData(uIds) {
      const data = await this.userRepositoryInstance.getUsers({
        uIds: uIds
      })
      this.userDetails = data.filter(item => item.email !== "")
    },
    updateUsersExcelDialog() {
      this.percentage = 0
      this.excelValidationErrors = []
      this.existingEmails = []
      this.updateUsersFile = null
      this.updateUsersDialog = !this.updateUsersDialog
    },
    async updateMultipleUsersViaExcel() {
      this.updateUsersLoader = true
      this.excelValidationErrors = []
      this.existingEmails = []
      const instututeData = {
        instituteId: this.selectedInstitute
      }
      auth.getPercentageOfAddedUsers(instututeData)
      const schema1 = {
        Sheet1: {
          'User Role': {
            validation: {
              type: 'list',
              values: ['STUDENT', 'FACULTY'],
              // required: true
            },
            variableName: 'roles'
          },
          'Core Department': {
            validation: {
              type: 'list',
              // values: this.departments,
            },
            variableName: 'department'
          },
          'Faculty Type': {
            validation: {
              type: 'list',
              values: ['Teaching', 'Non-Teaching'],
            },
            variableName: 'isTeaching'
          },
          'Designation': {
            validation: {
              type: 'list',
              // values: this.designationDetails,
            },
            variableName: 'designation'
          },
          'First Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'firstName'
          },
          'Middle Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'middleName'
          },
          'Last Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'lastName'
          },
          'Email': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'email'
          },
          'New Email': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'newEmail'
          },
          'Gender': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'gender'
          },
          'GR Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'grNumber'
          },
          'ABCID Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'abcIdNumber'
          },
          'College PRN': {
            validation: {
              type: 'textLength',
            },
            variableName: 'collegePRNNo'
          },
          'Mobile number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'mobile'
          },
          'Dob': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'dateOfBirth'
          },
          'User Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'userName'
          },
          'Parents Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsName'
          },
          'Mother Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'motherName'
          },
          'Parents Email': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsEmailId'
          },
          'Parents Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsMobile'
          },
        }
      }
      this.ExcelErr = []
      let roles = Object.keys(ROLES)
      let facultyTypeArray = ["Teaching", "Non-Teaching"]
      let res = await generateJsonFromExcel(this.updateUsersFile, schema1, false)
      const parsedData = res.data.Sheet1
      let newArray = []
      let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      parsedData.map((obj, i) => {
        let objToReturn = {}
        if (obj.department && obj.department !== "") {
          if (!this.departments?.includes(obj.department)) {
            this.excelValidationErrors.push(`Invalid department name entered for ${obj.firstName} ${obj.lastName} at row number ${i + 2}`)
          };
        }
        if (obj.roles && obj.roles !== "") {
          if (!roles?.includes(obj.roles)) {
            this.excelValidationErrors.push(`Invalid role entered for ${obj.firstName} ${obj.lastName} at row number ${i + 2}`)
          };
        }
        if (obj.designation && obj.designation !== "") {
          if (!this.designationDetails?.includes(obj.designation)) {
            this.excelValidationErrors.push(`Invalid designation entered for ${obj.firstName} ${obj.lastName} at row number ${i + 2}`)
          };
        }
        if (obj.email) {
          let tempEmail = obj.email.text ? obj.email.text : obj.email
          if (!emailRegex.test(tempEmail.trim())) {
            this.excelValidationErrors.push(`Invalid email entered for ${obj.firstName} ${obj.lastName} at row number ${i + 2}`)
          }
        }
        if (obj.newEmail) {
          let tempNewEmail = obj.newEmail.text ? obj.newEmail.text : obj.newEmail
          if (!emailRegex.test(tempNewEmail.trim())) {
            this.excelValidationErrors.push(`Invalid 'new email' entered for ${obj.firstName} ${obj.lastName} at row number ${i + 2}`)
          }
        }
        if (obj.isTeaching !== undefined) {
          obj.isTeaching = obj.isTeaching ? true : false
        }

        for (const [key, value] of Object.entries(obj)) {
          if (value !== undefined) {
            if (value !== "") {
              objToReturn[key] = value
            }
          }
        }
        newArray.push(objToReturn)
      })
      if (this.excelValidationErrors.length === 0) {
        const objToPush = {
          users: newArray,
          instituteId: this.selectedInstitute
        }
        let temp = setInterval(() => {
          this.updatePerscetage()
        }, 100);
        try {
          const data = await this.userRepositoryInstance.updateMultipleUsersOnBothSides(objToPush)
          if (data.result) {
            this.existingEmails = data.result
            this.updateUsersDialog = true
            clearInterval(temp)
          } else {
            this.updateUsersDialog = false
          }
        } catch (error) {
          this.updateUsersDialog = false
          console.error('error', error);
        }

        this.fetchData()
        // this.updateUsersDialog = false
      }
      this.updateUsersLoader = false
      this.$store.commit("liveData/set_percentage", {
        percentage: 0
      })

    },
    async getAllUserAuthentications() {
      try {
        this.userAuthentications = await this.UserAuthRepositoryInstance.getUserAuthenticationsByUIds({ uIds: this.allUIds })
      } catch (error) {
        this.userAuthentications = []
        console.error('error', error);
      }
    },
    async generateExistingUsersExcel() {
      this.excelDataLoader = true

      const array = this.userDetails.map((user) => {
        let tempFacultyType = ""
        if (user.isTeaching === undefined) {
          tempFacultyType = ""
        } else {
          tempFacultyType = user.isTeaching ? "Teaching" : "Non-Teaching"
        }
        let tempRole = this.userAuthentications.filter(item => item.email === user.email)[0]
        let userRole = ""
        if (tempRole) {
          for (const [key, value] of Object.entries(ROLES)) {
            if (tempRole.roles[0] === value) {
              userRole = key
            }
          }
        }
        let dob = ""
        if (user.dateOfBirth) {
          dob = user?.dateOfBirth?.includes('T') ? user.dateOfBirth.split('T')[0] : user.dateOfBirth
        }
        return {
          "User Role": userRole,
          "Core Department": user.department ?? "",
          "Faculty Type": tempFacultyType,
          "Designation": user.designation,
          "First Name": user.firstName,
          "Middle Name": user.middleName,
          "Last Name": user.lastName,
          "Email": user.email,
          "New Email": "",
          "Gender": user.gender,
          "GR Number": user.grNumber,
          "ABCID Number": user.abcIdNumber,
          "College PRN": user.collegePRNNo,
          "Mobile number": user.mobile,
          "Dob": dob,
          "User Name": tempRole?.userName,
          "Parents Name": user?.parentsName,
          "Mother Name": user?.motherName,
          "Parents Email": user?.parentsEmailId,
          "Parents Number": user?.parentsMobile,
        }
      })
      const schema1 = {
        Sheet1: {
          'User Role': {
            validation: {
              type: 'list',
              values: ['STUDENT', 'FACULTY'],
              // required: true
            },
            variableName: 'roles'
          },
          'Core Department': {
            validation: {
              type: 'list',
              values: this.departments,
            },
            variableName: 'department'
          },
          'Faculty Type': {
            validation: {
              type: 'list',
              values: ['Teaching', 'Non-Teaching'],
            },
            variableName: 'isTeaching'
          },
          'Designation': {
            validation: {
              type: 'list',
              values: this.designationDetails,
            },
            variableName: 'designation'
          },
          'First Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'firstName'
          },
          'Middle Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'middleName'
          },
          'Last Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'lastName'
          },
          'Email': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'email'
          },
          'New Email': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'newEmail'
          },
          'Gender': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'gender'
          },
          'GR Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'grNumber'
          },
          'ABCID Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'abcIdNumber'
          },
          'College PRN': {
            validation: {
              type: 'textLength',
            },
            variableName: 'collegePRNNo'
          },
          'Mobile number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'mobile'
          },
          'Dob': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'dateOfBirth'
          },
          'User Name': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'userName'
          },
          'Parents Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsName'
          },
          'Mother Name': {
            validation: {
              type: 'textLength',
            },
            variableName: 'motherName'
          },
          'Parents Email': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsEmailId'
          },
          'Parents Number': {
            validation: {
              type: 'textLength',
            },
            variableName: 'parentsMobile'
          },
        }
      }
      createExcelThroughJsonAndSchema(array, schema1, 'Users')
      // createExcelThroughJson(array, 'Users')
      this.excelDataLoader = false
    },
    closeEditUserDialog() {
      this.editUserDialog = false
      this.isSubAdmin = 'false'
      this.selectedUser = ''
      this.tempFirstName = ''
      this.tempMotherName = ''
      this.tempInstituteProvidedId = ''
      this.tempMiddleName = ''
      this.tempLastName = ''
      this.tempEmails = []
      this.tempPhones = []
      this.tempGender = ''
      this.tempDateOfBirth = ''
      this.tempBloodGroup = ''
      this.tempUserName = ''
      this.tempSetPassword = ''
      this.role = ""
    },
    async saveEditedDetailsOfUser() {
      if (this.isSubAdmin === "true" && this.role !== 'STUDENT') {
        this.subAdmin = 'SUB_ADMIN';
      } else if (this.isSubAdmin === null) {
        this.subAdmin = '';
      }
      const objToPush = {
        firstName: this.tempFirstName,
        middleName: this.tempMiddleName,
        lastName: this.tempLastName,
        gender: this.tempGender,
        dateOfBirth: this.tempDateOfBirth,
        grNumber: this.tempgrNumber,
        designation: this.tempDesignation,
        abcIdNumber: this.tempAbcIdNumber,
        motherName: this.tempMotherName,
        department: this.tempDepartment,
        isTeaching: this.isTeaching,
        roles: this.role,
        subAdmin: this.subAdmin,
        userName: this.userName,
        // mobile: this.tempPhones[0] || null,
        prevUserName: this.prevUserName,
        // email: this.tempEmails[this.tempEmails.length-1],
        isCandidate: this.isCandidate === 'Candidate' ? true : false,
        userName: this.tempUserName,
        uId: this.selectedUser.uId
      }
      if (this.tempEmails.length > 0) objToPush.email = this.tempEmails
      if (this.tempDesignation) {
        objToPush.designation = this.tempDesignation
      }
      if (this.isTeaching) {
        objToPush.isTeaching = this.isTeaching
      }
      if (this.tempDepartment) {
        objToPush.department = this.tempDepartment
      }
      if (this.tempPhones[0]) {
        objToPush.mobile = this.tempPhones[0]
      }
      if (this.tempPhones[0] === undefined) {
        objToPush.mobile = null
      }
      if (this.addingParent) {
        objToPush.childrenUserIds = [this.tempStudentId]
      } else {
        objToPush.instituteProvidedId = this.tempInstituteProvidedId
        objToPush.instituteId = this.selectedInstitute
      }
      if (this.tempSetPassword) {
        // const data = await this.userRepositoryInstance.updatePasswordFromManageUsers({
        //   uId: this.selectedUser.uId,
        objToPush.newPassword = this.tempSetPassword
        // })
      }
      const objToAddUsers = {
        users: [objToPush],
        instituteId: this.selectedInstitute
      }
      const data = await this.userRepositoryInstance.updateMultipleUsersOnBothSides(objToAddUsers)
      // if (this.emailsArrayToDelete.length > 0) {
      //   const deleteEmail = await this.userRepositoryInstance.deleteEmailsFromUserAuth({emailsToDelete: this.emailsArrayToDelete, uId: this.selectedUser.uId})
      // }
      await this.fetchData()
      this.closeEditUserDialog()
    },
    async addAndRemoveEmailsAndPhones() {
      const emailsToDelete = this.selectedUser.emails.filter(
        email => !this?.tempEmails?.includes(email)
      )

      for (let i = 0; i < emailsToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deleteEmail({
            uId: this.selectedUser.uId,
            email: emailsToDelete[i]
          })
        } catch (err) {
          console.error(err)
        }
      }

      const phonesToDelete = this.selectedUser.phones.filter(
        phone => !this.tempPhones?.includes(phone)
      )

      for (let i = 0; i < phonesToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deletePhone({
            uId: this.selectedUser.uId,
            phone: phonesToDelete[i]
          })
        } catch (err) {
          console.error(err)
        }
      }

      const emailsToAdd = this.tempEmails.filter(
        email => !this.selectedUser?.emails?.includes(email)
      )

      for (let i = 0; i < emailsToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addEmail({
            uId: this.selectedUser.uId,
            email: emailsToAdd[i]
          })
        } catch (err) {
          console.error(err)
        }
      }

      const phonesToAdd = this.tempPhones.filter(
        phone => !this.selectedUser?.phones?.includes(phone)
      )

      for (let i = 0; i < phonesToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addPhone({
            uId: this.selectedUser.uId,
            phone: phonesToAdd[i]
          })
        } catch (err) {
          console.error(err)
        }
      }
    },
    parseDate(dateOfBirth) {
      const parsedDate = new Date(dateOfBirth);
      return (
        parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getUTCDate()).slice(-2)
      );
    },
    addEmail() {
      this.tempEmails.push(this.tempEmail)
      this.tempEmail = ''
      this.addingEmail = false
    },
    addPhone() {
      this.tempPhones.push(this.tempPhone)
      this.tempPhone = ''
      this.addingPhone = false
    },
    openAddUserDialog() {
      this.addUserDialog = true
      this.selectedUser = {}
    },
    closeAddUserDialog() {
      this.addUserDialog = false
      this.addUserStepNumber = 1
      this.isCandidate = ''
      this.selectedUser = ''
      this.tempFirstName = ''
      this.tempInstituteProvidedId = ''
      this.tempMiddleName = ''
      this.tempLastName = ''
      this.role = ''
      this.isTeaching = false
      this.tempDepartment = ''
      this.tempEmails = []
      this.tempPhones = []
      this.tempGender = ''
      this.tempDateOfBirth = ''
      this.tempBloodGroup = ''
      this.userName = ''
      this.tempMotherName = ''
    },
    closeAddParentDialog() {
      this.addingParent = false
      this.addUserStepNumber = 1
      this.selectedUser = ''
      this.isCandidate = ''
      this.tempFirstName = ''
      this.tempInstituteProvidedId = ''
      this.tempMiddleName = ''
      this.tempLastName = ''
      this.tempEmails = []
      this.tempPhones = []
      this.tempGender = ''
      this.tempDateOfBirth = ''
      this.tempBloodGroup = ''
      this.tempUserName = ''
    },
    async gotoNextStep() {

      if (this.tempFirstName === '') {
        alert('Enter your first name ')
        return
      } else if (this.tempDateOfBirth === '') {
        alert('Enter your date of birth')
        return
      } else if (this.tempGender === '') {
        alert('Enter your gender')
        return
      } else {
        this.createUserProfileAndMoveToNextStep()
      }
    },
    async createUserProfileAndMoveToNextStep() {
      this.createUserLoading = true
      const objToPush = {
        firstName: this.tempFirstName,
        middleName: this.tempMiddleName,
        lastName: this.tempLastName,
        gender: this.tempGender,
        dateOfBirth: this.tempDateOfBirth,
        grNumber: this.tempgrNumber,
        abcId: this.tempAbcIdNumber,
        motherName: this.tempMotherName,
        department: this.tempDepartment,
        isTeaching: this.isTeaching,
        isCandidate: this.isCandidate === 'Candidate' ? true : false
      }

      if (this.addingParent) {
        objToPush.childrenUserIds = [this.tempStudentId]
      } else {
        objToPush.instituteProvidedId = this.tempInstituteProvidedId
        objToPush.instituteId = this.selectedInstitute
      }
      try {
        this.addUserStepNumber = 2
        this.createUserLoading = false
      } catch (err) {
        console.error(err)
        showStatus('Error creating user', 1000, 'error', this)
      }
    },
    async createUserEmailsAndPhones() {
      if (this.userName !== undefined && this.userName !== null && this.userName !== "") {
        try {
          const objToPush = {
            firstName: this.tempFirstName,
            middleName: this.tempMiddleName,
            lastName: this.tempLastName,
            gender: this.tempGender,
            dateOfBirth: this.tempDateOfBirth,
            grNumber: this.tempgrNumber,
            designation: this.tempDesignation,
            abcId: this.tempAbcIdNumber,
            motherName: this.tempMotherName,
            department: this.tempDepartment,
            isTeaching: this.isTeaching,
            roles: this.role,
            userName: this.userName,
            mobile: this.tempPhones[0],
            email: this.tempEmails[0],
            isCandidate: this.isCandidate === 'Candidate' ? true : false
          }

          if (this.addingParent) {
            objToPush.childrenUserIds = [this.tempStudentId]
          } else {
            objToPush.instituteProvidedId = this.tempInstituteProvidedId
            objToPush.instituteId = this.selectedInstitute
          }

          const objToAddUsers = {
            users: [objToPush],
            instituteId: this.selectedInstitute
          }
          const result = await this.userRepositoryInstance.addMultipleUsersOnBothSides(
            objToAddUsers
          )
        } catch (err) {
          console.error(err)
        }
      }
      this.addUserStepNumber = 3
      showStatus('User Added successfully', 1000, 'success', this)
      this.closeAddUserDialog()
      this.fetchData()
    },
    async sendWelcomeMail(FirstName, LastName, emailId, userName, uId) {
      try {
        let objToPush = {
          senderMail: this.senderMail,
          senderMailPassword: this.senderMailPassword,
          emailBody: this.emailBody,
          displayName: this.displayName,
          subjectDescription: this.subjectDescription,
          brandingLogo: this.instituteStudentLogoLink,
          brandingName: this.instituteStudentBrandingName,
          firstName: FirstName,
          lastName: LastName,
          emailId: emailId,
          userName: userName,
          uId: uId
        }
        const res = await this.MailSenderRepositoryInstance.sendWelcomeMail(
          objToPush
        )
      } catch (e) {
        console.error('err', e);
      }
    },
    validateUserProfileFields() {
      if (this.role === 'FACULTY') {
        if (
          (this.tempFirstName || this.tempMiddleName || this.tempLastName) &&
          this.tempGender && this.tempDateOfBirth && this.role && this.isTeaching && this.tempDepartment
        ) {
          return false
        } else {
          return true
        }
      } else {
        if ((this.tempFirstName || this.tempMiddleName || this.tempLastName) && this.tempGender && this.tempDateOfBirth) {
          return false
        } else {
          return true
        }
      }
    },
    removeFromArray(item, arr, isEmail) {
      if (isEmail) this.emailsArrayToDelete.push(item)
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },
    async portalNavigation(userName) {
      const userObj = {
        roles: this.role,
        userName: this.userName,
        email: this.tempEmails[this.tempEmails.length - 1],
        userName: this.tempUserName,
        uId: this.selectedUser.uId,
        isRedirectedFromAdmin: true,
        uuId: uuidv4()
      };

      await auth.portalNavigationSecurity({
        userName,
        uId: this.selectedUser.uId,
        authenticationId: userObj.uuId,
        adminUId: this.userData.uId
      });
      console.log("process.env.VUE_APP_STUDENT_PORTAL", process.env.VUE_APP_STUDENT_PORTAL, process.env.VUE_APP_FACULTY_PORTAL)
      const baseUrl = userObj.roles === "STUDENT" ? process.env.VUE_APP_STUDENT_PORTAL :
        userObj.roles === "FACULTY" ? process.env.VUE_APP_FACULTY_PORTAL : "";

      const loginUrl = baseUrl ? `${baseUrl}?userName=${encodeURIComponent(userName)}&isRedirectedFromAdmin=${userObj.isRedirectedFromAdmin}&uId=${this.selectedUser.uId}&uuId=${userObj.uuId}&adminUId=${this.userData.uId}` : '';

      if (baseUrl) {
        window.open(loginUrl, '_blank');
      }
    }
  }
}
</script>

<style src="./activityManageUsers.css" scoped></style>
