<template src="./activitySetup.html"> </template>
<script>
import auth from '../../Services/Firebase/auth'
import UserRepository from '../../Repository/User'
import showStatus from '../../NetworkManager/showStatus'
import { ROLES } from '../../Constants/index'
export default {
  name: 'activitySetup',
  data () {
    return {
      user: null,
      admissionMails: [
        'kaustubhatre18@gmail.com',
        'ambitions.kba@gmail.com',
        'inprospecttechnologiesofficial@gmail.com',
        'tech.inprospect@gmail.com',
        'virajlagad@gmail.com',
        'maardav.oturckar@gmail.com'
      ]
    }
  },
  created () {
    this.user = this.$store.getters['user/get_user']
    this.userRepositoryInstance = new UserRepository(this)
    const authToken = this.$store.getters['user/get_authToken']
    console.log("this.user Nilesh",this.user)
    console.log("this.user Nilesh",this.admissionMails)
    if (this.user) {
      const objToPush = {
        registrationToken: '',
        authToken: authToken,
        uuId: this.user.uid,
        email: this.user.email,
        phone: this.user.phoneNumber ? this.user.phoneNumber.substring(3) : null,
        role: ROLES.ADMIN
      }
      this.$store.commit('liveData/set_showAdmissionModule', this.admissionMails.includes(this.user.email))
      this.userRepositoryInstance.login(objToPush).then(res => {
        showStatus('Login Successful!', 1000, 'success', this)
        console.log(res)
        this.$router.push('/activitySelectInstituteNSemester')
      }).catch(err => {
        console.log(err)
        if (err.response.status === 401) {
          alert("You don't have access to this portal")
        }
        auth.logout(this)
      })
    } else {
      this.$router.push('/activityWelcome')
    }
  }
}
</script>
<style scoped src="./activitySetup.css"></style>
