export default {
  updateCourseInfo: "/courses/updateCourseInfo", //post
  deleteModuleFromBothSide: "/courses/deleteModuleFromBothSide", //post
  getSingleCourse: "/courses/getSingleCourse", //get
  mediaLibrary: "/mediaLibrary/getMediaLibrary",
  getAllCoursesInfoOfInstitute: "/courses/getAllCoursesInfoOfInstitute",
  getAllCoursesInfoOfStudent: "/courses/getAllCoursesInfoOfStudent",
  createStudentCourse: "/courses/createStudentCourse",
  getSingleStudentCourse: "/courses/getSingleStudentCourse",
  getAllMediaLibraryByInstitute: "/mediaLibrary/getAllMediaLibraryByInstitute",
  generateStudentCourseReport: "/excel/courses/generateStudentCourseReport",
  generateStudentFeedbackReport: "/excel/courses/generateStudentFeedbackReport",
  getProgressPercentageOfStudentsInCourse:
    "/courses/getProgressPercentageOfStudentsInCourse",
  getSyncCoursesDataOfSingleStudent: "/courses/syncCoursesDataOfSingleStudent",
  getSubsectionCompletionStatus: "/courses/getSubsectionCompletionStatus",
  getCourseRatingDetails: "/courses/getCourseRatingDetails",
  getMultiCourseRatings: "/courses/getMultiCourseRatings",
  createSaveLog: "/logger/courses/createSaveLog",
  createMultipleLogs: "/logger/courses/createMultipleLogs",
  getSaveLogs: "/logger/courses/getSaveLogs",
  getPublishLogs: "/logger/courses/getPublishLogs",
};
